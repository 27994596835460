import { Component, OnInit} from '@angular/core';
import { InvoiceService } from '../services/invoice.service';
import { devismodel } from '../models/facture.model';
import { ActivatedRoute } from '@angular/router';
import { FactureproformaService } from '../services/factureproforma.service';
import { DevisService } from '../services/devis.service';
import { FactureService } from '../services/facture.devis';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {
  invoice: devismodel;
  displayedColumns: string[] = ['id','name', 'description', 'quantity', 'price'];
 // taxRate: number = 0.20; // Example tax rate of 20%
type:string
//tva=0.19
userId:string
id:string
  subtotal: number=0;
  total: number=0;
  currentDate: Date = new Date();

  constructor(private invoiceService: InvoiceService,private route: ActivatedRoute,private factproserv: FactureproformaService
    ,private devserv: DevisService,private factserv: FactureService) {}
    generatePDF() {
      const data = document.getElementById('invoicepdf')!;
    
      // Temporarily hide buttons using CSS class
      data.classList.add('hide-buttons');
      
      html2canvas(data, {
        allowTaint: true,
        useCORS: true
      }).then(canvas => {
        // Remove the CSS class after generating PDF
        data.classList.remove('hide-buttons');
    
        const imgWidth = 190;
        const pageHeight = 295;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const heightLeft = imgHeight;
    
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 10; // One inch margin
    
        pdf.addImage(canvas, 'PNG', 10, position, imgWidth, imgHeight);
        if(this.type=='facture-proforma')
        {
          pdf.save(`facture-proforma/_${this.invoice.number}`);
        }
       else if(this.type=='facture')
        {
          pdf.save(`facture/_${this.invoice.number}`);
        }
       else if(this.type=='devis')
        {
          pdf.save(`devis/_${this.invoice.number}`);
        }
      }).catch(error => {
        console.error('Error generating PDF:', error);
        // Remove the CSS class in case of an error
        data.classList.remove('hide-buttons');
      });
    }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('type');
      this.userId = params.get('user');
      this.id = params.get('id');

    }
    )
    this.fetchInvoiceDetails();
  }

  fetchInvoiceDetails(): void {
    if(this.type=='facturepro')
    {
      this.factproserv.getFactureproformadataById(this.id).then((data :devismodel)=> {
        this.invoice = data;
        this.calculateTotals();
      });
    }
    else  if(this.type=='facture')
    {
      this.factserv.getFacturedataById(this.id).then((data :devismodel)=> {
        this.invoice = data;
        this.calculateTotals();
      });    }
   else if(this.type=='devis')
    {
      this.devserv.getDevisdataById(this.id).then((data :devismodel)=> {
        this.invoice = data;
        this.calculateTotals();
      });
        }

  }

  calculateTotals(): void {
    let subtotal = 0;
    for (const item of this.invoice.itemsdetails) {
      subtotal += +item.quantity * +item.unitPrice;
    }
    const tva = subtotal * this.invoice.tva;
    const total = subtotal + tva;

    this.subtotal = subtotal;
    this.total = total;
  }
}
