import { Component, OnInit } from '@angular/core';
import { VersionService } from '../services/versions.service';
import { Versionmodel } from '../models/versions.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public version: Versionmodel;
  private versionSub: Subscription;

  constructor(private ver:VersionService) { }

  ngOnInit() {
    this.versionSub = this.ver.versions$.subscribe(
      (versions) => {
        this.version = versions[versions.length-1];
      },
      (error) => {
        
      }
    ); 
      this.ver.getversionalldata();
  }

}
