
<div class="div">
  <img loading="lazy" srcset="../../assets/taswira3.jpg" class="img" />
  <div class="text-content">
      Macompta est une agence virtuelle de conseil et d’assistance comptable.
      L’idée de création de cette agence avec toute sa philosophie est issue de
      notre souci de faire profiter nos clients des avantages des nouvelles
      technologies d’information et de communication, dont notamment la
      rapidité, l’efficacité, la fiabilité, la proximité, la minimisation du
      coût,
  </div>
</div>
<div class="divH">Pourqoui nous ?</div>

<div class="divM">
<div class="div-2M"></div>
<div class="div-3M"></div>
<div class="div-4M"></div>
</div>

<div class="divS">
<div class="div-2S">
  <div class="columnS">
      <div class="div-3S">
          <img loading="lazy" srcset="../../assets/easy_of_use.jpg" class="imgS" />
          <div class="div-4S">Simplicité d’utilisation</div>
          <div class="div-5S">
              Sans connaissances comptables, enregistrez vos données (factures,
              relevés bancaires, caisses, etc.) via une interface simple. Pour
              une facture de vente, saisissez seulement le client, la date, le
              montant HT, la TVA, le timbre et le montant TTC.
          </div>
      </div>
  </div>
  <div class="column-2S">
      <div class="div-7S">
          <img loading="lazy" srcset="../../assets/confidentialité.png" class="img-2S" />
          <div class="div-8S">Confidentialité</div>
          <div class="div-9S">
              Notre plateforme vous offre les meilleures garanties en terme de
              confidentialité et de respect des informations transmises.
          </div>
      </div>
  </div>
  <div class="column-3S">
      <div class="div-11S">
          <img loading="lazy" srcset="../../assets/istockphoto-1179348997-612x612.jpg" class="img-3S" />
          <div class="div-12S">Disponibilité</div>
          <div class="div-13S">
              L’agence virtuelle de Macompta est ouverte 7j/7 et 24h/24. En
              conséquence, la saisie de vos informations peut se faire à
              n’importe quel moment.
          </div>
      </div>
  </div>
</div>
</div>

<div class="divM">
<div class="div-2M"></div>
<div class="div-3M"></div>
<div class="div-4M"></div>
</div>



<div class="divS">
<div class="div-2S">
  <div class="columnS">
      <div class="div-3S">
          <img loading="lazy" srcset="../../assets/th.jpg" class="imgS" />
          <div class="div-4S">Dépôt à distance de vos déclarations fiscales</div>
          <div class="div-5S">
              Avec la plateforme Macompta, vous pouvez téléliquider toutes vos déclarations fiscales. Il vous suffit de nous autoriser
              à déclarer et payer en ligne, et nous nous occupons du reste.
          </div>
      </div>
  </div>
  <div class="column-2S">
      <div class="div-7S">
          <img loading="lazy" srcset="../../assets/t.jpg" class="img-2S" />
          <div class="div-8S">Rapidité</div>
          <div class="div-9S">
              Pas de temps à perdre ? Nous non plus. Notre plateforme traite les informations rapidement et avec fiabilité garantie.
          </div>
      </div>
  </div>
  <div class="column-3S">
      <div class="div-11S">
          <img loading="lazy" srcset="../../assets/t1.jpg" class="img-3S" />
          <div class="div-12S">Garde des documents pour  vous</div>
          <div class="div-13S">
          L’agence virtuelle de Macompta est ouverte 7j/7 et 24h/24. En conséquence, la saisie de vos informations peut se faire à
          n’importe quel moment.
          </div>
      </div>
  </div>
</div>
</div>

<div class="divM">
<div class="div-2M"></div>
<div class="div-3M"></div>
<div class="div-4M"></div>
</div>