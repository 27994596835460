<div class="container-fluid">
  <div class="row justify-content-center">
    <mat-card class="col-md-3 feature-card feature-background-1" (mouseenter)="hoverFeature = 'decfiscmenss'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser.role=='admin'">
      <mat-card-header>
        <button mat-button (click)="collapse1 = !collapse1">
          <mat-icon>assignment</mat-icon>
Formulaire d'ajout d'actualités        
</button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse1">
      <form  [formGroup]="carouselform">
          <h2>Formulaire d'ajouts d'actualités</h2>
         
          
          
      
            <div title="indiquer titre actualité"class="input-container">
              <input type="text" class="form-control" formControlName="titre" id="titre" placeholder=" Entrez titre">
            </div> 
            <div title="indiquer commentaire"class="input-container">
              <input type="text" class="form-control" formControlName="commentaire" id="commentaire" placeholder=" Entrez commentaire">
            </div> 
             <div title="indiquer description"class="input-container">
              <textarea  class="form-control" rows="10" cols="50"id="description" formControlName="description"placeholder="Entrez description"></textarea>
            </div>
            <div title="indiquer rang"class="input-container">
              <input type="number" class="form-control" formControlName="rang" id="rang" placeholder=" Entrez rang">
            </div> 
            <div class="form-group">
              <label for="inputCV">Attacher votre document joint</label>                   
             <input type="file"  #filePicker (change)="onImagePick($event)" class="form-control" accept="application/pdf">
             <div class="image-preview" *ngIf="imagePreview">
               <img [src]="imagePreview" alt="">
             </div>
             <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
            
         </div>
           
          
        
          <button class="btn btn-success" (click)="saveactualite()"><i class="fa fa-spinner"*ngIf="loading"></i>Ajouter Actualité</button>
      </form>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'decfiscmenss'">
        <p>Ajouter des nouvelles actualités.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-2" (mouseenter)="hoverFeature = 'deccomptabilites'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser.role=='admin'">
      <mat-card-header>
        <button mat-button (click)="collapse2 = !collapse2">
          <mat-icon>folder</mat-icon>
Formulaire d'ajouts d'utilisateurs
        </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse2">
        <fieldset>
          <legend> Insérer plusieurs utlisateurs</legend>
          <div class="form-group row">
            <label class="col-sm-12 col-form-label"> Charger document</label>
              <div class="col-sm-12">
                <input type="file"  class="form-control inputFile" accept=".xls, .xlsx"(change)="onFileChange($event);">
              </div>
          </div>
          <button class="btn btn-success" (click)="afficher()"> <i class="fa fa-spinner" *ngIf="loading"></i>Valider</button>
        </fieldset>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'deccomptabilites'">
        <p>ajouter les utilisateurs en masse.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-3" (mouseenter)="hoverFeature = 'demandesDeServices'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser.role=='admin'">
      <mat-card-header>
        <button mat-button (click)="collapse3 = !collapse3">
          <mat-icon>assignment_turned_in</mat-icon>
          Afficher le formulaire d'ajouts de tarifs
        </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse3">
        <form [formGroup]="tarifform">
          <button type="button" class="visually-hidden" (click)="addammount()">Ajouter Tarif</button>
      <fieldset formArrayName="ammounts" 
        *ngFor="let ammount of ammountControls; let i = index;">
        <div [formGroupName]="i">
          <div class="row">
            <div class="col-6">
                <h4>Tarif {{i + 1}}</h4>
                <div class="col-6 text-right">
                  <button type="button" class="visually-hidden" (click)="removeammount(i)">Supprimer Tarif</button>
              </div>
            </div>
            <div class="container-fluid">
             <div class="col-6">
               <div class="form-group">
                   <label>Type Tarif</label>
                   <select  class="input-field" placeholder="Entrer type tarif" id="activity" style="width: 150px;"formControlName="type" name="type"(change)="onchangetypetarif(i,$event)" >
                     <option value=""></option>
                     <option *ngFor="let typ of type" [value]="typ">
                       {{typ}}
                   </option>
                   </select>
               </div>
           </div>
            
              <div class="col-6">
               <div class="form-group">
                   <label>Date Début</label>
                   <input type="date" class="form-control" formControlName="debut" placeholder="date début"/>
                 </div>
           </div>
           <div class="col-6">
             <div class="form-group">
                 <label>Date Fin</label>
                 <input type="date" class="form-control" formControlName="fin" placeholder="date fin"/>
               </div>
         </div>
         <div class="col-6">
           <div class="form-group">
               <label>Utilisateur</label>
               <select class="input-field" placeholder="Entrer user" id="activity" style="width: 150px;"formControlName="user" (click)="sort()"name="user">
                 <option></option>
                 <option *ngFor="let us of filtredusers" [value]="us._id">{{us.clientcode}} <span *ngIf="us.nature=='Personne Physique'">{{ us.firstname }} &nbsp; {{ us.lastname }}</span> &nbsp; <span *ngIf="us.nature=='Personne Morale'">{{ us.raisonsociale }}</span></option>    
               </select>
           </div>
       </div>
         <div class="col-6">
           <div class="form-group">
               <label>Nature</label>
               <select  class="input-field" placeholder="Entrer nature" id="activity" style="width: 150px;"formControlName="nature" (click)="sort()"name="nature" >
                 <option value=""></option>
                 <option *ngFor="let natur of nature" [value]="natur">
                   {{natur}}
               </option>
               </select>
           </div>
       </div>
       <div class="col-6">
         <div class="form-group">
             <label>Nature Activité</label>
             <select  class="input-field" placeholder="Entrer nature activité" id="activity" style="width: 150px;"formControlName="natureactivite" (click)="sort()"name="natureactivite" >
               <option value=""></option>
               <option *ngFor="let natureact of natureactivite" [value]="natureact">
                 {{natureact}}
             </option>
             </select>
         </div>
     </div>
     <div class="col-6">
       <div class="form-group">
           <label>Activité</label>
           <select  class="input-field" placeholder="Entrer activité" id="activity" style="width: 150px;"formControlName="activite" (click)="sort()"name="activite" >
             <option value=""></option>
             <option *ngFor="let act of activite" [value]="act">
               {{act}}
           </option>
           </select>
       </div>
   </div>
   <div class="col-6">
     <div class="form-group">
         <label>Sous Activité</label>
         <select  class="input-field" placeholder="Entrer sous activité" id="activity" style="width: 150px;"formControlName="sousactivite" (click)="sort()"name="sousactivite" >
           <option value=""></option>
           <option *ngFor="let sousact of sousactivite" [value]="sousact">
             {{sousact}}
         </option>
         </select>
     </div>
 </div>
 <div class="col-6">
   <div class="form-group">
       <label>Régime impôt</label>
       <select  class="input-field" placeholder="Entrer regime impot" id="activity" style="width: 150px;"formControlName="impot" name="impot" >
         <option value=""></option>
         <option *ngFor="let regimpot of regimeimpot" [value]="regimpot">
           {{regimpot}}
       </option>
       </select>
   </div>
 </div>
     <div class="col-6">
       <div class="form-group">
           <label>Prix</label>
           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
           class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="prix" placeholder="prix"/>
         </div>
   </div>
            </div>
          </div>
        </div>
          </fieldset>
        </form>
        <button class="btn btn-success" (click)="savetarif()"><i class="fa fa-spinner"*ngIf="loading"></i>Ajouter tarif</button>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'demandesDeServices'">
        <p>Gérez les tarifs de la plateforme.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-4" (mouseenter)="hoverFeature = 'factures'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser.role=='admin'">
      <mat-card-header>
        <button mat-button (click)="collapse4 = !collapse4">
          <mat-icon>receipt</mat-icon>
          Afficher le formulaire d'ajouts des relations
        </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse4">
        <fieldset>
          <legend> Insérer plusieurs relations</legend>
          <div class="form-group row">
            <label class="col-sm-12 col-form-label"> Charger document</label>
              <div class="col-sm-12">
                <input type="file"  class="form-control inputFile" accept=".xls, .xlsx"(change)="onFileChange2($event);">
              </div>
          </div>
          <button class="btn btn-success" (click)="afficher2()"> <i class="fa fa-spinner" *ngIf="loading"></i>Valider</button>
        </fieldset>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'factures'">
        <p>Gérer les relations dans la plateforme.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-5" (mouseenter)="hoverFeature = 'candidatures'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser.role=='admin'">
      <mat-card-header>
        <button mat-button (click)="collapse5 = !collapse5">
          <mat-icon>people</mat-icon>
          Afficher le formulaire d'envoi des sms
                </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse5">
        <form  [formGroup]="smsform">
          <h2>Formulaire d'envoi des sms</h2>
             <div title="indiquer description"class="input-container">
              <textarea  class="form-control" rows="10"maxlength="157" cols="50"id="description" formControlName="description"placeholder="Entrez description"></textarea>
            </div>
          <button class="btn btn-success" (click)="sendsms()"><i class="fa fa-spinner"*ngIf="loading"></i>Envoyer SMS</button>
      </form>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'candidatures'">
        <p>Gérez le formulaire d'envoi de SMS.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-6" (mouseenter)="hoverFeature = 'reclamations'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser.role=='admin'">
      <mat-card-header>
        <button mat-button (click)="collapse6 = !collapse6">
          <mat-icon>report_problem</mat-icon>
          Afficher le formulaire de paramêtrage des autres DS
        </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse6">
        <table class="table table-striped" id="cop section" [formGroup]="otherdsform">
          <thead>
            <tr> 
              <th scope="col">Libéllé</th>
              <th scope="col">Durée</th>
              <th scope="col">Soumis/Non soumis</th>

            </tr>
            </thead>
            <tbody formArrayName="ammounts3">
                <tr *ngFor="let ammount of ammountControls3; let i = index;"[formGroupName]="i">
               
                  <td>
                    <div class="col-6">
                        <input type="text"class="form-control"formControlName="libelle"placeholder="libelle"/>
                    </div>
                   </td>
                  <td >
                    <div class="col-6">
                      <input type="text"class="form-control"formControlName="duree"placeholder="durée"/>
                    </div>
                  </td>
                  <td >
                    <div class="col-6">
                      <input type="checkbox"formControlName=soumis/>
                    </div>
                  </td>
                  <td>
                          <div>
                          <button class="btn btn-danger btn-sm"(click)="removeammount3(i)"><span><i class="fa fa-minus-circle"></i><i class="fa fa-spinner" *ngIf="deleting"></i></span></button>   
                          </div>
                  </td>  
                  <td>
                    <div>
                    <button class="btn btn-primary mb-2" (click)="addammount3()"><span><i class="fa fa-plus-circle"></i></span></button>   
                    </div>
                   </td>    
                </tr>

            </tbody>
           
        </table>
        <button class="btn btn-success" (click)="submitotherds()"><i class="fa fa-spinner"*ngIf="submitted"></i>Valider</button>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'reclamations'">
        <p>Gérez les autres DS à paramêtrer.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-7" (mouseenter)="hoverFeature = 'candidatures'" (mouseleave)="hoverFeature = ''">
      <mat-card-header>
        <button mat-button (click)="collapse7 = !collapse7">
          <mat-icon>people</mat-icon>
          Afficher le formulaire de clôture des exercices
                </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse7">
        <form [formGroup]="exerciceform">
          <fieldset formArrayName="ammounts5" *ngFor="let ammount of ammountControls5; let i = index;">
            <div [formGroupName]="i">
              <div class="row">
                <div class="container-fluid">
                 <div class="col-6">
                   <div class="form-group"*ngIf="i==0">
                       <label>Sélectionner client</label>
                       <select  class="input-field" placeholder="Entrer client" id="activity" style="width: 150px;"formControlName="client" name="client"(change)="onchangeclient(i,$event)" >
                         <option value=""></option>
                         <option *ngFor="let us of filtredusers" [value]="us._id">
                           {{us.clientcode}} <span *ngIf="us.nature=='Personne Physique'">{{ us.firstname }} &nbsp; {{ us.lastname }}</span> &nbsp; <span *ngIf="us.nature=='Personne Morale'">{{ us.raisonsociale }}</span>
                       </option>
                       </select>
                   </div>
               </div>
                  <div class="col-6">
                   <div class="form-group">
                     <label>Choisir année</label>
                     <select  class="input-field" placeholder="Entrer client" id="activity" style="width: 150px;"formControlName="annee" name="annee">
                       <option value=""></option>
                       <ng-container *ngIf="exerciceform.get('ammounts5').value.at(0).client=='63c1dd07f2fac0831ec0cd7c'">
                         <option *ngFor="let an of anneesmbs" [value]="an" >{{an}}</option>           
                       </ng-container>  
                       <option *ngFor="let an of annees" [value]="an">{{an}}</option>
                     </select>
                 </div>
               </div>

               <div class="col-6" style="display: flex;">
                <div class="form-group">
                  <label>Du mois</label>
                  <select  class="input-field" placeholder="Entrer client" id="activity" style="width: 100px;"formControlName="moisdebut" name="annee">
                    <option value=""></option>
                    <option *ngFor="let mo of mois" [value]="mo">{{mo}}</option>
                  </select>
              </div>
              &nbsp;&nbsp;
              <div class="form-group">
                <label>jusqu'au mois</label>
                <select  class="input-field" placeholder="Entrer client" id="activity" style="width: 100px;"formControlName="moisfin" name="annee">
                  <option value=""></option>
                  <option *ngFor="let mo of mois" [value]="mo">{{mo}}</option>
                </select>
            </div>
            </div>
               <div class="col-6"style="width:20px">
                 <div class="form-group">
                     <label>statut (clôturé/ouvert)</label>
                     <input type="checkbox"formControlName=cloture/>
                   </div>
             </div>
             <div class="col-6">
               <div class="col-6 text-right">
                 <button type="button" class="visually-hidden" (click)="removeammount5(i)"><span><i class="fa fa-minus-circle"></i></span>Supprimer exercice clôturé</button>
             </div>
           </div>
                </div>
              </div>
            </div>
              </fieldset>
              <button type="button" class="btn btn-success mb-2" (click)="addammount5()"><span><i class="fa fa-plus-circle"></i></span>Ajouter exercice clôturé</button>   
            </form>
            <button class="btn btn-success" (click)="savecloture(exerciceform.get('ammounts5').value.at(0).client)"><i class="fa fa-spinner"*ngIf="loading"></i>Valider</button>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'candidatures'">
        <p>Gérez les exercices à clôturer.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-8" (mouseenter)="hoverFeature = 'reclamations'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser._id=='63c1da3edb6508d49b57a4de'">
      <mat-card-header>
        <button mat-button (click)="collapse8 = !collapse8">
          <mat-icon>report_problem</mat-icon>
          Afficher le formulaire de geston des versions
        </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse8">
        <p>{{version.first}}.{{version.second}}.{{version.third}}</p>
        <p>{{version.created | customDate}}</p>
       
        <div class="input-container" >
         <input type="number"[(ngModel)]="firstnumber"style="background-color: lightgrey;">
         <input type="number"[(ngModel)]="secondnumber"style="background-color: lightgrey;">
         <input type="number"[(ngModel)]="thirdnumber"style="background-color: lightgrey;">
       </div>
       <button class="btn btn-success" (click)="savenewversion()"><i class="fa fa-spinner"*ngIf="loading"></i>créer version</button>
      </mat-card-content>
      <mat-card-footer *ngIf="hoverFeature === 'reclamations'">
        <p>Gérez les versions du logiciel.</p>
      </mat-card-footer>
    </mat-card>

    <mat-card class="col-md-3 feature-card feature-background-9" (mouseenter)="hoverFeature = 'candidatures'" (mouseleave)="hoverFeature = ''"*ngIf="currentUser.role=='admin'">
      <mat-card-header>
        <button mat-button (click)="collapse9 = !collapse9">
          <mat-icon>people</mat-icon>
          Gestion calendrier fiscale
                </button>
      </mat-card-header>
      <mat-card-content *ngIf="collapse9">
        <a routerLink="calendar-fiscality" routerLinkActive="active"> Accéder au calendrier fiscale</a>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<h2> Différentes rubriques de paramêtrage</h2>
<mat-tab-group (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Paramêtrage des autres DS"*ngIf="currentUser.role=='admin'">      
    <table class="table table-striped" id="cop-section" [formGroup]="existentotherdsform">
      <thead>
        <tr> 
          <th scope="col">Libéllé</th>
          <th scope="col">Durée</th>
          <th scope="col">Soumis/Non soumis</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody formArrayName="ammounts4">
        <tr *ngFor="let ammount of ammountControls4; let i = index;" [formGroupName]="i">
          <td>
            <input type="text" class="form-control" formControlName="libelle" placeholder="Libéllé"/>
          </td>
          <td>
            <input type="text" class="form-control" formControlName="duree" placeholder="Durée"/>
          </td>
          <td class="text-center">
            <input type="checkbox" formControlName="soumis"/>
          </td>
          <td class="text-center">
            <button class="btn btn-danger btn-sm" (click)="removeammount4(i, existentotherdsform.getRawValue().ammounts4[i].id)">
              <i class="fa fa-minus-circle"></i>
              <i class="fa fa-spinner" *ngIf="deleting"></i>
            </button>
            <button class="btn btn-primary btn-sm" (click)="modifyammount4(i, existentotherdsform.getRawValue().ammounts4[i].id)">
              <i class="fa fa-wrench"></i>
              <i class="fa fa-spinner" *ngIf="updating"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    
  </mat-tab>

  <mat-tab label="Paramêtrage des actualités"*ngIf="currentUser.role=='admin'">      
    <table class="table table-striped">
      <thead>
      <tr>
  
        
        <th scope="col">Titre</th>
        <th scope="col">Commentaire</th>
        <th scope="col">rang</th>
        <th scope="col">Document joint</th>
        <th scope="col">Consultation</th>
        <th scope="col">Modification</th>
        <th scope="col">Suppression</th>

        
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let carousel of actualites">
  
        
        <td>{{carousel.titre}}</td>
        <td>{{carousel.commentaire}}</td>
        <td>{{carousel.rang}}</td>
        <td><a href="{{carousel.ficheUrl}}" target="_blank">Aperçu document joint</a></td>
        <td><button type="button" class="btn btn-success" (click)="getNavigation('view-carousel',carousel._id)">Consulter</button></td>
        <td><button type="button" class="btn btn-primary" (click)="getNavigation('modify-carousel',carousel._id)">Modifier</button></td>
        <td><button type="button" class="btn btn-danger" (click)="onDelete(carousel._id)">Supprimer</button></td>          
      </tr>
      </tbody>
    </table>
  </mat-tab>

  <mat-tab label="Paramêtrage des tarifs"*ngIf="currentUser.role=='admin'">      
    <table class="table table-striped">
      <thead>
      <tr>
  
        
        <th scope="col">Type tarif</th>
        <th scope="col">Date début</th>
        <th scope="col">Date fin</th>
        <th scope="col">Utilisateur</th>
        <th scope="col">Nature utilisateur</th>
        <th scope="col">Nature activité</th>
        <th scope="col">Activité</th>
        <th scope="col">Sous-activité</th>
        <th scope="col">Régime impôt</th>
        <th scope="col">Prix</th>
        
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let carousel of tarifs">
        <td>{{carousel.tarifs[0].type}}</td>
        <td>{{carousel.tarifs[0].debut}}</td>
        <td>{{carousel.tarifs[0].fin}}</td>
        <td> <span *ngIf="carousel.tarifs[0].type=='Tarif individuel'&& natureclient=='Personne Physique'">{{filteruser(carousel.tarifs[0].user)}} {{codeclient}}&nbsp;{{ nom }}&nbsp;{{prenom}}</span> <span *ngIf="carousel.tarifs[0].type=='Tarif individuel'&& natureclient!='Personne Physique'">{{filteruser(carousel.tarifs[0].user)}} {{codeclient}}&nbsp;{{ raisonsociale }}</span></td>
        <td>{{carousel.tarifs[0].nature}}</td>
        <td>{{carousel.tarifs[0].natureactivite}}</td>
        <td>{{carousel.tarifs[0].activite}}</td>
        <td>{{carousel.tarifs[0].sousactivite}}</td>
        <td>{{carousel.tarifs[0].impot}}</td>
        <td>{{carousel.tarifs[0].prix}}</td>
        <td><button type="button" class="btn btn-success" (click)="getNavigation('view-carousel',carousel._id)">Consulter</button></td>
        <td><button type="button" class="btn btn-primary" (click)="getNavigation('modify-carousel',carousel._id)">Modifier</button></td>
        <td><button type="button" class="btn btn-danger" (click)="onDelete(carousel._id)">Supprimer</button></td>          
      </tr>
      </tbody>
    </table>
  </mat-tab>

  <mat-tab label="Paramêtrage des relations"*ngIf="currentUser.role=='admin'">      
    <button nbButton outline status="danger"*ngIf="currentUser.role=='admin'" (click)="deleteall()" class="btn btn-danger">Supprimer Tous</button>
    <table class="table table-striped">
      <thead>
      <tr>
  
        
        <th scope="col">Prénom</th>
        <th scope="col">Nom</th>
        <th scope="col">mobile</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let relation of relations"> 
        <td>{{relation.firstname}}</td>
        <td>{{relation.lastname}}</td>
        <td>{{relation.mobile}}</td>
        <td><button type="button" class="btn btn-success" (click)="getNavigation('view-relation',relation._id)">Consulter</button></td>
        <td><button type="button" class="btn btn-primary" (click)="getNavigation('modify-relation',relation._id)">Modifier</button></td>
        <td><button type="button" class="btn btn-danger" (click)="onDelete(relation._id)">Supprimer</button></td>          
      </tr>
      </tbody>
    </table>
  </mat-tab>

  <mat-tab label="Paramêtrage des cours de change">
    <form [formGroup]="searchForm">
      <fieldset>
        <legend>Filtrer les cours de change</legend>
        <div class="row g-3 mt-2">
          <div class="col-md-6">
            <label for="dateinf">Supérieur à</label>
            <input class="form-control" id="dateinf" formControlName="dateinf" type="date" placeholder="Chercher par Email">
          </div>
          <div class="col-md-6">
            <label for="datesup">Inférieur à</label>
            <input class="form-control" id="datesup" formControlName="datesup" type="date" placeholder="Chercher par Email">
          </div>
          <div class="col-md-3 mt-3">
            <button class="btn btn-secondary btn-block" (click)="filtercours()">
              <i class="fa fa-search"></i> Chercher
            </button>
          </div>
        </div>
      </fieldset>
    </form>
    
    <table class="table table-striped mt-3" id="relevejointsection" [formGroup]="coursform">
      <thead>
        <tr>
          <th scope="col">Date cours</th>
          <th scope="col">USD</th>
          <th scope="col">Euro</th>
          <th scope="col">GBP</th>
          <th scope="col">DZD</th>
          <th scope="col">SAR</th>
          <th scope="col">CAD</th>
          <th scope="col">DKK</th>
          <th scope="col">JPY</th>
          <th scope="col">MAD</th>
          <th scope="col">NOK</th>
          <th scope="col">SEK</th>
          <th scope="col">CHF</th>
          <th scope="col">KWD</th>
          <th scope="col">AED</th>
          <th scope="col">LYD</th>
          <th scope="col">MRU</th>
          <th scope="col">BHD</th>
          <th scope="col">QAR</th>
          <th scope="col">CNY</th>
          <th scope="col" colspan="2">Actions</th>
        </tr>
      </thead>
      <tbody formArrayName="ammounts2">
        <tr *ngFor="let ammount of ammountControls2; let i = index;" [formGroupName]="i">
          <td>
            <input type="date" class="form-control" formControlName="jour" placeholder="Jour" (change)="checkdate(i)" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="dollar" placeholder="cours dollar" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="euro" placeholder="cours euro" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="gbp" placeholder="cours GBP" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="dzd" placeholder="cours DZD" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="sar" placeholder="cours SAR" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="cad" placeholder="cours CAD" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="dkk" placeholder="cours DKK" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="jpy" placeholder="cours JPY" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="mad" placeholder="cours MAD" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="nok" placeholder="cours NOK" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="sek" placeholder="cours SEK" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="chf" placeholder="cours CHF" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="kwd" placeholder="cours KWD" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="aed" placeholder="cours AED" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="lyd" placeholder="cours LYD" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="mru" placeholder="cours MRU" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="bhd" placeholder="cours BHD" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="qar" placeholder="cours QAR" />
          </td>
          <td>
            <input type="text" class="form-control" appFourDigitDecimalNumber (blur)="setFourNumberDecimal($event)" formControlName="cny" placeholder="cours CNY" />
          </td>
          <td style="display: block;"> 
            <div class="col-6"style="width: 300px">
               <input type="file"  #filePicker (change)="onFileChange3($event,i)" class="form-control" accept=".xls, .xlsx">
               <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
           </div>
         </td>
          <td>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-danger btn-sm" *ngIf="i > 0" (click)="removeammount2(i)">
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="20"></td>
          <td class="text-center">
            <button type="button" class="btn btn-primary mb-2" (click)="addammount2()">
              <i class="fa fa-plus-circle"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    
    <div class="text-right mt-3">
      <button class="btn btn-success" (click)="savecours()">
        <i class="fa fa-spinner" *ngIf="loading"></i> Valider
      </button>
    </div>
    
    <table class="table table-striped mt-3">
      <thead>
        <tr>
          <th>Date cours</th>
          <th>USD</th>
          <th>Euro</th>
          <th>GBP</th>
          <th>DZD</th>
          <th>SAR</th>
          <th>CAD</th>
          <th>DKK</th>
          <th>JPY</th>
          <th>MAD</th>
          <th>NOK</th>
          <th>SEK</th>
          <th>CHF</th>
          <th>KWD</th>
          <th>AED</th>
          <th>LYD</th>
          <th>MRU</th>
          <th>BHD</th>
          <th>QAR</th>
          <th>CNY</th>
          <th colspan="2">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cour of filtredcours; let k = index">
          <td>{{ cour.jour | customDate }}</td>
          <td>{{ cour.dollar }}</td>
          <td>{{ cour.euro }}</td>
          <td>{{ cour.gbp }}</td>
          <td>{{ cour.dzd }}</td>
          <td>{{ cour.sar }}</td>
          <td>{{ cour.cad }}</td>
          <td>{{ cour.dkk }}</td>
          <td>{{ cour.jpy }}</td>
          <td>{{ cour.mad }}</td>
          <td>{{ cour.nok }}</td>
          <td>{{ cour.sek }}</td>
          <td>{{ cour.chf }}</td>
          <td>{{ cour.kwd }}</td>
          <td>{{ cour.aed }}</td>
          <td>{{ cour.lyd }}</td>
          <td>{{ cour.mru }}</td>
          <td>{{ cour.bhd }}</td>
          <td>{{ cour.qar }}</td>
          <td>{{ cour.cny }}</td>
       
          <td>
            <button type="button" class="btn btn-danger btn-sm" (click)="remove(cour.jour)">
              <i class="fa fa-minus-circle"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
            
  </mat-tab>
</mat-tab-group>