import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '../models/user.model';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { Decfiscmens } from '../models/dec-fisc-mens';
import Swal from 'sweetalert2';
import { ExcelService } from '../services/generate.excel.service';
import * as logoFile from '../_helpers/declogo';
//import pdfMake from "pdfmake/build/pdfmake";  
//import pdfFonts from "pdfmake/build/vfs_fonts";  
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//import {saveAs} from 'file-saver';
//import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { interval, Subscription } from 'rxjs';
import { Sort } from '../_helpers/sort';
import { CommunService } from '../services/commun';
import { CarouselService } from '../services/settings';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { UsedressourceService } from '../services/usedressource.service';
import { Usedressourcemodel } from '../models/usedressource.model';
import { ComponentCanDeactivate } from '../services/component-can-deactivate';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

//pdfMake.vfs = pdfFonts.pdfMake.vfs; 

@Component({
  selector: 'app-view-dec-fisc-mens',
  templateUrl: './view-dec-fisc-mens.component.html',
  styleUrls: ['./view-dec-fisc-mens.component.scss']
})
export class ViewDecFiscMensComponent extends ComponentCanDeactivate implements OnInit,OnDestroy {
  totalfodec: number=0;
  totaltpe: number=0;
  totalfodecbase: number=0;
  totaltpebase: number=0;
  cabase: number=0;
  camontant: number=0;
  caexportbase: number=0;
  caexportmontant: number=0;
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.ngOnDestroy()
}
maincontainer=false;
  arrayBuffer:any;
  file:File;
  annee: string;
  sousactivite: string;
  showgenerate=false
  collab: any[]=[];
  private usersSub: Subscription;
  users: User[]=[];
  autretvacollecte=0.000;
  htsum=0.000;
  tvasum=0.000;
  ht19sum=0.000;
  tva19sum=0.000;
  ht13sum=0.000;
  tva13sum=0.000;
  htsussum=0.000;
  tvasussum=0.000;
  htexosum=0.000;
  tvaexosum=0.000;
  htexpsum=0.000;
  tvaexpsum=0.000;
  prenomcollab: any;
  nomcollab: any;
  filtredcollab: any[]=[];
  allstatuts: any[]=[];
  sortedallstatuts: any[]=[];
  public countdown=0
  private intervalSub: Subscription;
  selected: any;
  statusadmin: string[]=[];
  totaltime: number;
  showbuttons= false;
  settingsSub: Subscription;
  settings: any []=[];
  tarifs: any []=[];
  tarifsbase: any []=[];
  tarifsclient: any []=[];
  tarifsspecial: any []=[];

  prixminute: any;
  totalhonoraire=0.000;
  prixminuteindiv: string;
  prixminutespecial: string;

  prixminutepref: any;
  deccomptabilite: Deccomptabilite;
  @Input() user: User;
  firsttimer: number;
  statutsclients: any=[];
  @Input() natureactivite: string;
  @Input() decfisconly: string;

  decfiscmenssSub: Subscription;
  incomingfile(event) 
    {
    this.file= event.target.files[0]; 
    }
public decfiscmens=new Decfiscmens;
  public errormsg:string;
  loading=false;
  public tfpapayer: any
  public tfpareporter: any
  public statut: any
  public motif: any
  public activite:string
  currentUser: any;
  spreadBackColor = 'aliceblue';
  hostStyle = {
    width: '95vw',
    height: '80vh'
  };
  public optionValue:any
  public option71Value=0.000
  public option72Value=0.000
  public option64Value:any
  public honorairesum=0.000
  public honoraireretenue=0.000
  public type1:any
  public type2:any
  public type3:any
  public type4:any
  public type5:any
  public type6:any
  public type7:any

  foprolosapayer=0.000
  tfpapayertotal=0.000
  tfpareportertotal=0.000
  totalretenueammount=0.000;
  totaltfpammount=0.000;
  totalfoprolosammount=0.000;
  totalreporttvaammount=0.000;
  preptotaltvaammount=0.000;
  totaltvaammount=0.000;
  totaltimbreammount=0.000;
  totaltclammount=0.000;
  totalfspammount=0.000;
  totaldeclaration=0.000;
  minimumperceptionammount=0.000;
  preptotaldeclaration=0.000;
  prepminimumperceptionammount=0.000;
  tvacollecte=0.000
  tvacollecte19=0.000
  tvacollecte1=0.000
  tvacollecte2=0.000
  tvacollecte3=0.000
  tvacollecte4=0.000
  tvacollecte5=0.000
  tvacollecte6=0.000
  tvarecuperable=0.000
  autreform: FormGroup;
  currentdate=new Date()
  date1=new Date('04.01.2023')
  tva19=false;
  tvasuspens=false;
  tvaexonere=false;
  tvaexport=false;
  tva7=false;
  tva13=false;
  tvafodec=false;
  tvatpe=false;

  constructor(private router: Router,private scroller: ViewportScroller,
    private route: ActivatedRoute,private usedres: UsedressourceService,
    private dec: DecfiscmensService,    private deccompt: DeccomptabiliteService,

    private token: TokenStorageService,private commun: CommunService,
    private excelService: ExcelService,
    private userservice: UserService,private settservice: CarouselService,
    ){
      super()
    } 
    verifyexercicecloture(annee:string,mois:string):boolean
    {
      let show=false
      let filtredmois=[]
      filtredmois=this.commun.filterMois(annee,this.user)
      filtredmois.find(e=>e.value==mois)?show=false:show=true
      return show
    }
  ngOnInit() {
    this.loading = true;
    this.statusadmin=this.commun.statusadmin
    this.currentUser = this.token.getUser();
    console.log(this.currentUser)
  if(this.currentUser.role==='admin'||this.currentUser.role==='supervisor')
  {
    this.intervalSub=interval(1000).subscribe(value => this.countdown=value), this.countdown=0
    this.firsttimer=Date.now()
    this.userservice.getAll()
    this.usersSub = this.userservice.users$.subscribe(
      (users) => {
        this.users = users;
        this.loading = false;
        let filtred=[]
        filtred=this.deccompt.filterByValue(this.users,'desactive')
        this.collab=filtred.filter((user) => user.usertype === ('Collaborateur'||'collaborateur'))
        },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );  
  }
  if(this.router.url.includes('view-decfiscmens'))
{
  this.token.saved=false
  this.route.params.subscribe(
    (params: Params) => {
      this.dec.getDecfiscmensreqById(params.id).then(
        (decfiscmens: Decfiscmens) => {
        
          this.userservice.getUserById(decfiscmens.userId).then(
            (user: User) => {
              this.user=user
              this.natureactivite=user.natureactivite
              this.decfisconly='oui'
              this.user.tvaaffiche.find(el=>el.tauxtva=='7'&&el.affiche=='oui')?this.tva7=true:''
              this.user.tvaaffiche.find(el=>el.tauxtva=='13'&&el.affiche=='oui')?this.tva13=true:''
              this.user.tvaaffiche.find(el=>el.tauxtva=='19'&&el.affiche=='oui')?this.tva19=true:''
              this.user.tvaaffiche.find(el=>el.tauxtva=='suspension'&&el.affiche=='oui')?this.tvasuspens=true:''
              this.user.tvaaffiche.find(el=>el.tauxtva=='exonere'&&el.affiche=='oui')?this.tvaexonere=true:''
              this.user.tvaaffiche.find(el=>el.tauxtva=='export'&&el.affiche=='oui')?this.tvaexport=true:''
              user.tvaaffiche.find(el=>el.tauxtva=='fodec'&&el.affiche=='oui')?this.tvafodec=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='tpe'&&el.affiche=='oui')?this.tvatpe=true:''
              this.usedres.getUsedressourcedataById(decfiscmens._id).then(
                (usedres:Usedressourcemodel)=>
                      {
                        if(usedres&&usedres.connecteduser!=this.token.getUser().userId)
                        {
                return (Swal.fire({
                  title: `fichier en cours d\'utilisation par  ${usedres.firstname} ${usedres.lastname}`,
                  icon: 'error',
                  confirmButtonColor: '#3085d6',
                }).then((result) => {
                  this.router.navigate(['home'])
                  this.loading=false
                }).catch(() => {
                  Swal.fire('opération non aboutie!')
                })) 
              }
              else if(!usedres)
              {
                const newusedres:Usedressourcemodel =new Usedressourcemodel()
                newusedres.connecteduser=this.token.getUser().userId
                newusedres.ressourceid=decfiscmens._id
                newusedres.firstname=this.token.getUser().Firstname
                newusedres.lastname=this.token.getUser().Lastname
                newusedres.raisonsociale=this.token.getUser().raisonsociale
                newusedres.firstnameclient=decfiscmens.firstname
                newusedres.lastnameclient=decfiscmens.lastname
                newusedres.mois=decfiscmens.mois
                newusedres.annee=decfiscmens.annee
                newusedres.type='déclaration fiscale'
                this.usedres.create(newusedres).then(
                  (usedres:Usedressourcemodel)=>
                        {
              
                        }
                )
              }
                      }
              )
              this.dec.getdecfiscmens(decfiscmens.userId)
              this.decfiscmenssSub = this.dec.decfiscmenss$.subscribe(
                (decfiscmenss) => {
                  decfiscmenss.find(decfis=>decfis.impottype5.type?this.type5=this.decfiscmens.impottype5.type:'')
                },
                (error) => {
                  this.loading = false;
                  
                  this.errormsg=error.message;
                }
              );
              this.allstatuts=[]
              const sort = new Sort();
              this.loading = false;
              this.decfiscmens = decfiscmens;
              console.log(this.decfiscmens)
              this.activite=this.decfiscmens.activite
              this.sousactivite=this.decfiscmens.sousactivite
              this.tfpapayer=this.decfiscmens.impottype3.tfppayer
              this.tfpareporter=this.decfiscmens.impottype3.tfpreporter
              this.annee=this.decfiscmens.annee
              this.type1=this.decfiscmens.impottype1.type
              this.type2=this.decfiscmens.impottype2.type
              this.type3=this.decfiscmens.impottype3.type
              this.type4=this.decfiscmens.impottype4.type
              this.type5=this.decfiscmens.impottype5.type
              this.type6=this.decfiscmens.impottype6.type
              if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
              this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
              ||this.activite=='Sage-femmes' )
              {
                this.type7=this.decfiscmens.impottype7.type 
              }          
              if(decfiscmens.annee=='2023'&&this.activite=='Architectes'||decfiscmens.annee=='2023'&&this.activite=='Syndic des copropriétaires'||decfiscmens.annee=='2023'&&this.activite=='Ingénieurs-conseil'
              ||decfiscmens.annee=='2023'&&this.activite=='Dessinateurs'||decfiscmens.annee=='2023'&&this.activite=='Géomètres'
              ||decfiscmens.annee=='2023'&&this.activite=='Topographes'||decfiscmens.annee=='2023'&&this.activite=='Notaire'||
              decfiscmens.annee=='2023'&&this.activite=='Huissiers notaire'||decfiscmens.annee=='2023'&&this.activite=='Interprètes'||
              decfiscmens.annee=='2023'&&this.activite=='Expert'||decfiscmens.annee=='2023'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
              {
                this.tva19=true
                this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000

              }
              else if(decfiscmens.annee=='2024'&&this.activite=='Architectes'||decfiscmens.annee=='2024'&&this.activite=='Syndic des copropriétaires'||decfiscmens.annee=='2024'&&this.activite=='Ingénieurs-conseil'
              ||decfiscmens.annee=='2024'&&this.activite=='Dessinateurs'||decfiscmens.annee=='2024'&&this.activite=='Géomètres'
              ||decfiscmens.annee=='2024'&&this.activite=='Topographes'||decfiscmens.annee=='2024'&&this.activite=='Notaire'||
              decfiscmens.annee=='2024'&&this.activite=='Huissiers notaire'||decfiscmens.annee=='2024'&&this.activite=='Interprètes'||
              decfiscmens.annee=='2024'&&this.activite=='Expert'||decfiscmens.annee=='2024'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
              {
                this.tva19=true
                this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000

              }
              else if
              (decfiscmens.annee=='2023'&&decfiscmens.activite=='Médecin'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Infirmier'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Masseur'||
              decfiscmens.annee=='2023'&&decfiscmens.activite=='Physiothérapeute'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Ergothérapeute'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Psychomotricien'||
              decfiscmens.annee=='2023'&&decfiscmens.activite=='Diététicien'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Orthophoniste'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Orthoptiste'||
              decfiscmens.annee=='2023'&&decfiscmens.activite=='Sage-femmes')
              {
                this.tva7=true
                this.tva19=true
                this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
                this.htsum=+this.decfiscmens.impottype2.tvacollecter.chiffreaffaireht       
                this.tvasum=+this.decfiscmens.impottype2.tvacollecter.tvaammount

              }
              else if
              (decfiscmens.annee=='2024'&&decfiscmens.activite=='Médecin'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Infirmier'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Masseur'||
              decfiscmens.annee=='2024'&&decfiscmens.activite=='Physiothérapeute'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Ergothérapeute'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Psychomotricien'||
              decfiscmens.annee=='2024'&&decfiscmens.activite=='Diététicien'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Orthophoniste'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Orthoptiste'||
              decfiscmens.annee=='2024'&&decfiscmens.activite=='Sage-femmes')
              {
                this.tva7=true
                this.tva19=true
                this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
                this.htsum=+this.decfiscmens.impottype2.tvacollecter.chiffreaffaireht     
                this.tvasum=+this.decfiscmens.impottype2.tvacollecter.tvaammount

              }
              if(this.tva19==true)
              {
                this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
              }
              if(this.tva13==true)
              {
                this.ht13sum=+this.decfiscmens.impottype2.tvacollecter13?+this.decfiscmens.impottype2.tvacollecter13.chiffreaffaireht:0       
                this.tva13sum=+this.decfiscmens.impottype2.tvacollecter13?+this.decfiscmens.impottype2.tvacollecter13.tvaammount:0
              }  
              if(this.tva7==true)
              {
                this.htsum=+this.decfiscmens.impottype2.tvacollecter.chiffreaffaireht       
                this.tvasum=+this.decfiscmens.impottype2.tvacollecter.tvaammount
              }


              if(this.tvasuspens==true)
              {
                this.htsussum=+this.decfiscmens.impottype2.montantsuspension ||0      
                this.tvasussum=Math.floor((+this.htsussum* 0.19)*1000)/1000
              }  
              if(this.tvaexport==true)
              {
                this.htexpsum=+this.decfiscmens.impottype2.montantexport ||0       
                this.tvaexpsum=Math.floor((+this.htexpsum* 0.19)*1000)/1000
              }  
              if(this.tvaexonere==true)
              {
                this.htexosum=+this.decfiscmens.impottype2.montantexonere ||0      
                this.tvaexosum=Math.floor((+this.htexosum* 0.19)*1000)/1000
              }  
              if(this.tvafodec==true)
              {
                this.totalfodec = +(this.deccomptabilite.autre1).reduce((acc,curr)=>{
                  acc += +(+curr.montanthtfodec + +curr.montantht13fodec + +curr.montantht19fodec + +curr.montanthtexofodec + +curr.montanthtsusfodec || 0);
                  return acc;
                },0);
                this.totalfodecbase=Math.floor((+this.totalfodec*1.01)*1000)/1000
              }   
              if(this.tvatpe==true)
              {
                this.totaltpe = +(this.deccomptabilite.autre1).reduce((acc,curr)=>{
                  acc += +(+curr.montanthttpe + +curr.montantht13tpe + +curr.montantht19tpe + +curr.montanthtexotpe + +curr.montanthtsustpe || 0);
                  return acc;
                },0);
                this.totaltpebase=Math.floor((+this.totaltpe*1.07)*1000)/1000
              } 
              if (+this.decfiscmens.impottype1.honoraire1.montantbrut!==0 ||+this.decfiscmens.impottype1.honoraire3.montantbrut!==0 )
              {this.honorairesum= (+this.decfiscmens.impottype1.honoraire1.montantbrut + +this.decfiscmens.impottype1.honoraire3.montantbrut)
                this.honoraireretenue=Math.floor((this.honorairesum* 0.03)*1000)/1000 }
              
                
                
                if (+this.decfiscmens.impottype2.locationhabitationmeuble.htammount!=0 ||+this.decfiscmens.impottype2.locationusagecommercial.htammount!=0||
                  +this.decfiscmens.impottype2.operationlotissement.htammount!=0 ||+this.decfiscmens.impottype2.interetpercue.htammount!=0||+this.decfiscmens.impottype2.autretvaspecial.htammount!=0  )      
      {
      this.option71Value= +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
      + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount
      this.option72Value= Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
        + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000 
        if(decfiscmens.annee=='2023'&&this.activite=='Architectes'||decfiscmens.annee=='2023'&&this.activite=='Syndic des copropriétaires'||decfiscmens.annee=='2023'&&this.activite=='Ingénieurs-conseil'
        ||decfiscmens.annee=='2023'&&this.activite=='Dessinateurs'||decfiscmens.annee=='2023'&&this.activite=='Géomètres'
        ||decfiscmens.annee=='2023'&&this.activite=='Topographes'||decfiscmens.annee=='2023'&&this.activite=='Notaire'||
        decfiscmens.annee=='2023'&&this.activite=='Huissiers notaire'||decfiscmens.annee=='2023'&&this.activite=='Interprètes'||
        decfiscmens.annee=='2023'&&this.activite=='Expert'||decfiscmens.annee=='2023'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
        {
    this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
    + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
    this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
    + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000

  }
        else    if(decfiscmens.annee=='2024'&&this.activite=='Architectes'||decfiscmens.annee=='2024'&&this.activite=='Syndic des copropriétaires'||decfiscmens.annee=='2024'&&this.activite=='Ingénieurs-conseil'
        ||decfiscmens.annee=='2024'&&this.activite=='Dessinateurs'||decfiscmens.annee=='2024'&&this.activite=='Géomètres'
        ||decfiscmens.annee=='2024'&&this.activite=='Topographes'||decfiscmens.annee=='2024'&&this.activite=='Notaire'||
        decfiscmens.annee=='2024'&&this.activite=='Huissiers notaire'||decfiscmens.annee=='2024'&&this.activite=='Interprètes'||
        decfiscmens.annee=='2024'&&this.activite=='Expert'||decfiscmens.annee=='2024'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
        {
    this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
    + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
    this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
    + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
        }
    else if
    (decfiscmens.annee=='2023'&&decfiscmens.activite=='Médecin'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Infirmier'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Masseur'||
    decfiscmens.annee=='2023'&&decfiscmens.activite=='Physiothérapeute'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Ergothérapeute'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Psychomotricien'||
    decfiscmens.annee=='2023'&&decfiscmens.activite=='Diététicien'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Orthophoniste'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Orthoptiste'||
    decfiscmens.annee=='2023'&&decfiscmens.activite=='Sage-femmes')
        {
          if(this.decfiscmens.impottype2.tvacollecter19)
        {
          this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
          this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
        }
         else
         {
          this.ht19sum=+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
          this.tva19sum=+Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
         } 
        }
        else if
    (decfiscmens.annee=='2024'&&decfiscmens.activite=='Médecin'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Infirmier'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Masseur'||
    decfiscmens.annee=='2024'&&decfiscmens.activite=='Physiothérapeute'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Ergothérapeute'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Psychomotricien'||
    decfiscmens.annee=='2024'&&decfiscmens.activite=='Diététicien'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Orthophoniste'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Orthoptiste'||
    decfiscmens.annee=='2024'&&decfiscmens.activite=='Sage-femmes')
        {
          if(this.decfiscmens.impottype2.tvacollecter19)
        {
          this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
          this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
        }
         else
         {
          this.ht19sum=+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
          this.tva19sum=+Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
         } 
        }
        else
        {
          this.ht19sum=+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
          this.tva19sum=+Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
        }
      } 
    
    
    if (+this.decfiscmens.impottype2.locationhabitationmeuble.htammount==0 &&+this.decfiscmens.impottype2.locationusagecommercial.htammount==0&&
        +this.decfiscmens.impottype2.operationlotissement.htammount==0 &&+this.decfiscmens.impottype2.interetpercue.htammount==0&&+this.decfiscmens.impottype2.autretvaspecial.htammount==0  )
    {
      if
      (decfiscmens.annee=='2023'&&this.activite=='Architectes'||decfiscmens.annee=='2023'&&this.activite=='Syndic des copropriétaires'||decfiscmens.annee=='2023'&&this.activite=='Ingénieurs-conseil'
      ||decfiscmens.annee=='2023'&&this.activite=='Dessinateurs'||decfiscmens.annee=='2023'&&this.activite=='Géomètres'
      ||decfiscmens.annee=='2023'&&this.activite=='Topographes'||decfiscmens.annee=='2023'&&this.activite=='Notaire'||
      decfiscmens.annee=='2023'&&this.activite=='Huissiers notaire'||decfiscmens.annee=='2023'&&this.activite=='Interprètes'||
      decfiscmens.annee=='2023'&&this.activite=='Expert'||decfiscmens.annee=='2023'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant'||decfiscmens.annee=='2023'&&this.tva7==true)
      {
    this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht         
    this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
      }
      else   if
      (decfiscmens.annee=='2024'&&this.activite=='Architectes'||decfiscmens.annee=='2024'&&this.activite=='Syndic des copropriétaires'||decfiscmens.annee=='2024'&&this.activite=='Ingénieurs-conseil'
      ||decfiscmens.annee=='2024'&&this.activite=='Dessinateurs'||decfiscmens.annee=='2024'&&this.activite=='Géomètres'
      ||decfiscmens.annee=='2024'&&this.activite=='Topographes'||decfiscmens.annee=='2024'&&this.activite=='Notaire'||
      decfiscmens.annee=='2024'&&this.activite=='Huissiers notaire'||decfiscmens.annee=='2024'&&this.activite=='Interprètes'||
      decfiscmens.annee=='2024'&&this.activite=='Expert'||decfiscmens.annee=='2024'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant'||decfiscmens.annee=='2024'&&this.tva7==true)
      {
    this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht         
    this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
      }
    if
    (decfiscmens.annee=='2023'&&decfiscmens.activite=='Médecin'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Infirmier'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Masseur'||
    decfiscmens.annee=='2023'&&decfiscmens.activite=='Physiothérapeute'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Ergothérapeute'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Psychomotricien'||
    decfiscmens.annee=='2023'&&decfiscmens.activite=='Diététicien'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Orthophoniste'||decfiscmens.annee=='2023'&&decfiscmens.activite=='Orthoptiste'||
    decfiscmens.annee=='2023'&&decfiscmens.activite=='Sage-femmes'||decfiscmens.annee=='2023'&&this.tva19==true)
      {
        if(this.decfiscmens.impottype2.tvacollecter19)
        {
          this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht 
          this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
        }
        
      }
      else if
      (decfiscmens.annee=='2024'&&decfiscmens.activite=='Médecin'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Infirmier'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Masseur'||
      decfiscmens.annee=='2024'&&decfiscmens.activite=='Physiothérapeute'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Ergothérapeute'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Psychomotricien'||
      decfiscmens.annee=='2024'&&decfiscmens.activite=='Diététicien'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Orthophoniste'||decfiscmens.annee=='2024'&&decfiscmens.activite=='Orthoptiste'||
      decfiscmens.annee=='2024'&&decfiscmens.activite=='Sage-femmes'||decfiscmens.annee=='2024'&&this.tva19==true)
        {
          if(this.decfiscmens.impottype2.tvacollecter19)
          {
            this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht 
            this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
          }
          
        }
    }    
        
    this.totalretenueammount= +this.decfiscmens.impottype1.traitementetsalaire.retenuealasource+ +this.decfiscmens.impottype1.traitementetsalaire.contributionsociale+ +this.decfiscmens.impottype1.location1.montantretenue
    + +this.decfiscmens.impottype1.location2.montantretenue+ +this.decfiscmens.impottype1.location3.montantretenue+ +this.decfiscmens.impottype1.location4.montantretenue
    + +this.decfiscmens.impottype1.honoraire2.montantretenue+ +this.honoraireretenue
        + +this.decfiscmens.impottype1.montant10001.montantretenue+ +this.decfiscmens.impottype1.montant10002.montantretenue+ 
    +this.decfiscmens.impottype1.montant10003.montantretenue+ +this.decfiscmens.impottype1.montant10004.montantretenue
    
    
    
    this.tvarecuperable=+this.decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxtva+ +this.decfiscmens.impottype2.tvarecuperableautreachat.achatimportetva+ 
    +this.decfiscmens.impottype2.tvarecuperableequipement.achatlocauxtva+ +this.decfiscmens.impottype2.tvarecuperableequipement.achatimportetva+ 
    +this.decfiscmens.impottype2.tvarecuperableimmobilier.achatlocauxtva
    if(this.decfiscmens.impottype2.tvacollecter19&&this.decfiscmens.impottype2.tvacollecter13)
    {
      this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+ +this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +this.decfiscmens.impottype2.tvacollecter13.tvaammount+ Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
        + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
    }
    else   if(this.decfiscmens.impottype2.tvacollecter19&&!this.decfiscmens.impottype2.tvacollecter13)
    {
      this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+ +this.decfiscmens.impottype2.tvacollecter19.tvaammount+  Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
        + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
    }
    else   if(!this.decfiscmens.impottype2.tvacollecter19&&this.decfiscmens.impottype2.tvacollecter13)
    {
      this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+ +this.decfiscmens.impottype2.tvacollecter13.tvaammount+  Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
        + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
    }
    else
    {
      this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+  Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
        + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
    }
    
    this.preptotaltvaammount=this.tvacollecte-this.tvarecuperable
    this.option64Value=this.decfiscmens.impottype2.reporttvamoisprecedent
    console.log(this.preptotaltvaammount)
    if (this.preptotaltvaammount >= 0 && this.preptotaltvaammount-this.option64Value>=0)
    {
      this.totalreporttvaammount=0
      this.totaltvaammount=this.preptotaltvaammount-this.option64Value
    }
    else 
    {
    this.totaltvaammount=0
    this.totalreporttvaammount=this.option64Value-this.preptotaltvaammount
    }
    
    
    
    this.totalfoprolosammount= +this.decfiscmens.impottype4.montantfoprolos
    
    
      
        if (+this.decfiscmens.impottype3.tfppayer > 0)
        {
          console.log(this.decfiscmens.impottype3.tfppayer)
        this.totaltfpammount= +this.decfiscmens.impottype3.tfppayer
        } 
        else 
        {
          this.totaltfpammount= 0
        }
    this.totaltimbreammount=+this.decfiscmens.impottype5.totaldroittimbre
    this.totaltclammount=+this.decfiscmens.impottype6.tclpayer 
    if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
    this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
    ||this.activite=='Sage-femmes')
              { 
    this.totalfspammount=+this.decfiscmens.impottype7.montantcontribution
              }
    this.preptotaldeclaration=+this.totalretenueammount+ +this.totaltfpammount+ +this.totalfoprolosammount+ +this.totaltvaammount+ +this.totaltimbreammount+ 
    + +this.totalfspammount + +this.totaltpe + +this.totalfodec + +this.camontant + +this.caexportmontant
    console.log(this.totalretenueammount,this.totaltfpammount,this.totalfoprolosammount,this.totaltvaammount,this.totaltimbreammount,this.totaltclammount)
    console.log(this.honoraireretenue) 
              this.allstatuts=this.allstatuts.concat(this.decfiscmens.statutadmin,this.decfiscmens.statutcollab)
              this.totaltime = Math.floor(+this.allstatuts.reduce((acc,curr)=>{
                acc +=  +(curr.duree || 0);
                return acc;
              },0)/60);
              this.sortedallstatuts=this.allstatuts.sort(sort.startSort('datefin','asc',''));
              if(this.decfiscmens.affecte)
              {
                this.optionValue=this.decfiscmens.affecte
              }
              console.log(user)
              if(!user)
              {
                if (this.decfiscmens.regimefiscalimpot==='Réel'&&this.currentdate>=this.date1)  
{
 console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=20.000
}
else if (user.nature==='Personne Morale'&&this.currentdate>=this.date1) 
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=30.000

}  
else if (this.decfiscmens.regimefiscalimpot==='Forfait D\'assiette'&&this.currentdate>=this.date1) 
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=10.000

}
else if (this.decfiscmens.regimefiscalimpot==='Réel'&&this.date1>=this.currentdate)  
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=10.000
}  
else if (this.decfiscmens.regimefiscalimpot==='Forfait D\'assiette'&&this.date1>=this.currentdate) 
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=5.000

}
            if (this.preptotaldeclaration- this.prepminimumperceptionammount <= 0)
            
            {
              this.totaldeclaration=this.prepminimumperceptionammount
              this.minimumperceptionammount=this.prepminimumperceptionammount-this.preptotaldeclaration
            } 
            else 
            {
              this.totaldeclaration=this.preptotaldeclaration
              this.minimumperceptionammount=0.000
            
            }                
          }
         else  if (user)
          {


            if (user.regimefiscalimpot==='Réel'&&this.currentdate>=this.date1)  
{
 console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=20.000
}  
else if (user.regimefiscalimpot==='Forfait D\'assiette'&&this.currentdate>=this.date1) 
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=10.000

}
else if (user.nature==='Personne Morale'&&this.currentdate>=this.date1) 
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=30.000

}  
else if (user.regimefiscalimpot==='Réel'&&this.date1>=this.currentdate)  
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=10.000
}  
else if (user.regimefiscalimpot==='Forfait D\'assiette'&&this.date1>=this.currentdate) 
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=5.000

}
      /*  if(this.totalreporttvaammount!=0&&+this.totalretenueammount==0&&+this.totaltfpammount==0&&+this.totalfoprolosammount==0
          &&+this.totaltimbreammount==0&&+this.totaltclammount==0&&+this.totalfspammount==0)
        {
          console.log('here')
          this.prepminimumperceptionammount=0.000
        }*/
        if (this.preptotaldeclaration- this.prepminimumperceptionammount <= 0) 
        {
          this.totaldeclaration=this.prepminimumperceptionammount
          this.minimumperceptionammount=this.prepminimumperceptionammount-this.preptotaldeclaration
        } 
        else 
        {
          this.totaldeclaration=this.preptotaldeclaration
          this.minimumperceptionammount=0.000 
        }
          }
      
          if(this.decfiscmens.statutadmin.length>0)
          {
            this.settservice.getCarouselalldata()
            this.settingsSub = this.settservice.carousels$.subscribe(
              (settings) => {
                this.settings = settings; 
                this.tarifs=this.settings.filter(p => p.tarifs.length>0)
             
                this.tarifs.forEach(async (item, _index) => {
                  item.tarifs[0].type=='Tarif individuel'?
                  this.tarifsclient.push(item.tarifs[0]):
                  item.tarifs[0].type=='Tarif spécial'?
                  this.tarifsspecial.push(item.tarifs[0]):
                  this.tarifsbase.push(item.tarifs[0])
                        },
                       (error) => {
                         this.loading = false;
                         this.errormsg=error.message;
                       }
                     );
                     this.gettarifindiv()   
                     this.gettarifspecial() 
                     this.gettarifbase() 
                     console.log(this.prixminute,this.prixminuteindiv,this.prixminutespecial)   
                     this.prixminuteindiv?
                     this.totalhonoraire= Math.round((+this.totaltime * +this.prixminuteindiv) * 1000)/1000:
                     this.prixminutespecial?
                     this.totalhonoraire= Math.round((+this.totaltime * +this.prixminutespecial) * 1000)/1000:
                     this.totalhonoraire= Math.round((+this.totaltime * +this.prixminute) * 1000)/1000
          
              }
            )
//@ts-ignore
if(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].statut=='clôturé')
{
if(user)
{
  this.showgenerate=true

}
else if(!user)
{
this.showgenerate=true
this.settservice.getCarouselalldata()
this.settingsSub = this.settservice.carousels$.subscribe(
  (settings) => {
    this.settings = settings; 
    this.tarifs=this.settings.filter(p => p.tarifs.length>0)
    this.tarifs.forEach(async (item, index) => {
     //@ts-ignore
     if(item.tarifs[0].type=='Tarif spécial'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
     //@ts-ignore
     &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
     {
     //@ts-ignore
      if(item.tarifs[0].nature==this.decfiscmens.nature||item.tarifs[0].nature=='')
     {
 
       //@ts-ignore
     if(item.tarifs[0].natureactivite==this.decfiscmens.natureactivite||item.tarifs[0].natureactivite=='')
     {
 
       //@ts-ignore
     if(item.tarifs[0].activite==this.decfiscmens.activite||item.tarifs[0].activite=='')
     {
 
       //@ts-ignore
     if(item.tarifs[0].sousactivite==this.decfiscmens.sousactivite||item.tarifs[0].sousactivite=='')
     {
 
       //@ts-ignore
     if(item.tarifs[0].impot==this.decfiscmens.regimefiscalimpot||item.tarifs[0].impot=='')
     {
 
       this.prixminutepref=item.tarifs[0].prix
 
     
     }
     }
     }
     }
      }
     }
     //@ts-ignore
     else if(item.tarifs[0].type=='Tarif de base'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
     //@ts-ignore
     &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
     {
 
       
      this.prixminute=item.tarifs[0].prix
 
      
     }
            },
           (error) => {
             this.loading = false;
             this.errormsg=error.message;
           }
         );
 this.tarifs.forEach(async (item, index) => {
 //@ts-ignore
 if(item.tarifs[0].type=='Tarif spécial'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
 //@ts-ignore
 &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
 {
 //@ts-ignore
  if(item.tarifs[0].nature==this.decfiscmens.nature||item.tarifs[0].nature=='')
 {

   //@ts-ignore
 if(item.tarifs[0].natureactivite==this.decfiscmens.natureactivite||item.tarifs[0].natureactivite=='')
 {

   //@ts-ignore
 if(item.tarifs[0].activite==this.decfiscmens.activite||item.tarifs[0].activite=='')
 {

   //@ts-ignore
 if(item.tarifs[0].sousactivite==this.decfiscmens.sousactivite||item.tarifs[0].sousactivite=='')
 {

   //@ts-ignore
 if(item.tarifs[0].impot==this.decfiscmens.regimefiscalimpot||item.tarifs[0].impot=='')
 {

   this.prixminutepref=item.tarifs[0].prix
 this.totalhonoraire= Math.round((+this.totaltime * +this.prixminutepref) *1000)/1000

 
 }
 }
 }
 }
  }
 }
 //@ts-ignore
 else if(item.tarifs[0].type=='Tarif de base'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
 //@ts-ignore
 &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
 {

   
  this.prixminute=item.tarifs[0].prix
  this.totalhonoraire= Math.round((+this.totaltime * +this.prixminute) * 1000)/1000

  
 }
        },
       (error) => {
         this.loading = false;
         this.errormsg=error.message;
       }
     );
    
  }
)
}

}
          }
          
          if(this.decfiscmens.statutcollab.length>0)
          {
//@ts-ignore
this.statutsclients=this.decfiscmens.statutcollab.filter(p => p.statutclient=='déposé par le client'||p.statutclient=='modifié par le client'||p.statutclient=='saisi pour le compte du client'||p.statutclient=='déposé pour le compte du client')
if(this.statutsclients.length>0)
{
//@ts-ignore
if(this.statutsclients[this.statutsclients.length -1].statutclient=='déposé par le client'||this.statutsclients[this.statutsclients.length -1].statutclient=='déposé pour le compte du client')
{
this.showbuttons=true
}
}
            }
  
            }
          )
 

        }
      );
    }
  );
}
else
{
// console.log('faire ici')
 this.route.params.subscribe(
  (params) => {
    this.deccompt.getDeccomptabilitereqById(params.id).then(
      (deccomptabilite: Deccomptabilite) => {
        this.deccomptabilite = deccomptabilite;
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,this.deccomptabilite.annee,this.deccomptabilite.mois,this.deccomptabilite.source).then(
          (decfiscmens: Decfiscmens[]) => {


            this.dec.getdecfiscmens(decfiscmens[0].userId)
            this.decfiscmenssSub = this.dec.decfiscmenss$.subscribe(
              (decfiscmenss) => {
                decfiscmenss.find(decfis=>decfis.impottype5.type?this.type5=this.decfiscmens.impottype5.type:'')
              },
              (error) => {
                this.loading = false;
                
                this.errormsg=error.message;
              }
            );
            this.allstatuts=[]
            const sort = new Sort();
            this.loading = false;
            this.decfiscmens = decfiscmens[0];
            console.log(this.decfiscmens)
            this.activite=this.decfiscmens.activite
            this.sousactivite=this.decfiscmens.sousactivite
            this.tfpapayer=this.decfiscmens.impottype3.tfppayer
            this.tfpareporter=this.decfiscmens.impottype3.tfpreporter
            this.annee=this.decfiscmens.annee
            this.type1=this.decfiscmens.impottype1.type
            this.type2=this.decfiscmens.impottype2.type
            this.type3=this.decfiscmens.impottype3.type
            this.type4=this.decfiscmens.impottype4.type
            this.type5=this.decfiscmens.impottype5.type
            this.type6=this.decfiscmens.impottype6.type
            this.userservice.getUserById(this.decfiscmens.userId).then(
              (user: User) => {
                console.log(user)
                user.tvaaffiche.find(el=>el.tauxtva=='7'&&el.affiche=='oui')?this.tva7=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='13'&&el.affiche=='oui')?this.tva13=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='19'&&el.affiche=='oui')?this.tva19=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='suspension'&&el.affiche=='oui')?this.tvasuspens=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='exonere'&&el.affiche=='oui')?this.tvaexonere=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='export'&&el.affiche=='oui')?this.tvaexport=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='fodec'&&el.affiche=='oui')?this.tvafodec=true:''
                user.tvaaffiche.find(el=>el.tauxtva=='tpe'&&el.affiche=='oui')?this.tvatpe=true:''
              this.decfiscmens.impottype6.chiffreaffairettc?this.cabase=+this.decfiscmens.impottype6.chiffreaffairettc:this.cabase= +(this.deccomptabilite.autre1).reduce((acc,curr)=>{
                  acc += +(+curr.montantttc - +curr.montantdt - +curr.montanthtexp || 0);
                  return acc;
                },0);
              this.decfiscmens.impottype6.tclpayer?this.camontant=+this.decfiscmens.impottype6.tclpayer:this.camontant=Math.floor((+this.cabase* 0.002)*1000)/1000

                this.caexportbase= +(this.deccomptabilite.autre1).reduce((acc,curr)=>{
                  acc += +(+curr.montanthtexp || 0);
                  return acc;
                },0);
                this.caexportmontant=Math.floor((+this.caexportbase* 0.001)*1000)/1000

                if(this.tva19==true)
                {
                  this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                  this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
                console.log(this.ht19sum,this.tva19sum)
                }
               if(this.tva13==true)
                {
                  console.log(this.decfiscmens.impottype2.tvacollecter13)
                  this.ht13sum=this.decfiscmens.impottype2.tvacollecter13?+this.decfiscmens.impottype2.tvacollecter13.chiffreaffaireht:0       
                  this.tva13sum=this.decfiscmens.impottype2.tvacollecter13?+this.decfiscmens.impottype2.tvacollecter13.tvaammount:0
                  console.log(this.ht13sum,this.tva13sum)
                }  
                if(this.tva7==true)
                {
                  this.htsum=+this.decfiscmens.impottype2.tvacollecter.chiffreaffaireht        
                  this.tvasum=+this.decfiscmens.impottype2.tvacollecter.tvaammount
                }
                if(this.tvasuspens==true)
                {
                  this.htsussum=+this.decfiscmens.impottype2.montantsuspension ||0      
                  this.tvasussum=Math.floor((this.htsussum* 0.19)*1000)/1000
                }  
                if(this.tvaexport==true)
                {
                  this.htexpsum=+this.decfiscmens.impottype2.montantexport ||0      
                  this.tvaexpsum=Math.floor((+this.htexpsum* 0.19)*1000)/1000
                }  
                if(this.tvaexonere==true)
                {
                  this.htexosum=+this.decfiscmens.impottype2.montantexonere  ||0     
                  this.tvaexosum=Math.floor((+this.htexosum* 0.19)*1000)/1000
                }
                if(this.tvafodec==true)
                {
                  this.totalfodec = +(this.deccomptabilite.autre1).reduce((acc,curr)=>{
                    acc += +(+curr.montanthtfodec + +curr.montantht13fodec + +curr.montantht19fodec + +curr.montanthtexofodec + +curr.montanthtsusfodec || 0);
                    return acc;
                  },0);
                  this.totalfodecbase=Math.floor((+this.totalfodec*1.01)*1000)/1000
                }   
                if(this.tvatpe==true)
                {
                  this.totaltpe = +(this.deccomptabilite.autre1).reduce((acc,curr)=>{
                    acc += +(+curr.montanthttpe + +curr.montantht13tpe + +curr.montantht19tpe + +curr.montanthtexotpe + +curr.montanthtsustpe || 0);
                    return acc;
                  },0);
                  this.totaltpebase=Math.floor((+this.totaltpe*1.07)*1000)/1000
                } 
                if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
                this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
                ||this.activite=='Sage-femmes' )
                {
                  this.type7=this.decfiscmens.impottype7.type 
                }          
                if(decfiscmens[0].annee=='2023'&&this.activite=='Architectes'||decfiscmens[0].annee=='2023'&&this.activite=='Syndic des copropriétaires'||decfiscmens[0].annee=='2023'&&this.activite=='Ingénieurs-conseil'
                ||decfiscmens[0].annee=='2023'&&this.activite=='Dessinateurs'||decfiscmens[0].annee=='2023'&&this.activite=='Géomètres'
                ||decfiscmens[0].annee=='2023'&&this.activite=='Topographes'||decfiscmens[0].annee=='2023'&&this.activite=='Notaire'||
                decfiscmens[0].annee=='2023'&&this.activite=='Huissiers notaire'||decfiscmens[0].annee=='2023'&&this.activite=='Interprètes'||
                decfiscmens[0].annee=='2023'&&this.activite=='Expert'||decfiscmens[0].annee=='2023'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
                {
                  this.tva19=true
                  this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                  this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000

                }
                else if(decfiscmens[0].annee=='2024'&&this.activite=='Architectes'||decfiscmens[0].annee=='2024'&&this.activite=='Syndic des copropriétaires'||decfiscmens[0].annee=='2024'&&this.activite=='Ingénieurs-conseil'
                ||decfiscmens[0].annee=='2024'&&this.activite=='Dessinateurs'||decfiscmens[0].annee=='2024'&&this.activite=='Géomètres'
                ||decfiscmens[0].annee=='2024'&&this.activite=='Topographes'||decfiscmens[0].annee=='2024'&&this.activite=='Notaire'||
                decfiscmens[0].annee=='2024'&&this.activite=='Huissiers notaire'||decfiscmens[0].annee=='2024'&&this.activite=='Interprètes'||
                decfiscmens[0].annee=='2024'&&this.activite=='Expert'||decfiscmens[0].annee=='2024'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
                {
                  this.tva19=true
                  this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                  this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000

                }
                else if
                (decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Médecin'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Infirmier'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Masseur'||
                decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Physiothérapeute'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Ergothérapeute'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Psychomotricien'||
                decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Diététicien'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Orthophoniste'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Orthoptiste'||
                decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Sage-femmes')
                {
                  this.tva7=true
                  this.tva19=true
                  this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
                this.htsum=+this.decfiscmens.impottype2.tvacollecter.chiffreaffaireht        
                this.tvasum=+this.decfiscmens.impottype2.tvacollecter.tvaammount

                }
                else if
                (decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Médecin'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Infirmier'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Masseur'||
                decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Physiothérapeute'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Ergothérapeute'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Psychomotricien'||
                decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Diététicien'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Orthophoniste'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Orthoptiste'||
                decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Sage-femmes')
                {
                  this.tva7=true
                  this.tva19=true
                  this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
                this.htsum=+this.decfiscmens.impottype2.tvacollecter.chiffreaffaireht         
                this.tvasum=+this.decfiscmens.impottype2.tvacollecter.tvaammount

                }
               
      
                if (+this.decfiscmens.impottype1.honoraire1.montantbrut!==0 ||+this.decfiscmens.impottype1.honoraire3.montantbrut!==0 )
                {this.honorairesum= (+this.decfiscmens.impottype1.honoraire1.montantbrut + +this.decfiscmens.impottype1.honoraire3.montantbrut)
                  this.honoraireretenue=Math.floor((this.honorairesum* 0.03)*1000)/1000 }
                
                  
                  
                  if (+this.decfiscmens.impottype2.locationhabitationmeuble.htammount!=0 ||+this.decfiscmens.impottype2.locationusagecommercial.htammount!=0||
                    +this.decfiscmens.impottype2.operationlotissement.htammount!=0 ||+this.decfiscmens.impottype2.interetpercue.htammount!=0||+this.decfiscmens.impottype2.autretvaspecial.htammount!=0  )      
        {
        this.option71Value= +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
        + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount
        this.option72Value= Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000 
          if(decfiscmens[0].annee=='2023'&&this.activite=='Architectes'||decfiscmens[0].annee=='2023'&&this.activite=='Syndic des copropriétaires'||decfiscmens[0].annee=='2023'&&this.activite=='Ingénieurs-conseil'
          ||decfiscmens[0].annee=='2023'&&this.activite=='Dessinateurs'||decfiscmens[0].annee=='2023'&&this.activite=='Géomètres'
          ||decfiscmens[0].annee=='2023'&&this.activite=='Topographes'||decfiscmens[0].annee=='2023'&&this.activite=='Notaire'||
          decfiscmens[0].annee=='2023'&&this.activite=='Huissiers notaire'||decfiscmens[0].annee=='2023'&&this.activite=='Interprètes'||
          decfiscmens[0].annee=='2023'&&this.activite=='Expert'||decfiscmens[0].annee=='2023'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
          {
      this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
      + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
      this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
      + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
          }
          else    if(decfiscmens[0].annee=='2024'&&this.activite=='Architectes'||decfiscmens[0].annee=='2024'&&this.activite=='Syndic des copropriétaires'||decfiscmens[0].annee=='2024'&&this.activite=='Ingénieurs-conseil'
          ||decfiscmens[0].annee=='2024'&&this.activite=='Dessinateurs'||decfiscmens[0].annee=='2024'&&this.activite=='Géomètres'
          ||decfiscmens[0].annee=='2024'&&this.activite=='Topographes'||decfiscmens[0].annee=='2024'&&this.activite=='Notaire'||
          decfiscmens[0].annee=='2024'&&this.activite=='Huissiers notaire'||decfiscmens[0].annee=='2024'&&this.activite=='Interprètes'||
          decfiscmens[0].annee=='2024'&&this.activite=='Expert'||decfiscmens[0].annee=='2024'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
          {
      this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
      + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount         
      this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
      + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
          }
      else if
      (decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Médecin'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Infirmier'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Masseur'||
      decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Physiothérapeute'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Ergothérapeute'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Psychomotricien'||
      decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Diététicien'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Orthophoniste'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Orthoptiste'||
      decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Sage-femmes')
          {
            if(this.decfiscmens.impottype2.tvacollecter19)
          {
            this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
            this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
              + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
          }
           else
           {
            this.ht19sum=+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
            this.tva19sum=+Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
              + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
           } 
          }
          else if
          (decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Médecin'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Infirmier'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Masseur'||
          decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Physiothérapeute'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Ergothérapeute'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Psychomotricien'||
          decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Diététicien'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Orthophoniste'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Orthoptiste'||
          decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Sage-femmes')
              {
                if(this.decfiscmens.impottype2.tvacollecter19)
              {
                this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht+ +this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
                this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
              }
               else
               {
                this.ht19sum=+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
                this.tva19sum=+Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
                  + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
               } 
              }
          else
          {
            this.ht19sum=+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
            + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount        
            this.tva19sum=+Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
              + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
          }
        } 
      
      
      if (+this.decfiscmens.impottype2.locationhabitationmeuble.htammount==0 &&+this.decfiscmens.impottype2.locationusagecommercial.htammount==0&&
          +this.decfiscmens.impottype2.operationlotissement.htammount==0 &&+this.decfiscmens.impottype2.interetpercue.htammount==0&&+this.decfiscmens.impottype2.autretvaspecial.htammount==0  )
      {
        if
        (decfiscmens[0].annee=='2023'&&this.activite=='Architectes'||decfiscmens[0].annee=='2023'&&this.activite=='Syndic des copropriétaires'||decfiscmens[0].annee=='2023'&&this.activite=='Ingénieurs-conseil'
        ||decfiscmens[0].annee=='2023'&&this.activite=='Dessinateurs'||decfiscmens[0].annee=='2023'&&this.activite=='Géomètres'
        ||decfiscmens[0].annee=='2023'&&this.activite=='Topographes'||decfiscmens[0].annee=='2023'&&this.activite=='Notaire'||
        decfiscmens[0].annee=='2023'&&this.activite=='Huissiers notaire'||decfiscmens[0].annee=='2023'&&this.activite=='Interprètes'||
        decfiscmens[0].annee=='2023'&&this.activite=='Expert'||decfiscmens[0].annee=='2023'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
        {
      this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht         
      this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
        }
        else     if
        (decfiscmens[0].annee=='2024'&&this.activite=='Architectes'||decfiscmens[0].annee=='2024'&&this.activite=='Syndic des copropriétaires'||decfiscmens[0].annee=='2024'&&this.activite=='Ingénieurs-conseil'
        ||decfiscmens[0].annee=='2024'&&this.activite=='Dessinateurs'||decfiscmens[0].annee=='2024'&&this.activite=='Géomètres'
        ||decfiscmens[0].annee=='2024'&&this.activite=='Topographes'||decfiscmens[0].annee=='2024'&&this.activite=='Notaire'||
        decfiscmens[0].annee=='2024'&&this.activite=='Huissiers notaire'||decfiscmens[0].annee=='2024'&&this.activite=='Interprètes'||
        decfiscmens[0].annee=='2024'&&this.activite=='Expert'||decfiscmens[0].annee=='2024'&&this.activite=='Avocat'||this.activite=='Consultant'||this.sousactivite=='Consultant')
        {
      this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht         
      this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
        }
      if
      (decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Médecin'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Infirmier'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Masseur'||
      decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Physiothérapeute'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Ergothérapeute'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Psychomotricien'||
      decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Diététicien'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Orthophoniste'||decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Orthoptiste'||
      decfiscmens[0].annee=='2023'&&decfiscmens[0].activite=='Sage-femmes')
        {
          if(this.decfiscmens.impottype2.tvacollecter19)
          {
            this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht 
            this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
          }
          
        }
        else   if
        (decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Médecin'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Infirmier'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Masseur'||
        decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Physiothérapeute'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Ergothérapeute'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Psychomotricien'||
        decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Diététicien'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Orthophoniste'||decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Orthoptiste'||
        decfiscmens[0].annee=='2024'&&decfiscmens[0].activite=='Sage-femmes')
          {
            if(this.decfiscmens.impottype2.tvacollecter19)
            {
              this.ht19sum=+this.decfiscmens.impottype2.tvacollecter19.chiffreaffaireht 
              this.tva19sum=+this.decfiscmens.impottype2.tvacollecter19.tvaammount
            }
            
          }
      }    
          
      this.totalretenueammount= +this.decfiscmens.impottype1.traitementetsalaire.retenuealasource+ +this.decfiscmens.impottype1.traitementetsalaire.contributionsociale+ +this.decfiscmens.impottype1.location1.montantretenue
      + +this.decfiscmens.impottype1.location2.montantretenue+ +this.decfiscmens.impottype1.location3.montantretenue+ +this.decfiscmens.impottype1.location4.montantretenue
      + +this.decfiscmens.impottype1.honoraire2.montantretenue+ +this.honoraireretenue
          + +this.decfiscmens.impottype1.montant10001.montantretenue+ +this.decfiscmens.impottype1.montant10002.montantretenue+ 
      +this.decfiscmens.impottype1.montant10003.montantretenue+ +this.decfiscmens.impottype1.montant10004.montantretenue
      
      
      
      this.tvarecuperable=+this.decfiscmens.impottype2.tvarecuperableautreachat.achatlocauxtva+ +this.decfiscmens.impottype2.tvarecuperableautreachat.achatimportetva+ 
      +this.decfiscmens.impottype2.tvarecuperableequipement.achatlocauxtva+ +this.decfiscmens.impottype2.tvarecuperableequipement.achatimportetva+ 
      +this.decfiscmens.impottype2.tvarecuperableimmobilier.achatlocauxtva
      if(this.decfiscmens.impottype2.tvacollecter19&&this.decfiscmens.impottype2.tvacollecter13)
      {
        this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+ +this.decfiscmens.impottype2.tvacollecter19.tvaammount+ +this.decfiscmens.impottype2.tvacollecter13.tvaammount+ Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
      }
      else   if(this.decfiscmens.impottype2.tvacollecter19&&!this.decfiscmens.impottype2.tvacollecter13)
      {
        this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+ +this.decfiscmens.impottype2.tvacollecter19.tvaammount+  Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
      }
      else   if(!this.decfiscmens.impottype2.tvacollecter19&&this.decfiscmens.impottype2.tvacollecter13)
      {
        this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+ +this.decfiscmens.impottype2.tvacollecter13.tvaammount+  Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
      }
      else
      {
        this.tvacollecte=+this.decfiscmens.impottype2.tvacollecter.tvaammount+  Math.round(((+this.decfiscmens.impottype2.locationhabitationmeuble.htammount+ +this.decfiscmens.impottype2.locationusagecommercial.htammount
          + +this.decfiscmens.impottype2.operationlotissement.htammount+ +this.decfiscmens.impottype2.interetpercue.htammount+ +this.decfiscmens.impottype2.autretvaspecial.htammount)*0.19)*1000)/1000    
      }
      
      this.preptotaltvaammount=this.tvacollecte-this.tvarecuperable
      this.option64Value=this.decfiscmens.impottype2.reporttvamoisprecedent
      console.log(this.preptotaltvaammount)
      if (this.preptotaltvaammount >= 0 && this.preptotaltvaammount-this.option64Value>=0)
      {
        this.totalreporttvaammount=0
        this.totaltvaammount=this.preptotaltvaammount-this.option64Value
      }
      else 
      {
      this.totaltvaammount=0
      this.totalreporttvaammount=this.option64Value-this.preptotaltvaammount
      }
      
      
      
      this.totalfoprolosammount= +this.decfiscmens.impottype4.montantfoprolos
      
      
        
          if (+this.decfiscmens.impottype3.tfppayer > 0)
          {
            console.log(this.decfiscmens.impottype3.tfppayer)
          this.totaltfpammount= +this.decfiscmens.impottype3.tfppayer
          } 
          else 
          {
            this.totaltfpammount= 0
          }
      this.totaltimbreammount=+this.decfiscmens.impottype5.totaldroittimbre
      this.totaltclammount=+this.decfiscmens.impottype6.tclpayer 
      if(this.activite=='Médecin'||this.activite=='Infirmier'||this.activite=='Masseur'||this.activite=='Physiothérapeute'||
      this.activite=='Ergothérapeute'||this.activite=='Psychomotricien'||this.activite=='Diététicien'||this.activite=='Orthophoniste'||this.activite=='Orthoptiste'
      ||this.activite=='Sage-femmes')
                { 
      this.totalfspammount=+this.decfiscmens.impottype7.montantcontribution
                }
      this.preptotaldeclaration=+this.totalretenueammount+ +this.totaltfpammount+ +this.totalfoprolosammount+ +this.totaltvaammount+ +this.totaltimbreammount+ 
      + +this.totalfspammount + +this.totaltpe + +this.totalfodec + +this.camontant + +this.caexportmontant
      console.log(this.totalretenueammount,this.totaltfpammount,this.totalfoprolosammount,this.totaltvaammount,this.totaltimbreammount,this.totaltclammount)
      console.log(this.honoraireretenue) 
                this.allstatuts=this.allstatuts.concat(this.decfiscmens.statutadmin,this.decfiscmens.statutcollab)
                this.totaltime = Math.floor(+this.allstatuts.reduce((acc,curr)=>{
                  acc +=  +(curr.duree || 0);
                  return acc;
                },0)/60);
                this.sortedallstatuts=this.allstatuts.sort(sort.startSort('datefin','asc',''));
                if(this.decfiscmens.affecte)
                {
                  this.optionValue=this.decfiscmens.affecte
                }
                if(!user)
                {
                  if (this.decfiscmens.regimefiscalimpot==='Réel'&&this.currentdate>=this.date1)  
  {
   console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=20.000
  }  
  else if (this.decfiscmens.regimefiscalimpot==='Forfait D\'assiette'&&this.currentdate>=this.date1) 
  {
    console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=10.000
  
  }
  else if (this.decfiscmens.regimefiscalimpot==='Réel'&&this.date1>=this.currentdate)  
  {
    console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=10.000
  }  
  else if (this.decfiscmens.regimefiscalimpot==='Forfait D\'assiette'&&this.date1>=this.currentdate) 
  {
    console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=5.000
  
  }
             /* if(this.totalreporttvaammount!=0&&+this.totalretenueammount==0&&+this.totaltfpammount==0&&+this.totalfoprolosammount==0
                &&+this.totaltimbreammount==0&&+this.totaltclammount==0&&+this.totalfspammount==0)
              {
                console.log('here')
                this.prepminimumperceptionammount=0.000
              }*/
              if (this.preptotaldeclaration- this.prepminimumperceptionammount <= 0)
              
              {
                this.totaldeclaration=this.prepminimumperceptionammount
                this.minimumperceptionammount=this.prepminimumperceptionammount-this.preptotaldeclaration
              } 
              else 
              {
                this.totaldeclaration=this.preptotaldeclaration
                this.minimumperceptionammount=0.000
              
              }                
            }
            if (user)
            {
              if (user.regimefiscalimpot==='Réel'&&this.currentdate>=this.date1)  
  {
   console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=20.000
  }  
  else if (user.regimefiscalimpot==='Forfait D\'assiette'&&this.currentdate>=this.date1) 
  {
    console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=10.000
  
  }
  else if (user.nature==='Personne Morale'&&this.currentdate>=this.date1) 
{
  console.log(this.date1,this.currentdate) 
 this.prepminimumperceptionammount=30.000

}  
  else if (user.regimefiscalimpot==='Réel'&&this.date1>=this.currentdate)  
  {
    console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=10.000
  }  

  else if (user.regimefiscalimpot==='Forfait D\'assiette'&&this.date1>=this.currentdate) 
  {
    console.log(this.date1,this.currentdate) 
   this.prepminimumperceptionammount=5.000
  
  }
         /* if(this.totalreporttvaammount!=0&&+this.totalretenueammount==0&&+this.totaltfpammount==0&&+this.totalfoprolosammount==0
            &&+this.totaltimbreammount==0&&+this.totaltclammount==0&&+this.totalfspammount==0)
          {
            console.log('here')
            this.prepminimumperceptionammount=0.000
          }*/
          if (this.preptotaldeclaration- this.prepminimumperceptionammount <= 0) 
          {
            this.totaldeclaration=this.prepminimumperceptionammount
            this.minimumperceptionammount=this.prepminimumperceptionammount-this.preptotaldeclaration
          } 
          else 
          {
            this.totaldeclaration=this.preptotaldeclaration
            this.minimumperceptionammount=0.000 
          }
            }
        
            if(this.decfiscmens.statutadmin.length>0)
            {
              this.settservice.getCarouselalldata()
              this.settingsSub = this.settservice.carousels$.subscribe(
                (settings) => {
                  this.settings = settings; 
                  this.tarifs=this.settings.filter(p => p.tarifs.length>0)
                  
                  this.tarifs.forEach(async (item, _index) => {
                    item.tarifs[0].type=='Tarif individuel'?
                    this.tarifsclient.push(item.tarifs[0]):
                    item.tarifs[0].type=='Tarif spécial'?
                    this.tarifsspecial.push(item.tarifs[0]):
                    this.tarifsbase.push(item.tarifs[0])
                          },
                         (error) => {
                           this.loading = false;
                           this.errormsg=error.message;
                         }
                       );
                       this.gettarifindiv()   
                       this.gettarifspecial() 
                       this.gettarifbase() 
                       console.log(this.prixminute,this.prixminuteindiv,this.prixminutespecial)   
                       this.prixminuteindiv?
                       this.totalhonoraire= Math.round((+this.totaltime * +this.prixminuteindiv) * 1000)/1000:
                       this.prixminutespecial?
                       this.totalhonoraire= Math.round((+this.totaltime * +this.prixminutespecial) * 1000)/1000:
                       this.totalhonoraire= Math.round((+this.totaltime * +this.prixminute) * 1000)/1000
            
                }
              )
  //@ts-ignore
  if(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].statut=='clôturé')
  {
    if(user)
    {
      this.showgenerate=true
  
    }
  else if(!user)
  {
  this.showgenerate=true
  this.settservice.getCarouselalldata()
  this.settingsSub = this.settservice.carousels$.subscribe(
    (settings) => {
      this.settings = settings; 
      this.tarifs=this.settings.filter(p => p.tarifs.length>0)
      this.tarifs.forEach(async (item, index) => {
       //@ts-ignore
       if(item.tarifs[0].type=='Tarif spécial'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
       //@ts-ignore
       &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
       {
       //@ts-ignore
        if(item.tarifs[0].nature==this.decfiscmens.nature||item.tarifs[0].nature=='')
       {
   
         //@ts-ignore
       if(item.tarifs[0].natureactivite==this.decfiscmens.natureactivite||item.tarifs[0].natureactivite=='')
       {
   
         //@ts-ignore
       if(item.tarifs[0].activite==this.decfiscmens.activite||item.tarifs[0].activite=='')
       {
   
         //@ts-ignore
       if(item.tarifs[0].sousactivite==this.decfiscmens.sousactivite||item.tarifs[0].sousactivite=='')
       {
   
         //@ts-ignore
       if(item.tarifs[0].impot==this.decfiscmens.regimefiscalimpot||item.tarifs[0].impot=='')
       {
   
         this.prixminutepref=item.tarifs[0].prix
   
       
       }
       }
       }
       }
        }
       }
       //@ts-ignore
       else if(item.tarifs[0].type=='Tarif de base'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
       //@ts-ignore
       &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
       {
   
         
        this.prixminute=item.tarifs[0].prix
   
        
       }
              },
             (error) => {
               this.loading = false;
               this.errormsg=error.message;
             }
           );
   this.tarifs.forEach(async (item, index) => {
   //@ts-ignore
   if(item.tarifs[0].type=='Tarif spécial'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
   //@ts-ignore
   &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
   {
   //@ts-ignore
    if(item.tarifs[0].nature==this.decfiscmens.nature||item.tarifs[0].nature=='')
   {
  
     //@ts-ignore
   if(item.tarifs[0].natureactivite==this.decfiscmens.natureactivite||item.tarifs[0].natureactivite=='')
   {
  
     //@ts-ignore
   if(item.tarifs[0].activite==this.decfiscmens.activite||item.tarifs[0].activite=='')
   {
  
     //@ts-ignore
   if(item.tarifs[0].sousactivite==this.decfiscmens.sousactivite||item.tarifs[0].sousactivite=='')
   {
  
     //@ts-ignore
   if(item.tarifs[0].impot==this.decfiscmens.regimefiscalimpot||item.tarifs[0].impot=='')
   {
  
     this.prixminutepref=item.tarifs[0].prix
   this.totalhonoraire= Math.round((+this.totaltime * +this.prixminutepref) *1000)/1000
  
   
   }
   }
   }
   }
    }
   }
   //@ts-ignore
   else if(item.tarifs[0].type=='Tarif de base'&&new Date(item.tarifs[0].debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
   //@ts-ignore
   &&new Date(item.tarifs[0].fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
   {
  
     
    this.prixminute=item.tarifs[0].prix
    this.totalhonoraire= Math.round((+this.totaltime * +this.prixminute) * 1000)/1000
  
    
   }
          },
         (error) => {
           this.loading = false;
           this.errormsg=error.message;
         }
       );
      
    }
  )
  }
  
  }
            }
            if(this.decfiscmens.statutcollab.length>0)
            {
  //@ts-ignore
  if(this.decfiscmens.statutcollab.find(e => e.statutclient==='déposé par le client'))
  {
   this.showbuttons=true
  }
            }
    
              }
            )
   
  
          }
        );
      }
    )
  }
 )

}

  }
  canDeactivate():boolean {
    this.ngOnDestroy()
    if(this.token.saved)
    {
      return true;
    }
    return false;
  } 
  gettarifbase() {
    this.tarifsbase.forEach(
      async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
//@ts-ignore
&&new Date(item.fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
{
this.prixminute=item.prix
this.totalhonoraire= Math.round((+this.totaltime * +this.prixminute) * 1000)/1000
}
      }
    )
     }
  gettarifspecial() {
    this.tarifsspecial.forEach(
      async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
//@ts-ignore
&&new Date(item.fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
{
//@ts-ignore
if(item.nature==this.user.nature||item.nature=='')
{

//@ts-ignore
if(item.natureactivite==this.user.natureactivite||item.natureactivite=='')
{

//@ts-ignore
if(item.activite==this.user.activite||item.activite=='')
{

//@ts-ignore
if(item.sousactivite==this.user.sousactivite||item.sousactivite=='')
{

//@ts-ignore
if(item.impot==this.user.regimefiscalimpot||item.impot=='')
{
this.prixminutespecial=item.prix
this.totalhonoraire= Math.round((+this.totaltime * +this.prixminutespecial) * 1000)/1000  
}
}
}
}
}
}
      }
    )
    }
  gettarifindiv() {
    this.tarifsclient.forEach(
      async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin)
//@ts-ignore
&&new Date(item.fin)>=new Date(this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].datefin))
{
//@ts-ignore
if(item.user==this.user._id)
{  
this.prixminuteindiv=item.prix
this.totalhonoraire= Math.round((+this.totaltime * +this.prixminuteindiv) * 1000)/1000
console.log(this.totalhonoraire)
}
}
      }
    )
    }
  filterusers(id:string)
{
  if(this.collab.length>0)
{
  this.filtredcollab=this.deccompt.filterByValue(this.collab,id)
  if(this.filtredcollab.length>0)
  {
    this.prenomcollab=this.filtredcollab[0].firstname
    this.nomcollab=this.filtredcollab[0].lastname
  }
}
 
  
}
public payement(): void {
  const self = this;
  const blocs = [
    document.getElementById('traitementdetail'),
    document.getElementById('bloc1title'),
    document.getElementById('bloc1content'),
    document.getElementById('bloc2title'),
    document.getElementById('bloc2content'),
    document.getElementById('bloc3title'),
    document.getElementById('bloc3content'),
    document.getElementById('bloc4title'),
    document.getElementById('bloc4content'),
    document.getElementById('bloc5title'),
    document.getElementById('bloc5content')
  ];

  const container = document.createElement('div');

  // Append all blocks to the container
  blocs.forEach(bloc => {
    if (bloc) {
      const clonedBloc = bloc.cloneNode(true); // Clone the node to avoid moving it
      container.appendChild(clonedBloc);
    }
  });

  document.body.appendChild(container); // Append the container to the body
console.log(container)
  // Delay execution to ensure elements are fully rendered
  setTimeout(() => {
    html2canvas(container, { scale: 2 }).then((canvas: any) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }

      doc.save(`facture_${self.decfiscmens.mois}_${self.decfiscmens.annee}`);
    });
  // Remove the container from the DOM after PDF generation
  document.body.removeChild(container);
  }, 500); // Delay execution by 500 milliseconds (adjust as needed)
}

  public openPDF(): void {
    
this.loading=true
const self =this
const data = document.getElementById('deccont');
data.style.width="1230px"
data.style.height="100%"
console.log(data.style.width)
console.log(data.style.height)
html2canvas(data,{scale:2}).then((canvas:any) => {
  const imgWidth = 208;
  const pageHeight = 295;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;
  heightLeft -= pageHeight;
  const doc = new jsPDF('p', 'mm');
  doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    heightLeft -= pageHeight;
  }
  setTimeout(() => {self.loading=false
        
  }, 5000); 
  doc.save(`Déclaration mensuelle_${self.decfiscmens.mois}_${self.decfiscmens.annee}`);
  data.style.width="100%"
  data.style.height="100%"
});



  } 

  load(){
    const self =this
    var url = "https://res.cloudinary.com/dfodbn7qv/raw/upload/v1666542457/jwobokudq4bih0xp4j9u.xlsx";
    var oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.responseType = "arraybuffer";
  
    oReq.onload = function(e) {
    var arraybuffer = oReq.response;
  
  
    /* convert data to binary string */
    var data = new Uint8Array(arraybuffer);
    var arr = new Array();
    for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");
  
    /* Call XLSX */
    var workbook = XLSX.read(bstr, {type:"binary"});
  
    /* DO SOMETHING WITH workbook HERE */
    var first_sheet_name = workbook.SheetNames[0];
    /* Get worksheet */
    var worksheet = workbook.Sheets[first_sheet_name];
    let x = XLSX.utils.sheet_to_json(worksheet,{raw:true});
    console.log(x);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(x);
    console.log('worksheet',worksheet2);
    const workbook2: XLSX.WorkBook = { Sheets: { 'data': worksheet2 }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook2, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    self.saveAsExcelFile(excelBuffer, 'test');
  }
  
  oReq.send();
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      //FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    load2 () {
      const self = this
      fetch("https://res.cloudinary.com/dfodbn7qv/raw/upload/v1666542457/jwobokudq4bih0xp4j9u.xlsx") // fetch the remote url
        .then((response) => {
          response.blob().then((fileBlob) => { // convert the excel file to blob
          let file = new File([fileBlob], "Sample.xlsx"); //convert the blob into file
                   })
        })
        self.maincontainer=true
    }
 
  getNavigation(link, id){
      
    this.router.navigate([link + '/' + id]);
      
  }
  bottom(): void {
    this.scroller.scrollToAnchor("bottom");
  }
  top(): void {
    this.scroller.scrollToAnchor("top");
  }
  async decideadmin()
  {
    this.loading = true;
    const decfiscmens = new Decfiscmens();
    this.dec.getDecfiscmensreqById(this.decfiscmens._id).then(
      async (dec:Decfiscmens)=>{
        decfiscmens.impottype1={ type: dec.impottype1.type, traitementetsalaire: { salairebrut:dec.impottype1.traitementetsalaire.salairebrut, 
          salaireimposable: dec.impottype1.traitementetsalaire.salaireimposable, retenuealasource:dec.impottype1.traitementetsalaire.retenuealasource,
          contributionsociale:dec.impottype1.traitementetsalaire.contributionsociale, }, 
        location1: { type: dec.impottype1.location1.type,montantbrut:dec.impottype1.location1.montantbrut, taux:dec.impottype1.location1.taux,
           montantnet:dec.impottype1.location1.montantnet, montantretenue:dec.impottype1.location1.montantretenue, },
           location2: { type: dec.impottype1.location2.type,montantbrut:dec.impottype1.location2.montantbrut, taux:dec.impottype1.location2.taux,
            montantnet:dec.impottype1.location2.montantnet, montantretenue:dec.impottype1.location2.montantretenue, },
            location3: { type: dec.impottype1.location3.type,montantbrut:dec.impottype1.location3.montantbrut, taux:dec.impottype1.location3.taux,
              montantnet:dec.impottype1.location3.montantnet, montantretenue:dec.impottype1.location3.montantretenue, },
              location4: { type: dec.impottype1.location4.type,montantbrut:dec.impottype1.location4.montantbrut, taux:dec.impottype1.location4.taux,
                montantnet:dec.impottype1.location4.montantnet, montantretenue:dec.impottype1.location4.montantretenue, },
         honoraire1: {  type:dec.impottype1.honoraire1.type,montantbrut:dec.impottype1.honoraire1.montantbrut, taux:dec.impottype1.honoraire1.taux,
           montantnet:dec.impottype1.honoraire1.montantnet, montantretenue:dec.impottype1.honoraire1.montantretenue,},
      
         honoraire2: {  type:dec.impottype1.honoraire2.type,montantbrut:dec.impottype1.honoraire2.montantbrut, taux:dec.impottype1.honoraire2.taux,
           montantnet:dec.impottype1.honoraire2.montantnet, montantretenue:dec.impottype1.honoraire2.montantretenue,},
      
         honoraire3: {  type:dec.impottype1.honoraire3.type,montantbrut:dec.impottype1.honoraire3.montantbrut, taux:dec.impottype1.honoraire3.taux,
           montantnet:dec.impottype1.honoraire3.montantnet, montantretenue:dec.impottype1.honoraire3.montantretenue,},
           montant10001: {  type:dec.impottype1.montant10001.type,montantbrut:dec.impottype1.montant10001.montantbrut, taux:dec.impottype1.montant10001.taux,
          montantnet:dec.impottype1.montant10001.montantnet, montantretenue:dec.impottype1.montant10001.montantretenue,},
         montant10002: {  type:dec.impottype1.montant10002.type,montantbrut:dec.impottype1.montant10002.montantbrut,taux:dec.impottype1.montant10002.taux,
           montantnet:dec.impottype1.montant10002.montantnet, montantretenue:dec.impottype1.montant10002.montantretenue,},
           montant10003: {  type:dec.impottype1.montant10003.type,montantbrut:dec.impottype1.montant10003.montantbrut, taux:dec.impottype1.montant10003.taux,
             montantnet:dec.impottype1.montant10003.montantnet, montantretenue:dec.impottype1.montant10003.montantretenue,}, 
        montant10004: {  type:dec.impottype1.montant10004.type,montantbrut:dec.impottype1.montant10004.montantbrut, taux:dec.impottype1.montant10004.taux,
          montantnet:dec.impottype1.montant10004.montantnet, montantretenue:dec.impottype1.montant10004.montantretenue,},
           autre: dec.impottype1.autre}
        decfiscmens.impottype2={ type:dec.impottype2.type,reporttvamoisprecedent:dec.impottype2.reporttvamoisprecedent
          ,montantexonere:dec.impottype2.montantexonere
          ,montantexport:dec.impottype2.montantexport
          ,montantsuspension:dec.impottype2.montantsuspension
          ,tvacollecter:{
          type:dec.impottype2.tvacollecter.type,
          chiffreaffaireht:dec.impottype2.tvacollecter.chiffreaffaireht,
          tvaammount:dec.impottype2.tvacollecter.tvaammount,
          ammountttc:dec.impottype2.tvacollecter.ammountttc,
          
          },tvacollecter19:{
            type:dec.impottype2.tvacollecter.type,
            chiffreaffaireht:dec.impottype2.tvacollecter19.chiffreaffaireht,
            tvaammount:dec.impottype2.tvacollecter19.tvaammount,
            ammountttc:dec.impottype2.tvacollecter19.ammountttc,
            
            },tvacollecter13:{
              type:dec.impottype2.tvacollecter.type,
              chiffreaffaireht:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.chiffreaffaireht:null,
              tvaammount:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.tvaammount:null,
              ammountttc:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.ammountttc:null,
              
              },tvarecuperableimmobilier:{
              type:dec.impottype2.tvarecuperableimmobilier.type,
          achatlocauxht:dec.impottype2.tvarecuperableimmobilier.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableimmobilier.achatlocauxtva,
          
          
          },
          tvarecuperableequipement:{
              type:dec.impottype2.tvarecuperableequipement.type,
          achatlocauxht:dec.impottype2.tvarecuperableequipement.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableequipement.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableequipement.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableequipement.achatimportetva,
          
          
          },
          tvarecuperableautreachat:{
              type:dec.impottype2.tvarecuperableautreachat.type,
          achatlocauxht:dec.impottype2.tvarecuperableautreachat.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableautreachat.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableautreachat.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableautreachat.achatimportetva,
          
          
          },
          locationhabitationmeuble:{
              type:dec.impottype2.locationhabitationmeuble.type,
              htammount:dec.impottype2.locationhabitationmeuble.htammount,
              tvaammount:dec.impottype2.locationhabitationmeuble.tvaammount,
              ttcammount:dec.impottype2.locationhabitationmeuble.ttcammount,
              },
          locationusagecommercial:{
            type:dec.impottype2.locationusagecommercial.type,
            htammount:dec.impottype2.locationusagecommercial.htammount,
            tvaammount:dec.impottype2.locationusagecommercial.tvaammount,
            ttcammount:dec.impottype2.locationusagecommercial.ttcammount,
                  },
          operationlotissement:{
            type:dec.impottype2.operationlotissement.type,
            htammount:dec.impottype2.operationlotissement.htammount,
            tvaammount:dec.impottype2.operationlotissement.tvaammount,
            ttcammount:dec.impottype2.operationlotissement.ttcammount,
                      },
          interetpercue:{
            type:dec.impottype2.interetpercue.type,
            htammount:dec.impottype2.interetpercue.htammount,
            tvaammount:dec.impottype2.interetpercue.tvaammount,
            ttcammount:dec.impottype2.interetpercue.ttcammount,
                          },
          autretvaspecial:{
            type:dec.impottype2.autretvaspecial.type,
            htammount:dec.impottype2.autretvaspecial.htammount,
            tvaammount:dec.impottype2.autretvaspecial.tvaammount,
            ttcammount:dec.impottype2.autretvaspecial.ttcammount,
                              taux:dec.impottype2.autretvaspecial.taux,
                              }    }
                              decfiscmens.impottype3={ type:dec.impottype3.type,
                                basetfp:dec.impottype3.basetfp,
                                                          tfpsalairebrut:dec.impottype3.tfpsalairebrut,
                                montanttfpmois:dec.impottype3.montanttfpmois,
                                reporttfpmoisprecedent:dec.impottype3.reporttfpmoisprecedent,
                                montantavance:dec.impottype3.montantavance,
                                tfppayer:dec.impottype3.tfppayer,
                                tfpreporter:dec.impottype3.tfpreporter,
                                salairesnonsoumistfp:dec.impottype3.salairesnonsoumistfp}
                                decfiscmens.impottype4={ type:dec.impottype4.type,
                                basefoprolos:dec.impottype4.basefoprolos,
                                foprolossalairebrut:dec.impottype4.foprolossalairebrut,
                                montantfoprolos:dec.impottype4.montantfoprolos,
                                salairesnonsoumisfoprolos:dec.impottype4.salairesnonsoumisfoprolos}
                                decfiscmens.impottype5={ type:dec.impottype5.type,
                                nombrenotehonoraire:dec.impottype5.nombrenotehonoraire,
                    totaldroittimbre:dec.impottype5.totaldroittimbre,}
                    decfiscmens.impottype6={ type:dec.impottype6.type,
                    chiffreaffairettc:dec.impottype6.chiffreaffairettc,
                    tclpayer:dec.impottype6.tclpayer,}
                    decfiscmens.impottype7={ type:dec.impottype7.type,
                      chiffreaffaireht:dec.impottype7.chiffreaffaireht,
                      montantcontribution:dec.impottype7.montantcontribution,}
        decfiscmens.statutadmin=dec.statutadmin
        decfiscmens.statutcollab=dec.statutcollab
        this.commun.getcurrenttime().then(
          async (data:any) => {
         //@ts-ignore
         if(dec.statutadmin.length>0)
         {
           //@ts-ignore
           if(dec.statutadmin[dec.statutadmin.length-1].statut=='en cours de supervision')
           { 
             Swal.fire({
               title: 'Veuillez choisir entre les alternatives suivantes!',
               input: 'text',
               inputLabel: 'motif(facultatif)',
               inputValue: '',
               returnInputValueOnDeny: true,
               icon: 'info',
               showDenyButton: true,
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#555',
               confirmButtonText: 'marquer comme supervisé',
               cancelButtonText: 'Annuler',
               denyButtonText: 'à rectifier',
               
               }).then((result) => {
                
               if (result.isConfirmed) {
                 
                 decfiscmens.statutadmin.push
                 //@ts-ignore
                 ({
                   statut:'supervisé',
                   motif:result.value,
                   datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                 })
                 this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
                   (data:any) => {
                     this.loading = false;
                     Swal.fire({
                       position: 'center',
                       icon: 'success',
                       title: 'déclaration modifiée avec succès',
                       showConfirmButton: false,
                       timer: 3000
                     });
                     this.router.navigate(['admin-board']);
                   },
                   (error) => {
                     this.loading = false;
                     
                     window.scrollTo(0, 0);  
                   }
                 );        
               }
               else if (result.isDenied)
               {
                 decfiscmens.statutadmin.push
                 //@ts-ignore
                 ({
                   statut:'à rectifier',
                   motif:result.value,
                   datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                 })
                 decfiscmens.statutcollab.push
                 //@ts-ignore
                 ({
                   statutcoll:'en cours de traitement',
                   motifcoll:'',
                   datefin:data,
                   duree:'',     
                 })
                 this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
                   (data:any) => {
                     this.loading = false;
                     Swal.fire({
                       position: 'center',
                       icon: 'success',
                       title: 'déclaration modifiée avec succès',
                       showConfirmButton: false,
                       timer: 3000
                     });
                     this.router.navigate(['admin-board']);
                   },
                   (error) => {
                     this.loading = false;
                     
                     window.scrollTo(0, 0);  
                   }
                 ); 
               }
               
               }).catch(() => {
               Swal.fire('opération non aboutie!');
               });
           }
           //@ts-ignore
           if(dec.statutadmin[dec.statutadmin.length-1].statut=='en cours de validation')
           { 
             await Swal.fire({
               title: 'Veuillez choisir entre les alternatives suivantes!',
               input: 'text',
               inputLabel: 'motif(facultatif)',
               inputValue: '',
               returnInputValueOnDeny: true,
               icon: 'info',
               showDenyButton: true,
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#555',
               confirmButtonText: 'marquer comme validé',
               cancelButtonText: 'Annuler',
               denyButtonText: 'à rectifier',
               
             }).then((result) => {
               if (result.isConfirmed) 
               {
                 decfiscmens.statutadmin.push
                 //@ts-ignore
                 ({
                   statut:'validé',
                   motif:result.value,
                   datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                 })
                 this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
                   (data:any) => {
                     this.loading = false;
                     Swal.fire({
                       position: 'center',
                       icon: 'success',
                       title: 'déclaration modifiée avec succès',
                       showConfirmButton: false,
                       timer: 3000
                     });
                     this.router.navigate(['admin-board']);
                   },
                   (error) => {
                     this.loading = false;
                     
                     window.scrollTo(0, 0);  
                   }
                 );        
               }
               else if (result.isDenied)
               {
                 decfiscmens.statutadmin.push
                 //@ts-ignore
                 ({
                   statut:'à rectifier',
                   motif:result.value,
                   datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                 })
                 decfiscmens.statutcollab.push
                 //@ts-ignore
                 ({
                   statutcoll:'en cours de traitement',
                   motifcoll:'',
                   datefin:data,
                   duree:'',     
                 })
                 this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
                   (data:any) => {
                     this.loading = false;
                     Swal.fire({
                       position: 'center',
                       icon: 'success',
                       title: 'déclaration modifiée avec succès',
                       showConfirmButton: false,
                       timer: 3000
                     });
                     this.router.navigate(['admin-board']);
                   },
                   (error) => {
                     this.loading = false;
                     
                     window.scrollTo(0, 0);  
                   }
                 ); 
               }
               
               }).catch(() => {
               Swal.fire('opération non aboutie!');
               });
           }
           //@ts-ignore
           if(dec.statutadmin[dec.statutadmin.length-1].statut=='en cours de clôture')
           { 
             Swal.fire({
               title: 'Veuillez choisir entre les alternatives suivantes!',
               
               input: 'text',
               inputLabel: 'motif(facultatif)',
               inputValue: '',
               returnInputValueOnDeny: true,
               icon: 'info',
               showDenyButton: true,
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#555',
               confirmButtonText: 'marquer comme clôturé',
               cancelButtonText: 'Annuler',
               denyButtonText: 'à rectifier',
               
               }).then((result) => {
               if (result.isConfirmed) {
                 decfiscmens.statutadmin.push
                 //@ts-ignore
                 ({
                   statut:'clôturé',
                   motif:result.value,
                   datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                 })
                 this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
                   (data:any) => {
                     this.loading = false;
                     Swal.fire({
                       position: 'center',
                       icon: 'success',
                       title: 'déclaration modifiée avec succès',
                       showConfirmButton: false,
                       timer: 3000
                     });
                     const copiedecfiscmens:Decfiscmens = decfiscmens;

                     let indexcloturefisc=[]
                     let allstatutsfisc=[]
                    // copiedecfiscmens.statutadmin=[]
                    // copiedecfiscmens.statutcollab=[]
                     //copiedecfiscmens.statutparam=[]
                    // allstatutsfisc=allstatutsfisc.concat(decfiscmens[0].statutadmin,decfiscmens[0].statutcollab,decfiscmens[0].statutparam)
                    decfiscmens.statutadmin.forEach(
                   (e,index)=>
                   {
                     //@ts-ignore
                 e.statut=='clôturé'?indexcloturefisc.push(e.datefin):''
                   }
                 )
                 indexcloturefisc.length==1?
                 (copiedecfiscmens.statutadmin=decfiscmens.statutadmin,
                   copiedecfiscmens.statutcollab=decfiscmens.statutcollab,
                   copiedecfiscmens.statutparam=decfiscmens.statutparam)
                 :
                 //@ts-ignore
                (copiedecfiscmens.statutadmin=decfiscmens.statutadmin.filter(e=>e.datefin>indexcloturefisc[indexcloturefisc.length-2]),
                //@ts-ignore
                copiedecfiscmens.statutcollab=decfiscmens.statutcollab.filter(e=>e.datefin>indexcloturefisc[indexcloturefisc.length-2]),
                //@ts-ignore
                copiedecfiscmens.statutparam=decfiscmens.statutparam.filter(e=>e.datefin>indexcloturefisc[indexcloturefisc.length-2])) 
                     copiedecfiscmens.source='copie fiscalité'
                     copiedecfiscmens._id=null
                     allstatutsfisc=allstatutsfisc.concat(copiedecfiscmens.statutadmin,copiedecfiscmens.statutcollab,copiedecfiscmens.statutparam)
let totaltimefisc = Math.floor(+allstatutsfisc.reduce((acc,curr)=>{
acc +=  +(curr.duree || 0);
return acc;
},0)/60);
                     this.prixminuteindiv?
                     (copiedecfiscmens.totalhonoraire= Math.round((Math.floor(+totaltimefisc) * +this.prixminuteindiv) * 1000)/1000,
                     copiedecfiscmens.totaltime=totaltimefisc,
                     copiedecfiscmens.tauxapplique=this.prixminuteindiv):
                       this.prixminutespecial?
                       (copiedecfiscmens.totalhonoraire= Math.round((Math.floor(+totaltimefisc) * +this.prixminutespecial) * 1000)/1000,
                       copiedecfiscmens.totaltime=totaltimefisc,
                       copiedecfiscmens.tauxapplique=this.prixminutespecial):
                       (copiedecfiscmens.totalhonoraire= Math.round((Math.floor(+totaltimefisc) * +this.prixminute) * 1000)/1000,
                       copiedecfiscmens.totaltime=totaltimefisc,
                       copiedecfiscmens.tauxapplique=this.prixminute)
                     copiedecfiscmens.created=new Date(data)
    
                     this.dec.create(copiedecfiscmens).then(
                      (data:any) => {
                        this.loading = false;                    
                      },
                      (error) => {
                        this.loading = false;                       
                      }
                     )  
                     this.router.navigate(['admin-board']);
                   },
                   (error) => {
                     this.loading = false;
                     
                     window.scrollTo(0, 0);  
                   }
                 );
              
                   
               }
               else if (result.isDenied)
               {
                 decfiscmens.statutadmin.push
                 //@ts-ignore
                 ({
                   statut:'à rectifier',
                   motif:result.value,
                   datefin:data,
                    duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                 })
                 decfiscmens.statutcollab.push
                 //@ts-ignore
                 ({
                   statutcoll:'en cours de traitement',
                   motifcoll:'',
                   datefin:data,
                   duree:'',     
                 })
                 this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
                   (data:any) => {
                     this.loading = false;
                     Swal.fire({
                       position: 'center',
                       icon: 'success',
                       title: 'déclaration modifiée avec succès',
                       showConfirmButton: false,
                       timer: 3000
                     });
                     this.router.navigate(['admin-board']);
                   },
                   (error) => {
                     this.loading = false;
                     
                     window.scrollTo(0, 0);  
                   }
                 ); 
               }
               
               }).catch(() => {
               Swal.fire('opération non aboutie!');
               });
           }
           
         }
          }
        )

      }
    )
   
                 
                  
   
  }
  async traite()
  {

    this.loading = true;
    const decfiscmens = new Decfiscmens();
    this.dec.getDecfiscmensreqById(this.decfiscmens._id).then(
      async (dec:Decfiscmens)=>{
        decfiscmens.impottype1={ type: this.decfiscmens.impottype1.type, traitementetsalaire: { salairebrut:this.decfiscmens.impottype1.traitementetsalaire.salairebrut, 
          salaireimposable: dec.impottype1.traitementetsalaire.salaireimposable, retenuealasource:dec.impottype1.traitementetsalaire.retenuealasource,
          contributionsociale:dec.impottype1.traitementetsalaire.contributionsociale, }, 
        location1: { type: dec.impottype1.location1.type,montantbrut:dec.impottype1.location1.montantbrut, taux:dec.impottype1.location1.taux,
           montantnet:dec.impottype1.location1.montantnet, montantretenue:dec.impottype1.location1.montantretenue, },
           location2: { type: dec.impottype1.location2.type,montantbrut:dec.impottype1.location2.montantbrut, taux:dec.impottype1.location2.taux,
            montantnet:dec.impottype1.location2.montantnet, montantretenue:dec.impottype1.location2.montantretenue, },
            location3: { type: dec.impottype1.location3.type,montantbrut:dec.impottype1.location3.montantbrut, taux:dec.impottype1.location3.taux,
              montantnet:dec.impottype1.location3.montantnet, montantretenue:dec.impottype1.location3.montantretenue, },
              location4: { type: dec.impottype1.location4.type,montantbrut:dec.impottype1.location4.montantbrut, taux:dec.impottype1.location4.taux,
                montantnet:dec.impottype1.location4.montantnet, montantretenue:dec.impottype1.location4.montantretenue, },
         honoraire1: {  type:dec.impottype1.honoraire1.type,montantbrut:dec.impottype1.honoraire1.montantbrut, taux:dec.impottype1.honoraire1.taux,
           montantnet:dec.impottype1.honoraire1.montantnet, montantretenue:dec.impottype1.honoraire1.montantretenue,},
      
         honoraire2: {  type:dec.impottype1.honoraire2.type,montantbrut:dec.impottype1.honoraire2.montantbrut, taux:dec.impottype1.honoraire2.taux,
           montantnet:dec.impottype1.honoraire2.montantnet, montantretenue:dec.impottype1.honoraire2.montantretenue,},
      
         honoraire3: {  type:dec.impottype1.honoraire3.type,montantbrut:dec.impottype1.honoraire3.montantbrut, taux:dec.impottype1.honoraire3.taux,
           montantnet:dec.impottype1.honoraire3.montantnet, montantretenue:dec.impottype1.honoraire3.montantretenue,},
           montant10001: {  type:dec.impottype1.montant10001.type,montantbrut:dec.impottype1.montant10001.montantbrut, taux:dec.impottype1.montant10001.taux,
          montantnet:dec.impottype1.montant10001.montantnet, montantretenue:dec.impottype1.montant10001.montantretenue,},
         montant10002: {  type:dec.impottype1.montant10002.type,montantbrut:dec.impottype1.montant10002.montantbrut,taux:dec.impottype1.montant10002.taux,
           montantnet:dec.impottype1.montant10002.montantnet, montantretenue:dec.impottype1.montant10002.montantretenue,},
           montant10003: {  type:dec.impottype1.montant10003.type,montantbrut:dec.impottype1.montant10003.montantbrut, taux:dec.impottype1.montant10003.taux,
             montantnet:dec.impottype1.montant10003.montantnet, montantretenue:dec.impottype1.montant10003.montantretenue,}, 
        montant10004: {  type:dec.impottype1.montant10004.type,montantbrut:dec.impottype1.montant10004.montantbrut, taux:dec.impottype1.montant10004.taux,
          montantnet:dec.impottype1.montant10004.montantnet, montantretenue:dec.impottype1.montant10004.montantretenue,},
           autre: dec.impottype1.autre}
        decfiscmens.impottype2={ type:dec.impottype2.type,reporttvamoisprecedent:dec.impottype2.reporttvamoisprecedent
          ,montantexonere:dec.impottype2.montantexonere
          ,montantexport:dec.impottype2.montantexport
          ,montantsuspension:dec.impottype2.montantsuspension
          ,tvacollecter:{
          type:dec.impottype2.tvacollecter.type,
          chiffreaffaireht:dec.impottype2.tvacollecter.chiffreaffaireht,
          tvaammount:dec.impottype2.tvacollecter.tvaammount,
          ammountttc:dec.impottype2.tvacollecter.ammountttc,
          
          },tvacollecter19:{
            type:dec.impottype2.tvacollecter.type,
            chiffreaffaireht:dec.impottype2.tvacollecter19.chiffreaffaireht,
            tvaammount:dec.impottype2.tvacollecter19.tvaammount,
            ammountttc:dec.impottype2.tvacollecter19.ammountttc,
            
            },tvacollecter13:{
              type:dec.impottype2.tvacollecter.type,
              chiffreaffaireht:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.chiffreaffaireht:null,
              tvaammount:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.tvaammount:null,
              ammountttc:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.ammountttc:null,
              
              },tvarecuperableimmobilier:{
              type:dec.impottype2.tvarecuperableimmobilier.type,
          achatlocauxht:dec.impottype2.tvarecuperableimmobilier.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableimmobilier.achatlocauxtva,
          
          
          },
          tvarecuperableequipement:{
              type:dec.impottype2.tvarecuperableequipement.type,
          achatlocauxht:dec.impottype2.tvarecuperableequipement.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableequipement.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableequipement.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableequipement.achatimportetva,
          
          
          },
          tvarecuperableautreachat:{
              type:dec.impottype2.tvarecuperableautreachat.type,
          achatlocauxht:dec.impottype2.tvarecuperableautreachat.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableautreachat.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableautreachat.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableautreachat.achatimportetva,
          
          
          },
          locationhabitationmeuble:{
              type:dec.impottype2.locationhabitationmeuble.type,
              htammount:dec.impottype2.locationhabitationmeuble.htammount,
              tvaammount:dec.impottype2.locationhabitationmeuble.tvaammount,
              ttcammount:dec.impottype2.locationhabitationmeuble.ttcammount,
              },
          locationusagecommercial:{
            type:dec.impottype2.locationusagecommercial.type,
            htammount:dec.impottype2.locationusagecommercial.htammount,
            tvaammount:dec.impottype2.locationusagecommercial.tvaammount,
            ttcammount:dec.impottype2.locationusagecommercial.ttcammount,
                  },
          operationlotissement:{
            type:dec.impottype2.operationlotissement.type,
            htammount:dec.impottype2.operationlotissement.htammount,
            tvaammount:dec.impottype2.operationlotissement.tvaammount,
            ttcammount:dec.impottype2.operationlotissement.ttcammount,
                      },
          interetpercue:{
            type:dec.impottype2.interetpercue.type,
            htammount:dec.impottype2.interetpercue.htammount,
            tvaammount:dec.impottype2.interetpercue.tvaammount,
            ttcammount:dec.impottype2.interetpercue.ttcammount,
                          },
          autretvaspecial:{
            type:dec.impottype2.autretvaspecial.type,
            htammount:dec.impottype2.autretvaspecial.htammount,
            tvaammount:dec.impottype2.autretvaspecial.tvaammount,
            ttcammount:dec.impottype2.autretvaspecial.ttcammount,
                              taux:dec.impottype2.autretvaspecial.taux,
                              }    }
                              decfiscmens.impottype3={ type:dec.impottype3.type,
                                basetfp:dec.impottype3.basetfp,
                                                          tfpsalairebrut:dec.impottype3.tfpsalairebrut,
                                montanttfpmois:dec.impottype3.montanttfpmois,
                                reporttfpmoisprecedent:dec.impottype3.reporttfpmoisprecedent,
                                montantavance:dec.impottype3.montantavance,
                                tfppayer:dec.impottype3.tfppayer,
                                tfpreporter:dec.impottype3.tfpreporter,
                                salairesnonsoumistfp:dec.impottype3.salairesnonsoumistfp}
                                decfiscmens.impottype4={ type:dec.impottype4.type,
                                basefoprolos:dec.impottype4.basefoprolos,
                                foprolossalairebrut:dec.impottype4.foprolossalairebrut,
                                montantfoprolos:dec.impottype4.montantfoprolos,
                                salairesnonsoumisfoprolos:dec.impottype4.salairesnonsoumisfoprolos}
                                decfiscmens.impottype5={ type:dec.impottype5.type,
                                nombrenotehonoraire:dec.impottype5.nombrenotehonoraire,
                    totaldroittimbre:dec.impottype5.totaldroittimbre,}
                    decfiscmens.impottype6={ type:dec.impottype6.type,
                    chiffreaffairettc:dec.impottype6.chiffreaffairettc,
                    tclpayer:dec.impottype6.tclpayer,}
                    decfiscmens.impottype7={ type:dec.impottype7.type,
                      chiffreaffaireht:dec.impottype7.chiffreaffaireht,
                      montantcontribution:dec.impottype7.montantcontribution,}
                      decfiscmens.statutadmin=dec.statutadmin
                      decfiscmens.statutcollab=dec.statutcollab
                    
                       //@ts-ignore
      if(dec.statutcollab.length>0)
      {
        //@ts-ignore
        if(dec.statutcollab[dec.statutcollab.length-1].statutcoll!='traité')
        { 
          await Swal.fire({
            title: 'Veuillez choisir entre les alternatives suivantes!',
            input: 'text',
            inputLabel: 'motif(facultatif)',
            inputValue: '',
            returnInputValueOnDeny: true,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#555',
            confirmButtonText: 'marquer comme traité',
            cancelButtonText: 'Annuler',
            
          }).then((result) => {
            this.commun.getcurrenttime().then(
              (data:any) => {
                if (result.isConfirmed) 
                {
                  decfiscmens.statutcollab.push
                  //@ts-ignore
                  ({
                    statutcoll:'traité',
                    motifcoll:result.value,
                    datefin:data,
                     duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                  })
        
         
                  this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
                    (data:any) => {
                      this.loading = false;
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'déclaration traité avec succès',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      //this.router.navigate(['collab-board']);
                    },
                    (error) => {
                      this.loading = false;
                      
                      window.scrollTo(0, 0);  
                    }
                  );
                }
              }
            )
      
         
          
        }
          )
      }
        else
        {
          this.router.navigate(['collab-board']);
        }
      }
      }
    )
   
   
  }
  update(e){
    this.selected = e.target.value   
    if(this.selected=='supprimer le dernier statut collaborateur')
    {
      this.deletestatutcollab()
    }
    else if(this.selected=='supprimer le dernier statut paramêtrage')
    {
      this.deletestatutparam()
    }
    else
    {
      this.deletestatutadmin()
    }
  }
  deletestatutcollab()
  {
    this.loading = true;
    const decfiscmens = new Decfiscmens();
    this.dec.getDecfiscmensreqById(this.decfiscmens._id).then(
      (dec:Decfiscmens)=>{
        decfiscmens.impottype1={ type: dec.impottype1.type, traitementetsalaire: { salairebrut:dec.impottype1.traitementetsalaire.salairebrut, 
          salaireimposable: dec.impottype1.traitementetsalaire.salaireimposable, retenuealasource:dec.impottype1.traitementetsalaire.retenuealasource,
          contributionsociale:dec.impottype1.traitementetsalaire.contributionsociale, }, 
        location1: { type: dec.impottype1.location1.type,montantbrut:dec.impottype1.location1.montantbrut, taux:dec.impottype1.location1.taux,
           montantnet:dec.impottype1.location1.montantnet, montantretenue:dec.impottype1.location1.montantretenue, },
           location2: { type: dec.impottype1.location2.type,montantbrut:dec.impottype1.location2.montantbrut, taux:dec.impottype1.location2.taux,
            montantnet:dec.impottype1.location2.montantnet, montantretenue:dec.impottype1.location2.montantretenue, },
            location3: { type: dec.impottype1.location3.type,montantbrut:dec.impottype1.location3.montantbrut, taux:dec.impottype1.location3.taux,
              montantnet:dec.impottype1.location3.montantnet, montantretenue:dec.impottype1.location3.montantretenue, },
              location4: { type: dec.impottype1.location4.type,montantbrut:dec.impottype1.location4.montantbrut, taux:dec.impottype1.location4.taux,
                montantnet:dec.impottype1.location4.montantnet, montantretenue:dec.impottype1.location4.montantretenue, },
         honoraire1: {  type:dec.impottype1.honoraire1.type,montantbrut:dec.impottype1.honoraire1.montantbrut, taux:dec.impottype1.honoraire1.taux,
           montantnet:dec.impottype1.honoraire1.montantnet, montantretenue:dec.impottype1.honoraire1.montantretenue,},
      
         honoraire2: {  type:dec.impottype1.honoraire2.type,montantbrut:dec.impottype1.honoraire2.montantbrut, taux:dec.impottype1.honoraire2.taux,
           montantnet:dec.impottype1.honoraire2.montantnet, montantretenue:dec.impottype1.honoraire2.montantretenue,},
      
         honoraire3: {  type:dec.impottype1.honoraire3.type,montantbrut:dec.impottype1.honoraire3.montantbrut, taux:dec.impottype1.honoraire3.taux,
           montantnet:dec.impottype1.honoraire3.montantnet, montantretenue:dec.impottype1.honoraire3.montantretenue,},
           montant10001: {  type:dec.impottype1.montant10001.type,montantbrut:dec.impottype1.montant10001.montantbrut, taux:dec.impottype1.montant10001.taux,
          montantnet:dec.impottype1.montant10001.montantnet, montantretenue:dec.impottype1.montant10001.montantretenue,},
         montant10002: {  type:dec.impottype1.montant10002.type,montantbrut:dec.impottype1.montant10002.montantbrut,taux:dec.impottype1.montant10002.taux,
           montantnet:dec.impottype1.montant10002.montantnet, montantretenue:dec.impottype1.montant10002.montantretenue,},
           montant10003: {  type:dec.impottype1.montant10003.type,montantbrut:dec.impottype1.montant10003.montantbrut, taux:dec.impottype1.montant10003.taux,
             montantnet:dec.impottype1.montant10003.montantnet, montantretenue:dec.impottype1.montant10003.montantretenue,}, 
        montant10004: {  type:dec.impottype1.montant10004.type,montantbrut:dec.impottype1.montant10004.montantbrut, taux:dec.impottype1.montant10004.taux,
          montantnet:dec.impottype1.montant10004.montantnet, montantretenue:dec.impottype1.montant10004.montantretenue,},
           autre: dec.impottype1.autre}
        decfiscmens.impottype2={ type:dec.impottype2.type,reporttvamoisprecedent:dec.impottype2.reporttvamoisprecedent
          ,montantexonere:dec.impottype2.montantexonere
          ,montantexport:dec.impottype2.montantexport
          ,montantsuspension:dec.impottype2.montantsuspension
          ,tvacollecter:{
          type:dec.impottype2.tvacollecter.type,
          chiffreaffaireht:dec.impottype2.tvacollecter.chiffreaffaireht,
          tvaammount:dec.impottype2.tvacollecter.tvaammount,
          ammountttc:dec.impottype2.tvacollecter.ammountttc,
          
          },tvacollecter19:{
            type:dec.impottype2.tvacollecter.type,
            chiffreaffaireht:dec.impottype2.tvacollecter19.chiffreaffaireht,
            tvaammount:dec.impottype2.tvacollecter19.tvaammount,
            ammountttc:dec.impottype2.tvacollecter19.ammountttc,
            
            },tvacollecter13:{
              type:dec.impottype2.tvacollecter.type,
              chiffreaffaireht:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.chiffreaffaireht:null,
              tvaammount:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.tvaammount:null,
              ammountttc:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.ammountttc:null,
              
              },tvarecuperableimmobilier:{
              type:dec.impottype2.tvarecuperableimmobilier.type,
          achatlocauxht:dec.impottype2.tvarecuperableimmobilier.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableimmobilier.achatlocauxtva,
          
          
          },
          tvarecuperableequipement:{
              type:dec.impottype2.tvarecuperableequipement.type,
          achatlocauxht:dec.impottype2.tvarecuperableequipement.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableequipement.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableequipement.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableequipement.achatimportetva,
          
          
          },
          tvarecuperableautreachat:{
              type:dec.impottype2.tvarecuperableautreachat.type,
          achatlocauxht:dec.impottype2.tvarecuperableautreachat.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableautreachat.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableautreachat.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableautreachat.achatimportetva,
          
          
          },
          locationhabitationmeuble:{
              type:dec.impottype2.locationhabitationmeuble.type,
              htammount:dec.impottype2.locationhabitationmeuble.htammount,
              tvaammount:dec.impottype2.locationhabitationmeuble.tvaammount,
              ttcammount:dec.impottype2.locationhabitationmeuble.ttcammount,
              },
          locationusagecommercial:{
            type:dec.impottype2.locationusagecommercial.type,
            htammount:dec.impottype2.locationusagecommercial.htammount,
            tvaammount:dec.impottype2.locationusagecommercial.tvaammount,
            ttcammount:dec.impottype2.locationusagecommercial.ttcammount,
                  },
          operationlotissement:{
            type:dec.impottype2.operationlotissement.type,
            htammount:dec.impottype2.operationlotissement.htammount,
            tvaammount:dec.impottype2.operationlotissement.tvaammount,
            ttcammount:dec.impottype2.operationlotissement.ttcammount,
                      },
          interetpercue:{
            type:dec.impottype2.interetpercue.type,
            htammount:dec.impottype2.interetpercue.htammount,
            tvaammount:dec.impottype2.interetpercue.tvaammount,
            ttcammount:dec.impottype2.interetpercue.ttcammount,
                          },
          autretvaspecial:{
            type:dec.impottype2.autretvaspecial.type,
            htammount:dec.impottype2.autretvaspecial.htammount,
            tvaammount:dec.impottype2.autretvaspecial.tvaammount,
            ttcammount:dec.impottype2.autretvaspecial.ttcammount,
                              taux:dec.impottype2.autretvaspecial.taux,
                              }    }
                              decfiscmens.impottype3={ type:dec.impottype3.type,
                                basetfp:dec.impottype3.basetfp,
                                                          tfpsalairebrut:dec.impottype3.tfpsalairebrut,
                                montanttfpmois:dec.impottype3.montanttfpmois,
                                reporttfpmoisprecedent:dec.impottype3.reporttfpmoisprecedent,
                                montantavance:dec.impottype3.montantavance,
                                tfppayer:dec.impottype3.tfppayer,
                                tfpreporter:dec.impottype3.tfpreporter,
                                salairesnonsoumistfp:dec.impottype3.salairesnonsoumistfp}
                                decfiscmens.impottype4={ type:dec.impottype4.type,
                                basefoprolos:dec.impottype4.basefoprolos,
                                foprolossalairebrut:dec.impottype4.foprolossalairebrut,
                                montantfoprolos:dec.impottype4.montantfoprolos,
                                salairesnonsoumisfoprolos:dec.impottype4.salairesnonsoumisfoprolos}
                                decfiscmens.impottype5={ type:dec.impottype5.type,
                                nombrenotehonoraire:dec.impottype5.nombrenotehonoraire,
                    totaldroittimbre:dec.impottype5.totaldroittimbre,}
                    decfiscmens.impottype6={ type:dec.impottype6.type,
                    chiffreaffairettc:dec.impottype6.chiffreaffairettc,
                    tclpayer:dec.impottype6.tclpayer,}
                    decfiscmens.impottype7={ type:dec.impottype7.type,
                      chiffreaffaireht:dec.impottype7.chiffreaffaireht,
                      montantcontribution:dec.impottype7.montantcontribution,}
        decfiscmens.statutadmin=dec.statutadmin
        decfiscmens.statutcollab=dec.statutcollab
        decfiscmens.statutcollab.pop()
      
                     
          this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
            (data:any) => {
              this.loading = false;
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'statut collaborateur supprimé avec succès',
                showConfirmButton: false,
                timer: 3000
              });
              this.reloadPage()
            },
            (error) => {
              this.loading = false;
              
              window.scrollTo(0, 0);  
            }
          ); 
      }
    )
   

  }
  deletestatutparam()
  {
    this.loading = true;
    this.deccompt.getDeccomptabilitereqById(this.deccomptabilite._id).then(
      (deccomptabilite:Deccomptabilite)=>{
        this.dec.geexistenttdecfiscmens(deccomptabilite.userId,deccomptabilite.annee,deccomptabilite.mois,deccomptabilite.source).then(
          async (decfiscmens: Decfiscmens[]) => {
            if(decfiscmens.length>0)
            {
              deccomptabilite.statutparam.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (data:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut parametrage supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  this.reloadPage()
                },
                (error) => {
                  this.loading = false;
                  
                  window.scrollTo(0, 0);  
                }
              );
              decfiscmens[0].statutparam.pop()          
              this.dec.modifydecfiscmensreqById(decfiscmens[0]._id,decfiscmens[0]).then(
                (data:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut parametrage supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  this.reloadPage()
                },
                (error) => {
                  this.loading = false;
                  
                  window.scrollTo(0, 0);  
                }
              );
            }
            else
            {
              deccomptabilite.statutparam.pop()          
              this.deccompt.completedeccomptabilitereqById(deccomptabilite._id,deccomptabilite).then(
                (data:any) => {
                  this.loading = false;
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'statut parametrage supprimé avec succès',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  this.reloadPage()
                },
                (error) => {
                  this.loading = false;
                  
                  window.scrollTo(0, 0);  
                }
              );
            }
          }
        )
       
      }
    )
  }
  deletestatutadmin()
  {
    this.loading = true;
    const decfiscmens = new Decfiscmens();
    this.dec.getDecfiscmensreqById(this.decfiscmens._id).then(
      (dec:Decfiscmens)=>{
        decfiscmens.impottype1={ type: dec.impottype1.type, traitementetsalaire: { salairebrut:dec.impottype1.traitementetsalaire.salairebrut, 
          salaireimposable: dec.impottype1.traitementetsalaire.salaireimposable, retenuealasource:dec.impottype1.traitementetsalaire.retenuealasource,
          contributionsociale:dec.impottype1.traitementetsalaire.contributionsociale, }, 
        location1: { type: dec.impottype1.location1.type,montantbrut:dec.impottype1.location1.montantbrut, taux:dec.impottype1.location1.taux,
           montantnet:dec.impottype1.location1.montantnet, montantretenue:dec.impottype1.location1.montantretenue, },
           location2: { type: dec.impottype1.location2.type,montantbrut:dec.impottype1.location2.montantbrut, taux:dec.impottype1.location2.taux,
            montantnet:dec.impottype1.location2.montantnet, montantretenue:dec.impottype1.location2.montantretenue, },
            location3: { type: dec.impottype1.location3.type,montantbrut:dec.impottype1.location3.montantbrut, taux:dec.impottype1.location3.taux,
              montantnet:dec.impottype1.location3.montantnet, montantretenue:dec.impottype1.location3.montantretenue, },
              location4: { type: dec.impottype1.location4.type,montantbrut:dec.impottype1.location4.montantbrut, taux:dec.impottype1.location4.taux,
                montantnet:dec.impottype1.location4.montantnet, montantretenue:dec.impottype1.location4.montantretenue, },
         honoraire1: {  type:dec.impottype1.honoraire1.type,montantbrut:dec.impottype1.honoraire1.montantbrut, taux:dec.impottype1.honoraire1.taux,
           montantnet:dec.impottype1.honoraire1.montantnet, montantretenue:dec.impottype1.honoraire1.montantretenue,},
      
         honoraire2: {  type:dec.impottype1.honoraire2.type,montantbrut:dec.impottype1.honoraire2.montantbrut, taux:dec.impottype1.honoraire2.taux,
           montantnet:dec.impottype1.honoraire2.montantnet, montantretenue:dec.impottype1.honoraire2.montantretenue,},
      
         honoraire3: {  type:dec.impottype1.honoraire3.type,montantbrut:dec.impottype1.honoraire3.montantbrut, taux:dec.impottype1.honoraire3.taux,
           montantnet:dec.impottype1.honoraire3.montantnet, montantretenue:dec.impottype1.honoraire3.montantretenue,},
           montant10001: {  type:dec.impottype1.montant10001.type,montantbrut:dec.impottype1.montant10001.montantbrut, taux:dec.impottype1.montant10001.taux,
          montantnet:dec.impottype1.montant10001.montantnet, montantretenue:dec.impottype1.montant10001.montantretenue,},
         montant10002: {  type:dec.impottype1.montant10002.type,montantbrut:dec.impottype1.montant10002.montantbrut,taux:dec.impottype1.montant10002.taux,
           montantnet:dec.impottype1.montant10002.montantnet, montantretenue:dec.impottype1.montant10002.montantretenue,},
           montant10003: {  type:dec.impottype1.montant10003.type,montantbrut:dec.impottype1.montant10003.montantbrut, taux:dec.impottype1.montant10003.taux,
             montantnet:dec.impottype1.montant10003.montantnet, montantretenue:dec.impottype1.montant10003.montantretenue,}, 
        montant10004: {  type:dec.impottype1.montant10004.type,montantbrut:dec.impottype1.montant10004.montantbrut, taux:dec.impottype1.montant10004.taux,
          montantnet:dec.impottype1.montant10004.montantnet, montantretenue:dec.impottype1.montant10004.montantretenue,},
           autre: dec.impottype1.autre}
        decfiscmens.impottype2={ type:dec.impottype2.type,reporttvamoisprecedent:dec.impottype2.reporttvamoisprecedent
          ,montantexonere:dec.impottype2.montantexonere
          ,montantexport:dec.impottype2.montantexport
          ,montantsuspension:dec.impottype2.montantsuspension
          ,tvacollecter:{
          type:dec.impottype2.tvacollecter.type,
          chiffreaffaireht:dec.impottype2.tvacollecter.chiffreaffaireht,
          tvaammount:dec.impottype2.tvacollecter.tvaammount,
          ammountttc:dec.impottype2.tvacollecter.ammountttc,
          
          },tvacollecter19:{
            type:dec.impottype2.tvacollecter.type,
            chiffreaffaireht:dec.impottype2.tvacollecter19.chiffreaffaireht,
            tvaammount:dec.impottype2.tvacollecter19.tvaammount,
            ammountttc:dec.impottype2.tvacollecter19.ammountttc,
            
            },tvacollecter13:{
              type:dec.impottype2.tvacollecter.type,
              chiffreaffaireht:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.chiffreaffaireht:null,
              tvaammount:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.tvaammount:null,
              ammountttc:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.ammountttc:null,
              
              },tvarecuperableimmobilier:{
              type:dec.impottype2.tvarecuperableimmobilier.type,
          achatlocauxht:dec.impottype2.tvarecuperableimmobilier.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableimmobilier.achatlocauxtva,
          
          
          },
          tvarecuperableequipement:{
              type:dec.impottype2.tvarecuperableequipement.type,
          achatlocauxht:dec.impottype2.tvarecuperableequipement.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableequipement.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableequipement.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableequipement.achatimportetva,
          
          
          },
          tvarecuperableautreachat:{
              type:dec.impottype2.tvarecuperableautreachat.type,
          achatlocauxht:dec.impottype2.tvarecuperableautreachat.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableautreachat.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableautreachat.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableautreachat.achatimportetva,
          
          
          },
          locationhabitationmeuble:{
              type:dec.impottype2.locationhabitationmeuble.type,
              htammount:dec.impottype2.locationhabitationmeuble.htammount,
              tvaammount:dec.impottype2.locationhabitationmeuble.tvaammount,
              ttcammount:dec.impottype2.locationhabitationmeuble.ttcammount,
              },
          locationusagecommercial:{
            type:dec.impottype2.locationusagecommercial.type,
            htammount:dec.impottype2.locationusagecommercial.htammount,
            tvaammount:dec.impottype2.locationusagecommercial.tvaammount,
            ttcammount:dec.impottype2.locationusagecommercial.ttcammount,
                  },
          operationlotissement:{
            type:dec.impottype2.operationlotissement.type,
            htammount:dec.impottype2.operationlotissement.htammount,
            tvaammount:dec.impottype2.operationlotissement.tvaammount,
            ttcammount:dec.impottype2.operationlotissement.ttcammount,
                      },
          interetpercue:{
            type:dec.impottype2.interetpercue.type,
            htammount:dec.impottype2.interetpercue.htammount,
            tvaammount:dec.impottype2.interetpercue.tvaammount,
            ttcammount:dec.impottype2.interetpercue.ttcammount,
                          },
          autretvaspecial:{
            type:dec.impottype2.autretvaspecial.type,
            htammount:dec.impottype2.autretvaspecial.htammount,
            tvaammount:dec.impottype2.autretvaspecial.tvaammount,
            ttcammount:dec.impottype2.autretvaspecial.ttcammount,
                              taux:dec.impottype2.autretvaspecial.taux,
                              }    }
                              decfiscmens.impottype3={ type:dec.impottype3.type,
                                basetfp:dec.impottype3.basetfp,
                                                          tfpsalairebrut:dec.impottype3.tfpsalairebrut,
                                montanttfpmois:dec.impottype3.montanttfpmois,
                                reporttfpmoisprecedent:dec.impottype3.reporttfpmoisprecedent,
                                montantavance:dec.impottype3.montantavance,
                                tfppayer:dec.impottype3.tfppayer,
                                tfpreporter:dec.impottype3.tfpreporter,
                                salairesnonsoumistfp:dec.impottype3.salairesnonsoumistfp}
                                decfiscmens.impottype4={ type:dec.impottype4.type,
                                basefoprolos:dec.impottype4.basefoprolos,
                                foprolossalairebrut:dec.impottype4.foprolossalairebrut,
                                montantfoprolos:dec.impottype4.montantfoprolos,
                                salairesnonsoumisfoprolos:dec.impottype4.salairesnonsoumisfoprolos}
                                decfiscmens.impottype5={ type:dec.impottype5.type,
                                nombrenotehonoraire:dec.impottype5.nombrenotehonoraire,
                    totaldroittimbre:dec.impottype5.totaldroittimbre,}
                    decfiscmens.impottype6={ type:dec.impottype6.type,
                    chiffreaffairettc:dec.impottype6.chiffreaffairettc,
                    tclpayer:dec.impottype6.tclpayer,}
                    decfiscmens.impottype7={ type:dec.impottype7.type,
                      chiffreaffaireht:dec.impottype7.chiffreaffaireht,
                      montantcontribution:dec.impottype7.montantcontribution,}
        decfiscmens.statutadmin=dec.statutadmin
        decfiscmens.statutcollab=dec.statutcollab
        decfiscmens.statutadmin.pop()
      
                    
          this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
            (data:any) => {
              this.loading = false;
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'statut admin supprimé avec succès',
                showConfirmButton: false,
                timer: 3000
              });
              this.reloadPage()
            },
            (error) => {
              this.loading = false;
              
              window.scrollTo(0, 0);  
            }
          ); 
      }
    )
  
  }
  affect()
  {
    this.loading = true;
    const decfiscmens = new Decfiscmens();
    this.dec.getDecfiscmensreqById(this.decfiscmens._id).then(
      (dec:Decfiscmens)=>{
        decfiscmens.impottype1={ type: dec.impottype1.type, traitementetsalaire: { salairebrut:dec.impottype1.traitementetsalaire.salairebrut, 
          salaireimposable: dec.impottype1.traitementetsalaire.salaireimposable, retenuealasource:dec.impottype1.traitementetsalaire.retenuealasource,
          contributionsociale:dec.impottype1.traitementetsalaire.contributionsociale, }, 
        location1: { type: dec.impottype1.location1.type,montantbrut:dec.impottype1.location1.montantbrut, taux:dec.impottype1.location1.taux,
           montantnet:dec.impottype1.location1.montantnet, montantretenue:dec.impottype1.location1.montantretenue, },
           location2: { type: dec.impottype1.location2.type,montantbrut:dec.impottype1.location2.montantbrut, taux:dec.impottype1.location2.taux,
            montantnet:dec.impottype1.location2.montantnet, montantretenue:dec.impottype1.location2.montantretenue, },
            location3: { type: dec.impottype1.location3.type,montantbrut:dec.impottype1.location3.montantbrut, taux:dec.impottype1.location3.taux,
              montantnet:dec.impottype1.location3.montantnet, montantretenue:dec.impottype1.location3.montantretenue, },
              location4: { type: dec.impottype1.location4.type,montantbrut:dec.impottype1.location4.montantbrut, taux:dec.impottype1.location4.taux,
                montantnet:dec.impottype1.location4.montantnet, montantretenue:dec.impottype1.location4.montantretenue, },
         honoraire1: {  type:dec.impottype1.honoraire1.type,montantbrut:dec.impottype1.honoraire1.montantbrut, taux:dec.impottype1.honoraire1.taux,
           montantnet:dec.impottype1.honoraire1.montantnet, montantretenue:dec.impottype1.honoraire1.montantretenue,},
      
         honoraire2: {  type:dec.impottype1.honoraire2.type,montantbrut:dec.impottype1.honoraire2.montantbrut, taux:dec.impottype1.honoraire2.taux,
           montantnet:dec.impottype1.honoraire2.montantnet, montantretenue:dec.impottype1.honoraire2.montantretenue,},
      
         honoraire3: {  type:dec.impottype1.honoraire3.type,montantbrut:dec.impottype1.honoraire3.montantbrut, taux:dec.impottype1.honoraire3.taux,
           montantnet:dec.impottype1.honoraire3.montantnet, montantretenue:dec.impottype1.honoraire3.montantretenue,},
           montant10001: {  type:dec.impottype1.montant10001.type,montantbrut:dec.impottype1.montant10001.montantbrut, taux:dec.impottype1.montant10001.taux,
          montantnet:dec.impottype1.montant10001.montantnet, montantretenue:dec.impottype1.montant10001.montantretenue,},
         montant10002: {  type:dec.impottype1.montant10002.type,montantbrut:dec.impottype1.montant10002.montantbrut,taux:dec.impottype1.montant10002.taux,
           montantnet:dec.impottype1.montant10002.montantnet, montantretenue:dec.impottype1.montant10002.montantretenue,},
           montant10003: {  type:dec.impottype1.montant10003.type,montantbrut:dec.impottype1.montant10003.montantbrut, taux:dec.impottype1.montant10003.taux,
             montantnet:dec.impottype1.montant10003.montantnet, montantretenue:dec.impottype1.montant10003.montantretenue,}, 
        montant10004: {  type:dec.impottype1.montant10004.type,montantbrut:dec.impottype1.montant10004.montantbrut, taux:dec.impottype1.montant10004.taux,
          montantnet:dec.impottype1.montant10004.montantnet, montantretenue:dec.impottype1.montant10004.montantretenue,},
           autre: dec.impottype1.autre}
        decfiscmens.impottype2={ type:dec.impottype2.type,reporttvamoisprecedent:dec.impottype2.reporttvamoisprecedent
          ,montantexonere:dec.impottype2.montantexonere
          ,montantexport:dec.impottype2.montantexport
          ,montantsuspension:dec.impottype2.montantsuspension
          ,tvacollecter:{
          type:dec.impottype2.tvacollecter.type,
          chiffreaffaireht:dec.impottype2.tvacollecter.chiffreaffaireht,
          tvaammount:dec.impottype2.tvacollecter.tvaammount,
          ammountttc:dec.impottype2.tvacollecter.ammountttc,
          
          },tvacollecter19:{
            type:dec.impottype2.tvacollecter.type,
            chiffreaffaireht:dec.impottype2.tvacollecter19.chiffreaffaireht,
            tvaammount:dec.impottype2.tvacollecter19.tvaammount,
            ammountttc:dec.impottype2.tvacollecter19.ammountttc,
            
            }
            ,tvacollecter13:{
              type:dec.impottype2.tvacollecter.type,
              chiffreaffaireht:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.chiffreaffaireht:null,
              tvaammount:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.tvaammount:null,
              ammountttc:dec.impottype2.tvacollecter13?dec.impottype2.tvacollecter13.ammountttc:null,
              
              },tvarecuperableimmobilier:{
              type:dec.impottype2.tvarecuperableimmobilier.type,
          achatlocauxht:dec.impottype2.tvarecuperableimmobilier.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableimmobilier.achatlocauxtva,
          
          
          },
          tvarecuperableequipement:{
              type:dec.impottype2.tvarecuperableequipement.type,
          achatlocauxht:dec.impottype2.tvarecuperableequipement.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableequipement.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableequipement.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableequipement.achatimportetva,
          
          
          },
          tvarecuperableautreachat:{
              type:dec.impottype2.tvarecuperableautreachat.type,
          achatlocauxht:dec.impottype2.tvarecuperableautreachat.achatlocauxht,
          achatlocauxtva:dec.impottype2.tvarecuperableautreachat.achatlocauxtva,
          achatimporteht:dec.impottype2.tvarecuperableautreachat.achatimporteht,
          achatimportetva:dec.impottype2.tvarecuperableautreachat.achatimportetva,
          
          
          },
          locationhabitationmeuble:{
              type:dec.impottype2.locationhabitationmeuble.type,
              htammount:dec.impottype2.locationhabitationmeuble.htammount,
              tvaammount:dec.impottype2.locationhabitationmeuble.tvaammount,
              ttcammount:dec.impottype2.locationhabitationmeuble.ttcammount,
              },
          locationusagecommercial:{
            type:dec.impottype2.locationusagecommercial.type,
            htammount:dec.impottype2.locationusagecommercial.htammount,
            tvaammount:dec.impottype2.locationusagecommercial.tvaammount,
            ttcammount:dec.impottype2.locationusagecommercial.ttcammount,
                  },
          operationlotissement:{
            type:dec.impottype2.operationlotissement.type,
            htammount:dec.impottype2.operationlotissement.htammount,
            tvaammount:dec.impottype2.operationlotissement.tvaammount,
            ttcammount:dec.impottype2.operationlotissement.ttcammount,
                      },
          interetpercue:{
            type:dec.impottype2.interetpercue.type,
            htammount:dec.impottype2.interetpercue.htammount,
            tvaammount:dec.impottype2.interetpercue.tvaammount,
            ttcammount:dec.impottype2.interetpercue.ttcammount,
                          },
          autretvaspecial:{
            type:dec.impottype2.autretvaspecial.type,
            htammount:dec.impottype2.autretvaspecial.htammount,
            tvaammount:dec.impottype2.autretvaspecial.tvaammount,
            ttcammount:dec.impottype2.autretvaspecial.ttcammount,
                              taux:dec.impottype2.autretvaspecial.taux,
                              }    }
                              decfiscmens.impottype3={ type:dec.impottype3.type,
                                basetfp:dec.impottype3.basetfp,
                                                          tfpsalairebrut:dec.impottype3.tfpsalairebrut,
                                montanttfpmois:dec.impottype3.montanttfpmois,
                                reporttfpmoisprecedent:dec.impottype3.reporttfpmoisprecedent,
                                montantavance:dec.impottype3.montantavance,
                                tfppayer:dec.impottype3.tfppayer,
                                tfpreporter:dec.impottype3.tfpreporter,
                                salairesnonsoumistfp:dec.impottype3.salairesnonsoumistfp}
                                decfiscmens.impottype4={ type:dec.impottype4.type,
                                basefoprolos:dec.impottype4.basefoprolos,
                                foprolossalairebrut:dec.impottype4.foprolossalairebrut,
                                montantfoprolos:dec.impottype4.montantfoprolos,
                                salairesnonsoumisfoprolos:dec.impottype4.salairesnonsoumisfoprolos}
                                decfiscmens.impottype5={ type:dec.impottype5.type,
                                nombrenotehonoraire:dec.impottype5.nombrenotehonoraire,
                    totaldroittimbre:dec.impottype5.totaldroittimbre,}
                    decfiscmens.impottype6={ type:dec.impottype6.type,
                    chiffreaffairettc:dec.impottype6.chiffreaffairettc,
                    tclpayer:dec.impottype6.tclpayer,}
                    decfiscmens.impottype7={ type:dec.impottype7.type,
                      chiffreaffaireht:dec.impottype7.chiffreaffaireht,
                      montantcontribution:dec.impottype7.montantcontribution,}
                      decfiscmens.statutadmin=dec.statutadmin
                      decfiscmens.statutcollab=dec.statutcollab       
    
        decfiscmens.affecte =this.optionValue;
        this.filterusers(this.optionValue)
        this.commun.getcurrenttime().then(
          (data:any) => {
            decfiscmens.statutadmin.push
            //@ts-ignore
            ({
              statut:'affecté',
              motif:'',
              datefin:data,
               duree:Math.floor((Date.now()-this.firsttimer) / 1000),
              collaborateur:this.prenomcollab+' '+this.nomcollab     
            })
            decfiscmens.statutcollab.push
            //@ts-ignore
            ({
              statutcoll:'en cours de traitement',
              motifcoll:'',
              datefin:data,
              duree:'',     
            })
           
           
            this.dec.modifydecfiscmensreqById(dec._id,decfiscmens).then(
              (data:any) => {
                this.loading = false;
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'déclaration affectée avec succès',
                  showConfirmButton: false,
                  timer: 3000
                });
                this.router.navigate(['admin-board']);
              },
              (error) => {
                this.loading = false;
                
                window.scrollTo(0, 0);
                
              
                
              }
            );
          }
        )
      
      }
    )
  
  }
  onDelete() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        this.dec.getDecfiscmensreqById(params.id).then(
          (data:any) => {
            this.loading = false;
            Swal.fire({
              title: 'Veuillez confirmer la suppression!',
              
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.dec.deletedecfiscmensById(params.id);
                this.router.navigate(['admin-board']);
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  Upload() {
    this.router.navigate(['create-report']);
}
  generateExcel() {}
  reloadPage(): void {
  
    window.location.reload();
    
  }

  ngOnDestroy(){
    this.intervalSub?this.intervalSub.unsubscribe():'';
    this.usersSub?this.usersSub.unsubscribe():'';
    this.settingsSub?this.settingsSub.unsubscribe():'';
    this.usedres.getUsedressourcedataById(this.decfiscmens._id).then(
      (usedres:Usedressourcemodel)=>
            {
              if(usedres)
              {
                if(usedres.connecteduser==this.token.getUser().userId)
                {
                  this.usedres.deleteusedressourcedataById(this.decfiscmens._id).then(
                    (usedres:Usedressourcemodel)=>
                          {
                            
                          }
                  )
                }
              }
            }
    )
  }
  }