export class devismodel {
    itemsdetails: ItemDetails[];
    clientdetails: {
        name: string;
        address: string;
        phone: string;
        email: string;
    };
    fournisseurdetails: {
        name: string;
        address: string;
        phone: string;
        email: string;
    };
    status: string;
    currency: string;
    terms: string;
    validityDate: string;
    notes: string;
    ref: string;
    prefixe: string;
    number: number;
    userId: string;
    logo: string;
    tva: number;

    _id: string;
    created: Date;

    constructor() {
        this.itemsdetails = [];
        this.clientdetails = {
            name: '',
            address: '',
            phone: '',
            email: ''
        };
        this.fournisseurdetails = {
            name: '',
            address: '',
            phone: '',
            email: ''
        };
        this.status = '';
        this.currency = '';
        this.terms = '';
        this.validityDate = '';
        this.notes = '';
        this.userId = '';
        this.logo = '';
    }
}

interface ItemDetails {
    id: number;
    libelle: string;
    description: string;
    quantity: string;
    unitPrice: string;
}
