import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDisplayDirective } from '../directive/user-display.directive'; // Adjust the path as necessary
//pipe
//pipes
import { DecimalFormatDirective } from '../directive/decimal-format.directive';


@NgModule({
  declarations: [UserDisplayDirective,
    DecimalFormatDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [UserDisplayDirective, DecimalFormatDirective] // Export the directive to make it available for import
})
export class SharedmoduleModule { }
