<div id="main-container">
  <div id="buttons-container" class="col-md-6">
    <button nbButton outline status="success" *ngIf="currentUser.role=='admin'" (click)="getNavigation('add-event')" class="btn btn-success">Ajouter Evenement(s)</button>
    <button nbButton outline status="danger" *ngIf="currentUser.role=='admin'" (click)="deleteAll()" class="btn btn-danger">Supprimer Tous</button>
    <div class="nav-buttons">
      <button (click)="prevMonth()">Previous Month</button>
      <button (click)="nextMonth()">Next Month</button>
      <button (click)="prevYear()">Previous Year</button>
      <button (click)="nextYear()">Next Year</button>
    </div>
    <div class="cal-date-nav-buttons">
      <button id="hier" mwlCalendarPreviousView [(viewDate)]="viewDate" [view]="view">hier</button>
      <button id="Aujourd'hui" mwlCalendarToday [(viewDate)]="viewDate">Aujourd'hui</button>
      <button id="demain" mwlCalendarNextView [(viewDate)]="viewDate" [view]="view">demain</button>
    </div>
    <span id="changer">
      <button id="mois" (click)="setView(CalendarView.Month)">Mois</button>
      <button id="semaine" (click)="setView(CalendarView.Week)">Semaine</button>
      <button id="jour" (click)="setView(CalendarView.Day)">Jour</button>
    </span>
  </div>

  <div id="calendar-container">
    <div id="cal-date-field">{{ viewDate | calendarDate: (view + 'ViewTitle'):'fr' }}</div>
    <span [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                               [events]="events" [viewDate]="viewDate"
                               [activeDayIsOpen]="activeDayIsOpen"
                               (dayClicked)="dayClicked($event.day)" (eventClicked)="navigateToEvent($event)">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                              [events]="events" [dayStartHour]="22"
                              [dayEndHour]="24" [viewDate]="viewDate"(eventClicked)="navigateToEvent($event)">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                             [dayStartHour]="22" [dayEndHour]="24"
                             [events]="events" [viewDate]="viewDate"(eventClicked)="navigateToEvent($event)">
      </mwl-calendar-day-view>
    </span>
  </div>
</div>
