import { Directive, Input, OnInit, Renderer2, ElementRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';

@Directive({
  selector: '[appUserDisplay]'
})
export class UserDisplayDirective implements OnInit {
  @Input('appUserDisplay') userId: string;
  @Input() displayProperties: string[];

  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.userService.getUserById(this.userId).then((user: User) => {
      const userData = this.renderUserData(user);
      this.displayUserData(user, userData);
    }).catch(error => {
      console.error('Error fetching user:', error);
      this.renderer.setProperty(this.el.nativeElement, 'textContent', 'Error fetching user data');
    });
  }

  private renderUserData(user: User): any {
    const userData: any = {};

    this.displayProperties.forEach(prop => {
      const value = user[prop];
      if (value !== undefined && value !== null) {
        userData[prop] = value;
      }
    });

    return userData;
  }

  private displayUserData(user: User, userData: any) {
    this.renderer.setProperty(this.el.nativeElement, 'textContent', '');

    if (user.nature === 'Personne Physique' && (this.displayProperties.includes('firstname') || this.displayProperties.includes('lastname'))) {
      const text = `${user.firstname} ${user.lastname} ${user.clientcode}`;
      const textElement = this.renderer.createText(text);
      this.renderer.appendChild(this.el.nativeElement, textElement);
    } else if (user.nature === 'Personne Morale' && this.displayProperties.includes('raisonsociale')) {
      const text = `${ user.raisonsociale} ${user.clientcode}`;
      const textElement = this.renderer.createText(text);
      this.renderer.appendChild(this.el.nativeElement, textElement);
    } else {
      Object.keys(userData).forEach(key => {
        const value = userData[key];
        const text = `${key}: ${value}`;
        const textElement = this.renderer.createText(text);
        const lineBreak = this.renderer.createElement('br');
        this.renderer.appendChild(this.el.nativeElement, textElement);
        this.renderer.appendChild(this.el.nativeElement, lineBreak);
      });
    }
  }
}
