import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, finalize } from 'rxjs/operators';
import { AlertService } from '../_helpers/alert.service';
import { UserService} from '../services/user.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordform: FormGroup;
  loading = false;
  submitted = false;
  successmsg:string;
  errormsg:string;

  constructor(
      private formBuilder: FormBuilder,
      private accountService: UserService,
      private alertService: AlertService,
      private router: Router

  ) { }

  ngOnInit() {
      this.forgotpasswordform = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotpasswordform.controls; }

  onSubmit() {
      this.submitted = true;

      this.loading = true;
      
      this.accountService.forgotPassword(this.f.email.value).then(
        (success:any) => {
          Swal.fire({
            title: 'Nous vous avons envoyé un email avec les instructions pour réinitialiser votre mot de passe',
            icon: 'error',
            confirmButtonColor: '#3085d6', 
            confirmButtonText: 'D\'accord',
          }).then((_result) => {          this.router.navigate(['login']);
        }).catch(() => {
            Swal.fire('opération non aboutie!')
          })

        },
        (error)=> {
            
          this.alertService.error(error.error.error);
          window.scrollTo(0, 0);
        }
    );
  }
}
