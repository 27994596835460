
export class Deccomptabilite {
  userId: string;
  _id:string;
  nature:string;
  registrecommerce:string;
  matriculefiscale:string;
  codetva:string;
  codegenre:string;
  raisonsociale:string;
  adresse:string;
  codepostal:string;
  activite:string;
  sousactivite:string;
  datearretactivite:string;
  regimefiscalimpot:string;
  firstname:string;
  lastname:string;
  codedeclaration:string;
  mois:string;
  annee:string;
  debitmoisprecedent:string;
  creditmoisprecedent:string
  moisreleve:string
  anneereleve:string
  totalht:number
  totalhtfodec:number
  totalhttpe:number

  totaltva:number
  totalhtexo:number
  totalhtexofodec:number
  totalhtexotpe:number

  totaltvaexo:number
  totalhtsus:number
  totalhtsusfodec:number
  totalhtsustpe:number

  totaltvasus:number
  totalhtexp:number
  totalhtexpfodec:number
  totalhtexptpe:number

  totaltvaexp:number
  realtotaltva:number
  totaldt:number
  totalttc:number
  totalttcexo:number
  totalttcsus:number
  totalttcexp:number

  totalht19:number
  totalht19fodec:number
  totalht19tpe:number

  totaltva19:number
  realtotaltva19:number
  totaldt19:number
  totalttc19:number

  totalht13:number
  totalht13fodec:number
  totalht13tpe:number

  totaltva13:number
  realtotaltva13:number
  totaldt13:number
  totalttc13:number
  totalht0:number
  totaltva0:number
  realtotaltva0:number
  totaldt0:number
  totalttc0:number
  totalht019:number
  totaltva019:number
  realtotaltva019:number
  totaldt019:number
  totalttc019:number

  totalht013:number
  totaltva013:number
  realtotaltva013:number
  totaldt013:number
  totalttc013:number
  totalht2:number
  totalht2fodec:number
  totalht2tpe:number

  totaltva2:number
  totalht2exo:number
  totalht2exofodec:number
  totalht2exotpe:number

  totaltva2exo:number
  totalht2sus:number
  totalht2susfodec:number
  totalht2sustpe:number

  totaltva2sus:number
  totalht2exp:number
  totalht2expfodec:number
  totalht2exptpe:number

  totaltva2exp:number
  totaldt2:number
  totalttc2:number
  totalht219:number
  totalht219fodec:number
  totalht219tpe:number

  totaltva219:number
  totaldt219:number
  totalttc219:number

  totalht213:number
  totalht213fodec:number
  totalht213tpe:number

  totaltva213:number
  totaldt213:number
  totalttc213:number
  totalttc2exo:number
  totalttc2sus:number
  totalttc2exp:number

  totalrecette:number
  totalrecettefodec:number
  totalrecettetpe:number

  totalrecette19:number
  totalrecette19fodec:number
  totalrecette19tpe:number

  totalrecette13:number
  totalrecette13fodec:number
  totalrecette13tpe:number


  totalrecetteexo:number
  totalrecetteexofodec:number
  totalrecetteexotpe:number

  totalrecettesus:number
  totalrecettesusfodec:number
  totalrecettesustpe:number

  totalrecetteexp:number
  totalrecetteexpfodec:number
  totalrecetteexptpe:number

  totalht3:number
  totalht8:number
  totalht9:number
  totalht11:number

  totalht3devise:number
  totaltva3:number
  totaldt3:number 
  totalttc3:number
  totaldebit:number
  totalcredit:number
  totalsoldemois:number
  totalsalairebrut:number
  totalcnss:number
  totalsalaireimposable:number
  totalretenueimpot:number
  totalavancepret:number
  totalsalairenet:number
  affecte:string;
  dateaffectation:string;
  created:Date;
  updated: Date;
  dateouverturedossier:number
  contientnumeroother:boolean
  showbuttonotheretatrecette:boolean
  contientnumero:boolean
  statutadmin:string[]
  statutcollab:string[]
  statutparam:string[]
  source:string
  paye:boolean
  marquepaye:boolean
  totalhonoraire:number
totaltime:number
tauxapplique:string
autrecopbis:Autrecopbis[]
autre0:Autre0[]
autre0bis:Autre0bis[]
autre1:Autre[]
autre2bis:Autre2bis[]
autre2:Autre2[]
autre1bis:Autrebis[]
autre3:Autre3[]
autre3bis:Autre3bis[]
autre4:Autre4[]
autre4bis:Autre4bis[]
autre5:Autre5[]
autre5bis:Autre5bis[]
autre6:Autre6[]
autre6bis:Autre6bis[]
autre8:Autre8[]
autre8bis:Autre8bis[]
autre9:Autre9[]
autre9bis:Autre9bis[]
autre11:Autre11[]
autre11bis:Autre11bis[]
}
export interface Autre0 {
  type:string,
  numeropiece:string,
  jour: string,
  date: string,
  typepiece:string,
  devise:string
  numeronote:string,
  montantht:string,
  montantht19:string,
  montantht13:string,

  montanthtexo:string,
  montanthtsus:string,
  montanthtexp:string,
  montanthtexpdevise:string,

  montanttva:string,
  montanttva19:string,
  montanttva13:string,

  montanttvaexo:string,
  montanttvasus:string,
  montanttvaexp:string,
  montantdt:string,
  montantttc:string,
  montantttc19:string,
  montantttc13:string,

  montantttcexo:string,
  montantttcsus:string,
  montantttcexp:string,
  client:string,
  objet:string,
  autreobjet:string,
  annule:string,

  nature:string,
  autrenature:string,
  banque:string,
  autrebanque:string,
  numerocompte: '',
  autrenumerocompte: '',
  echeance:string,
  datevirement:string,
  autreclient:string,

}
export interface Autrecopbis {
  code:string,
  journal: string,
  date: string,
  prefixe:string,
  numeropiece:string,
  libelle:string,
  numeroexterne:string,
  reflettrage:string,
  compte:string,
  sens:string,
  montant:number,

}
export interface Autre0bis {
  code:string,
  journal: string,
  date: string,
  prefixe:string,
  numeropiece:string,
  libelle:string,
  numeroexterne:string,
  reflettrage:string,
  compte:string,
  sens:string,
  montant:number,

}
export interface Autre {
        type:string,
        numeropiece:string,
        jour: string,
        date: string,
        typepiece:string,
        devise:string
        numeronote:string,
        montantht:string,
        montanthtfodec:string,
        montanthttpe:string,
        montantht19:string,
        montantht19fodec:string,
        montantht19tpe:string,
        montantht13:string,
        montantht13fodec:string,
        montantht13tpe:string,
        montanthtexo:string,
        montanthtexofodec:string,
        montanthtexotpe:string,
        montanthtsus:string,
        montanthtsusfodec:string,
        montanthtsustpe:string,
        montanthtexp:string,
        montanthtexpdevise:string,
        montanttva:string,
        montanttva19:string,
        montanttva13:string,
        montanttvaexo:string,
        montanttvasus:string,
        montanttvaexp:string,
        montantdt:string,
        montantttc:string,
        montantttc19:string,
        montantttc13:string,
        montantttcexo:string,
        montantttcsus:string,
        montantttcexp:string,
        client:string,
        objet:string,
        autreobjet:string,
        annule:string,
        nature:string,
        autrenature:string,
        banque:string,
        autrebanque:string,
        numerocompte: '',
        autrenumerocompte: '',
        echeance:string,
        datevirement:string,
        autreclient:string,

}
export interface Autrebis {
  code:string,
  journal: string,
  date: string,
  prefixe:string,
  numeropiece:string,
  libelle:string,
  numeroexterne:string,
  reflettrage:string,
  compte:string,
  sens:string,
  montant:number,

}
export interface Autre2 {
      type:string,
      jour: string,
      numeropiece:string,
      date: string,
      devise: string,

      recette:string,
      recettefodec:string,
      recettetpe:string,

      recette19:string,
      recette19fodec:string,
      recette19tpe:string,

      recette13:string,
      recette13fodec:string,
      recette13tpe:string,

      recetteexo:string,
      recetteexofodec:string,
      recetteexotpe:string,

      recettesus:string,
      recettesusfodec:string,
      recettesustpe:string,

      recetteexp:string,


      recetteexpdevise:string,

      montantht:string,
      montantht19:string,
      montantht13:string,

      montanthtexo:string,
      montanthtsus:string,
      montanthtexp:string,

      montanttva:string,
      montanttva19:string,
      montanttva13:string,

      montanttvaexo:string,
      montanttvasus:string,
      montanttvaexp:string,

      montantdt:string,
      montantttc:string,
      montantttc19:string,
      montantttc13:string,

      montantttcexo:string,
      montantttcsus:string,
      montantttcexp:string,

}
export interface Autre2bis {
  code:string,
  journal: string,
  date: string,
  prefixe:string,
  numeropiece:string,
  libelle:string,
  numeroexterne:string,
  reflettrage:string,
  compte:string,
  sens:string,
  montant:number,
}
export interface Autre3 {
      type:string,
      numeropiece:string,
      jour: string,
      date: string,
      devise: string,
      fournisseur:string,
      autrefournisseur:string,
      typepiece:string,
      numerofacture:string,
      natureachat:string,
      autrenatureachat:string,
      montantht:string,
      montanthtachat:string,
      montanthtdevise:string,
      montanttva:string,
      montantdt:string,
      montantttc:string,
      reglement:string,
      ficheUrl:string,
      contientfiche:boolean
}
export interface Autre3bis {
  code:string,
  journal: string,
  date: string,
  prefixe:string,
  numeropiece:string,
  libelle:string,
  numeroexterne:string,
  reflettrage:string,
  compte:string,
  sens:string,
  montant:number,
}
export interface Autre8 {
  type:string,
  numeropiece:string,
  jour: string,
  date: string,
  devise: string,
  beneficiaire:string,
  autrebeneficiaire:string,
  fournisseur:string,
  autrefournisseur:string,
  typepiece:string,
  numerofacture:string,
  natureachat:string,
  autrenatureachat:string,
  montantht:string,
  montanthtachat:string,
  montanthtdevise:string,
  montanttva:string,
  montantdt:string,
  montantttc:string,
  reglement:string,
  ficheUrl:string,
  numerocheque:string,
  banque:string,
  contientfiche:boolean
}
export interface Autre8bis {
code:string,
journal: string,
date: string,
prefixe:string,
numeropiece:string,
libelle:string,
numeroexterne:string,
reflettrage:string,
compte:string,
sens:string,
montant:number,
}
export interface Autre9 {
  type:string,
  numeropiece:string,
  jour: string,
  date: string,
  devise: string,
  beneficiaire:string,
  autrebeneficiaire:string,
  fournisseur:string,
  autrefournisseur:string,
  typepiece:string,
  numerofacture:string,
  natureachat:string,
  autrenatureachat:string,
  montantht:string,
  montanthtachat:string,
  montanthtdevise:string,
  montanttva:string,
  montantdt:string,
  montantttc:string,
  reglement:string,
  ficheUrl:string,
  contientfiche:boolean
}
export interface Autre9bis {
code:string,
journal: string,
date: string,
prefixe:string,
numeropiece:string,
libelle:string,
numeroexterne:string,
reflettrage:string,
compte:string,
sens:string,
montant:number,
}
export interface Autre11 {
  type:string,
  numeropiece:string,
  jour: string,
  date: string,
  devise: string,
  beneficiaire:string,
  autrebeneficiaire:string,
  fournisseur:string,
  autrefournisseur:string,
  typepiece:string,
  numerofacture:string,
  natureachat:string,
  autrenatureachat:string,
  montantht:string,
  montanthtachat:string,
  montanthtdevise:string,
  montanttva:string,
  montantdt:string,
  montantttc:string,
  reglement:string,
  ficheUrl:string,
  contientfiche:boolean
}
export interface Autre11bis {
code:string,
journal: string,
date: string,
prefixe:string,
numeropiece:string,
libelle:string,
numeroexterne:string,
reflettrage:string,
compte:string,
sens:string,
montant:number,
}
export interface Autre4 {
  type:string,
  banque:string,
  devise:string,
  numerocompte:string,
  soldedebit:string,
  soldecredit:string,
  creditdevise:string,
  soldefinmoisdinar:string,
  soldefinmoisdevise:string,
  mouvements:[]
}
export interface Autre4bis {
  code:string,
  journal: string,
  date: string,
  prefixe:string,
  numeropiece:string,
  libelle:string,
  numeroexterne:string,
  reflettrage:string,
  compte:string,
  sens:string,
  montant:number,
}
export interface Autre5 {
  type:string,
  annee: string,
  mois: string,
  ficheUrl:string,
  contientfiche:boolean

}
export interface Autre5bis {
  code:string,
  journal: string,
  date: string,
  prefixe:string,
  numeropiece:string,
  libelle:string,
  numeroexterne:string,
  reflettrage:string,
  compte:string,
  sens:string,
  montant:number,
}
export interface Autre6 {
  
  type:string,
  typesalaire:string,
  salarie:Salarie[],
     
    }
    export interface Autre6bis {
      code:string,
      journal: string,
      date: string,
      prefixe:string,
      numeropiece:string,
      libelle:string,
      numeroexterne:string,
      reflettrage:string,
      compte:string,
      sens:string,
      montant:number,
    }
    export interface Salarie{
      type:string,
      matricule: string,
      nomprenom: string,
      salairebrut:string,
      naturecontrat:string,
      sexe:string,
      naissance:string,
      etatcivil:string,
      salairenet:string,
      cin:string,
      delivre:string,
      adresse:string,
      mobile:string,
      mail:string,
      chef:string,
      enfant:string,
      contientfiche:boolean
      ficheUrl:string,
    }