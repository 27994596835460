<mat-card>
  <mat-card-header>
    <mat-card-title>Formulaire D'inscription</mat-card-title>
  </mat-card-header>
  
  <form [formGroup]="signupForm" (ngSubmit)="onSignup()" class="form-container" fxLayout="column" fxLayoutGap="20px">
    <!-- Nature Utilisateur -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="30%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Nature utilisateur</mat-label>
          <mat-select placeholder="Entrer nature utilisateur" id="nature" formControlName="nature" name="nature">
            <mat-option value="Personne Physique">Personne Physique</mat-option>
            <mat-option value="Personne Morale">Personne Morale</mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && f.nature.errors?.required">Nature Utilisateur est obligatoire</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="30%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Type Utilisateur</mat-label>
          <mat-select placeholder="Entrer Votre Type Utilisateur" id="usertype" formControlName="usertype" name="usertype">
            <mat-option value="Consultant">Consultant</mat-option>
            <mat-option value="Collaborateur">Collaborateur</mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && f.usertype.errors?.required">Type Utilisateur est obligatoire</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="30%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Civilité</mat-label>
          <mat-select placeholder="Entrer Votre civilité" id="civilite" formControlName="civilite" name="civilite">
            <mat-option value="Monsieur">Monsieur</mat-option>
            <mat-option value="Madame">Madame</mat-option>
            <mat-option value="Mademoiselle">Mademoiselle</mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && f.civilite.errors?.required">Civilité est obligatoire</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <!-- Nom, Prénom -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Nom Utilisateur</mat-label>
          <input matInput type="text" placeholder="Entrer Votre Nom" id="lastname" formControlName="lastname">
          <mat-icon matSuffix>person</mat-icon>
          <mat-error *ngIf="submitted && f.lastname.errors?.required">Nom Obligatoire</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Prénom Utilisateur</mat-label>
          <input matInput type="text" placeholder="Entrer Votre Prénom" id="firstname" formControlName="firstname">
          <mat-icon matSuffix>person</mat-icon>
          <mat-error *ngIf="submitted && f.firstname.errors?.required">Prénom Obligatoire</mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Raison Sociale, Fonction -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container-disabled">
          <mat-label>Raison Sociale</mat-label>
          <input matInput type="text" placeholder="Entrer Votre Raison Sociale" id="raisonsociale" formControlName="raisonsociale">
          <mat-icon matSuffix>business</mat-icon>
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container-disabled">
          <mat-label>Fonction</mat-label>
          <input matInput type="text" placeholder="Entrer Votre Fonction" id="fonction" formControlName="fonction">
          <mat-icon matSuffix>business</mat-icon>
        </mat-form-field>
      </div>
    </div>
    
    <!-- Nom Société, Secteur -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container-disabled">
          <mat-label>Nom Société</mat-label>
          <input matInput type="text" placeholder="Entrer Nom Société" id="nomsociete" formControlName="nomsociete">
          <mat-icon matSuffix>business</mat-icon>
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container-disabled">
          <mat-label>Secteur</mat-label>
          <input matInput type="text" placeholder="Entrer Votre Secteur" id="secteur" formControlName="secteur">
          <mat-icon matSuffix>business</mat-icon>
        </mat-form-field>
      </div>
    </div>
    
    <!-- Code Client -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="70%" fxFlex.xs="100%">
        <div class="row">
          <div class="col-md-3">
            <label for="clientcode" class="form-label">Code Client</label>
            <input type="text" class="form-control" id="clientcode" formControlName="clientcode" [ngClass]="{ 'is-invalid': submitted && f.clientcode.errors }" disabled required />
            <div *ngIf="submitted && f.clientcode.errors" class="invalid-feedback">
              <div *ngIf="f.clientcode.errors.required">Veuillez générer votre code client</div>
            </div>
          </div>
          <div class="col-md-3">
            <button type="button" class="btn btn-primary mt-4" (click)="randomString()">Generer Code</button>
          </div>
        </div>
      </div>
    </div>

    <!-- N° Mobile Utilisateur, Confirmer N° Mobile Utilisateur -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>N° Mobile Utilisateur</mat-label>
          <input matInput type="text" placeholder="Entrez Votre Numéro Mobile" id="mobile" formControlName="mobile">
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error *ngIf="submitted && f.mobile.errors?.required">Numéro Mobile Obligatoire</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Confirmer N° Mobile Utilisateur</mat-label>
          <input matInput type="text" placeholder="Confirmez Votre Numéro Mobile" id="confirmmobile" formControlName="confirmmobile">
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error *ngIf="submitted && f.confirmmobile.errors?.required">Numéro Mobile Obligatoire</mat-error>
          <mat-error *ngIf="submitted && f.confirmmobile.errors?.mustMatch">Les numéros mobiles ne sont pas identiques</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <!-- Email Utilisateur, Confirmer Email Utilisateur -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Email Utilisateur</mat-label>
          <input matInput type="email" placeholder="Entrez Votre Email" id="email" formControlName="email">
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="submitted && f.email.errors?.required">Email Obligatoire</mat-error>
          <mat-error *ngIf="submitted && f.email.errors?.email">Adresse Email non valides</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Confirmer Email Utilisateur</mat-label>
          <input matInput type="email" placeholder="Confirmez Votre Email" id="confirmemail" formControlName="confirmemail">
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="submitted && f.confirmemail.errors?.required">Email Obligatoire</mat-error>
          <mat-error *ngIf="submitted && f.confirmemail.errors?.email">Adresse Email non valides</mat-error>
          <mat-error *ngIf="submitted && f.confirmemail.errors?.mustMatch">Les Emails ne sont pas identiques</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <!-- Mot de Passe Utilisateur, Confirmer Mot de Passe Utilisateur -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Mot de Passe Utilisateur</mat-label>
          <input matInput type="password" placeholder="Entrez Votre Mot De Passe" id="password" formControlName="password">
          <mat-icon matSuffix>lock</mat-icon>
          <mat-error *ngIf="submitted && f.password.errors?.required">Mot de passe Obligatoire</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="fill" class="input-container">
          <mat-label>Confirmer Mot de Passe Utilisateur</mat-label>
          <input matInput type="password" placeholder="Confirmez Votre Mot De Passe" id="confirmpassword" formControlName="confirmpassword">
          <mat-icon matSuffix>lock</mat-icon>
          <mat-error *ngIf="submitted && f.confirmpassword.errors?.required">Mot de passe Obligatoire</mat-error>
          <mat-error *ngIf="submitted && f.confirmpassword.errors?.mustMatch">Les Mot de passe ne sont pas identiques</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <!-- Submit and Reset Buttons -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-raised-button color="primary" type="submit">Inscription</button>
      <button mat-button type="reset" (click)="onReset()">Réinitialiser</button>
    </div>
  </form>
</mat-card>
