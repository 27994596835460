<head>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
</head>
<body>
    <div class="forgot-password-form">
        <form [formGroup]="forgotpasswordform" (ngSubmit)="onSubmit()">
            <div class="avatar">
                <img src="../../assets/forgot pass icon.png" alt="Avatar">
            </div>
            <h2 class="text-center">Mot de passe oublié</h2>   
            <div class="form-group">
                <input type="text" class="form-control" name="email" formControlName="email" placeholder="entrer votre email" required="required">
            </div>
                    
            <div class="form-group">
                <button type="submit"  class="btn btn-primary btn-lg btn-block">Envoyer clé de regénération</button>
            </div>
            
        </form>
        <div *ngIf="errormsg"><p>{{ errormsg }}</p></div>
        <div *ngIf="successmsg"><p>{{ successmsg }}</p></div>
    </div>
    </body>