import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { devismodel } from '../models/facture.model';
const API_URL_test = 'http://localhost:3003/api/factureproforma/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/factureproforma/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class FactureproformaService {
    
    
    constructor(private http: HttpClient) { }
    public factureproformas: devismodel[] = [];
    public factureproformas$ = new Subject<devismodel[]>();
    getFactureprof(page: number, size: number, filter: string = '', status: string = ''): devismodel[] {
      let filteredFactureprof = this.factureproformas;
  
      if (filter) {
        filteredFactureprof = filteredFactureprof.filter(invoice => 
          invoice.ref.toLowerCase().includes(filter.toLowerCase()) ||
          invoice.clientdetails.name.toLowerCase().includes(filter.toLowerCase())
        );
      }
  
      if (status) {
        filteredFactureprof = filteredFactureprof.filter(invoice => invoice.status === status);
      }
  
      const start = (page - 1) * size;
      return filteredFactureprof.slice(start, start + size);
    }
  
    getTotalFactureprof(filter: string = '', status: string = ''): number {
      let filteredFactureprof = this.factureproformas;
  
      if (filter) {
        filteredFactureprof = filteredFactureprof.filter(invoice => 
          invoice.ref.toLowerCase().includes(filter.toLowerCase()) ||
          invoice.clientdetails.name.toLowerCase().includes(filter.toLowerCase())
        );
      }
  
      if (status) {
        filteredFactureprof = filteredFactureprof.filter(invoice => invoice.status === status);
      }
  
      return filteredFactureprof.length;
    }
    getFactureproformaalldata() {
        this.http.get(API_URL_cloud).subscribe(
          (factureproformas: devismodel[]) => {
            if (factureproformas) {
              this.factureproformas = factureproformas;
              this.emitFactureproformas();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitFactureproformas() {
        this.factureproformas$.next(this.factureproformas);
      }
      getfactureproformauser(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'byuser/'  ,{userId}).subscribe(
            (invo: devismodel[]) => {
              if (invo) {
                this.factureproformas = invo;
                this.emitFactureproformas();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        });
      }
      getfactureproformauserwithoutsubscription(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'byuser/'  ,{userId}).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getFactureproformadataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(factureproforma: devismodel, image: File[]) {
        return new Promise((resolve, reject) => {
          const factureproformaData = new FormData();
          factureproformaData.append('factureproforma', JSON.stringify(factureproforma));
          for (let i = 0; i < image.length; i++)
          {
            factureproformaData.append('image', image[i],'logofacture'+factureproforma.number+'user'+factureproforma.userId); 
          }
          this.http.post(API_URL_cloud, factureproformaData).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      update(factureproformaId: string, factureproforma: devismodel, image: File[]) {
        return new Promise((resolve, reject) => {
          const factureproformaData = new FormData();
          factureproformaData.append('factureproforma', JSON.stringify(factureproforma));
          for (let i = 0; i < image.length; i++) {
            factureproformaData.append('image', image[i], 'logofacture' + factureproforma.number + 'user' + factureproforma.userId);
          }
          this.http.put(`${API_URL_cloud}/${factureproformaId}`, factureproformaData).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      
    /*  modifyfactureproformaById(id: string, factureproforma: devismodel) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, factureproforma).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }*/
      deletefactureproformadataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
