import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { invoicemodel } from '../models/invoice.model';
import { environment } from '../../environments/environment';

const API_URL_test = 'http://localhost:3003/api/clicto/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/clicto/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class clictopayService {
    
    
    constructor(private http: HttpClient) { }
   /* public invoices: invoicemodel[] = [];
    public invoices$ = new Subject<invoicemodel[]>();*/
    /*authorizeapi(amount:any,currency:any,language:any,orderNumber:any,returnUrl:any,failUrl:any,description:any,clientId:any,jsonParams:any) {
        this.http.get(API_URL_test+'amount='+amount+'&currency='+currency+'&language='+language+'&orderNumber='+orderNumber+'&password='+environment.password+'&returnUrl='+returnUrl+
        '&userName='+environment.login+'&failurl='+failUrl+'&description='+description+'&clientId='+clientId+'&jsonParams='+jsonParams).subscribe(
          (reponse:any) => {
        if (reponse.formUrl) {
console.log(reponse)
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }*/
      
      authorize(authorizeurl:any) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud,{authorizeurl}).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      stateauthorize(stateauthorizeurl:any) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'getorderstatus/',{stateauthorizeurl}).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
    }
