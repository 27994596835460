import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Proformatinvoicemodel } from '../models/proformatinvoice.model';
const API_URL_test = 'http://localhost:3003/api/proformatinvoice/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/proformatinvoice/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class ProformatinvoiceService {
    
    
    constructor(private http: HttpClient) { }
    public proformatinvoices: Proformatinvoicemodel[] = [];
    public proformatinvoices$ = new Subject<Proformatinvoicemodel[]>();
    public proformatarchivatedinvoices: Proformatinvoicemodel[] = [];
    public proformatarchivatedinvoices$ = new Subject<Proformatinvoicemodel[]>();
    getProformatinvoicealldata() {
        this.http.get(API_URL_cloud).subscribe(
          (proformatinvoices: Proformatinvoicemodel[]) => {
            if (proformatinvoices) {
              this.proformatinvoices = proformatinvoices;
              this.emitProformatinvoices();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitProformatinvoices() {
        this.proformatinvoices$.next(this.proformatinvoices);
      }
      getProformatinvoicearchivatedalldata(filters:any) {
        this.http.post(API_URL_cloud+'/filterarchivated/',filters).subscribe(
          (proformatinvoices: Proformatinvoicemodel[]) => {
            if (proformatinvoices) {
              this.proformatarchivatedinvoices = proformatinvoices;
              this.emitProformatarchivatedinvoices();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitProformatarchivatedinvoices() {
        this.proformatarchivatedinvoices$.next(this.proformatarchivatedinvoices);
      }
      getProformatinvoicedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(proformatinvoice: Proformatinvoicemodel) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, proformatinvoice).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
    /*  modifyproformatinvoiceById(id: string, proformatinvoice: Proformatinvoicemodel) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, proformatinvoice).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }*/
      deleteproformatinvoicedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
