import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  searchForm: FormGroup;
  @Input() globalarrayusers: any[]=[]; // Define searchForm as an input variable
  @Input() globalarrayalldsnonpaye: any[]=[]; // Define searchForm as an input variable
  @Input() showusersform: any; // Define searchForm as an input variable
  @Input() showalldsform: any; // Define searchForm as an input variable

  @Output() filteredArrayOutput: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    // Initialize the searchForm FormGroup and its form controls
    if(this.showusersform){
      this.searchForm = this.formBuilder.group({
        email: ['', [Validators.email]],
        mobile: [''],
        nom: [''],
        firstname: [''],
        raisonsociale: [''],
        clientcode: [''],
        natureactivite: ['']
      });
    }
else if(this.showalldsform){
  this.searchForm = this.formBuilder.group({
    client: ['']
  });
}
  }
  filterFunction(): any {
    const filteredArray = this.globalarrayusers.filter(user => {
      const firstname = this.searchForm.get('firstname').value;
      const email = this.searchForm.get('email').value;
      const mobile = this.searchForm.get('mobile').value;
      const nom = this.searchForm.get('nom').value;
      const raisonsociale = this.searchForm.get('raisonsociale').value;
      const clientcode = this.searchForm.get('clientcode').value;
      const natureactivite = this.searchForm.get('natureactivite').value;
  
      return (!firstname || user.firstname.includes(firstname)) &&
             (!email || user.email.includes(email)) &&
             (!mobile || user.mobile.includes(mobile)) &&
             (!nom || user.nom.includes(nom)) &&
             (!raisonsociale || user.raisonsociale.includes(raisonsociale)) &&
             (!clientcode || user.clientcode.includes(clientcode)) &&
             (!natureactivite || user.natureactivite.includes(natureactivite));
    });
    this.filteredArrayOutput.emit(filteredArray);
    return filteredArray;
  }
  filterFunctionalldsnonpaye(): any {
    const filteredArray = this.globalarrayalldsnonpaye.filter(dsnonpaye => {
      const clientid = this.searchForm.get('client').value;

      return (!dsnonpaye.nature&&dsnonpaye.clientId==clientid||dsnonpaye.nature&&dsnonpaye.userId==clientid);
    });
    this.filteredArrayOutput.emit(filteredArray);
    return filteredArray;
  }
  onfilter()
  {
    if(this.showusersform)
    {
      this.filterFunction()
    }
    else if(this.showalldsform)
    {
      this.filterFunctionalldsnonpaye()
    }
  }
}
