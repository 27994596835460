<div class="modal-signup" [style.display]="isSignupModalOpen ? 'block' : 'none'">
  <div class="modal-content">
    <div id="loader" *ngIf="loading"></div>
    <div class="right-section-signup"*ngIf="!loading">
      <h1>Formulaire d'inscription</h1>
      <h2>Merci de remplir le formulaire avec les informations obligatoires</h2>

      <!-- First Section of Inputs -->
      <div class="two-section-forms">
      <form class="input-section"[formGroup]="signupForm" (ngSubmit)="onSignup()">
        <div class="form-group-signup">
        <div *ngIf="submitted && f.email.errors">
            <div *ngIf="f.email.errors.required">Email Obligatoire</div>
            <div *ngIf="f.email.errors.email">Adresse Email non valides</div>
        </div>
        <label for="email2">Renseigner votre E-mail</label>
       <input  type="email" placeholder="Entrez Votre Email" id="email" 
        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"formControlName="email"(blur)="emailconfirmwithotp()">   
        </div>
        <div class="form-group-signup">
          <div *ngIf="submitted && f.usertype.errors" >
            <div *ngIf="f.usertype.errors.required">Type Utilisateur est obligatoire</div>
        </div>
          <label for="email1">Type Utilisateur:</label>
          <select [ngClass]="{ 'is-invalid': submitted && f.usertype.errors }"class="form-select" aria-label="Default select example"formControlName="usertype" name="usertype">
            <option hidden >Entrer Votre Type Utilisateur</option>
            <option value="Client">Client</option>
            <option disabled value="Candidat">Candidat</option>
          </select>
        </div>
       
     
        <div class="form-group-signup"*ngIf="signupForm.controls['nature'].value=='Personne Physique'||signupForm.controls['nature'].value=='Personne Morale'">
          <div *ngIf="submitted && f.firstname.errors" >
            <div *ngIf="f.firstname.errors.required">Prénom Obligatoire</div>
        </div>
          <span *ngIf="signupForm.controls['nature'].value=='Personne Physique'">Prénom Utilisateur:
            <input  type="text" placeholder="Entrer Votre Prénom" id="firstname" formControlName="firstname" name="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"></span>
          <span *ngIf="signupForm.controls['nature'].value=='Personne Morale'">Prénom du responsable de l'entité:
            <input  type="text" placeholder="Entrer Votre Prénom" id="firstname" formControlName="firstname" name="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"></span>
          </div>
        <div class="form-group-signup"*ngIf="signupForm.controls['nature'].value=='Personne Physique'||signupForm.controls['nature'].value=='Personne Morale'">
          <div *ngIf="submitted && f.lastname.errors" >
            <div *ngIf="f.lastname.errors.required">Nom Obligatoire</div>
        </div>
          <span *ngIf="signupForm.controls['nature'].value=='Personne Physique'">Nom Utilisateur:
            <input  type="text" placeholder="Entrer Votre Nom" id="lastname" formControlName="lastname" name="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"></span>
          <span *ngIf="signupForm.controls['nature'].value=='Personne Morale'">Nom du responsable de l'entité:
            <input  type="text" placeholder="Entrer Votre Nom" id="lastname" formControlName="lastname" name="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"></span>
            </div>
      
    
        <div class="form-group-signup">
          <div *ngIf="submitted && f.indicatif.errors">
            <div *ngIf="f.indicatif.errors.required">Indicatif Mobile Obligatoire </div>
            <div *ngIf="f.indicatif.errors.pattern">Veuillez saisir un indicatif valide</div> 
        </div>
        <div *ngIf="submitted && f.mobile.errors" >
          <div *ngIf="f.mobile.errors.required">Numéro Mobile Obligatoire</div>
          <div *ngIf="f.mobile.errors.pattern">Veuillez saisir un numéro mobile valide</div>
      </div>
      <label for="password1">Numéro de téléphone :</label>
      <div style="display: flex"><input  style="flex: 0.5"type="text" placeholder="Indicatif pays" id="indicatif" 
        [ngClass]="{ 'is-invalid': submitted && f.indicatif.errors }"formControlName="indicatif" style="width: 100px;" >
        <input  style="flex:1"type="text" placeholder="Numéro Mobile" id="mobile" 
        [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"formControlName="mobile"(blur)="mobileconfirmwithotp()">         
      </div> 
        </div>
        <div class="form-group-signup">
      <div *ngIf="submitted && f.password.errors" >
            <div *ngIf="f.password.errors.required">Mot de passe Obligatoire</div> 
        </div>
        <div *ngIf="submitted && f.confirmpassword.errors">
          <div *ngIf="f.confirmpassword.errors.required">Mot de passe Obligatoire</div>
        </div>
          <label for="password1">Mot de passe :</label>
          <div style="display: flex">
            <input  [type]="fieldTextType ? 'text' : 'password'" placeholder="Entrez Votre Mot De Passe" id="password" 
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"formControlName="password" required>
            <span >
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye-slash': !fieldTextType,
                  'fa-eye': fieldTextType
                }"
                (click)="toggleFieldTextType()"
              ></i>
            </span>
          </div>
                </div>
        <!-- Add more input fields for the first section here -->
      </form>

      <!-- Second Section of Inputs -->
      <form class="input-section"[formGroup]="signupForm" (ngSubmit)="onSignup()">
        <div class="form-group-signup">
        <div *ngIf="submitted && f.confirmemail.errors">
        <div *ngIf="f.confirmemail.errors.mustMatch">Les Emails  ne sont pas identiques</div>
        </div>
        <label for="email2">Confirmer votre E-mail</label>
        <input  type="email" placeholder="Confirmez Votre Email" id="confirmemail" 
          [ngClass]="{ 'is-invalid': submitted && f.confirmemail.errors }"formControlName="confirmemail" required>
        </div>
        <div class="form-group-signup">
          <div *ngIf="submitted && f.nature.errors" >
            <div *ngIf="f.nature.errors.required">Nature est obligatoire</div>
        </div>
          <label for="email1">Nature Utilisateur:</label>
          <select [ngClass]="{ 'is-invalid': submitted && f.nature.errors }"class="form-select" aria-label="Default select example"formControlName="nature" name="nature">
            <option hidden >Entrer la nature de l'utilisateur</option>
            <option value="Personne Physique">Personne Physique</option>
            <option value="Personne Morale">Personne Morale</option>
          </select>
        </div>
        <div class="form-group-signup">
          <div *ngIf="submitted && f.civilite.errors" >
            <div *ngIf="f.civilite.errors.required">Civilité est obligatoire</div>
        </div>
          <label for="email1">Civilité: </label>
          <select class="form-select" aria-label="Default select example"placeholder="Entrer Votre civilite" id="civilite" formControlName="civilite" name="civilite">
              <option selected>Open this select menu</option>
              <option hidden >Entrer Votre Civilité</option>
              <option value="Monsieur">Monsieur</option>
              <option value="Madame">Madame</option>
            </select>
        </div>
        <div class="form-group-signup"*ngIf="signupForm.controls['nature'].value=='Personne Morale'">
          <div *ngIf="submitted && f.raisonsociale.errors" >
            <div *ngIf="f.raisonsociale.errors.required">Raison Sociale est obligatoire</div>
        </div>
          <label for="email2">Raison sociale:</label>
          <input type="text" placeholder="Entrer Votre Raison sociale" id="raisonsociale" formControlName="raisonsociale" name="raisonsociale" [ngClass]="{ 'is-invalid': submitted && f.raisonsociale.errors }">
        </div>
        <div class="form-group-signup">
          <div *ngIf="submitted && f.confirmmobile.errors" >
            <div *ngIf="f.confirmmobile.errors.required">Numéro Mobile Obligatoire</div>
            <div *ngIf="f.confirmmobile.errors.mustMatch">Les numéros mobiles ne sont pas identiques</div>
            <div *ngIf="f.confirmmobile.errors.pattern">Veuillez saisir un numéro mobile valide</div>
          
          </div> 
          <label for="password1">Confirmer votre numéro :</label>
          <input  style="flex:1"type="text" placeholder="Confirmez Mobile" id="confirmmobile" 
          [ngClass]="{ 'is-invalid': submitted && f.confirmmobile.errors }"formControlName="confirmmobile">
        </div>
        <div class="form-group-signup">
          <div style="display: flex;"> 
        <div *ngIf="submitted && f.confirmpassword.errors" >
          <div *ngIf="f.confirmpassword.errors.required">Mot de passe Obligatoire</div>
          <div *ngIf="f.confirmpassword.errors.mustMatch">Les Mot de passe  ne sont pas identiques</div>
        </div></div>
          <label for="password1">Confirmer votre mot de passe :</label>
          <div style="display: flex">
            
            <input  [type]="repeatFieldTextType ? 'text' : 'password'" placeholder="Confirmer Votre Mot De Passe" id="confirmpassword" 
            [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }"formControlName="confirmpassword" required>
            <span >
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye-slash': !repeatFieldTextType,
                  'fa-eye': repeatFieldTextType
                }"
                (click)="togglerepeatFieldTextType()"
              ></i>
            </span>
          </div>
                </div>
        <div class="form-group-signup">
          <div *ngIf="submitted && f.clientcode.errors" >
            <div *ngIf="f.clientcode.errors.required">Veuillez générer votre code client</div>
        </div>
          <label for="password1">Code Client:</label>
          <div style="display: flex;"><input  style="background-color: white;color:black" type="text" id="clientcode" [ngClass]="{ 'is-invalid': submitted && f.clientcode.errors }"formControlName="clientcode" disabled>
            <input type="button" style="background-color: green;color:white"value="Générer code client" (click)="randomString()"></div>
          </div>
      
 
        <!-- Add more input fields for the second section here -->
      </form>
  </div>

      <div class="btn-signup-section">
          <div style="width: 50%; text-align: end;">
              <button class="reinstaller-btn-modal" (click)="onReset()">Réinitialiser</button>
          </div>
          <div style="width: 50%;">
              <button class="signup-btn-modal" (click)="onSignup()">Inscription</button>
          </div>
      </div>
    </div>

    <!-- Close Button (Top-left corner) -->
    <div class="close-button" (click)="closeSignupModal()" style="background-color: #F8F8F9;">
      <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
    </div>
  </div>
</div>
