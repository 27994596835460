<body>
  <div class="container">
    <div class="message">
      <h1>Bientôt en Marche</h1>
      <p>Page Introuvable Ou Fonctionnalité En cours de Développement</p>
    </div>
    <div class="content">
      <div class="image-wrapper">
        <img
          loading="lazy"
          src="../../assets/coming-soon-page.jpg"
          class="image"
          alt="Coming Soon"
        />
      </div>
    </div>
  </div>
</body>