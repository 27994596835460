import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Versionmodel } from '../models/versions.model';
const API_URL_test = 'http://localhost:3002/api/versionmodel/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/versionmodel/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class VersionService {
    constructor(private http: HttpClient) { }
    public versions: Versionmodel[] = [];
    public versions$ = new Subject<Versionmodel[]>();
    getversionalldata() {
        this.http.get(API_URL_cloud).subscribe(
          (versions: Versionmodel[]) => {
            if (versions) {
              this.versions = versions;
              this.emitversions();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      emitversions() {
        this.versions$.next(this.versions);
      }
      getversiondataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(version: Versionmodel) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, version).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      deleteversiondataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
    }
