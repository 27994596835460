import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { invoicemodel } from '../models/invoice.model';
const API_URL_test = 'http://localhost:3003/api/invoice/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/invoice/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class InvoiceService {
    
    
    constructor(private http: HttpClient) { }
    public invoices: invoicemodel[] = [];
    public invoices$ = new Subject<invoicemodel[]>();
    public archivatedinvoices: invoicemodel[] = [];
    public archivatedinvoices$ = new Subject<invoicemodel[]>();
    getInvoicealldata() {
        this.http.get(API_URL_cloud).subscribe(
          (invoices: invoicemodel[]) => {
            if (invoices) {
              this.invoices = invoices;
              this.emitInvoices();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitInvoices() {
        this.invoices$.next(this.invoices);
      }
      getinvoicearchivatedalldata(filters:any) {
        this.http.post(API_URL_cloud+'/filterarchivated/',filters).subscribe(
          (invoices: invoicemodel[]) => {
            if (invoices) {
              this.archivatedinvoices = invoices;
              this.emitarchivatedinvoices();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitarchivatedinvoices() {
        this.archivatedinvoices$.next(this.archivatedinvoices);
      }
      getinvoiceuser(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'byuser/'  ,{userId}).subscribe(
            (invo: invoicemodel[]) => {
              if (invo) {
                this.invoices = invo;
                this.emitInvoices();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        });
      }
      getInvoicedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(invoice: invoicemodel) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, invoice).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
    /*  modifyinvoiceById(id: string, invoice: invoicemodel) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, invoice).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }*/
      deleteinvoicedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
