



<div class="container">
  <div class="inner-container">
    <div class="header">Contacter Nous</div>
    <div class="sub-header">
      Nous vous garantissons l’excellence, la compétence et l’écoute engagée d’un partenaire
    </div>
    <div class="form-container">
      <div class="form-content">
        <div class="contact-info">
          <div class="contact-card">
            <div class="contact-title">Informations De Contact</div>
            
              <div class="contact-details">
                <div class="contact-item">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/196c6593e8708bc4bf3f5f1c38ef224625a93e90e38cdf5801b45ce775c385b0?" class="contact-icon" />
                  <div class="contact-text">98 35 05 75</div>
                </div>
                <div class="contact-item email-item">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5a12f737972c9ef4bbd20bec9276a2c62c35f1964700d6b86c3b7b4631abc794?" class="contact-icon" />
                  <div class="contact-text email">macompta@macompta.com.tn</div>
                </div>
                <div class="contact-item address-item">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/cb374b3441aa9f8fe2efa8fdd51320dce1086ba8a37a7511e00dcb708b0df9ed?" class="contact-icon" />
                  <div class="contact-text address">Ariana center Bureau A318 Ariana 2080</div>
                </div>
              </div>
             
            <img src="assets/letter.png" alt="img" class="letter">

            <div class="icon-container">
              <a href="https://www.facebook.com/profile.php?id=100089278023685" target="_blank"class="bg-light nav-fill btn btn-sm-square rounded-circle"><i class="fab fa-facebook-f " style = "color: #031A3D;"></i></a>
                <a href="" class="bg-light nav-fill btn btn-sm-square rounded-circle"><i class="fab fa-twitter " style = "color: #031A3D;"></i></a>
                <a href="" class="bg-light nav-fill btn btn-sm-square rounded-circle"><i class="fab fa-instagram " style = "color: #031A3D;"></i></a>
                <a href="" class="bg-light nav-fill btn btn-sm-square rounded-circle me-0"><i class="fab fa-linkedin-in " style = "color: #031A3D;"></i></a>
            </div> 
          </div>
        </div>
        <div class="contact-form">
          
          <form [formGroup]="contactform"(ngSubmit)="onSubmit()"style="text-align: center;">
            <div class="form-group">
              <label for="nom">Nom</label>
              <input type="text" class="form-control" formControlName="lastname" id="lastname" placeholder=" Entrez Votre Nom"
              [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"placeholder="entrer votre nom"required>
              <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                <div *ngIf="f.lastname.errors.required">Nom Obligatoire</div>
            </div> 
            </div>
            <div class="form-group">
              <label for="prenom">Prénom</label>
              <input type="text" class="form-control" formControlName="firstname" id="firstname" placeholder=" Entrez Votre Prenom"
              [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"placeholder="entrer votre prenom" required>
              <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                <div *ngIf="f.firstname.errors.required">Prénom Obligatoire</div>
            </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" formControlName="email"id="email" placeholder=" Entrez Votre Email"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"placeholder="entrer votre email">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email Obligatoire</div>
              <div *ngIf="f.email.errors.email">Adresse Email non valides</div>
          </div>
            </div>
            <div class="form-group">
              <label for="mobile">Numéro Mobile</label>
              <input type="tel" class="form-control" id="mobile" formControlName="mobile"placeholder=" Entrez Votre Numéro Mobile"
              [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"placeholder="entrer votre mobile" required>
              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                <div *ngIf="f.mobile.errors.required">Numéro Mobile Obligatoire</div>
            </div>
            </div>
            <div class="form-group">
              <label for="file">Attacher Fichier</label>
              <input type="file"  #filePicker (change)="onImagePick($event)" class="form-control" 
           accept="application/pdf">
           <div class="image-preview" *ngIf="imagePreview">
             <img [src]="imagePreview" alt="">
           </div>
           <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
            </div>
            <div class="form-group">
              <label for="description">Description</label>
              <textarea  class="form-control" id="description" formControlName="description"placeholder="Rédiger Votre Message"
              [ngClass]="{ 'is-invalid': submitted && f.description.errors }"placeholder="Présentez vous brièvement"required></textarea>
                             <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                 <div *ngIf="f.description.errors.required">Description Obligatoire</div>
                             </div>
            </div>
            <div class="form-buttons">
              <button type="submit" class="btn btn-default submit"><i class="fa fa-paper-plane" aria-hidden="true"></i>  Envoyer Message</button>
            <button type="reset" class="btn btn-default submit"(click)="onReset()">  Réinitialiser</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>