import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '../services/auth.service';


import { CondidateService } from '../services/condidate.service';
import { Condidate } from '../models/condidate.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '../models/user.model';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { ContactService } from '../services/contact.service';
import { Contact } from '../models/contact.model';
import Swal from 'sweetalert2';
import { OtherdsServiceUser } from '../services/otherds-user.service';
import { Otherdsmodeluser } from '../models/otherds-user.model';
import { Proformatinvoicemodel } from '../models/proformatinvoice.model';

import { CommunService } from '../services/commun';
import { ProformatinvoiceService } from '../services/proformatinvoice.service';
import { clictopayService } from '../services/clictopay.service';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Sort } from '../_helpers/sort';
import { invoicemodel } from '../models/invoice.model';
import { InvoiceService } from '../services/invoice.service';
import { MatTableDataSource } from '@angular/material/table';
import { FactureService } from '../services/facture.devis';
import { DevisService } from '../services/devis.service';
import { FactureproformaService } from '../services/factureproforma.service';
import { devismodel } from '../models/facture.model';
@Component({
  selector: 'app-user-board',
  templateUrl: './user-board.component.html',
  styleUrls: ['./user-board.component.scss']
})
export class UserBoardComponent implements OnInit,OnDestroy {
  public filtredusers: User[] = [];
  prenom:string;
  nom:string
  role: string;
  isLoggedIn = false;
  displaynote='none'
  today=new Date()
  private condidatesSub: Subscription;
  private contactsub: Subscription;
  private decfiscmenssSub: Subscription;
  public decfiscmens: Decfiscmens;
  private deccomptabilitesSub: Subscription;
  private facturesclientSub: Subscription;
  private devisclientSub: Subscription;
  private facturesproclientSub: Subscription;

  public deccomptabilite: Deccomptabilite;
  public facturesclient: devismodel[]=[];
  public facturesproclient: devismodel[]=[];
  public devisclient: devismodel[]=[];


  public errormsg:string;
  public loading: boolean;
  usertype: string;
  email: string;
  userId:string;
  converteddate: Date;
  converteddate2: Date;
  public condidates: Condidate[] = [];
  public contacts: Contact[] = [];
  public decfiscmenss: Decfiscmens[] = [];
  public deccomptabilites: Deccomptabilite[] = [];
  showgenerate: boolean;
  candidaturevalide=false
  candidaturenonvalide=false
  reclamationtraite=false
  reclamationnontraite=false
  decfiscmenswindow=false
  deccomptabilitewindow=false
  facturesclientwindow=false
  facturesproclientwindow=false
  devisclientwindow=false

  decfiscmensnumber=0;
  deccomptabilitenumber=0;
  condval=0;
  condnonal=0;
  contval=0;
  contnonval=0;
  facturesclientval=0;
  devisclientval=0;
  facturesproclientval=0;

  user: User;
  proformatinvoicemodel: Proformatinvoicemodel;

  allotherdsSub: Subscription;
  otherdss: Otherdsmodeluser[]=[];
  alldeccomptabSub: Subscription;
  dsdeccomptabilites: Deccomptabilite[]=[];
  alldecfiscmensSub: Subscription;
  dsdecfiscmenss: Decfiscmens[]=[];
  payedotherdss: Otherdsmodeluser[]=[];
  nonpayedotherdss: Otherdsmodeluser[]=[];
  payedsdeccomptabilites: Deccomptabilite[]=[];
  nonpayedsdeccomptabilites: Deccomptabilite[]=[];
  payedsdecfiscmenss: Decfiscmens[]=[];
  nonpayedsdecfiscmenss: Decfiscmens[]=[];
  alldssusernonpayewindow=false;
  alldssuserpayewindow=false;
  alldecfiscmenss: Decfiscmens[]=[];
  alldeccomptabilites: Deccomptabilite[]=[];
  submitting=false;
  previwing=false;
  option0value: string;
  ordernumebr: any;
  proformatinvoicegenerated: any;
  invoicewindow=false;
  invsub: Subscription;
  invoices=[];
  hoverFeature: string = '';
  collapse1 = false;
  collapse2 = false;
  collapse3 = false;
  collapse4 = false;
  collapse5 = false;
  collapse6 = false;
  selectedTab=0;
  displayedColumns: string[] = ['code', 'nature', 'mois', 'annee', 'prenom', 'nom', 'statut', 'totalHonoraireHT', 'dateCreation', 'payement', 'actions'];
  dataSource: MatTableDataSource<any>;
  constructor(          
    private Auth: TokenStorageService,
    private cond:CondidateService,
    private cont:ContactService,private clicserv:clictopayService,
    private deccompt: DeccomptabiliteService,private proforinv: ProformatinvoiceService,private invoi: InvoiceService,
    private router: Router,private comm: CommunService,private userservice: UserService,private factclserv: FactureService,private factproserv: FactureproformaService,private devclserv: DevisService,
    private dec: DecfiscmensService,private otheruser: OtherdsServiceUser,
    private route: ActivatedRoute,
    
    ) {
      this.dataSource = new MatTableDataSource(this.getalldsusernonpaye());
     }
  ngOnInit() {
      const user = this.Auth.getUser();
 //     this.user=user
 //console.log(this.router.url.split('orderId=')[1].split('&')[0])

      this.usertype = user.usertype;
      this.email = user.email;
      this.userId = user.userId;
      this.userservice.getUserById(this.userId).then(
        (user: User) => {
          this.user=user
        }
      )
      this.role=user.role;
      this.prenom=user.Firstname
      this.nom=user.Lastname
    this.loading = true;          
    this.condidatesSub = this.cond.condidates$.subscribe(
      (condidates) => {
        this.condidates = condidates;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        
        this.errormsg=error.message;
      }
    );
   if (this.usertype=='Candidat'||this.usertype=='candidat')
   {    this.cond.getCondidate(this.email);
   }
   this.contactsub = this.cont.contactreqs$.subscribe(
    (contacts) => {
      this.contacts = contacts;
      this.loading = false;
    },
    (error) => {
      this.loading = false;
      
      this.errormsg=error.message;
    }
  );
    this.cont.getContact(this.email);
    this.invsub = this.invoi.invoices$.subscribe(
      (invs) => {
        this.invoices = invs;
        this.loading = false;
        if(this.router.url.includes('orderId'))
        {
          let originalurlvalue='baseurl'
          let orderId=this.router.url.split('orderId=')[1].split('&')[0]
          let login='credentiellogin'
          let password='motdepasse'
          let language='fr'
          let url=originalurlvalue+'&orderId='+orderId+'&password='+password+'&userName='+login+'&language='+language
          this.clicserv.stateauthorize(url).then(
            (res:any)=>
            {
              res.OrderStatus!=2?Swal.fire('opération de paiement refusée pour la raison suivante:'+`${res.ErrorMessage}!`):
              (
              this.proforinv.getProformatinvoicedataById(res.OrderNumber).then(
                async (resp:any)=>{
                 console.log(this.invoices)
                 console.log(this.invoices.find(e=>e.profid==res.OrderNumber))
                  if(!this.invoices.find(e=>e.profid==res.OrderNumber))
                  {
                    let allds=[]
                 
                    allds=allds.concat(resp.totalds.demandesnonsoumis,resp.totalds.demandessoumis)
                    for await (const element of allds) {
                      if(element.nature=='comptabilite')
                      {
                        const newelement: Deccomptabilite = new Deccomptabilite()
                newelement.paye=true
                await  this.deccompt.completedeccomptabilitereqById(element._id,newelement).then(
                  (data:any) => {
                   // this.loading = false;
                  },
                  (error) => {
                  //  this.loading = false;
                    
                  }
                )
                      }
                      else  if(element.nature=='Déclaration Mensuelle')
                      {
                        const newelement: Decfiscmens = new Decfiscmens()
                newelement.paye=true
                await  this.dec.completedecfiscmensreqById(element._id,newelement).then(
                  (data:any) => {
                   // this.loading = false;
                  },
                  (error) => {
                  //  this.loading = false;
                    
                  }
                )
                      }
                      else  if(!element.nature)
                      {
                        const newelement: Otherdsmodeluser = new Otherdsmodeluser()
                newelement.paye=true
                await  this.otheruser.modifyotherdsByIdforuser(element._id,newelement).then(
                  (data:any) => {
                   // this.loading = false;
                  },
                  (error) => {
                  //  this.loading = false;
                    
                  }
                )
                      }
                    
                    }
                    const Invoicemodel:invoicemodel=new invoicemodel()
                    Invoicemodel.totalds={userId:'',demandesnonsoumis:[],demandessoumis:[],totalht:'',totalgeneral:'',soustotal1:'',soustotal2:''
                    ,timbrefiscal:'',tva:''}
                    Invoicemodel.userId=this.user._id
                    Invoicemodel.orderid=orderId
                    Invoicemodel.totalds.userId=this.user._id
                    Invoicemodel.totalds.demandesnonsoumis=resp.totalds.demandesnonsoumis
                    Invoicemodel.totalds.demandessoumis=resp.totalds.demandessoumis
                    Invoicemodel.totalds.totalht=resp.totalds.totalht
                    Invoicemodel.totalds.soustotal1=resp.totalds.soustotal1
                    Invoicemodel.totalds.soustotal2=resp.totalds.soustotal2
                    Invoicemodel.totalds.timbrefiscal=resp.totalds.timbrefiscal
                    Invoicemodel.totalds.totalgeneral=resp.totalds.totalgeneral
                    Invoicemodel.totalds.tva=resp.totalds.tva
                    Invoicemodel.profid=res.OrderNumber
                    await this.invoi.create(Invoicemodel).then(
                      (da:any) => {
                        console.log(da)
                        Swal.fire({
                          title: 'opération de paiement réalisée avec succès! veuillez choisir entre les deux options',
                          icon: 'info',
                          confirmButtonColor: '#3085d6',
                          showDenyButton: true,
                          confirmButtonText: 'consulter la facture',
                          denyButtonText: 'rester sur le tableau de bord',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.router.navigate(['view-invoice/'+`${da.data._id}`]);
                          }
                          else{
                    this.router.navigate(['user-board']);
                  }
             
                        }).catch(() => {
                          Swal.fire('opération non aboutie!');
                        });             // this.loading = false;
                      },
                      (error) => {
                      //  this.loading = false;
                        
                      }
                    )
                  }
              
                  
                }
              
              )
              )
              console.log(res)
              this.submitting = false;
            },
            (error) => {
            this.submitting = false;
            }
          )
        }
      },
      (error) => {
        this.loading = false;
        
        this.errormsg=error.message;
      }
    );
    if(this.router.url.includes('dsapayer'))
    {
      this.alldssusernonpayewindow=true
    }
    this.decfiscmenssSub = this.dec.decfiscmenss$.subscribe(
      (decfiscmenss) => {
        this.alldecfiscmenss=decfiscmenss
        this.decfiscmenss = decfiscmenss.filter(e=>!e.source);
        this.loading = false;
        this.decfiscmensnumber=this.decfiscmenss.length

      },
      (error) => {
        this.loading = false;
        
        this.errormsg=error.message;
      }
    );
    this.deccomptabilitesSub = this.deccompt.deccomptabilites$.subscribe(
      (deccomptabilites) => {
        this.alldeccomptabilites=deccomptabilites
        this.deccomptabilites = deccomptabilites.filter(e=>!e.source);
        this.loading = false;
        this.deccomptabilitenumber=this.deccomptabilites.length
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.allotherdsSub = this.otheruser.otherdss$.subscribe(
      (otherdss) => {
        this.otherdss = otherdss.filter(e=>new Date(e.created) >=new Date('11.30.2023'));
        this.payedotherdss= this.otherdss.filter(e=>e.paye==true)
        this.nonpayedotherdss= this.otherdss.filter(e=>e.paye==false)
        
        this.loading = false;
//        this.deccomptabilitenumber=this.deccomptabilites.length
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.alldeccomptabSub = this.deccompt.dsdeccomptabilitesuser$.subscribe(
      (deccomptabilities) => {
        this.dsdeccomptabilites = deccomptabilities.filter(e=>new Date(e.created)>=new Date('11.30.2023'));
        this.payedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==true)
        this.nonpayedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==false)
        this.loading = false;
//        this.deccomptabilitenumber=this.deccomptabilites.length
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.alldecfiscmensSub = this.dec.dsdecfiscmenss$.subscribe(
      (decfiscmenss) => {
        this.dsdecfiscmenss = decfiscmenss.filter(e=>new Date(e.created)>=new Date('11.30.2023')&&e.origine!='généré automatiquement');
        this.payedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==true)
        this.nonpayedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==false)

        this.loading = false;
//        this.deccomptabilitenumber=this.deccomptabilites.length
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.facturesclientSub = this.factclserv.factures$.subscribe(
      (factures) => {
        this.facturesclient=factures
        this.loading = false;
        this.facturesclientval=this.facturesclient.length
        console.log(this.facturesclientval)
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.facturesproclientSub = this.factproserv.factureproformas$.subscribe(
      (factures) => {
        this.facturesproclient=factures
        this.loading = false;
        this.facturesproclientval=this.facturesproclient.length
        console.log(this.facturesproclientval)

      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.devisclientSub = this.devclserv.deviss$.subscribe(
      (factures) => {
        this.devisclient=factures
        this.loading = false;
        this.devisclientval=this.devisclient.length
        console.log(this.devisclientval)

      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    if (this.usertype==='Client')
    {
   this.dec.getdecfiscmens(this.userId).then(
      (decfiscmens: Decfiscmens) => {
        this.loading = false;
        this.decfiscmens = decfiscmens;
        this.converteddate=this.addHours(this.decfiscmens.created);
        this.decfiscmens.created=this.converteddate    
      }
    )
    this.deccompt.getdeccomptabilite(this.userId).then(
      (deccomptabilite: Deccomptabilite) => {
        this.loading = false;
        this.deccomptabilite = deccomptabilite;
        this.converteddate2=this.addHours(this.deccomptabilite.created);
        this.deccomptabilite.created=this.converteddate2        
      }
    )
    this.otheruser.getOtherdsdataByuser(this.userId)
    this.deccompt.getDeccomptabilitereqByfactureuser(this.userId)
    this.dec.getdecfiscmensqByfactureuser(this.userId)
    this.invoi.getinvoiceuser(this.userId);

  };  
  }
  getNavigationinvoice(link, id)
{
  this.router.navigate([link + '/' + id]); 
}
  async payinvoice(){
    this.submitting = true;
    let amount=Math.trunc(+this.shownotehonoraire()[7]*1000)
    let currency=788
    let language='fr'
    let ordernumber=this.ordernumebr
    let returnUrl='url/user-board'
    let failUrl='failurl/user-board'
    let login='credentiellogin'
    let password='motdepasse'
    let description='autorisation'
    let clientId=this.user._id
    let originalurlvalue='baseurl'
    let url=originalurlvalue+'amount='+amount+'&currency='+currency+'&language='+language+'&orderNumber='+ordernumber+'&password='+password+'&returnUrl='+returnUrl+
    '&userName='+login+'&failurl='+failUrl+'&description='+description+'&clientId='+clientId/*+"&jsonParams={'profid':"+`${this.ordernumebr}`+"}"*/
this.clicserv.authorize(url).then(
  (res:any)=>
  {
    console.log(res)
    this.submitting = false;
    if(res.formUrl)
    {
      this.router.navigate([]).then((_result) => {
        window.open(res.formUrl, '_blank');
      })
    }
    else
    {
      alert(res.errorMessage)
    }
  },
  (error) => {
  this.submitting = false;
  }
)
   /* for await (const element of this.shownotehonoraire()[4]) {
      if(element.nature=='comptabilite')
      {
        const newelement: Deccomptabilite = new Deccomptabilite()
newelement.paye=true
await  this.deccompt.completedeccomptabilitereqById(element._id,newelement).then(
  (data:any) => {
   // this.loading = false;
  },
  (error) => {
  //  this.loading = false;
    
  }
)
      }
      else  if(element.nature=='Déclaration Mensuelle')
      {
        const newelement: Decfiscmens = new Decfiscmens()
newelement.paye=true
await  this.dec.completedecfiscmensreqById(element._id,newelement).then(
  (data:any) => {
   // this.loading = false;
  },
  (error) => {
  //  this.loading = false;
    
  }
)
      }
      else  if(!element.nature)
      {
        const newelement: Otherdsmodeluser = new Otherdsmodeluser()
newelement.paye=true
await  this.otheruser.modifyotherdsByIdforuser(element._id,newelement).then(
  (data:any) => {
   // this.loading = false;
  },
  (error) => {
  //  this.loading = false;
    
  }
)
      }
    
    };*/
    this.submitting=false
   //this.reloadPage()
  }
  public printinvoice(): void {
    const self =this
    const data = document.getElementById('honoraireuser');
    html2canvas(data,{scale:2}).then((canvas:any) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
       
      doc.save(`facture ${self.option0value}`);
    });
    
    
    
      } 
  verifyexercicecloture(annee:string,mois:string):boolean
{
  let show=false
  let filtredmois=[]
  filtredmois=this.comm.filterMois(annee,this.user)
  filtredmois.find(e=>e.value==mois)?show=false:show=true
  return show
}
  getalldsuser():any{
    let alldssuserlength:any
    let alldssuser:any=[]
    alldssuser=alldssuser.concat(this.otherdss,this.dsdeccomptabilites,this.dsdecfiscmenss)
alldssuserlength=alldssuser.length
let resultobject=[alldssuser,alldssuserlength]
return resultobject
  }
  getalldsusernonpaye():any{
    let alldssusernonpayelength:any
    let alldssusernonpaye:any=[]
    alldssusernonpaye=alldssusernonpaye.concat(this.nonpayedotherdss,this.nonpayedsdeccomptabilites,this.nonpayedsdecfiscmenss)
    const sort = new Sort();
    alldssusernonpaye=alldssusernonpaye.sort(sort.startSort('created','asc',''));
    alldssusernonpayelength=alldssusernonpaye.length
let resultobject=[alldssusernonpaye,alldssusernonpayelength]
return resultobject
  }
  getalldsuserpaye():any{
    let alldssuserpayelength:any
    let alldssuserpaye:any=[]
    alldssuserpaye=alldssuserpaye.concat(this.payedotherdss,this.payedsdeccomptabilites,this.payedsdecfiscmenss)
    const sort = new Sort();
    alldssuserpaye=alldssuserpaye.sort(sort.startSort('created','asc',''));
    alldssuserpayelength=alldssuserpaye.length
let resultobject=[alldssuserpaye,alldssuserpayelength]
return resultobject
  }
  getNavigationautreds(link, id)
  {
    this.router.navigate([link + '/' + id]); 
  }
  getNavigationcondidates(link, id){
    this.router.navigate([link + '/' + id]); 
  }
  getNavigationdecfiscmens(link, id){
    this.router.navigate([link + '/' + id]); 
  }
  getNavigation(link, id){
   /* let deccompt=this.alldeccomptabilites.find(e=>e._id==id)
    if(deccompt.statutadmin.length>0)
    {
  //@ts-ignore
  if(deccompt.statutadmin[deccompt.statutadmin.length-1].statut=='clôturé'&&this.user.regimefiscalimpot=='Forfait D\'assiette'&&link=='modify-deccomptabilite')
  {
    Swal.fire('opération non autorisée')
  }
  else{
    this.router.navigate([link + '/' + id]); 
  }
    }
  else{*/
    this.router.navigate([link + '/' + id]); 
 // }

  }
  getNavigationcontacts(link, id){

    this.router.navigate([link + '/' + id]); 
  }
  addHours(date:Date) {
    date.setTime(date.getTime() * 60 * 60 * 1000);

    return date;
  }  
getcondidatevalide() {
  //@ts-ignore                    

this.condval=(this.condidates.filter((condidate) => condidate.statutadmin.find(e => e.statut==='clôturé'))).length                                 
 //@ts-ignore                    

return this.condidates.filter((condidate) => condidate.statutadmin.find(e => e.statut==='clôturé'));                                                           
                                      
}
getcondidatenonvalide() {
//@ts-ignore                    

this.condnonal=(this.condidates.filter((condidate) => !condidate.statutadmin.find(e => e.statut==='clôturé'))).length                                   
//@ts-ignore                    

return this.condidates.filter((condidate) => !condidate.statutadmin.find(e => e.statut==='clôturé'));                                                           
                                    
} 
getcontactvalide() {
  //@ts-ignore                    

this.contval=(this.contacts.filter((contact) => contact.statutadmin.find(e => e.statut==='clôturé'))).length                                   
 //@ts-ignore                    

return this.contacts.filter((contact) => contact.statutadmin.find(e => e.statut==='clôturé'));                                                           
                                      
}
getcontactnonvalide() {
//@ts-ignore                    

this.contnonval=(this.contacts.filter((contact) => !contact.statutadmin.find(e => e.statut==='clôturé'))).length                                      
//@ts-ignore                    

return this.contacts.filter((contact) => !contact.statutadmin.find(e => e.statut==='clôturé'));                                                           
                                    
} 
onTabChange(event: any) {
  this.selectedTab = event.index;
}
shownotehonoraire():any
{
  let dstopay=[]
  let dstopaynonsoumis=[]
  let totalhonht=0
  let totaltva=0
  let totalttc=0
  let soustotal2=0
  let totalgeneral=0
  let timbrefiscal=0
  for (let i = 0; i < this.getalldsusernonpaye()[1] ; i++) 
  {
    let checkbox:any = document.getElementById('dsnonpayecheckbox'+`${i}`);
    checkbox?
    checkbox.checked==true?
    this.getalldsusernonpaye()[0][i].soumis||this.getalldsusernonpaye()[0][i].nature?
    dstopay.push(this.getalldsusernonpaye()[0][i]):
   dstopaynonsoumis.push(this.getalldsusernonpaye()[0][i])
   :''
   :''
  }

dstopay.length>0?
totalhonht=dstopay.reduce((acc,curr)=>{
  acc +=  +(curr.totalhonoraire || 0);
  return acc;
},0)
:
totalhonht=0
dstopaynonsoumis.length>0?
soustotal2=dstopaynonsoumis.reduce((acc,curr)=>{
  acc +=  +(curr.totalhonoraire || 0);
  return acc;
},0)
:
soustotal2=0
totaltva= totalhonht*0.19
timbrefiscal=1
totalttc=totalhonht+totaltva
totalgeneral=totalttc+soustotal2+timbrefiscal
let result=[totalhonht,totaltva,timbrefiscal,totalttc,dstopay,dstopaynonsoumis,soustotal2,totalgeneral]

return result
}
previewnotehonoraire()
{
  if(this.shownotehonoraire()[4].length==0&&this.shownotehonoraire()[5].length==0)
  return (
    alert('veuillez sélectionner au moins une ds!')
)
  //this.shownotehonoraire()[4].length==0&&this.shownotehonoraire()[5].length==0?alert('veuillez sélectionner au moins une ds!'):''
  this.previwing = true;
  const proformatinvoicemodel:Proformatinvoicemodel = new Proformatinvoicemodel();
  //let ammounts3=this.otherdsform.get('ammounts3') as FormArray;
  proformatinvoicemodel.totalds={userId:'',demandesnonsoumis:[],demandessoumis:[],totalht:'',totalgeneral:'',soustotal1:'',soustotal2:''
,timbrefiscal:'',tva:''}
      proformatinvoicemodel.totalds.userId=this.user._id
      proformatinvoicemodel.totalds.demandesnonsoumis=this.shownotehonoraire()[5]
proformatinvoicemodel.totalds.demandessoumis=this.shownotehonoraire()[4]
proformatinvoicemodel.totalds.totalht=this.shownotehonoraire()[0]
proformatinvoicemodel.totalds.soustotal1=this.shownotehonoraire()[3]
proformatinvoicemodel.totalds.soustotal2=this.shownotehonoraire()[6]
proformatinvoicemodel.totalds.timbrefiscal=this.shownotehonoraire()[2]
proformatinvoicemodel.totalds.totalgeneral=this.shownotehonoraire()[7]
proformatinvoicemodel.totalds.tva=this.shownotehonoraire()[1]
this.proforinv.create(proformatinvoicemodel).then(
        (data:any) => {
          console.log(data)
          this.previwing=false
          this.proformatinvoicegenerated=data.data
          this.ordernumebr=data.data._id
          this.option0value='P/'+data.data.prefixe+'/'+data.data.ref
this.displaynote='block'
         // this.loading = false;
        },
        (error) => {
        //  this.loading = false;
          
        }
      )
 
  
}
closecopPopup()
{
  this.displaynote='none'
}
click6()
{
  this.deccomptabilitewindow=true
} 
  click7()
          {
            this.decfiscmenswindow=true
          } 
          click8()
          {
            this.otheruser.getOtherdsdataByuser(this.userId)
            this.deccompt.getDeccomptabilitereqByfactureuser(this.userId)
            this.dec.getdecfiscmensqByfactureuser(this.userId)
            this.alldssusernonpayewindow=true
          } 
          click9()
          {
            this.otheruser.getOtherdsdataByuser(this.userId)
            this.deccompt.getDeccomptabilitereqByfactureuser(this.userId)
            this.dec.getdecfiscmensqByfactureuser(this.userId)
            this.alldssuserpayewindow=true
          } 
          click11()
          {
            this.candidaturevalide=true
          }
          click12()
          {
            this.candidaturenonvalide=true
          }
          click13()
          {
            this.reclamationtraite=true
          }
          click14()
          {
            this.reclamationnontraite=true
          }  
          click10()
          {
            this.invoicewindow=true
          }  
          click15()
          {
            this.invoicewindow=false
          }
          click16()
          {
            this.facturesclientwindow=true
            this.factclserv.getfactureuser(this.userId).then(
              res=>{
              }
            )
          }
          click17()
          {
            this.facturesproclientwindow=true
            this.factproserv.getfactureproformauser(this.userId).then(
              res=>{
              }
            )
          }
          click18()
          {
            this.devisclientwindow=true
            this.devclserv.getdevisuser(this.userId).then(
              res=>{
              }
            )
          }

          click32()
          {
            this.decfiscmenswindow=false
          }
          click33()
          {
            this.deccomptabilitewindow=false
          }
          click25()
          {
            this.candidaturevalide=false
          }
          click26()
          {
            this.candidaturenonvalide=false
          }
          click27()
          {
            this.reclamationtraite=false
          }
          click28()
          {
            this.reclamationnontraite=false
          }
          click34()
          {
            this.alldssusernonpayewindow=false
          }
          click35()
          {
            this.alldssuserpayewindow=false
          }
          click19()
          {
            this.facturesclientwindow=false
          }
          click20()
          {
            this.facturesproclientwindow=false
          }
          click21()
          {
            this.devisclientwindow=false
          }
          reloadPage(): void {
  
            setTimeout(() => window.location.reload(), 1000);
            
          }
          ngOnDestroy(){
            this.condidatesSub?this.condidatesSub.unsubscribe():'';
            this.contactsub?this.contactsub.unsubscribe():''
            this.decfiscmenssSub?this.decfiscmenssSub.unsubscribe():''
            this.deccomptabilitesSub?this.deccomptabilitesSub.unsubscribe():''
            this.allotherdsSub?this.allotherdsSub.unsubscribe():''
            this.alldeccomptabSub?this.alldeccomptabSub.unsubscribe():''
            this.alldecfiscmensSub?this.alldecfiscmensSub.unsubscribe():''
            this.invsub?this.invsub.unsubscribe():''

            }
  }
  

  
  
  
  

 


