import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Usedressourcemodel } from '../models/usedressource.model';
const API_URL_test = 'http://localhost:3003/api/usedressource/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/usedressource/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class UsedressourceService {
    constructor(private http: HttpClient) { }
    public usedressourcess: Usedressourcemodel[] = [];
    public usedressourcess$ = new Subject<Usedressourcemodel[]>();
    getUsedressourcealldata() {
        this.http.get(API_URL_cloud).subscribe(
          (usedressourcess: Usedressourcemodel[]) => {
            if (usedressourcess) {
              this.usedressourcess = usedressourcess;
              this.emitUsedressourcess();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      emitUsedressourcess() {
        this.usedressourcess$.next(this.usedressourcess);
      }
      getUsedressourcedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(usedressource: Usedressourcemodel) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, usedressource).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      deleteusedressourcedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
    }
