import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-choix-declaration',
  templateUrl: './choix-declaration.component.html',
  styleUrls: ['./choix-declaration.component.scss']
})
export class ChoixDeclarationComponent {

  
  checkboxStates: boolean[] = [false, false, false, false, false, false, false];

  constructor(private router: Router, private snackBar: MatSnackBar) {}
  selectedIndex: number = -1; 
  toggleCheck(index: number): void {
    
    // Reset all checkboxes and set the clicked one to true
    this.checkboxStates = this.checkboxStates.map((_, i) => i === index);
  
    // Clear localStorage before setting new item
    localStorage.removeItem('selectedDeclarationIndex');
    localStorage.setItem('selectedDeclarationIndex', index.toString());
    this.selectedIndex = index;
  }
  openSnackbar(message: string): void {
    this.snackBar.open(message, 'OK', {
      duration: 3000,
      panelClass: ['custom-snackbar', 'custom-snackbar-top']
    });
  }
  directDataEntry() {
    // Your logic for "Saisie directe des données sur la déclaration"
    this.router.navigate([ 'desc-fisc-mens-direct/tt' ]);
  }

  customTemplate() {
    // Your logic for "Passer par une maquette personnalisée"
    this.router.navigate([ 'declare-fiscality' ]);
  }
  redirectToDeclaration(): void {
    // Find the index of the checked checkbox
    const selectedIndex = this.checkboxStates.findIndex(state => state);

    if (selectedIndex === -1) {
      this.openSnackbar('Veuillez sélectionner une option avant de continuer.');
    } else {
      // Navigate based on the selected index
      switch (selectedIndex) {
        case 0:
          Swal.fire({
            title: 'Choisissez une option',
            text: 'Sélectionnez une des options suivantes :',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Saisie directe des données sur la déclaration',
            cancelButtonText: 'Annuler',
            showDenyButton: true,
            denyButtonText: 'Passer par une maquette personnalisée',
            customClass: {
              confirmButton: 'btn btn-primary',
              denyButton: 'btn btn-secondary',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.directDataEntry();
            } else if (result.isDenied) {
              this.customTemplate();
            }
          });
          break;
          case 1:
          this.router.navigate( [ '/dec-acompte-prov' ]);
          break;
       /* case 2:
          this.router.navigate(  [ '/declamensuelle' ]);
          break;
        case 3:
          this.router.navigate(['/declaration-employeur']);
          break;
        case 4:
          this.router.navigate(['/liasse-fiscale']);
          break;
        case 5:
          this.router.navigate(['/declaration-trimestrielle-achats']);
          break;
        case 6:
          this.router.navigate(['/declaration-trimestrielle-ventes']);
          break;*/
        default:
          // Handle default case or do nothing
          this.openSnackbar('Ce type de déclaration est en cours de développement');
          break;
      }
    }
  }

}
