<body>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <mat-card class="col-md-3 feature-card feature-background-1" *ngIf="decfiscmenss.length > 0 && user && usertype === 'Client'" (mouseenter)="hoverFeature = 'decfiscmenss'" (mouseleave)="hoverFeature = ''">
        <mat-card-header>
          <button mat-button (click)="collapse1 = !collapse1">
            <mat-icon>assignment</mat-icon>
            {{ user.regimefiscalimpot !== 'Forfait D\'assiette' ? 'Declarations fiscales' : 'Declarations fiscales générées manuellement' }}
          </button>
        </mat-card-header>
        <mat-card-content *ngIf="collapse1">
          <ul>
            <li (click)="click7()">
              <mat-icon>list</mat-icon>
              <span class="badge bg-danger">{{ decfiscmensnumber }}</span> Liste des déclarations
            </li>
          </ul>
        </mat-card-content>
        <mat-card-footer *ngIf="hoverFeature === 'decfiscmenss'">
          <p>Gérez vos déclarations fiscales efficacement.</p>
        </mat-card-footer>
      </mat-card>
  
      <mat-card class="col-md-3 feature-card feature-background-2" *ngIf="deccomptabilites.length > 0 && user && usertype === 'Client'" (mouseenter)="hoverFeature = 'deccomptabilites'" (mouseleave)="hoverFeature = ''">
        <mat-card-header>
          <button mat-button (click)="collapse2 = !collapse2">
            <mat-icon>folder</mat-icon>
            {{ user.regimefiscalimpot !== 'Forfait D\'assiette' ? 'Fichiers comptables' : 'Maquettes de génération des déclarations fiscales' }}
          </button>
        </mat-card-header>
        <mat-card-content *ngIf="collapse2">
          <ul>
            <li (click)="click6()">
              <mat-icon>list</mat-icon>
              <span class="badge bg-danger">{{ deccomptabilitenumber }}</span> Liste des fichiers comptables
            </li>
          </ul>
        </mat-card-content>
        <mat-card-footer *ngIf="hoverFeature === 'deccomptabilites'">
          <p>Accédez et gérez vos fichiers comptables.</p>
        </mat-card-footer>
      </mat-card>
  
      <mat-card class="col-md-3 feature-card feature-background-3" *ngIf="getalldsuser()[1] > 0 && usertype === 'Client'" (mouseenter)="hoverFeature = 'demandesDeServices'" (mouseleave)="hoverFeature = ''">
        <mat-card-header>
          <button mat-button (click)="collapse3 = !collapse3">
            <mat-icon>assignment_turned_in</mat-icon>
            <span class="badge bg-danger">{{ getalldsuser()[1] }}</span> Liste des demandes de services
          </button>
        </mat-card-header>
        <mat-card-content *ngIf="collapse3">
          <ul>
            <li (click)="click8()">
              <mat-icon>hourglass_empty</mat-icon>
              <span class="badge bg-danger">{{ getalldsusernonpaye()[1] }}</span> Liste des demandes de services pas encore réglées
            </li>
            <li (click)="click9()">
              <mat-icon>check_circle</mat-icon>
              <span class="badge bg-danger">{{ getalldsuserpaye()[1] }}</span> Liste des demandes de services réglées
            </li>
          </ul>
        </mat-card-content>
        <mat-card-footer *ngIf="hoverFeature === 'demandesDeServices'">
          <p>Suivez vos demandes de services et leur statut.</p>
        </mat-card-footer>
      </mat-card>
  
      <mat-card class="col-md-3 feature-card feature-background-4" *ngIf="invoices.length > 0 && usertype === 'Client'" (mouseenter)="hoverFeature = 'factures'" (mouseleave)="hoverFeature = ''">
        <mat-card-header>
          <button mat-button (click)="collapse4 = !collapse4">
            <mat-icon>receipt</mat-icon>
            <span class="badge bg-danger">{{ invoices.length }}</span> Liste des factures fournisseurs
          </button>
        </mat-card-header>
        <mat-card-content *ngIf="collapse4">
          <ul>
            <li (click)="click10()">
              <mat-icon>check</mat-icon>
              <span class="badge bg-danger">{{ invoices.length }}</span> Liste des factures fournisseurs réglées
            </li>
          </ul>
        </mat-card-content>
        <mat-card-footer *ngIf="hoverFeature === 'factures'">
          <p>Consultez et gérez vos factures fournisseurs.</p>
        </mat-card-footer>
      </mat-card>
  
      <mat-card class="col-md-3 feature-card feature-background-5" *ngIf="condidates.length > 0 && usertype === 'Candidat'" (mouseenter)="hoverFeature = 'candidatures'" (mouseleave)="hoverFeature = ''">
        <mat-card-header>
          <button mat-button (click)="collapse5 = !collapse5">
            <mat-icon>people</mat-icon>
            Liste des Candidatures
          </button>
        </mat-card-header>
        <mat-card-content *ngIf="collapse5">
          <ul>
            <li (click)="click11()">
              <mat-icon>close</mat-icon>
              <span class="badge bg-danger">{{ condval }}</span> Clôture
            </li>
            <li (click)="click12()">
              <mat-icon>hourglass_empty</mat-icon>
              <span class="badge bg-danger">{{ condnonal }}</span> En cours d'exécution
            </li>
          </ul>
        </mat-card-content>
        <mat-card-footer *ngIf="hoverFeature === 'candidatures'">
          <p>Gérez vos candidatures et suivez leur statut.</p>
        </mat-card-footer>
      </mat-card>
  
      <mat-card class="col-md-3 feature-card feature-background-6" *ngIf="contacts.length > 0" (mouseenter)="hoverFeature = 'reclamations'" (mouseleave)="hoverFeature = ''">
        <mat-card-header>
          <button mat-button (click)="collapse6 = !collapse6">
            <mat-icon>report_problem</mat-icon>
            Liste des réclamations
          </button>
        </mat-card-header>
        <mat-card-content *ngIf="collapse6">
          <ul>
            <li (click)="click13()">
              <mat-icon>close</mat-icon>
              <span class="badge bg-danger">{{ contval }}</span> Clôture
            </li>
            <li (click)="click14()">
              <mat-icon>hourglass_empty</mat-icon>
              <span class="badge bg-danger">{{ contnonval }}</span> En cours d'exécution
            </li>
          </ul>
        </mat-card-content>
        <mat-card-footer *ngIf="hoverFeature === 'reclamations'">
          <p>Gérez vos réclamations et suivez leur statut.</p>
        </mat-card-footer>
      </mat-card>
  
      <mat-card class="col-md-3 feature-card feature-background-7" (mouseenter)="hoverFeature = 'facturesclient'" (mouseleave)="hoverFeature = ''">
        <mat-card-header>
          <button mat-button (click)="collapse6 = !collapse6">
            <mat-icon>receipt</mat-icon>
            Liste des Factures/devis clients
          </button>
        </mat-card-header>
        <mat-card-content *ngIf="collapse6">
          <ul>
            <li (click)="click16()">
              <mat-icon>receipt</mat-icon>
              <span class="badge bg-danger">{{ facturesclientval }}</span> Factures clients
            </li>
            <li (click)="click17()">
              <mat-icon>receipt</mat-icon>
              <span class="badge bg-danger">{{ facturesproclientval }}</span> Factures proforma
            </li>
            <li (click)="click18()">
              <mat-icon>receipt</mat-icon>
              <span class="badge bg-danger">{{ devisclientval }}</span> Devis
            </li>
          </ul>
        </mat-card-content>
        <mat-card-footer *ngIf="hoverFeature === 'facturesclient'">
          <p>Gérez vos facures/devis de vos clients.</p>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
  <mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab label="liste des ds pas encore rêglées"*ngIf="alldssusernonpayewindow&&getalldsusernonpaye()[0]">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des ds pas encore rêglées</div>
        <span (click)="click34()"><i class="bi bi-x-circle-fill"></i></span>
        <p *ngIf="getalldsusernonpaye()[1]>0" style="color: red;" >(veuillez cochez les DS que vous voulez règler)</p>
    </ng-template>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="_id"></i></th>
          <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="nature"></i></th>
          <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="mois"></i></th>
          <th scope="col">Année <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="annee"></i></th>
          <th scope="col">Description <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="userId"></i></th>
          <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="statut"></i></th>
          <th scope="col">Total honoraire HT</th>
          <th scope="col">Date de création <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsusernonpaye()[0]" data-order="desc" data-name="created"></i></th>
          <th scope="col">Payement</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dsnonpaye of getalldsusernonpaye()[0]; let i = index">
          <td>{{ dsnonpaye._id }}</td>
          <td>
            <span *ngIf="dsnonpaye.nature">{{ dsnonpaye.nature }}</span>
            <span *ngIf="!dsnonpaye.nature">Autre demande de service</span>
          </td>
          <td>{{ dsnonpaye.mois }}</td>
          <td>{{ dsnonpaye.annee }}</td>
          <td>
            <span *ngIf="!dsnonpaye.nature">{{ dsnonpaye.libelle }} &nbsp; / {{ dsnonpaye.description }}</span>
            <span *ngIf="dsnonpaye.nature">N/A</span>
          </td>
          <td>
            <span *ngIf="dsnonpaye.statutadmin">
              <span *ngIf="dsnonpaye.statutadmin.length > 0">
                <ng-container *ngFor="let statut of dsnonpaye.statutadmin; let j = index">
                  <span *ngIf="j == dsnonpaye.statutadmin.length - 1">{{ statut.statut }}</span>
                </ng-container>
              </span>
            </span>
          </td>
          <td>{{ dsnonpaye.totalhonoraire | number:'1.3-3' | removeComma }}</td>
          <td>{{ dsnonpaye.created | customDate }}</td>
          <td>
            <input type="checkbox" id="{{ 'dsnonpayecheckbox' + i }}" (change)="shownotehonoraire()" />
          </td>
          <td>
            <button class="btn btn-success btn-sm" (click)="getNavigationautreds('view-autreds', dsnonpaye._id)" *ngIf="!dsnonpaye.nature">Consulter</button>
            <button class="btn btn-success btn-sm" (click)="getNavigation('view-deccomptabilite', dsnonpaye._id)" *ngIf="dsnonpaye.nature === 'comptabilite'">Consulter</button>
            <button class="btn btn-success btn-sm" (click)="getNavigationdecfiscmens('view-decfiscmens', dsnonpaye._id)" *ngIf="dsnonpaye.nature === 'Déclaration Mensuelle'">Consulter</button>
          </td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>TotalHT:</td>
          <td>{{ shownotehonoraire()[0] + shownotehonoraire()[6] | number:'1.3-3' | removeComma }}</td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>TotalTVA(19%):</td>
          <td>{{ shownotehonoraire()[1] | number:'1.3-3' | removeComma }}</td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>Timbrefiscal:</td>
          <td>{{ shownotehonoraire()[2] | number:'1.3-3' | removeComma }}</td>
        </tr>
        <tr>
          <td colspan="8"></td>
          <td>TotalTTC:</td>
          <td>{{ shownotehonoraire()[7] | number:'1.3-3' | removeComma }}</td>
          <td>
            <button class="btn btn-info btn-sm" (click)="previewnotehonoraire()" *ngIf="shownotehonoraire()[3] != 1">Aperçu Note d'honoraires</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div> 
    </mat-tab>
    <mat-tab label="liste des déclarations fiscales" *ngIf="decfiscmenswindow&&decfiscmenss">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des déclarations fiscales</div>
        <span (click)="click32()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="_id"></i></th>
            <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="nature"></i></th>
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="mois"></i></th>
            <th scope="col">Année <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="annee"></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="userId"></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="userId"></i></th>
            <th scope="col">Origine</th>
            <th scope="col">Source</th>
            <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="statut"></i></th>
            <th scope="col">Date de création <i class="fa fa-sort" aria-hidden="true" [appSort]="decfiscmenss" data-order="desc" data-name="created"></i></th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let decfiscmens of decfiscmenss; let i = index">
            <td>{{ decfiscmens._id }}</td>
            <td>{{ decfiscmens.nature }}</td>
            <td>{{ decfiscmens.mois }}</td>
            <td>{{ decfiscmens.annee }}</td>
            <td>{{ prenom }}</td>
            <td>{{ nom }}</td>
            <td>{{ decfiscmens.origine }}</td>
            <td><span *ngIf="decfiscmens.source">{{ decfiscmens.source }}</span></td>
            <td>
              <span *ngIf="decfiscmens.statutadmin.length > 0">
                <ng-container *ngFor="let statut of decfiscmens.statutadmin; let j = index">
                  <span *ngIf="j == decfiscmens.statutadmin.length - 1">{{ statut.statut }}</span>
                </ng-container>
              </span>
            </td>
            <td>{{ decfiscmens.created | customDate }}</td>
            <td>
              <span *ngIf="decfiscmens.statutadmin.length > 0">
                <ng-container *ngIf="decfiscmens.statutadmin[decfiscmens.statutadmin.length - 1].statut == 'clôturé' && decfiscmens.affecte">
                  <button type="button" class="btn btn-success btn-sm" (click)="getNavigationdecfiscmens('view-decfiscmens', decfiscmens._id)">Consulter</button>
                </ng-container>
              </span>
              <span *ngIf="decfiscmens.origine != 'généré automatiquement' && !decfiscmens.source">
                <button type="button" class="btn btn-primary btn-sm" [ngStyle]="{ 'color': (verifyexercicecloture(decfiscmens.annee,decfiscmens.mois)) ? 'black' : 'white', 'background-color': (verifyexercicecloture(decfiscmens.annee,decfiscmens.mois)) ? 'gray' : 'green' }" [disabled]="verifyexercicecloture(decfiscmens.annee,decfiscmens.mois)" (click)="getNavigationdecfiscmens('modify-decfiscmens', decfiscmens._id)">Modifier/Compléter</button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    </mat-tab>
    <mat-tab label="liste des fichiers comptables" *ngIf="deccomptabilitewindow&&deccomptabilites">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des fichiers comptables</div>
        <span (click)="click33()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="_id"></i></th>
            <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="nature"></i></th>
            <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="mois"></i></th>
            <th scope="col">Année <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="annee"></i></th>
            <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="userId"></i></th>
            <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="userId"></i></th>
            <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="statut"></i></th>
            <th scope="col">Source <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="source"></i></th>
            <th scope="col">Date de création <i class="fa fa-sort" aria-hidden="true" [appSort]="deccomptabilites" data-order="desc" data-name="created"></i></th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let deccomptabilite of deccomptabilites; let i = index">
            <td>{{ deccomptabilite._id }}</td>
            <td>{{ deccomptabilite.nature }}</td>
            <td>{{ deccomptabilite.mois }}</td>
            <td>{{ deccomptabilite.annee }}</td>
            <td>{{ prenom }}</td>
            <td>{{ nom }}</td>
            <td>
              <span *ngIf="deccomptabilite.statutadmin.length > 0">
                <ng-container *ngFor="let statut of deccomptabilite.statutadmin; let j = index">
                  <span *ngIf="j == deccomptabilite.statutadmin.length - 1">{{ statut.statut }}</span>
                </ng-container>
              </span>
            </td>
            <td><span *ngIf="deccomptabilite.source">{{ deccomptabilite.source }}</span></td>
            <td>{{ deccomptabilite.created | customDate }}</td>
            <td>
              <span *ngIf="deccomptabilite.statutadmin.length > 0">
                <ng-container *ngIf="deccomptabilite.statutadmin[deccomptabilite.statutadmin.length - 1].statut == 'clôturé' && deccomptabilite.affecte">
                  <button type="button" class="btn btn-success btn-sm" (click)="getNavigation('view-deccomptabilite', deccomptabilite._id)">Consulter</button>
                </ng-container>
              </span>
              <span *ngIf="deccomptabilite.origine != 'généré automatiquement' && !deccomptabilite.source">
                <button type="button" class="btn btn-primary btn-sm" [ngStyle]="{ 'color': (verifyexercicecloture(deccomptabilite.annee,deccomptabilite.mois)) ? 'black' : 'white', 'background-color': (verifyexercicecloture(deccomptabilite.annee,deccomptabilite.mois)) ? 'gray' : 'green' }" [disabled]="verifyexercicecloture(deccomptabilite.annee,deccomptabilite.mois)" (click)="getNavigation('modify-deccomptabilite', deccomptabilite._id)">Modifier/Compléter</button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    </mat-tab>
    <mat-tab label="liste des Candidatures clôturés" *ngIf="candidaturevalide&&condidates">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des Candidatures clôturés</div>
        <span (click)="click25()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Email <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="email"></i></th>
            <th>Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="firstname"></i></th>
            <th>Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="lastname"></i></th>
            <th>Adresse <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="adresse"></i></th>
            <th>Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="mobile"></i></th>
            <th>Statut Admin</th>
            <th>Statut Collaborateur</th>
            <th>CV</th>
            <th>Date de création <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="created"></i></th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let condidate of getcondidatevalide()">
            <td>{{ condidate.email }}</td>
            <td>{{ condidate.firstname }}</td>
            <td>{{ condidate.lastname }}</td>
            <td>{{ condidate.adresse }}</td>
            <td>{{ condidate.mobile }}</td>
            <td>
              <span *ngIf="condidate.statutadmin">
                <ng-container *ngFor="let statut of condidate.statutadmin">
                  {{ statut.statut }}
                </ng-container>
              </span>
            </td>
            <td>
              <span *ngIf="condidate.statutcollab">
                <ng-container *ngFor="let statut of condidate.statutcollab">
                  {{ statut.statutcoll }}
                </ng-container>
              </span>
            </td>
            <td><a href="{{ condidate.ficheUrl }}" target="_blank">Aperçu CV</a></td>
            <td>{{ condidate.created }}</td>
            <td>
              <button type="button" class="btn btn-success" (click)="getNavigationcondidates('view-condidate', condidate._id)">Consulter</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    </mat-tab>
    <mat-tab label="liste des Candidatures" *ngIf="candidaturenonvalide&&condidates">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des Candidatures en cours de traitement</div>
        <span (click)="click26()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Email <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="email"></i></th>
            <th>Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="firstname"></i></th>
            <th>Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="lastname"></i></th>
            <th>Adresse <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="adresse"></i></th>
            <th>Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="mobile"></i></th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th>CV</th>
            <th>Date de création <i class="fa fa-sort" aria-hidden="true" [appSort]="condidates" data-order="desc" data-name="created"></i></th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let condidate of getcondidatenonvalide()">
            <td>{{ condidate.email }}</td>
            <td>{{ condidate.firstname }}</td>
            <td>{{ condidate.lastname }}</td>
            <td>{{ condidate.adresse }}</td>
            <td>{{ condidate.mobile }}</td>
            <td>
              <span *ngIf="condidate.statutadmin">
                <ng-container *ngFor="let statut of condidate.statutadmin">
                  {{ statut.statut }}
                </ng-container>
              </span>
            </td>
            <td>
              <span *ngIf="condidate.statutcollab">
                <ng-container *ngFor="let statut of condidate.statutcollab">
                  {{ statut.statutcoll }}
                </ng-container>
              </span>
            </td>                      
            <td><a href="{{ condidate.ficheUrl }}" target="_blank">Aperçu CV</a></td>
            <td>{{ condidate.created }}</td>
            <td>
              <button type="button" class="btn btn-success" (click)="getNavigationcondidates('view-condidate', condidate._id)">Consulter</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    </mat-tab>
    <mat-tab label="liste des réclamations" *ngIf="reclamationtraite&&contacts">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des réclamations traités</div>
        <span (click)="click27()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Date Creation <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="created"></i></th>
            <th>Email <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="email"></i></th>
            <th>Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="firstname"></i></th>
            <th>Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="lastname"></i></th>
            <th>Fichier Joint</th>
            <th>Mobile <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="mobile"></i></th>
            <th>Description <i class="fa fa-sort" aria-hidden="true" [appSort]="contacts" data-order="desc" data-name="description"></i></th>
            <th scope="col">Statut Admin</th>
            <th scope="col">Statut Collaborateur</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contact of getcontactvalide()">
            <td>{{ contact.created }}</td>
            <td>{{ contact.email }}</td>
            <td>{{ contact.firstname }}</td>
            <td>{{ contact.lastname }}</td>
            <td><a href="{{ contact.ficheUrl }}" target="_blank">Aperçu Fichier</a></td>
            <td>{{ contact.mobile }}</td>
            <td>{{ contact.description }}</td>
            <td>
              <span *ngIf="contact.statutadmin">
                <ng-container *ngFor="let statut of contact.statutadmin">
                  {{ statut.statut }}
                </ng-container>
              </span>
            </td>
            <td>
              <span *ngIf="contact.statutcollab">
                <ng-container *ngFor="let statut of contact.statutcollab">
                  {{ statut.statutcoll }}
                </ng-container>
              </span>
            </td>
            <td>
              <button type="button" class="btn btn-success" (click)="getNavigationcontacts('view-contactreq', contact._id)">Consulter</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    </mat-tab>
    <mat-tab label="liste des réclamations en cours" *ngIf="reclamationnontraite&&contacts">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des réclamations en cours de traitement</div>
        <span (click)="click28()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Prenom</th>
            <th>Nom</th>
            <th>Adresse</th>
            <th>Mobile</th>
            <th>Decision</th>
            <th>CV</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contact of getcontactnonvalide()">
            <td>{{ contact.email }}</td>
            <td>{{ contact.firstname }}</td>
            <td>{{ contact.lastname }}</td>
            <td>{{ contact.adresse }}</td>
            <td>{{ contact.mobile }}</td>
            <td>{{ contact.decision }}</td>
            <td><a href="{{ contact.ficheUrl }}" target="_blank">Aperçu CV</a></td>
            <td>
              <button type="button" class="btn btn-success" (click)="getNavigationcontacts('view-contact', contact._id)">Consulter</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    </mat-tab>
    <mat-tab label="liste des ds rêglées"*ngIf="alldssuserpayewindow&&getalldsuserpaye()[0]">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des ds rêglées</div>
        <span (click)="click35()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="_id"></i></th>
          <th scope="col">Nature <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="nature"></i></th>
          <th scope="col">Mois <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="mois"></i></th>
          <th scope="col">Année <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="annee"></i></th>
          <th scope="col">Description <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="userId"></i></th>
          <th scope="col">Statut <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="statut"></i></th>
          <th scope="col">Total honoraire HT</th>
          <th scope="col">Date de création <i class="fa fa-sort" aria-hidden="true" [appSort]="getalldsuserpaye()[0]" data-order="desc" data-name="created"></i></th>  
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dspaye of getalldsuserpaye()[0]; let i = index">
          <td>{{ dspaye._id }}</td>
          <td>
            <span *ngIf="dspaye.nature">{{ dspaye.nature }}</span>
            <span *ngIf="!dspaye.nature">Autre demande de service</span>
          </td>
          <td><span *ngIf="dspaye.mois">{{ dspaye.mois }}</span></td>
          <td><span *ngIf="dspaye.annee">{{ dspaye.annee }}</span></td>
          <td>
            <span *ngIf="!dspaye.nature">{{ dspaye.libelle }} &nbsp; / {{ dspaye.description }}</span>
            <span *ngIf="dspaye.nature">N/A</span>
          </td>
          <td>
            <span *ngIf="dspaye.statutadmin && dspaye.statutadmin.length > 0">
              <ng-container *ngFor="let statut of dspaye.statutadmin; let j = index">
                <span *ngIf="j === dspaye.statutadmin.length - 1">{{ statut.statut }}</span>
              </ng-container>
            </span>
          </td>
          <td>{{ dspaye.totalhonoraire | number:'1.3-3' | removeComma }}</td>
          <td>{{ dspaye.created | customDate }}</td>
          <td>
            <button type="button" class="btn btn-success" (click)="getNavigationautreds('view-autreds', dspaye._id)" *ngIf="!dspaye.nature">Consulter</button>
            <button type="button" class="btn btn-success" (click)="getNavigation('view-deccomptabilite', dspaye._id)" *ngIf="dspaye.nature === 'comptabilite'">Consulter</button>
            <button type="button" class="btn btn-success" (click)="getNavigationdecfiscmens('view-decfiscmens', dspaye._id)" *ngIf="dspaye.nature === 'Déclaration Mensuelle'">Consulter</button>
            <img src="../../assets/free.png" id="logo" alt="image-DGI" style="width: 40%; height: 40%; border: none" *ngIf="dspaye.marquepaye">
          </td>
        </tr>
      </tbody>
    </table>
    
    </mat-tab>
    <mat-tab label="liste des factures règlées"*ngIf="invoicewindow&&invoices">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des factures règlées</div>
        <span (click)="click15()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Code <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="_id"></i></th>
          <th scope="col">Prenom <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="userId"></i></th>
          <th scope="col">Nom <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="userId"></i></th>
          <th scope="col">Date de création <i class="fa fa-sort" aria-hidden="true" [appSort]="allinvoice" data-order="desc" data-name="created"></i></th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let inv of invoices; let i = index">
          <td>F{{ inv.prefixe }}/{{ inv.ref }}</td>
          <td>{{ prenom }}</td>
          <td>{{ nom }}</td>
          <td>{{ inv.created | customDate }}</td>
          <td>
            <button type="button" class="btn btn-success" (click)="getNavigationinvoice('view-invoice', inv._id)">Consulter</button>
          </td>
        </tr>
      </tbody>
    </table>
    
    </mat-tab>
    <mat-tab label="liste des factures clients"*ngIf="facturesclientwindow&&facturesclient">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des factures clients</div>
        <span (click)="click19()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <app-invoice-list [pagedInvoices]="facturesclient" [type]="'facture'" [user]="userId"></app-invoice-list>
    </mat-tab>
    <mat-tab label="liste des factures proforma clients"*ngIf="facturesproclientwindow&&facturesproclient">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des factures proforma clients</div>
        <span (click)="click20()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <app-invoice-list [pagedInvoices]="facturesproclient" [type]="'facturepro'" [user]="userId"> </app-invoice-list>
    </mat-tab>
    <mat-tab label="liste des devis clients"*ngIf="devisclientwindow&&devisclient">
      <ng-template mat-tab-label style="padding: 3px;">
        <div class="col" style="margin-left: 20px;">liste des devis clients</div>
        <span (click)="click21()"><i class="bi bi-x-circle-fill"></i></span>
    </ng-template>
    <app-invoice-list [pagedInvoices]="devisclient" [type]="'devis'" [user]="userId"></app-invoice-list>
    </mat-tab>
  </mat-tab-group> 
     <!-- modal for Note d'honoraires-->
    <div class="modal-signup" [ngStyle]="{'display':displaynote}">
        <div class="modal-content">
          <div class="right-section-signup">
            <div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;" >
              <div class="card"id="honoraireuser">
                <div class="card-header bg-black"></div>
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-12 text-center">
                        <img src="../../assets/img/Frame 2177.png" id="logo" alt="image-DGI">
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-xl-12">
                        <ul class="list-unstyled text-end">
                          <li>Tunis le {{today | customDate}}</li>
                          <li>
                            A l'attention de: &nbsp;
                            <span *ngIf="user.nature === 'Personne Physique'">
                              <span *ngIf="user.civilite === 'Monsieur'"> Mr </span>
                              <span *ngIf="user.civilite === 'Madame'"> Mme </span>
                              {{user.firstname}} &nbsp;{{user.lastname}}
                            </span>
                            <span *ngIf="user.nature !== 'Personne Physique'">{{user.raisonsociale}}</span>
                            &nbsp;<span>({{user.clientcode | uppercase}})</span>
                          </li>
                          <li>{{user.matriculefiscale | uppercase}}</li>
                          <li>{{user.adresseactivite | uppercase}}</li>
                        </ul>
                      </div>
                    </div>
                    <div class="row text-center mt-4">
                      <h3 class="text-uppercase" style="font-size: 40px;">Note d'honoraires N°: {{option0value}} (Proforma)</h3>
                    </div>
                    <div class="row mx-3 mt-4">
                      <table class="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th scope="col">N° DS</th>
                            <th scope="col">Désignation</th>
                            <th scope="col">Description</th>
                            <th scope="col">Durée</th>
                            <th scope="col">Taux/minute</th>
                            <th scope="col">Montant HT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let dsnonpaye of shownotehonoraire()[4]; let i = index">
                            <td>{{dsnonpaye._id | uppercase}}</td>
                            <td>
                              <span *ngIf="dsnonpaye.nature">{{dsnonpaye.nature}}</span>
                              <span *ngIf="!dsnonpaye.nature">{{dsnonpaye.libelle}}</span>
                            </td>
                            <td>
                              <span *ngIf="dsnonpaye.nature">{{dsnonpaye.mois}}/{{dsnonpaye.annee}}</span>
                              <span *ngIf="!dsnonpaye.nature">{{dsnonpaye.description}}</span>
                            </td>
                            <td>
                              <span *ngIf="dsnonpaye.nature">{{dsnonpaye.totaltime}}</span>
                              <span *ngIf="!dsnonpaye.nature && dsnonpaye.duree != 0">{{dsnonpaye.duree}}</span>
                              <span *ngIf="!dsnonpaye.nature && dsnonpaye.duree == 0">N/A</span>
                            </td>
                            <td>
                              <span *ngIf="dsnonpaye.nature && dsnonpaye.totaltime == 0">N/A</span>
                              <span *ngIf="!dsnonpaye.nature && dsnonpaye.duree == 0">N/A</span>
                              <span *ngIf="dsnonpaye.nature && dsnonpaye.totaltime != 0 || !dsnonpaye.nature && dsnonpaye.duree != 0">
                                {{dsnonpaye.tauxapplique | number:'1.3-3' | removeComma}}
                              </span>
                            </td>
                            <td>{{dsnonpaye.totalhonoraire | number:'1.3-3' | removeComma}}</td>
                          </tr>
                          <tr>
                            <td colspan="5"> Total HT:</td>
                            <td>{{shownotehonoraire()[0] | number:'1.3-3' | removeComma}}</td>
                          </tr>
                          <tr>
                            <td colspan="5">TVA (19%):</td>
                            <td>{{shownotehonoraire()[1] | number:'1.3-3' | removeComma}}</td>
                          </tr>
                          <tr>
                            <td colspan="5">Sous total1:</td>
                            <td>{{shownotehonoraire()[3] | number:'1.3-3' | removeComma}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                
                    <div class="row mx-3 mt-4">
                      <table class="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th scope="col">N° DS</th>
                            <th scope="col">Désignation</th>
                            <th scope="col">Description</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Montant HT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let dsnonpaye of shownotehonoraire()[5]; let i = index">
                            <td>{{dsnonpaye._id | uppercase}}</td>
                            <td>
                              <span *ngIf="dsnonpaye.nature">{{dsnonpaye.nature}}</span>
                              <span *ngIf="!dsnonpaye.nature">{{dsnonpaye.libelle}}</span>
                            </td>
                            <td>
                              <span *ngIf="dsnonpaye.nature">{{dsnonpaye.mois}}/{{dsnonpaye.annee}}</span>
                              <span *ngIf="!dsnonpaye.nature">{{dsnonpaye.description}}</span>
                            </td>
                            <td></td>
                            <td></td>
                            <td>{{dsnonpaye.totalhonoraire | number:'1.3-3' | removeComma}}</td>
                          </tr>
                          <tr>
                            <td colspan="5">Sous Total2:</td>
                            <td>{{shownotehonoraire()[6] | number:'1.3-3' | removeComma}}</td>
                          </tr>
                          <tr>
                            <td colspan="5">Timbre fiscal:</td>
                            <td>{{shownotehonoraire()[2] | number:'1.3-3' | removeComma}}</td>
                          </tr>
                          <tr>
                            <td colspan="5">Total général:</td>
                            <td>{{shownotehonoraire()[7] | number:'1.3-3' | removeComma | currency:'TND'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                
                    <div class="top-info mt-4">
                      <small class="me-3" style="color: black;">
                        <a href="#"><i class="fas fa-solid fa-phone me-2" style="color:#D11317"></i></a>98 35 05 75
                      </small>
                      <small class="me-3" style="color: black;">
                        <a href="#"><i class="fas fa-envelope me-2" style="color:#D11317"></i></a>macompta@macompta.com.tn
                      </small>
                      <small class="me-3" style="color: black;">
                        <a href="#"><i class="bi bi-house-door-fill" style="color:#D11317"></i></a>Ariana center Bureau A318 Ariana 2080
                      </small>
                    </div>
                    <div class="row mt-2 mb-5">
                      <p class="fw-bold mt-3">Signature:</p>
                    </div>
                  </div>
                </div>
                
                <div class="card-footer bg-black"></div>
              </div>
            </div>
           
            <div class="btn-signup-section">
                <div style="width: 30%; text-align: end;">
                    <button class="reinstaller-btn-modal" (click)="closecopPopup()">Annuler</button>
                </div>
                <div style="width: 35%;">
                    <button class="signup-btn-modal" (click)="printinvoice()">Générer facture</button>
                </div>
                <div style="width: 35%">
                  <button class="reinstaller-btn-modal"style="background-color: lightgreen;" (click)="payinvoice()"><i class="fa fa-spinner" *ngIf="submitting"></i>Règler facture</button>
              </div>
            </div>
          </div>
      
          <!-- Close Button (Top-left corner) -->
          <div class="close-button" (click)="closecopPopup()" style="background-color: #F8F8F9;">
            <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
          </div>
        </div>
    </div>

</body>
