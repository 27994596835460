
  <body>
<div id="loader" *ngIf="loading"></div>
  <div class="col-lg-12" *ngIf="!loading">
    <div class="container-fluid">
		<h2 style="text-align:center">Détails De La notification!</h2>
		<hr>          
      <div class="users-grid" style="overflow-x:auto;overflow-y: auto;">
        <table class="table table-striped">
            <thead>
                <tr>     
                    <th>Message</th>
                    <th>Type</th>
                    <th>Date de création</th>
                </tr>
                </thead>
          <tbody>
          <tr>
            <td>{{notifrec.message}}</td>
            <td><span *ngIf="!notifrec.type">SMS</span><span *ngIf="notifrec.type">Email</span></td>
            <td>{{notifrec.created | customDate}}</td>
          </tr>
          <tr>  
          </tr>
          </tbody>
        </table>
      </div> 
    </div>
  </div>
</body>