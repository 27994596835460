import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DecEmployeurRoutingModule } from './dec-employeur-routing.module';

import { Part2Component } from './part2/part2.component';
import { Part2anx1Component } from './part2anx1/part2anx1.component';
import { Annexe1Component } from './annexe1/annexe1.component';
import { Anx1finComponent } from './anx1fin/anx1fin.component';
import { Anx2p1Component } from './anx2p1/anx2p1.component';
import { Anx2p2Component } from './anx2p2/anx2p2.component';
import { Anx2p3Component } from './anx2p3/anx2p3.component';
import { Anx3p1Component } from './anx3p1/anx3p1.component';
import { Anx3p2Component } from './anx3p2/anx3p2.component';
import { Anx3p3Component } from './anx3p3/anx3p3.component';
import { Anx4p1Component } from './anx4p1/anx4p1.component';
import { Anx4p2Component } from './anx4p2/anx4p2.component';
import { Anx4p3Component } from './anx4p3/anx4p3.component';

import { Anx5p1Component } from './anx5p1/anx5p1.component';
import { Anx5p2Component } from './anx5p2/anx5p2.component';
import { Anx5p3Component } from './anx5p3/anx5p3.component';
import { Anx6Component } from './anx6/anx6.component';
import { Anx6p2Component } from './anx6p2/anx6p2.component';
import { Anx6p3Component } from './anx6p3/anx6p3.component';
import { Anx7p1Component } from './anx7p1/anx7p1.component';

import { Anx7p2Component } from './anx7p2/anx7p2.component';
import { Anx7p3Component } from './anx7p3/anx7p3.component';
import { FichierrecapComponent } from './fichierrecap/fichierrecap.component';
import { DecEmployeurGeneralComponent } from './dec-employeur-general.component';


@NgModule({
  declarations: [
    Part2Component,
    Part2anx1Component,
    Annexe1Component,
    Anx1finComponent,
    Anx2p1Component,
    Anx2p2Component,
    Anx2p3Component,
    Anx3p1Component,
    Anx3p2Component,
    Anx3p3Component,
    Anx4p1Component,
    Anx4p2Component,
    Anx4p3Component,
    Anx5p1Component,
    Anx5p2Component,
    Anx5p3Component,
    Anx6Component,
    Anx6p2Component,
    Anx6p3Component,
    Anx7p1Component,
    Anx7p2Component,
    Anx7p3Component,
    FichierrecapComponent,
    DecEmployeurGeneralComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DecEmployeurRoutingModule
  ]
})
export class DecEmployeurModule { }
