import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FactureProformaComponent } from './facture-proforma/facture-proforma.component';
import { FormsModule } from '@angular/forms';
import { InvoiceGeneratorComponent } from './invoice-generator/invoice-generator.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { FacturationRoutingModule } from './facturation.routing';
import { SharedmoduleModule } from '../sharedmodule/sharedmodule.module'; // Adjust the path as necessary
import { UserService } from '../services/user.service';
//material
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    FactureProformaComponent,
    InvoiceGeneratorComponent
  ],
  imports: [
    CommonModule,FormsModule,     MatTableModule,    ReactiveFormsModule,PdfViewerModule,

    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    SharedmoduleModule,
    MatSelectModule,   FacturationRoutingModule ,// Add the routing module here
    HttpClientModule,   TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [UserService],


})
  
export class FacturationModule { }

