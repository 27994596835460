<div class="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
    <div class="container pt-5 pb-4">
        <div class="row g-5">
            <div class="col-md-4">
                <a href="index.html">
                    <img class="fit-picture" src="../../../assets/img/footerlogo.png" alt="Grapefruit slice atop a pile of other slices" />
                </a>
                <img src="../../assets/locationIcon.png" alt="location icon" style="width:7.5%; display: inline;"><p style="display: inline; margin-left: 2%; color: white; font-size: 1vw;"> <a href="https://www.google.com/maps/search/Ariana+center+Bureau+A318+Ariana+2080/@36.8578794,10.1871067,17z/data=!3m1!4b1?entry=ttu" style="display: inline;" target="_blank">Ariana center Bureau A318 Ariana 2080 </a></p>
                <div class="d-flex hightech-link">
                    <a href="https://www.facebook.com/profile.php?id=100089278023685" target="_blank"class="btn-light nav-fill btn btn-square rounded-circle me-2"><i class="fab fa-facebook-f "></i></a>
                    <a href="" class="btn-light nav-fill btn btn-square rounded-circle me-2"><i class="fab fa-twitter "></i></a>
                    <a href="" class="btn-light nav-fill btn btn-square rounded-circle me-2"><i class="fab fa-instagram "></i></a>
                    <a href="" class="btn-light nav-fill btn btn-square rounded-circle me-0"><i class="fab fa-linkedin-in "></i></a>
                </div>
            </div>
            <div class="col-md-4" style="padding-right: 180px;">
                <a href="#" class="h3" style="color: #D11317;">Liens utiles</a>
                <div class="mt-4 d-flex flex-column short-link">
                    <a routerLink="home" routerLinkActive="active"  class="mb-2 text-white"><i class="fas fa-angle-right  me-2" style="color: #D11317;"></i>Acceuil</a>
                    <a routerLink="why"  routerLinkActive="active" class="mb-2 text-white"><i class="fas fa-angle-right  me-2" style="color: #D11317;"></i>Pourquoi choisir Macompta</a>
                    <a routerLink="career"  routerLinkActive="active" class="mb-2 text-white"><i class="fas fa-angle-right  me-2" style="color: #D11317;"></i>Carrière</a>
                    <a routerLink="/home/contact"  target=”_blank” routerLinkActive="active" class="mb-2 text-white"><i class="fas fa-angle-right  me-2"style="color: #D11317;"></i>Contact</a>
                </div>
            </div>
            <div class="col-md-4">
                <a href="#" class="h3" style="color: #D11317;">Contact Us</a>
                <div class="text-white mt-4 d-flex flex-column contact-link">
                    <a href="#" class="py-3 text-light  "><i class="fas fa-phone-alt  me-2" style="color: #D11317;"></i> 98 350 575</a>
                    <a href="#" class="py-3 text-light "><i class="fas fa-envelope  me-2" style="color: #D11317;"></i> macompta@macompta.com.tn</a>
                </div>
            </div>
        </div>
        <hr class="text-light mt-5 mb-4">
        <div class="row">
            <div class="col-md-6 text-center text-md-start">
                <span class="text-light"><a href="#" style="color: #D11317;"><i class="fas fa-copyright  me-2" style="color: #D11317;"></i>MaCompta</a>Tous droits réservés.</span>
            </div>
            <div class="col-md-6 text-center text-md-end" *ngIf="version">
                <span class="text-light">Version numéro<a href="https://macompta.com.tn"  style="color: #D11317;">{{version.first}}.{{version.second}}.{{version.third}}</a> </span>
            </div>
        </div>
    </div>
  </div>
  