import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthService } from '../services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { User } from '../models/user.model';
import Swal from 'sweetalert2';
import { UserService } from '../services/user.service';
import { LoginModalService } from '../services/login-modal.service';
import { SignupModalService } from '../services/signup-modal.service';
import { Otherdsmodeluser } from '../models/otherds-user.model';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { OtherdsServiceUser } from '../services/otherds-user.service';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy{

  
    role: string;
    isLoggedIn = false;
    showAdminBoard = false;
    showModeratorBoard = false;
    username: string;
    lastname: string;
  usertype: any;
  currentuser: any;
  nature: string;
  raisonsociale: string;
  user: User;
  allotherdsSub: Subscription;
  otherdss: Otherdsmodeluser[]=[];
  alldeccomptabSub: Subscription;
  dsdeccomptabilites: Deccomptabilite[]=[];
  alldecfiscmensSub: Subscription;
  dsdecfiscmenss: Decfiscmens[]=[];
  payedotherdss: Otherdsmodeluser[]=[];
  nonpayedotherdss: Otherdsmodeluser[]=[];
  payedsdeccomptabilites: Deccomptabilite[]=[];
  nonpayedsdeccomptabilites: Deccomptabilite[]=[];
  payedsdecfiscmenss: Decfiscmens[]=[];
  nonpayedsdecfiscmenss: Decfiscmens[]=[];
  alldssusernonpayewindow=false;
  alldssuserpayewindow=false;
  alldecfiscmenss: Decfiscmens[]=[];
  alldeccomptabilites: Deccomptabilite[]=[];
  isMenuOpened: boolean = false;
  itemsespaceVisible: boolean=false;
  homeroute: boolean=false;

  constructor(
    private router: Router,
              private Auth: TokenStorageService,
              private userservice: UserService,private dec: DecfiscmensService,private otheruser: OtherdsServiceUser,private deccompt: DeccomptabiliteService,
              private loginModalService: LoginModalService , private signupModalService : SignupModalService
              ) { }
  ngOnDestroy(): void {
    this.homeroute=false
  }
              toggleMenu(): void {
                this.isMenuOpened = !this.isMenuOpened;
                console.log('clicked inside');
                console.log(this.isMenuOpened);
              }
            
              clickedOutside(): void {
                this.isMenuOpened = false; // Close the menu when clicked outside
                console.log('clicked outside');
                console.log(this.isMenuOpened);
                this.itemsespaceVisible = false
                this.itemsserviceVisible = false
                this.itemsSectionVisible = false
              }
            
              itemsSectionVisible: boolean = false;
              itemsserviceVisible: boolean = false;

              displaySectionItems() : void {
                this.itemsSectionVisible = !this.itemsSectionVisible;
                this.itemsespaceVisible = false
                this.itemsserviceVisible = false

              }
              displayespaceItems() : void {
                this.itemsespaceVisible = !this.itemsespaceVisible;
                this.itemsserviceVisible = false
                this.itemsSectionVisible = false

              }
              displayserviceItems() : void {
                this.itemsserviceVisible = !this.itemsserviceVisible;
                this.itemsespaceVisible = false
                this.itemsSectionVisible = false

              }
              isProfileOpened:boolean=false;
              openProfile():void{
                this.isProfileOpened=!this.isProfileOpened;
              }
            
              clickedOutsideProfile():void{
                this.isProfileOpened=false;
              }
              clickedOutsideNotif() : void{
                this.isNotifOpened=false;
              }
            
            
              isNotifOpened : boolean = false;
            
              displayNotif(){
                this.isNotifOpened = !this.isNotifOpened;
              }
              sections = [
                { id: 'actualite', name: 'Actualité' },
                { id: 'rappelez-vous', name: 'Rappelez Vous!' },
                { id: 'nos-valeurs', name: 'Nos valeurs' },
                { id: 'qui-sommes-nous', name: 'Qui sommes nous?' },
                { id: 'notre-projet', name: 'Notre projet' },
                { id: 'nos-services', name: 'Nos services' }
              ];
              activeSection: string = '';
  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.homeroute = event.urlAfterRedirects === '/home';
    });
    this.isLoggedIn = !!this.Auth.getToken();
    if (this.isLoggedIn) {
      this.currentuser = this.Auth.getUser();
      this.userservice.getUserById(this.currentuser.userId).then(
        (user:User)=>{
          this.user=user
          this.role = user.role;
          this.usertype=user.usertype
          this.username = user.firstname;
          this.lastname= user.lastname;
          this.nature=user.nature
          this.raisonsociale=user.raisonsociale
          this.allotherdsSub = this.otheruser.otherdss$.subscribe(
            (otherdss) => {
              this.otherdss = otherdss.filter(e=>new Date(e.created) >=new Date('11.30.2023'));
              this.payedotherdss= this.otherdss.filter(e=>e.paye==true)
              this.nonpayedotherdss= this.otherdss.filter(e=>e.paye==false)
      //        this.deccomptabilitenumber=this.deccomptabilites.length
            },
            (error) => {
        
            }
          );
          this.alldeccomptabSub = this.deccompt.dsdeccomptabilitesuser$.subscribe(
            (deccomptabilities) => {
              this.dsdeccomptabilites = deccomptabilities.filter(e=>new Date(e.created)>=new Date('11.30.2023'));
              this.payedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==true)
              this.nonpayedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==false)
      //        this.deccomptabilitenumber=this.deccomptabilites.length
            },
            (error) => {
    
            }
          );
          this.alldecfiscmensSub = this.dec.dsdecfiscmenss$.subscribe(
            (decfiscmenss) => {
              this.dsdecfiscmenss = decfiscmenss.filter(e=>new Date(e.created)>=new Date('11.30.2023')&&e.origine!='généré automatiquement');
              this.payedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==true)
              this.nonpayedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==false)
      
      //        this.deccomptabilitenumber=this.deccomptabilites.length
            },
            (error) => {
      
            }
          );
          if (this.usertype==='Client')
          {
          this.otheruser.getOtherdsdataByuser(this.currentuser.userId)
          this.deccompt.getDeccomptabilitereqByfactureuser(this.currentuser.userId)
          this.dec.getdecfiscmensqByfactureuser(this.currentuser.userId)
        };
        }
      )
   
    }

     
    
    
  
  }
  getalldsuser():any{
    let alldssuserlength:any
    let alldssuser:any=[]
    alldssuser=alldssuser.concat(this.otherdss,this.dsdeccomptabilites,this.dsdecfiscmenss)
alldssuserlength=alldssuser.length
let resultobject=[alldssuser,alldssuserlength]
return resultobject
  }
  getalldsusernonpaye():any{
    let alldssusernonpayelength:any
    let alldssusernonpaye:any=[]
    alldssusernonpaye=alldssusernonpaye.concat(this.nonpayedotherdss,this.nonpayedsdeccomptabilites,this.nonpayedsdecfiscmenss)
    alldssusernonpayelength=alldssusernonpaye.length
let resultobject=[alldssusernonpaye,alldssusernonpayelength]
return resultobject
  }
  getalldsuserpaye():any{
    let alldssuserpayelength:any
    let alldssuserpaye:any=[]
    alldssuserpaye=alldssuserpaye.concat(this.payedotherdss,this.payedsdeccomptabilites,this.payedsdecfiscmenss)
    alldssuserpayelength=alldssuserpaye.length
let resultobject=[alldssuserpaye,alldssuserpayelength]
return resultobject
  }
  openLoginModal() {
    console.log('openLoginModal called'); // Add this log
    this.loginModalService.openModal();
  }
  openSignupModal() {
    console.log('opensignupModal called'); // Add this log
    this.signupModalService.openModal();
  }
  infoassiette(): void {
    if(this.user.regimefiscalimpot=='Forfait D\'assiette')
    {
      Swal.fire({
        title: 'Vous êtes sous le règime forfait d\assiette, veuillez noter que vous n\'êtes pas tenue d\'établir vos fichiers comptables',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      }).then((result) => {
        this.router.navigate(['declare-comptabilite'])

      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });  
    }
    else
    {
      this.router.navigate(['declare-comptabilite'])
    }
  }
  logout(): void {
    Swal.fire({
      title: 'Vous êtes sur le point de se déconnecter, voulez vous continuer?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Se Déconnecter',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {   
    this.Auth.signOut();
this.reloadPage()
      }
      else{
        
      }

    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });
    
        
    
    
  }
  scrollToSection(id: string) {
    document.getElementById(id)!.scrollIntoView({ behavior: 'smooth' });
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    let currentSection = '';
    this.sections.forEach(section => {
      const element = document.getElementById(section.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= 175 && rect.bottom > 175) {
          currentSection = section.id;
        }
      }
    });
    this.activeSection = currentSection;
  }
  reloadPage (){
    setTimeout(() => window.location.reload(), 3000);
    
    
  }
  

 

}
