import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormStateService {
  private gardeformState: FormGroup;
  private rsformState: FormGroup;
  private tfpformState: FormGroup;
  private foprolosformState: FormGroup;
  private dtformState: FormGroup;
  private tva1formState: FormGroup;
  private tva2formState: FormGroup;
  private tcl1formState: FormGroup;
  private tcl2formState: FormGroup;
  private tcl3formState: FormGroup;
  private prov1formState: FormGroup;
  private prov2formState: FormGroup;


  setprov1FormState(form: FormGroup) {
    this.prov1formState = form;
  }

  getprov1FormState(): FormGroup {
    return this.prov1formState;
  }
  setprov2FormState(form: FormGroup) {
    this.prov2formState = form;
  }

  getprov2FormState(): FormGroup {
    return this.prov2formState;
  }
  setGardeFormState(form: FormGroup) {
    this.gardeformState = form;
  }

  getGardeFormState(): FormGroup {
    return this.gardeformState;
  }
  setrsFormState(form: FormGroup) {
    this.rsformState = form;
  }

  getrsFormState(): FormGroup {
    return this.rsformState;
  }
  settfpFormState(form: FormGroup) {
    this.tfpformState = form;
  }

  gettfpFormState(): FormGroup {
    return this.tfpformState;
  }
  setfoprolosFormState(form: FormGroup) {
    this.foprolosformState = form;
  }

  getfoprolosFormState(): FormGroup {
    return this.foprolosformState;
  }
  setdtFormState(form: FormGroup) {
    this.dtformState = form;
  }

  getdtFormState(): FormGroup {
    return this.dtformState;
  }
  settva1FormState(form: FormGroup) {
    this.tva1formState = form;
  }

  gettva1FormState(): FormGroup {
    return this.tva1formState;
  }
  settva2FormState(form: FormGroup) {
    this.tva2formState = form;
  }

  gettva2FormState(): FormGroup {
    return this.tva2formState;
  }
  settcl1FormState(form: FormGroup) {
    this.tcl1formState = form;
  }

  gettcl1FormState(): FormGroup {
    return this.tcl1formState;
  }
  settcl2FormState(form: FormGroup) {
    this.tcl2formState = form;
  }

  gettcl2FormState(): FormGroup {
    return this.tcl2formState;
  }
  settcl3FormState(form: FormGroup) {
    this.tcl3formState = form;
  }

  gettcl3FormState(): FormGroup {
    return this.tcl3formState;
  }
}
