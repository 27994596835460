export class Usedressourcemodel {
    _id:string;
    ressourceid:string;
    connecteduser:string;
    firstname: string;
    lastname: string;
    code: string;
    raisonsociale: string;
    firstnameclient: string;
    lastnameclient: string;
    mois: string;
    annee: string;
    type: string;
    created:Date;
    updated: Date
        }