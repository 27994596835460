
<body style="margin: 0;">

    <!-- <div class="navDiv">
      <div class="buttonContainer"><button class="boutonNav">Actualité</button></div>
      <div class="buttonContainer"><button class="boutonNav">Rappelez vous !</button></div>
      <div class="buttonContainer"><button class="boutonNav">Nos valeurs</button></div>
      <div class="buttonContainer"><button class="boutonNav">Qui sommes nous ?</button></div>
      <div class="buttonContainer"><button class="boutonNav">Notre projet</button></div>
      <div class="buttonContainer"><button class="boutonNav">Nos services</button></div>
    </div> -->
    <section class="pageActualite page"  id="actualite">
        <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator"*ngIf="carousels" class="section carousel-section" >
            <slide *ngFor="let item of sortedcarousels; let index=index">
               <img src="../../../assets/img/carousel-2.jpg.png" alt="image slide" style="display: block; width: 100%;height:400px;border-radius: 55px 55px 55px 55px">
               <div class="carousel-caption">
                <h4><span style="color: red;">{{item.titre}}</span> <br><span
                    style="color: white">{{item.commentaire}}</span><br><a class="btn btn-primary"*ngIf="item.description" (click)="voirdetail('view-carousel',item._id)" style="bottom: 0;">Lire Plus</a></h4>
              </div>
            </slide>
         </carousel>
    </section>

    <section class="pageRappelezVous page" id="rappelez-vous">
  <app-due-date-timeline></app-due-date-timeline>
    </section>

    <section class="pageNosValeurs page" id="nos-valeurs">
      <p style= "color:black; margin-bottom: 2%;" class="title">Nos valeurs</p>
      <div class="valeursContainer">
        <div class="valeur">
          <img src="../../assets/simpliciteIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Simplicité d'utilisation</b>
        </div>
        <div class="valeur">
          <img src="../../assets/securiteIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Sécurité</b>
        </div>
        <div class="valeur">
          <img src="../../assets/confidentialiteIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Confidentialité</b>
        </div>
        <div class="valeur">
          <img src="../../assets/disponibilteIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Disponibilité</b>
        </div>
        <div class="valeur">
          <img src="../../assets/proximiteIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Proximité</b>
        </div>
      </div>
      <div class="valeursContainer">
        <div class="valeur">
          <img src="../../assets/gardeDocIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Garde des documents par vous</b>
        </div>
        <div class="valeur">
          <img src="../../assets/gratuiteIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Gratuité de saisie</b>
        </div>
        <div class="valeur">
          <img src="../../assets/rapiditeIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Rapidité</b>
        </div>
        <div class="valeur">
          <img src="../../assets/depotIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Dépôt à distance de vos déclarations   fiscales</b>
        </div>
        <div class="valeur">
          <img src="../../assets/reductionCoutIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Minimisation des coûts</b>
        </div>
      </div>
      <div class="valeursContainer">
        <div class="valeur">
          <img src="../../assets/dematrialisationIcone.png" class="valeurImage">
          <br>
          <b style="font-size: 1.8vw;">Dématrialisation à 100%</b>
        </div>
      </div>
    
    </section>
    
    
    
    
    
    <section class="pageAboutUs page" id="qui-sommes-nous">
      <p class="title" style="color:#E5FF45;">À propos de nous</p>
      <p class="aboutUs">Logiciels de comptabilité, facturation et paie, puissants, complets au meilleur prix.</p>
      <img class="aboutUsImage" src="../../assets/about1.png">
      <div style="display: flex;">
      <div style="display: inline ; width:60%;"><p class="aboutUsParagraph">Logiciel de facturation, comptabilité, note de frais, déclarations fiscales, immobilisations, paie et déclarations sociales... Gérez toute votre entreprise (ou association) sur macompta.com.tn.</p>
      <button class="enSavoirPlus">En savoir plus</button> </div> 
      <div><img class="aboutUsImage" src="../../assets/about2.png"></div>
    </div>
      <br>
      <br/>
    </section>
    
    
    <section class="pageNotreProjet page "  id="notre-projet">
      <p style="color:black" class="title">Notre projet </p>
      <p style="color:#BB0000; font-size: 2.5vw;" class="title">Notre présentation</p>
      <video controls="controls" class="videoPresentation"> 
        <source src="../../assets/v2_présentation_générale.mp4" type="video/mp4">
      </video>
      <br/>
      <br/>
    </section>
    
    
    <div class="pageNosServices page" id="nos-services">
      <br/>
      <p style="color:black" class="title">Nos services</p>
      <p class="title" style="color: #061B3C; font-size: 2.5vw; margin-bottom: 2.5%;">Une suite complète d'outils pour votre gestion</p>
      <br/>
      
   
      <div style="display: flex; flex-wrap: wrap;">
        <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".3s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <i class="far fa-sticky-note fa-7x mb-4" style="color: #031A3D;"></i>
                <h4 class="mb-3">Comptabilité</h4>
                <p class="mb-4 service-text">Tenez votre comptabilité.</p>
                <a (click)="infoassiette()" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
              </div>
            </div>
          </div>
        </div>
      
        <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".5s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <i class="fas fa-money-bill fa-7x mb-4" style="color: #031A3D;"></i>
                <h4 class="mb-3">Fiscalité</h4>
                <p class="mb-4 service-text">Etablissez vos déclarations fiscales</p>
                <a href="choix-declaration" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
              </div>
            </div>
          </div>
        </div>
      
        <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".7s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <i class="fas fa-balance-scale fa-7x mb-4" style="color: #031A3D;"></i>
                <h4 class="mb-3">Paie</h4>
                <p class="mb-4 service-text">Préparez vos fiches de paie.</p>
                <a routerLink="Documentation" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
              </div>
            </div>
          </div>
        </div>
      
        <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".3s">
          <div class="services-item bg-light">
            <div class="p-4 text-center services-content">
              <div class="services-content-icon">
                <i class="fas fa-calculator fa-7x mb-4" style="color: #031A3D;"></i>
                <h4 class="mb-3">Conseil & Consultation</h4>
                <p class="mb-4 service-text">Demandez un conseil.</p>
                <a routerLink="Documentation" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
    
    
    </body>







<!--<body>
    <div class="left">
      <div class="side">
        <app-due-date-timeline ></app-due-date-timeline>
        </div>
    <div class="main">
          <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator"*ngIf="carousels" class="section carousel-section" >
            <slide *ngFor="let item of sortedcarousels; let index=index">
               <img src="../../../assets/img/carousel-2.jpg.png" alt="image slide" style="display: block; width: 100%;height:400px;border-radius: 55px 55px 55px 55px">
               <div class="carousel-caption">
                <h4><span style="color: red;">{{item.titre}}</span> <br><span
                    style="color: white">{{item.commentaire}}</span><br><a class="btn btn-primary"*ngIf="item.description" (click)="voirdetail('view-carousel',item._id)" style="bottom: 0;">Lire Plus</a></h4>
              </div>
            </slide>
         </carousel>
         <div class="section features-container">
            <div class="feature">
                <i class="fas fa-key feature-icon"></i>
                <h5 class="feature-title">Simplicité d'utilisation</h5>
            </div>
            <div class="feature">
                <i class="far fa-calendar-check feature-icon"></i>
                <h5 class="feature-title">Sécurité</h5>
            </div>
            <div class="feature">
                <i class="fas fa-redo feature-icon"></i>
                <h5 class="feature-title">Confidentialité</h5>
            </div>
            <div class="feature">
                <i class="fas fa-handshake feature-icon"></i>
                <h5 class="feature-title">Disponibilité</h5>
            </div>
            <div class="feature">
                <i class="fas fa-key feature-icon"></i>
                <h5 class="feature-title">Proximité</h5>
            </div>
            <div class="feature">
                <i class="far fa-calendar-check feature-icon"></i>
                <h5 class="feature-title">Garde des documents par vous</h5>
            </div>
            <div class="feature">
                <i class="fas fa-redo feature-icon"></i>
                <h5 class="feature-title">Gratuité de saisie</h5>
            </div>
            <div class="feature">
                <i class="fas fa-key feature-icon"></i>
                <h5 class="feature-title">Rapidité</h5>
            </div>
            <div class="feature">
                <i class="far fa-calendar-check feature-icon"></i>
                <h5 class="feature-title">Dépôt à distance de vos déclarations fiscales</h5>
            </div>
            <div class="feature">
                <i class="fas fa-redo feature-icon"></i>
                <h5 class="feature-title">Minimisation des coûts</h5>
            </div>
            <div class="feature">
                <i class="fas fa-handshake feature-icon"></i>
                <h5 class="feature-title">Dématrialisation à 100%</h5>
            </div>
        </div>      
        <div class="section container-fluid py-5">
            <div class="container pt-5">
                <div class="row g-5 flex align-items-center">
                    <div class="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
                        <div class="h-100 position-relative">
                            <img src="../../../assets/img/about-1.png" class="img-fluid w-75 rounded" alt="" style="margin-bottom: 25%;">
                            <div class="position-absolute w-75" style="top: 25%; left: 25%;">
                                <img src="../../../assets/img/about-2.png" class="img-fluid w-100 rounded" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                        <h5 class = "mobile-about-h5" style="color: #031A3D;">À propos de nous</h5>
                        <h1 class="mb-4 mobile-about-h1">Logiciels de comptabilité, facturation et paie, puissants, complets au meilleur prix.</h1>
                        <p class="text-about-paragraph">Logiciel de facturation, comptabilité, note de frais, déclarations fiscales, immobilisations, paie et déclarations sociales... Gérez toute votre entreprise (ou association) sur macompta.com.tn.</p>
                        <button routerLink="who"class="btn-about">En savoir plus</button>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>
<div class="container">
  <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style="max-width: 600px;">
    <h5 style="color: #031A3D;">Notre Projet</h5>
    <h1>Notre Présentation</h1>
  </div>
  <img
    class="fit-picture mobile-img"
    src="./../../assets/img/Group 91.png"
    alt="Grapefruit slice atop a pile of other slices"
    (click)="playVideo()"
    [style.display]="videoVisible ? 'none' : 'block'"
  />
  <video class="mobile-video" *ngIf="videoVisible" width="1206" height="557" controls>
    <source src="./../../assets/img/v2_présentation_générale.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</div>
<div class="container-fluid services py-5 mb-5">
  <div class="container">
      <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style="max-width: 600px;">
          <h5 style="color: #031A3D;">Nos Services</h5>
          <h1>Une suite complète d'outils pour votre gestion</h1>
      </div>
      <div class="row g-5 services-inner">
          <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                  <div class="p-4 text-center services-content">
                      <div class="services-content-icon">
                          <i class="far fa-sticky-note fa-7x mb-4 " style="color: #031A3D;"></i>                      
                          <h4 class="mb-3">Comptabilité</h4>
                          <p class="mb-4 service-text">Tenez votre comptabilité.</p>
                          <a (click)="infoassiette()"style="cursor: pointer;" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".5s">
              <div class="services-item bg-light">
                  <div class="p-4 text-center services-content">
                      <div class="services-content-icon">
                          <i class="fas fa-money-bill fa-7x mb-4" style="color: #031A3D;"></i>
                          <h4 class="mb-3">Fiscalité</h4>
                          <p class="mb-4 service-text">Etablissez vos déclarations fiscales</p>
                        <a  href="declare-fiscality" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".7s">
              <div class="services-item bg-light">
                  <div class="p-4 text-center services-content">
                      <div class="services-content-icon">
                          <i class="fas fa-balance-scale fa-7x mb-4 " style="color: #031A3D;"></i>
                          <h4 class="mb-3">Paie</h4>
                          <p class="mb-4 service-text">Préparez vos fiches de paie.</p>
                          <a  routerLink="Documentation" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-lg-6 wow fadeIn" data-wow-delay=".3s">
              <div class="services-item bg-light">
                  <div class="p-4 text-center services-content">
                      <div class="services-content-icon">
                          <i class="fas fa-calculator fa-7x mb-4 " style="color: #031A3D;"></i>
                          <h4 class="mb-3">Conseil & Consultation</h4>
                          <p class="mb-4 service-text">Demandez un conseil.</p>
                          <a routerLink="Documentation" class="btn text-white px-5 py-3 rounded-pill" style="background-color: #D11317;">Accéder au service</a>
                      </div>
                  </div>
              </div>
          </div>
          Repeat similar structure for other services 
      </div>
  </div>
</div>

  <div id="contactid">
  <router-outlet></router-outlet>
  </div>     
</body>-->