import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tcl2',
  templateUrl: './tcl2.component.html',
  styleUrls: ['./tcl2.component.scss']
})
export class TCL2Component implements OnInit,OnDestroy{
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  tcl2form: FormGroup;
  sumOfDisabledControls: number = 0;
  formValueChangesSubscription: Subscription;
  sumOfPositiveControls: number;
  sumOfNegativeControls: number;
  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.tcl2form.reset();
  }
  ngOnInit(): void {
     // Initialize forms
     const savedFormState = this.formStateService.gettcl2FormState();
     if (savedFormState) {
       this.tcl2form = savedFormState;
       
     }
     else
     {
      this.tcl2form = this.fb.group({
        natureassiette: [null, [Validators.min(0)]],
      });
     }
  }
  ngOnDestroy(): void {
        // Save the form state when the component is destroyed
        this.formStateService.settcl2FormState(this.tcl2form);
  }
  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }
  previous() {
    this.previousStep.emit();
  }

}