



<div class="container mt-5">
    <div class="circle circle-red"></div>
    <div class="circle circle-white"></div>
  
    <h2 class="text-center">DÉPOSER VOTRE CANDIDATURE</h2>
    <p class="text-center">Nous sommes dans l'attente d'excellents profils pour intégrer notre équipe</p>
    
    <div class="form-container">
      <form [formGroup]="condidateform" (ngSubmit)="onSubmit()">
        <div class="form-row">
          <div class="form-group 1">
            <label for="prenom">Prénom</label>
            <input type="text" class="form-control" id="firstname" formControlName="firstname" 
            [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"placeholder="entrer votre prenom" required>
            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                <div *ngIf="f.firstname.errors.required">Prénom Obligatoire</div>
            </div> 
        </div>
          <div class="form-group 1">
            <label for="nom">Nom</label>
            <input type="text" class="form-control" id="lastname" formControlName="lastname" 
            [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"placeholder="entrer votre nom"required>
            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                <div *ngIf="f.lastname.errors.required">Nom Obligatoire</div>
            </div> 
        </div>
        </div>
  
        <div class="form-row">
          <div class="form-group 2">
            <label for="mobile">Mobile</label>
            <input type="text" class="form-control" id="mobile" formControlName="mobile" 
            [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"placeholder="entrer votre mobile" required>
            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                <div *ngIf="f.mobile.errors.required">Numéro Mobile Obligatoire</div>
            </div>  
        </div>
          <div class="form-group 2">
            <label for="confirmMobile">Confirmer Mobile</label>
            <input type="text" class="form-control" id="confirmmobile" formControlName="confirmmobile" 
            [ngClass]="{ 'is-invalid': submitted && f.confirmmobile.errors }"placeholder="confimer votre mobile"required>
            <div *ngIf="submitted && f.confirmmobile.errors" class="invalid-feedback">
                <div *ngIf="f.confirmmobile.errors.required">Numéro Mobile Obligatoire</div>
                <div *ngIf="f.confirmmobile.errors.mustMatch">Les numéros mobiles ne sont pas identiques</div>
            </div>
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group 3">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="inputEmail" formControlName="email" 
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"placeholder="entrer votre email">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email Obligatoire</div>
                <div *ngIf="f.email.errors.email">Adresse Email non valides</div>
            </div>
        </div>
          <div class="form-group 3">
            <label for="confirmEmail">Confirmer Email</label>
            <input type="email" class="form-control" id="inputConfirmEmail" formControlName="confirmemail" 
            [ngClass]="{ 'is-invalid': submitted && f.confirmemail.errors }"placeholder="confimer votre email">
            <div *ngIf="submitted && f.confirmemail.errors" class="invalid-feedback">
                <div *ngIf="f.confirmemail.errors.required">Email Obligatoire</div>
                <div *ngIf="f.confirmemail.errors.email">Adresse Email non valides</div>
                <div *ngIf="f.confirmemail.errors.mustMatch">Les Emails  ne sont pas identiques</div>
              </div>
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group 4">
            <label for="adresse">Adresse</label>
            <input type="text" class="form-control" id="inputAdresse" formControlName="adresse" 
                            [ngClass]="{ 'is-invalid': submitted && f.adresse.errors }"placeholder="entrer votre adresse">
                            <div *ngIf="submitted && f.adresse.errors" class="invalid-feedback">
                                <div *ngIf="f.adresse.errors.required">Adresse Obligatoire</div>
                            </div> 
        </div>
        <div class="form-group 4">
            <label for="specialite">Spécialité</label>
            <select  class="form-control" placeholder="Choisir Votre Spécialité" id="specialite" [(ngModel)]="optionValue"
            [ngClass]="{ 'is-invalid': submitted && f.specialite.errors }"formControlName="specialite" name="specialite" >
            <option value="" disabled selected>Choisir...</option>
            <option value="Comptabilité">Comptabilité</option>
            <option value="Fiscalité">Fiscalité</option>
            <option value="Autre">Autre</option>
          </select>
          <div *ngIf="submitted && f.specialite.errors" class="invalid-feedback">
            <div *ngIf="f.specialite.errors.required">Specialité Obligatoire</div>
        </div>
          </div>
        </div>
<div class="form-row">
    <div class="form-group 5">
        <div id="selectspecialite"*ngIf="optionValue == 'Autre'">
        
            <input class="form-control" type="text" placeholder="Choisir Votre Spécialité" id="selectspecialite" formControlName="selectspecialite">
          </div>
    </div>
</div>

        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="cv">Attacher Votre CV</label>
            <input type="file"  #filePicker (change)="onImagePick($event)" class="form-control-file"accept="application/pdf" accept=".xls, .xlsx">
    <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
        <div *ngIf="f.image.errors.required">CV Obligatoire</div>
    </div>
    <div class="image-preview" *ngIf="imagePreview">
      <img [src]="imagePreview" alt="">
    </div>
    <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="message">Exprimez-vous</label>
            <textarea class="form-control" id="inputMessage" rows="10" formControlName="description" 
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"placeholder="Présentez vous brièvement"required></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">Description Obligatoire</div>
            </div>
        </div>
        </div>
  
        <div class="button-group text-center">
          <input type="submit" class="btn btn-primary" value="Envoyer candidature">
          <input class="btn btn-secondary" type="reset" (click)="onReset()"value="Réinitialiser">
        </div>
      </form>
      <div *ngIf="successmessage" class="alert alert-success mt-3">
        {{ successmessage }}
      </div>
    </div>
</div>
<!--<body>
    <div class="container-fluid">
        <div id="loader" *ngIf="loading"></div>
        <div class="row"*ngIf="!loading">
            <div class="col-lg-12">
                <div class="contact-form">
                    <h1>Déposer votre candidature</h1>
                    <p class="hint-text">Nous sommes dans l'attente d'excellents profils pour intégrer notre équipe</p>
                    <form [formGroup]="condidateform"(ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="firstname">Prénom</label>
                                    <input type="text" class="form-control" id="firstname" formControlName="firstname" 
                                    [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"placeholder="entrer votre prenom" required>
                                    <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstname.errors.required">Prénom Obligatoire</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="lastname">Nom</label>
                                    <input type="text" class="form-control" id="lastname" formControlName="lastname" 
                                    [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"placeholder="entrer votre nom"required>
                                    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Nom Obligatoire</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="mobile">Mobile</label>
                                    <input type="text" class="form-control" id="mobile" formControlName="mobile" 
                                    [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"placeholder="entrer votre mobile" required>
                                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.mobile.errors.required">Numéro Mobile Obligatoire</div>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="confirmmobile">Confirmer Mobile</label>
                                    <input type="text" class="form-control" id="confirmmobile" formControlName="confirmmobile" 
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmmobile.errors }"placeholder="confimer votre mobile"required>
                                    <div *ngIf="submitted && f.confirmmobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmmobile.errors.required">Numéro Mobile Obligatoire</div>
                                        <div *ngIf="f.confirmmobile.errors.mustMatch">Les numéros mobiles ne sont pas identiques</div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="inputEmail">Email</label>
                                    <input type="email" class="form-control" id="inputEmail" formControlName="email" 
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"placeholder="entrer votre email">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email Obligatoire</div>
                                        <div *ngIf="f.email.errors.email">Adresse Email non valides</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="inputConfirmEmail">Confirmer Email</label>
                                    <input type="email" class="form-control" id="inputConfirmEmail" formControlName="confirmemail" 
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmemail.errors }"placeholder="confimer votre email">
                                    <div *ngIf="submitted && f.confirmemail.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmemail.errors.required">Email Obligatoire</div>
                                        <div *ngIf="f.confirmemail.errors.email">Adresse Email non valides</div>
                                        <div *ngIf="f.confirmemail.errors.mustMatch">Les Emails  ne sont pas identiques</div>
                                      </div>
                                </div>
                               
                            </div>
                        </div>                
                        <div class="form-group">
                            <label for="inputAdresse">Adresse</label>
                            <input type="text" class="form-control" id="inputAdresse" formControlName="adresse" 
                            [ngClass]="{ 'is-invalid': submitted && f.adresse.errors }"placeholder="entrer votre adresse">
                            <div *ngIf="submitted && f.adresse.errors" class="invalid-feedback">
                                <div *ngIf="f.adresse.errors.required">Adresse Obligatoire</div>
                            </div>
                        </div>
                        <div  class="form-group">
                            <label for="inputspecialite">Spécialité</label>
                            <select  class="form-control" placeholder="Choisir Votre Spécialité" id="specialite" [(ngModel)]="optionValue"
                            [ngClass]="{ 'is-invalid': submitted && f.specialite.errors }"formControlName="specialite" name="specialite" >
                            <option hidden >Choisir Votre Spécialité</option>
                            <option value="Comptabilité">Comptabilité</option>
                            <option value="Fiscalité">Fiscalité</option>
                            <option value="Autre">Autre</option>
                          </select>
                          <div *ngIf="submitted && f.specialite.errors" class="invalid-feedback">
                            <div *ngIf="f.specialite.errors.required">Specialité Obligatoire</div>
                        </div>
                          </div>
                        <div id="selectspecialite"*ngIf="optionValue == 'Autre'">
                        
                            <input class="form-control" type="text" placeholder="Choisir Votre Spécialité" id="selectspecialite" formControlName="selectspecialite">
                          </div>
                        <div class="form-group">
     <label for="inputCV">Attacher Votre CV</label>                   
    <input type="file"  #filePicker (change)="onImagePick($event)" class="form-control" 
    accept="application/pdf" accept=".xls, .xlsx">
    <div *ngIf="submitted && f.image.errors" class="invalid-feedback">
        <div *ngIf="f.image.errors.required">CV Obligatoire</div>
    </div>
    <div class="image-preview" *ngIf="imagePreview">
      <img [src]="imagePreview" alt="">
    </div>
    <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
   
</div>
                        <div class="form-group">
                            <label for="inputMessage">Exprimez vous</label>
                            <textarea class="form-control" id="inputMessage" rows="10" formControlName="description" 
                            [ngClass]="{ 'is-invalid': submitted && f.description.errors }"placeholder="Présentez vous brièvement"required></textarea>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                <div *ngIf="f.description.errors.required">Description Obligatoire</div>
                            </div>
                        </div>
                        <input type="submit" class="btn btn-primary" value="Envoyer candidature">
                        <input class="btn btn-secondary" type="reset" (click)="onReset()"value="Réinitialiser">
                        <p class="text-success" >{{successmessage}}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</body>--> 