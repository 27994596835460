

<div class="modal" [style.display]="isLoginModalOpen ? 'block' : 'none'">
  <div class="modal-content">


      <!-- Left Section (Image) -->
      <div class="left-section">
          <img src="../../assets/img/image.png" alt="Your Image">
      </div>

      <!-- Right Section (Form) -->
      <div class="right-section">
          <!-- modal for viex all ds non paye by user-->
   <div class="modal-signup"  [ngStyle]="{'display':displayverifsecond}">
    <div class="modal-content">
      <div class="right-section-signup">
        <div class="container-fluid" style="position: relative; height: 400px;overflow-y: auto;">
          <h1>Liste des comptes de l'utilisateur</h1>
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">Code </th>
              <th scope="col">Email </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of comptesuser; let i = index"> 
              <td>{{user.clientcode}}</td>
              <td>{{user.email}}</td>
              <td><button type="button" class="btn btn-success" (click)="onLogin(user.email,user._id)">Utiliser ce compte</button></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="btn-signup-section">
          <div style="width: 50%; text-align: end;">
              <button class="reinstaller-btn-modal" (click)="correctpass()">Corriger Mot de Passe</button>
          </div>
      </div>
      </div>
    </div>
  </div>
          <h1 >Bienvenue</h1>
          <h2>Connectez-Vous</h2>
          <form [formGroup]="loginForm"(ngSubmit)="loginForm.valid"novalidate>
              <div class="form-group">
                  <label for="email">Email:</label>
                  <input type="text" id="email" name="email" formControlName="email" placeholder="Adresse Email" required autofocus>
              </div>

              <div class="form-group">
                  <label for="password">Mot de passe:</label>
                  <div style="display: flex;">
                    <input [type]="fieldTextType ? 'text' : 'password'"formControlName="password"style="width:95%;"placeholder="Entrer mot de passe" required/>
                    <span class="input-group-addon"style="width: 5%;">
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }"
                        (click)="toggleFieldTextType()"
                      ></i>
                    </span>
                  </div>
                 
              </div>
                <div class="row">
                  <div class="col">
                    <a routerLink="signup" routerLinkActive="active" style="cursor: pointer;margin-left: 3px;" class="btn"(click)="closeLoginModal()"(click)="openSignupModal()">Inscrivez Vous Gratuitement</a>
                  </div>
                  <div class="col">
                    <a  routerLink="forgot-password" routerLinkActive="active"style="cursor: pointer"(click)="closeLoginModal()" class="btn">Mot de Passe Oublié?</a>
                  </div>
                </div>
              <button class="login-btn-modal"(click)="onverifyduplicate()" [disabled]="loginForm.invalid">Connecter</button>
          </form>
      </div>
              <!-- Close Button (Top-left corner) -->
              <div class="close-button" (click)="closeLoginModal()" style="background-color:#F8F8F9;">
                  <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
              </div>
      
  </div>
</div>