import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageGardeComponent } from './dec-fisc-direct-compo/page-garde/page-garde.component';
import { TaxWizardComponentComponent } from './dec-fisc-direct-compo/tax-wizard-component/tax-wizard-component.component';
import { PageGarde2Component } from './dec-fisc-direct-compo/page-garde2/page-garde2.component';
import { RSComponent } from './dec-fisc-direct-compo/rs/rs.component';
import { TFPCompComponent } from './dec-fisc-direct-compo/tfp-comp/tfp-comp.component';
import { FOPROLOSCOMPOComponent } from './dec-fisc-direct-compo/foprolos-compo/foprolos-compo.component';
import { TVA1Component } from './dec-fisc-direct-compo/tva1/tva1.component';
import { TVA2Component } from './dec-fisc-direct-compo/tva2/tva2.component';
import { DTCOMPComponent } from './dec-fisc-direct-compo/dt-comp/dt-comp.component';
import { TCL1Component } from './dec-fisc-direct-compo/tcl1/tcl1.component';
import { TCL2Component } from './dec-fisc-direct-compo/tcl2/tcl2.component';
import { TCL3Component } from './dec-fisc-direct-compo/tcl3/tcl3.component';
const routes: Routes = [
  {path:'RS',component:RSComponent},
   {path:'TFPc',component:TFPCompComponent},
   {path:'FOPRCOm',component:FOPROLOSCOMPOComponent},
   {path:'TVA1',component:TVA1Component},
   {path:'TVA2',component:TVA2Component},
   {path:'DT',component:DTCOMPComponent},
   {path:'TCL1',component:TCL1Component},
   {path:'TCL2',component:TCL2Component},
   {path:'TCL3',component:TCL3Component},
   {path : 'pGarde',component :PageGardeComponent},
  {path : 'p2Garde',component :PageGarde2Component},
  {path : 'tt',component :TaxWizardComponentComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DescFiscMensDirectModuleRoutingModule { }
