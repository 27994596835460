import { Component, OnInit } from '@angular/core';


import { ActivatedRoute, Params, Router } from '@angular/router';
import { Otherdsmodeluser } from '../models/otherds-user.model';
import { User } from '../models/user.model';
import { OtherdsServiceUser } from '../services/otherds-user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import { Recouvrementnotif } from '../models/notification-recouvrement.model';
import { Notificationrec } from '../services/notification-recouvrement.service';
@Component({
  selector: 'app-rec-not-list',
  templateUrl: './rec-not-list.component.html',
  styleUrls: ['./rec-not-list.component.scss']
})
export class RecNotListComponent implements OnInit {


  public user: User;
  public notifrec: Recouvrementnotif;
  public errormsg:string;
  public loading: boolean;
  public role: string;
  currentUser: any;

  

  constructor(
              private router: Router,
              private route: ActivatedRoute,
              private usersservice: UserService,
              private recnot: Notificationrec,
              private token: TokenStorageService) { }
  ngOnInit() {
    this.loading = true;
    this.currentUser = this.token.getUser();
  
    this.route.params.subscribe(
      (params: Params) => {
        this.recnot.getnotificationrecById(params.id).then(
          (recnot: Recouvrementnotif) => {
            this.loading = false;
            this.notifrec = recnot;
          }
        );
      }
    );
    
  }

}
