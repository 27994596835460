import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';
import { DecfiscmensService } from '../../services/dec-fisc-mens';

@Component({
  selector: 'app-tva1',
  templateUrl: './tva1.component.html',
  styleUrls: ['./tva1.component.scss']
})
export class TVA1Component implements OnInit,OnDestroy{
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  tva1form: FormGroup;
  sumOfDisabledControls: number =0;
  formValueChangesSubscription: Subscription;
  sumOfPositiveControls: number =0;
  sumOfNegativeControls: number =0;
  annee: any;
  mois: any;
  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService,private DecfiscmensService :DecfiscmensService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.tva1form.reset();
  }
  ngOnInit(): void {
     // Initialize forms
     const savedFormState = this.formStateService.gettva1FormState();
     if (savedFormState) {
       this.tva1form = savedFormState;
         // Reset sum
   this.updateSumAndTotal()
     }
     else
     {
      this.tva1form = this.fb.group({
        tvaDue7: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montant7: [0, [Validators.min(0)]],
        tvaDue13: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montant13: [0, [Validators.min(0)]],
        tvaDue19: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montant19: [0, [Validators.min(0)]],
        tvaDue6: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montant6: [0, [Validators.min(0)]],
        tvaDue12: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montant12: [0, [Validators.min(0)]],
        tvaDue18: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montant18: [0, [Validators.min(0)]],
        tvaDueOther: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantOther: [0, [Validators.min(0)]],
        achats19: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantAchats19: [0, [Validators.min(0)]],
        achatsEquipementsLocal: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantAchatsEquipementsLocal: [0, [Validators.min(0)]],
        achatsEquipementsImportes: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantAchatsEquipementsImportes: [0, [Validators.min(0)]],
        autresAchatsLocal: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantAutresAchatsLocal: [0, [Validators.min(0)]],
        autresAchatsImportes: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantAutresAchatsImportes: [0, [Validators.min(0)]],
        autresDeductions1000Dinars: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantAutresDeductions1000Dinars: [0, [Validators.min(0)]],
        tvaNonResidents: [{ value: 0, disabled: true }, [Validators.min(0)]],
        montantTvaNonResidents: [0, [Validators.min(0)]],
        v1: [0, [Validators.min(0)]],
        v1montant: [0, [Validators.min(0)]],
        v2: [0, [Validators.min(0)]],
        v3: [0, [Validators.min(0)]],
        v4: [0, [Validators.min(0)]],
        v5: [0, [Validators.min(0)]],
        v6: [0, [Validators.min(0)]],
        v7: [0, [Validators.min(0)]],
        v8: [0, [Validators.min(0)]],
        totalpositive: [0, [Validators.min(0)]],
        totalnegative: [0, [Validators.min(0)]]

      });
     }

     this.setupAutoCalculations();

// Subscribe to value changes of all form controls
    // Subscribe to value changes of all form controls
    this.formValueChangesSubscription = this.tva1form.valueChanges.subscribe(() => {
      this.updateSumAndTotal();
    });
  }
  ngOnDestroy(): void {
        // Save the form state when the component is destroyed
        this.formStateService.settva1FormState(this.tva1form);
        this.tva1form.get('totalpositive').setValue(this.sumOfPositiveControls)
        this.tva1form.get('totalnegative').setValue(this.sumOfNegativeControls)

    // Unsubscribe from value changes to prevent memory leaks
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
  }
  setupAutoCalculations(): void {
    const keysWithValueChanges = [
      { tvaDueKey: 'montant7', montantKey: 'tvaDue7', multiplier: 0.07 },
      { tvaDueKey: 'montant13', montantKey: 'tvaDue13', multiplier: 0.13 },
      { tvaDueKey: 'montant19', montantKey: 'tvaDue19', multiplier: 0.19 },
      { tvaDueKey: 'montant6', montantKey: 'tvaDue6', multiplier: 0.06 },
      { tvaDueKey: 'montant12', montantKey: 'tvaDue12', multiplier: 0.12 },
      { tvaDueKey: 'montant18', montantKey: 'tvaDue18', multiplier: 0.18 },
      { tvaDueKey: 'montantOther', montantKey: 'tvaDueOther', multiplier: 0.19 },
      { tvaDueKey: 'montantAchats19', montantKey: 'achats19', multiplier: 0.19 },
      { tvaDueKey: 'montantAchatsEquipementsLocal', montantKey: 'achatsEquipementsLocal', multiplier: 0.19 },
      { tvaDueKey: 'montantAchatsEquipementsImportes', montantKey: 'achatsEquipementsImportes', multiplier: 0.19 },
      { tvaDueKey: 'montantAutresAchatsLocal', montantKey: 'autresAchatsLocal', multiplier: 0.19 },
      { tvaDueKey: 'montantAutresAchatsImportes', montantKey: 'autresAchatsImportes', multiplier: 0.19 },
      { tvaDueKey: 'montantAutresDeductions1000Dinars', montantKey: 'autresDeductions1000Dinars', multiplier: 0.25 },
      { tvaDueKey: 'montantTvaNonResidents', montantKey: 'tvaNonResidents', multiplier: 1 }
    ];
  
    keysWithValueChanges.forEach(item => {
      this.tva1form.get(item.tvaDueKey)?.valueChanges.subscribe(value => {
        const montantControlKey = item.montantKey;
        const tvaDueValue = this.tva1form.get(item.tvaDueKey)?.value;
        const newValue = Math.floor((tvaDueValue * item.multiplier) * 1000) / 1000;
        this.tva1form.get(montantControlKey)?.setValue(newValue, { emitEvent: false });
      });
    });
  }
  
  updateSumAndTotal(): void {
    this.sumOfPositiveControls = 0;
    this.sumOfNegativeControls = 0;
    const positiveKeys = [
      'tvaDue7',
      'tvaDue13',
      'tvaDue19',
      'tvaDue6',
      'tvaDue12',
      'tvaDue18',
      'tvaDueOther',
      'v8'
    ];
    const negativeKeys = [
      'achats19',
      'achatsEquipementsLocal',
      'achatsEquipementsImportes',
      'autresAchatsLocal',
      'autresAchatsImportes',
      'autresDeductions1000Dinars',
      'tvaNonResidents',
      'v1',
      'v2',
      'v4',
      'v6',
  ];

    Object.keys(this.tva1form.controls).forEach(key => {
      const control = this.tva1form.get(key);
      if (control && positiveKeys.includes(key)) {
        this.sumOfPositiveControls += control.value;
      } else if (control && negativeKeys.includes(key)) {
        this.sumOfNegativeControls += control.value;
      }
    });
  }
  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }
  previous() {
    this.previousStep.emit();
  }

}