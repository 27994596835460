import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { Otherdsmodel } from '../models/otherds.model';
import { Otherdsmodeluser } from '../models/otherds-user.model';

import { User } from '../models/user.model';
import { CommunService } from '../services/commun';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { OtherdsService } from '../services/otherds.service';
import { OtherdsServiceUser } from '../services/otherds-user.service';

import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2';
import { CarouselService } from '../services/settings';

@Component({
  selector: 'app-add-otherds',
  templateUrl: './add-otherds.component.html',
  styleUrls: ['./add-otherds.component.scss']
})
export class AddOtherdsComponent implements OnInit {
  currentUser: User;
  user:User;
  usersSub: Subscription;
  users: User[]=[];
  filtredusers: User[]=[];
  otherdsform:FormGroup
  ammounts:FormArray
  isLoggedIn=false;
  loading=false;
  errormsg: any;
  otherdss: Otherdsmodel[]=[];
  otherds: Subscription;
  option01Value:any
  otherdsusersub: Subscription;
  otherdsuser: Otherdsmodeluser[]=[];
  otherdspreviousform:FormGroup
  ammounts1:FormArray
  deleting=false;
  updating=false;
  private intervalSub: Subscription;
  countdown=0;
  firsttimer=0;
  submitting: boolean;
  settingsSub: any;
  tarifsclient: any[]=[];
  tarifspecial: any[]=[];
  tarifsbase: any[]=[];
  secondtimer=0;
  constructor(private token: TokenStorageService,private commun: CommunService,private usersservice: UserService,private fb: FormBuilder,private router: Router,private deccompt:DeccomptabiliteService,
    private oth:OtherdsService,private othsettings:OtherdsServiceUser,private settservice:CarouselService) {
    this.otherdsform = this.fb.group({
      ammounts: this.fb.array([ this.createammount() ])
   });
   this.otherdspreviousform = this.fb.group({
    ammounts1: this.fb.array([ this.createammount() ])
 });
   }
// Helper method in your component TypeScript file
isPayeTrue(index: number): boolean {
  const formArray = this.otherdspreviousform.get('ammounts1') as FormArray;
  const payeControl = formArray.at(index).get('paye');
  return payeControl?.value === true;
}

  ngOnInit() {
    this.loading=true
   //verify user loggedin or redirect to login
   this.isLoggedIn = !!this.token.getToken();
   if (this.isLoggedIn) {
     this.currentUser = this.token.getUser();  
   }
   else return (
     this.token.saved=true,
     this.router.navigate(['login']));
     this.usersSub = this.usersservice.users$.subscribe(
      (users) => {
        this.users = users;
        this.filtredusers=this.deccompt.filterByValue(this.users,'desactive')
        this.filtredusers=this.filtredusers.filter((filter) => (filter.usertype === 'Client'&&!filter.desactive.statut))
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.otherds = this.oth.otherdss$.subscribe(
      (otherds) => {
        this.otherdss = otherds;        
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.otherdsusersub = this.othsettings.otherdss$.subscribe(
      (otherds) => {
        this.otherdsuser = otherds;   
        this.otherdsuser.length>0?this.otherdspreviousform = new FormGroup({                
          ammounts1: new FormArray(this.otherdsuser.map(item => {
            const group = this.createammount();
            //@ts-ignore
            group.patchValue(
              {
                id:item._id,
                libelle:item.libelle,
                description:item.description,
                duree:item.duree,
                soumis:item.soumis,
                totalhonoraire:item.totalhonoraire,
                tauxapplique:item.tauxapplique,

                paye:item.paye
              }
            ); 
              return group;
          }))
        }):''     
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errormsg=error.message;
      }
    );
    this.settingsSub = this.settservice.carousels$.subscribe(
      (settings) => {
      //  this.settings = settings; 
       // this.tarifs=settings.filter(p => p.tarifs.length>0)
       // user.prixspecialminute?this.tarifsclient.push(user.prixspecialminute):''
       settings.filter(p => p.tarifs.length>0).forEach(async (item, _index) => {
        //@ts-ignore
          item.tarifs[0].type=='Tarif individuel'?
          this.tarifsclient.push(item.tarifs[0]):
        //@ts-ignore
          item.tarifs[0].type=='Tarif spécial'?
          this.tarifspecial.push(item.tarifs[0]):
          this.tarifsbase.push(item.tarifs[0])
                },
               (error) => {
                 this.loading = false;
                 this.errormsg=error.message;
               }
             );
           /*  this.tarifsclient.length>0?
             (
              this.prixminuteindiv=user.prixspecialminute,
              this.totalhonoraire= Math.round((+this.totaltime * +this.prixminuteindiv) * 1000)/1000,
              console.log(this.totalhonoraire)
             ):*/
//    console.log(this.tarifsclient,tarifspecial,tarifsbase)

//

//
  //  console.log(this.prixminute,this.prixminuteindiv,this.prixminutespecial)   

      }
    )
    this.settservice.getCarouselalldata()
    this.getall()
    this.oth.getOtherdsalldata()
  }
  setThreeNumberDecimal($event) {
    $event.target.value = $event.target.value ? $event.target.value : 0;
    $event.target.value = parseFloat($event.target.value).toFixed(3);
  }
  get ammountControls() {
    return this.otherdsform.get('ammounts')['controls'];
  }
  get ammountControls1() {
    return this.otherdspreviousform.get('ammounts1')['controls'];
  }
  getall() {                                             
    this.usersservice.getAll();                                               
 }
 createammount(): FormGroup {
  return this.fb.group({
    id:'',
    libelle: '', 
    duree: '',
    totalhonoraire: '',
    tauxapplique:'',
    soumis: false,
    paye: false,
    description:'',
    countdown:0
  });
}
addammount(): void {
  this.ammounts = this.otherdsform.get('ammounts') as FormArray;
  this.ammounts.push(this.createammount());
}

removeammount(i: number) {
  this.ammounts = this.otherdsform.get('ammounts') as FormArray;
  this.ammounts.removeAt(i);
}
getotherdsinfo(i:number,lib:string,id='')
{
  this.ammounts = this.otherdsform.get('ammounts') as FormArray;
  
this.oth.getOtherdsdataById(id).then(
  (other:Otherdsmodel[])=>
  {
    let chosenother=other.find(oth=>oth.libelle==lib)
    let calduree=chosenother.duree
this.usersservice.getUserById(this.option01Value).then(
  (user:User)=>{
    let prixminuteindiv=this.gettarifindiv(user._id,Date.now())
    let prixminutespecial=this.gettarifspecial(Date.now(),user)
    let prixminute=this.gettarifbase(Date.now())
    prixminuteindiv?
    this.ammounts.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60) ,
        tauxapplique:prixminuteindiv,

        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminuteindiv) * 1000)/1000,
        soumis:chosenother.soumis
      }
    ):
    prixminutespecial?
    this.ammounts.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60),
        tauxapplique:prixminutespecial,
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminutespecial) * 1000)/1000,
        soumis:chosenother.soumis
      }
    ):
    this.ammounts.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60),
        tauxapplique:prixminute,
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminute) * 1000)/1000,
        soumis:chosenother.soumis
      }
    )
  }
)
  }
)
}
calculatetotalhonoraire(i:number,form:FormArray,id='')
{  
  console.log(form)
this.oth.getOtherdsdataById(id).then(
  (other:Otherdsmodel[])=>
  {
    let chosenother=other.find(oth=>oth.libelle==form.value.at(i).libelle)
    let calduree=form.value.at(i).duree
this.usersservice.getUserById(this.option01Value).then(
  (user:User)=>{
    let prixminuteindiv=this.gettarifindiv(user._id,Date.now())
    let prixminutespecial=this.gettarifspecial(Date.now(),user)
    let prixminute=this.gettarifbase(Date.now())
    prixminuteindiv?
    form.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60) ,
        tauxapplique:prixminuteindiv,
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminuteindiv) * 1000)/1000,
        soumis:chosenother?chosenother.soumis:false
      }
    ):
    prixminutespecial?
    form.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60) ,
        tauxapplique:prixminutespecial,
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminutespecial) * 1000)/1000,
        soumis:chosenother?chosenother.soumis:false
      }
    ):
    form.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60) ,
        tauxapplique:prixminute,
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminute) * 1000)/1000,
        soumis:chosenother?chosenother.soumis:false
      }
    )
  }
)
  }
)
}
removeammount1(i: number,id:string) {
  console.log(id)
  this.deleting=true
  this.ammounts1 = this.otherdspreviousform.get('ammounts1') as FormArray;
  Swal.fire({
    title: 'Veuillez confirmer la suppression!',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirmer',
  }).then((result) => {
    if (result.value) {
      this.ammounts1.removeAt(i); 
      this.othsettings.deleteotherdsdataById(id).then(
        (data)=>
        {
          this.deleting=false
  //        this.reloadPage()
        }
      )     
    }
    else{
      this.deleting=false
    }
  }).catch(() => {
    Swal.fire('opération non aboutie!');
  });  

}
modifyammount1(i: number,id:string) {
  this.updating=true
  this.ammounts1 = this.otherdspreviousform.get('ammounts1') as FormArray;
let  otherds:Otherdsmodeluser = new Otherdsmodeluser()
  otherds=this.ammounts1.value[i]  
  this.othsettings.modifyotherdsById(id,otherds).then(
        (data:any) => {
         this.updating = false;
         Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'élément modifié avec succès!',
          showConfirmButton: false,
          timer: 6000 
        });
        },
        (error) => {
          this.updating = false;            
        }
      )
}
play(i:number)
{
  this.ammounts = this.otherdsform.get('ammounts') as FormArray;
  this.countdown=this.ammounts.value.at(i).countdown
  this.intervalSub=interval(1000).subscribe(value => (this.ammounts.at(i).patchValue(
    {
      countdown:value+this.countdown
    }
  ),this.countdown=this.countdown))
 //this.firsttimer==0?this.firsttimer=Date.now():this.firsttimer=Date.now()-(Date.now()-this.firsttimer) 
 this.firsttimer=Date.now()
}
/*stop(i:number)
{

this.ammounts = this.otherdsform.get('ammounts') as FormArray;
this.intervalSub?this.intervalSub.unsubscribe():'';
this.countdown=0
let calduree=Math.floor((Date.now()-this.firsttimer) / 1000)
this.usersservice.getUserById(this.option01Value).then(
  (user:User)=>{
    let prixminuteindiv=this.gettarifindiv(user._id,Date.now())
    let prixminutespecial=this.gettarifspecial(Date.now(),user)
    let prixminute=this.gettarifbase(Date.now())
    prixminuteindiv?
    this.ammounts.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60),
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminuteindiv) * 1000)/1000
      }
    ):
    prixminutespecial?
    this.ammounts.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60),
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminutespecial) * 1000)/1000
      }
    ):
    this.ammounts.at(i).patchValue(
      {
        duree:Math.floor(+calduree/60),
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminute) * 1000)/1000
      }
    )
  }
)


}*/
pause(i:number)
{

this.ammounts = this.otherdsform.get('ammounts') as FormArray;
this.intervalSub?this.intervalSub.unsubscribe():'';
//this.secondtimer=Date.now();
console.log(this.firsttimer)
let calduree=Math.floor((Date.now()-this.firsttimer) / 1000)+this.countdown
this.countdown=Math.floor((Date.now()-this.firsttimer) / 1000)+this.countdown
console.log(calduree)
this.usersservice.getUserById(this.option01Value).then(
  (user:User)=>{
    let prixminuteindiv=this.gettarifindiv(user._id,Date.now())
    let prixminutespecial=this.gettarifspecial(Date.now(),user)
    let prixminute=this.gettarifbase(Date.now())
    prixminuteindiv?
    this.ammounts.at(i).patchValue(
      {
        countdown:this.countdown,
        duree:Math.floor(+calduree/60),
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminuteindiv) * 1000)/1000
      }
    ):
    prixminutespecial?
    this.ammounts.at(i).patchValue(
      {
        countdown:this.countdown,
        duree:Math.floor(+calduree/60),
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminutespecial) * 1000)/1000
      }
    ):
    this.ammounts.at(i).patchValue(
      {
        countdown:this.countdown,
        duree:Math.floor(+calduree/60),
        totalhonoraire:Math.round((Math.floor(+calduree/60) * +prixminute) * 1000)/1000
      }
    )
  }
)


}
replay(i:number)
{
  this.ammounts = this.otherdsform.get('ammounts') as FormArray;
  this.intervalSub?this.intervalSub.unsubscribe():'';
  this.countdown=0
  this.ammounts.at(i).patchValue(
    {
      countdown:this.countdown,
      duree:this.countdown,
      totalhonoraire:0
    }
  )
}
sort()
{
this.filtredusers=this.filtredusers.sort()
}
async save() {
  this.submitting = true;
  const otherds = new Otherdsmodeluser();
  let ammounts=this.otherdsform.get('ammounts') as FormArray;
  for await (const element of ammounts.value) {
    const otherds:Otherdsmodeluser = new Otherdsmodeluser()
      otherds.libelle=element.libelle
      otherds.description=element.description
      otherds.paye=element.paye
      otherds.userId=this.currentUser.userId
      otherds.clientId=this.option01Value
      otherds.duree=element.duree
      otherds.totalhonoraire=element.totalhonoraire
      otherds.soumis=element.soumis
      otherds.tauxapplique=element.tauxapplique

    await  this.othsettings.create(otherds).then(
        (data:any) => {
         // this.loading = false;
        },
        (error) => {
        //  this.loading = false;
          
        }
      )
  };
  this.submitting=false
 this.reloadPage()
}
getuserotherds(id:any)
{
  this.othsettings.getOtherdsdataByuser(id)


}
gettarifindiv(id:string,date:number):any{
  let prixminuteindiv:any
 // console.log(this.tarifsclient)
  this.tarifsclient.forEach(
    async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(date)
//@ts-ignore
&&new Date(item.fin)>=new Date(date))
{
//console.log(item.user,this.user._id)
//@ts-ignore
if(item.user==id)
{  
//console.log(this.prixminuteindiv)
prixminuteindiv=item.prix
//console.log(this.totalhonoraire)
}
}
    }
  )
  return prixminuteindiv
}
gettarifspecial(date:any,user:User):any{
 // console.log(this.tarifspecial)
let prixminutespecial:any
  this.tarifspecial.forEach(
    async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(date)
//@ts-ignore
&&new Date(item.fin)>=new Date(date))
{
//@ts-ignore
if(item.nature==user.nature||item.nature=='')
{
//@ts-ignore
if(item.natureactivite==user.natureactivite||item.natureactivite=='')
{
//@ts-ignore
if(item.activite==user.activite||item.activite=='')
{
//@ts-ignore
if(item.sousactivite==user.sousactivite||item.sousactivite=='')
{
//@ts-ignore
if(item.impot==user.regimefiscalimpot||item.impot=='')
{
prixminutespecial=item.prix
//console.log(this.totalhonoraire)

}
}
}
}
}
}
    }
  )
  return prixminutespecial
}
gettarifbase(date:any):any{
  //console.log(this.tarifsbase)
  let prixminute:any
  this.tarifsbase.forEach(
    async (item,_index)=>{
//@ts-ignore
if(new Date(item.debut)<=new Date(date)
//@ts-ignore
&&new Date(item.fin)>=new Date(date))
{
prixminute=item.prix
//console.log(this.totalhonoraire,this.totaltime,this.prixminute)
}
    }
  )   
  return prixminute
}
reloadPage(): void {
  
  setTimeout(() => window.location.reload(), 1000);
  
}
}
