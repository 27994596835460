import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ExcelService } from '../services/excel.service';

@Component({
  selector: 'app-export-array',
  templateUrl: './export-array.component.html',
  styleUrls: ['./export-array.component.scss']
})
export class ExportArrayComponent implements OnInit {
  @Input() globalarray: any[]=[]; // Define searchForm as an input variable

  constructor(private excelService:ExcelService) { }

  ngOnInit() {
  }
  exportusersAsXLSX(source:any[],name:string):void {
    this.excelService.exportAsExcel(source, name);
  }
}
