import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Carouselmodel } from '../models/settings';
import { CarouselService } from '../services/settings';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { TokenStorageService } from '../services/token-storage.service';
import { User } from '../models/user.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [
    { provide: CarouselConfig, useValue: { interval: 4000, noPause: false, showIndicators: true } }
 ],
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  carousels: Carouselmodel[];
  carouselsSub: any;
  sortedcarousels: Carouselmodel[];
  noWrapSlides = false;
  showIndicator = true;
  actualites: Carouselmodel[];
  loading: boolean;
  isLoggedIn: boolean;
  currentuser: User;
  videoVisible: boolean = false;
  isMenuOpened: boolean = false;

  toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
    console.log('clicked inside');
    console.log(this.isMenuOpened);
  }

  clickedOutside(): void {
    this.isMenuOpened = false; // Close the menu when clicked outside
    console.log('clicked outside');
    console.log(this.isMenuOpened);
  }

  itemsSectionVisible: boolean = false;

  displaySectionItems() : void {
    this.itemsSectionVisible = !this.itemsSectionVisible;
  }

  sections = [
    { id: 'actualite', name: 'Actualité' },
    { id: 'rappelez-vous', name: 'Rappelez Vous!' },
    { id: 'nos-valeurs', name: 'Nos valeurs' },
    { id: 'qui-sommes-nous', name: 'Qui sommes nous?' },
    { id: 'notre-projet', name: 'Notre projet' },
    { id: 'nos-services', name: 'Nos services' }
  ];
  activeSection: string = '';

  constructor(
    private carousel:CarouselService,private router: Router,private tokenStorage:TokenStorageService,
   
  ) {}
 
  ngOnInit() {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    if (this.isLoggedIn) {
      this.currentuser = this.tokenStorage.getUser();
    }
    this.carouselsSub = this.carousel.carousels$.subscribe(
      (carousels) => {
        this.carousels = carousels; 
        this.actualites=this.carousels.filter(p => p.tarifs.length==0&&p.cours.length==0)
        this.sortedcarousels=this.actualites.sort((a, b) => a.rang - b.rang);
      },
      (error) => {
        
      }
    );
    
      this.carousel.getCarouselalldata();
      this.onScroll();

  }
  
  playVideo() {
    this.videoVisible = true;
  }
  infoassiette(): void {
    if(this.currentuser.regimefiscalimpot=='Forfait D\'assiette')
    {
      Swal.fire({
        title: 'Vous êtes sous le règime forfait d\assiette, veuillez noter que vous n\'êtes pas tenue d\'établir vos fichiers comptables',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      }).then((result) => {
        this.router.navigate(['declare-comptabilite'])
      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });  
    }
    else
    {
      this.router.navigate(['declare-comptabilite'])
    }
  }
  getNavigation(link, id){
      
    this.carousel.getCarouseldataById(id);
    this.router.navigate([link + '/' + id]); 
  }
  voirdetail(link, id)
  {
    if (this.isLoggedIn) 
    {
      this.router.navigate([link + '/' + id]); 
    }
    else 
    {
      this.router.navigate(['login'])
      this.loading=false
    }
    
  }
  reloadPage (){
    let window= document.getElementById('id01')    
  }
  scrollToSection(id: string) {
    document.getElementById(id)!.scrollIntoView({ behavior: 'smooth' });
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    let currentSection = '';
    this.sections.forEach(section => {
      const element = document.getElementById(section.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= 175 && rect.bottom > 175) {
          currentSection = section.id;
        }
      }
    });
    this.activeSection = currentSection;
  }
}
