import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Otherdsmodel } from '../models/otherds.model';
const API_URL_test = 'http://localhost:3003/api/otherds/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/otherds/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class OtherdsService {
    
    
    constructor(private http: HttpClient) { }
    public otherdss: Otherdsmodel[] = [];
    public otherdss$ = new Subject<Otherdsmodel[]>();
    getOtherdsalldata() {
        this.http.get(API_URL_cloud).subscribe(
          (otherdss: Otherdsmodel[]) => {
            if (otherdss) {
              this.otherdss = otherdss;
              this.emitOtherdss();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitOtherdss() {
        this.otherdss$.next(this.otherdss);
      }
      getOtherdsdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(otherds: Otherdsmodel) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, otherds).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      modifyotherdsById(id: string, otherds: Otherdsmodel) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, otherds).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      deleteotherdsdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
