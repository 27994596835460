   
  
  
  
  
  
  
  <form [formGroup]="anxdebForm1" (ngSubmit)="onSubmit1()">
    <h2>  Fiche descriptive - Enregistrement «ANXBEN02 » </h2>
  
    <div>
      <label for="A300">Type enregistrement:</label>
      <input id="A300" formControlName="A300">
      
    </div>
  
    <h3>Identifiant contribuable déclarant</h3>
  
    <div>
      <label for="A001">Matricule Fiscal:</label>
      <input id="A001" formControlName="A001">
      <div *ngIf="anxdebForm1.get('A001').invalid && anxdebForm1.get('A001').touched">
        <p *ngIf="anxdebForm1.get('A001').hasError('required')">Matricule Fiscal est obligatoire.</p>
        <p *ngIf="anxdebForm1.get('A001').hasError('pattern')">Matricule Fiscal doit avoir 7 nombres.</p>
      </div>
    </div>
  
    <div>
      <label for="A002">Clé du matricule fiscal:</label>
      <input id="A002" formControlName="A002">
      <div *ngIf="anxdebForm1.get('A002').invalid && anxdebForm1.get('A002').touched">
        <p *ngIf="anxdebForm1.get('A002').hasError('required')">Clé du matricule fiscal est obligatoire.</p>
        <p *ngIf="anxdebForm1.get('A002').hasError('pattern')">Clé du matricule fiscal doit comporter un seul caractère.</p>
      </div>
    </div>
  
    <div>
      <label for="A003">Catégorie contribuable:</label>
      <input id="A003" formControlName="A003">
      <div *ngIf="anxdebForm1.get('A003').invalid && anxdebForm1.get('A003').touched">
        <p *ngIf="anxdebForm1.get('A003').hasError('required')">Catégorie contribuable est obligatoire.</p>
        <p *ngIf="anxdebForm1.get('A003').hasError('pattern')">Catégorie contribuable doit avoir un seul caractère différent de 'E'.</p>
      </div>
    </div>
  
    <div>
      <label for="A004">Numéro de l’établissement secondaire du contribuable:</label>
      <input id="A004" formControlName="A004">
      <div *ngIf="anxdebForm1.get('A004').invalid && anxdebForm1.get('A004').touched">
        <p *ngIf="anxdebForm1.get('A004').hasError('required')">Numéro de l’établissement secondaire du contribuable est obligatoire.</p>
        <p *ngIf="anxdebForm1.get('A004').hasError('pattern')">Numéro de l’établissement secondaire du contribuable doit être de 3 nombres: 000.</p>
      </div>
    </div>
  
    <div>
      <label for="A305">Exercice:</label>
      <input id="A305" formControlName="A305">
      <div *ngIf="anxdebForm1.get('A305').invalid && anxdebForm1.get('A305').touched">
        <p *ngIf="anxdebForm1.get('A305').hasError('pattern')">Exercice doit comporter 4 caractères.</p>
      </div>
    </div>
  
    <div>
      <label for="A306">Numéro d’ordre</label>
      <input id="A306" formControlName="A306">
      <div *ngIf="anxdebForm1.get('A306').invalid && anxdebForm1.get('A306').touched">
        <p *ngIf="anxdebForm1.get('A306').hasError('required')">Numéro d’ordre est obligatoire.</p>
        <p *ngIf="anxdebForm1.get('A306').hasError('pattern')">Numéro d’ordre doit comporter 6 nombres.</p>
      </div>
    </div>
  
    <div>
      <label for="A307">Type identifiant du bénéficiaire</label>
      <input id="A307" formControlName="A307">
      <div *ngIf="anxdebForm1.get('A307').invalid && anxdebForm1.get('A307').touched">
        <pre *ngIf="anxdebForm1.get('A307').hasError('pattern')">
            Type identifiant du bénéficiaire
            1 : Matricule Fiscal,
            2 : Numéro de la carte d’identité
            nationale,
            3 : numéro de la carte de séjour
            pour les étrangers,
            4 : Identifiant des personnes non
            domiciliées ni établies en Tunisie.</pre>
        <p *ngIf="anxdebForm1.get('A307').hasError('required')"> Type identifiant du bénéficiaire  est obligatoire.</p>
      </div>
    </div>
  
    <h3>Identifiant bénéficiaire</h3>
  
    <div>
      <label for="A308"> Matricule fiscal ou Numéro de la
        carte d’identité nationale </label>
      <input id="A308" formControlName="A308">
      <div *ngIf="anxdebForm1.get('A308').invalid && anxdebForm1.get('A308').touched">
        <p *ngIf="anxdebForm1.get('A308').hasError('pattern')">Numéro de la carte d’identité nationale ou Numéro de la carte de séjour pour les étrangers doit comporter 13 caractères.</p>
      </div>
    </div>
  
    <div>
      <label for="A309"> Nom et prénom ou raison sociale
        du bénéficiaire </label>
      <input id="A309" formControlName="A309">
      <div *ngIf="anxdebForm1.get('A309').invalid && anxdebForm1.get('A309').touched">
        <p *ngIf="anxdebForm1.get('A309').hasError('pattern')">Nom et prénom du bénéficiaire doit comporter 40 caractères.</p>
      </div>
    </div>
  
    <div>
      <label for="A310"> Activité du bénéficiaire  </label>
      <input id="A310" formControlName="A310">
      <div *ngIf="anxdebForm1.get('A310').invalid && anxdebForm1.get('A310').touched">
        <p *ngIf="anxdebForm1.get('A310').hasError('pattern')"> Activité du bénéficiaire   doit comporter 40 caractères.</p>
      </div>
    </div>
  
    <div>
      <label for="A311">Dernière adresse du bénéficiaire</label>
      <input id="A311" formControlName="A311">
      <div *ngIf="anxdebForm1.get('A311').invalid && anxdebForm1.get('A311').touched">
        <p *ngIf="anxdebForm1.get('A311').hasError('pattern')">Dernière adresse du bénéficiaire doit comporter 120 caractères.</p>
      </div>
    </div>
  
    <div>
      <label for="A312"> Intérêts des comptes spéciaux
        d’épargne ouverts auprès des
        banques et de la CENT
         </label>
      <input id="A312" formControlName="A312">
      <div *ngIf="anxdebForm1.get('A312').invalid && anxdebForm1.get('A312').touched">
        <pre *ngIf="anxdebForm1.get('A312').hasError('pattern')">
            Intérêts des comptes spéciaux
            d’épargne ouverts auprès des
            banques et de la CENT doit comporter 15 nbres 
            
            </pre>
      </div>
    </div>
  
    <div>
      <label for="A313">  Intérêts des autres capitaux
        mobiliers </label>
      <input id="A313" formControlName="A313">
      <div *ngIf="anxdebForm1.get('A313').invalid && anxdebForm1.get('A313').touched">
        <p *ngIf="anxdebForm1.get('A313').hasError('pattern')"> Intérêts des autres capitaux
            mobiliers doit comporter 15 nombres.</p>
      </div>
    </div>
  
    <div>
      <label for="A314"> Intérêts des prêts payés aux
        établissements bancaires non
        établis en Tunisie </label>
      <input id="A314" formControlName="A314">
      <div *ngIf="anxdebForm1.get('A314').invalid && anxdebForm1.get('A314').touched">
        <p *ngIf="anxdebForm1.get('A314').hasError('pattern')">  Intérêts des prêts payés aux
            établissements bancaires non
            établis en Tunisie doit comporter 15 nombres .</p>
      </div>
    </div>
  
    <div>
        <label for="A315">  Montant des retenues opérées</label>
        <input id="A315" formControlName="A315">
        <div *ngIf="anxdebForm1.get('A315').invalid &&anxdebForm1.get('A315').touched">
          <p *ngIf="anxdebForm1.get('A315').hasError('pattern')">  Montant des retenues opérées  doit comporter 15 nombres </p>
        </div>
      </div>
      <div>
        <label for="A316">  Montant net servi 
          </label>
        <input id="A316" formControlName="A316">
        <div *ngIf="anxdebForm1.get('A316').invalid &&anxdebForm1.get('A316').touched">
          <p *ngIf="anxdebForm1.get('A316').hasError('pattern')">   Montant net servi   doit comporter 15 nombres 
             </p>
        </div>
      </div>
    
    <div>
      <label for="A317">  Plus-value immobilière (prix de
        l’immeuble déclaré dans l’acte)
        </label>
      <input id="A317" formControlName="A317">
      <div *ngIf="anxdebForm1.get('A317').invalid &&anxdebForm1.get('A317').touched">
        <p *ngIf="anxdebForm1.get('A317').hasError('pattern')">    Zone réservée doit comporter 86 caractères 
           </p>
      </div>
    </div>
    
                  <button type="submit">
                    {{ isEditMode ? 'Valider' : 'Modifier' }}
                  </button>
     
    </form>
    