import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tcl3',
  templateUrl: './tcl3.component.html',
  styleUrls: ['./tcl3.component.scss']
})
export class TCL3Component implements OnInit,OnDestroy{
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  tcl3form: FormGroup;
  formValueChangesSubscription: Subscription;
  sumOfPositiveControls: number=0;
  sumOfNegativeControls: number=0;
  total:number=0

  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.tcl3form.reset();
  }
  ngOnInit(): void {
     // Initialize forms
     const savedFormState = this.formStateService.gettcl3FormState();
     if (savedFormState) {
       this.tcl3form = savedFormState;
         const positiveKeys = ['taxDue_1','taxDue_2'];
        const negativeKeys = [
        ];
         Object.keys(this.tcl3form.controls).forEach(key => {
           const control = this.tcl3form.get(key);
           if (control && positiveKeys.includes(key)) {
             this.sumOfPositiveControls += control.value;
           } else if (control && negativeKeys.includes(key)) {
             this.sumOfNegativeControls += control.value;
           }
         });
         this.total = this.sumOfPositiveControls - this.sumOfNegativeControls;
     }
     else
     {
      this.tcl3form = this.fb.group({
        taxDue_1: [''],
        assiette_1: [''],
        taxDue_2: [''],
        assiette_2: [''],
        taxDue_industrial_commercial_professional: [''],
      taxDue_previous_months: [''],
      total: [''],
      annualTaxDue: [''],
      minimumTaxDue: [''],
      remainingTaxDue: ['']
      });
     }

     this.setupAutoCalculations();

// Subscribe to value changes of all form controls
    // Subscribe to value changes of all form controls
    this.formValueChangesSubscription = this.tcl3form.valueChanges.subscribe(() => {
      this.updateSumAndTotal();
    });
  }
  ngOnDestroy(): void {
        // Save the form state when the component is destroyed
        this.formStateService.settcl3FormState(this.tcl3form);
    // Unsubscribe from value changes to prevent memory leaks
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
  //  this.goto(1)
  }
  validate(): void {
    // Save the form state when the component is destroyed
    this.formStateService.settcl3FormState(this.tcl3form);
// Unsubscribe from value changes to prevent memory leaks
if (this.formValueChangesSubscription) {
  this.formValueChangesSubscription.unsubscribe();
}
this.goto(1)
}
  setupAutoCalculations(): void {
    const keysWithValueChanges = [
      { tvaDueKey: 'assiette_1', montantKey: 'taxDue_1', multiplier: 0.02 },
      { tvaDueKey: 'assiette_2', montantKey: 'taxDue_2', multiplier: 0.01 },
      ];
  
    keysWithValueChanges.forEach(item => {
      this.tcl3form.get(item.tvaDueKey)?.valueChanges.subscribe(value => {
        const montantControlKey = item.montantKey;
        const tvaDueValue = this.tcl3form.get(item.tvaDueKey)?.value;
        const newValue = Math.floor((tvaDueValue * item.multiplier) * 1000) / 1000;
        this.tcl3form.get(montantControlKey)?.setValue(newValue, { emitEvent: false });
      });
    });
  }
  
  updateSumAndTotal(): void {
    this.sumOfPositiveControls = 0
    this.sumOfNegativeControls = 0
    const positiveKeys = ['taxDue_1','taxDue_2'];
    const negativeKeys = [
    ];
         Object.keys(this.tcl3form.controls).forEach(key => {
           const control = this.tcl3form.get(key);
           if (control && positiveKeys.includes(key)) {
             this.sumOfPositiveControls += control.value;
           } else if (control && negativeKeys.includes(key)) {
             this.sumOfNegativeControls += control.value;
           }
           this.total = this.sumOfPositiveControls - this.sumOfNegativeControls;
         });
  }
  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }
  previous() {
    this.previousStep.emit();
  }

}
