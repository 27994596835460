import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FactureProformaComponent } from './facture-proforma/facture-proforma.component';
import { InvoiceGeneratorComponent } from './invoice-generator/invoice-generator.component';

const routes: Routes = [
  { path: 'facture-proforma/:documentType', component: FactureProformaComponent },
  { path: 'invoice-generator', component: InvoiceGeneratorComponent },
  // Add any other routes you need here
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FacturationRoutingModule { }
