import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    return this.numberToWords(value);
  }

  private numberToWords(num: number): string {
    const units = ["", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
    const teens = ["dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
    const tens = ["", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"];
    const thousands = ["", "mille", "million", "milliard", "billion"];

    if (num === 0) return "zéro";

    let words = "";

    const getWords = (n: number, index: number): string => {
      let word = "";

      if (n > 99) {
        word += units[Math.floor(n / 100)] + " cent ";
        n %= 100;
      }

      if (n > 19) {
        word += tens[Math.floor(n / 10)];
        if (n % 10 > 0) word += "-" + units[n % 10];
      } else if (n > 9) {
        word += teens[n - 10];
      } else {
        word += units[n];
      }

      return word.trim() + " " + thousands[index];
    }

    let index = 0;

    while (num > 0) {
      let part = num % 1000;
      if (part > 0) words = getWords(part, index) + " " + words;
      num = Math.floor(num / 1000);
      index++;
    }

    return words.trim();
  }
}
