 







<form [formGroup]="anxdebForm" (ngSubmit)="onSubmit()">
    <h2>  Fiche descriptive - Enregistrement « ANXDEB00 » </h2>
    
    <div>
      <label for="E300">Type enregistrement:</label>
      <input id="E300" formControlName="E300">
      <div *ngIf="anxdebForm.get('E300').invalid && anxdebForm.get('E300').touched">
        <p *ngIf="anxdebForm.get('E300').hasError('required')">Type enregistrement est obligatoire </p>
        <p *ngIf="anxdebForm.get('E300').hasError('invalidValue')">Valeur de Type enregistrement invalide .</p>
      </div>
    </div>
  
    <h3>Identifiant contribuable déclarant</h3>
    
    <div>
      <label for="E001">Matricule Fiscal:</label>
      <input id="E001" formControlName="E001">
      <div *ngIf="anxdebForm.get('E001').invalid && anxdebForm.get('E001').touched">
        <p *ngIf="anxdebForm.get('E001').hasError('required')">Matricule Fiscal est obligatoire .</p>
        <p *ngIf="anxdebForm.get('E001').hasError('pattern')">Matricule Fiscal doit comporter 7 nombres </p>
      </div>
    </div>
  
    <div>
      <label for="E002">Clé du matricule fiscal:</label>
      <input id="E002" formControlName="E002">
      <div *ngIf="anxdebForm.get('E002').invalid && anxdebForm.get('E002').touched">
        <p *ngIf="anxdebForm.get('E002').hasError('required')">Clé du matricule fiscal est obligatoire </p>
        <p *ngIf="anxdebForm.get('E002').hasError('pattern')">Clé du matricule fiscal doit etre un seul caractère </p>
      </div>
    </div>
  
    <div>
      <label for="E003">Catégorie contribuable:</label>
      <input id="E003" formControlName="E003">
      <div *ngIf="anxdebForm.get('E003').invalid && anxdebForm.get('E003').touched">
        <p *ngIf="anxdebForm.get('E003').hasError('required')">Catégorie contribuable est obligatoire </p>
        <p *ngIf="anxdebForm.get('E003').hasError('pattern')">Catégorie contribuable doit etre un seul caractère et différente de 'E'</p>
      </div>
    </div>
  
    <div>
      <label for="E004">Numéro de l’établissement secondaire du contribuable:</label>
      <input id="E004" formControlName="E004">
      <div *ngIf="anxdebForm.get('E004').invalid && anxdebForm.get('E004').touched">
        <p *ngIf="anxdebForm.get('E004').hasError('required')">Numéro de l’établissement secondaire du contribuable est obligatoire </p>
        <p *ngIf="anxdebForm.get('E004').hasError('pattern')">Numéro de l’établissement secondaire du contribuable doit etre de valeur 000 </p>
      </div>
    </div>
  
    <div>
      <label for="E305">Exercice:</label>
      <input id="E305" formControlName="E305">
      <div *ngIf="anxdebForm.get('E305').invalid && anxdebForm.get('E305').touched">
        <p *ngIf="anxdebForm.get('E305').hasError('pattern')">Exercice doit comporter 4 caractères </p>
      </div>
    </div>
  
    <div>
      <label for="E306">Type de document:</label>
      <input id="E306" formControlName="E306">
      <div *ngIf="anxdebForm.get('E306').invalid && anxdebForm.get('E306').touched">
        <p *ngIf="anxdebForm.get('E306').hasError('required')">Type de document est obligatoire </p>
        <p *ngIf="anxdebForm.get('E306').hasError('pattern')">Type de document doit etre de valeur An3</p>
      </div>
    </div>
  
    <div>
      <label for="E307">Code acte:</label>
      <input id="E307" formControlName="E307">
      <div *ngIf="anxdebForm.get('E307').invalid && anxdebForm.get('E307').touched">
        <pre *ngIf="anxdebForm.get('E307').hasError('pattern')">
valeur valide : 
0 : Spontané,
1 : Régularisation.
2 : redressement
        </pre>
        <p *ngIf="anxdebForm.get('E307').hasError('required')">Code acte est obligatoire </p>
      </div>
    </div>
  
    <div>
      <label for="E308">Nombre total de bénéficiaires:</label>
      <input id="E308" formControlName="E308">
      <div *ngIf="anxdebForm.get('E308').invalid && anxdebForm.get('E308').touched">
        <p *ngIf="anxdebForm.get('E308').hasError('pattern')"> Nombre total de bénéficiaires doit comporter 6 nombres </p>
      </div>
    </div>
  
    <div>
      <label for="E309"> Nom et prénom ou Raison sociale
        contribuable déclarant</label>
      <input id="E309" formControlName="E309">
      <div *ngIf="anxdebForm.get('E309').invalid && anxdebForm.get('E309').touched">
        <p *ngIf="anxdebForm.get('E309').hasError('pattern')">Nom et prénom ou Raison sociale contribuable déclarant doit comporter 40 caractères </p>
      </div>
    </div>
  
    <div>
      <label for="E310">Activité contribuable déclarant:</label>
      <input id="E310" formControlName="E310">
      <div *ngIf="anxdebForm.get('E310').invalid && anxdebForm.get('E310').touched">
        <p *ngIf="anxdebForm.get('E310').hasError('pattern')">Activité contribuable déclarant doit comporter 40 caractères </p>
      </div>
    </div>
  
    <h3> Dernière adresse contribuable
        déclarant </h3>
  
    <div>
      <label for="E311">Ville:</label>
      <input id="E311" formControlName="E311">
      <div *ngIf="anxdebForm.get('E311').invalid && anxdebForm.get('E311').touched">
        <p *ngIf="anxdebForm.get('E311').hasError('pattern')">Ville doit comporter 40 caractères .</p>
      </div>
    </div>
  
    <div>
      <label for="E312">Rue:</label>
      <input id="E312" formControlName="E312">
      <div *ngIf="anxdebForm.get('E312').invalid && anxdebForm.get('E312').touched">
        <p *ngIf="anxdebForm.get('E312').hasError('pattern')">Rue doit comporter 72 caractères </p>
      </div>
    </div>
  
    <div>
      <label for="E313">Numéro:</label>
      <input id="E313" formControlName="E313">
      <div *ngIf="anxdebForm.get('E313').invalid && anxdebForm.get('E313').touched">
        <p *ngIf="anxdebForm.get('E313').hasError('pattern')">Numéro doit comporter 4 nombres </p>
      </div>
    </div>
  
    <div>
      <label for="E314">Code postal:</label>
      <input id="E314" formControlName="E314">
      <div *ngIf="anxdebForm.get('E314').invalid && anxdebForm.get('E314').touched">
        <p *ngIf="anxdebForm.get('E314').hasError('pattern')">Code postal doit comporter 4 nombres </p>
      </div>
    </div>
  
    <div>
      <label for="E315">Zone réservée:</label>
      <input id="E315" formControlName="E315">
      <div *ngIf="anxdebForm.get('E315').invalid && anxdebForm.get('E315').touched">
        <p *ngIf="anxdebForm.get('E315').hasError('pattern')">Zone réservée must be 171 characters long.</p>
      </div>
    </div>
  
    <button type="submit">{{ isEditMode ? 'Valider' : 'Modifier' }}</button>
    
  </form>
  <app-anx3p2></app-anx3p2>
  <app-anx3p3></app-anx3p3>
  <a [routerLink]="['/dec-employeur/anx4p1']">
    <img 
      src="assets/dec.png"
      class="dec"
      width="6vw"
      height="8vh"
    />
  </a>
  
  <a [routerLink]="['/dec-employeur/anx2p3']">
    <img 
      src="assets/dec-left.png"
      class="dec-left"
      width="6vw"
      height="8vh"
    />
  </a>
  