import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supervisor-board',
  templateUrl: './supervisor-board.component.html',
  styleUrls: ['./supervisor-board.component.scss']
})
export class SupervisorBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
