import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DescFiscMensDirectModuleRoutingModule } from './desc-fisc-mens-direct-module-routing.module';
import { PageGardeComponent } from './dec-fisc-direct-compo/page-garde/page-garde.component';
import { TaxWizardComponentComponent } from './dec-fisc-direct-compo/tax-wizard-component/tax-wizard-component.component';
import { PageGarde2Component } from './dec-fisc-direct-compo/page-garde2/page-garde2.component';
import { RSComponent } from './dec-fisc-direct-compo/rs/rs.component';
import { TFPCompComponent } from './dec-fisc-direct-compo/tfp-comp/tfp-comp.component';
import { FOPROLOSCOMPOComponent } from './dec-fisc-direct-compo/foprolos-compo/foprolos-compo.component';
import { TVA1Component } from './dec-fisc-direct-compo/tva1/tva1.component';
import { TVA2Component } from './dec-fisc-direct-compo/tva2/tva2.component';
import { DTCOMPComponent } from './dec-fisc-direct-compo/dt-comp/dt-comp.component';
import { TCL1Component } from './dec-fisc-direct-compo/tcl1/tcl1.component';
import { TCL2Component } from './dec-fisc-direct-compo/tcl2/tcl2.component';
import { TCL3Component } from './dec-fisc-direct-compo/tcl3/tcl3.component';
// Angular Material modules
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';

//modules
import { SharedmoduleModule } from './sharedmodule/sharedmodule.module';


@NgModule({
  declarations: [
    PageGardeComponent,
    TaxWizardComponentComponent,
    PageGarde2Component,
    RSComponent,
    TFPCompComponent,
    FOPROLOSCOMPOComponent,
    TVA1Component,
    TVA2Component,
    DTCOMPComponent,
    TCL1Component,
    TCL2Component,
    TCL3Component
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatBadgeModule,
    MatExpansionModule,MatSnackBarModule,
    MatSlideToggleModule,MatCheckboxModule,MatDatepickerModule,MatFormFieldModule,MatNativeDateModule,MatGridListModule,MatSidenavModule,MatListModule,MatPaginatorModule,
    DescFiscMensDirectModuleRoutingModule,
    SharedmoduleModule
  ]
  })

export class DescFiscMensDirectModuleModule { }
