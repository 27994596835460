import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const input = event.target.value.toUpperCase();
    this.ngControl.control?.setValue(input);
  }
}
