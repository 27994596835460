export class Contact {
    type:string;
    userId: string;
    email: string;
    emailenvoyea: string;
    _id:string;
    ficheUrl: string;
    firstname:string;
    lastname:string;
    mobile:String;
    adresse:string;
    description:string;
    affecte:string;
    dateaffectation:string;
    created:Date;
    dateouverturedossier:number
    updated: Date
    statutadmin:string[]
    statutcollab:string[] 
    typeemail:string
    up:string;
    middle:string;
    bottom:string;

    }