
import { AfterViewInit,ChangeDetectionStrategy, Renderer2, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { concat, Subscription } from 'rxjs';
import { User } from '../models/user.model';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { Userdeleted } from '../models/user-deleted.model';
import { Router } from '@angular/router';
import { Condidate } from '../models/condidate.model';
import { Contact } from '../models/contact.model';
import { Proformatinvoicemodel } from '../models/proformatinvoice.model';
import { invoicemodel } from '../models/invoice.model';
import { Usedressourcemodel } from '../models/usedressource.model';
import { Location } from '@angular/common';

import { CondidateService } from '../services/condidate.service';
import { ContactService } from '../services/contact.service';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { FormGroup, FormBuilder } from '@angular/forms';
import {ExcelService} from '../services/excel.service';
import { Sort } from '../_helpers/sort';
import { CommunService } from '../services/commun';
import Swal from 'sweetalert2';
import { OtherdsServiceUser } from '../services/otherds-user.service';
import { Otherdsmodeluser } from '../models/otherds-user.model';
import { ProformatinvoiceService } from '../services/proformatinvoice.service';
import { InvoiceService } from '../services/invoice.service';
import { UsedressourceService } from '../services/usedressource.service';
import { Recouvrementnotif } from '../models/notification-recouvrement.model';
import { Notificationrec } from '../services/notification-recouvrement.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-admin-board',
  templateUrl: './admin-board.component.html',
  styleUrls: ['./admin-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminBoardComponent implements OnInit,OnDestroy  {
  memoCache = new Map<string, any>();
  public searchForm: FormGroup;
  public loading: boolean;
  public users: User[] = [];
  public users2: User[]= [];
  public users3: User[]= [];
  public filtredusers: User[] = [];
  public usedressources: Usedressourcemodel[] = [];
  public paginatedusedressources: Usedressourcemodel[] = [];
  public decfiscmenss: Decfiscmens[] = [];
  public deccomptabilites: Deccomptabilite[] = [];
  public usersdeleted: Userdeleted[] = [];
  public condidates: Condidate[] = [];
  public contacts: Contact[] = [];
  private condidatesSub: Subscription;
  private contactsSub: Subscription;
  private usersSub: Subscription;
  private usersdeletedSub: Subscription;
  private decfiscmenssSub: Subscription;
  private deccomptabilitesSub: Subscription;
  prenom:string;
  nom:string
  errormsg:string;
  firstname:string;
  lastname:string;
  statut:string;
  email:string;
  date:Date;
  firstnamedecomptabilite:string
  lastnamedeccomptabilite:string
  filtredusers2: User[] = [];
  filtredusers3: User[] = [];
  prenomfisc: string
  nomfisc: string
  clientactif=false
  clientbloque=false
  clientsupptemporairement=false
  collaborateurs=false
  consultants=false
  candidat=false
  decfiscmensvalide=false
  decfiscmensnonvalide=false
  deccomptabilitevalide=false
  deccomptabilitenonvalide=false
  candidaturevalide=false
  candidaturenonvalide=false
  reclamationtraite=false
  reclamationnontraite=false
  clienttotal=0;
  utilconnecte=0;
  ca=0;
  cb=0;
  coll=0;
  cons=0;
  decfiscvali=0;
  decfiscnonvali=0;
  deccomptvalid=0;
  deccompnonval=0;
  del=0;
  condval=0;
  condnonal=0;
  contval=0;
  contnonval=0;
  condida=0;
  dossdecfiscencours=0;
  dossdeccompencours=0;
  dosscandencours=0;
  dosscontactencours=0;
  dossencours: any[]=[];
  showdossencours: boolean;
  dossencours1: Decfiscmens[];
  dossencours2: Deccomptabilite[];
  dossencours3: Condidate[];
  dossencours4: Contact[];
  showdosspasencoreaffecte: boolean;
  showdosspasencorevalide: boolean;
  dossdecfiscnonaffecte=0;
  dossdeccompnonaffecte=0;
  dosscandnonaffecte=0;
  dosscontactnonaffecte=0;
  dossnonaffecte1: Decfiscmens[];
  dossnonaffecte2: Deccomptabilite[];
  dossnonaffecte3: Condidate[];
  dossnonaffecte4: Contact[];
  dossnonaffecte: any[]=[];
  dossdecfiscpasencorevalide=0;
  dossdeccomppasencorevalide=0;
  dosscandpasencorevalide=0;
  dosscontactpasencorevalide=0;
  dosspasencorevalide1: Decfiscmens[];
  dosspasencorevalide2: Deccomptabilite[];
  dosspasencorevalide3: Condidate[];
  dosspasencorevalide4: Contact[];
  dosspasencorevalide: any[]=[];
  prenomaffecte: string;
  nomaffecte: string;
  sorteddossencours: any[]=[]
  paginateddossencours: any[] = [];
  sorteddossnonaffecte: any[]=[];
  paginateddosspasencoreaffecte: any[] = [];
  sorteddosspasencorevalide: any[]=[];
  paginateddosspasencorevalide: any[] = [];
  pageSize = 5;
  currentPage = 0;
  decfiscmens: Decfiscmens;
  deccomptabilite: Deccomptabilite;
  condidate: Condidate;
  contact: Contact;
  dossencoursvalide: any[]=[];
  dossencoursnonvalide: any[]=[];
  showdallusers: boolean;
  showconnected: boolean;
  decfiscmensvalidecopie: boolean;
  deccomptabilitevalidecopie: boolean;
  dossencoursvalidecopie: any[]=[];
  decfiscvalicopie=0;
  deccomptvalidcopie=0;
  showdeccomptabilite=false;
  allotherdsSub: Subscription;
  otherdss: Otherdsmodeluser[]=[];
  alldeccomptabSub: Subscription;
  dsdeccomptabilites: Deccomptabilite[]=[];
  alldecfiscmensSub: Subscription;
  dsdecfiscmenss: Decfiscmens[]=[];
  payedotherdss: Otherdsmodeluser[]=[];
  nonpayedotherdss: Otherdsmodeluser[]=[];
  payedsdeccomptabilites: Deccomptabilite[]=[];
  nonpayedsdeccomptabilites: Deccomptabilite[]=[];
  payedsdecfiscmenss: Decfiscmens[]=[];
  nonpayedsdecfiscmenss: Decfiscmens[]=[];
  alldssusernonpayewindow=false;
  alldssuserpayewindow=false;
  submitting=false;
  marquepayedotherdss: Otherdsmodeluser[];
  marquepayedsdeccomptabilites: Deccomptabilite[];
  marquepayedsdecfiscmenss: Decfiscmens[];
  allproformatinvoiceSub: Subscription;
  allproformatinvoice: Proformatinvoicemodel[]=[];
  allproformatarchivatedinvoice: Proformatinvoicemodel[]=[];

  allinvoiceSub: Subscription;
  allinvoice: invoicemodel[]=[];
  allarchivatedinvoice: invoicemodel[]=[];

  allinvoicewindow=false;
  alldssusermarquepayewindow=false;
  allproformatwindow=false;
  usedressourcewindow=false;
  showmodulerecouvrement=false
usedreslength=0
  prenomreq: any;
  nomreq: any;
  usedressub: Subscription;
  displaydsnonpaye='none';
  displaynotifrec='none';
  detailuserid: string;
  detaildays: number;
  alldsbyusernonpaye: any[]=[];
  allnotifrecs: any[]=[];
  usernoteid: string;
  notifbyusersSub: Subscription;
  notificationrecs: Recouvrementnotif[]=[];
  currentproid: any;
  @ViewChild(CdkVirtualScrollViewport, {static: false})
  public viewPort: CdkVirtualScrollViewport;
  raisonsociale: string;
  raisonsocialefisc: string;
  displaysearchresults= 'none';
  filtredusersarray: any[]=[];
  displaysearchresultsalldsnonpaye='none';
  filtredalldsnonpayearray: any[]=[];
  showrecouvrementdetails=false;
  sendingnotif=false;
  allproformatarchivatedinvoiceSub: Subscription;
  allarchivatedinvoiceSub: Subscription;
  allproformatarchivewindow: boolean=false;
  allinvoicearchivewindow: boolean=false;
  public get inverseOfTranslation(): string {
    if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
      return "-0px";
    }
    let offset = this.viewPort["_renderedContentOffset"];
    return `-${offset}px`;
  }
  isMenuOpen = false;
  
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }
  constructor(private formBuilder: FormBuilder,
              private UserService: UserService,
              private cond:CondidateService,
              private cont:ContactService,private commun: CommunService,
              private dec:DecfiscmensService,private proinv:ProformatinvoiceService,private invo:InvoiceService,
              private deccompt:DeccomptabiliteService,private location: Location,
              private router: Router,private otheruser: OtherdsServiceUser,private usedres: UsedressourceService,
              private excelService:ExcelService,private notificationrec:Notificationrec,
              private cdr: ChangeDetectorRef) { }
              ngOnInit() {
                this.searchForm = this.formBuilder.group({
              
                  lastname: [null,],
                  firstname: [null,],
                  email: [null,],
                  date: [null,],
                
                })
                this.contactsSub = this.cont.contactreqs$.subscribe(
                  (contacts) => {
                    this.contacts = contacts.filter(con=>!con.typeemail);
                    
                    this.loading = false;
                  },
                  (error) => {
                    this.loading = false;
                    
                    this.errormsg = error.message;
                  }
                );

                this.condidatesSub = this.cond.condidates$.subscribe(
                  (condidates) => {
                    this.condidates = condidates;
                    
                    this.loading = false;
                  },
                  (error) => {
                    this.loading = false;
                    
                    this.errormsg=error.message;
                  }
                );
                this.notifbyusersSub = this.notificationrec.notificationrecs$.subscribe(
                  (notificationrecs) => {
                    this.notificationrecs = notificationrecs;
                    this.loading = false;
                    this.cdr.markForCheck()                                                    
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.usersSub = this.UserService.users$.subscribe(
                  (users) => {
                    this.users = users;
                    this.users2= users
                    this.users3= users
                    this.loading = false;
                    this.cdr.markForCheck()                                                    
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.decfiscmenssSub = this.dec.decfiscmenss$.subscribe(
                  (decfiscmenss) => {

                    this.decfiscmenss = decfiscmenss;
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.usedressub = this.usedres.usedressourcess$.subscribe(
                  (usedress) => {

                    this.usedressources = usedress;
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.deccomptabilitesSub = this.deccompt.deccomptabilites$.subscribe(
                  (deccomptabilites) => {
                    this.deccomptabilites = deccomptabilites;
                    this.showdeccomptabilite=true
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.allotherdsSub = this.otheruser.otherdss$.subscribe(
                  (otherdss) => {
                    this.otherdss = otherdss.filter(e=>new Date(e.created) >=new Date('11.30.2023'));
                    this.payedotherdss= this.otherdss.filter(e=>e.paye==true&&!e.marquepaye)
                    this.marquepayedotherdss= this.otherdss.filter(e=>e.paye==true&&e.marquepaye==true)
                    this.nonpayedotherdss= this.otherdss.filter(e=>e.paye==false)
                    this.loading = false;
                    this.showrecouvrementdetails=true
                    this.cdr.markForCheck()                                                    

            //        this.deccomptabilitenumber=this.deccomptabilites.length
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.alldeccomptabSub = this.deccompt.dsdeccomptabilites$.subscribe(
                  (deccomptabilities) => {
                    this.dsdeccomptabilites = deccomptabilities.filter(e=>new Date(e.created)>=new Date('11.30.2023'));
                    this.payedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==true&&!e.marquepaye)
                    this.marquepayedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==true&&e.marquepaye==true)
                    this.nonpayedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==false)
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

            //        this.deccomptabilitenumber=this.deccomptabilites.length
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.alldecfiscmensSub = this.dec.dsdecfiscmenss$.subscribe(
                  (decfiscmenss) => {
                    this.dsdecfiscmenss = decfiscmenss.filter(e=>new Date(e.created)>=new Date('11.30.2023')&&e.origine!='généré automatiquement');
                    this.payedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==true&&!e.marquepaye)
                    this.marquepayedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==true&&e.marquepaye==true)
                    this.nonpayedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==false)
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

            //        this.deccomptabilitenumber=this.deccomptabilites.length
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.allproformatarchivatedinvoiceSub = this.proinv.proformatarchivatedinvoices$.subscribe(
                  (proinvoices) => {
                    this.allproformatarchivatedinvoice = proinvoices 
                    this.loading = false;
                    
                    this.cdr.markForCheck()                                                    

            //        this.deccomptabilitenumber=this.deccomptabilites.length
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.allproformatinvoiceSub = this.proinv.proformatinvoices$.subscribe(
                  (proinvoices) => {
                    this.allproformatinvoice = proinvoices 
                    this.loading = false;
                    
                    this.cdr.markForCheck()                                                    

            //        this.deccomptabilitenumber=this.deccomptabilites.length
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.allarchivatedinvoiceSub = this.invo.archivatedinvoices$.subscribe(
                  (invoices) => {
                    this.allarchivatedinvoice = invoices 
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

            //        this.deccomptabilitenumber=this.deccomptabilites.length
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.allinvoiceSub = this.invo.invoices$.subscribe(
                  (invoices) => {
                    this.allinvoice = invoices 
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

            //        this.deccomptabilitenumber=this.deccomptabilites.length
                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
                this.usersdeletedSub = this.UserService.usersdeleted$.subscribe(
                  (usersdeleted) => {
                    this.usersdeleted = usersdeleted;
                    this.loading = false;
                    this.cdr.markForCheck()                                                    

                  },
                  (error) => {
                    this.loading = false;
                    this.errormsg=error.message;
                  }
                );
               this.getall()
               this.getalldeccomptabilites()
               this.getalldecfiscmenss()
               this.getalldeleted()
               this.getclients()
               this.getclientsbloqued()
               this.getcollaborateurs()
               this.getcondidates()
               this.getcondidatesall()
               this.getconsultants()
               this.getcontactsall()
               this.invo.getInvoicealldata()
               this.otheruser.getOtherdsalldata()
               this.deccompt.getDeccomptabilitereqByfacture()
               this.dec.getdecfiscmensreqByfacture()
               this.usedres.getUsedressourcealldata()
              }
              handleFilteredArray(filteredArray: any[]): void {
                // Do something with the filtered array received from the child component
                this.displaysearchresults='block'
                this.filtredusersarray=filteredArray
              }
              handleFilteredArrayalldsnonpaye(filteredArray: any[]): void {
                // Do something with the filtered array received from the child component
                this.displaysearchresultsalldsnonpaye='block'
                this.filtredalldsnonpayearray=filteredArray
              }
              opennotifrecs(userid:any):any
              {
                this.displaynotifrec='block'
this.allnotifrecs=this.getnotifrecbyuser(userid)[0]
              }
              getnotifrecbyuser(userid:any):any
              {
      let notifrecbyuser=[]
      notifrecbyuser=this.deccompt.filterByValue(this.notificationrecs,userid)
      return [notifrecbyuser,notifrecbyuser.length]
              }
              async notifrec(userid: string, days: any, selecteduser: User) {
                this.alldsbyusernonpaye = this.gettotalimpayebyuser(userid, days)[3];
                await Swal.fire({
                  title: 'Veuillez préciser le canal de la notification',
                  width: '80%',
                  inputValue: this.inputmessage(userid, days, selecteduser)[0] + '\n' + this.inputmessage(userid, days, selecteduser)[1],
                  html: `
                    <input type="checkbox" id="checkbox">
                    <label for="checkbox"> Inclure la facture proforma globale dans la notification</label><br>
                    <label for="notification-channel">Choisir le canal:</label>
                    <select id="notification-channel">
                      <option value="email">Email</option>
                      <option value="sms">SMS</option>
                    </select>
                  `,
                  input: 'textarea',
                  inputAttributes: {
                    readonly: 'readonly', // Make the input read-only
                  },
                  inputLabel: 'Message',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#555',
                  confirmButtonText: '<span id="confirm-text">Envoyer</span><span id="spin" style="display:none;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>',
                  cancelButtonText: 'Fermer',
                  preConfirm: (message) => {
                    // Show the spin
      (document.getElementById('confirm-text') as HTMLElement).style.display = 'none';
      (document.getElementById('spin') as HTMLElement).style.display = 'inline-block';

                    // Do not close the modal, just perform the actions
                    const selectedChannel = (document.getElementById('notification-channel') as HTMLSelectElement).value;
                    if (selectedChannel === 'email') {
                      this.handleEmailNotification(userid, days, selecteduser, message);
                      return false; // Prevent Swal from closing
                    } else if (selectedChannel === 'sms') {
                      this.handleSmsNotification(userid, days, selecteduser, message);
                      return false; // Prevent Swal from closing
                    }
                  },
                  didOpen: () => {
                    const emailMessage = this.inputmessage(userid, days, selecteduser)[0] + '\n' + this.inputmessage(userid, days, selecteduser)[1];
                    const smsMessage = 'Vous avez des DS non réglées. Un mail vous a été envoyé. ';
                    const notificationChannelSelect = document.getElementById('notification-channel') as HTMLSelectElement; // Cast to HTMLSelectElement
                    const messageTextarea = document.querySelector('.swal2-textarea') as HTMLTextAreaElement;
              
                    // Add event listener to the dropdown to update the textarea value based on the selected channel
                    notificationChannelSelect.addEventListener('change', (event) => {
                      const selectedChannel = (event.target as HTMLSelectElement).value; // Cast event.target to HTMLSelectElement
                      messageTextarea.value = selectedChannel === 'email' ? emailMessage : smsMessage;
                      Swal.getInput().value = selectedChannel === 'email' ? emailMessage : smsMessage;
                    });
                  },
                  willClose: () => {
                    // Handle any final actions before the modal closes, if needed
                  }
                });
              }
              
              handleEmailNotification(userid: string, days: any, selecteduser: User, message: any) {
                const checkboxElement = document.getElementById('checkbox') as HTMLInputElement;
                const isCheckboxChecked = checkboxElement ? checkboxElement.checked : false;
                if (isCheckboxChecked) {
                  const notifrec = new Recouvrementnotif();
                  notifrec.clientid = userid;
                  notifrec.message = message;
                  notifrec.type = true;
                  this.notificationrec.create(notifrec).then((recdata) => {
                    const proformatinvoicemodel: Proformatinvoicemodel = new Proformatinvoicemodel();
                    proformatinvoicemodel.totalds = { userId: '', demandesnonsoumis: [], demandessoumis: [], totalht: '', totalgeneral: '', soustotal1: '', soustotal2: '', timbrefiscal: '', tva: '' };
                    proformatinvoicemodel.totalds.userId = userid;
                    proformatinvoicemodel.totalds.demandesnonsoumis = this.generateglobalproforma()[5];
                    proformatinvoicemodel.totalds.demandessoumis = this.generateglobalproforma()[4];
                    proformatinvoicemodel.totalds.totalht = this.generateglobalproforma()[0];
                    proformatinvoicemodel.totalds.soustotal1 = this.generateglobalproforma()[3];
                    proformatinvoicemodel.totalds.soustotal2 = this.generateglobalproforma()[6];
                    proformatinvoicemodel.totalds.timbrefiscal = this.generateglobalproforma()[2];
                    proformatinvoicemodel.totalds.totalgeneral = this.generateglobalproforma()[7];
                    proformatinvoicemodel.totalds.tva = this.generateglobalproforma()[1];
                    this.proinv.create(proformatinvoicemodel).then((data: any) => {
                      const contact = new Contact();
                      contact.email = 'service-financier@macompta.com.tn';
                      contact.emailenvoyea = selecteduser.email;
                      contact.up = this.inputmessage(userid, days, selecteduser)[0];
                      contact.middle = this.inputmessage(userid, days, selecteduser)[1];
                      contact.bottom = 'Lien de la facture proforma: ' + `${window.location.protocol}//${window.location.host}` + '/' + 'view-proformatinvoice' + '/' + data.data._id;
                      this.cont.comunicatewithuserspecial(contact).then(
                        (data: any) => {
                          this.loading = false;
// Hide the spin and show the confirm text again
(document.getElementById('confirm-text') as HTMLElement).style.display = 'inline';
(document.getElementById('spin') as HTMLElement).style.display = 'none';
                          alert('message envoyée avec succès!')

                        },
                        (error) => {
                          this.loading = false;
                        }
                      );
                    },
                    (error) => {
                      // Handle error
                    });
                  });
                } else {
                  const notifrec = new Recouvrementnotif();
                  notifrec.clientid = userid;
                  notifrec.message = message;
                  notifrec.type = true;
                  this.notificationrec.create(notifrec).then((recdata) => {
                    const contact = new Contact();
                    contact.email = 'service-financier@macompta.com.tn';
                    contact.emailenvoyea = selecteduser.email;
                    contact.up = this.inputmessage(userid, days, selecteduser)[0];
                    contact.middle = this.inputmessage(userid, days, selecteduser)[1];
                    contact.bottom = '';
                    this.cont.comunicatewithuserspecial(contact).then(
                      (data: any) => {
                        this.loading = false;
// Hide the spin and show the confirm text again
(document.getElementById('confirm-text') as HTMLElement).style.display = 'inline';
(document.getElementById('spin') as HTMLElement).style.display = 'none';
                        alert('message envoyée avec succès!')

                      },
                      (error) => {
                        this.loading = false;
                      }
                    );
                  });
                }
              }
              
              handleSmsNotification(userid: string, days: any, selecteduser: User, message: any) {
                const checkboxElement = document.getElementById('checkbox') as HTMLInputElement;
                const isCheckboxChecked = checkboxElement ? checkboxElement.checked : false;
              
                if (isCheckboxChecked) {
                  const proformatinvoicemodel: Proformatinvoicemodel = new Proformatinvoicemodel();
                  proformatinvoicemodel.totalds = { userId: '', demandesnonsoumis: [], demandessoumis: [], totalht: '', totalgeneral: '', soustotal1: '', soustotal2: '', timbrefiscal: '', tva: '' };
                  proformatinvoicemodel.totalds.userId = userid;
                  proformatinvoicemodel.totalds.demandesnonsoumis = this.generateglobalproforma()[5];
                  proformatinvoicemodel.totalds.demandessoumis = this.generateglobalproforma()[4];
                  proformatinvoicemodel.totalds.totalht = this.generateglobalproforma()[0];
                  proformatinvoicemodel.totalds.soustotal1 = this.generateglobalproforma()[3];
                  proformatinvoicemodel.totalds.soustotal2 = this.generateglobalproforma()[6];
                  proformatinvoicemodel.totalds.timbrefiscal = this.generateglobalproforma()[2];
                  proformatinvoicemodel.totalds.totalgeneral = this.generateglobalproforma()[7];
                  proformatinvoicemodel.totalds.tva = this.generateglobalproforma()[1];
                  this.proinv.create(proformatinvoicemodel).then((data: any) => {
                    const user = selecteduser;
                    const smsMessage = message + '\n' + 'Lien de la facture proforma: ' + `${window.location.protocol}//${window.location.host}` + '/' + 'view-proformatinvoice' + '/' + data.data._id;
                    this.UserService.comunicatewithusersms(user, smsMessage).then(
                      (data: any) => {
                        this.loading = false;
                        const notifrec = new Recouvrementnotif();
                        notifrec.clientid = userid;
                        notifrec.message = message;
                        notifrec.type = false;
                        this.notificationrec.create(notifrec).then((recdata) => {
// Hide the spin and show the confirm text again
(document.getElementById('confirm-text') as HTMLElement).style.display = 'inline';
(document.getElementById('spin') as HTMLElement).style.display = 'none';
                          alert('message envoyée avec succès!')

                        });
                      },
                      (error) => {
                        this.loading = false;
                      }
                    );
                  });
                } else {
                  const user = selecteduser;
                  const smsMessage = message;
                  this.UserService.comunicatewithusersms(user, smsMessage).then(
                    (data: any) => {
                      this.loading = false;
                      const notifrec = new Recouvrementnotif();
                      notifrec.clientid = userid;
                      notifrec.message = message;
                      notifrec.type = false;
                      this.notificationrec.create(notifrec).then((recdata) => {
// Hide the spin and show the confirm text again
(document.getElementById('confirm-text') as HTMLElement).style.display = 'inline';
(document.getElementById('spin') as HTMLElement).style.display = 'none';
                        alert('message envoyée avec succès!')
                      });
                    },
                    (error) => {
                      this.loading = false;
                    }
                  );
                }
              }
              
  inputmessage(userid: string, days: any, selecteduser: any): any{
    let number = this.gettotalimpayebyuser(userid,days)[4];
    let up=''
    let bottom=''
    let middle=''
    let returnedmessage=''
    switch (true) {
      case (number >= 0 && number < 0.5):
        selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Physique'?
        (up='A l\’attention de '+'Mr '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
        middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.'
        )
        :selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Morale'?
        (up='A l\’attention de '+'Mr '+selecteduser.firstname+' '+selecteduser.lastname+' '+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
        middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
        :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Physique'?
        (
up='A l\’attention de '+'Mme '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.'
        )
        :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Morale'?
        (
          up='A l\’attention de '+'Mme '+' '+selecteduser.firstname+' '+selecteduser.lastname+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.'
        )
      :' '
        break;
      case (number >= 0.5 && number < 0.75):
        selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Physique'?
        (
          up='A l\’attention de '+'Mr '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d\’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.'
        )
        :selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Morale'?
        (
          up='A l\’attention de '+'Mr '+' '+selecteduser.firstname+' '+selecteduser.lastname+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d\’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.'
        )
        :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Physique'?
        (
          up='A l\’attention de '+'Mme '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d\’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.'
        )
        :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Morale'?
        (up='A l\’attention de '+'Mme '+' '+selecteduser.firstname+' '+selecteduser.lastname+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d\’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
      :' '
        break;
      case (number >= 0.75 && number < 1):
        selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Physique'?
        (up='A l\’attention de '+'Mr '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées afin d’éviter le blocage de traitement de votre dossier par le système (Le blocage de traitement se fait automatiquement par le système en atteignant 100% du crédit autorisé). Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
        :selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Morale'?
        (up='A l\’attention de '+'Mr '+selecteduser.firstname+' '+selecteduser.lastname+' '+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées afin d’éviter le blocage de traitement de votre dossier par le système (Le blocage de traitement se fait automatiquement par le système en atteignant 100% du crédit autorisé). Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
        :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Physique'?
        (up='A l\’attention de '+'Mme '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées afin d’éviter le blocage de traitement de votre dossier par le système (Le blocage de traitement se fait automatiquement par le système en atteignant 100% du crédit autorisé). Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
        :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Morale'?
        (up='A l\’attention de '+'Mme '+selecteduser.firstname+' '+selecteduser.lastname+' '+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous vous informons que nos honoraires non réglés ont atteint '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'de votre plafond autorisé. Nous vous invitons à payer tout ou partie des D.S non réglées afin d’éviter le blocage de traitement de votre dossier par le système (Le blocage de traitement se fait automatiquement par le système en atteignant 100% du crédit autorisé). Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
      :' '
        break;
        case (number >= 1):
          selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Physique'?
          (up='A l\’attention de '+'Mr '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous avons le regret de vous informer que le traitement de votre dossier a été bloqué automatiquement par le système compte tenu du fait que vous avez atteint (ou dépassé) votre plafond autorisé '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'. Nous vous invitons à régulariser votre situation par le paiement de tout ou partie des D.S non réglées pour que votre dossier soit accessible et traitable par nos collaborateurs. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
          :selecteduser.civilite=='Monsieur'&&selecteduser.nature=='Personne Morale'?
          (up='A l\’attention de '+'Mr '+selecteduser.firstname+' '+selecteduser.lastname+' '+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
          middle='Cher client, nous avons le regret de vous informer que le traitement de votre dossier a été bloqué automatiquement par le système compte tenu du fait que vous avez atteint (ou dépassé) votre plafond autorisé  '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'. Nous vous invitons à régulariser votre situation par le paiement de tout ou partie des D.S non réglées pour que votre dossier soit accessible et traitable par nos collaborateurs. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
          :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Physique'?
          (up='A l\’attention de '+'Mme '+selecteduser.firstname+' '+selecteduser.lastname+' '+'(Code '+selecteduser.clientcode+')',
            middle='Cher client, nous avons le regret de vous informer que le traitement de votre dossier a été bloqué automatiquement par le système compte tenu du fait que vous avez atteint (ou dépassé) votre plafond autorisé  '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'. Nous vous invitons à régulariser votre situation par le paiement de tout ou partie des D.S non réglées pour que votre dossier soit accessible et traitable par nos collaborateurs. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
          :selecteduser.civilite=='Madame'&&selecteduser.nature=='Personne Morale'?
          (up='A l\’attention de '+'Mme '+selecteduser.firstname+' '+selecteduser.lastname+' '+' responsable de  '+selecteduser.raisonsociale+' '+'(Code '+selecteduser.clientcode+')',
            middle='Cher client, nous avons le regret de vous informer que le traitement de votre dossier a été bloqué automatiquement par le système compte tenu du fait que vous avez atteint (ou dépassé) votre plafond autorisé  '+'('+Math.trunc((this.gettotalimpayebyuser(userid,days)[4]*100))+'% '+')'+'. Nous vous invitons à régulariser votre situation par le paiement de tout ou partie des D.S non réglées pour que votre dossier soit accessible et traitable par nos collaborateurs. Nous vous rappelons que le paiement peut être fait soit par carte bancaire, en accédant à votre espace sur macompta.com.tn, soit par tout autre moyen qui vous convient. Après paiement, vous pouvez visualiser et/ou éditer la note d’honoraires correspondante. Pour consulter vos D.S non réglées, vous pouvez accéder à votre compte sur notre plateforme macompta.com.tn. Nous restons à votre entière disposition pour vous donner de plus amples informations.')
        :' '
          break;
          default:
        returnedmessage='Number is not in any specified range.';
        break;
    }
return [up,middle]
  }
              getalldsuser():any{
                let alldssuserlength:any
                let alldssuser:any=[]
                alldssuser=alldssuser.concat(this.otherdss,this.dsdeccomptabilites,this.dsdecfiscmenss)
            alldssuserlength=alldssuser.length
            let resultobject=[alldssuser,alldssuserlength]
            return resultobject
              }
              getalldsusernonpaye():any{
                let alldssusernonpayelength:any
                let alldssusernonpaye:any=[]
                let totalhtalldsnonpaye=0
                alldssusernonpaye=alldssusernonpaye.concat(this.nonpayedotherdss,this.nonpayedsdeccomptabilites,this.nonpayedsdecfiscmenss)
                const sort = new Sort();
                alldssusernonpaye=alldssusernonpaye.sort(sort.startSort('created','asc',''));
                alldssusernonpayelength=alldssusernonpaye.length
                totalhtalldsnonpaye = +(alldssusernonpaye).reduce((acc,curr)=>{
                  acc += +(curr.totalhonoraire || 0);
                  return acc;
                },0);

            let resultobject=[alldssusernonpaye,alldssusernonpayelength,totalhtalldsnonpaye]
            return resultobject
              }
              
              gettotalimpayebyuser(userid: string, days: number): any {
                // Check if the result is already memoized
                const cacheKey = `${userid}_${days}`;
                if (this.memoCache.has(cacheKey)) {
                  return this.memoCache.get(cacheKey);
                }
              
                let decision = false;
                let enddate = new Date().getTime();
                let startdate = new Date().getTime();
                let ratio = 0;
                let allimpayebyuser = this.deccompt.filterByValue(this.getalldsusernonpaye()[0], userid);
              
                if (allimpayebyuser.length > 0) {
                  const filteredImp = allimpayebyuser.filter(imp => imp.totalhonoraire > 0);
                  if (filteredImp.length > 0) {
                      startdate = new Date(filteredImp[0].created).getTime();
                  } else {
                      // Handle case where no records with totalhonoraire > 0
                      startdate = allimpayebyuser[0].created; // or set another default value if needed
                  }
              } else {
                  // Handle case where there are no unpaid records for the user
                  const result = [true, 0, 0, [], 0];
                  this.memoCache.set(cacheKey, result);
                  return result;
                }
              
                let allperiod = enddate - startdate;
              
                let totalhtalldsnonpaye = allimpayebyuser.reduce((acc, curr) => {
                  return acc + (curr.totalhonoraire || 0);
                }, 0);
              
                totalhtalldsnonpaye = totalhtalldsnonpaye > 0 ? totalhtalldsnonpaye : 0;
              
                let seuilimpaye = (totalhtalldsnonpaye / allperiod) * (days * 24 * 3600 * 1000);
              
                ratio = seuilimpaye > 0 ? totalhtalldsnonpaye / seuilimpaye : 0;
              
                decision = totalhtalldsnonpaye <= seuilimpaye;
              
                const result = [decision, totalhtalldsnonpaye, seuilimpaye, allimpayebyuser, ratio];
                
                // Memoize the result
                this.memoCache.set(cacheKey, result);
              
                return result;
              }
              
              opendetails(userid:string,days:number)
              {    
this.memoCache.clear()
this.displaydsnonpaye='block'
this.alldsbyusernonpaye=this.gettotalimpayebyuser(userid,days)[3]
this.usernoteid=userid
              }  
previewnotehonoraire()
{
  if(this.shownotehonoraire()[4].length==0&&this.shownotehonoraire()[5].length==0)
  return (
    alert('veuillez sélectionner au moins une ds!')
)
  //this.shownotehonoraire()[4].length==0&&this.shownotehonoraire()[5].length==0?alert('veuillez sélectionner au moins une ds!'):''
  //this.previwing = true;
  const proformatinvoicemodel:Proformatinvoicemodel = new Proformatinvoicemodel();
  //let ammounts3=this.otherdsform.get('ammounts3') as FormArray;
  proformatinvoicemodel.totalds={userId:'',demandesnonsoumis:[],demandessoumis:[],totalht:'',totalgeneral:'',soustotal1:'',soustotal2:''
,timbrefiscal:'',tva:''}
      proformatinvoicemodel.totalds.userId=this.usernoteid
      proformatinvoicemodel.totalds.demandesnonsoumis=this.shownotehonoraire()[5]
proformatinvoicemodel.totalds.demandessoumis=this.shownotehonoraire()[4]
proformatinvoicemodel.totalds.totalht=this.shownotehonoraire()[0]
proformatinvoicemodel.totalds.soustotal1=this.shownotehonoraire()[3]
proformatinvoicemodel.totalds.soustotal2=this.shownotehonoraire()[6]
proformatinvoicemodel.totalds.timbrefiscal=this.shownotehonoraire()[2]
proformatinvoicemodel.totalds.totalgeneral=this.shownotehonoraire()[7]
proformatinvoicemodel.totalds.tva=this.shownotehonoraire()[1]
this.proinv.create(proformatinvoicemodel).then(
        (data:any) => {
        //this.previwing=false
        this.router.navigate([]).then((_result) => {
          window.open('view-proformatinvoice' + '/' + data.data._id, '_blank');
        });
         // this.loading = false;
        },
        (error) => {
        //  this.loading = false;
        }
      )
 
  
}
shownotehonoraire():any
{
  let dstopay=[]
  let dstopaynonsoumis=[]
  let totalhonht=0
  let totaltva=0
  let totalttc=0
  let soustotal2=0
  let totalgeneral=0
  let timbrefiscal=0
  for (let i = 0; i < this.alldsbyusernonpaye.length ; i++) 
  {
    let checkbox:any = document.getElementById('dsnonpayecheckbox'+`${i}`);
    checkbox?
    checkbox.checked==true?
    this.alldsbyusernonpaye[i].soumis||this.alldsbyusernonpaye[i].nature?
    dstopay.push(this.alldsbyusernonpaye[i]):
   dstopaynonsoumis.push(this.alldsbyusernonpaye[i])
   :''
   :''
  }

dstopay.length>0?
totalhonht=dstopay.reduce((acc,curr)=>{
  acc +=  +(curr.totalhonoraire || 0);
  return acc;
},0)
:
totalhonht=0
dstopaynonsoumis.length>0?
soustotal2=dstopaynonsoumis.reduce((acc,curr)=>{
  acc +=  +(curr.totalhonoraire || 0);
  return acc;
},0)
:
soustotal2=0
totaltva= totalhonht*0.19
timbrefiscal=1
totalttc=totalhonht+totaltva
totalgeneral=totalttc+soustotal2+timbrefiscal
let result=[totalhonht,totaltva,timbrefiscal,totalttc,dstopay,dstopaynonsoumis,soustotal2,totalgeneral]

return result
}   
generateglobalproforma():any
{
  let dstopay=[]
  let dstopaynonsoumis=[]
  let totalhonht=0
  let totaltva=0
  let totalttc=0
  let soustotal2=0
  let totalgeneral=0
  let timbrefiscal=0
  for (let i = 0; i < this.alldsbyusernonpaye.length ; i++) 
  {
    this.alldsbyusernonpaye[i].soumis||this.alldsbyusernonpaye[i].nature?
    dstopay.push(this.alldsbyusernonpaye[i]):
   dstopaynonsoumis.push(this.alldsbyusernonpaye[i])
  }

dstopay.length>0?
totalhonht=dstopay.reduce((acc,curr)=>{
  acc +=  +(curr.totalhonoraire || 0);
  return acc;
},0)
:
totalhonht=0
dstopaynonsoumis.length>0?
soustotal2=dstopaynonsoumis.reduce((acc,curr)=>{
  acc +=  +(curr.totalhonoraire || 0);
  return acc;
},0)
:
soustotal2=0
totaltva= totalhonht*0.19
timbrefiscal=1
totalttc=totalhonht+totaltva
totalgeneral=totalttc+soustotal2+timbrefiscal
let result=[totalhonht,totaltva,timbrefiscal,totalttc,dstopay,dstopaynonsoumis,soustotal2,totalgeneral]
return result
} 
previewnotehonoraireglobal()
{
  //this.shownotehonoraire()[4].length==0&&this.shownotehonoraire()[5].length==0?alert('veuillez sélectionner au moins une ds!'):''
  //this.previwing = true;
  const proformatinvoicemodel:Proformatinvoicemodel = new Proformatinvoicemodel();
  //let ammounts3=this.otherdsform.get('ammounts3') as FormArray;
  proformatinvoicemodel.totalds={userId:'',demandesnonsoumis:[],demandessoumis:[],totalht:'',totalgeneral:'',soustotal1:'',soustotal2:''
,timbrefiscal:'',tva:''}
      proformatinvoicemodel.totalds.userId=this.usernoteid
      proformatinvoicemodel.totalds.demandesnonsoumis=this.generateglobalproforma()[5]
proformatinvoicemodel.totalds.demandessoumis=this.generateglobalproforma()[4]
proformatinvoicemodel.totalds.totalht=this.generateglobalproforma()[0]
proformatinvoicemodel.totalds.soustotal1=this.generateglobalproforma()[3]
proformatinvoicemodel.totalds.soustotal2=this.generateglobalproforma()[6]
proformatinvoicemodel.totalds.timbrefiscal=this.generateglobalproforma()[2]
proformatinvoicemodel.totalds.totalgeneral=this.generateglobalproforma()[7]
proformatinvoicemodel.totalds.tva=this.generateglobalproforma()[1]
this.proinv.create(proformatinvoicemodel).then(
        (data:any) => {
          this.currentproid=data.data._id
        //this.previwing=false
         // this.loading = false;
        },
        (error) => {
        //  this.loading = false;
        }
      )
 
  
}
              getalldsuserpaye():any{
                let alldssuserpayelength:any
                let alldssuserpaye:any=[]
                alldssuserpaye=alldssuserpaye.concat(this.payedotherdss,this.payedsdeccomptabilites,this.payedsdecfiscmenss)
                const sort = new Sort();
                alldssuserpaye=alldssuserpaye.sort(sort.startSort('created','asc',''));
                alldssuserpayelength=alldssuserpaye.length
            let resultobject=[alldssuserpaye,alldssuserpayelength]
            return resultobject
              }
              getalldsusermarquepaye():any{
              
                let alldssusermarquepayelength:any
                let alldssusermarquepaye:any=[]
                alldssusermarquepaye=alldssusermarquepaye.concat(this.marquepayedotherdss,this.marquepayedsdeccomptabilites,this.marquepayedsdecfiscmenss)

                const sort = new Sort();
            
                alldssusermarquepaye=alldssusermarquepaye.sort(sort.startSort('created','asc',''));
                alldssusermarquepayelength=alldssusermarquepaye.length
            let resultobject=[alldssusermarquepaye,alldssusermarquepayelength]
            return resultobject
              }
               payinvoice(id:string,nature:string){
                this.submitting = true;
                  if(nature=='comptabilite')
                  {
            const newelement: Deccomptabilite = new Deccomptabilite()
            newelement.paye=true
            newelement.marquepaye=true

            this.deccompt.completedeccomptabilitereqById(id,newelement).then(
              (data:any) => {
               // this.loading = false;
               this.UserService.getUserById(data.data.userId).then(
                (user:User)=>
                {
                  this.click55()
                  this.closecopPopup()
                  setTimeout(() => {
                   this.cdr.markForCheck()
                   this.opendetails(user._id,user.countingdays?user.countingdays:60)
                 }, 3000);
                   this.submitting=false
                }
               )
             
              },
              (error) => {
              //  this.loading = false;
                
              }
            )
                  }
                  else  if(nature=='Déclaration Mensuelle')
                  {
                    const newelement: Decfiscmens = new Decfiscmens()
            newelement.paye=true
            newelement.marquepaye=true

            this.dec.completedecfiscmensreqById(id,newelement).then(
              (data:any) => {
               // this.loading = false;
               this.UserService.getUserById(data.data.userId).then(
                (user:User)=>
                {
                  this.click55()
                  this.closecopPopup()
                  setTimeout(() => {
                   this.cdr.markForCheck()
                   this.opendetails(user._id,user.countingdays?user.countingdays:60)
                 }, 3000);
                   this.submitting=false
                }
               )
              },
              (error) => {
              //  this.loading = false;
                
              }
            )
                  }
                  else  if(!nature)
                  {
                    const newelement: Otherdsmodeluser = new Otherdsmodeluser()
            newelement.paye=true
            newelement.marquepaye=true

            this.otheruser.modifyotherdsByIdforuser(id,newelement).then(
              (data:any) => {
              
               // this.loading = false;
               this.UserService.getUserById(data.data.clientId).then(
                (user:User)=>
                {
                  this.click55()
                 this.closecopPopup()
                 setTimeout(() => {
                  this.cdr.markForCheck()
                  this.opendetails(user._id,user.countingdays?user.countingdays:60)
                }, 3000);
                  this.submitting=false

                }
               )
              },
              (error) => {
              //  this.loading = false;
                
              }
            )
                  }       
              }
              debutcompteurdecfiscale(link,id)
              {

                this.dec.getDecfiscmensreqById(id).then(
                  (decfiscmens: Decfiscmens) => {
                    
                    this.decfiscmens = decfiscmens;
                    this.UserService.getUserById(this.decfiscmens.userId).then(
                      (user:User)=>{
                        if (user)
                        {
                          if(user.standby)
                          return (
                            Swal.fire({
                            title: 'utilisateur en Standby, veuillez le libérer',
                            icon: 'error',
                            confirmButtonColor: '#3085d6',
                          }).then((_result) => {}).catch(() => {
                            Swal.fire('opération non aboutie!')
                          }))  
                        }
                                           
                        this.commun.getcurrenttime().then(
                          (data:any) => {
                            if(this.decfiscmens.statutcollab.length>0)
                            {
                                                  //@ts-ignore
                            if(this.decfiscmens.statutcollab[this.decfiscmens.statutcollab.length-1].statutcoll=='traité'&&this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].statut=='affecté'
                                                  //@ts-ignore
                            ||this.decfiscmens.statutcollab[this.decfiscmens.statutcollab.length-1].statutcoll=='traité'&&this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].statut=='à rectifier'
                                                  //@ts-ignore
                            ||this.decfiscmens.statutcollab[this.decfiscmens.statutcollab.length-1].statutcoll=='traité'&&this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-2].statut=='clôturé'
                            //@ts-ignore
                            ||this.decfiscmens.statutcollab[this.decfiscmens.statutcollab.length-1].statutcoll=='traité'&&this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-2].statut=='en cours de supervision'&&this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].statut!='supervisé')
                                                  {
                                                    
                                                    this.decfiscmens.statutadmin.push
                                                    //@ts-ignore
                                                    ({
                                                      statut:'en cours de supervision',
                                                      motif:'',
                                                      datefin:data,
                                                    })                      
                                                    this.dec.modifydecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                                                      (_data:any) => {
                                                        this.router.navigate([link + '/' + id]); 
                                                      },
                                                      (_error) => {
                                                        this.loading = false;
                                                        
                                                        window.scrollTo(0, 0);     
                                                  }
                                                  );
                                                  }
                                                                          //@ts-ignore
                            
                                                  if(this.decfiscmens.statutcollab[this.decfiscmens.statutcollab.length-1].statutcoll=='traité'&&this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].statut=='supervisé')
                                                  {
                                                    
                                                    this.decfiscmens.statutadmin.push
                                                    //@ts-ignore
                                                    ({
                                                      statut:'en cours de validation',
                                                      motif:'',
                                                      datefin:data,
                                                    })                      
                                                    this.dec.modifydecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                                                      (_data:any) => {
                                                        this.router.navigate([link + '/' + id]); 
                                                      },
                                                      (_error) => {
                                                        this.loading = false;
                                                        
                                                        window.scrollTo(0, 0);     
                                                  }
                                                  );
                                                  }
                                                                          //@ts-ignore
                                                  if(this.decfiscmens.statutcollab[this.decfiscmens.statutcollab.length-1].statutcoll=='traité'&&this.decfiscmens.statutadmin[this.decfiscmens.statutadmin.length-1].statut=='validé')
                                                  {
                                                    
                                                    this.decfiscmens.statutadmin.push
                                                    //@ts-ignore
                                                    ({
                                                      statut:'en cours de clôture',
                                                      motif:'',
                                                      datefin:data,
                                                    })                      
                                                    this.dec.modifydecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                                                      (_data:any) => {
                                                        this.router.navigate([link + '/' + id]); 
                                                      },
                                                      (_error) => {
                                                        this.loading = false;
                                                        
                                                        window.scrollTo(0, 0);     
                                                  }
                                                  );
                                                  }
                                                  
                            }
                                               
                            
                              if(!this.decfiscmens.dateouverturedossier)
                            {
                              
                              this.decfiscmens.dateouverturedossier=data
                            
                              this.dec.modifydecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                                (_data:any) => {
                                  this.router.navigate([link + '/' + id]); 
                                },
                                (_error) => {
                                  this.loading = false;
                                  
                                  window.scrollTo(0, 0);     
                            }
                            );
                            }
                            else 
                            {
                              this.router.navigate([link + '/' + id]); 
                            } 
                          }
                        )
                      }
                    )
           
                   
}
)
}
//debutcomptabilite
debutcompteurcomptabilite(link,id)
{

  this.deccompt.getDeccomptabilitereqById(id).then(
    (deccomptabilite: Deccomptabilite) => {
      this.deccomptabilite = deccomptabilite;
      this.dec.geexistenttdecfiscmens(this.deccomptabilite.userId,this.deccomptabilite.annee,this.deccomptabilite.mois,this.deccomptabilite.source).then(
        (decfiscmens: Decfiscmens[]) => {
          if(decfiscmens.length>0)
          {
            this.decfiscmens = decfiscmens[0];
            this.UserService.getUserById(this.deccomptabilite.userId).then(
              (user:User)=>{
                
                if (user)
                {
                  let countingdays:number
                user.countingdays? countingdays=user.countingdays : countingdays=60
                  if(user.standby)
                  return (
                    Swal.fire({
                    title: 'utilisateur en Standby, veuillez le libérer',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                  }).then((_result) => {}).catch(() => {
                    Swal.fire('opération non aboutie!')
                  }))  
else  if(!this.gettotalimpayebyuser(user._id,countingdays)[0])
return (
  Swal.fire({
  title: `cet utilisateur est bloqué car il a atteint le seuil du total impayé à savoir: ${this.gettotalimpayebyuser(user._id,countingdays)[2].toFixed(3)} relatif à un total général d\'impayé de 
  ${this.gettotalimpayebyuser(user._id,countingdays)[1].toFixed(3)}`,
  icon: 'error',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Envoyer un rappel au client',
  cancelButtonText: 'Continuer sans envoyer de rappel',
}).then((result) => {
  if (result.value) {
  console.log('configurer  le rappel');
  }
else{;
}
}).catch(() => {
  Swal.fire('opération non aboutie!')
}))  
                }                     
                this.commun.getcurrenttime().then(
                  (data:any) => {
                    if(this.deccomptabilite.statutcollab.length>0)
                    {
                                          //@ts-ignore
                    if(this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='affecté'
                                          //@ts-ignore
                    ||this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='à rectifier'
                                          //@ts-ignore
                    ||this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-2].statut=='clôturé'
                    //@ts-ignore
                    ||this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-2].statut=='en cours de supervision'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut!='supervisé')
                                          {
                                            
                                            this.deccomptabilite.statutadmin.push
                                            //@ts-ignore
                                            ({
                                              statut:'en cours de supervision',
                                              motif:'',
                                              datefin:data,
                                            })                      
                                            this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                                              (_data:any) => {
                                                this.decfiscmens.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de supervision',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.dec.completedecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              },
                                              (_error) => {
                                                this.loading = false;
                                                
                                                window.scrollTo(0, 0);     
                                          }
                                          );
                                     
                                          }
                                                                  //@ts-ignore
                    
                                          if(this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='supervisé')
                                          {
                                            
                                            this.deccomptabilite.statutadmin.push
                                            //@ts-ignore
                                            ({
                                              statut:'en cours de validation',
                                              motif:'',
                                              datefin:data,
                                            })                      
                                            this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                                              (_data:any) => {
                                                this.decfiscmens.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de validation',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.dec.completedecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              },
                                              (_error) => {
                                                this.loading = false;
                                                
                                                window.scrollTo(0, 0);     
                                          }
                                          );
                                   
                                          }
                                                                  //@ts-ignore
                                          if(this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='validé')
                                          {
                                            
                                            this.deccomptabilite.statutadmin.push
                                            //@ts-ignore
                                            ({
                                              statut:'en cours de clôture',
                                              motif:'',
                                              datefin:data,
                                            })                      
                                            this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                                              (_data:any) => {
                                                this.decfiscmens.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de clôture',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.dec.completedecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              },
                                              (_error) => {
                                                this.loading = false;
                                                
                                                window.scrollTo(0, 0);     
                                          }
                                          );
                                        
                                          }
                                          
                    }
                                       
                    
                      if(!this.deccomptabilite.dateouverturedossier)
                    {
                      
                      this.deccomptabilite.dateouverturedossier=data
                    
                      this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                        (_data:any) => {
                          this.decfiscmens.dateouverturedossier=data
                    
                          this.dec.completedecfiscmensreqById(this.decfiscmens._id,this.decfiscmens).then(
                            (_data:any) => {
                              this.router.navigate([link + '/' + id]); 
                            },
                            (_error) => {
                              this.loading = false;
                              
                              window.scrollTo(0, 0);     
                        }
                        );
                          this.router.navigate([link + '/' + id]); 
                        },
                        (_error) => {
                          this.loading = false;
                          
                          window.scrollTo(0, 0);     
                    }
                    );
                 
                    }
                    else 
                    {
                      this.router.navigate([link + '/' + id]); 
                    } 
                  }
                )
              }
            )
          }
          else
          {
            this.UserService.getUserById(this.deccomptabilite.userId).then(
              (user:User)=>{
                if (user)
                {
                  let countingdays:number
                  user.countingdays? countingdays=user.countingdays : countingdays=60
                  if(user.standby)
                  return (
                    Swal.fire({
                    title: 'utilisateur en Standby, veuillez le libérer',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                  }).then((_result) => {}).catch(() => {
                    Swal.fire('opération non aboutie!')
                  })) 
                  else  if(!this.gettotalimpayebyuser(user._id,countingdays)[0])
return (
  Swal.fire({
  title: `cet utilisateur est bloqué car il a atteint le seuil du total impayé à savoir: ${this.gettotalimpayebyuser(user._id,countingdays)[2].toFixed(3)} relatif à un total général d\'impayé de 
  ${this.gettotalimpayebyuser(user._id,countingdays)[1].toFixed(3)}`,
  icon: 'error',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Envoyer un rappel au client',
  cancelButtonText: 'Continuer sans envoyer de rappel',
}).then((result) => {
  if (result.value) {
  console.log('configurer  le rappel');
  }
else{;
}
}).catch(() => {
  Swal.fire('opération non aboutie!')
}))  
                }                     
                this.commun.getcurrenttime().then(
                  (data:any) => {
                    if(this.deccomptabilite.statutcollab.length>0)
                    {
                                          //@ts-ignore
                    if(this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='affecté'
                                          //@ts-ignore
                    ||this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='à rectifier'
                                          //@ts-ignore
                    ||this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-2].statut=='clôturé'
                    //@ts-ignore
                    ||this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-2].statut=='en cours de supervision'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut!='supervisé')
                                          {
                                            
                                            this.deccomptabilite.statutadmin.push
                                            //@ts-ignore
                                            ({
                                              statut:'en cours de supervision',
                                              motif:'',
                                              datefin:data,
                                            })                      
                                            this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                                              (_data:any) => {
                                                this.router.navigate([link + '/' + id]); 
                                              },
                                              (_error) => {
                                                this.loading = false;
                                                
                                                window.scrollTo(0, 0);     
                                          }
                                          );
                                          }
                                                                  //@ts-ignore
                    
                                          if(this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='supervisé')
                                          {
                                            
                                            this.deccomptabilite.statutadmin.push
                                            //@ts-ignore
                                            ({
                                              statut:'en cours de validation',
                                              motif:'',
                                              datefin:data,
                                            })                      
                                            this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                                              (_data:any) => {
                                                this.router.navigate([link + '/' + id]); 
                                              },
                                              (_error) => {
                                                this.loading = false;
                                                
                                                window.scrollTo(0, 0);     
                                          }
                                          );
                                          }
                                                                  //@ts-ignore
                                          if(this.deccomptabilite.statutcollab[this.deccomptabilite.statutcollab.length-1].statutcoll=='traité'&&this.deccomptabilite.statutadmin[this.deccomptabilite.statutadmin.length-1].statut=='validé')
                                          {
                                            
                                            this.deccomptabilite.statutadmin.push
                                            //@ts-ignore
                                            ({
                                              statut:'en cours de clôture',
                                              motif:'',
                                              datefin:data,
                                            })                      
                                            this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                                              (_data:any) => {
                                                this.router.navigate([link + '/' + id]); 
                                              },
                                              (_error) => {
                                                this.loading = false;
                                                
                                                window.scrollTo(0, 0);     
                                          }
                                          );
                                          }
                                          
                    }
                                       
                    
                      if(!this.deccomptabilite.dateouverturedossier)
                    {
                      
                      this.deccomptabilite.dateouverturedossier=data
                    
                      this.deccompt.completedeccomptabilitereqById(this.deccomptabilite._id,this.deccomptabilite).then(
                        (_data:any) => {
                          this.router.navigate([link + '/' + id]); 
                        },
                        (_error) => {
                          this.loading = false;
                          
                          window.scrollTo(0, 0);     
                    }
                    );
                    }
                    else 
                    {
                      this.router.navigate([link + '/' + id]); 
                    }  
                  }
                )
              }
            )
          }
    
        }
      )


     
}
)
}
//debutcompteurcontactreq
debutcontact(link,id)
              {

                this.cont.getContactreqById(id).then(
                  (contact: Contact) => {
                    
                    this.contact = contact;
                    this.commun.getcurrenttime().then(
                      (data:any) => {
                        if(this.contact.statutcollab.length>0)
                        {
                                              //@ts-ignore
                        if(this.contact.statutcollab[this.contact.statutcollab.length-1].statutcoll=='traité'&&this.contact.statutadmin[this.contact.statutadmin.length-1].statut=='affecté'
                                              //@ts-ignore
                        ||this.contact.statutcollab[this.contact.statutcollab.length-1].statutcoll=='traité'&&this.contact.statutadmin[this.contact.statutadmin.length-1].statut=='à rectifier'
                        
                         //@ts-ignore
                         ||this.contact.statutcollab[this.contact.statutcollab.length-1].statutcoll=='traité'&&this.contact.statutadmin[this.contact.statutadmin.length-1].statut=='affecté'&&this.contact.statutadmin[this.contact.statutadmin.length-2].statut=='affecté')
                                              {
                                                
                                                this.contact.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de supervision',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.cont.modifycontactreqById(this.contact._id,this.contact).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              }
                                                                      //@ts-ignore
                        
                                              if(this.contact.statutcollab[this.contact.statutcollab.length-1].statutcoll=='traité'&&this.contact.statutadmin[this.contact.statutadmin.length-1].statut=='supervisé')
                                              {
                                                
                                                this.contact.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de validation',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.cont.modifycontactreqById(this.contact._id,this.contact).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              }
                                                                      //@ts-ignore
                                              if(this.contact.statutcollab[this.contact.statutcollab.length-1].statutcoll=='traité'&&this.contact.statutadmin[this.contact.statutadmin.length-1].statut=='validé')
                                              {
                                                
                                                this.contact.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de clôture',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.cont.modifycontactreqById(this.contact._id,this.contact).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              }
                                              
                        }
                                           
                        
                          if(!this.contact.dateouverturedossier)
                        {
                          
                          this.contact.dateouverturedossier=data
                        
                          this.cont.modifycontactreqById(this.contact._id,this.contact).then(
                            (_data:any) => {
                              this.router.navigate([link + '/' + id]); 
                            },
                            (_error) => {
                              this.loading = false;
                              
                              window.scrollTo(0, 0);     
                        }
                        );
                        }
                        else 
                        {
                          this.router.navigate([link + '/' + id]); 
                        } 
                      }
                    )
                   
}
)
}
//debutcompteurcondidate
debutcandidature(link,id)
              {

                this.cond.getCondidateById(id).then(
                  (condidate: Condidate) => {
                    
                    this.condidate = condidate;
                    this.commun.getcurrenttime().then(
                      (data:any) => {
                        if(this.condidate.statutcollab.length>0)
                        {
                                              //@ts-ignore
                        if(this.condidate.statutcollab[this.condidate.statutcollab.length-1].statutcoll=='traité'&&this.condidate.statutadmin[this.condidate.statutadmin.length-1].statut=='affecté'
                                              //@ts-ignore
                        ||this.condidate.statutcollab[this.condidate.statutcollab.length-1].statutcoll=='traité'&&this.condidate.statutadmin[this.condidate.statutadmin.length-1].statut=='à rectifier')
                                              {
                                                
                                                this.condidate.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de supervision',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.cond.modifycondidateById(this.condidate._id,this.condidate).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              }
                                                                      //@ts-ignore
                        
                                              if(this.condidate.statutcollab[this.condidate.statutcollab.length-1].statutcoll=='traité'&&this.condidate.statutadmin[this.condidate.statutadmin.length-1].statut=='supervisé')
                                              {
                                                
                                                this.condidate.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de validation',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.cond.modifycondidateById(this.condidate._id,this.condidate).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              }
                                                                      //@ts-ignore
                                              if(this.condidate.statutcollab[this.condidate.statutcollab.length-1].statutcoll=='traité'&&this.condidate.statutadmin[this.condidate.statutadmin.length-1].statut=='validé')
                                              {
                                                
                                                this.condidate.statutadmin.push
                                                //@ts-ignore
                                                ({
                                                  statut:'en cours de clôture',
                                                  motif:'',
                                                  datefin:data,
                                                })                      
                                                this.cond.modifycondidateById(this.condidate._id,this.condidate).then(
                                                  (_data:any) => {
                                                    this.router.navigate([link + '/' + id]); 
                                                  },
                                                  (_error) => {
                                                    this.loading = false;
                                                    
                                                    window.scrollTo(0, 0);     
                                              }
                                              );
                                              }
                                              
                        }
                                           
                        
                          if(!this.condidate.dateouverturedossier)
                        {
                          this.condidate.dateouverturedossier=data
                        
                          this.cond.modifycondidateById(this.condidate._id,this.condidate).then(
                            (_data:any) => {
                              this.router.navigate([link + '/' + id]); 
                            },
                            (_error) => {
                              this.loading = false;
                              
                              window.scrollTo(0, 0);     
                        }
                        );
                        }
                        else 
                        {
                          this.router.navigate([link + '/' + id]); 
                        } 
                      }
                    )
                   
}
)
}
verifyexercicecloture(annee:string,id:string,mois:string):boolean
{
  let show=false
  let filtredmois=[]
  let selecteduser=this.deccompt.filterByValue2(this.users,id)
  let deletedselecteduser=this.deccompt.filterByValue2(this.usersdeleted,id)
  selecteduser?filtredmois=this.commun.filterMois(annee,selecteduser):filtredmois=this.commun.filterMois(annee,deletedselecteduser)
  filtredmois.find(e=>e.value==mois)?show=false:show=true
  return show
}
liberateusedressource(id:string)
{
                this.usedres.deleteusedressourcedataById(id).then(
                  (usedres:Usedressourcemodel)=>
                        {
                          this.click53()
                        }
                )
}
/*identifyres(id:string):any
{
  let rescompt=this.deccompt.filterByValue2(this.deccomptabilites,id)
  let resfisc=this.deccompt.filterByValue2(this.decfiscmenss,id)
if(rescompt)
{
  return [rescompt.mois,rescompt.annee,'comptabilité',rescompt.firstname,rescompt.lastname]
}
else{
  return [resfisc.mois,resfisc.annee,'déclaration fiscle',resfisc.firstname,resfisc.lastname]
}
}*/
filterusers(id:string)
{
  this.filtredusers=this.deccompt.filterByValue(this.users,id) || []
  if(this.filtredusers.length>0)
  {
    this.prenom=this.filtredusers[0].firstname
    this.nom=this.filtredusers[0].lastname
    this.raisonsociale=this.filtredusers[0].raisonsociale

  }
  else
  {
    this.prenom='utilisateur supprimé'
    this.nom='utilisateur supprimé'
  }
}
filterusers2(id:string)
{
  this.filtredusers2=this.deccompt.filterByValue(this.users2,id) || []
  if(this.filtredusers2.length>0)
  {
    this.prenomfisc=this.filtredusers2[0].firstname
    this.nomfisc=this.filtredusers2[0].lastname
    this.prenomaffecte=this.filtredusers2[0].firstname
    this.nomaffecte=this.filtredusers2[0].lastname
    this.raisonsocialefisc=this.filtredusers2[0].raisonsociale

  }
  else
  {
    this.prenomfisc='utilisateur supprimé'
    this.nomfisc='utilisateur supprimé'
  }
  
}
filterusers3(email:any)
{
  this.filtredusers3=this.deccompt.filterByValue(this.users3,email) || []
  if(this.filtredusers3.length>0)
  {
    this.prenomreq=this.filtredusers3[0].firstname
    this.nomreq=this.filtredusers3[0].lastname
  }
  else
  {
    this.prenomreq='utilisateur supprimé'
    this.nomreq='utilisateur supprimé'
  }
  
}
getadmincollabview(link, id){
      
  this.UserService.getUserById(id);
  this.router.navigate([]).then((_result) => {
    window.open(link + '/' + id, '_blank');
  });
}
getNavigationnotifrec(link, id)
{
  this.router.navigate([]).then((_result) => {
    window.open(link + '/' + id, '_blank');
  });
 // this.router.navigate([link + '/' + id]); 
}
getNavigationautreds(link, id)
{
  this.router.navigate([]).then((_result) => {
    window.open(link + '/' + id, '_blank');
  });
 // this.router.navigate([link + '/' + id]); 
}
getNavigationproformatinvoice(link, id)
{
  this.router.navigate([]).then((_result) => {
    window.open(link + '/' + id, '_blank');
  });
 // this.router.navigate([link + '/' + id]); 
}
getNavigationinvoice(link, id)
{
  this.router.navigate([]).then((_result) => {
    window.open(link + '/' + id, '_blank');
  });
 // this.router.navigate([link + '/' + id]); 
}
              getNavigationusers(link, id){
      
                this.UserService.getUserById(id);
                this.router.navigate([]).then((_result) => {
                  window.open(link + '/' + id, '_blank');
                });              
              }
              getNavigationusersdeleted(link, id){
      
                this.UserService.getUserdeletedById(id);
                this.router.navigate([]).then((_result) => {
                  window.open(link + '/' + id, '_blank');
                }); 
              }
              getNavigationdecfiscmenss(link, id){
      
                this.dec.getDecfiscmensreqById(id);
                this.router.navigate([]).then((_result) => {
                  window.open(link + '/' + id, '_blank');
                }); 
              }
              getNavigationdeccomptabilites(link, id){
      
                this.deccompt.getDeccomptabilitereqById(id);
                this.router.navigate([]).then((_result) => {
                  window.open(link + '/' + id, '_blank');
                }); 
              }
              getNavigationcondidates(link, id){
      
                this.cond.getCondidateById(id);
                this.router.navigate([link + '/' + id]); 
              }
              getNavigationcontacts(link, id){
      
                this.cont.getContactreqById(id);
                this.router.navigate([link + '/' + id]); 
              }
              getuserdeccomptabilite(id:string) {
                 
                return this.filtredusers=this.users.filter((user) => (user._id === id));
              }
              getallusers() {
                let filtred=[]
                filtred=this.deccompt.filterByValue(this.users,'desactive')
                this.clienttotal=filtred.length
                return filtred; 
              }
              getconnectedusers() {
                let filtred=[]
                filtred=this.deccompt.filterByValue(this.users,'desactive')
                this.utilconnecte=(filtred.filter((filter) => (filter.connected === true))).length
                return filtred.filter((filter) => (filter.connected === true)); 
              }
              getclients() {
                let filtred=[]
                filtred=this.deccompt.filterByValue(this.users,'desactive')
                this.ca=(filtred.filter((filter) => (filter.usertype === 'Client'&&!filter.desactive.statut))).length
                return filtred.filter((filter) => (filter.usertype === 'Client'&&!filter.desactive.statut)); 
              }
              getclientsbloqued() {
                let filtred=[]
                filtred=this.deccompt.filterByValue(this.users,'desactive')
                this.cb=(filtred.filter((user) => user.desactive.statut)).length
                return (filtred.filter((user) => user.desactive.statut));
               
              }
              getcollaborateurs() {
                let filtred=[]
                filtred=this.deccompt.filterByValue(this.users,'desactive')
                this.coll=(filtred.filter((user) => user.usertype === ('Collaborateur'||'collaborateur'))).length
                return filtred.filter((user) => user.usertype === ('Collaborateur'||'collaborateur')); 
              }
              getconsultants() {
                let filtred=[]
                filtred=this.deccompt.filterByValue(this.users,'desactive')
                this.cons=(filtred.filter((user) => user.usertype === ('Consultant'||'consultant'))).length
                return filtred.filter((user) => user.usertype === ('Consultant'||'consultant')); 
              }
              getcondidates() {
                let filtred=[]
                filtred=this.deccompt.filterByValue(this.users,'desactive')
                this.condida=(filtred.filter((user) => user.usertype === 'Candidat')).length
                return filtred.filter((user) => user.usertype === 'Candidat');
              }
              getusersbyfirstname() {
                this.firstname=this.searchForm.get('firstname').value;
                this.UserService.getuserbyfirstname(this.firstname)
                 
              }
              getusersbylastname() {
                this.lastname=this.searchForm.get('lastname').value;
                                this.UserService.getuserbylastname(this.lastname)
                                 
              }
              getusersbyemail() {
                                
                this.email=this.searchForm.get('email').value;
                this.UserService.getuserbyemail(this.email);
                                               
                                                 
              }
              getall() {
                                
                                                
                this.UserService.getAll();
                                                               
                                                                 
             }
             getdossiersencours()
             {
              this.dossencours1=new Array
              this.getall()
              this.getalldeccomptabilites()
              this.getalldecfiscmenss()
              this.getcondidatesall()
              this.getcontactsall()
              this.decfiscmenss.forEach((item, _index) => { 
                if(item.statutadmin.length>0&&item.statutcollab.length>0)
                    {
                             //@ts-ignore                                                            
                      if(item.statutadmin[item.statutadmin.length-1].statut!='clôturé'&&item.affecte&&!item.source)
                      {
                        this.dossencours1.push(item)
                      }
                    }
                 }
                 )
              this.dossdecfiscencours=this.dossencours1.length

              
                            //@ts-ignore                                                            
this.dossdeccompencours=(this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte&&!deccomptabilite.source  
              //@ts-ignore                                                            

       &&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut!='clôturé':'')).length

                                                
              //@ts-ignore                                                            
              this.dosscandencours=(this.condidates.filter((condidate) => !condidate.statutadmin.find(e => e.statut==='clôturé')&&condidate.affecte)).length                                   
              //@ts-ignore                                                            
              this.dosscontactencours=(this.contacts.filter((contact) => !contact.statutadmin.find(e => e.statut==='clôturé')&&contact.affecte&&!contact.up)).length                                   
       //@ts-ignore                                                            
              this.dossencours2=(this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte&&!deccomptabilite.source 
                            //@ts-ignore                                                            
    
              &&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut!='clôturé':''))
       //@ts-ignore                                                            
              this.dossencours3=((this.condidates.filter((condidate) => !condidate.statutadmin.find(e => e.statut==='clôturé')&&condidate.affecte)))
       //@ts-ignore                                                            
              this.dossencours4=((this.contacts.filter((contact) => !contact.statutadmin.find(e => e.statut==='clôturé')&&contact.affecte&&!contact.up)))
       this.dossencours=[]
       this.dossencours=this.dossencours.concat(this.dossencours1,this.dossencours2,this.dossencours3,this.dossencours4) 
       const sort = new Sort();
       this.sorteddossencours=this.dossencours.sort(sort.startSort('created','asc','')); 
       this.paginatedossencoursData()

            return (this.sorteddossencours);
             }
             getdossiersencoursnonaffecte()
             {
            this.getall()
              this.getalldeccomptabilites()
              this.getalldecfiscmenss()
              this.getcondidatesall()
              this.getcontactsall()
  
  this.dossdecfiscnonaffecte=(this.decfiscmenss.filter((decfiscmens) => 
 !decfiscmens.affecte&&!decfiscmens.source&&
       //@ts-ignore                                                            

 decfiscmens.statutcollab.length>0?decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='déposé par le client'||decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='déposé pour le compte du client':'')).length
  this.dossdeccompnonaffecte=(this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte&&!deccomptabilite.source
         //@ts-ignore                                                            

  &&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='déposé par le client'||deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='déposé pour le compte du client':'')).length                                   
  this.dosscandnonaffecte=(this.condidates.filter((condidate) => !condidate.affecte)).length                                   
  this.dosscontactnonaffecte=(this.contacts.filter((contact) => !contact.affecte&&!contact.up)).length                                   
this.dossnonaffecte1=(this.decfiscmenss.filter((decfiscmens) => !decfiscmens.affecte&&!decfiscmens.source
&&
       //@ts-ignore                                                            

decfiscmens.statutcollab.length>0?decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='déposé par le client'||decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='déposé pour le compte du client':''))
this.dossnonaffecte2=((this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte&&!deccomptabilite.source
       //@ts-ignore                                                            

&&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='déposé par le client'||deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='déposé pour le compte du client':'')))
this.dossnonaffecte3=((this.condidates.filter((condidate) => !condidate.affecte)))
this.dossnonaffecte4=((this.contacts.filter((contact) => !contact.affecte&&!contact.up)))
this.dossnonaffecte=[]
this.dossnonaffecte=this.dossnonaffecte.concat(this.dossnonaffecte1,this.dossnonaffecte2,this.dossnonaffecte3,this.dossnonaffecte4)
const sort = new Sort();
this.sorteddossnonaffecte=this.dossnonaffecte.sort(sort.startSort('created','asc',''));
this.paginatedosspasencoreaffecteData()

            return (this.sorteddossnonaffecte);
             }
             getdossiersencourspasencorevalide()
             {
             this.getall()
              this.getalldeccomptabilites()
              this.getalldecfiscmenss()
              this.getcondidatesall()
              this.getcontactsall()
 
  this.dossdecfiscpasencorevalide=(this.decfiscmenss.filter((decfiscmens) => !decfiscmens.affecte&&!decfiscmens.source
        //@ts-ignore                                                            

  &&decfiscmens.statutcollab.length>0?decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='modifié par le client'||decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='saisi pour le compte du client'||decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='modifié pour le compte du client':''
  ||!decfiscmens.affecte&&decfiscmens.statutcollab.length==0)).length
  this.dossdeccomppasencorevalide=(this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte&&!deccomptabilite.source
         //@ts-ignore                                                            

  &&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='modifié par le client'||deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='saisi pour le compte du client'||deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='modifié pour le compte du client':''
  ||!deccomptabilite.affecte&&deccomptabilite.statutcollab.length==0)).length                                   
                                     
this.dosspasencorevalide1=(this.decfiscmenss.filter((decfiscmens) => !decfiscmens.affecte&&!decfiscmens.source
       //@ts-ignore                                                            

&&decfiscmens.statutcollab.length>0?decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='modifié par le client'||decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='saisi pour le compte du client'||decfiscmens.statutcollab[decfiscmens.statutcollab.length-1].statutclient=='modifié pour le compte du client':''
||!decfiscmens.affecte&&decfiscmens.statutcollab.length==0))
this.dosspasencorevalide2=((this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte&&!deccomptabilite.source
       //@ts-ignore                                                            

&&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='modifié par le client'||deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='saisi pour le compte du client'||deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='modifié pour le compte du client':''
||!deccomptabilite.affecte&&deccomptabilite.statutcollab.length==0)))

this.dosspasencorevalide=[]
this.dosspasencorevalide=this.dosspasencorevalide.concat(this.dosspasencorevalide1,this.dosspasencorevalide2)
const sort = new Sort();
this.sorteddosspasencorevalide=this.dosspasencorevalide.sort(sort.startSort('created','asc',''));
this.paginatedosspasencorevalideData()
  return (this.sorteddosspasencorevalide);  
             }
             getalldecfiscmenss() {
                                
              this.showdeccomptabilite=false                                                                  
              this.dec.getdecfiscmenss();
                                                             
                                                               
           } 
           getdecfiscmenssvalide() {
           // this.getalldecfiscmenss()
            this.dossencoursvalide=[]
            this.decfiscmenss.forEach((item, _index) => { 
              if(item.statutadmin.length>0&&item.statutcollab.length>0)
                  {
            //@ts-ignore                                                            
                    if(item.statutadmin[item.statutadmin.length-1].statut=='clôturé'&&item.affecte&&!item.source)
                    {
                      this.dossencoursvalide.push(item)
                    }
                  }
               }
               )
            this.decfiscvali=this.dossencoursvalide.length                   
             return this.dossencoursvalide;                                                           
                                                             
         }  
         getdecfiscmenssvalidecopie() {
        //  this.getalldecfiscmenss()
          this.dossencoursvalidecopie=[]
          this.decfiscmenss.forEach((item, _index) => { 
            if(item.statutadmin.length>0&&item.statutcollab.length>0)
                {
          //@ts-ignore                                                            
                  if(item.statutadmin[item.statutadmin.length-1].statut=='clôturé'&&item.affecte&&item.source)
                  {
                    this.dossencoursvalidecopie.push(item)
                  }
                }
             }
             )
          this.decfiscvalicopie=this.dossencoursvalidecopie.length                   
           return this.dossencoursvalidecopie;                                                           
                                                           
       }  
         getdecfiscmenssnonvalide() {
        //  this.getalldecfiscmenss()
          this.dossencoursnonvalide=[]
          this.decfiscmenss.forEach((item, _index) => { 
            if(item.statutadmin.length>0&&item.statutcollab.length>0)
                {
          //@ts-ignore                                                            
                  if(item.statutadmin[item.statutadmin.length-1].statut!='clôturé'&&item.affecte&&!item.source)
                  {
                    this.dossencoursnonvalide.push(item)
                  }
                }
             }
             )
          this.decfiscnonvali=this.dossencoursnonvalide.length                   
           return this.dossencoursnonvalide;                                                          
                                                           
       } 
           getalldeccomptabilites() {   
            this.showdeccomptabilite=false                                
            this.deccompt.getdeccomptabilites();
         }
         getdeccomptabilitesvalide() {
        //  this.getalldeccomptabilites()
                      //@ts-ignore                    
                      
          this.deccomptvalid=(this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte&&!deccomptabilite.source    
                        //@ts-ignore                                                            
 
          &&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='clôturé':'')).length                                      
                      //@ts-ignore                    

          return (this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte&&!deccomptabilite.source     
                        //@ts-ignore                                                            

          &&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='clôturé':''));                                                           
                                                           
       }  
       getdeccomptabilitesvalidecopie() {
      //  this.getalldeccomptabilites()
this.deccomptvalidcopie=(this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte&&deccomptabilite.source    
          //@ts-ignore                                                            

&&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='clôturé':'')).length                                      
        //@ts-ignore                    

return (this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte&&deccomptabilite.source     
          //@ts-ignore                                                            

&&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut=='clôturé':''));                                                           
                                             
}  
       getdeccomptabilitesnonvalide() {
      //  this.getalldeccomptabilites()
                     //@ts-ignore                    
                       
         this.deccompnonval=(this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.statutadmin.find(e => e.statut==='clôturé')&&deccomptabilite.affecte&&!deccomptabilite.source)).length                                       
                    //@ts-ignore                    

         return this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.statutadmin.find(e => e.statut==='clôturé')&&deccomptabilite.affecte&&!deccomptabilite.source);                                                           
                                                         
     }                     
             getalldeleted() {
                                
                                                
              this.UserService.getAlldeleted();
              this.del=this.usersdeleted.length                                              
                                                               
           } 
              getcondidatesbyemail() {
                                                                                
                this.email=this.searchForm.get('email').value;
                this.cond.getCondidate(this.email);
                                                                                                
                                                                                                 
             }
              getcondidatesall() {
                                                                                
                                                          
                this.cond.getCondidates();
                                                                                                                
                                                                                                                 
             }
             getcondidatevalide() {
                           //@ts-ignore                    
                 
               this.condval=(this.condidates.filter((condidate) => condidate.statutadmin.find(e => e.statut==='clôturé'))).length                                 
                          //@ts-ignore                    

               return this.condidates.filter((condidate) => condidate.statutadmin.find(e => e.statut==='clôturé'));                                                           
                                                               
           }
           getcondidatenonvalide() {
                         //@ts-ignore                    
                   
             this.condnonal=(this.condidates.filter((condidate) => !condidate.statutadmin.find(e => e.statut==='clôturé')&&condidate.affecte)).length                                   
                        //@ts-ignore                    

             return this.condidates.filter((condidate) => !condidate.statutadmin.find(e => e.statut==='clôturé')&&condidate.affecte);                                                           
                                                             
         }
            getcontactreqsbydateinf() {
                                                                                
              
              this.cont.getContactreqsinf(this.searchForm.get('date').value);
                                                                                              
                                                                                               
           }
           getcontactreqsbydatesup() {
                                                                                
            
            this.cont.getContactreqssup(this.searchForm.get('date').value);
                                                                                            
                                                                                             
         }
          getcontactsall() {
                                                                              
                                                        
              this.cont.getContactreqs();
                                                                                                              
                                                                                                               
           }
           getcontactvalide() {
                         //@ts-ignore                    
                   
             this.contval=(this.contacts.filter((contact) => contact.statutadmin.find(e => e.statut==='clôturé'))).length                                   
                        //@ts-ignore                    

             return this.contacts.filter((contact) => contact.statutadmin.find(e => e.statut==='clôturé'));                                                           
                                                             
         }
         getcontactnonvalide() {
                      //@ts-ignore                    
                      
          this.contnonval=(this.contacts.filter((contact) => !contact.statutadmin.find(e => e.statut==='clôturé')&&contact.affecte)).length                                      
                      //@ts-ignore                    

          return this.contacts.filter((contact) => !contact.statutadmin.find(e => e.statut==='clôturé')&&contact.affecte);                                                           
                                                           
       }
           exportusersAsXLSX(source:any[],name:string):void {
            this.excelService.exportAsExcel(source, name);
          }
          onTabClick(_event) {
   
          }
          click1()
          {
this.clientactif=true
          }
          click2()
          {
      this.clientbloque=true      
          }
          click3()
          {
         this.clientsupptemporairement=true   
          }
          click4()
          {
            this.collaborateurs=true
          }
          click5()
          {
            this.consultants=true
          }
          click6()
          {
            this.candidat=true
          }
          click7()
          {
            this.decfiscmensvalide=true
          }
          click8()
          {
            this.decfiscmensnonvalide=true
          }
          click9()
          {
            this.deccomptabilitevalide=true
          }
          click10()
          {
            this.deccomptabilitenonvalide=true
          }
          click11()
          {
            this.candidaturevalide=true
          }
          click12()
          {
            this.candidaturenonvalide=true
          }
          click13()
          {
            this.reclamationtraite=true
          }
          click14()
          {
            this.reclamationnontraite=true
          }
          click29()
          {
            this.showdossencours=true
          }
          click30()
          {
            this.showdosspasencoreaffecte=true
          }
          click37()
          {
            this.showdosspasencorevalide=true
          }
          click15()
          {
this.clientactif=false
          }
          click16()
          {
      this.clientbloque=false      
          }
          click17()
          {
         this.clientsupptemporairement=false   
          }
          click18()
          {
            this.collaborateurs=false
          }
          click19()
          {
            this.consultants=false
          }
          click20()
          {
            this.candidat=false
          }
          click21()
          {
            this.decfiscmensvalide=false
          }
          click22()
          {
            this.decfiscmensnonvalide=false
          }
          click23()
          {
            this.deccomptabilitevalide=false
          }
          click24()
          {
            this.deccomptabilitenonvalide=false
          }
          click25()
          {
            this.candidaturevalide=false
          }
          click26()
          {
            this.candidaturenonvalide=false
          }
          click27()
          {
            this.reclamationtraite=false
          }
          click28()
          {
            this.reclamationnontraite=false
          }
          click31()
          {
            this.showdossencours=false
          }
          click32()
          {
            this.showdosspasencoreaffecte=false
          }
          click38()
          {
            this.showdosspasencorevalide=false
          }
          click33()
          {
            this.showdallusers=true
          }
          click34()
          {
            this.showdallusers=false
          }
          click35()
          {
            this.showconnected=true
          }
          click36()
          {
            this.showconnected=false
          }
          click39()
          {
            this.decfiscmensvalidecopie=false
          }
          click40()
          {
            this.decfiscmensvalidecopie=true
          }
          click41()
          {
            this.deccomptabilitevalidecopie=false
          }
          click42()
          {
            this.deccomptabilitevalidecopie=true
          }
          click43()
          {
            this.otheruser.getOtherdsalldata()
            this.deccompt.getDeccomptabilitereqByfacture()
            this.dec.getdecfiscmensreqByfacture()
            this.alldssusernonpayewindow=true
          }
          click44()
          {
            this.otheruser.getOtherdsalldata()
            this.deccompt.getDeccomptabilitereqByfacture()
            this.dec.getdecfiscmensreqByfacture()
            this.alldssuserpayewindow=true
          }
          click45()
          {
            this.alldssusernonpayewindow=false
          }
          click46()
          {
            this.alldssuserpayewindow=false
          }
          click47()
          {
            this.invo.getInvoicealldata()
            this.allinvoicewindow=true
          }
          click48()
          {
            this.alldssusermarquepayewindow=false

          }
          click49()
          {
            this.allproformatwindow=false
          }
          click50()
          {
            this.allinvoicewindow=false

          }
          click51()
          {
            this.proinv.getProformatinvoicealldata()
            this.allproformatwindow=true

          }
          click52()
          {
            this.otheruser.getOtherdsalldata()
            this.deccompt.getDeccomptabilitereqByfacture()
            this.dec.getdecfiscmensreqByfacture()
            this.alldssusermarquepayewindow=true
          }
          click53()
          {
            this.usedres.getUsedressourcealldata()
            this.paginateusedressourcesData()
            this.usedreslength=this.usedressources.length
            this.usedressourcewindow=true
          }
          click54()
          {
            this.usedressourcewindow=false
          }
          click55()
          {
            this.otheruser.getOtherdsalldatawithoutsubscription().then(
              (otherdss:any) => {
                this.otherdss = otherdss.filter(e=>new Date(e.created) >=new Date('11.30.2023'));
                this.payedotherdss= this.otherdss.filter(e=>e.paye==true&&!e.marquepaye)
                this.marquepayedotherdss= this.otherdss.filter(e=>e.paye==true&&e.marquepaye==true)
                this.nonpayedotherdss= this.otherdss.filter(e=>e.paye==false)
                this.loading = false;
                this.cdr.markForCheck() 
                this.deccompt.getDeccomptabilitereqByfacturewithoutsubscription().then(
                  (deccomptabilities:any) => {
                    this.dsdeccomptabilites = deccomptabilities.filter(e=>new Date(e.created)>=new Date('11.30.2023'));
                    this.payedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==true&&!e.marquepaye)
                    this.marquepayedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==true&&e.marquepaye==true)
                    this.nonpayedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==false)
                    this.loading = false;
                    this.cdr.markForCheck()  
                    this.dec.getdecfiscmensqByfacturewithoutsubscription().then(
                      (decfiscmenss:any) => {
                        this.dsdecfiscmenss = decfiscmenss.filter(e=>new Date(e.created)>=new Date('11.30.2023')&&e.origine!='généré automatiquement');
                        this.payedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==true&&!e.marquepaye)
                        this.marquepayedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==true&&e.marquepaye==true)
                        this.nonpayedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==false)
                        this.loading = false;
                        this.cdr.markForCheck()  
                        this.getalldsusernonpaye()
                        this.notificationrec.getallnotificationrec()
                        this.showmodulerecouvrement=true
                      }
                    )
                  }
                )
              }
            )           
          }
          click56()
          {
            this.showmodulerecouvrement=false
          }
          closecopPopup()
          {
            this.displaydsnonpaye='none'
          }
          closefiltredresults()
          {
            this.displaysearchresults='none'
          }
          closefiltredresultsalldsnonpaye()
          {
            this.displaysearchresultsalldsnonpaye='none'
          }
          closenotifrecpopup()
          {
            this.displaynotifrec='none'
          }
         /* refresh()
          {
            this.showdeccomptabilite=false
            this.getalldeccomptabilites()
            this.getalldecfiscmenss()
            this.getcondidatesall()
            this.getcontactsall()
          }*/
          //pagination methods
          onPageChangedosspasencorevalide(event: PageEvent): void {
            this.pageSize = event.pageSize;
            this.currentPage = event.pageIndex;
            this.paginatedosspasencorevalideData();
          }
        
          paginatedosspasencorevalideData(): void {
            const start = this.currentPage * this.pageSize;
            const end = start + this.pageSize;
            this.paginateddosspasencorevalide = this.sorteddosspasencorevalide.slice(start, end);
          }
          onPageChangedosspasencoreaffecte(event: PageEvent): void {
            this.pageSize = event.pageSize;
            this.currentPage = event.pageIndex;
            this.paginatedosspasencoreaffecteData();
          }
        
          paginatedosspasencoreaffecteData(): void {
            const start = this.currentPage * this.pageSize;
            const end = start + this.pageSize;
            this.paginateddosspasencoreaffecte = this.sorteddossnonaffecte.slice(start, end);
          }
          onPageChangedossencours(event: PageEvent): void {
            this.pageSize = event.pageSize;
            this.currentPage = event.pageIndex;
            this.paginatedossencoursData();
          }
        
          paginatedossencoursData(): void {
            const start = this.currentPage * this.pageSize;
            const end = start + this.pageSize;
            this.paginateddossencours = this.sorteddossencours.slice(start, end);
          }
          onPageChangeusedressources(event: PageEvent): void {
            this.pageSize = event.pageSize;
            this.currentPage = event.pageIndex;
            this.paginateusedressourcesData();
          }
        
          paginateusedressourcesData(): void {
            const start = this.currentPage * this.pageSize;
            const end = start + this.pageSize;
            this.paginatedusedressources = this.usedressources.slice(start, end);
          }
          //archivated invoices
          showproinvoicearchivated()
          {
            this.allproformatarchivewindow=true
          }
          showinvoicearchivated()
          {
            this.allinvoicearchivewindow=true
          }
          closeproformaarchivated()
          {
            this.allproformatarchivewindow=false
          }
          closeinvoicearchivated()
          {
            this.allinvoicearchivewindow=false
          }
          onFiltersSubmittedinvoices(filterArray: any[]) {
            // Send the request to the backend with the filter criteria
            this.invo.getinvoicearchivatedalldata(filterArray)
          }
          onFiltersSubmittedproinvoices(filterArray: any[]) {
            // Send the request to the backend with the filter criteria
            this.proinv.getProformatinvoicearchivatedalldata(filterArray)
          }
          ngOnDestroy(){
            this.condidatesSub?this.condidatesSub.unsubscribe():'';
            this.contactsSub?this.contactsSub.unsubscribe():''
            this.decfiscmenssSub?this.decfiscmenssSub.unsubscribe():''
            this.deccomptabilitesSub?this.deccomptabilitesSub.unsubscribe():''
            this.allotherdsSub?this.allotherdsSub.unsubscribe():''
            this.alldeccomptabSub?this.alldeccomptabSub.unsubscribe():''
            this.alldecfiscmensSub?this.alldecfiscmensSub.unsubscribe():''
            this.allproformatinvoiceSub?this.allproformatinvoiceSub.unsubscribe():''
            this.allinvoiceSub?this.allinvoiceSub.unsubscribe():''
            this.usedressub?this.usedressub.unsubscribe():''

            }
}
  