import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
const API_URL_test = 'http://localhost:3002/api/commun/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/commun/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({
  providedIn: 'root'
})
export class CommunService {
  constructor(private http: HttpClient) { }
public time:any
    statuscollab:string[]=['traité']
    statusadmin:string[]=['en cours de supervision','supervisé','en cours de validation','validé','en cours de clôture','clôturé','à rectifié']
    nature: any[]=["Personne Physique","Personne Morale"];
    regimeimpot: any[]=["Réel","Forfait D'assiette","Non soumis"];
    natureactivite: any[]=["Profession Libérale","associations et syndics","Commerçant","Artisan","Salarié ou Pensionnaire","société" ];
    activites: any[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
    "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire","Huissiers notaire (de justice)", "Interprètes",
    "Ingénieurs-conseil","Topographes","Syndic des copropriétaires","Ventes en gros","Ventes en détail","Consommation sur place","Pharmacie","Batiment et travaux annexes","Réparation automobiles","Autre"
  ,"Menuiserie","Commerciale","Industrielle","Service"];
    sousactivites: any[]=["Avocat","Avocat à la cour d'appel","Avocat à la cour de cassation","Médecin","Médecin spécialiste","Médecin dentiste","Médecin vétérinaire","pièces auto"
  ,"produits dives","étagères métalliques","Fruits secs","Pnematique","Café","Restaurant","Plomberie et climatisation","Electricité Batiment","Maçonnerie","Mécanique Auto","Tolerie"
,"Electricité Auto","Menuiserie Bois","Menuiserie Aluminium","Commerce international","Fabrication de produits chimiques","Activités informatiques","Consultant","Agence de voyage",
"Agence de publicité","Information et promotion médicale et scientifique","Organisation des séminaires","Organisation des évènements"];
    specialites: any[]=["Chirurgie générale",
    "Chirurgie pédiatrique",  
    "Chirurgie carcinologique",
    "Chirurgie cardio-vasculaire",
    "Chirurgie vasculaire périphérique",
    "Chirurgie neurologique",
    "Chirurgie orthopédique et traumatologique",
    "Chirurgie plastique, réparatrice et esthétique",
    "Chirurgie urologique",
    "Gynéco-obstétrique",
    "ORL",
    "Stomatologie et chirurgie maxillo-faciale",
    "Ophtalmologie",
    "Chirurgie thoracique",
    "Anesthésie réanimation",
    "Psychiatrie",
    "Pédo-psychiatrie",
    "Imagerie médicale",
    "Anatomie",
    "Anatomie et cytologie pathologiques",
    "Carcinologie médicale",
    "Cardiologie",
    "Dermatologie",
    "Endocrinologie",
    "Gastro-entérologie",
    "Hématologie clinique",
    "Maladies infectieuses",
    "Médecine d’urgence",
    "Médecine de travail",
    "Médecine interne",
    "Médecine légale",
    "Médecine physique, rééducation et réadaptation fonctionnelle",
    "Médecine préventive et communautaire",
    "Néphrologie",
    "Neurologie",
    "Nutrition et maladies nutritionnelles",
    "Pédiatrie",
    "Pneumologie",
    "Radiothérapie carcinologique",
    "Réanimation médicale",
    "Rhumatologie",
    "Biophysique et médecine nucléaire",
    "Génétique",
    "Biologie médicale option biochimie",
    "Biologie médicale option hématologie",
    "Biologie médicale option parasitologie",
    "Biologie médicale option microbiologie",
    "Biologie médicale option immunologie",
    "Histo-embryologie",
    "Pharmacologie",
    "Physiologie et explorations fonctionnelles"];
    sousspecialites: any[]=[];
    sousactivitesavocat: string[]=["Avocat","Avocat à la cour d'appel","Avocat à la cour de cassation"];
    sousactivitesmedecin: string[]=["Médecin","Médecin spécialiste","Médecin dentiste","Médecin vétérinaire"];
    specialitesmedecinspecialiste: string[]=["Chirurgie générale",
    "Chirurgie pédiatrique",
    "Chirurgie carcinologique",
    "Chirurgie cardio-vasculaire",
    "Chirurgie vasculaire périphérique",
    "Chirurgie neurologique",
    "Chirurgie orthopédique et traumatologique",
    "Chirurgie plastique, réparatrice et esthétique",
    "Chirurgie urologique",
    "Gynéco-obstétrique",
    "ORL",
    "Stomatologie et chirurgie maxillo-faciale",
    "Ophtalmologie",
    "Chirurgie thoracique",
    "Anesthésie réanimation",
    "Psychiatrie",
    "Pédo-psychiatrie",
    "Imagerie médicale",
    "Anatomie",
    "Anatomie et cytologie pathologiques",
    "Carcinologie médicale",
    "Cardiologie",
    "Dermatologie",
    "Endocrinologie",
    "Gastro-entérologie",
    "Hématologie clinique",
    "Maladies infectieuses",
    "Médecine d’urgence",
    "Médecine de travail",
    "Médecine interne",
    "Médecine légale",
    "Médecine physique, rééducation et réadaptation fonctionnelle",
    "Médecine préventive et communautaire",
    "Néphrologie",
    "Neurologie",
    "Nutrition et maladies nutritionnelles",
    "Pédiatrie",
    "Pneumologie",
    "Radiothérapie carcinologique",
    "Réanimation médicale",
    "Rhumatologie",
    "Biophysique et médecine nucléaire",
    "Génétique",
    "Biologie médicale option biochimie",
    "Biologie médicale option hématologie",
    "Biologie médicale option parasitologie",
    "Biologie médicale option microbiologie",
    "Biologie médicale option immunologie",
    "Histo-embryologie",
    "Pharmacologie",
    "Physiologie et explorations fonctionnelles"];
    activitesassociation: string[];
    activitesassociations: any[]=["Syndic des copropriétaires"];
    activitesliberales: string[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
    "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire","Huissiers notaire (de justice)", "Interprètes",
    "Ingénieurs-conseil","Topographes","Autre"];
    annees=['2021','2022','2023']
    MIME_TYPES = {
      'image/jpg': 'jpg',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/octet-stream': 'octet-stream'
    };
    
    getcurrenttime() {
      return new Promise((resolve, reject) => {
        

        this.http.get(API_URL_cloud +'/currenttime').subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error); 
          }
        );
      });
    }
  sortByDate(a, b) 
  {
      if (a.jour < b.jour) {
          return 1;
      }
      if (a.jour > b.jour) {
          return -1;
      }
      return 0;
  }
  filterMois(annee,user:User) :any
  {
    // Array to hold the filtered months
    let filtredmois = [];
  
    // Object array representing all months
    const allMonths = [
      { value: "01", label: "(01) janvier" },
      { value: "02", label: "(02) février" },
      { value: "03", label: "(03) Mars" },
      { value: "04", label: "(04) avril" },
      { value: "05", label: "(05) Mai" },
      { value: "06", label: "(06) juin" },
      { value: "07", label: "(07) juillet" },
      { value: "08", label: "(08) août" },
      { value: "09", label: "(09) septembre" },
      { value: "10", label: "(10) octobre" },
      { value: "11", label: "(11) novembre" },
      { value: "12", label: "(12) décembre" }
    ];
  
    // Check if user.exercicecloture is empty
    if (!user.exercicecloture || user.exercicecloture.length === 0) {
      // If empty, consider all months as not closed
      filtredmois = [...allMonths];
    } else {
      // Find the object in user.exercicecloture for the selected year
      const yearRecord = user.exercicecloture.find(e => e.annee === annee);
  
      if (!yearRecord || yearRecord.cloture === false) {
        // If no record found for the year or the year is not fully closed, include all months
        filtredmois = [...allMonths];
      } else {
        // Handle the case where moisdebut and moisfin could be undefined
        const moisdebut = yearRecord.moisdebut ? parseInt(yearRecord.moisdebut, 10) : null;
        const moisfin = yearRecord.moisfin ? parseInt(yearRecord.moisfin, 10) : null;
  
        if (moisdebut !== null && moisfin !== null && yearRecord.cloture === true) {
          // If moisdebut and moisfin are defined and the year is partially closed
          // Include only months outside the range between moisdebut and moisfin
          filtredmois = allMonths.filter(mois => {
            const moisNumber = parseInt(mois.value, 10);
            return moisNumber < moisdebut || moisNumber > moisfin;
          });
        } else {
          // If moisdebut or moisfin are undefined, treat all months as open
          filtredmois = [...allMonths];
        }
      }
    }
  
    return filtredmois;
  }
}