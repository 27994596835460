import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dt-comp',
  templateUrl: './dt-comp.component.html',
  styleUrls: ['./dt-comp.component.scss']
})
export class DTCOMPComponent implements OnInit,OnDestroy {
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  dtform: FormGroup;
  formValueChangesSubscription: Subscription;
  total1: number=0;
  total2: number=0;
  total3: number=0;
  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.dtform.reset();
  }
  ngOnInit(): void {
    // Initialize forms
    const savedFormState = this.formStateService.getdtFormState();
    if (savedFormState) {
      this.dtform = savedFormState;
    //  this.updateSumAndTotal();
    } else {
      this.dtform = this.fb.group({
        v1: [0, [Validators.min(0)]],
      v2: [{ value: 0, disabled: true }],
      v3: [0, [Validators.min(0)]],
      v4: [0, [Validators.min(0)]],
      v5: [{ value: 0, disabled: true }],
      v6: [0, [Validators.min(0)]],
      v7: [0, [Validators.min(0)]],
      v8: [{ value: 0, disabled: true }],
      v9: [0, [Validators.min(0)]],
      v10: [0, [Validators.min(0)]],
      v11: [{ value: 0, disabled: true }],
      v12: [0, [Validators.min(0)]],
      v13: [0, [Validators.min(0)]],
      v14: [{ value: 0, disabled: true }],
      v15: [0, [Validators.min(0)]],
      v16: [0, [Validators.min(0)]],
      v17: [{ value: 0, disabled: true }],
      v18: [0, [Validators.min(0)]],
      v19: [{ value: 0, disabled: true }]

      });
    }
    this.setupAutoCalculation('v18','v17');
  }
  ngOnDestroy(): void {
        // Save the form state when the component is destroyed
        this.formStateService.setdtFormState(this.dtform);
    // Unsubscribe from value changes to prevent memory leaks
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
    this.goto(1)
  }
  setupAutoCalculation(sourceControlName: string, targetControlName: string): void {
    this.dtform.get(sourceControlName).valueChanges.subscribe(value => {
      const targetControl = this.dtform.get(targetControlName);
      if (targetControl) {
        targetControl.setValue(Math.floor( (value * 1)*1000)/1000, { emitEvent: false });
        this.dtform.get('v19').setValue(Math.floor( (value * 1)*1000)/1000, { emitEvent: false })
      }
    });
  }

  /*updateSumAndTotal(): void {
    this.total2 = 0;
    this.total3 = 0;

    Object.keys(this.dtform.controls).forEach(key => {
      const control = this.dtform.get(key);
      if (control && +key==17) {
        this.sumOfdisabledControls += control.value;
      }
    });
  }*/

  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }

  previous() {
    this.previousStep.emit();
  }
}