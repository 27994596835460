import { Component, OnInit } from '@angular/core';
import { isSameDay, isSameMonth, startOfDay, endOfDay } from 'date-fns'; 
import { CalendarWeekViewComponent, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { HttpClient } from '@angular/common/http';
import { ApiServiceService } from '../services/event.service';
import Swal from 'sweetalert2';
import { Events } from '../models/event.model';
import { Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-calendar-fiscality',
  templateUrl: './calendar-fiscality.component.html',
  styleUrls: ['./calendar-fiscality.component.scss'],
})
export class CalendarFiscalityComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  dayStartHour: number = 0;
  dayEndHour: number = 24;
  CalendarView = CalendarView;
  events: CalendarEvent[] = [];
  activeDayIsOpen = false;
  currentUser: any;

  constructor(
    public http: HttpClient,
    private apiService: ApiServiceService,
    private router: Router,
    private token: TokenStorageService
  ) {
    this.fetchEvents();
  }

  ngOnInit() {
    this.currentUser = this.token.getUser();
    
  }
  prevMonth(): void {
    this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() - 1, 1);
  }

  nextMonth(): void {
    this.viewDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 1);
  }

  prevYear(): void {
    this.viewDate = new Date(this.viewDate.getFullYear() - 1, this.viewDate.getMonth(), 1);
  }

  nextYear(): void {
    this.viewDate = new Date(this.viewDate.getFullYear() + 1, this.viewDate.getMonth(), 1);
  }
  fetchEvents(): void {
    this.apiService.getAllEvents().subscribe(
      (data: any[]) => {
        this.events = data.map(eventData => ({
          start: new Date(eventData.date), // Assuming `date` is the start date of the event
          end: new Date(eventData.date),   // Assuming single-day events, set end to the same as start
          title: eventData.title,          // Use `title` for event title
          id: eventData._id,
          color: {                         // Optional: Customize event color
            primary: '#1e90ff',
            secondary: '#D1E8FF'
          },
          // Add other properties if needed
        }));
      },
      (error) => {
        console.error('Error fetching events', error);
      }
    );
  }
  

  deleteEvent(id: string): void {
    this.apiService.deleteSingleEvent(id).then(
      (data: any) => {
        Swal.fire('Event deleted successfully!', '', 'success');
        this.fetchEvents(); // Refresh events
      },
      (error) => {
        Swal.fire('Error deleting event', '', 'error');
      }
    );
  }

  deleteAll(): void {
    this.apiService.deleteAllEvents().then(
      (data: any) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'All events deleted successfully!',
          showConfirmButton: false,
          timer: 2000
        });
        this.fetchEvents();
      },
      (error) => {
        Swal.fire('Error deleting all events', '', 'error');
      }
    );
  }

  viewEvent(id: string): void {
    this.router.navigate(['view-event/' + id]);
  }

  getNavigation(link: string): void {
    this.router.navigate([link]);
  }

  setView(view: CalendarView): void {
    this.view = view;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  navigateToEvent(event: any) {
    this.apiService.geteventreqById(event.event.id).then((data: any) => {});
    this.router.navigate(['view-event/' + event.event.id]);
  }
}
