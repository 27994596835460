
<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>
<body>
  

<div class="bodycontainer">
  <div id="loader" *ngIf="loading"></div>
  
  <div class="padding container-fluid d-flex justify-content-center"*ngIf="currentuser.role=='admin'&&contact">
    
    <fieldset >
      <legend> Formulaire De validation des déclarations mensuelles(admin)</legend>
      <h3><span> Date ouverture dossier: </span>{{option204Value | customDate}}</h3>
    <form [formGroup]="contactFormadmin">
      <button type="button" class="btn btn-primary mb-2" (click)="addammount1()">Ajouter statut</button>
  <fieldset formArrayName="ammounts1" *ngFor="let ammount of ammountControls1; let i = index;">
    <div [formGroupName]="i">
      <div class="row">
        
        <div class="container-fluid">
          <div class="col-6">
            <div class="form-group">
              <label for="fq_name">Statut</label>    
              <select  class="form-control btn-block" placeholder="Entrer Votre décision" id="fiscalimpot" id="statut" formControlName="statut" >
                <option value="default"> </option>
                <option *ngFor="let statut of statusadmin" [value]="statut">
                    {{statut}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="fq_email">Motif</label>
              <textarea cols="30" rows="10"type="text" class="form-control btn-block" placeholder="Indiquer votre motif" id="motif" formControlName="motif"></textarea>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
                <label>Date de fin de traitement</label>
                <input type="text" class="form-control" [value]="contactFormadmin.controls['ammounts1'].controls[i].controls.datefin.value | customDate" formControlName="datefin" readonly='true'/>
                <div class="col-6"style="width:20px">
                  <input type="checkbox" id="{{ 'myCheck3' + i }}" (change)="finadmin(i)"  formControlName=fintraitement/>
               </div>
              </div>
        </div>
          <div class="col-6">
            <div class="form-group">
                <label>Durée de traitement</label>
                <input type="text" class="form-control" formControlName="duree" readonly='true'/>
              </div>
        </div>
        </div>
      </div>
    </div>
      </fieldset>
      <div class="text-right">
        <input type="submit" class="hidden" value="Submit"/>
    </div>
    </form>
  </fieldset>
  <div class="form-group">
    <input type="submit" class="btn btn-primary text-white py-2 px-4 btn-block" (click)="onSubmit()"value="Sauvegarder Décision"[disabled]="contactFormadmin.invalid">  
  </div> 
    </div>
    <div class="padding container-fluid d-flex justify-content-center"*ngIf="role=='supervisor'&&contact||currentuser.rolesuperviseur=='Autorisé'&&contact">
      <fieldset >
        <legend> Formulaire De validation des déclarations mensuelles(collab)</legend>
        <h3><span> Date ouverture dossier</span>{{option204Value | customDate}}</h3>
        <form [formGroup]="contactFormcollab">
        <button type="button" class="btn btn-primary mb-2" (click)="addammount2()">Ajouter Statut</button>
    <fieldset formArrayName="ammounts2" *ngFor="let ammount of ammountControls2; let i = index;">
      <div [formGroupName]="i">
        <div class="row">
          
          <div class="container-fluid">
            <div class="col-6">
              <div class="form-group">
                <label for="fq_name">Statut</label>    
                <select  class="form-control btn-block" placeholder="Entrer Votre décision" id="fiscalimpot" id="statutcoll" formControlName="statutcoll" >
                  <option value="default"> </option>
                  <option *ngFor="let statut of statuscollab" [value]="statut">
                      {{statut}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="fq_email">Motif</label>
                <textarea cols="30" rows="10"type="text" class="form-control btn-block" placeholder="Indiquer votre motif" id="motifcoll" formControlName="motifcoll"></textarea>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                  <label>Date de fin de traitement</label>
                  <input type="text" class="form-control" [value]="contactFormcollab.controls['ammounts2'].controls[i].controls.datefin.value | customDate"formControlName="datefin" readonly='true'/>
                  <div class="col-6"style="width:20px">
                    <input type="checkbox" id="{{ 'admin' + i }}" (change)="fincollab(i)"  formControlName=fintraitement/>
                 </div>
                </div>
          </div>
            <div class="col-6">
              <div class="form-group">
                  <label>Durée de traitement</label>
                  <input type="text" class="form-control" formControlName="duree" readonly='true'/>
                </div>
          </div>
          </div>
        </div>
      </div>
        </fieldset>
      </form>
    </fieldset>
    <div class="form-group">
      <input type="submit" class="btn btn-primary text-white py-2 px-4 btn-block" (click)="onSubmitcoll()"value="Sauvegarder Décision"[disabled]="contactFormcollab.invalid">  
    </div>
              </div>
</div>
</body>