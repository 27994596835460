<body>
  <nav class="navbar" appClickOutside (clickOutside)="clickedOutside()">
      <img src="../../assets/logoMaCompta.png" alt="logo Macompta" routerLink="home" class="logoHeader">
      <div class="menu-wrapper">
          <img src="../../assets/menu.png" 
               onmouseover="this.src='../../assets/menuHover.png';" 
               onmouseout="this.src='../../assets/menu.png';" 
               class="menuIcon" 
               (click)="toggleMenu()">
      <div class="menu-container" *ngIf="isMenuOpened">
                <div class="menu">
                    <a routerLink="home" routerLinkActive="active" class="nav-item nav-link active text-secondary">
                        <i class="fas fa-home"></i> Accueil
                    </a>
                    <div class="menu-item" (click)="displaySectionItems()" [attr.aria-expanded]="itemsSectionVisible">
                        <i class="fas fa-list"></i> Sections
                        <ul *ngIf="itemsSectionVisible" class="dropdown-menu">
                            <a routerLink="who" *ngIf="isLoggedIn" routerLinkActive="active" class="dropdown-item">Qui sommes nous?</a>
                            <a title=" (veuillez vous inscrire/se connecter)" routerLink="login" *ngIf="!isLoggedIn" class="dropdown-item">Qui sommes nous?</a>
                            <a routerLink="why" *ngIf="isLoggedIn" routerLinkActive="active" class="dropdown-item">Pourquoi choisir Macompta</a>
                            <a title=" (veuillez vous inscrire/se connecter)" routerLink="login" *ngIf="!isLoggedIn" class="dropdown-item">Pourquoi choisir Macompta</a>
                            <a routerLink="/home/contact" target="_blank" routerLinkActive="active" class="dropdown-item">Contact</a>
                            <a routerLink="career" routerLinkActive="active" class="dropdown-item">Carrière</a>
                        </ul>
                    </div>
                    <div class="menu-item" (click)="displayespaceItems()" *ngIf="isLoggedIn" [attr.aria-expanded]="itemsespaceVisible">
                        <i class="fas fa-user"></i> Espace utilisateur
                        <ul *ngIf="itemsespaceVisible" class="dropdown-menu">
                            <a routerLink="admin-board" *ngIf="role=='admin'" routerLinkActive="active" class="dropdown-item">Tableau de bord Admin</a>
                            <a routerLink="settings" *ngIf="role=='admin'" routerLinkActive="active" class="dropdown-item">Paramêtrage Admin</a>
                            <a routerLink="addotherds" *ngIf="role=='admin'||role=='supervisor'" routerLinkActive="active" class="dropdown-item">Ajouter autre DS</a>
                            <a routerLink="declare-comptabilite" *ngIf="role=='admin'||role=='supervisor'" routerLinkActive="active" class="dropdown-item">Saisie pour le compte du client</a>
                            <a routerLink="user-board" *ngIf="role=='basic'" routerLinkActive="active" class="dropdown-item">Tableau de bord Utilisateur</a>
                            <a routerLink="collab-board" *ngIf="usertype=='Collaborateur'" routerLinkActive="active" class="dropdown-item">Tableau de bord Collaborateur</a>
                        </ul>
                    </div>
                    <div class="menu-item" (click)="displayserviceItems()" *ngIf="isLoggedIn && usertype!='Candidat'" [attr.aria-expanded]="itemsserviceVisible">
                        <i class="fas fa-concierge-bell"></i> Services en ligne
                        <ul *ngIf="itemsserviceVisible" class="dropdown-menu">
                            <a (click)="infoassiette()" class="dropdown-item">Tenez votre comptabilité</a>
                            <a href="choix-declaration" routerLinkActive="active" class="dropdown-item">Etablissez vos déclarations fiscales</a>
                            <a routerLink="Documentation" routerLinkActive="active" class="dropdown-item">Préparez vos fiches de paie</a>
                            <a routerLink="Documentation" routerLinkActive="active" class="dropdown-item">Demandez un conseil</a>
                            <a routerLink="facturation/invoice-generator" routerLinkActive="active" class="dropdown-item">Module de facturation</a>
                            <a routerLink="dec-employeur/fichierrecap" routerLinkActive="active" class="dropdown-item">Etablir la déclaration de l'employeur</a>

                        </ul>
                    </div>
                </div>
      </div>  
      </div>
      <ul class="section-links" *ngIf="homeroute">
          <li *ngFor="let section of sections" [class.active]="activeSection === section.id">
              <a (click)="scrollToSection(section.id)">{{ section.name }}</a>
          </li>
      </ul>
      <div class="nav-right"*ngIf="isLoggedIn">
          <div class="notif-wrapper"clickOutside (clickOutside)="clickedOutsideNotif()">
              <img src="../../assets/notificationIcon.png" alt="notification icon" class="icon" (click)="displayNotif()">
              <ul class="notification-list" *ngIf="isNotifOpened">
                  <li class="notificationChoice">
                      <p routerLink="user-board/dsapayer" class="notification-item">
                          Voir tous les ds à payer
                          <span class="badge rounded-pill badge-notification bg-danger">{{getalldsusernonpaye()[1]}}</span>
                      </p>
                  </li>
              </ul>
          </div>
          <div class="profile-wrapper"clickOutside (clickOutside)="clickedOutsideProfile()">
              <img src="../../assets/profileIcon.png" alt="profile icon" class="icon" (click)="openProfile()">
              <ul class="profile-list" *ngIf="isProfileOpened && isLoggedIn"routerLink="profil">
                  <li *ngIf="nature === 'Personne Physique' || !nature">
                      <strong routerLink="profil">{{username}} {{lastname}}</strong>
                  </li>
                  <li *ngIf="nature === 'Personne Morale'">
                      <strong routerLink="profil">{{raisonsociale}}</strong>
                  </li>
              </ul>
          </div>
      </div>
      <button class="btn-add-collab" *ngIf="role=='admin'" routerLink="collab-create" routerLinkActive="active">Ajouter collaborateur</button>
      <button class="btn-logout" *ngIf="isLoggedIn" (click)="logout()" routerLink="login" routerLinkActive="active">Se déconnecter</button>
      <button class="btn-login" *ngIf="!isLoggedIn" routerLink="login" (click)="openLoginModal()" routerLinkActive="active">Se connecter</button>
      <button class="btn-signup" *ngIf="!isLoggedIn" (click)="openSignupModal()">Inscription Gratuite</button>
  </nav>
</body>