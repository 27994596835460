

<div class="collab-board">
    <h1>Tableau De Board Collaborateur</h1>
    <div class="line"></div>
    <div class="buttons">
      <button class="btn charger-dossier" (click)="getdossiersencours()" style="cursor: pointer;"><i class="fa fa-spinner"*ngIf="refresh"></i>Charger Dossier</button>
      <button class="btn update-cours"><a routerLink="settings"routerLinkActive="active">Mise à jour du cours de change </a> </button>
    </div>
    <div *ngIf="usertype=='Collaborateur'" class="table-section">
      <h2>Liste des Ordres de services </h2>
      <table>
        <thead>
          <tr>
            <th scope="col">Prenom</th>
                <th scope="col">Nom</th>
                <th scope="col">Mois</th>
                <th scope="col">Annee</th>
                <th scope="col">Type</th>
                <th scope="col">Origine</th>
                <th scope="col">Statut Admin</th>
                <th scope="col">Statut Collaborateur</th>
                <th scope="col">Date de création<i class="fa fa-sort" aria-hidden="true" [appSort]="dossnonaffecte" data-order="desc" data-name="created" ></i></th>
                <th scope="col">date de modification</th>
                <th scope="col">Consultation</th>
          </tr>
        </thead>
        <tbody *ngIf="currentuser.rolesuperviseur=='Autorisé'">
              <tr *ngFor="let sorteddossencour of sorteddossencours">
                <td><span *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'">{{filterusers2(sorteddossencour.userId)}}{{prenomfisc}}</span>
                    <span *ngIf="sorteddossencour.nature==='comptabilite'||sorteddossencour.nature==='déclaration comptable'">{{filterusers(sorteddossencour.userId)}}{{prenom}}</span>
                    <span *ngIf="!sorteddossencour.nature">{{sorteddossencour.firstname}}</span></td>
                  <td><span *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'">{{filterusers2(sorteddossencour.userId)}}{{nomfisc}}</span>
                    <span *ngIf="sorteddossencour.nature==='comptabilite'||sorteddossencour.nature==='déclaration comptable'">{{filterusers(sorteddossencour.userId)}}{{nom}}</span>
                    <span *ngIf="!sorteddossencour.nature">{{sorteddossencour.lastname}}</span></td>
                    <td><span *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'||'comptabilite'||'déclaration comptable'">{{sorteddossencour.mois}}</span></td>
                    <td><span *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'||'comptabilite'||'déclaration comptable'">{{sorteddossencour.annee}}</span></td>    
                  <td><span *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'||'comptabilite'||'déclaration comptable'">{{sorteddossencour.nature}}</span>
                    <span *ngIf="sorteddossencour.type==='candidature'">{{sorteddossencour.type}}</span>
                    <span *ngIf="sorteddossencour.type==='réclamation'">{{sorteddossencour.type}}</span></td>
                    <td><span *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'">{{sorteddossencour.origine}}</span></td>
                    <td>
                      <span *ngIf="sorteddossencour.statutadmin">
                        <ng-container *ngFor="let statut of sorteddossencour.statutadmin">
                          {{statut.statut}}
                        </ng-container>
                      </span>  
                    </td>
                    <td>
                      <span *ngIf="sorteddossencour.statutcollab">
                        <ng-container *ngFor="let statut of sorteddossencour.statutcollab">
                          {{statut.statutcoll}}
                        </ng-container>
                      </span>  
                    </td>                  
                    <td>{{sorteddossencour.created |customDate}}</td>
                  <td>{{sorteddossencour.updated |customDate}}</td>
                  
                  
                  <td>  
                    <button type="button" class="btn btn-success" *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'&&sorteddossencour.origine!='généré automatiquement'"(click)="debutcompteurdecfiscale('view-decfiscmens',sorteddossencour._id)">Consulter</button>
                    <button type="button" class="btn btn-success" *ngIf="sorteddossencour.nature==='comptabilite'||sorteddossencour.nature==='déclaration comptable'"(click)="debutcompteurcomptabilite('view-deccomptabilite',sorteddossencour._id)">Consulter</button>
                    <button type="button" class="btn btn-success" *ngIf="sorteddossencour.type==='candidature'"(click)="getNavigationusers('view-condidate',sorteddossencour._id)">Consulter</button>
                    <button type="button" class="btn btn-success" *ngIf="sorteddossencour.type==='réclamation'"(click)="getNavigationusers('view-contactreq',sorteddossencour._id)">Consulter</button></td>
                    <td><button type="button" class="btn btn-success" *ngIf="sorteddossencour.nature==='Déclaration Mensuelle'"(click)="getadmincollabview('modify-decfiscmens',sorteddossencour._id)"target="_blank">Consulter maquette</button>

              </tr>
        </tbody>
        <tbody *ngIf="currentuser.rolesuperviseur!='Autorisé'">
                <tr>
                  <td><span *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'">{{filterusers2(sorteddossencours.userId)}}{{prenomfisc}}</span>
                      <span *ngIf="sorteddossencours.nature==='comptabilite'||sorteddossencours.nature==='déclaration comptable'">{{filterusers(sorteddossencours.userId)}}{{prenom}}</span>
                      <span *ngIf="!sorteddossencours.nature">{{sorteddossencours.firstname}}</span></td>
                    <td><span *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'">{{filterusers2(sorteddossencours.userId)}}{{nomfisc}}</span>
                      <span *ngIf="sorteddossencours.nature==='comptabilite'||sorteddossencours.nature==='déclaration comptable'">{{filterusers(sorteddossencours.userId)}}{{nom}}</span>
                      <span *ngIf="!sorteddossencours.nature">{{sorteddossencours.lastname}}</span></td>
                      <td><span *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'||'comptabilite'||'déclaration comptable'">{{sorteddossencours.mois}}</span></td>
                    <td><span *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'||'comptabilite'||'déclaration comptable'">{{sorteddossencours.annee}}</span></td>
                    <td><span *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'||'comptabilite'||'déclaration comptable'">{{sorteddossencours.nature}}</span>
                      <span *ngIf="sorteddossencours.type==='candidature'">{{sorteddossencours.type}}</span>
                      <span *ngIf="sorteddossencours.type==='réclamation'">{{sorteddossencours.type}}</span></td>
                      <td><span *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'">{{sorteddossencours.origine}}</span></td>
                      <td>
                        <span *ngIf="sorteddossencours.statutadmin">
                          <ng-container *ngFor="let statut of sorteddossencours.statutadmin">
                            {{statut.statut}}
                          </ng-container>
                        </span>  
                      </td>
                      <td>
                        <span *ngIf="sorteddossencours.statutcollab">
                          <ng-container *ngFor="let statut of sorteddossencours.statutcollab">
                            {{statut.statutcoll}}
                          </ng-container>
                        </span>  
                      </td>                  
                      <td>{{sorteddossencours.created |customDate}}</td>
                    <td>{{sorteddossencours.updated |customDate}}</td>
                    
                    
                    <td>  <button type="button" class="btn btn-success" *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'&&sorteddossencours.origine!='généré automatiquement'"(click)="debutcompteurdecfiscale('view-decfiscmens',sorteddossencours._id)">Consulter</button>
                      <button type="button" class="btn btn-success" *ngIf="sorteddossencours.nature==='comptabilite'||sorteddossencours.nature==='déclaration comptable'"(click)="debutcompteurcomptabilite('view-deccomptabilite',sorteddossencours._id)">Consulter</button>
                      <button type="button" class="btn btn-success" *ngIf="sorteddossencours.type==='candidature'"(click)="getNavigationusers('view-condidate',sorteddossencours._id)">Consulter</button>
                      <button type="button" class="btn btn-success" *ngIf="sorteddossencours.type==='réclamation'"(click)="getNavigationusers('view-contactreq',sorteddossencours._id)">Consulter</button></td>
                      <td><button type="button" class="btn btn-success" *ngIf="sorteddossencours.nature==='Déclaration Mensuelle'"(click)="getadmincollabview('modify-decfiscmens',sorteddossencours._id)"target="_blank">Consulter maquette</button>
                </tr>
              </tbody>
      </table>
    </div>
</div>