import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';
import { DecfiscmensService } from '../../services/dec-fisc-mens';

@Component({
  selector: 'app-tva2',
  templateUrl: './tva2.component.html',
  styleUrls: ['./tva2.component.scss']
})
export class TVA2Component implements OnInit,OnDestroy{
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  tva2form: FormGroup;
  formValueChangesSubscription: Subscription;
  sumOfPositiveControls: number =0;
  sumOfNegativeControls: number =0;
  sumOfpayereporter: number =0;
  sumOfpayereporter1:number =0;
  statepr: string;
  statepr1: string;
  annee: any;
  mois: any;
  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService,private DecfiscmensService :DecfiscmensService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.tva2form.reset();
  }
  ngOnInit(): void {
     // Initialize forms
     const savedFormState = this.formStateService.gettva2FormState();
     const savedtva1FormState = this.formStateService.gettva1FormState();
     const savedFormgardeState = this.formStateService.getGardeFormState();
     if (savedFormgardeState) {
         this.annee = savedFormgardeState.get('annee').value;
         this.mois = savedFormgardeState.get('mois').value;
     }
     savedtva1FormState?this.sumOfPositiveControls = savedtva1FormState.get('totalpositive').value:''
     savedtva1FormState?this.sumOfNegativeControls = savedtva1FormState.get( 'totalnegative').value:''
     if (savedFormState) {
       this.tva2form = savedFormState;
       this.sumOfpayereporter = Math.abs(this.sumOfPositiveControls - this.sumOfNegativeControls);
       this.sumOfpayereporter1=this.sumOfpayereporter + this.tva2form.get('creditMoisPrecedent').value;
       this.sumOfPositiveControls - this.sumOfNegativeControls >= 0 ? this.statepr='P' : this.statepr='R';
       this.sumOfPositiveControls - (this.sumOfNegativeControls + +this.tva2form.get('creditMoisPrecedent').value) >= 0 ? this.statepr1='P' : this.statepr1='R';
       this.tva2form.get('rp').setValue( this.sumOfpayereporter)
          }
     else
     {
            //verify report tva mois précédent
let mois=['01','02','03','04','05','06','07','08','09','10','11','12'] 
let mois1:any
let desiredmois1:any
let verifymois1:any
let verifyannee1:any
let reporttva:any
let selected = (element) => element ===this.mois;
if(this.mois!='01')
{
  mois1=mois.findIndex(selected)
  desiredmois1=mois[+(mois1-1)]
  this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).find(e => verifymois1=(e.mois === desiredmois1&&e.annee === this.annee));

  console.log(verifymois1)
  if(verifymois1)
{ 
  let tvarecuperable=+(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvarecuperableautreachat.achatlocauxtva+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvarecuperableautreachat.achatimportetva+ 
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvarecuperableequipement.achatlocauxtva+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvarecuperableequipement.achatimportetva+ 
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvarecuperableimmobilier.achatlocauxtva+
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.reporttvamoisprecedent

  let tvacollecte= +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvacollecter19.tvaammount+ 
  ((this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvacollecter13?+(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvacollecter13.tvaammount:0)+
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.tvacollecter.tvaammount+ Math.trunc(((+(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.locationhabitationmeuble.htammount+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.locationusagecommercial.htammount
  + +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.operationlotissement.htammount+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.interetpercue.htammount+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois===desiredmois1&&p.annee===this.annee))[0].impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
   
reporttva=(tvarecuperable-tvacollecte).toFixed(3)
this.sumOfpayereporter = Math.abs(this.sumOfPositiveControls - this.sumOfNegativeControls);
this.sumOfPositiveControls - this.sumOfNegativeControls >= 0 ? this.statepr='P' : this.statepr='R';
this.statepr=='P'?this.sumOfpayereporter1=Math.abs(this.sumOfpayereporter - +reporttva):this.sumOfpayereporter1= this.sumOfpayereporter + +reporttva;
this.sumOfPositiveControls - (this.sumOfNegativeControls + +reporttva) >= 0 ? this.statepr1='P' : this.statepr1='R';

if(reporttva>0)
{
  this.tva2form = this.fb.group({
    rp: [this.sumOfpayereporter, [Validators.min(0)]],
    resteAPayerOuReporter: [0, [Validators.min(0)]],
    creditMoisPrecedent: [reporttva, [Validators.min(0)]],
    montantRestitue: [0, [Validators.min(0)]],
    montantDejaRestitue: [0, [Validators.min(0)]],
    reliquatRestituer: [0, [Validators.min(0)]],
    creditTVA: [0, [Validators.min(0)]],
    montantRestitueCredit: [0, [Validators.min(0)]],
    reliquatCreditTVA: [0, [Validators.min(0)]],
    montantCreditDeduction: [0, [Validators.min(0)]],
    extraInput: [0, [Validators.min(0)]],
    reliquatDemandeRestitution: [0, [Validators.min(0)]],
    rp1: [this.sumOfpayereporter1, [Validators.min(0)]],
    resteAPayer: [0, [Validators.min(0)]],
    auCoursDuMois: [0, [Validators.min(0)]],
    auNumero: [0, [Validators.min(0)]],
    variation: [0, [Validators.min(0)]],
    exerciceAnterieur: [0, [Validators.min(0)]],
    exerciceCourant: [0, [Validators.min(0)]],

    // New form controls for the second table
    achatsSuspensionTVA1: [0, [Validators.min(0)]],
    montant1: [0, [Validators.min(0)]],
    achatsSuspensionTVA2: [0, [Validators.min(0)]],
    montant2: [0, [Validators.min(0)]],
    achatsExoneres1: [0, [Validators.min(0)]],
    montant3: [0, [Validators.min(0)]],
    achatsNonAssujettis: [0, [Validators.min(0)]],
    montant4: [0, [Validators.min(0)]],
    autresAchats: [0, [Validators.min(0)]],
    montant5: [0, [Validators.min(0)]],
    exportation: [0, [Validators.min(0)]],
    montant6: [0, [Validators.min(0)]],
    ventesSuspensionTVA: [0, [Validators.min(0)]],
    montant7: [0, [Validators.min(0)]],
    caExonereTVA: [0, [Validators.min(0)]],
    montant8: [0, [Validators.min(0)]],
    cessionAQuai: [0, [Validators.min(0)]],
    montant9: [0, [Validators.min(0)]],
    total: [0, [Validators.min(0)]],
    montantTotal: ['']
  });
}
else
{
  this.tva2form = this.fb.group({
    rp: [this.sumOfpayereporter, [Validators.min(0)]],
    resteAPayerOuReporter: [0, [Validators.min(0)]],
    creditMoisPrecedent: [0, [Validators.min(0)]],
    montantRestitue: [0, [Validators.min(0)]],
    montantDejaRestitue: [0, [Validators.min(0)]],
    reliquatRestituer: [0, [Validators.min(0)]],
    creditTVA: [0, [Validators.min(0)]],
    montantRestitueCredit: [0, [Validators.min(0)]],
    reliquatCreditTVA: [0, [Validators.min(0)]],
    montantCreditDeduction: [0, [Validators.min(0)]],
    extraInput: [0, [Validators.min(0)]],
    reliquatDemandeRestitution: [0, [Validators.min(0)]],
    rp1: [this.sumOfpayereporter1, [Validators.min(0)]],
    resteAPayer: [0, [Validators.min(0)]],
    auCoursDuMois: [0, [Validators.min(0)]],
    auNumero: [0, [Validators.min(0)]],
    variation: [0, [Validators.min(0)]],
    exerciceAnterieur: [0, [Validators.min(0)]],
    exerciceCourant: [0, [Validators.min(0)]],

    // New form controls for the second table
    achatsSuspensionTVA1: [0, [Validators.min(0)]],
    montant1: [0, [Validators.min(0)]],
    achatsSuspensionTVA2: [0, [Validators.min(0)]],
    montant2: [0, [Validators.min(0)]],
    achatsExoneres1: [0, [Validators.min(0)]],
    montant3: [0, [Validators.min(0)]],
    achatsNonAssujettis: [0, [Validators.min(0)]],
    montant4: [0, [Validators.min(0)]],
    autresAchats: [0, [Validators.min(0)]],
    montant5: [0, [Validators.min(0)]],
    exportation: [0, [Validators.min(0)]],
    montant6: [0, [Validators.min(0)]],
    ventesSuspensionTVA: [0, [Validators.min(0)]],
    montant7: [0, [Validators.min(0)]],
    caExonereTVA: [0, [Validators.min(0)]],
    montant8: [0, [Validators.min(0)]],
    cessionAQuai: [0, [Validators.min(0)]],
    montant9: [0, [Validators.min(0)]],
    total: [0, [Validators.min(0)]],
    montantTotal: ['']
  });
}
}
else{
  this.tva2form = this.fb.group({
    rp: [this.sumOfpayereporter, [Validators.min(0)]],
    resteAPayerOuReporter: [0, [Validators.min(0)]],
    creditMoisPrecedent: [0, [Validators.min(0)]],
    montantRestitue: [0, [Validators.min(0)]],
    montantDejaRestitue: [0, [Validators.min(0)]],
    reliquatRestituer: [0, [Validators.min(0)]],
    creditTVA: [0, [Validators.min(0)]],
    montantRestitueCredit: [0, [Validators.min(0)]],
    reliquatCreditTVA: [0, [Validators.min(0)]],
    montantCreditDeduction: [0, [Validators.min(0)]],
    extraInput: [0, [Validators.min(0)]],
    reliquatDemandeRestitution: [0, [Validators.min(0)]],
    rp1: [this.sumOfpayereporter1, [Validators.min(0)]],
    resteAPayer: [0, [Validators.min(0)]],
    auCoursDuMois: [0, [Validators.min(0)]],
    auNumero: [0, [Validators.min(0)]],
    variation: [0, [Validators.min(0)]],
    exerciceAnterieur: [0, [Validators.min(0)]],
    exerciceCourant: [0, [Validators.min(0)]],

    // New form controls for the second table
    achatsSuspensionTVA1: [0, [Validators.min(0)]],
    montant1: [0, [Validators.min(0)]],
    achatsSuspensionTVA2: [0, [Validators.min(0)]],
    montant2: [0, [Validators.min(0)]],
    achatsExoneres1: [0, [Validators.min(0)]],
    montant3: [0, [Validators.min(0)]],
    achatsNonAssujettis: [0, [Validators.min(0)]],
    montant4: [0, [Validators.min(0)]],
    autresAchats: [0, [Validators.min(0)]],
    montant5: [0, [Validators.min(0)]],
    exportation: [0, [Validators.min(0)]],
    montant6: [0, [Validators.min(0)]],
    ventesSuspensionTVA: [0, [Validators.min(0)]],
    montant7: [0, [Validators.min(0)]],
    caExonereTVA: [0, [Validators.min(0)]],
    montant8: [0, [Validators.min(0)]],
    cessionAQuai: [0, [Validators.min(0)]],
    montant9: [0, [Validators.min(0)]],
    total: [0, [Validators.min(0)]],
    montantTotal: ['']
  });
}
}
else if(this.mois==='01')
{
  this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).find(e => verifymois1=(e.mois === '12'&&+e.annee === +this.annee-1));
  if(verifymois1)
{ 
  let tvarecuperable=+(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvarecuperableautreachat.achatlocauxtva+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvarecuperableautreachat.achatimportetva+ 
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvarecuperableequipement.achatlocauxtva+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvarecuperableequipement.achatimportetva+ 
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvarecuperableimmobilier.achatlocauxtva+
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.reporttvamoisprecedent
  let tvacollecte=+(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvacollecter19.tvaammount+
  ((this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvacollecter13?+(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvacollecter13.tvaammount:0)+
  +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.tvacollecter.tvaammount+ Math.trunc(((+(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.locationhabitationmeuble.htammount+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.locationusagecommercial.htammount
  + +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.operationlotissement.htammount+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.interetpercue.htammount+ +(this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).filter(p => p.mois==='12'&&+p.annee===+this.annee-1))[0].impottype2.autretvaspecial.htammount)*0.19)*1000)/1000
   
reporttva=(tvarecuperable- +tvacollecte).toFixed(3)
this.sumOfpayereporter = Math.abs(this.sumOfPositiveControls - this.sumOfNegativeControls);
this.sumOfPositiveControls - this.sumOfNegativeControls >= 0 ? this.statepr='P' : this.statepr='R';
this.statepr=='P'?this.sumOfpayereporter1=Math.abs(this.sumOfpayereporter - +reporttva):this.sumOfpayereporter1= this.sumOfpayereporter + +reporttva;
this.sumOfPositiveControls - (this.sumOfNegativeControls + +reporttva) >= 0 ? this.statepr1='P' : this.statepr1='R';

if(reporttva>0)
{
  this.tva2form = this.fb.group({
    rp: [this.sumOfpayereporter, [Validators.min(0)]],
    resteAPayerOuReporter: [0, [Validators.min(0)]],
    creditMoisPrecedent: [reporttva, [Validators.min(0)]],
    montantRestitue: [0, [Validators.min(0)]],
    montantDejaRestitue: [0, [Validators.min(0)]],
    reliquatRestituer: [0, [Validators.min(0)]],
    creditTVA: [0, [Validators.min(0)]],
    montantRestitueCredit: [0, [Validators.min(0)]],
    reliquatCreditTVA: [0, [Validators.min(0)]],
    montantCreditDeduction: [0, [Validators.min(0)]],
    extraInput: [0, [Validators.min(0)]],
    reliquatDemandeRestitution: [0, [Validators.min(0)]],
    rp1: [this.sumOfpayereporter1, [Validators.min(0)]],
    resteAPayer: [0, [Validators.min(0)]],
    auCoursDuMois: [0, [Validators.min(0)]],
    auNumero: [0, [Validators.min(0)]],
    variation: [0, [Validators.min(0)]],
    exerciceAnterieur: [0, [Validators.min(0)]],
    exerciceCourant: [0, [Validators.min(0)]],

    // New form controls for the second table
    achatsSuspensionTVA1: [0, [Validators.min(0)]],
    montant1: [0, [Validators.min(0)]],
    achatsSuspensionTVA2: [0, [Validators.min(0)]],
    montant2: [0, [Validators.min(0)]],
    achatsExoneres1: [0, [Validators.min(0)]],
    montant3: [0, [Validators.min(0)]],
    achatsNonAssujettis: [0, [Validators.min(0)]],
    montant4: [0, [Validators.min(0)]],
    autresAchats: [0, [Validators.min(0)]],
    montant5: [0, [Validators.min(0)]],
    exportation: [0, [Validators.min(0)]],
    montant6: [0, [Validators.min(0)]],
    ventesSuspensionTVA: [0, [Validators.min(0)]],
    montant7: [0, [Validators.min(0)]],
    caExonereTVA: [0, [Validators.min(0)]],
    montant8: [0, [Validators.min(0)]],
    cessionAQuai: [0, [Validators.min(0)]],
    montant9: [0, [Validators.min(0)]],
    total: [0, [Validators.min(0)]],
    montantTotal: ['']
  });
}
else
{
  this.tva2form = this.fb.group({
    rp: [this.sumOfpayereporter, [Validators.min(0)]],
    resteAPayerOuReporter: [0, [Validators.min(0)]],
    creditMoisPrecedent: [0, [Validators.min(0)]],
    montantRestitue: [0, [Validators.min(0)]],
    montantDejaRestitue: [0, [Validators.min(0)]],
    reliquatRestituer: [0, [Validators.min(0)]],
    creditTVA: [0, [Validators.min(0)]],
    montantRestitueCredit: [0, [Validators.min(0)]],
    reliquatCreditTVA: [0, [Validators.min(0)]],
    montantCreditDeduction: [0, [Validators.min(0)]],
    extraInput: [0, [Validators.min(0)]],
    reliquatDemandeRestitution: [0, [Validators.min(0)]],
    rp1: [this.sumOfpayereporter1, [Validators.min(0)]],
    resteAPayer: [0, [Validators.min(0)]],
    auCoursDuMois: [0, [Validators.min(0)]],
    auNumero: [0, [Validators.min(0)]],
    variation: [0, [Validators.min(0)]],
    exerciceAnterieur: [0, [Validators.min(0)]],
    exerciceCourant: [0, [Validators.min(0)]],

    // New form controls for the second table
    achatsSuspensionTVA1: [0, [Validators.min(0)]],
    montant1: [0, [Validators.min(0)]],
    achatsSuspensionTVA2: [0, [Validators.min(0)]],
    montant2: [0, [Validators.min(0)]],
    achatsExoneres1: [0, [Validators.min(0)]],
    montant3: [0, [Validators.min(0)]],
    achatsNonAssujettis: [0, [Validators.min(0)]],
    montant4: [0, [Validators.min(0)]],
    autresAchats: [0, [Validators.min(0)]],
    montant5: [0, [Validators.min(0)]],
    exportation: [0, [Validators.min(0)]],
    montant6: [0, [Validators.min(0)]],
    ventesSuspensionTVA: [0, [Validators.min(0)]],
    montant7: [0, [Validators.min(0)]],
    caExonereTVA: [0, [Validators.min(0)]],
    montant8: [0, [Validators.min(0)]],
    cessionAQuai: [0, [Validators.min(0)]],
    montant9: [0, [Validators.min(0)]],
    total: [0, [Validators.min(0)]],
    montantTotal: ['']
  });
}
}
else
{
  this.tva2form = this.fb.group({
    rp: [this.sumOfpayereporter, [Validators.min(0)]],
    resteAPayerOuReporter: [0, [Validators.min(0)]],
    creditMoisPrecedent: [0, [Validators.min(0)]],
    montantRestitue: [0, [Validators.min(0)]],
    montantDejaRestitue: [0, [Validators.min(0)]],
    reliquatRestituer: [0, [Validators.min(0)]],
    creditTVA: [0, [Validators.min(0)]],
    montantRestitueCredit: [0, [Validators.min(0)]],
    reliquatCreditTVA: [0, [Validators.min(0)]],
    montantCreditDeduction: [0, [Validators.min(0)]],
    extraInput: [0, [Validators.min(0)]],
    reliquatDemandeRestitution: [0, [Validators.min(0)]],
    rp1: [this.sumOfpayereporter1, [Validators.min(0)]],
    resteAPayer: [0, [Validators.min(0)]],
    auCoursDuMois: [0, [Validators.min(0)]],
    auNumero: [0, [Validators.min(0)]],
    variation: [0, [Validators.min(0)]],
    exerciceAnterieur: [0, [Validators.min(0)]],
    exerciceCourant: [0, [Validators.min(0)]],

    // New form controls for the second table
    achatsSuspensionTVA1: [0, [Validators.min(0)]],
    montant1: [0, [Validators.min(0)]],
    achatsSuspensionTVA2: [0, [Validators.min(0)]],
    montant2: [0, [Validators.min(0)]],
    achatsExoneres1: [0, [Validators.min(0)]],
    montant3: [0, [Validators.min(0)]],
    achatsNonAssujettis: [0, [Validators.min(0)]],
    montant4: [0, [Validators.min(0)]],
    autresAchats: [0, [Validators.min(0)]],
    montant5: [0, [Validators.min(0)]],
    exportation: [0, [Validators.min(0)]],
    montant6: [0, [Validators.min(0)]],
    ventesSuspensionTVA: [0, [Validators.min(0)]],
    montant7: [0, [Validators.min(0)]],
    caExonereTVA: [0, [Validators.min(0)]],
    montant8: [0, [Validators.min(0)]],
    cessionAQuai: [0, [Validators.min(0)]],
    montant9: [0, [Validators.min(0)]],
    total: [0, [Validators.min(0)]],
    montantTotal: ['']
  });
}
}
  
     }

     this.setupAutoCalculations();

// Subscribe to value changes of all form controls
    // Subscribe to value changes of all form controls
    this.formValueChangesSubscription = this.tva2form.valueChanges.subscribe(() => {
      this.updateSumAndTotal();
    });
  }

  
  ngOnDestroy(): void {
    // Unsubscribe from value changes to prevent memory leaks
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
  //  this.goto(1)
  }
  validate(): void {
    // Save the form state when the component is destroyed
    this.formStateService.settva2FormState(this.tva2form);
// Unsubscribe from value changes to prevent memory leaks
if (this.formValueChangesSubscription) {
  this.formValueChangesSubscription.unsubscribe();
}
this.goto(1)
}
  setupAutoCalculations(): void {
    const keysWithValueChanges = [
      { tvaDueKey: 'montant7', montantKey: 'tvaDue7', multiplier: 0.07 },
      { tvaDueKey: 'montant13', montantKey: 'tvaDue13', multiplier: 0.13 },
      { tvaDueKey: 'montant19', montantKey: 'tvaDue19', multiplier: 0.19 },
      { tvaDueKey: 'montant6', montantKey: 'tvaDue6', multiplier: 0.06 },
      { tvaDueKey: 'montant12', montantKey: 'tvaDue12', multiplier: 0.12 },
      { tvaDueKey: 'montant18', montantKey: 'tvaDue18', multiplier: 0.18 },
      { tvaDueKey: 'montantOther', montantKey: 'tvaDueOther', multiplier: 0.19 },
      { tvaDueKey: 'montantAchats19', montantKey: 'achats19', multiplier: 0.19 },
      { tvaDueKey: 'montantAchatsEquipementsLocal', montantKey: 'achatsEquipementsLocal', multiplier: 0.19 },
      { tvaDueKey: 'montantAchatsEquipementsImportes', montantKey: 'achatsEquipementsImportes', multiplier: 0.19 },
      { tvaDueKey: 'montantAutresAchatsLocal', montantKey: 'autresAchatsLocal', multiplier: 0.19 },
      { tvaDueKey: 'montantAutresAchatsImportes', montantKey: 'autresAchatsImportes', multiplier: 0.19 },
      { tvaDueKey: 'montantAutresDeductions1000Dinars', montantKey: 'autresDeductions1000Dinars', multiplier: 0.25 },
      { tvaDueKey: 'montantTvaNonResidents', montantKey: 'tvaNonResidents', multiplier: 1 }
    ];
  
    keysWithValueChanges.forEach(item => {
      this.tva2form.get(item.tvaDueKey)?.valueChanges.subscribe(value => {
        const montantControlKey = item.montantKey;
        const tvaDueValue = this.tva2form.get(item.tvaDueKey)?.value;
        const newValue = Math.floor((tvaDueValue * item.multiplier) * 1000) / 1000;
        this.tva2form.get(montantControlKey)?.setValue(newValue, { emitEvent: false });
      });
    });
  }
  
  updateSumAndTotal(): void {
    this.sumOfPositiveControls = 0
    this.sumOfNegativeControls = 0
  const positiveKeys = [];
        const negativeKeys = [
          'creditMoisPrecedent'
        ];
         Object.keys(this.tva2form.controls).forEach(key => {
           const control = this.tva2form.get(key);
           if (control && positiveKeys.includes(key)) {
             this.sumOfPositiveControls += control.value;
           } else if (control && negativeKeys.includes(key)) {
             this.sumOfNegativeControls += control.value;
           }
         });
         this.sumOfpayereporter1 = Math.abs(this.sumOfPositiveControls - this.sumOfNegativeControls);
         this.sumOfPositiveControls - this.sumOfNegativeControls >= 0 ? this.statepr1='P' : this.statepr1='R';
  }
  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }
  previous() {
    this.previousStep.emit();
  }

}