<body style="text-align: center;">  
  <div id="loader" *ngIf="loading"></div>
  <div class="col-lg-6" *ngIf="!loading" style="margin: 0 auto; text-align: center;">
    <h2>Rappelez Vous!</h2>
    <hr>
    <table style="margin: 0 auto;">
      <tbody>
        <tr *ngFor="let event of events">
          <td id="date">{{ event.date | date : "dd-MM-y" }}</td>
          <td>
            <a (click)="voirdetail('view-event', event._id)" style="cursor: pointer;" routerLinkActive="active">
              {{ event.title }}
            </a>
            &nbsp;
            <a class="btn btn-primary" *ngIf="event.description" style="font-size: 10px" (click)="voirdetail('view-event', event._id)">
              Lire Plus
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="text-align: center">
      <a class="btn btn-primary" (click)="voirtout()">Voir tout le calendrier</a>
    </div>
  </div>
</body>
