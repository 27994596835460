import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { invoicemodel } from '../models/invoice.model';
import { User } from '../models/user.model';
import { InvoiceService } from '../services/invoice.service';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit {

  public user: User;
  public inv: invoicemodel;
  public errormsg:string;
  public loading: boolean;
  public role: string;
  currentUser: any;
  constructor(
              private router: Router,
              private route: ActivatedRoute,
              private usersservice: UserService,
              private invo: InvoiceService,
              private token: TokenStorageService) { }
  ngOnInit() {
    this.loading = true;
    this.currentUser = this.token.getUser();
    this.route.params.subscribe(
      (params: Params) => {
        this.invo.getInvoicedataById(params.id).then(
          (invo: invoicemodel) => {
            this.loading = false;
            this.inv = invo;
            this.usersservice.getUserById(this.inv.totalds.userId).then(
              (user:User)=>
              {
                this.user=user
              }
            )
          }
        );
      }
    );
    
  }
  public printinvoice(): void {
    const self =this
    const data = document.getElementById('honoraireuser');
    html2canvas(data,{scale:2}).then((canvas:any) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
       
      doc.save(`facture F/${self.inv.prefixe}/${self.inv.ref}`);
    });
    
    
    
      } 
}
