import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-foprolos-compo',
  templateUrl: './foprolos-compo.component.html',
  styleUrls: ['./foprolos-compo.component.scss']
})
export class FOPROLOSCOMPOComponent implements OnInit,OnDestroy {
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  foprolosform: FormGroup;
  formValueChangesSubscription: Subscription;
  sumOfdisabledControls: number = 0;
  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.foprolosform.reset();
  }
  ngOnInit(): void {
    // Initialize forms
    const savedFormState = this.formStateService.getfoprolosFormState();
    if (savedFormState) {
      this.foprolosform = savedFormState;
      this.updateSumAndTotal();
    } else {
      this.foprolosform = this.fb.group({
        v1: [{ value: 0, disabled: true }],
        v2: [0, [Validators.min(0)]],
      });
    }
    this.setupAutoCalculation('v2', 'v1', 0.01);
    // Subscribe to value changes of all form controls
    this.formValueChangesSubscription = this.foprolosform.valueChanges.subscribe(() => {
      this.updateSumAndTotal();
    });
  }
  ngOnDestroy(): void {
        // Save the form state when the component is destroyed
        this.formStateService.setfoprolosFormState(this.foprolosform);
    // Unsubscribe from value changes to prevent memory leaks
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
    this.goto(1)
  }
  setupAutoCalculation(sourceControlName: string, targetControlName: string, multiplier: number): void {
    this.foprolosform.get(sourceControlName).valueChanges.subscribe(value => {
      const targetControl = this.foprolosform.get(targetControlName);
      if (targetControl) {
        targetControl.setValue(Math.floor( (value * multiplier)*1000)/1000, { emitEvent: false });
      }
    });
  }

  updateSumAndTotal(): void {
    this.sumOfdisabledControls = 0;
    Object.keys(this.foprolosform.controls).forEach(key => {
      const control = this.foprolosform.get(key);
      if (control && control.disabled) {
        this.sumOfdisabledControls += control.value;
      }
    });
  }

  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }

  previous() {
    this.previousStep.emit();
  }
}
