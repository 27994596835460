export class Otherdsmodeluser {
    userId:string;
    clientId:string;
    paye:boolean;
    marquepaye:boolean;
    totalhonoraire:number
    libelle: string;
    description:string;
    duree: string;
    tauxapplique:string;
    soumis: boolean;
    _id:string; 
    created:Date;
    updated: Date      
        }