<div class="invoice-list-container">
    <mat-form-field appearance="outline" class="filter-field">
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Rechercher par référence ou client">
    </mat-form-field>
  
    <mat-form-field appearance="outline" class="filter-field">
      <mat-label>Status</mat-label>
      <mat-select (selectionChange)="applyStatusFilter($event)">
        <mat-option value="">Tous</mat-option>
        <mat-option value="Paid">règlé</mat-option>
        <mat-option value="Unpaid">pas encore règlé</mat-option>
      </mat-select>
    </mat-form-field>
  <h2>Liste des <span *ngIf="type=='facture'">Factures</span><span *ngIf="type=='facturepro'">Factures Proforma</span><span *ngIf="type=='devis'">Devis</span></h2>
    <mat-table [dataSource]="pagedInvoices" class="mat-elevation-z8">
      <ng-container matColumnDef="ref">
        <mat-header-cell *matHeaderCellDef> Référence </mat-header-cell>
        <mat-cell *matCellDef="let invoice"> {{invoice.prefixe}}/{{invoice.ref}} ({{invoice.number}})</mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="client">
        <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
        <mat-cell *matCellDef="let invoice"> {{invoice.clientdetails.name}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef> Montant </mat-header-cell>
        <mat-cell *matCellDef="let invoice"> {{getTotalAmount(invoice.itemsdetails) | currency:invoice.currency}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> Statut </mat-header-cell>
        <mat-cell *matCellDef="let invoice"> {{invoice.status}} </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let invoice"> {{invoice.created | date}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let invoice">
          <button mat-icon-button (click)="viewInvoice(invoice._id)">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteInvoice(invoice._id)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button (click)="generateExcel(invoice._id)">
            <mat-icon>build</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  
    <mat-paginator [length]="totalInvoices"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 10, 20]"
                   (page)="onPageChange($event)">
    </mat-paginator>
</div>
  