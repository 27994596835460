
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Proformatinvoicemodel } from '../models/proformatinvoice.model';
import { User } from '../models/user.model';
import { ProformatinvoiceService } from '../services/proformatinvoice.service';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { OtherdsServiceUser } from '../services/otherds-user.service';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { Otherdsmodeluser } from '../models/otherds-user.model';
import { invoicemodel } from '../models/invoice.model';
import { InvoiceService } from '../services/invoice.service';
@Component({
  selector: 'app-view-proformatinvoice',
  templateUrl: './view-proformatinvoice.component.html',
  styleUrls: ['./view-proformatinvoice.component.scss']
})
export class ViewProformatinvoiceComponent implements OnInit {

  public user: User;
  public prformatinv: Proformatinvoicemodel;
  public errormsg:string;
  public loading: boolean;
  public role: string;
  currentUser: any;
  invsub: any;
  invoices: invoicemodel[]=[];
  constructor(
              private router: Router,
              private route: ActivatedRoute,
              private usersservice: UserService,
              private profinvo: ProformatinvoiceService,private deccompt: DeccomptabiliteService,private dec: DecfiscmensService,private otheruser: OtherdsServiceUser,
              private token: TokenStorageService,private invoi: InvoiceService) { }
  ngOnInit() {
    this.loading = true;
    this.currentUser = this.token.getUser();
    if(this.router.url.includes('orderId'))
{
  Swal.fire('autorisation de paiement accordée')
}
    this.route.params.subscribe(
      (params: Params) => {
        this.profinvo.getProformatinvoicedataById(params.id).then(
          (profinvo: Proformatinvoicemodel) => {
            this.loading = false;
            this.prformatinv = profinvo;
            console.log(this.prformatinv)
            this.usersservice.getUserById(this.prformatinv.totalds.userId).then(
              (user:User)=>
              {
                this.user=user
                this.invsub = this.invoi.invoices$.subscribe(
                  (invs) => {
                    this.invoices = invs;
                    });
                    this.invoi.getinvoiceuser(this.user._id);
              }
            )
          }
        );
      }
    );
    
  }
  verifyalldsnonpaye():boolean
  {
    let returnvalue=[]
    let retunvaluefinal=false
    let allds=[]
    allds=allds.concat(this.prformatinv.totalds.demandesnonsoumis,this.prformatinv.totalds.demandessoumis)
    allds.forEach(e=>this.deccompt.filterByValue2(this.invoices,e._id)?returnvalue.push(true):returnvalue.push(false))
    this.deccompt.filterByValue2(returnvalue,'true')?retunvaluefinal=false:retunvaluefinal=true
    console.log(retunvaluefinal)
    return  retunvaluefinal
  }
  async payinvoice()
  {
    let allds=[]
    if(!this.verifyalldsnonpaye())
    {
     return(Swal.fire({
      title: 'cette facture proforma comporte des DS qui sont déjà payés, veuillez demandez au client de générer une nouvelle facture proforma',
      icon: 'info',
      confirmButtonColor: '#3085d6',
    })) 
    }
    allds=allds.concat(this.prformatinv.totalds.demandesnonsoumis,this.prformatinv.totalds.demandessoumis)
          for await (const element of allds) {
            if(element.nature=='comptabilite')
            {
              const newelement: Deccomptabilite = new Deccomptabilite()
      newelement.paye=true
      await  this.deccompt.completedeccomptabilitereqById(element._id,newelement).then(
        (data:any) => {
         // this.loading = false;
        },
        (error) => {
        //  this.loading = false;
          
        }
      )
            }
            else  if(element.nature=='Déclaration Mensuelle')
            {
              const newelement: Decfiscmens = new Decfiscmens()
      newelement.paye=true
      await  this.dec.completedecfiscmensreqById(element._id,newelement).then(
        (data:any) => {
         // this.loading = false;
        },
        (error) => {
        //  this.loading = false;
          
        }
      )
            }
            else  if(!element.nature)
            {
              const newelement: Otherdsmodeluser = new Otherdsmodeluser()
      newelement.paye=true
      await  this.otheruser.modifyotherdsByIdforuser(element._id,newelement).then(
        (data:any) => {
         // this.loading = false;
        },
        (error) => {
        //  this.loading = false;
          
        }
      )
            }
          
          }
          const Invoicemodel:invoicemodel=new invoicemodel()
          Invoicemodel.totalds={userId:'',demandesnonsoumis:[],demandessoumis:[],totalht:'',totalgeneral:'',soustotal1:'',soustotal2:''
          ,timbrefiscal:'',tva:''}
          Invoicemodel.userId=this.user._id
          Invoicemodel.orderid=''
          Invoicemodel.totalds.userId=this.user._id
          Invoicemodel.totalds.demandesnonsoumis=this.prformatinv.totalds.demandesnonsoumis
          Invoicemodel.totalds.demandessoumis=this.prformatinv.totalds.demandessoumis
          Invoicemodel.totalds.totalht=this.prformatinv.totalds.totalht
          Invoicemodel.totalds.soustotal1=this.prformatinv.totalds.soustotal1
          Invoicemodel.totalds.soustotal2=this.prformatinv.totalds.soustotal2
          Invoicemodel.totalds.timbrefiscal=this.prformatinv.totalds.timbrefiscal
          Invoicemodel.totalds.totalgeneral=this.prformatinv.totalds.totalgeneral
          Invoicemodel.totalds.tva=this.prformatinv.totalds.tva
          Invoicemodel.profid=this.prformatinv._id
          await this.invoi.create(Invoicemodel).then(
            (da:any) => {
              console.log(da)
              Swal.fire({
                title: 'opération de paiement réalisée avec succès! veuillez choisir entre les deux options',
                icon: 'info',
                confirmButtonColor: '#3085d6',
                showDenyButton: true,
                confirmButtonText: 'consulter la facture',
                denyButtonText: 'revenir au tableau de bord admin',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['view-invoice/'+`${da.data._id}`]);
                }
                else{
          this.router.navigate(['admin-board']);
        }
   
              }).catch(() => {
                Swal.fire('opération non aboutie!');
              });             // this.loading = false;
            },
            (error) => {
            //  this.loading = false;
              
            }
          )
      

  }
  public printinvoice(): void {
    const self =this
    const data = document.getElementById('honoraireuser');
    html2canvas(data,{scale:2}).then((canvas:any) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
       
      doc.save(`facture P/${self.prformatinv.prefixe}/${self.prformatinv.ref}`);
    });
    
    
    
      } 
}
