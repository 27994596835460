import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-config-releve-banque',
  templateUrl: './config-releve-banque.component.html',
  styleUrls: ['./config-releve-banque.component.scss']
})
export class ConfigReleveBanqueComponent implements OnInit {
  configForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfigReleveBanqueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.configForm = this.fb.group({
      cellulebanque: [data.cellulebanque || '', Validators.required],
      celluleNumeroCompte: [data.celluleNumeroCompte || '', Validators.required],
      celluleDebit: [data.celluleDebit || '', Validators.required],
      celluleCredit: [data.celluleCredit || '', Validators.required],
      ligneDebutTransactions: [data.ligneDebutTransactions || '', Validators.required],
      ligneFinTransactions: [data.ligneFinTransactions || '', Validators.required],
      colonneJourTransaction: [data.colonneJourTransaction || '', Validators.required],
      colonneDateTransaction: [data.colonneDateTransaction || '', Validators.required],
      colonneDesignation: [data.colonneDesignation || '', Validators.required],
      colonneDebitTransaction: [data.colonneDebitTransaction || '', Validators.required],
      colonneCreditTransaction: [data.colonneCreditTransaction || '', Validators.required],
    });
  }

  ngOnInit(): void {}

  saveConfig(): void {
    if (this.configForm.valid) {
      this.dialogRef.close(this.configForm.value);
    }
  }
}
