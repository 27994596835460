
<div class="container-fluid"*ngIf="inv">
  <div class="card"id="honoraireuser">
    <div class="card-header bg-black"></div>
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <img src="../../assets/img/Frame 2177.png" id="logo" class="navbar-left" alt="image-DGI">
          </div>
        </div>
        <div class="row"*ngIf="user">
          <div class="col-xl-12">
          <ul class="list-unstyled float-end">
              <li>Tunis le {{inv.created| customDate}}</li>
              <li>A l'attention de: &nbsp;<span *ngIf="user.nature=='Personne Physique'"> <span *ngIf="user.civilite=='Monsieur'"> Mr </span> <span *ngIf="user.civilite=='Madame'"> Mme </span> {{user.firstname}} &nbsp;{{ user.lastname }}</span> <span *ngIf="user.nature!='Personne Physique'">{{ user.raisonsociale }}</span> &nbsp;<span>({{user.clientcode| uppercase}})</span></li>
              <li>{{user.matriculefiscale| uppercase}}</li>
              <li>{{user.adresseactivite| uppercase}}</li> 
          </ul>
          </div>
        </div>
        <div class="row text-center">
          <h3 class="text-uppercase text-center mt-3" style="font-size: 40px;">Note d'honoraires N°&nbsp;F/{{inv.prefixe}}/{{inv.ref}}</h3>
        </div>
        <div class="row mx-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">N° DS</th>
                <th scope="col">Désignation</th>
                <th scope="col">Description</th>
                <th scope="col">Durée</th>
                <th scope="col">Taux/minute</th>
                <th scope="col">Montant HT</th>
              </tr>
            </thead>
            <tbody  >
              <tr *ngFor="let totds of inv.totalds.demandessoumis; let i = index">
                <td>{{totds._id| uppercase}}</td>
                <td><span *ngIf="totds.nature">{{totds.nature}}</span> <span *ngIf="!totds.nature">{{totds.libelle}}</span></td>
                <td><span *ngIf="totds.nature">{{totds.mois}}/{{totds.annee}}</span> <span *ngIf="!totds.nature">{{totds.description}}</span></td>
                <td><span *ngIf="totds.nature">{{totds.totaltime}}</span><span *ngIf="!totds.nature&&totds.duree!=0">{{totds.duree}}</span> <span *ngIf="!totds.nature&&totds.duree==0">N/A</span></td>
                <td><span *ngIf="totds.nature&&totds.totaltime==0">N/A</span><span *ngIf="!totds.nature&&totds.duree==0">N/A</span><span *ngIf="totds.nature&&totds.totaltime!=0||!totds.nature&&totds.duree!=0">{{totds.tauxapplique| number:'1.3-3'| removeComma}}</span> </td>
                <td>{{totds.totalhonoraire| number:'1.3-3'| removeComma}}</td>
              </tr>
            <tr>
              <td colspan="5"> Total HT:</td>
              <td> {{inv.totalds.totalht| number:'1.3-3'| removeComma}}</td>
            </tr>
            <tr>
              <td colspan="5">TVA (19%):</td>
              <td>{{inv.totalds.tva| number:'1.3-3'| removeComma}}</td>
            </tr>
            <tr>
              <td colspan="5">Sous total1:</td>
              <td>{{inv.totalds.soustotal1| number:'1.3-3'| removeComma}}</td>
            </tr>
            </tbody>
            <tbody  >
              <tr *ngFor="let totds of inv.totalds.demandesnonsoumis; let i = index">
                <td >{{totds._id| uppercase}}</td>
                <td><span *ngIf="totds.nature">{{totds.nature}}</span> <span *ngIf="!totds.nature">{{totds.libelle}}</span></td> 
                <td><span *ngIf="totds.nature">{{totds.mois}}/{{totds.annee}}</span> <span *ngIf="!totds.nature">{{totds.description}}</span></td> 
                <td ></td>
                <td ></td>
                <td>{{totds.totalhonoraire| number:'1.3-3'| removeComma}}</td>
              </tr>
              <tr>
                <td colspan="5">Sous Total2:</td>
                <td>{{inv.totalds.soustotal2| number:'1.3-3'| removeComma}}</td>
              </tr>
              <hr>
              <tr>
                <td colspan="5">Timbre fiscal:</td>
                <td> {{inv.totalds.timbrefiscal| number:'1.3-3'| removeComma}}</td>
              </tr>
              <tr>
                <td colspan="5">Total général:</td>
                <td>{{inv.totalds.totalgeneral| number:'1.3-3'| removeComma| currency:'TND'}}</td>
              </tr>
            </tbody>
          </table>
        </div>

     
        <div class="top-info">
          <small class="me-3 " style="color: black;"><a href="#"><i class="fas fa-solid fa-phone me-2 " style = "color:#D11317"></i></a>98 35 05 75</small>
          <small class="me-3"style="color: black;"><a href="#"><i class="fas fa-envelope me-2" style = "color:#D11317"></i></a>macompta@macompta.com.tn</small>
          <small class="me-3 "style="color: black;"><a href="#"><i class="bi bi-house-door-fill" style = "color:#D11317"></i></a>Ariana center Bureau A318 Ariana 2080</small>
          
        </div>
        <div class="row mt-2 mb-5">
          <p class="fw-bold mt-3">Signature:</p>
        </div>
  
      </div>
  
  
  
    </div>
    <div class="card-footer bg-black"></div>
  </div>
</div>
<div class="btn-signup-section">
<!--<div style="width: 30%; text-align: end;">
        <button class="reinstaller-btn-modal" (click)="closecopPopup()">Annuler</button>
    </div>
   <div style="width: 35%">
      <button class="reinstaller-btn-modal"style="background-color: lightgreen;" (click)="payinvoice()"><i class="fa fa-spinner" *ngIf="submitting"></i>Règler facture</button>
  </div>-->    
    <div style="width: 50%;">
        <button class="signup-btn-modal" (click)="printinvoice()">Générer facture</button>
    </div>
   
</div>
