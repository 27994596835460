import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { Carouselmodel } from '../models/settings';
import { CarouselService } from '../services/settings';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Form, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { read, utils } from "xlsx"
import Swal from 'sweetalert2';
import { ApiServiceService } from '../services/event.service';
import { UserService } from '../services/user.service';
import { relationService } from '../services/relation.service';
import { User } from '../models/user.model';
import { Otherdsmodel } from '../models/otherds.model';

import { Relations } from '../models/relation.model';
import { Versionmodel } from '../models/versions.model';

import { CommunService } from '../services/commun';
import { OtherdsService } from '../services/otherds.service';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { VersionService } from '../services/versions.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  currentUser: any;
   loading=false;
  public carousels: Carouselmodel[] = [];
  public version: Versionmodel

  public otherdss: Otherdsmodel[] = [];
  private otherdssSub: Subscription;
  private versionSub: Subscription;

  private carouselsSub: Subscription;
  carouselform: FormGroup;
  otherdsform: FormGroup;
  existentotherdsform: FormGroup;

  smsform: FormGroup;
  public searchForm: FormGroup;
public exerciceform: FormGroup
  public imagePreview: string;
  fileUploaded = false;
  file: any;
  uploadEvent: any;
  arrayBuffer: string | ArrayBuffer;
  exceljsondata: Event[];
  exceljsondata2: User[];
  exceljsondata3: Relations[];
  exceljsondata4=[];
  filtredcours=[];
  tarifform: FormGroup;
  coursform: FormGroup;
  public ammounts: FormArray;
  public ammounts2: FormArray;
  public ammounts3: FormArray;
  public ammounts4: FormArray;
  public ammounts5: FormArray;

  public type: any[]=["Tarif de base","Tarif spécial","Tarif individuel"];
  public annees: any[]=["2021","2022","2023","2024"];
  public mois: any[]=["01","02","03","04","05","06","07","08","09","10","11","12"];
  public anneesmbs: any[]=["2013","2014","2015","2016","2017","2018","2019","2020"];

  nature: any[];
  natureactivite: any[];
  activite: any[];
  sousactivite: any[];
  regimeimpot: any[];
  actualites: Carouselmodel[];
  tarifs: Carouselmodel[];
  cours: Carouselmodel[];
  relationsSub: Subscription;
  relations: Relations[];
  sortedcours: string[];
  submitting=false;
  deleting=false;
  updating=false;
  errormsg: any;
  users: User[]=[];
  filtredusers: any[]=[];
  usersSub: Subscription;
  prenom: any;
  nom: any;
  raisonsociale: any;
  codeclient: any;
  natureclient: any;
  firstnumber:number
  secondnumber:number
  thirdnumber:number
  hoverFeature: string = '';
  collapse1 = false;
  collapse2 = false;
  collapse3 = false;
  collapse4 = false;
  collapse5 = false;
  collapse6 = false;
  collapse7 = false;
  collapse8 = false;
  collapse9 = false;

  selectedTab=0;
  constructor(private token: TokenStorageService,private carousel:CarouselService,private relationservice: relationService,private userservice: UserService,
    private eve:ApiServiceService,private formBuilder: FormBuilder,private commun: CommunService,private other: OtherdsService,
    private router: Router,private deccompt:DeccomptabiliteService,private ver:VersionService) {
      this.tarifform = this.formBuilder.group({
        ammounts: this.formBuilder.array([ this.createammount() ])
     });
     this.exerciceform = this.formBuilder.group({
      ammounts5: this.formBuilder.array([ this.createammount5() ])
   });
     this.coursform = this.formBuilder.group({
      ammounts2: this.formBuilder.array([ this.createammount2() ])
   });
   this.otherdsform = this.formBuilder.group({
    ammounts3: this.formBuilder.array([ this.createammount3() ])
 });
 this.existentotherdsform = this.formBuilder.group({
  ammounts4: this.formBuilder.array([ this.createammount4() ])
});
     }
     onTabChange(event: any) {
      this.selectedTab = event.index;
    }
  ngOnInit() {
    this.currentUser = this.token.getUser();
    this.nature=this.commun.nature
    this.natureactivite=this.commun.natureactivite
    this.activite=this.commun.activites
    this.sousactivite=this.commun.sousactivites
    this.regimeimpot=this.commun.regimeimpot
    this.searchForm = this.formBuilder.group({           
      dateinf: [null,],
      datesup: [null,],
    })
    this.carouselsSub = this.carousel.carousels$.subscribe(
      (carousels) => {
        this.carousels = carousels;
        this.actualites=this.carousels.filter(p => p.tarifs.length==0&&p.cours.length==0)
        this.tarifs=this.carousels.filter(p => p.tarifs.length>0)
        this.cours=this.carousels.filter(p => p.cours.length>0)
        this.sortedcours=this.cours[0].cours.sort(this.commun.sortByDate)
this.filtredcours=this.sortedcours.slice(0,30) 
        /*this.cours.length>0?
          this.coursform = new FormGroup({          
          ammounts2: new FormArray(this.sortedcours.map(item => {
            const group = this.createammount2();
            //@ts-ignore
            group.patchValue(item);
            return group;
          }))
        }):'';*/
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        
      }
    );
    
      this.carousel.getCarouselalldata();
      this.relationsSub = this.relationservice.relations$.subscribe(
        (relations) => {
          this.relations = relations;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          
        }
      ); 
        this.relationservice.getAll();
        this.versionSub = this.ver.versions$.subscribe(
          (versions) => {
            this.version = versions[versions.length-1];
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            
          }
        ); 
          this.ver.getversionalldata();
        this.otherdssSub = this.other.otherdss$.subscribe(
          (otherdss) => {
            this.otherdss = otherdss;
          this.otherdss.length>0?this.existentotherdsform = new FormGroup({                
              ammounts4: new FormArray(this.otherdss.map(item => {
                const group = this.createammount4();
                //@ts-ignore
                group.patchValue(
                  {
                    id:item._id,
                    libelle:item.libelle,
                    duree:item.duree,
                    soumis:item.soumis

                  }
                ); 
                  return group;
              }))
            }):''
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            
          }
        );
        
          this.other.getOtherdsalldata();
          this.usersSub = this.userservice.users$.subscribe(
            (users) => {
              this.users = users;
              this.filtredusers=this.deccompt.filterByValue(this.users,'desactive')
              this.filtredusers=this.filtredusers.filter((filter) => (filter.usertype === 'Client'&&!filter.desactive.statut))
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              this.errormsg=error.message;
            }
          );
          this.getall()
    this.carouselform = this.formBuilder.group({
      titre: [''],
      rang: [''],
      commentaire: [''],
      description: [''],
      
      image: [null]
      
    });
    this.smsform = this.formBuilder.group({
    
      description: [''],
  
    });
   
  }
  getall() {                                             
    this.userservice.getAll();                                               
 }
 filteruser(id:string)
 {
  let userschosen=[]
  userschosen=this.deccompt.filterByValue(this.filtredusers,`${id}`)
 // console.log(userschosen)
  if(userschosen.length>0)
  {
 // console.log(userschosen[0])
    this.prenom=userschosen[0].firstname
    this.nom=userschosen[0].lastname
    this.raisonsociale=userschosen[0].raisonsociale
    this.codeclient=userschosen[0].clientcode
    this.natureclient=userschosen[0].nature
  }
  else
  {
    this.prenom='utilisateur supprimé'
    this.nom='utilisateur supprimé'
  }
 }
 onchangetypetarif(i:number,e:any)
 {
  let selected = e.target.value
  let ammounts=this.tarifform.get('ammounts') as FormArray;
  if(selected=='Tarif individuel'){
    ammounts.controls[i].get('nature').disable()  
    ammounts.controls[i].get('natureactivite').disable()  
    ammounts.controls[i].get('activite').disable()  
    ammounts.controls[i].get('sousactivite').disable()  
    ammounts.controls[i].get('impot').disable() 
    ammounts.controls[i].get('user').enable()  
 
  }
  else  if(selected=='Tarif de base'){
    ammounts.controls[i].get('nature').disable()  
    ammounts.controls[i].get('natureactivite').disable()  
    ammounts.controls[i].get('activite').disable()  
    ammounts.controls[i].get('sousactivite').disable()  
    ammounts.controls[i].get('impot').disable()  
    ammounts.controls[i].get('user').disable()  

  }
  else{
    ammounts.enable()
    ammounts.controls[i].get('user').disable()  
  }
 }
 onchangeclient(i: number, e: any) {
  let ammounts5 = this.exerciceform.get('ammounts5') as FormArray;

  this.userservice.getUserById(ammounts5.value.at(i).client).then((user: User) => {
    if (user.exercicecloture && user.exercicecloture.length > 0) {
      const updatedAmmounts = user.exercicecloture.map(item => {
        const group = this.createammount5();
        group.patchValue({
          client: user._id,
          annee: item.annee,
          moisdebut: item.moisdebut,
          moisfin: item.moisfin,
          cloture: item.cloture
        });
        return group;
      });
      this.exerciceform.setControl('ammounts5', new FormArray(updatedAmmounts));
    } else {
      while (ammounts5.length) {
        ammounts5.removeAt(0);
      }
      ammounts5.push(this.createammount5());
      ammounts5.at(0).patchValue({
        client: user._id,
        annee: '',
        moisdebut: '',
        moisfin: '',
        cloture: ''
      });
    }
  });
}

  createammount(): FormGroup {
    return this.formBuilder.group({
      type: '',
      annee: '',
      debut: '',
      fin: '',
      user: '',
      nature: '',
      natureactivite: '',
      activite: '',
      sousactivite: '',
      impot: '',
      prix: '',
    });
  }
  createammount2(): FormGroup {
    return this.formBuilder.group({
      jour:'',
      dollar: '0',
      euro: '0',
      gbp: '0',
      dzd: '0',
      sar: '0',
      cad: '0',
      dkk: '0',
      jpy: '0',
      mad: '0',
      nok: '0',
      sek: '0',
      chf: '0',
      kwd: '0',
      aed: '0',
      lyd: '0',
      mru: '0',
      bhd: '0',
      qar: '0',
      cny: '0',
    });
  }
  createammount3(): FormGroup {
    return this.formBuilder.group({
      id:'',
      libelle: '',
      duree: '',
      soumis: false,

    });
  }
  createammount4(): FormGroup {
    return this.formBuilder.group({
      id:'',
      libelle: '',
      duree: '',
      soumis: false,

    });
  }
  createammount5(): FormGroup {
    return this.formBuilder.group({
      client:'',
      annee: '',
      moisdebut: '',
      moisfin: '',
      cloture: '',
    });
  }
  get f() { return this.carouselform.controls; }
  get ammountControls() {
    return this.tarifform.get('ammounts')['controls'];
  }
  get ammountControls2() {
    return this.coursform.get('ammounts2')['controls'];
  }
  get ammountControls3() {
    return this.otherdsform.get('ammounts3')['controls'];
  }
  get ammountControls4() {
    return this.existentotherdsform.get('ammounts4')['controls'];
  }
  get ammountControls5() {
    return this.exerciceform.get('ammounts5')['controls'];
  }
  setThreeNumberDecimal($event) {
    $event.target.value = $event.target.value ? $event.target.value : 0;
    $event.target.value = parseFloat($event.target.value).toFixed(3);
  }
  setFourNumberDecimal($event) {
    $event.target.value = $event.target.value ? $event.target.value : 0;
    $event.target.value = parseFloat($event.target.value).toFixed(4);
  }
  addammount2(){
    this.ammounts2 = this.coursform.get('ammounts2') as FormArray;
    this.ammounts2.push(this.createammount2());
  }
  removeammount2(i: number) {
    this.ammounts2.removeAt(i); 
  }
  addammount3(){
    this.ammounts3 = this.otherdsform.get('ammounts3') as FormArray;
    this.ammounts3.push(this.createammount3());
  }
  addammount5(){
    this.ammounts5 = this.exerciceform.get('ammounts5') as FormArray;
    this.ammounts5.push(this.createammount5());
  }
  removeammount3(i: number) {  
    this.ammounts3 = this.otherdsform.get('ammounts3') as FormArray;
    this.ammounts3.removeAt(i); 
  }
  removeammount5(i: number) {  
    this.ammounts5 = this.exerciceform.get('ammounts5') as FormArray;
    this.ammounts5.removeAt(i); 
  }
  removeammount4(i: number,id:string) {
    this.deleting=true
    this.ammounts4 = this.existentotherdsform.get('ammounts4') as FormArray; 
    Swal.fire({
      title: 'Veuillez confirmer la suppression!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmer',
    }).then((result) => {
      if (result.value) {
        this.ammounts4.removeAt(i); 
        this.other.deleteotherdsdataById(id).then(
          (data)=>
          {
            this.deleting=false
 //           this.reloadPage()
          }
        )       
      }
      else{
        this.deleting=false
      }
    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });  

  }
  modifyammount4(i: number,id:string) {
    this.updating=true
    this.ammounts4 = this.existentotherdsform.get('ammounts4') as FormArray;
let  otherds:Otherdsmodel = new Otherdsmodel()
    otherds=this.ammounts4.value[i]  
    this.other.modifyotherdsById(id,otherds).then(
          (data:any) => {
           this.updating = false;
           Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'élément modifié avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
          },
          (error) => {
            this.updating = false;            
          }
        )
  }
  sort()
  {
    this.nature.sort()
    this.natureactivite.sort()
    this.activite.sort()
    this.sousactivite.sort()
    this.regimeimpot.sort()
  }
  saveactualite() {
    this.loading = true;
   
    const carousel = new Carouselmodel();
  carousel.titre = this.carouselform.get('titre').value;
    
  carousel.commentaire = this.carouselform.get('commentaire').value;
    
  carousel.description = this.carouselform.get('description').value;
  carousel.rang = this.carouselform.get('rang').value;

    
    carousel.ficheUrl = '';
   if(this.carouselform.get('image').value==null) 
   {
    
      this.carousel.createwithoutfile(carousel).then(
        (data:any) => {
          this.carouselform.reset();
          this.loading = false;
          this.reloadPage()
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'actualité ajoutée avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
        },
        (error) => {
          this.loading = false;
          
        }
      )
    
   
   }
  else
  {
    this.carousel.create(carousel, this.carouselform.get('image').value).then(
      (data:any) => {
        this.carouselform.reset();
        this.loading = false;
        this.reloadPage()
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'actualité ajoutée avec succès!',
          showConfirmButton: false,
          timer: 6000 
        });
      },
      (error) => {
        this.loading = false;
        
      }
    );
  }
  }
  savetarif() {
    this.loading = true;
    const carousel = new Carouselmodel();
    
  carousel.tarifs =[];
  carousel.tarifs=this.tarifform.getRawValue().ammounts
      this.carousel.createwithoutfile(carousel).then(
        (data:any) => {
          this.carouselform.reset();
          this.loading = false;
          this.reloadPage()
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Tarifs ajouté avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
        },
        (error) => {
          this.loading = false;
          
        }
      )
  }
  savecloture(id:string) {
    this.loading = true;
    this.userservice.getUserById(id).then(
      (user:User)=>
      {
        const newuser=new User()

        newuser.exercicecloture =[];
        newuser.exercicecloture=this.exerciceform.getRawValue().ammounts5
        this.userservice.modifyUserById(user._id,newuser).then(
              (data:any) => {
             //   this.carouselform.reset();
                this.loading = false;
                this.reloadPage()
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'parametrage ajouté avec succès!',
                  showConfirmButton: false,
                  timer: 6000 
                });
              },
              (error) => {
                this.loading = false;
                
              }
            )
      }
    )   
 
  }
  async submitotherds() {
    this.submitting = true;
    const otherds = new Otherdsmodel();
    let ammounts3=this.otherdsform.get('ammounts3') as FormArray;
    for await (const element of ammounts3.value) {
      const otherds:Otherdsmodel = new Otherdsmodel()
        otherds.libelle=element.libelle
        otherds.duree=element.duree
        otherds.soumis=element.soumis

      await  this.other.create(otherds).then(
          (data:any) => {
           // this.loading = false;
          },
          (error) => {
          //  this.loading = false;
            
          }
        )
    };
    this.submitting=false
    this.reloadPage()
  }
  savecours() {
    this.loading = true; 
    if(this.cours.length==0)
    {
      const carousel = new Carouselmodel();
      carousel.cours=this.coursform.get('ammounts2').value
      //@ts-ignore
      carousel.cours=carousel.cours.filter(item => (item.dollar!='0'||item.euro!='0'||item.gbp!='0'));
     this.carousel.createwithoutfile(carousel).then(
                (data:any) => {
                  this.carouselform.reset();
                  this.loading = false;
                  this.reloadPage()
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'cours ajouté avec succès!',
                    showConfirmButton: false,
                    timer: 6000 
                  });
                },
                (error) => {
                  this.loading = false;
                  
                }
              ) 
    }
    else
    {
      const carousel = this.cours[0]
      carousel.cours=[]
      carousel.cours=carousel.cours.concat(this.coursform.get('ammounts2').value,this.sortedcours)
      //@ts-ignore
      carousel.cours=carousel.cours.filter(item => (item.dollar!='0'||item.euro!='0'||item.gbp!='0'));

      const duplicates = 
      [...carousel.cours.reduce((map, item) => // group items by id
      //@ts-ignore
        map.set(item.jour, [...(map.get(item.jour) || []), item])
      , new Map)
      .values()] // get grouped arrays
      .filter(list => list.length > 1) // keep duplicates
      //@ts-ignore
      .flat(); // return one array
      if(duplicates.length>0)
      {
        return(
          Swal.fire(`vous avez des cours introduits en double, veuillez vérifier!`),
          this.filtredcours=duplicates
        )
      }
    this.carousel.modify(carousel._id,carousel,'').then(
                (data:any) => {
                  this.carouselform.reset();
                  this.loading = false;
                  this.reloadPage()
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'cours ajouté avec succès!',
                    showConfirmButton: false,
                    timer: 6000 
                  });
                },
                (error) => {
                  this.loading = false;                
                }
              ) 
    }
  
  }
  sendsms() {
    this.loading = true;

      this.relationservice.sendsms(this.smsform.get('description').value).then(
        (data:any) => {
          this.smsform.reset();
          this.loading = false;
          this.reloadPage()
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'sms envoyés avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
        },
        (error) => {
          this.loading = false;
          
        }
      )
  }
  savenewversion() {
    this.loading = true;
    const version = new Versionmodel();
version.first=this.firstnumber
version.second=this.secondnumber
version.third=this.thirdnumber
    this.ver.create(version).then(
        (data:any) => {
          this.loading = false;
          this.reloadPage()
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'version créé avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
        },
        (error) => {
          this.loading = false;
          
        }
      )
  }
  onImagePick(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.carouselform.get('image').patchValue(file);
    this.carouselform.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      if (this.carouselform.get('image').valid) {
        this.imagePreview = reader.result as string;
        this.fileUploaded = true;
      } else {
        this.imagePreview = null;
      }
    };
    reader.readAsDataURL(file);
    
  }
  
  getNavigation(link, id){
      
    this.carousel.getCarouseldataById(id);
    this.router.navigate([link + '/' + id]); 
  }
  getcarousels() {
                                
                                                
    this.carousel.getCarouselalldata();
                                                   
                                                     
 }
 onDeleteotherds(id) {
  this.loading = true;
  this.other.getOtherdsdataById(id).then(
        (data:any) => {
          this.loading = false;
          Swal.fire({
            title: 'Veuillez confirmer la suppression!',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmer',
          }).then((result) => {
            if (result.value) {
              this.other.deleteotherdsdataById(id);
              this.reloadPage()
            }
          }).catch(() => {
            Swal.fire('opération non aboutie!');
          });     
        }       
      );  
}
 onDelete(id) {
    this.loading = true;
    this.carousel.getCarouseldataById(id);
    
        this.carousel.getCarouseldataById(id).then(
          (data:any) => {
            this.loading = false;
            Swal.fire({
              title: 'Veuillez confirmer la suppression!',
              
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.carousel.deletecarouseldataById(id);
                this.reloadPage()
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      
  }
 myFunction1() {
  var checkbox:any = document.getElementById("myCheck1");
  var text2 = document.getElementById("bodycontainer");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    Swal.fire({
      title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire d\'ajout d\'actualités, voulez vous continuer?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Réinitialiser',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        
        this.carouselform.reset();
        text2.style.display = "none";
      }
      else{
        checkbox.checked = true
        text2.style.display = "block";

      }
    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });
  }
}
myFunction2() {
  var checkbox:any = document.getElementById("myCheck2");
  var text2 = document.getElementById("bodycontainer2");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    Swal.fire({
      title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire d\'ajout d\'utilisateurs, voulez vous continuer?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Réinitialiser',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        
        text2.style.display = "none";
      }
      else{
        checkbox.checked = true
        text2.style.display = "block";

      }
    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });
  }
}
myFunction3() {
  var checkbox:any = document.getElementById("myCheck3");
  var text2 = document.getElementById("bodycontainer3");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    Swal.fire({
      title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire d\'ajout des tarifs, voulez vous continuer?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Réinitialiser',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        
        this.carouselform.reset();
        text2.style.display = "none";
      }
      else{
        checkbox.checked = true
        text2.style.display = "block";

      }
    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });
  }
}
myFunction4() {
  var checkbox:any = document.getElementById("myCheck4");
  var text2 = document.getElementById("bodycontainer4");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    Swal.fire({
      title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire d\'ajout de relations, voulez vous continuer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Réinitialiser',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) { 
        text2.style.display = "none";
      }
      else{
        checkbox.checked = true
        text2.style.display = "block";

      }
    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });
  }
}
myFunction5() {
  var checkbox:any = document.getElementById("myCheck5");
  var text2 = document.getElementById("bodycontainer5");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    Swal.fire({
      title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire d\'envoi des sms, voulez vous continuer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Réinitialiser',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) { 
        text2.style.display = "none";
      }
      else{
        checkbox.checked = true
        text2.style.display = "block";

      }
    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });
  }
}
myFunction6() {
  var checkbox:any = document.getElementById("myCheck6");
  var text2 = document.getElementById("bodycontainer6");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    text2.style.display = "none";
  }
}
myFunction7() {
  var checkbox:any = document.getElementById("myCheck7");
  var text2 = document.getElementById("bodycontainer7");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    Swal.fire({
      title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire de cloture des exercices, voulez vous continuer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Réinitialiser',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.value) {
        this.exerciceform.reset();
        text2.style.display = "none";
      }
      else{
        checkbox.checked = true
        text2.style.display = "block";

      }
    }).catch(() => {
      Swal.fire('opération non aboutie!');
    });
  }
}
myFunction8() {
  var checkbox:any = document.getElementById("myCheck8");
  var text2 = document.getElementById("bodycontainer8");
  if (checkbox.checked == true){
    text2.style.display = "block";
  } else {
    text2.style.display = "none";
  }
}
onFileChange(event) {
  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
    this.uploadEvent = event;
  }
  let fileReader = new FileReader();
  fileReader.onload = (e) => {
    this.arrayBuffer = fileReader.result;
    //@ts-ignore
    var data = new Uint8Array(this.arrayBuffer);
    var arr = new Array();
    for (var i = 0; i != data.length; ++i)
      arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");
    var workbook = read(bstr, {
      type: "binary"
    });
    var first_sheet_name = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[first_sheet_name];
    this.exceljsondata2 = utils.sheet_to_json(worksheet, {
      raw: true,
      defval: "",
    });
  };
  fileReader.readAsArrayBuffer(this.file);
}
afficher()
{
  this.loading = true;
  this.userservice.addusers(this.exceljsondata2).then(
    (data:any) => {
      this.loading = false;
Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'utilisateurs ajoutées avec succès!',
        showConfirmButton: false,
        timer: 6000 
      });
          this.reloadPage();
    },
    (error) => {
      this.loading = false;
      
    }
  );
}
onFileChange2(event) {
  if (event.target.files.length > 0) {
    this.file = event.target.files[0];
    this.uploadEvent = event;
  }
  let fileReader = new FileReader();
  fileReader.onload = (e) => {
    this.arrayBuffer = fileReader.result;
    //@ts-ignore
    var data = new Uint8Array(this.arrayBuffer);
    var arr = new Array();
    for (var i = 0; i != data.length; ++i)
      arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");
    var workbook = read(bstr, {
      type: "binary"
    });
    var first_sheet_name = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[first_sheet_name];
    this.exceljsondata3 = utils.sheet_to_json(worksheet, {
      raw: true,
      defval: "",
    });
  };
  fileReader.readAsArrayBuffer(this.file);
}
afficher2()
{
  this.loading = true;
  this.relationservice.addrelations(this.exceljsondata3).then(
    (data:any) => {
      this.loading = false;
Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'relations ajoutées avec succès!',
        showConfirmButton: false,
        timer: 6000 
      });
          this.reloadPage();
    },
    (error) => {
      this.loading = false;
      
    }
  );
}
deleteall() {
  this.relationservice.deleteAllRelations().then(
    (data: any) => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'toutes les relations sont suuprimés avec succès!',
        showConfirmButton: false,
        timer: 6000 
      });
      this.reloadPage()
    });
}
onFileChange3(event,i:number) {
  this.exceljsondata4=[]
  let ammounts2 = this.coursform.get('ammounts2') as FormArray;
  if (event.target.files.length > 0) 
  {
    this.file = event.target.files[0];
    this.uploadEvent = event;
  }
  let fileReader = new FileReader();
  fileReader.onload = (e) => {
    this.arrayBuffer = fileReader.result;
    //@ts-ignore
    var data = new Uint8Array(this.arrayBuffer);
    var arr = new Array();
    for (var j = 0; j != data.length; ++j)
      arr[j] = String.fromCharCode(data[j]);
    var bstr = arr.join("");
    var workbook = read(bstr, {
      type: "binary"
    });
    var first_sheet_name = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[first_sheet_name];
    this.exceljsondata4 = utils.sheet_to_json(worksheet, {
      raw: true,
      defval: "",
    });
    this.exceljsondata4.length==19?ammounts2.at(i).patchValue({
      euro:(+this.exceljsondata4[13].Valeur/10000).toFixed(4),
      dollar:(+this.exceljsondata4[4].Valeur/10000).toFixed(4),
      gbp:(+this.exceljsondata4[5].Valeur/10000).toFixed(4),
      dzd:(+this.exceljsondata4[0].Valeur/100000).toFixed(4),
      sar:(+this.exceljsondata4[1].Valeur/100000).toFixed(4),
      cad:(+this.exceljsondata4[2].Valeur/10000).toFixed(4),
      dkk:(+this.exceljsondata4[3].Valeur/1000000).toFixed(4),
      jpy:(+this.exceljsondata4[6].Valeur/10000000).toFixed(4),
      mad:(+this.exceljsondata4[7].Valeur/100000).toFixed(4),
      nok:(+this.exceljsondata4[8].Valeur/1000000).toFixed(4),
      sek:(+this.exceljsondata4[9].Valeur/100000).toFixed(4),
      chf:(+this.exceljsondata4[10].Valeur/100000).toFixed(4),
      kwd:(+this.exceljsondata4[11].Valeur/10000).toFixed(4),
      aed:(+this.exceljsondata4[12].Valeur/100000).toFixed(4),
      lyd:(+this.exceljsondata4[14].Valeur/10000).toFixed(4),
      mru:(+this.exceljsondata4[15].Valeur/100000).toFixed(4),
      bhd:(+this.exceljsondata4[16].Valeur/10000).toFixed(4),
      qar:(+this.exceljsondata4[17].Valeur/100000).toFixed(4),
      cny:(+this.exceljsondata4[18].Valeur/10000).toFixed(4),
     }):this.exceljsondata4.length==20?
     ammounts2.at(i).patchValue({
      euro:(+this.exceljsondata4[14].__EMPTY_4/10000).toFixed(4),
      dollar:(+this.exceljsondata4[5].__EMPTY_4/10000).toFixed(4),
      gbp:(+this.exceljsondata4[6].__EMPTY_4/10000).toFixed(4),
      dzd:(+this.exceljsondata4[1].__EMPTY_4/100000).toFixed(4),
      sar:(+this.exceljsondata4[2].__EMPTY_4/100000).toFixed(4),
      cad:(+this.exceljsondata4[3].__EMPTY_4/10000).toFixed(4),
      dkk:(+this.exceljsondata4[4].__EMPTY_4/1000000).toFixed(4),
      jpy:(+this.exceljsondata4[7].__EMPTY_4/10000000).toFixed(4),
      mad:(+this.exceljsondata4[8].__EMPTY_4/100000).toFixed(4),
      nok:(+this.exceljsondata4[9].__EMPTY_4/1000000).toFixed(4),
      sek:(+this.exceljsondata4[10].__EMPTY_4/100000).toFixed(4),
      chf:(+this.exceljsondata4[11].__EMPTY_4/100000).toFixed(4),
      kwd:(+this.exceljsondata4[12].__EMPTY_4/10000).toFixed(4),
      aed:(+this.exceljsondata4[13].__EMPTY_4/100000).toFixed(4),
      lyd:(+this.exceljsondata4[15].__EMPTY_4/10000).toFixed(4),
      mru:(+this.exceljsondata4[16].__EMPTY_4/100000).toFixed(4),
      bhd:(+this.exceljsondata4[17].__EMPTY_4/10000).toFixed(4),
      qar:(+this.exceljsondata4[18].__EMPTY_4/100000).toFixed(4),
      cny:(+this.exceljsondata4[19].__EMPTY_4/10000).toFixed(4),
     })
     : Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Un problème est survenu, cliquer sur le bouton pour réessayer',
      confirmButtonText: 'Réessayer',
      timer: 30000,
    })
  };
  fileReader.readAsArrayBuffer(this.file);
}
reloadPage(): void {
  
  setTimeout(() => window.location.reload(), 1000);
  
}
filtercours(){
  if(this.searchForm.get('dateinf').value&&this.searchForm.get('datesup').value)
  {
    //@ts-ignore
    this.filtredcours=this.sortedcours.filter(p =>new Date(p.jour)<=new Date(this.searchForm.get('datesup').value)
    //@ts-ignore
    &&new Date(p.jour)>=new Date(this.searchForm.get('dateinf').value))
  }
  else if(this.searchForm.get('dateinf').value&&!this.searchForm.get('datesup').value)
  {
        //@ts-ignore
    this.filtredcours=this.sortedcours.filter(p =>new Date(p.jour)>=new Date(this.searchForm.get('dateinf').value))
  }
  else if(!this.searchForm.get('dateinf').value&&this.searchForm.get('datesup').value)
  {
        //@ts-ignore
    this.filtredcours=this.sortedcours.filter(p =>new Date(p.jour)<=new Date(this.searchForm.get('datesup').value))
  }
}
remove(k:Date) {
  //@ts-ignore
this.sortedcours=this.sortedcours.filter(p=>p.jour!=k)
this.filtredcours=this.filtredcours.filter(p=>p.jour!=k)
}
checkdate(i:number)
{
  let ammounts2 = this.coursform.get('ammounts2') as FormArray;
let date=ammounts2.value.at(i).jour
  //@ts-ignore
let chosendatecours=this.sortedcours.find(element=>element.jour==date)
if(chosendatecours)
{
  Swal.fire({
    title: 'un cours avec cette date existe déjà!veuillez vérifier',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Vérifier',    
  }).then((result) => {
    if (result.value) {
      ammounts2.at(i).patchValue(
        {
            //@ts-ignore
dollar:chosendatecours.dollar,
  //@ts-ignore
euro:chosendatecours.euro,
  //@ts-ignore
gbp:chosendatecours.gbp,
            //@ts-ignore
            dzd:chosendatecours.dzd,
            //@ts-ignore
          sar:chosendatecours.sar,
            //@ts-ignore
          cad:chosendatecours.cad,
                      //@ts-ignore
dkk:chosendatecours.dkk,
//@ts-ignore
jpy:chosendatecours.jpy,
//@ts-ignore
mad:chosendatecours.mad,
            //@ts-ignore
            nok:chosendatecours.nok,
            //@ts-ignore
          sek:chosendatecours.sek,
            //@ts-ignore
          chf:chosendatecours.chf,
                      //@ts-ignore
kwd:chosendatecours.kwd,
//@ts-ignore
aed:chosendatecours.aed,
//@ts-ignore
lyd:chosendatecours.lyd,
            //@ts-ignore
            mru:chosendatecours.mru,
            //@ts-ignore
          bhd:chosendatecours.bhd,
            //@ts-ignore
          qar:chosendatecours.qar,
          //@ts-ignore
          cny:chosendatecours.cny
        }
      )
        //@ts-ignore
      this.filtredcours=this.filtredcours.filter(p=>p.jour==chosendatecours.jour)
    }
else
{
  ammounts2.at(i).patchValue(
    {
      jour:null,
dollar:0,
euro:0,
gbp:0,
dzd: 0,
sar: 0,
cad: 0,
dkk: 0,
jpy: 0,
mad: 0,
nok: 0,
sek: 0,
chf: 0,
kwd: 0,
aed: 0,
lyd: 0,
mru: 0,
bhd: 0,
qar: 0,
cny: 0
    }
  )
}
  }).catch(() => {
    Swal.fire('opération non aboutie!');
  });
}
}
affichertout()
{
  this.filtredcours=this.sortedcours
}
}
