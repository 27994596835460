<mat-card class="invoice-card" id="invoicepdf">
  <mat-card-header class="card-header">
    <div class="header-content">
      <img mat-card-avatar [src]="invoice.logo" alt="Logo de l'entreprise">
      <mat-card-title>Facture #{{invoice.prefixe}}/{{invoice.ref}} ({{invoice.number}})</mat-card-title>
      <div class="header-dates">
        <span class="validity-date">
          <label>Valable jusqu'au:</label> {{ invoice.validityDate | customDate }}
        </span>
        <span class="current-date">
          <label>Tunis, le:</label> {{ currentDate | customDate }}
        </span>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content>
    <div class="invoice-info">
      <h2>Informations sur le client</h2>
      <p><strong>Nom :</strong> {{ invoice.clientdetails.name }}</p>
      <p><strong>Email :</strong> {{ invoice.clientdetails.email }}</p>
      <p><strong>Adresse :</strong> {{ invoice.clientdetails.address }}</p>
      <p><strong>Mobile :</strong> {{ invoice.clientdetails.phone }}</p>
    </div>

    <div class="invoice-info">
      <h2>Informations sur le vendeur</h2>
      <p><strong>Nom :</strong> {{ invoice.fournisseurdetails.name }}</p>
      <p><strong>Email :</strong> {{ invoice.fournisseurdetails.email }}</p>
      <p><strong>Adresse :</strong> {{ invoice.fournisseurdetails.address }}</p>
      <p><strong>Mobile :</strong> {{ invoice.fournisseurdetails.phone }}</p>
    </div>

    <div class="invoice-items">
      <h2>Articles</h2>
      <mat-table [dataSource]="invoice.itemsdetails" class="mat-elevation-z8">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Numéro </mat-header-cell>
          <mat-cell *matCellDef="let item"> {{ item.id }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Libellé </mat-header-cell>
          <mat-cell *matCellDef="let item"> {{ item.libelle }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let item"> {{ item.description }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef> Quantité </mat-header-cell>
          <mat-cell *matCellDef="let item"> {{ item.quantity }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef> Prix unitaire </mat-header-cell>
          <mat-cell *matCellDef="let item"> {{ item.unitPrice | currency:'TND' }} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>

    <div class="invoice-section">
      <h2>Totaux</h2>
      <p><strong>Sous-total :</strong> {{ subtotal | currency:'TND' }}</p>
      <p><strong>TVA :</strong> {{ invoice.tva | percent }}</p>
      <p><strong>Total :</strong> {{ total | currency:'TND' }}</p>
      <p><strong>Total en lettres :</strong> {{ total | numberToWords }} dinars</p>
    </div>

    <div class="invoice-section">
      <h2>Informations supplémentaires</h2>
      <p><strong>Statut :</strong> {{ invoice.status }}</p>
      <p><strong>Conditions :</strong> {{ invoice.terms }}</p>
      <p><strong>Remarques :</strong> {{ invoice.notes }}</p>
    </div>

    <div class="invoice-section signatures">
      <div class="signature">
        <h3>Signature du vendeur</h3>
      </div>
      <div class="signature">
        <h3>Signature du client</h3>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="generatePDF()">Télécharger le PDF</button>
  </mat-card-actions>
</mat-card>
