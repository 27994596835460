
<div class="page-container" *ngIf="user">
  <h1>Complétude des Données Utilisateurs</h1>
        <!-- Content for Modification Données Signalétiques -->
        <div *ngIf="!user.natureactivite || user.natureactivite=='Autre/null' || !user.activite || user.activite=='Autre/null'|| user.regimefiscalimpot=='Autre/null'
        || !user.regimefiscalimpot && user.nature=='Personne Physique' || !user.matriculefiscale|| !user.adresseactivite || !user.codepostal">
       <h4 *ngIf="user.usertype=='Client'"> veuillez renseigner les champs obligatoires (*)</h4>
      </div>
<mat-tab-group (selectedTabChange)="onTabChangesdonnessignaletique($event)">
          <form  [formGroup]="userForm">
          <mat-tab label="Informations générales">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <mat-form-field appearance="outline">
                        <mat-label>Nature D'activité</mat-label>
                        <mat-select [(ngModel)]="optionValue" (selectionChange)="update0($event)" formControlName="activitynature">
                          <mat-option [value]="currentuser.natureactivite" hidden>{{currentuser.natureactivite}}</mat-option>
                          <mat-option value=""></mat-option>
                          <mat-option *ngFor="let natactivite of natureactivites" [value]="natactivite">{{natactivite}}</mat-option>
                          <mat-option value="Autre">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="optionValue == 'Autre'">
                      <mat-form-field appearance="outline">
                        <mat-label>Nature D'activité</mat-label>
                        <input matInput placeholder="Renseigner votre nature d'activité" formControlName="selectactivitynature">
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <mat-form-field appearance="outline">
                        <mat-label>Activité</mat-label>
                        <mat-select [(ngModel)]="option1Value" (click)="sort()" (selectionChange)="update($event)" formControlName="activity" style="width: 150px;">
                          <mat-option [value]="user.activite" hidden>{{user.activite}}</mat-option>
                          <mat-option value=""></mat-option>
                          <mat-option *ngFor="let activite of activites" [value]="activite">{{activite}}</mat-option>
                          <mat-option value="Autre" *ngIf="optionValue != 'association et syndic'">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="option1Value == 'Autre'">
                      <mat-form-field appearance="outline">
                        <mat-label>Activité</mat-label>
                        <input matInput placeholder="Renseigner votre activité" formControlName="selectactivity">
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <mat-form-field appearance="outline">
                        <mat-label>Date d'effet</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Modifier Votre Date d'effet" [ngClass]="{ 'is-invalid': submitted && f.dateeffeterrors }" formControlName="dateeffet">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <p *ngIf="user.dateeffet">{{user.dateeffet}}</p>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <mat-form-field appearance="outline">
                        <mat-label>Sous-Activité</mat-label>
                        <mat-select [(ngModel)]="option2Value" (click)="sort()" (selectionChange)="update2($event)" formControlName="underactivity">
                          <mat-option [value]="user.sousactivite" hidden>{{user.sousactivite}}</mat-option>
                          <mat-option value=""></mat-option>
                          <mat-option *ngFor="let sousactivite of sousactivites" [value]="sousactivite">{{sousactivite}}</mat-option>
                          <mat-option value="Autre">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="option2Value == 'Autre'">
                      <mat-form-field appearance="outline">
                        <mat-label>Sous-Activité</mat-label>
                        <input matInput placeholder="Renseigner votre Sous-Activité" formControlName="selectunderactivity">
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <mat-form-field appearance="outline">
                        <mat-label>Spécialité</mat-label>
                        <mat-select (click)="sort()" (selectionChange)="update3($event)" formControlName="specialite">
                          <mat-option [value]="user.specialite" hidden>{{user.specialite}}</mat-option>
                          <mat-option value=""></mat-option>
                          <mat-option *ngFor="let specialite of specialites" [value]="specialite">{{specialite}}</mat-option>
                          <mat-option value="Autre">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <mat-form-field appearance="outline">
                        <mat-label>Sous-Spécialité</mat-label>
                        <mat-select formControlName="sousspecialite">
                          <mat-option [value]="user.sousspecialite" hidden>{{user.sousspecialite}}</mat-option>
                          <mat-option value=""></mat-option>
                          <mat-option *ngFor="let sousspecialite of sousspecialites" [value]="sousspecialite">{{sousspecialite}}</mat-option>
                          <mat-option value="Autre">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td colspan="2">
                    <div class="form-group" *ngIf="user.usertype == 'Client' && userForm.controls['nature'].value=='Personne Physique'">
                      <mat-form-field appearance="outline">
                        <mat-label>Régime Fiscal En Matière D'impôts Directs</mat-label>
                        <mat-select [(ngModel)]="option3Value" formControlName="fiscalimpot">
                          <mat-option [value]="user.regimefiscalimpot" hidden>{{user.regimefiscalimpot}}</mat-option>
                          <mat-option value="Réel">Réel</mat-option>
                          <mat-option value="Forfait D'assiette">Forfait D'assiette</mat-option>
                          <mat-option *ngIf="userForm.controls['activitynature'].value!='Profession Libérale'" value="Non soumis">Non soumis</mat-option>
                          <mat-option value="Autre">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="option3Value == 'Autre'">
                      <mat-form-field appearance="outline">
                        <mat-label>Régime Fiscal En Matière D'impôts Directs</mat-label>
                        <input matInput placeholder="Renseigner régime fiscal en matière d'impôts directs" formControlName="selectfiscalimpot">
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <mat-form-field appearance="outline">
                        <mat-label>Régime Fiscal En Matière de TVA</mat-label>
                        <mat-select formControlName="fiscaltvaassobli" (click)="sort()">
                          <mat-option [value]="user.regimefiscaltva" hidden>{{user.regimefiscaltva}}</mat-option>
                          <mat-option value=""></mat-option>
                          <mat-option *ngFor="let fiscaltvaassobli of fiscaltvaassoblis" [value]="fiscaltvaassobli">{{fiscaltvaassobli}}</mat-option>
                          <mat-option value="Autre">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Informations spécifiques">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="form-group" *ngIf="natsociete">
                      <label for="choice" class="col-md-8 control-label">Êtes-vous totalement exportateur:</label>
                      <div id="block1" *ngIf="natsociete">
                        <mat-checkbox id="choice" formControlName="export">Oui</mat-checkbox>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="col-md-4 control-label" *ngIf="tot || userForm.controls['export'].value == true">Déclaration d'investissement</label>
                      <div class="col-md-8 inputGroupContainer" *ngIf="tot || userForm.controls['export'].value == true">
                        <div class="input-group">
                          <span class="input-group-addon" style="max-width: 100%;"><i class="glyphicon glyphicon-asterisk" style="color: red;"></i></span>
                          <mat-form-field appearance="fill">
                            <mat-label>Upload File</mat-label>
                            <input type="file" matInput (change)="onImagePick2($event)" accept="application/pdf">
                            <mat-icon matSuffix>upload</mat-icon>
                          </mat-form-field>
                          <b><a href="{{user.ficheUrl2}}" target="_blank">Aperçu DI</a></b>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="natsociete">
                      <label for="choice" class="col-md-8 control-label">Avez-vous une matricule CNSS:</label>
                      <div id="block3">
                        <mat-checkbox id="choice3" formControlName="salaire">Oui</mat-checkbox>
                      </div>
                    </div>
                  </td>
                </tr>
                
              </tbody>
              </table>
          </mat-tab>
          <mat-tab label="Informations supplémentaires">
            <table>
              <tbody>
                <tr *ngIf="sal || userForm.controls['salaire'].value==true">
                  <td>
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>Matricule CNSS</mat-label>
                        <input matInput type="text" placeholder="Renseigner la matricule cnss" formControlName="matriculecnss">
                        <mat-icon matPrefix>phone</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>Taux de la charge patronale</mat-label>
                        <input matInput type="text" placeholder="Renseigner le taux de la charge patronale" formControlName="tauxchargepatronale">
                        <mat-icon matPrefix>phone</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>Taux de l'accident de travail</mat-label>
                        <input matInput type="text" placeholder="Renseigner le taux de l'accident de travail" formControlName="tauxaccidenttravail">
                        <mat-icon matPrefix>phone</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field appearance="outline">
                        <mat-label>Régime horaire</mat-label>
                        <input matInput type="text" placeholder="Renseigner le régime horaire" formControlName="regimehoraire">
                        <mat-icon matPrefix>phone</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                  
                <tr>
                  <td *ngIf="user.activite == 'Syndic des copropriétaires' || userForm.controls['activity'].value=='Syndic des copropriétaires'">
                    <div class="form-group">
                      <mat-checkbox id="choice4" formControlName="matfiscale">Avez vous une matricule fiscale: Oui</mat-checkbox>
                    </div>
                  </td>
                  <td *ngIf="user.activite != 'Syndic des copropriétaires' || (user.usertype == 'Client' && mat) || (user.usertype == 'Client' && userForm.controls['matfiscale'].value==true)">
                    <div class="form-group">
                      <label class="col-md-4 control-label">Carte d'identification fiscale</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field appearance="outline">
                          <mat-label>Upload File</mat-label>
                          <input matInput type="file" (change)="onImagePick($event)" accept="application/pdf">
                          <mat-icon matPrefix>upload</mat-icon>
                        </mat-form-field>
                        <b><a href="{{user.ficheUrl}}" target="_blank">Aperçu CIF</a></b>
                      </div>
                    </div>
                  </td>
                  <td colspan="2" *ngIf="mat || userForm.controls['matfiscale'].value==true || !user.activite">
                    <div class="input-container">
                      <label class="col-md-4 control-label">Matricule Fiscale</label>
                      <div class="col-md-8 inputGroupContainer">
                        <div class="input-row">
                          <mat-form-field appearance="outline" class="mat-form-field-small">
                            <input matInput type="text" formControlName="fiscalmat" placeholder="saisir les 7 premiers chiffres" [ngClass]="{ 'is-invalid': f.fiscalmat.errors }">
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="mat-form-field-small">
                            <input matInput type="text" formControlName="fiscalmatletter" placeholder="saisir la lettre en majuscule" [ngClass]="{ 'is-invalid': f.fiscalmatletter.errors }">
                          </mat-form-field>
                          <p>/</p>
                          <mat-form-field appearance="outline" class="mat-form-field-small">
                            <input matInput type="text" formControlName="fiscalmatinchanged" placeholder="B" [ngClass]="{ 'is-invalid': f.fiscalmatinchanged.errors }">
                          </mat-form-field>
                          <p>/</p>
                          <mat-form-field appearance="outline" class="mat-form-field-small">
                            <input matInput type="text" formControlName="fiscalmatinchanged2" placeholder="C" [ngClass]="{ 'is-invalid': f.fiscalmatinchanged2.errors }">
                          </mat-form-field>
                          <p>/</p>
                          <mat-form-field appearance="outline" class="mat-form-field-small">
                            <input matInput type="text" formControlName="fiscalmatnumbers" placeholder="saisir les trois chiffres" [ngClass]="{ 'is-invalid': f.fiscalmatnumbers.errors }">
                          </mat-form-field>
                        </div>
                        <div class="validation-messages">
                          <div *ngIf="f.fiscalmat.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmat.errors.pattern">Veuillez saisir les 7 premiers chiffres</div>
                            <div *ngIf="f.fiscalmat.errors.length">Saisissez uniquement 7 lettres</div>
                            <div *ngIf="f.fiscalmat.errors.required">1ère partie obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatletter.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatletter.errors.pattern">Veuillez saisir la lettre majuscule qui suit les chiffres</div>
                            <div *ngIf="f.fiscalmatletter.errors.length">Saisissez uniquement 1 lettre</div>
                            <div *ngIf="f.fiscalmatletter.errors.required">2ème partie obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatinchanged.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatinchanged.errors.pattern">Veuillez saisir une première lettre majuscule</div>
                            <div *ngIf="f.fiscalmatinchanged.errors.length">Saisissez uniquement 1 lettre</div>
                            <div *ngIf="f.fiscalmatinchanged.errors.required">1ère lettre obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatinchanged2.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatinchanged2.errors.pattern">Veuillez saisir une deuxième lettre majuscule</div>
                            <div *ngIf="f.fiscalmatinchanged2.errors.length">Saisissez uniquement 1 lettre</div>
                            <div *ngIf="f.fiscalmatinchanged2.errors.required">2ème lettre obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatnumbers.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatnumbers.errors.pattern">Veuillez saisir les 3 derniers chiffres</div>
                            <div *ngIf="f.fiscalmatnumbers.errors.length">Saisissez uniquement 3 chiffres</div>
                            <div *ngIf="f.fiscalmatnumbers.errors.required">3ème partie obligatoire</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                
                <tr *ngIf="user.activite == 'Syndic des copropriétaires' || userForm.controls['activity'].value=='Syndic des copropriétaires'">
                  <td colspan="1">
                    <fieldset>
                      <legend>Liste des copropriétaires</legend>
                      <p>veuillez respecter la forme suivante du fichier excel à rattacher :</p>
                      <p>1ère colonne: numero &nbsp; 2ème colonne: coproprietaire</p>
                      <div class="form-group row">
                        <label class="col-sm-12 col-form-label">Charger document</label>
                        <div class="col-sm-12">
                          <mat-form-field appearance="fill" class="full-width">
                            <mat-label>Upload File</mat-label>
                            <input type="file" matInput (change)="onFileChange($event)" accept=".xls, .xlsx">
                            <mat-icon matSuffix>upload</mat-icon>
                          </mat-form-field>
                        </div>
                      </div>
                      <table mat-table class="table table-striped" [formGroup]="copform">
                        <thead>
                          <tr>
                            <th mat-header-cell *matHeaderCellDef scope="col">Numéro</th>
                            <th mat-header-cell *matHeaderCellDef scope="col">Copropriétaire</th>
                          </tr>
                        </thead>
                        <tbody formArrayName="ammounts2">
                          <tr mat-row *ngFor="let ammount of ammountControls2; let i = index;" [formGroupName]="i">
                            <td mat-cell>
                              <mat-form-field appearance="fill">
                                <input matInput type="text" formControlName="numero" placeholder="Numéro" (change)="check(i)">
                              </mat-form-field>
                            </td>
                            <td mat-cell>
                              <mat-form-field appearance="fill">
                                <textarea matInput cols="30" rows="2" formControlName="coproprietaire" placeholder="Copropriétaire"></textarea>
                              </mat-form-field>
                            </td>
                            <td mat-cell>
                              <button mat-icon-button color="warn" (click)="removeammount2(i)">
                                <mat-icon>remove_circle</mat-icon>
                              </button>
                            </td>
                            <td mat-cell>
                              <button mat-icon-button color="primary" (click)="addammount2(i)">
                                <mat-icon>add_circle</mat-icon>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </td>
                </tr>
                
                </tbody>
            </table>
          </mat-tab>
        </form>
</mat-tab-group>
        <button class="btn btn-success"[disabled]="userForm.invalid" (click)="onSubmit()" ><mat-icon *ngIf="loading" class="fa-spin">autorenew</mat-icon>Valider</button>
</div>