// signup-modal.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignupModalService  {
  private isModalOpen = new BehaviorSubject<boolean>(false);
  modalOpen$ = this.isModalOpen.asObservable();

  openModal() {
    this.isModalOpen.next(true);
    console.log('Modal opened');
  }
  
  closeModal() {
    this.isModalOpen.next(false);
    console.log('Modal closed');
  }
}
