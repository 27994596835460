import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Otherdsmodeluser } from '../models/otherds-user.model';
const API_URL_test = 'http://localhost:3003/api/otherdsuser/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/otherdsuser/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class OtherdsServiceUser {  
    constructor(private http: HttpClient) { }
    public otherdss: Otherdsmodeluser[] = [];
    public otherdss$ = new Subject<Otherdsmodeluser[]>();
    getOtherdsalldata() {
        this.http.get(API_URL_cloud).subscribe(
          (otherdss: Otherdsmodeluser[]) => {
            if (otherdss) {
              this.otherdss = otherdss;
              this.emitOtherdss();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitOtherdss() {
        this.otherdss$.next(this.otherdss);
      }
      getOtherdsalldatawithoutsubscription() {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getOtherdsdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getOtherdsdataByuser(client: string) {
        this.http.get(API_URL_cloud+'client/'+client).subscribe(
            (otherdss: Otherdsmodeluser[]) => {
              if (otherdss) {
                this.otherdss = otherdss;
                this.emitOtherdss();
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
      getOtherdsdataByuserwithoutsubscription(client: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud+'client/'+client).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(otherds: Otherdsmodeluser) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, otherds).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      modifyotherdsById(id: string, otherds: Otherdsmodeluser) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, otherds).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      modifyotherdsByIdforuser(id: string, otherds: Otherdsmodeluser) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+'modify/'+ id, otherds).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      deleteotherdsdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
