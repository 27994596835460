
<div class="view-autre-ds-container">
  <div class="line"></div>
  <div class="table-container">
    <h2>Détails De La demande de service!</h2>
    <table *ngIf="otherdsuser">
      <thead>
        <tr>     
            <th>libelle</th>
            <th>description</th>
            <th>duree</th>
            <th>total honoraire HT</th>
            <th>Soumis/Non soumis</th>
            <th>Statut</th>        
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{otherdsuser.libelle | uppercase}}</td>
            <td>{{otherdsuser.description | uppercase}}</td>
            <td>{{otherdsuser.duree}}</td>
            <td>{{otherdsuser.totalhonoraire | number:'1.3-3'| removeComma}}</td>
            <td><span *ngIf="otherdsuser.soumis"> Soumis</span> <span *ngIf="!otherdsuser.soumis"> Non soumis</span></td>
            <td [ngStyle]="{'background-color': (!otherdsuser.paye)? 'red':'lightgreen' }"><span *ngIf="otherdsuser.paye"> Règlé</span> <span *ngIf="!otherdsuser.paye"> Non règlé</span></td>
          </tr>
          <tr>  
          </tr>
          </tbody>
    </table>
  </div>
</div>
