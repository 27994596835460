<body class="fs-4">
    <div class="container mt-5">
      <h1 class="text-center mt-5 text-danger fs-2">الخصم من المورد</h1>
      <h1 class="text-center mt-3 text-danger fs-2">RETENUE A LA SOURCE</h1>
      <table class="table table-bordered mt-5">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" class="text-center">أساس الخصم</th>
            <th scope="col" class="text-center">النسبة</th>
            <th scope="col" class="text-center">مبلغ الخصم</th>
            <th scope="col"></th>
          </tr>
          <tr>
            <th scope="col"></th>
            <th scope="col" class="text-center">Assiette de la retenue</th>
            <th scope="col" class="text-center">Taux</th>
            <th scope="col" class="text-center">Montant de la retenue</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody [formGroup]="rsform">
          <tr>
            <td class="text-left-fr">
              Traitements, salaires, pensions et rentes viagères ayant subi une
              retenue à la source dans le cadre du droit commun
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="salariesPensionsSource"  />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="salariesPensions"  />
            </td>
            <td class="text-right-ar">
              المرتبات و الأجور و الجرايات و الإيرادات العمرية التي تحملت خصما
              من المورد طبقا للقانون لعام
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              Traitements, salaires, rémunérations, indemnités et avantages
              ayant subi une retenue à la source au taux de 20%
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="salaries20PercentSource"  />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="salaries20Percent"  />
            </td>
            <td class="text-right-ar">
              المرتبات و الأجور وو المكافآت والمنح و الإمتيازات الأخرى المتعلقة
              بها التي تحملت خصما من المورد بنسبة 20 بالمائة
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              La contribution sociale de solidarité due sur les traitements,
              salaires, remunerations, indemnites
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="socialContribution"  />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar">
              المساهمة الاجتماعية ? التضامنية المستوجبة على المرتبات والأجور
              والمنح والجرايات والإيرادات العمرية
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              Commissions, Courtages et Loyers et Redevances des activités non
              commerciales
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar">
              العمولات و أجور الوساطة و الأكرية ومكافآت الأنشطة الغير التجاريّة
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr text-primary">
              - Servis aux résidents et établis
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar text-primary">
              -المدفوعة للمقيمين و المستقرين
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes physiques
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="residentsIndividuals"  />
            </td>
            <td class="text-center">10%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="residentsIndividualsAmount"  />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص طبيعيون
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes morales
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="residentsCorporations"  />
            </td>
            <td class="text-center">10%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="residentsCorporationsAmount"  />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص معنويون
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr text-primary">
              - Servis aux non résidents et non établis
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar text-primary">
              -المدفوعة لغير المقيمين وغير المستقرين
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes physiques
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="nonResidentsIndividuals"  />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="nonResidentsIndividualsAmount"  />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص طبيعيون
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes morales
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="nonResidentsCorporations"  />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="nonResidentsCorporationsAmount"  />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص معنويون
            </td>
          </tr>

          <tr>
            <td class="text-left-fr">
              Honoraires servis aux personnes physiques non soumises au régime
              réel
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="honorairesNonRegimeReel" />
            </td>
            <td class="text-center">10%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="honorairesNonRegimeReelAmount" />
            </td>
            <td class="text-right-ar">
              الأتعاب المدفوعة للأشخاص الطبيعيين غير الخاضعين للنظام الحقیقی
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              Honoraires servis aux personnes morales et aux personnes physiques
              soumises au régime réel
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="honorairesRegimeReel" />
            </td>
            <td class="text-center">3%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="honorairesRegimeReelAmount" />
            </td>
            <td class="text-right-ar">
              الأتعاب المدفوعة للأشخاص المعنويين وللأشخاص الطبيعيين الخاضعين
              للنظام الحقيقي
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              Rémunérations des artistes et des créateurs
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsArtistesCreateurs" />
            </td>
            <td class="text-center">5%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsArtistesCreateursAmount" />
            </td>
            <td class="text-right-ar">
              المكافآت المدفوعة إلى الفنانين والمبدعين
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              Loyers des hotels servis aux personnes morales et aux personnes
              physiques soumises au régime réel
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="loyersHotelsRegimeReel" />
            </td>
            <td class="text-center">5%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="loyersHotelsRegimeReelAmount" />
            </td>
            <td class="text-right-ar">
              أكرية النزل المدفوعة إلى الأشخاص المعنويين والأشخاص الطبيعيين
              الخاضعين للنظام الحقيقي
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              Rémunérations payées en contrepartie de la performance dans la
              prestation des services pour le compte d'autrui
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsPerformanceServices" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsPerformanceServicesAmount" />
            </td>
            <td class="text-right-ar">
              المكافآت المدفوعة مقابل لنجاعة في اسداء الخدمات لفائدة الغير
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">
              Intérêts des comptes spéciaux d'épargne ouverts auprès des banques
              et de la CENT
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="interetsComptesEpargne" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="interetsComptesEpargneAmount" />
            </td>
            <td class="text-right-ar">
              فوائد الإيداعات بالحسابات الخاصّة للإذخار لدى المؤسسات البنكية
              وصندوق الإدخار الوطني التونسي
            </td>
          </tr>
        
          <tr>
            <td class="text-left-fr">Revenus des autres capitaux mobiliers</td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar">مداخيل رؤوس الأموال المنقولة الأخرى</td>
          </tr>
        
          <tr>
            <td class="text-left-fr text-primary">- Revenant aux résidents</td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar text-primary">-الرّاجعة للمقيمين</td>
          </tr>
        
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes physiques
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="revenusResidentsPersonnesPhysiques" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="revenusResidentsPersonnesPhysiquesAmount" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص طبيعيون
            </td>
          </tr>

          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes morales
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="personnesMoralesAmount" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="personnesMoralesPercentage" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص معنويون
            </td>
          </tr>
          
          <tr></tr>
          
          <tr>
            <td class="text-left-fr text-primary">
              - Revenant aux non résidents
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar text-primary">- الرّاجعة لغير المقيمين</td>
          </tr>
          
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes physiques
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="personnesPhysiquesAmount" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="personnesPhysiquesPercentage" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص طبيعيون
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes morales
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="personnesMoralesNonResidentesAmount" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="personnesMoralesNonResidentesPercentage" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص معنويون
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr">
              Parts des actions
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar">
              حصص الأسهم
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr text-primary">
              servies aux personnes physiques résidentes
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="partsActionsResidentesAmount" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="partsActionsResidentesPercentage" />
            </td>
            <td class="text-right-ar text-primary">
              الراجعة إلى الأشخاص الطبيعيين المقيمين
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr text-primary">
              - servies aux personnes physiques et morales non résidentes
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="partsActionsNonResidentesAmount" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="partsActionsNonResidentesPercentage" />
            </td>
            <td class="text-right-ar text-primary">
              الراجعة إلى الأشخاص الطبيعيين والمعنويين غير المقيمين
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr">
              Rémunérations et primes attribuées aux membres des conseils, des
              directoires et des comites des sociétés
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar">
              المكافآت والمنح المسندة لأعضاء المجالس والهيئات واللجان
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr text-primary">- Revenant aux résidents</td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar text-primary">-الرّاجعة للمقيمين</td>
          </tr>
          
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes physiques
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsResidentsPersonnesPhysiquesAmount" />
            </td>
            <td class="text-center">20%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsResidentsPersonnesPhysiquesPercentage" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص طبيعيون
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes morales
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsResidentsPersonnesMoralesAmount" />
            </td>
            <td class="text-center">20%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsResidentsPersonnesMoralesPercentage" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص معنويون
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr">- Revenant aux non résidents</td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar">-الرّاجعة للمقيمين</td>
          </tr>
          
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes physiques
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsNonResidentsPersonnesPhysiquesAmount" />
            </td>
            <td class="text-center">20%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsNonResidentsPersonnesPhysiquesPercentage" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص طبيعيون
            </td>
          </tr>
          
          <tr>
            <td class="text-left-fr" style="text-indent: 50px">
              Personnes morales
            </td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsNonResidentsPersonnesMoralesAmount" />
            </td>
            <td class="text-center">20%</td>
            <td class="text-center">
              <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerationsNonResidentsPersonnesMoralesPercentage" />
            </td>
            <td class="text-right-ar" style="text-indent: 50px">
              أشخاص معنويون
            </td>
          </tr>

    <!-- First row -->
    <tr>
      <td class="text-left-fr">
        Rémunérations payées aux salariés et aux non salariés en contre partie d'un travail occasionnel ou accidentel en dehors de leur activité principale
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerations_input1" />
      </td>
      <td class="text-center">15%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerations_input2" />
      </td>
      <td class="text-right-ar">
        التأجيرات المدفوعة للأجراء وغير الأجراء مقابل عمل وقتي أو ظرفي خارج نشاطهم الأصلي
      </td>
    </tr>

    <!-- Second row -->
    <tr>
      <td class="text-left-fr">
        Intérêts des prêts payés aux établissements bancaires non établis en Tunisie
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="interets_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="interets_input2" />
      </td>
      <td class="text-right-ar">
        فوائد القروض المدفوعة للمؤسسات البنكيّة غير المستقرّة بالبلاد التونسية
      </td>
    </tr>

    <!-- Third row -->
    <tr>
      <td class="text-left-fr">
        Prix de cession des immeubles , des droits sociaux y afférents declare dans l'acte
      </td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-right-ar">
        سعر التفويت في العقارات وفي الحقوق المتعلّقة بها المصرح به بالعقد
      </td>
    </tr>

    <!-- Fourth row -->
    <tr>
      <td class="text-left-fr text-primary">
        - Pour les résidents et établis
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="residents_etablis_input1" />
      </td>
      <td class="text-center">2.5%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="residents_etablis_input2" />
      </td>
      <td class="text-right-ar text-primary">
        بالنسبة إلى المقيمين والمستقرّين
      </td>
    </tr>

    <!-- Fifth row -->
    <tr>
      <td class="text-left-fr text-primary">
        - Pour les non residents et non établis
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="non_residents_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="non_residents_input2" />
      </td>
      <td class="text-right-ar text-primary">
        بالنسبة إلى غير المقيمين وغير المستقرين
      </td>
    </tr>

    <!-- Sixth row -->
    <tr>
      <td class="text-left-fr" style="text-indent: 50px">
        Personnes Physiques
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_physiques_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_physiques_input2" />
      </td>
      <td class="text-right-ar" style="text-indent: 50px">
        أشخاص طبيعيون
      </td>
    </tr>

    <!-- Seventh row -->
    <tr>
      <td class="text-left-fr" style="text-indent: 50px">
        Personnes Morales non établies pour l'immeubles ou des droits y relatifs ou des droits sociaux dans les sociétés civiles immobilières
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_morales_non_etablies_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_morales_non_etablies_input2" />
      </td>
      <td class="text-right-ar" style="text-indent: 50px">
        أشخاص معنويون غير مستقرون بالنسبة للعقارات والحقوق المتعلقة بها والحقوق االجتماعية في الشركات المدنية العقارية
      </td>
    </tr>

    <!-- Eighth row -->
    <tr>
      <td class="text-left-fr">
        Les montants payés au titre des acquisitions de marchandises, materiels, équipements et de services supérieurs ou égaux à 1000 dinars y compris la TVA
      </td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-right-ar">
        المبالغ المدفوعة بعنوان الاقتناءات من سلع ومعدّات وتجهيزات وخدمات التي تساوي أو تفوق 1000 دينار بما في ذلك الأداء على القيمة المضافة
      </td>
    </tr>

    <!-- Ninth row -->
    <tr>
      <td class="text-left-fr text-primary">
        - Auprès des sociétés soumises à un IS de 15%
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="societes_is_15_input1" />
      </td>
      <td class="text-center">1%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="societes_is_15_input2" />
      </td>
      <td class="text-right-ar text-primary">
        لدى المؤسسات الخاضعة للضريبة على الشّركات بنسبة 15%
      </td>
    </tr>

    <!-- Tenth row -->
    <tr>
      <td class="text-left-fr text-primary">
        - Auprès des sociétés soumises à un IS de 10%
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="societes_is_10_input1" />
      </td>
      <td class="text-center">0.5%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="societes_is_10_input2" />
      </td>
      <td class="text-right-ar text-primary">
        لدى المؤسسات الخاضعة للضريبة على الشّركات بنسبة 10%
      </td>
    </tr>

    <!-- Eleventh row -->
    <tr>
      <td class="text-left-fr text-primary">
        - Auprès des PP dont les revenus bénéficient d'une deduction de deux tiers
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="pp_deduction_deux_tiers_input1" />
      </td>
      <td class="text-center">0.5%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="pp_deduction_deux_tiers_input2" />
      </td>
      <td class="text-right-ar text-primary">
        لدى المؤسّسات الفرديّة المنتفعة بطرح ثلثي المداخيل
      </td>
    </tr>

    <!-- Twelfth row -->
    <tr>
      <td class="text-left-fr">Auprès d'autres entrprises</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="autres_entreprises_input1" />
      </td>
      <td class="text-center">1.5%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="autres_entreprises_input2" />
      </td>
      <td class="text-right-ar">لدى المؤسّسات الأخرى</td>
    </tr>

    <!-- Thirteenth row -->
    <tr>
      <td class="text-left-fr">
        T.V.A. retenue à la source au titre des montants égales ou supérieurs à 1000 dinars y compris la TVA payés par l'Etat, les collectivites locales, les établissements et entreprises publics
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="tva_retenue_source_input1" />
      </td>
      <td class="text-center">25%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="tva_retenue_source_input2" />
      </td>
      <td class="text-right-ar">
        خصم الأداء على القيمة ? المضافة من المورد بعنوان المبالغ المدفوعة من قبل المؤسّسات و المنشآت العمومية التي تساوي أو تفوق 1000 دينار بما في ذلك الأداء على القيمة المضافة
      </td>
    </tr>

    <!-- Fourteenth row -->
    <tr>
      <td class="text-left-fr">
        TVA retenue à la source au titre des opérations soumises à la TVA effectuées avec les non résidents
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="tva_retenue_source_non_residents_input1" />
      </td>
      <td class="text-center">100%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="tva_retenue_source_non_residents_input2" />
      </td>
      <td class="text-right-ar">
        خصم الأداء على القيمة المضافة من المورد بعنوان العمليّات المنجزة مع الأشخاص الّذين ليست لهم مؤسّسة بالبلاد لتونسية
      </td>
    </tr>

    <!-- Fifteenth row -->
    <tr>
      <td class="text-left-fr">
        Rémunérations payées aux non résidents et non établis en Tunisie au titre des travaux de construction ou des opérations de montage ou des activités de contrôle y relative ou d'autres travaux dont la période n'excède pas 6 mois
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerations_non_residents_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="remunerations_non_residents_input2" />
      </td>
      <td class="text-right-ar">
        المكافآت المدفوعة إلى غير ? المقيمين وغير المستقرين الذين ينجزون أشغال بناء أو عمليات تركيب أو خدمات أخرى لا تتجاوز مدتها ستة أشهر
      </td>
    </tr>

    <!-- Sixteenth row -->
    <tr>
      <td class="text-left-fr">
        Montants servis aux non- résidents établis en Tunisie et qui ne procedent pas au depot de la déclaration d'existence avant d'entamer leur activité
      </td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-right-ar">
        المبالغ المدفوعة إلى غير المقيمين الذين ينشطون في إطار منشأة دائمة و الذين لا يودعون التصريح في الوجود قبل بدأ النشاط
      </td>
    </tr>

    <tr>
      <td class="text-left-fr text-primary">
        Personnes établies en Tunisie et résidentes dans un Etat ou un
        territoire dont le régime fiscal est privilégié
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_etablies_tunisie_input1" />
      </td>
      <td class="text-center">25%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_etablies_tunisie_input2" />
      </td>
      <td class="text-right-ar text-primary">
        منشآت دائمة تابعة لمؤسسات مقيمة ببلد أو إقليم ذي نظام جبائي تفاضلي
      </td>
    </tr>

    <tr>
      <td class="text-left-fr text-primary">
        Autres établissements stables
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="autres_etablissements_stables_input1" />
      </td>
      <td class="text-center">15%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="autres_etablissements_stables_input2" />
      </td>
      <td class="text-right-ar text-primary">منشآت دائمة أخرى</td>
    </tr>

    <tr>
      <td class="text-left-fr">
        Avance sur les ventes des entreprises industrielles et de commerce
        de gros aux profit des personnes physiques soumises à I'IR selon
        le régime forfaitaire dans la catégorie des BIC ou sur la base
        d'une assiette forfaitaire dans la catégorie des BNC
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="avance_ventes_entreprises_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="avance_ventes_entreprises_input2" />
      </td>
      <td class="text-right-ar">
        التسبقة الموظفة على بيوعات المؤسسات الصناعية ومؤسسات تجارة الجملة
        إلى الأشخاص الطبعيين الخاضعين للضريبة على الدخل حسب النظام
        التقديري في صنف الأرباح الصناعية و التجارية أو على أساس قاعدة
        تقديرية في صنف أرباح المهن غير التجارية
      </td>
    </tr>

    <tr>
      <td class="text-left-fr">
        Avance sur les ventes des produits alcoolisés
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="avance_ventes_alcoolises_input1" />
      </td>
      <td class="text-center">5%</td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="avance_ventes_alcoolises_input2" />
      </td>
      <td class="text-right-ar">
        التسبقة الموظفة على بيوعات المشروبات الكحوليّة القيمة الزائدة
        المتأتية من ج
      </td>
    </tr>

    <tr>
      <td class="text-left-fr">
        La plus value de cession des actions des parts sociales ou des
        parts de fonds prévues par la législation les régissant par les
        personnes non résidentes non établies
      </td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-right-ar">
        القيمة الزائدة المتأتية من ج تفويت الأشخاص غير المقيمين و غير
        المستقرين في الأسهم أو في المنابات الاجتماعية أو في حصص الصناديق
        المنصوص عليها بالتشريع المتعلق بها
      </td>
    </tr>

    <tr>
      <td class="text-left-fr text-primary" style="text-indent: 50px">
        Personnes physiques
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_physiques_input3" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_physiques_input4" />
      </td>
      <td class="text-right-ar text-primary" style="text-indent: 50px">
        أشخاص طبيعيون
      </td>
    </tr>

    <tr>
      <td class="text-left-fr text-primary" style="text-indent: 50px">
        Personnes morales
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_morales_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_morales_input2" />
      </td>
      <td class="text-right-ar text-primary" style="text-indent: 50px">
        أشخاص معنويون
      </td>
    </tr>

    <tr>
      <td class="text-left-fr">
        Autres revenus servis aux personnes non residentes et non établies
      </td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-center"></td>
      <td class="text-right-ar">
        المكافآت الأخرى المدفوعة ? إلى غير المقيمين غير المستقرّين
      </td>
    </tr>

    <tr>
      <td class="text-left-fr text-primary" style="text-indent: 50px">
        Personnes physiques
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_physiques_autres_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_physiques_autres_input2" />
      </td>
      <td class="text-right-ar text-primary" style="text-indent: 50px">
        أشخاص طبيعيون
      </td>
    </tr>

    <tr>
      <td class="text-left-fr text-primary" style="text-indent: 50px">
        Personnes morales
      </td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_morales_autres_input1" />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input type="text" class="form-control"  appDecimalFormat formControlName ="personnes_morales_autres_input2" />
      </td>
      <td class="text-right-ar text-primary" style="text-indent: 50px">
        أشخاص معنويون
      </td>
    </tr>

    <tr>
      <td class="text-left-fr">Total</td>
      <td class="text-center" colspan="3">{{sumOfDisabledControls | number:'1.3-3'}}</td>
      <td class="text-right-ar">المجموع</td>
    </tr>
        </tbody>
      </table>
      <div class="row wizard-buttons">
        <div class="col d-flex justify-content-center align-items-center">
          <button
            (click)="ngOnDestroy()"
            type="button"
            class="btn btn-outline-primary"
          >
          موافق  Valider
          </button>
          <button
            (click)="reset()"
            type="button"
            class="btn btn-outline-primary"
          >
          إلغاء   Annuler
          </button>
          <button
          (click)="goto(1)"
            type="button"
            class="btn btn-outline-primary"
          >
            السابق Precedent
          </button>
        </div>
      </div>
    </div>
</body>
