
  <body class="fs-5">
    <div class="container mt-5">
      <h1 class="text-center mt-5 text-danger fs-2">معلوم الطابع الجبائي</h1>
      <h1 class="text-center mt-3 text-danger fs-2">DROIT DE TIMBRE</h1>
<form [formGroup]="dtform">
  <table class="table table-bordered mt-5">
    <thead>
      <tr>
        <th scope="col" class="text-center text-warning">
          Droit du sur les factures des lignes et les montants payés au
          titre des services de téléphonie et des services d'internet
        </th>
        <th scope="col" class="text-center">المعلوم المستوجب</th>
        <th scope="col" class="text-center text-warning">
          المعلوم المستوجب على المبالغ المفوترة وعلى المبالغ المستخلصة
          بعنوان خدمات الهاتف وخدمات الأنترنات
        </th>
      </tr>
      <tr>
        <th scope="col" class="text-center"></th>
        <th scope="col" class="text-center">Droit dû (I)</th>
        <th scope="col" class="text-center"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v1" class="form-control" />
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <table class="table table-bordered mt-5">
    <thead>
      <tr>
        <th scope="col" class="text-center text-warning">
          Droit de timbre exigible sur les tickets de vente délivrés par les
          magasins commerciaux et les entreprises franchisées d'une marque
          commerciale étrangère
        </th>
        <th scope="col" class="text-center"></th>
        <th scope="col" class="text-center text-warning">
          المعلوم المستوجب على تذاكر البيع المسلمة من قبل المغازات التجارية
          والمستغلين لعلامة تجارية أجنبية
        </th>
      </tr>
      <tr>
        <th scope="col" class="text-center">المعلوم المستوجب</th>
        <th scope="col" class="text-center">عدد التذاكر</th>
        <th scope="col" class="text-center">عدد الفروع</th>
      </tr>
      <tr>
        <th scope="col" class="text-center">Droit dû (II)</th>
        <th scope="col" class="text-center">Nombre de tickets</th>
        <th scope="col" class="text-center">Nombre d'établissements</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v2" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v3" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v4" class="form-control" />
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table table-bordered mt-5">
    <thead>
      <tr>
        <th scope="col" class="text-center text-warning">
          Droit de timbre exigible sur les autres documents
        </th>
        <th scope="col" class="text-center"></th>
        <th scope="col" class="text-center"></th>
        <th scope="col" class="text-center text-warning">
          المعلوم المستوجب على الوثائق الأخرى
        </th>
      </tr>
      <tr>
        <th scope="col" class="text-center">المعلوم المستوجب</th>
        <th scope="col" class="text-center">عدد الوثائق</th>
        <th scope="col" class="text-center">عدد الفروع</th>
        <th scope="col" class="text-center">نوع الوثيقة</th>
      </tr>
      <tr>
        <th scope="col" class="text-center">Droit dû (II)</th>
        <th scope="col" class="text-center">Nombre de documents</th>
        <th scope="col" class="text-center">Nombre d'établissements</th>
        <th scope="col" class="text-center">Type document</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v5" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v6" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v7" class="form-control" />
        </td>
        <td class="text-right-ar">
          <div class="d-flex justify-content-between align-items-center">
            <span> شهادة فحص فني لوسيلة نقل </span>
            <span class="text-left-fr">
              Certificats de visite technique de moyen de transport
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v8" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v9" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v10" class="form-control" />
        </td>
        <td class="text-right-ar" colspan="2">
          <div class="d-flex justify-content-between align-items-center">
            <span> تذكرة نقل دولي للأشخاص</span>
            <span class="text-left-fr">
              Billets de transport international aérien et maritime de
              personnes</span
            >
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v11" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v12" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v13" class="form-control" />
        </td>
        <td class="text-right-ar" colspan="2">
          <div class="d-flex justify-content-between align-items-center">
            <span
              >فاتورة أو كمبيالة بما في ذلك القابلة للقراءة الآلية
            </span>
            <span class="text-left-fr"
              >Factures ou lettres de change y compris celles se prêtant à
              la lecture électronique
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v14" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v15" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" appDecimalFormat  formControlName="v16" class="form-control" />
        </td>
        <td class="text-right-ar" colspan="2">
          <div class="d-flex justify-content-between align-items-center">
            <span>وثائق أخرى </span>
            <span class="text-left-fr"> Autres documents</span>
          </div>
        </td>
      </tr>
      <tr scope="row">
        <td class="text-center">
          <input type="text" formControlName="v17" class="form-control" />
        </td>
        <td class="text-center">
          <input type="text" formControlName="v18" class="form-control" />
        </td>
        <td class="text-right-ar text-primary" colspan="4">
          <div class="d-flex justify-content-between align-items-center">
            <span>المجموع </span>
            <span class="text-left-fr"> TOTAL</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table table-bordered mt-5">
    <thead>
      <tr>
        <th scope="col" class="text-center">Total de droit de timbre dû</th>
        <th scope="col" class="text-center"></th>
        <th scope="col" class="text-center">المعلوم الجملي المستوجب</th>
      </tr>
      <tr>
        <th scope="col" class="text-center"></th>
        <th scope="col" class="text-center">
          <input type="text" formControlName="v19" class="form-control" />
        </th>
        <th scope="col" class="text-center"></th>
      </tr>
    </thead>
  </table>
</form>
 
      <div class="row wizard-buttons">
        <div class="col d-flex justify-content-center align-items-center">
          <button
          (click)="ngOnDestroy()"
          type="button"
          class="btn btn-outline-primary"
        >
        موافق  Valider
        </button>
        <button
        (click)="reset()"
        type="button"
        class="btn btn-outline-primary"
      >
      إلغاء   Annuler
      </button>
      <button
      (click)="goto(1)"
        type="button"
        class="btn btn-outline-primary"
      >
        السابق Precedent
      </button>
        </div>
      </div>
    </div>
  </body>
