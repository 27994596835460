<img loading="lazy" srcset="../../assets/taswira1.jpg" class="img" />
<div class="div">
    <div class="div-2">
        <div class="column">
            <div class="div-3">
                <span style="font-size: 72px; color: rgba(254, 197, 54, 1)">
                    Qui
                </span>
                <span style="font-size: 58px; color: rgba(0, 0, 0, 1)">Sommes Nous</span>
            </div>
        </div>
        <div class="column-2">
            <div class="div-4">
                Notre cabinet a été créé en 1997 par Fayçal BEN AYED, commissaire
                aux comptes inscrit au tableau de la compagnie et titulaire du
                Master C.C.A de l’I.A.E de Lyon 3, et ce, après avoir travaillé dans
                un cabinet d’expertise comptable de renommée nationale et dans un
                groupe de sociétés. Afin de répondre au mieux à vos attentes et de
                gérer au plus près vos intérêts, notre cabinet est garnie de
                compétences dynamiques et réactives pour vous accompagner dans
                toutes les étapes de la vie de votre entreprise, quelle que soit sa
                forme. Notre principal objectif est d’apporter un haut niveau de
                qualité à la réalisation de nos missions assuré aussi bien par une
                formation régulière de notre équipe que par une adaptation
                permanente de notre organisation et de nos méthodes et approches de
                travail. En 2015, nous avons créé notre bureau virtuel Macompta afin
                d’être à proximité de tous nos clients. Notre cabinet adhère
                totalement à l’emblème de l’ordre des experts comptables de Tunisie
                : Science, Conscience et Indépendance.
            </div>
        </div>
    </div>
    <div class="divN">Notre Équipe</div>
    <div class="divE">
        <div class="div-2E">
            <div class="columnE">
                <img loading="lazy" src="../../assets/taswira2.jpg" alt="Team Member" class="img2" />
                <div class="div-3E">
                    <div class="div-4E">Fayçal Ben Ayed</div>
                    <div class="div-5E">Propriétaire</div>
                    <div class="div-6E">
                        Commissaire aux comptes inscrit au tableau de la compagnie et titulaire du
                        Master C.C.A de l’I.A.E de Lyon 3, et ce, après avoir travaillé dans un
                        cabinet d’expertise comptable de renommée nationale et dans un groupe de
                        sociétés
                    </div>
                </div>
            </div>
            <div class="columnE">
                <img loading="lazy" src="../../assets/taswira2.jpg" alt="Team Member" class="img2" />
                <div class="div-3E">
                    <div class="div-4E">Collaborateur 1</div>
                    <div class="div-5E">collaborateur</div>
                    <div class="div-6E">
                    </div>
                </div>
            </div>
            <div class="columnE">
                <img loading="lazy" src="../../assets/taswira2.jpg" alt="Team Member" class="img2" />
                <div class="div-3E">
                    <div class="div-4E">Collaborateur 2</div>
                    <div class="div-5E">collaborateur</div>
                    <div class="div-6E">
                   
                    </div>
                </div>
            </div>
            
            <!-- Add more columns for other team members -->
        </div>
    </div>
</div>