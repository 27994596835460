
  <body>
    <div class="container mt-5">
      <h1 class="text-center mt-5 text-danger fs-2">
        الأداء على القيمة المضافة
      </h1>
      <h1 class="text-center mt-3 text-danger fs-2">
        TAXE SUR LA VALEUR AJOUTEE
      </h1>
      <table class="table table-bordered mt-5" [formGroup]="tva1form">
        <thead>
          <tr>
            <th scope="col" class="text-center">الطرح</th>
            <th scope="col" class="text-center">أ ق م المستوجب</th>
            <th scope="col" class="text-center">النسبة</th>
            <th scope="col" class="text-center">المبلغ</th>
            <th scope="col" colspan="2"></th>
          </tr>
          <tr>
            <th scope="col" class="text-center">TVA déductible</th>
            <th scope="col" class="text-center">TVA due</th>
            <th scope="col" class="text-center">Taux</th>
            <th scope="col" class="text-center">Montant</th>
            <th scope="col" colspan="2"></th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaDue7" />
            </td>
            <td class="text-center">7%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montant7" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="text-primary d-flex justify-content-between align-items-center">
                <span>رقم المعاملات الخاضع للأداء على القيمة المضافة بدون إعتبار الأداء على القيمة المضافة</span>
                <span class="text-left-fr">1- C. A. soumis à la TVA hors TVA</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaDue13" />
            </td>
            <td class="text-center">13%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montant13" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center"></div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaDue19" />
            </td>
            <td class="text-center">19%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montant19" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center"></div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaDue6" />
            </td>
            <td class="text-center">6%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montant6" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center"></div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaDue12" />
            </td>
            <td class="text-center">12%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montant12" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center"></div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaDue18" />
            </td>
            <td class="text-center">18%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montant18" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center"></div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaDueOther" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantOther" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>عمليات التسليم للنفس</span>
                <span class="text-left-fr">- Livraison à soi-même</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar" colspan="2">
              <div class="text-primary d-flex justify-content-between align-items-center">
                <span>الشراءات الخاضعة للأداء على القيمة المضافة بدون إعتبار أ.ق.م. والتي تخوّل حقّ الطرح</span>
                <span class="text-left-fr">2- Achats soumis à la TVA hors TVA et ouvrant droit à déduction</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="achats19" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">19%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantAchats19" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>شراءات عقاريّة</span>
                <span class="text-left-fr">2-1 Achats d'immeubles</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>شراءات معدّات</span>
                <span class="text-left-fr">2-2 Achats d'équipements</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="achatsEquipementsLocal" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantAchatsEquipementsLocal" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>محليّة</span>
                <span class="text-left-fr">- Locaux</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="achatsEquipementsImportes" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantAchatsEquipementsImportes" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>موزدة</span>
                <span class="text-left-fr">- Importés</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>شراءات أخرى</span>
                <span class="text-left-fr">2-3 Autres Achats</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="autresAchatsLocal" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantAutresAchatsLocal" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>محليّة</span>
                <span class="text-left-fr">- Locaux</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="autresAchatsImportes" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantAutresAchatsImportes" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div
                class="text-primary d-flex justify-content-between align-items-center"
              >
                <span>طروحات أخرى</span>
                <span class="text-left-fr">3- Autres déductions</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="autresDeductions1000Dinars" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">25%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantAutresDeductions1000Dinars" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  أ.ق.م المستوجب على المبالغ الّتي تساوي أو تفوق 1000 دينار بما في ذلك
                  أ.ق.م و مبلغ الخصم من المورد
                </span>
                <span class="text-left-fr">
                  TVA due au titre des montants égales ou supérieurs à 1000 dinars y
                  compris la TVA
                </span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="tvaNonResidents" />
            </td>
            <td class="text-center"></td>
            <td class="text-center">100%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="montantTvaNonResidents" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  أ. ق.م المستوجب على العمليات المنجزة مع الأشخاص الذين ليست لهم مؤسسات
                  بالبلاد التونسية
                </span>
                <span class="text-left-fr">
                  - TVA retenue à la source au titre des opérations réalisées par les non
                  résidents
                </span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v1" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v1montant" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>أ .ق.م تقديري على وسائل النقل</span>
                <span class="text-left-fr">- TVA forfaitaire sur les moyens de transport</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v2" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v3" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>عمليات التسليم للنفس</span>
                <span class="text-left-fr">- Livraison à soi-même</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar" colspan="2">
              <div class="text-primary d-flex justify-content-between align-items-center">
                <span>التعديلات</span>
                <span class="text-left-fr">4- Régularisations</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>طرح إضافي</span>
                <span class="text-left-fr">4-1 Déduction additionnelle</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v4" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v5" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>بعنوان عمليات الفسخ و الإلغاء</span>
                <span class="text-left-fr">4-1-1 Au titre d'opérations de résiliation et d'annulation</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v6" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v7" />
            </td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>بعنوان عمليات أخرى</span>
                <span class="text-left-fr">4-1-2 Au titre d'autres operations</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center"></td>
            <td class="text-center">
              <input type="text" appDecimalFormat formControlName="v8" />
            </td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-right-ar" colspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>عادة دفع</span>
                <span class="text-left-fr">4-2 Reversement</span>
              </div>
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center">
              {{ sumOfNegativeControls | number:'1.3-3' }}
            </td>
            <td class="text-center">
              {{ sumOfPositiveControls | number:'1.3-3' }}
            </td>
            <td class="text-right-ar text-primary" colspan="4">
              <div class="d-flex justify-content-between align-items-center">
                <span>المجموع</span>
                <span class="text-left-fr">TOTAL</span>
              </div>
            </td>
          </tr>
                    
        </tbody>
      </table>
      

      <div class="row wizard-buttons">
        <div class="col d-flex justify-content-center align-items-center">
          <button
          (click)="next()"
            type="button"
            class="btn btn-outline-primary mx-2"
          >
            الموالي Suivant
          </button>
          <button
          (click)="goto(1)"
            type="button"
            class="btn btn-outline-primary"
          >
            السابق Precedent
          </button>
        </div>
      </div>
    </div>
  </body>
