<!DOCTYPE html>
<html lang="ar" dir="rtl">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>نموذج إدخال البيانات</title>
    <link
      rel="stylesheet"
      href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    />
  </head>
  <body>
    <!-- modal for adding new cop dc-->
<div class="modal-signup"tabindex="-1"role="dialog"[ngStyle]="{'display':displaycop}">
  <div class="modal-content">
    <div class="right-section-signup">
<div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;">
<h4>Formulaire de complétude des données des copropriétaires</h4>
<div class="form-group">
  <label class="col-md-4 control-label">Périodicité</label>
  <div class="col-md-8 inputGroupContainer">
     <div class="input-group"><span class="input-group-addon"><i class="glyphicon glyphicon-time"></i></span>
      <!-- @ts-ignore -->
      <select class="btn btn-select resizeselect"placeholder="periodicite" id="perio"#someHashName20 [(ngModel)]="option0Value"(click)="sort(settedperiodicites)"(change)="patch()">
        <option hidden [value]=""></option>
        <option value=""></option>
  <option *ngFor="let per of settedperiodicites" [value]="per">
    {{per}}
</option>
        <option hidden value="Autre">Autre</option>
      </select>
      </div>
  </div>
</div>
<table class="table table-striped"[formGroup]="ajoutdccopform">
  <thead>
  <tr>
    <th>N°</th>
    <th>copropriétaires</th>
    <th>annee</th>            
    <th>périodicité</th>            
    <th>montant par échéance</th>              
  </tr>
</thead>

<tbody formArrayName="ammounts10">

  <tr *ngFor="let ammount of ammountControls10; let u = index;"[formGroupName]="u">
    <td>
      <div >
           <input type="text"  
         class="form-control-special"formControlName="numero"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
      </div>
    </td>
    <td>
      <div >
           <input type="text"  
         class="form-control-special"formControlName="coproprietaire"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
      </div>
    </td>
    <td style="width: 100px">
      <div >
           <input type="text"  
         class="form-control-special"formControlName="annee"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
      </div>
    </td>

          <td style="width: 150px;display: block;">
            <div >
              <select class="btn btn-select resizeselect"style="pointer-events: none;"onclick="return false;" onkeydown="return false;"placeholder="periodicite" id="perio"#someHashName20 formControlName="periodicite" name="periodicite" (click)="sort()"readonly='true'>
                <option hidden [value]=""></option>
                <option value=""></option>
          <option *ngFor="let per of settedperiodicites" [value]="per">
            {{per}}
        </option>
                <option hidden value="Autre">Autre</option>
              </select>
                </div>
                <div *ngIf="someHashName20?.value == 'Autre'">
                  <input class="form-control-special" type="text"(change)="onChangecop(u)" placeholder="perio" id="period" formControlName="autreperiodicite">
                </div>
          </td> 
          <td>
            <div >
                 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
               class="form-control-special"formControlName="montantecheance"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
            </div>
          </td>
     
          
  </tr>
</tbody>
</table>

<div class="modal-footer">
<button type="button" class="btn btn-danger" (click)="closecopPopup()">Annuler</button>
<button type="button" class="btn btn-success" (click)="ajouterdccop()">Valider</button>

</div>
</div>
    </div>
          <!-- Close Button (Top-left corner) -->
          <div class="close-button" (click)="closecopPopup()" style="background-color: #F8F8F9;">
            <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
          </div>
  </div>
</div>
    <div class="container">
      <h1 class="text-center mt-5 text-danger fs-2">
        التصريح الشهري بالأداعات
      </h1>
      <h1 class="text-center mt-3 text-danger fs-2">
        DECLARATION MENSUELLE D'IMPOTS
      </h1>
      <form class="row d-flex justify-content-center"[formGroup]="gardeform">
        <table class="table table-bordered">
          <tr>
            <td scope="col">السنة</td>
            <td rowspan="2">
              <label for="annee" class="label-year">Sélectionner une année :</label>
              <mat-form-field appearance="fill">
                <mat-select id="year" name="year" (selectionChange)="verifyfutur($event)"formControlName="annee">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let an of filtredannees" [value]="an">{{ an }}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td scope="col">الشهر</td>
            <td rowspan="2">
              <label for="mois" class="label-month">Sélectionner un mois :</label>
              <mat-form-field appearance="fill">
                <mat-select id="month" name="month" #cricket (selectionChange)="verifyfutur($event)"formControlName="mois">
                  <mat-option></mat-option>
                  <mat-option value="01">(01) Janvier</mat-option>
                  <mat-option value="02">(02) Février</mat-option>
                  <mat-option value="03">(03) Mars</mat-option>
                  <mat-option value="04">(04) Avril</mat-option>
                  <mat-option value="05">(05) Mai</mat-option>
                  <mat-option value="06">(06) Juin</mat-option>
                  <mat-option value="07">(07) Juillet</mat-option>
                  <mat-option value="08">(08) Août</mat-option>
                  <mat-option value="09">(09) Septembre</mat-option>
                  <mat-option value="10">(10) Octobre</mat-option>
                  <mat-option value="11">(11) Novembre</mat-option>
                  <mat-option value="12">(12) Décembre</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td scope="col">Année</td>
            <td scope="col">Mois</td>
          </tr>
      </table>
        <table class="table table-bordered mt-5" *ngIf="user">
          <thead>
            <tr>
              <th scope="col">رمز التصريح</th>
              <th scope="col">المعرف الجبائي</th>
              <th scope="col">معرف أ.ق.م</th>
              <th scope="col">رمز الصنف</th>
              <th scope="col">الموسسة الثانوية</th>
            </tr>
            <tr>
              <th scope="col">Acte</th>
              <th scope="col">Matricule fiscal</th>
              <th scope="col">Code T.V.A</th>
              <th scope="col">Code categorie</th>
              <th scope="col">No etablissement secondaire</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select name="s" id="ss"formControlName="dectype">
                  <option value="initiale">Initiale</option>
                  <option value="rectificative">Rectificative</option>
                </select>
              </td>
              <td>{{user.matriculefiscale.split(' ')[0]}}{{user.matriculefiscale.split(' ')[1].substring(0,1)}}</td>
              <td>{{user.matriculefiscale.split(' ')[1].split('/')[1]}}</td>
              <td>{{user.matriculefiscale.split(' ')[1].split('/')[2]}}</td>
              <td>{{user.matriculefiscale.split(' ')[1].split('/')[3]}}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-bordered mt-5" *ngIf="user">
          <thead>
            <tr>
              <th scope="col">الإسم و اللقب أو الإسم الإجتماعي</th>
              <th scope="col">عنوان المقرّ الإجتماعي</th>
              <th scope="col">النشاط الأصلي</th>
            </tr>
            <tr>
              <th scope="col">Nom et prenom ou raison sociale</th>
              <th scope="col">Adresse</th>
              <th scope="col">Activite principale</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
            <span *ngIf="user.nature === 'Personne Physique'">{{ user.firstname }} {{ user.lastname }}</span>
            <span *ngIf="user.nature === 'Personne Morale'">{{ user.raisonsociale }}</span>
              </td>
              <td>{{ user.adresseactivite }}</td>
              <td><span *ngIf="user.activite!='Médecin'&&user.natureactivite!='société'">{{ user.activite }}</span><span *ngIf="user.activite=='Médecin'">{{ user.sousactivite }}</span><span *ngIf="user.natureactivite=='société'">{{ user.sousactivite }}</span></td>
            </tr>
          </tbody>
        </table>

        <tr class="mt-3">
          <th class="text-primary">ضع علامة في القسيمة المناسبة</th>
          <th class="text-primary">Cocher la case correspondante</th>
        </tr>

        <table class="table table-bordered mt-5">
          <tr id="row1">
            <th>لخصم من المورد</th>
            <th>لأداء على التكوين المهني</th>
            <th>لالمساهمة في صندوق النهوض بالمسكن لفائدة الأجراء</th>
            <th>المعلوم على الاستهلاك</th>
            <th>لأداء على القيمة المضافة</th>
            <th>معاليم أخرى على رقم المعاملات</th>
            <th>المعاليم على التأمين</th>
            <th>معلوم الطابع الجبائي</th>
            <th>المعلوم على المؤسسات</th>
            <th>لمعلوم على النزل</th>
            <th>معلوم الإجازة</th>
          </tr>
          <tr id="row1">
            <th>RS</th>
            <th>TFP</th>
            <th>FOPROLOS</th>
            <th>DC</th>
            <th>TVA</th>
            <th>Autres Taxes sur CA</th>
            <th>Taxes sur les Assurances</th>
            <th>DT</th>
            <th>TCL</th>
            <th>TH</th>
            <th>DL</th>
          </tr>
          <tr id="row1">
            <td><input type="checkbox" formControlName="RS" /></td>
            <td><input type="checkbox" formControlName="TFP" /></td>
            <td><input type="checkbox" formControlName="FOPROLOS" /></td>
            <td><input type="checkbox" formControlName="DC" /></td>
            <td><input type="checkbox" formControlName="TVA" /></td>
            <td><input type="checkbox" formControlName="autresTaxesSurCA" /></td>
            <td><input type="checkbox" formControlName="taxesSurLesAssurances" /></td>
            <td><input type="checkbox" formControlName="DT" /></td>
            <td><input type="checkbox" formControlName="TCL" /></td>
            <td><input type="checkbox" formControlName="TH" /></td>
            <td><input type="checkbox" formControlName="DL" /></td>
          </tr>
        </table>
        <div class="col d-flex justify-content-center align-items-center">
          <button
            (click)="previous()"
            type="button"
            class="btn btn-outline-primary mx-2"
          >
            السابق Precedent
          </button>
          <button
            (click)="next()"
            type="button"
            class="btn btn-outline-primary mx-2"
          >
            الموالي Suivant
          </button>
        </div>
      </form>
    </div>
  </body>
</html>
