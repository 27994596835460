export class Proformatinvoicemodel {
    totalds: {
        userId:string,
        demandessoumis:[],
demandesnonsoumis:[],
totalht:string,
tva:string,
soustotal1:string,
soustotal2:string,
timbrefiscal:string,
totalgeneral:string
    };
    ref:string; 
    prefixe:string; 
    archivated:string
    _id:string; 
    created:Date;
        }