<head>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto|Varela+Round">

<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
</head>
<body>
    <div class="team-section">
        <div class="container-fluid">
        <div class="loader" *ngIf="tokenStatus == TokenStatus.Validating"></div>
        <div class="row">
            <div class="col-lg-12" >
              <div class="team-member" *ngIf="tokenStatus == TokenStatus.Valid">
                <div class="icon-box-success">
                    <i class="material-icons">&#xE876;</i>
                </div>	         
                <h3>{{successmessage}}</h3>
                <hr>
                </div>
            </div>
            
          </div>
        
          <div class="row">
            <div class="col-lg-12" >
              <div class="team-member" *ngIf="tokenStatus == TokenStatus.Invalid">
                <div class="icon-box-failure">
                    <i class="material-icons">&#xE5CD;</i>
                </div>         
                <h3>Vérification échouée, vous pouvez passer par le lien ci-desous</h3>
                <hr>
                </div>
            </div>
            
          </div>
        </div>
        </div>
    
    <div class="reset-password-form">
        
        <form [formGroup]="resetpasswordform" *ngIf="tokenStatus == TokenStatus.Valid"(ngSubmit)="onSubmit()">
            <div class="avatar">
                <img src="../../assets/forgot pass icon.png" alt="Avatar">
            </div>
           
            <h2 class="text-center">Regénérer Mot De Passe</h2>   
            <div class="form-group" style="display: flex;">
                <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" name="password" formControlName="password" placeholder="entrer votre nouveau mot de passe" required="required">
                <span class="input-field">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-eye-slash': !fieldTextType,
                        'fa-eye': fieldTextType
                      }"
                      (click)="toggleFieldTextType()"
                    ></i>
                  </span>  
            </div>
            <div class="form-group" style="display: flex;">
                <input [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control" name="confirmpassword" formControlName="confirmpassword" placeholder="confirmer votre nouveau mot de passe" required="required">
                <span class="input-field">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-eye-slash': !repeatFieldTextType,
                        'fa-eye': repeatFieldTextType
                      }"
                      (click)="togglerepeatFieldTextType()"
                    ></i>
                  </span>
            </div>
                    
            <div class="form-group">
                <button type="submit"  class="btn btn-primary btn-lg btn-block">Changer mot de passe</button>
            </div>
            
        </form>
        <div *ngIf="errormsg"><p>{{ errormsg }}</p></div>
        <div *ngIf="successmsg"><p>{{ successmsg }}</p></div>
    </div>
    </body>