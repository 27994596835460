import { Component } from '@angular/core';

@Component({
  selector: 'app-dec-employeur-general',
  templateUrl: './dec-employeur-general.component.html',
  styleUrls: ['./dec-employeur-general.component.scss']
})
export class DecEmployeurGeneralComponent {

}
