import { Component, Input, OnInit } from '@angular/core';
import { FactureService } from '../services/facture.devis';
import { devismodel } from '../models/facture.model';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import * as XLSX from 'xlsx';
import { FactureproformaService } from '../services/factureproforma.service';
import { DevisService } from '../services/devis.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  displayedColumns: string[] = ['ref', 'client', 'amount', 'status', 'created', 'actions'];
  @Input() pagedInvoices: devismodel[] = [];
  @Input() type: string;
  @Input() user: string;
  totalInvoices: number;
  pageSize: number = 10;
  currentPage: number = 0;
  filterValue: string = '';
  statusFilter: string = '';

  constructor(private invoiceService: FactureService,private factproserv: FactureproformaService
    ,private devserv: DevisService,private router: Router) {}

  ngOnInit() {
    this.loadInvoices();
  }

  loadInvoices() {
  /*  if(this.type=='facturepro')
    {
      this.factproserv.getfactureproformauser(this.user)
    }
    else  if(this.type=='facture')
    {
      this.invoiceService.getfactureuser(this.user)
    }
    if(this.type=='devis')
    {
      this.devserv.getdevisuser(this.user)
    }*/

    this.pagedInvoices = this.invoiceService.getFactures(this.currentPage + 1, this.pageSize, this.filterValue, this.statusFilter);
    this.totalInvoices = this.invoiceService.getTotalFactures(this.filterValue, this.statusFilter);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadInvoices();
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.loadInvoices();
  }

  applyStatusFilter(event: any) {
    this.statusFilter = event.value;
    this.loadInvoices();
  }

  getTotalAmount(items: { quantity: number; unitPrice: number }[]): number {
    return items.reduce((total, item) => total + (item.quantity * item.unitPrice), 0);
  }
  viewInvoice(invoice: devismodel) {
    this.router.navigate([]).then((result) => {
      window.open('view-facturation/'+this.type+'/'+this.user+'/'+invoice, '_blank');
    })
  }


  deleteInvoice(invoice: string): void {
    Swal.fire({
      title: 'Etes vous sure?',
      text: 'vous voulez vraiment poursuivre la suppression?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, je veux la supprimer!',
      cancelButtonText: 'Non, annuler la suppression'
    }).then((result) => {
      if (result.isConfirmed) {
        this.performDelete(invoice);
        Swal.fire(
          'Deleted!',
          'Your invoice has been deleted.',
          'success'
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your invoice is safe :)',
          'error'
        );
      }
    });
  }

  performDelete(invoice: string): void {
    // Logic to delete invoice
    if (this.type === 'facturepro') {
      this.factproserv.deletefactureproformadataById(invoice);
    } else if (this.type === 'facture') {
      this.invoiceService.deletefacturedataById(invoice);
    } else if (this.type === 'devis') {
      this.devserv.deletedevisdataById(invoice);
    }
  }
}
