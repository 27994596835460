import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';
import { DecfiscmensService } from '../../services/dec-fisc-mens';

@Component({
  selector: 'app-tfp-comp',
  templateUrl: './tfp-comp.component.html',
  styleUrls: ['./tfp-comp.component.scss']

})
export class TFPCompComponent implements OnInit,OnDestroy {
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  tfpform: FormGroup;
  formValueChangesSubscription: Subscription;
  sumOfPositiveControls: number = 0;
  sumOfNegativeControls: number = 0;
  totalAmount: number = 0;
  statepr:string=null
  annee: any;
  mois: any;
  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService,private DecfiscmensService :DecfiscmensService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.tfpform.reset();
  }
  ngOnInit(): void {
    // Initialize forms
    const savedFormState = this.formStateService.gettfpFormState();
    const savedFormgardeState = this.formStateService.getGardeFormState();

    if (savedFormgardeState) {
        this.annee = savedFormgardeState.get('annee').value;
        this.mois = savedFormgardeState.get('mois').value;
    }

    if (savedFormState) {
        this.tfpform = savedFormState;
        this.updateSumAndTotal();
    } else {
        // Determine the previous month and year
        let previousMonthYear = this.getPreviousMonthYear(this.mois, this.annee);
        if (previousMonthYear) {
            let { previousMois, previousAnnee } = previousMonthYear;
            this.initializeForm(previousMois, previousAnnee);
            this.updateSumAndTotal();
        } else {
            this.initializeFormWithDefaults();
            this.updateSumAndTotal();
        }
    }

    this.setupAutoCalculation('v2', 'v1', 0.01);
    this.setupAutoCalculation('v4', 'v3', 0.02);

    // Subscribe to value changes of all form controls
    this.formValueChangesSubscription = this.tfpform.valueChanges.subscribe(() => {
        this.updateSumAndTotal();
    });
}

private getPreviousMonthYear(currentMois: string, currentAnnee: string): { previousMois: string, previousAnnee: string } | null {
    const moisArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    let moisIndex = moisArray.indexOf(currentMois);
    if (moisIndex > 0) {
        return { previousMois: moisArray[moisIndex - 1], previousAnnee: currentAnnee };
    } else if (moisIndex === 0) {
        return { previousMois: '12', previousAnnee: (parseInt(currentAnnee) - 1).toString() };
    }
    return null;
}

private initializeForm(mois: string, annee: string): void {
    let verifymois = this.DecfiscmensService.decfiscmenss.filter(element=>!element.source).find(e => e.mois === mois && e.annee === annee);
    if (verifymois) {
        let reporttfp = +verifymois.impottype3.tfpreporter;
        let avancetfp = +verifymois.impottype3.montantavance;
        this.tfpform = this.fb.group({
            v1: [{ value: 0, disabled: true }],
            v2: [0, [Validators.min(0)]],
            v3: [{ value: 0, disabled: true }],
            v4: [0, [Validators.min(0)]],
            v5: [reporttfp, [Validators.min(0)]],
            v6: [0, [Validators.min(0)]],
            v7: [0, [Validators.min(0)]],
            v8: [0, [Validators.min(0)]],
            v9: [0, [Validators.min(0)]],
            v10: [0, [Validators.min(0)]],
            v11: [0, [Validators.min(0)]],
        });
    } else {
        this.initializeFormWithDefaults();
    }
}

private initializeFormWithDefaults(): void {
    this.tfpform = this.fb.group({
        v1: [{ value: 0, disabled: true }],
        v2: [0, [Validators.min(0)]],
        v3: [{ value: 0, disabled: true }],
        v4: [0, [Validators.min(0)]],
        v5: [0, [Validators.min(0)]],
        v6: [0, [Validators.min(0)]],
        v7: [0, [Validators.min(0)]],
        v8: [0, [Validators.min(0)]],
        v9: [0, [Validators.min(0)]],
        v10: [0, [Validators.min(0)]],
        v11: [0, [Validators.min(0)]],
    });
}

  ngOnDestroy(): void {
        // Save the form state when the component is destroyed
        this.formStateService.settfpFormState(this.tfpform);
    // Unsubscribe from value changes to prevent memory leaks
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
    this.goto(1)
  }
  setupAutoCalculation(sourceControlName: string, targetControlName: string, multiplier: number): void {
    this.tfpform.get(sourceControlName).valueChanges.subscribe(value => {
      const targetControl = this.tfpform.get(targetControlName);
      if (targetControl) {
        targetControl.setValue(Math.floor( (value * multiplier)*1000)/1000, { emitEvent: false });
      }
    });
  }

  updateSumAndTotal(): void {
    this.sumOfPositiveControls = 0;
    this.sumOfNegativeControls = 0;
    this.totalAmount = 0;

    const positiveKeys = ['v1', 'v3', 'v8'];
    const negativeKeys = ['v5', 'v6', 'v7'];

    Object.keys(this.tfpform.controls).forEach(key => {
      const control = this.tfpform.get(key);
      if (control && positiveKeys.includes(key)) {
        this.sumOfPositiveControls += control.value;
      } else if (control && negativeKeys.includes(key)) {
        this.sumOfNegativeControls += control.value;
      }
    });
    this.totalAmount = Math.abs(this.sumOfPositiveControls - this.sumOfNegativeControls);
    this.sumOfPositiveControls - this.sumOfNegativeControls >= 0 ? this.statepr='P' : this.statepr='R';
  }

  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }

  previous() {
    this.previousStep.emit();
  }
}
