
<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>
<body>
  

<div class="bodycontainer">
  <div id="loader" *ngIf="loading"></div>
  
<div class="container-fluid" *ngIf="!loading">
   
  
<form  [formGroup]="eventForm">
    <h2>Formulaire De Modificaion Des Evènements</h2>
   
    
    

      <div class="input-container">
        <i class="fa fa-user icon"></i>
        <input class="input-field" type="text" placeholder="Modifier le titre de l'évènement" id="title" formControlName="title">
      </div> 
      <div class="input-container">
        <i class="fa fa-date icon"></i>
        <input class="input-field" type="date"  id="date" formControlName="date">
      </div>
      <div title="indiquer nom évènement"class="input-container">
        <i class="fa fa-user icon"></i>
        <textarea  class="form-control" rows="4" cols="50"id="description" formControlName="description"placeholder="Entrez votre commentaire"
         ></textarea>
      </div> 
    
  
    <button class="btn btn-success" (click)="onSubmit()">Envoyer Modification</button>
  </form>
  <p>{{ errormsg }}</p>
  <div class="alert alert-success" *ngIf="isSuccessful">
    Modificaion effectuée avec succès!!
  </div>
</div>
</div>
</body>