import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MustMatch } from '../_helpers/must-match.validator';
import { AlertService } from '../_helpers/alert.service';
import Swal from 'sweetalert2';
import { SignupModalService } from '../services/signup-modal.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { OTP } from '../models/otp-table';
import { OTPService } from '../services/otp-table.service';
import { Contact } from '../models/contact.model';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit,OnDestroy {

  signupForm: FormGroup;
  loading = false;
  errorMessage: string;
  isSuccessful = false;
  isSignUpFailed = false;
  submitted = false;
  fiscalmatPattern = "^[0-9]{8}$" 
  indicatifPattern = "^[0-9]{3}$" 
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  isSignupModalOpen: boolean = false;
emailverified=false
mobileverified=false
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private auth: AuthService,
              private alertService: AlertService ,private signupModalService: SignupModalService,private userservice: UserService,private otp: OTPService,private cont: ContactService
              ) { 
              //  this.signupForm.reset()
                this.signupModalService.modalOpen$.subscribe((isOpen) => {
                  this.isSignupModalOpen = isOpen;
                });

              }
  ngOnDestroy(): void {
this.closeSignupModal()
  }

  ngOnInit() {
    
    this.signupForm = this.formBuilder.group({
      email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
      password: [{value: '', disabled: true}, Validators.required],
      confirmpassword: [{value: '', disabled: true}, Validators.required],
      firstname: [null, Validators.required],
      confirmemail: [{value: '', disabled: true}, Validators.required],
      indicatif: ["216",[Validators.pattern(this.indicatifPattern),Validators.required]],
      mobile: [null,[Validators.pattern(this.fiscalmatPattern),Validators.required]],
      confirmmobile: [null,[Validators.pattern(this.fiscalmatPattern),Validators.required]],
      usertype: [null, Validators.required],
      lastname: [null, Validators.required],
      fonction: [null],
      secteur: [null],
      nature: [null,Validators.required],
      civilite: [null,Validators.required],
      raisonsociale: [null],
      nomsociete: [null],
      clientcode: [null, Validators.required],
      role: [ {value: "basic", disabled: true},Validators.required],
    },
    {
      validator: [MustMatch('email','confirmemail'),MustMatch('mobile','confirmmobile')]
    });
   // this.signupForm.reset()
   setTimeout(() => {
    this.signupForm.controls['email'].enable();
    this.signupForm.controls['password'].enable();
    this.signupForm.controls['confirmemail'].enable();
    this.signupForm.controls['confirmpassword'].enable();

  }, 3000);

  }
  get f() { return this.signupForm.controls; }
  closeSignupModal() {
    this.signupModalService.closeModal();
    this.signupForm.reset()
  }
  randomString() {
    var chars = "ABCDEFGHIJKLMNOPQRSTUVW";
    var string_length = 1;
    var randomstring = '';
    var chars2 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ";
    var string_length2 = 2;
    var randomstring2 = '';
    for (var i=0; i<string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
    }
    for (var j=0; j<string_length2; j++) {
      var rnum2= Math.floor(Math.random() * chars2.length);
      randomstring2 += chars2.substring(rnum2,rnum2+1);
    }
    this.signupForm.patchValue({clientcode: randomstring+randomstring2});
  }
  onSignup() {
    this.loading = true;
    this.submitted = true;

    if (this.signupForm.invalid) {
      
      return this.loading = false;
  }
    const email = this.signupForm.get('email').value;
    
    const mobile = this.signupForm.get('indicatif').value+this.signupForm.get('mobile').value; 
    const usertype = this.signupForm.get('usertype').value;
    
    const password = this.signupForm.get('password').value;
    const confirmpassword = this.signupForm.get('confirmpassword').value;
    const firstname = this.signupForm.get('firstname').value;
    const lastname = this.signupForm.get('lastname').value;
    const fonction = this.signupForm.get('fonction').value;
    const secteur = this.signupForm.get('secteur').value;
    const civilite = this.signupForm.get('civilite').value;
    const nature = this.signupForm.get('nature').value;

    const raisonsociale = this.signupForm.get('raisonsociale').value;
    const nomsociete = this.signupForm.get('nomsociete').value;
    const clientcode = this.signupForm.get('clientcode').value;
    const role = this.signupForm.get('role').value;
    const emailverified=this.emailverified
    const mobileverified=this.mobileverified
    this.auth.register(email, password,confirmpassword,mobile,usertype,firstname,lastname,fonction,secteur,civilite,nature,raisonsociale,nomsociete,clientcode,role,emailverified,mobileverified).subscribe({

      next: data => {
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'inscription réalisée avec succès, veuillez cliquer sur le lien de vérification envoyé à votre adresse Email aussi par SMS pour pouvoir se connecter',
          showConfirmButton: false,
          
        });
        
        
        this.loading = false;
        this.closeSignupModal()
        this.router.navigate(['home']);
      },
      error: error => {
        this.loading=false;
          
      }
    });

  }
  onReset() {
    this.submitted = false;
    this.signupForm.reset();
}
toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  togglerepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }
  emailconfirmwithotp()
  {
    /*console.log(datavalue)
    this.processing=true
    let name=e.target.getAttribute('formControlName')*/
    if(this.isSignupModalOpen)
    {
      this.userservice.getUserByemail(this.signupForm.get('email').value).then(
        (data:any)=>{
        //  this.processing=false
        console.log(data)
          if(data.length>0)
          {
            this.otp.createwithoutfile().then(
              async (res:any)=>{
                const contactadmin = new Contact();
                contactadmin.email = 'macompta@macompta.com.tn'
                contactadmin.emailenvoyea = 'f.benayed@macompta.com.tn';
                contactadmin.description = `code de vérification: ${res.data.otp.value}`;
                contactadmin.typeemail = "OTP";
                this.cont.comunicatewithuser(contactadmin).then(
                    async (data:any) => {
                      //this.processing=true
  const contact = new Contact();
  contact.email = 'macompta@macompta.com.tn'
  contact.emailenvoyea = this.signupForm.get('email').value;
  contact.description = `code de vérification: ${res.data.otp.value}`;
  contact.typeemail = "OTP";
  
  this.cont.comunicatewithuser(contact).then(
      async (data:any) => {
        const { value: numero } = await Swal.fire({
          title: 'confirmation d\'identité',
          html: 'veuillez saisir le code envoyé par email,il va être expiré après 1 minute',
          input: 'text',
          inputLabel: 'code',
          inputValue: '',
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Vous devez renseigner une valeur!'
            }
          }
        })
        this.otp.validateotp(numero).then(
          (success:any) => {
                    this.emailverified=true

         Swal.fire({
              title: 'otp valide',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            }).then((result) => {
            }).catch(() => {
              Swal.fire('opération non aboutie!')
            })
                  },
          (error)=> {
            Swal.fire({
              title: 'otp erroné',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            }).then((result) => {
              this.signupForm.patchValue(
                {
                  email:null
                }
              )  
            }).catch(() => {
              Swal.fire('opération non aboutie!')
            })
          }
        )
      },
      (error) => {
        this.loading = false;
      }
    );
                    }
                )
  
    
              }
            )
          }
           else
           {
           }    
        }
      )
    }

  }
  mobileconfirmwithotp()
  {
    /*console.log(datavalue)
    this.processing=true
    let name=e.target.getAttribute('formControlName')*/
    if(this.isSignupModalOpen)
{
  this.userservice.getUserBymobile(this.signupForm.get('indicatif').value+this.signupForm.get('mobile').value).then(
    (datauser:any)=>{
    //  this.processing=false
    console.log(datauser)
      if(datauser)
      {
        this.otp.createwithoutfile().then(
          async (res:any)=>{
            const useradmin = 
              {
                _id:'63c6a638b550e8e10edff18c',
                mobile:'21698350575'
              }
            
            const messageadmin = `code de vérification: ${res.data.otp.value}`;
            this.userservice.comunicatewithusersms(useradmin,messageadmin).then(
                async (data:any) => {
                  //this.processing=true
const user = datauser
const message = `code de vérification: ${res.data.otp.value}`;
this.userservice.comunicatewithusersms(user,message).then(
  async (data:any) => {
    const { value: numero } = await Swal.fire({
      title: 'confirmation d\'identité',
      html: 'veuillez saisir le code envoyé par sms,il va être expiré après 1 minute',
      input: 'text',
      inputLabel: 'code',
      inputValue: '',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Vous devez renseigner une valeur!'
        }
      }
    })
    this.otp.validateotp(numero).then(
      (success:any) => {
                  this.mobileverified=true
     Swal.fire({
          title: 'otp valide',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        }).then((result) => {

        }).catch(() => {
          Swal.fire('opération non aboutie!')
        })
              },
      (error)=> {
        Swal.fire({
          title: 'otp erroné',
          icon: 'error',
          confirmButtonColor: '#3085d6',
        }).then((result) => {
          this.signupForm.patchValue(
            {
              mobile:null
            }
          )  
        }).catch(() => {
          Swal.fire('opération non aboutie!')
        })
      }
    )
  },
  (error) => {
    this.loading = false;
  }
);
                }
            )

      
          }
        )
      }
       else
       {
       }    
    }
  )
}

  }
}