
  <body>
    <div class="container">
      <h1 class="text-center mt-5 header-title">
        الأداعات والمعاليم التي سيقع إحتسابها
      </h1>
      <h1 class="text-center mt-3 header-title">IMPOTS ET TAXES A LIQUIDER</h1>

      <div class="row d-flex justify-content-center">
        <div class="col-md-8 mt-5">
          <div class="row mb-3">
            <div class="col text-primary text-center fs-2">
              <p>إضغط على الآداء أو المعلوم الّذي تريد إحتسابه</p>
              <p>Cliquer sur l'impot ou la taxe a liquider</p>
            </div>
          </div>
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td *ngIf="showrstax">
                  <a (click)="goto(2)" class="btn link-secondary fs-4" 
                    >الخصم من المورد RS</a
                  >
                </td>
                <td *ngIf="showtfptax">
                  <a (click)="goto(3)" class="btn link-secondary fs-4" 
                    >الأداء على التكوين المهني TFP</a
                  >
                </td>
                <td *ngIf="showfoprolostax">
                  <a (click)="goto(4)" class="btn link-secondary fs-4">
                    المساهمة في صندوق الأداء على التكوين المهني النهوض بالمسكن
                    لفائدة الأجراء FOPROLOS
                  </a>
                </td>
                <td *ngIf="showtvatax">
                  <a (click)="goto(5)" class="btn link-secondary fs-4">أ ق م TVA</a>
                </td>
                <td *ngIf="showdttax">
                  <a (click)="goto(7)" class="btn link-secondary fs-4"
                    >معلوم الطابع الجيائي DT</a
                  >
                </td>
                <td *ngIf="showtcltax">
                  <a (click)="goto(8)" class="btn link-secondary fs-4"
                    >المعلوم على المؤسّسات TCL</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <footer>
      <div class="container text-center mt-5 fs-5 text-warning">
        <p>
          ملاحظة: يجب إدخال المبالغ بإعتبار المليمات، ضع نقطة بين الدّينار
          والملّيمات. مثال: 2500 دينار يجب إدخالها 2500.000
        </p>
        <p>
          Remarque : Vous devez obligatoirement saisir les montants y compris
          les millimes, taper les Dinars et les Millimes separes par un point.
          Exemple : pour saisir 2500DT, il faut taper 2500.000
        </p>
      </div>
    </footer>
    <div class="col d-flex justify-content-center align-items-center">
      <button
        (click)="previous()"
        type="button"
        class="btn btn-outline-primary mx-2"
      >
        السابق Precedent
      </button>
    </div>
  </body>
