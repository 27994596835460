import { Component, OnInit } from '@angular/core';
import { Decfiscmens } from 'src/app/models/dec-fisc-mens';
import { User } from 'src/app/models/user.model';
import { FormStateService } from 'src/app/services/form-store.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { DecfiscmensService } from '../../services/dec-fisc-mens';

@Component({
  selector: 'app-tax-wizard-component',
  templateUrl: './tax-wizard-component.component.html',
  styleUrls: ['./tax-wizard-component.component.scss']
})
export class TaxWizardComponentComponent implements OnInit{
   currentStep = 0;
  totalSteps = 11;
  loading: boolean;
  isLoggedIn: boolean;
  currentUser: User;
  user: User;
  annee: any;
  mois: any;
  constructor(
    private formStateService: FormStateService,private usersservice: UserService,private token: TokenStorageService,private DecfiscmensService :DecfiscmensService
  ) { }
  ngOnInit(): void {
    const savedFormState = this.formStateService.getGardeFormState();
    this.isLoggedIn = !!this.token.getToken();
    if (this.isLoggedIn) {
  this.currentUser = this.token.getUser();  
  this.DecfiscmensService.getdecfiscmens(this.currentUser.userId).then(
    (decfiscmens: Decfiscmens[]) => {
    }
  )    
}
 // Initialize forms
       this.usersservice.getUserById(this.currentUser.userId).then(
        (user: User) => {
          this.loading = false;
          this.user = user;
        }
       )
  }
  // Méthode pour aller à l'étape suivante
  next() {
    if (this.currentStep < this.totalSteps - 1) {
      this.currentStep++;
      console.log(this.currentStep);
    }
  }

  // Méthode pour revenir à l'étape précédente
  previous() {
    if (this.currentStep > 0) {
      this.currentStep--;
      console.log(this.currentStep);
    }
  }
  handleStepChanged(step: number) {
    console.log('Step changed to:', step);
    // Your logic to handle the step change
    this.currentStep=step
  }
  onSubmit(event: any) {
    this.loading = true;
    const savedFormState = this.formStateService.getdtFormState();
    const savedForm2State = this.formStateService.getfoprolosFormState();
    const savedForm3State = this.formStateService.getGardeFormState();
    const savedForm4State = this.formStateService.getrsFormState();
    const savedForm5State = this.formStateService.gettcl1FormState();
    const savedForm6State = this.formStateService.gettcl2FormState();
    const savedForm7State = this.formStateService.gettcl3FormState();
    const savedForm8State = this.formStateService.gettfpFormState();
    const savedForm9State = this.formStateService.gettva1FormState();
    const savedForm10State = this.formStateService.gettva2FormState();
    const decfiscmens:Decfiscmens = new Decfiscmens();
    decfiscmens.impottype1={ type: '', traitementetsalaire: { salairebrut:'', salaireimposable: '', retenuealasource: '',contributionsociale: '', }, 
    location1: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location2: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
    location3: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },location4: { type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '', },
     honoraire1: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, honoraire2: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
     honoraire3: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10001: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},
     montant10002: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',},montant10003: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, 
    montant10004: {  type: '',montantbrut: '', taux: '', montantnet: '', montantretenue: '',}, autre: []}
    decfiscmens.impottype2={ type: '',reporttvamoisprecedent:'',montantexport:'',montantexonere:'',montantsuspension:'',
    tvacollecter:{
      type:'',
      chiffreaffaireht:'',
      tvaammount:'',
      ammountttc:'',
      
      },
      tvacollecter19:{
        type:'',
        chiffreaffaireht:'',
        tvaammount:'',
        ammountttc:'',
        
        }
        ,
      tvacollecter13:{
        type:'',
        chiffreaffaireht:'',
        tvaammount:'',
        ammountttc:'',
        
        },tvarecuperableimmobilier:{
          type:'',
      achatlocauxht:'',
      achatlocauxtva:'',
      
      
      },
      tvarecuperableequipement:{
          type:'',
      achatlocauxht:'',
      achatlocauxtva:'',
      achatimporteht:'',
      achatimportetva:'',
      
      
      },
      tvarecuperableautreachat:{
          type:'',
      achatlocauxht:'',
      achatlocauxtva:'',
      achatimporteht:'',
      achatimportetva:'',
      
      
      },
      locationhabitationmeuble:{
          type:'',
          htammount:'',
          tvaammount:'',
          ttcammount:'',
          },
      locationusagecommercial:{
          type:'',
              htammount:'',
              tvaammount:'',
              ttcammount:'',
              },
      operationlotissement:{
          type:'',
                  htammount:'',
                  tvaammount:'',
                  ttcammount:'',
                  },
      interetpercue:{
          type:'',
                      htammount:'',
                      tvaammount:'',
                      ttcammount:'',
                      },
      autretvaspecial:{
          type:'',
                          htammount:'',
                          tvaammount:'',
                          ttcammount:'',
                          taux:'',
                          }    }
                          decfiscmens.impottype3={ type:'',
                            basetfp:'',
                            tfpsalairebrut:'',
                            montanttfpmois:'',
                            reporttfpmoisprecedent:'',
                            montantavance:'',
                            tfppayer:'',
                            tfpreporter:'',
                            salairesnonsoumistfp:''}
                            decfiscmens.impottype4={ type:'',
                            foprolossalairebrut:'',
                            basefoprolos:'',
                            montantfoprolos:'',
                            salairesnonsoumisfoprolos:''}
                            decfiscmens.impottype5={ type:'',
                            nombrenotehonoraire:'',
                totaldroittimbre:'',}
                decfiscmens.impottype6={ type:'',
                chiffreaffairettc:'',
                tclpayer:'',}
                decfiscmens.impottype7={ type:'',
                chiffreaffaireht:'',
                montantcontribution:'',}
    decfiscmens.userId = this.user._id;
    decfiscmens.activite=this.user.activite;
    decfiscmens.regimefiscalimpot=this.user.regimefiscalimpot;
    decfiscmens.sousactivite=this.user.sousactivite;
    decfiscmens.codepostal=this.user.codepostal;
    decfiscmens.adresse=this.user.adresseactivite
    decfiscmens.firstname=this.user.firstname
    decfiscmens.lastname=this.user.lastname
    decfiscmens.raisonsociale=this.user.raisonsociale
    decfiscmens.codegenre=this.user.codegenre
    decfiscmens.codetva=this.user.codetva
    decfiscmens.matriculefiscale=this.user.matriculefiscale
    decfiscmens.registrecommerce=this.user.registrecommerce
    decfiscmens.datearretactivite=this.user.datearretactivite
    this.formStateService.getGardeFormState()?decfiscmens.annee=this.formStateService.getGardeFormState().get('annee').value:''
    this.formStateService.getGardeFormState()?decfiscmens.mois=this.formStateService.getGardeFormState().get('mois').value:''
    decfiscmens.origine='généré manuellement'
    decfiscmens.source=''
    decfiscmens.nature='Déclaration Mensuelle'

    if(this.annee==''||this.mois=='')
    {
      return (
        Swal.fire({
        title: 'veuillez indiquer le mois et l\'année de la déclaration',
        icon: 'error',
        confirmButtonColor: '#3085d6',
      }).then((result) => {this.loading=false,  event.target.disabled = false
      }).catch(() => {
        Swal.fire('opération non aboutie!')
      }))
    }
if (savedForm4State) 
{
decfiscmens.impottype1.type='Retenue à la source'
decfiscmens.formrs=savedForm4State.value
}
if(savedForm8State)
{
decfiscmens.impottype3.type='TFP'
decfiscmens.formtfp=savedForm8State.value
}
if(savedForm2State)
{
decfiscmens.impottype4.type='FOPROLOS'
decfiscmens.formfoprolos=savedForm2State.value
}
if(savedForm9State&&savedForm10State)
{

decfiscmens.impottype2.type='TVA'
//decfiscmens.impottype2.reporttvamoisprecedent=this.option64Value
decfiscmens.impottype2.tvacollecter.type='TVA collecté'
decfiscmens.impottype2.tvarecuperableautreachat.type='TVA récupérable pour les autres achats'
decfiscmens.impottype2.tvarecuperableequipement.type='TVA récupérable pour les achats d\'équipements'
decfiscmens.impottype2.tvarecuperableimmobilier.type='TVA récupérable pour les achats immobiliers'
decfiscmens.impottype2.locationhabitationmeuble.type='TVA sur location à usage d\'habitation meublé'
decfiscmens.impottype2.locationusagecommercial.type='TVA sur location à usage commercial, industriel, professionnel et artisanal'
decfiscmens.impottype2.operationlotissement.type='TVA sur opérations de lotissement'
decfiscmens.impottype2.interetpercue.type='TVA sur intêrets percus'
decfiscmens.impottype2.autretvaspecial.type='TVA sur autres revenus'
decfiscmens.formtva1=savedForm9State.value
decfiscmens.formtva2=savedForm10State.value
}
/*if(this.option172Value)
{
  decfiscmens.impottype7.type='FSSP'
}*/

if(savedFormState)
{
decfiscmens.impottype5.type='Droit de timbre'
decfiscmens.formdt=savedFormState
}
if(savedForm5State&&savedForm6State&&savedForm7State)
{
decfiscmens.impottype6.type='TCL'
decfiscmens.formtcl1=savedForm5State.value
decfiscmens.formtcl2=savedForm6State.value
decfiscmens.formtcl3=savedForm7State.value
}
event.target.disabled = true; 
console.log(decfiscmens)
this.DecfiscmensService.create(decfiscmens).then(
  (data:any) => {
    this.token.saved=true;
    this.loading = false;
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'déclaration sauvegardée avec succès! un email vous a été envoyer pour confirmer la réception de votre déclaration. vous pouvez désormais modifier/compléter votre déclaration à travers votre tableau de bord',
      showConfirmButton: false,
      timer: 6000 
    });
   // this.router.navigate(['modify-decfiscmens/'+data.data._id])
  },
  (error) => {
    this.loading = false;
    
  }
)

}
}
