<h1 mat-dialog-title>Configuration de Mapping</h1>
<div mat-dialog-content>
  <form [formGroup]="configForm">
    <div>
      <label for="cellulebanque">Cellule pour Nom Banque :</label>
      <input id="cellulebanque" formControlName="cellulebanque" appUppercase placeholder="ex : STB">
    </div>
    <div>
      <label for="celluleNumeroCompte">Cellule pour Numéro de Compte :</label>
      <input id="celluleNumeroCompte" formControlName="celluleNumeroCompte" appUppercase placeholder="ex : A3">
    </div>
    <div>
      <label for="celluleDebit">Cellule pour Débit :</label>
      <input id="celluleDebit" formControlName="celluleDebit" appUppercase placeholder="ex : E6">
    </div>
    <div>
      <label for="celluleCredit">Cellule pour Crédit :</label>
      <input id="celluleCredit" formControlName="celluleCredit" appUppercase placeholder="ex : F6">
    </div>
    <div>
      <label for="ligneDebutTransactions">Ligne pour Début des Transactions :</label>
      <input id="ligneDebutTransactions" type="number" formControlName="ligneDebutTransactions" placeholder="ex : 8">
    </div>
    <div>
      <label for="ligneFinTransactions">Ligne pour Fin des Transactions :</label>
      <input id="ligneFinTransactions" type="number" formControlName="ligneFinTransactions" placeholder="ex : 20">
    </div>
    <div>
      <label for="colonneJourTransaction">Colonne pour Jour de Transaction :</label>
      <input id="colonneJourTransaction" formControlName="colonneJourTransaction" appUppercase placeholder="ex : A">
    </div>
    <div>
      <label for="colonneDateTransaction">Colonne pour Date de Transaction :</label>
      <input id="colonneDateTransaction" formControlName="colonneDateTransaction" appUppercase placeholder="ex : B">
    </div>
    <div>
      <label for="colonneDesignation">Colonne pour Désignation :</label>
      <input id="colonneDesignation" formControlName="colonneDesignation" appUppercase placeholder="ex : C">
    </div>
    <div>
      <label for="colonneDebitTransaction">Colonne pour Débit :</label>
      <input id="colonneDebitTransaction" formControlName="colonneDebitTransaction" appUppercase placeholder="ex : E">
    </div>
    <div>
      <label for="colonneCreditTransaction">Colonne pour Crédit :</label>
      <input id="colonneCreditTransaction" formControlName="colonneCreditTransaction" appUppercase placeholder="ex : F">
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button color="primary" (click)="saveConfig()">Enregistrer</button>
</div>
