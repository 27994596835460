

<div class="page-container" *ngIf="user">
  <h1>Modification des Données Utilisateurs</h1>
        <mat-tab-group (selectedTabChange)="onTabChangesdonnessignaletique($event)">
          <form  [formGroup]="userForm">
          <mat-tab label="Informations générales">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <div class="form-group" *ngIf="user.usertype != 'Candidat'">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Nature utilisateur</mat-label>
                        <mat-select placeholder="Entrer nature utilisateur" id="nature" formControlName="nature" name="nature" (selectionChange)="update1($event)">
                          <mat-option value="" disabled hidden>Entrer la nature de l'utilisateur</mat-option>
                          <mat-option value="Personne Physique">Personne Physique</mat-option>
                          <mat-option value="Personne Morale">Personne Morale</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="userForm.controls['nature'].value=='Personne Morale'">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Raison Sociale</mat-label>
                        <input matInput placeholder="Modifier Votre Raison Sociale" id="raisonsociale" formControlName="raisonsociale">
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Civilite</mat-label>
                        <mat-select placeholder="Entrer Votre Nom" id="civilite" formControlName="civilite" name="civilite">
                          <mat-option hidden>Modifier Votre Civilité</mat-option>
                          <mat-option value="Monsieur">Monsieur</mat-option>
                          <mat-option value="Madame">Madame</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <ng-container *ngIf="userForm.controls['nature'].value=='Personne Physique'; else personMorale">
                        <label class="col-md-4 control-label">Nom Utilisateur</label>
                      </ng-container>
                      <ng-template #personMorale>
                        <label class="col-md-4 control-label">Nom du responsable de l'entité</label>
                      </ng-template>
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Nom</mat-label>
                        <input matInput type="text" placeholder="Modifier Votre Nom" id="lastname" formControlName="lastname" name="lastname">
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <ng-container *ngIf="userForm.controls['nature'].value=='Personne Physique'; else personMoraleFirstName">
                        <label class="col-md-4 control-label">Prénom Utilisateur</label>
                      </ng-container>
                      <ng-template #personMoraleFirstName>
                        <label class="col-md-4 control-label">Prénom du responsable de l'entité</label>
                      </ng-template>
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Prénom</mat-label>
                        <input matInput type="text" placeholder="Modifier Votre Prénom" id="firstname" formControlName="firstname">
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div *ngIf="f.codepostal.errors" class="invalid-feedback" style="color:red;float: right;">
                      <div>Code Postal en 4 caractères</div>
                    </div>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Code Postal</mat-label>
                        <input matInput type="text" (keypress)="keyPressNumbers($event)" placeholder="Modifier Votre Code Postal"
                               [ngClass]="{ 'is-invalid': f.codepostal.errors }" id="codepostal" formControlName="codepostal">
                        <mat-icon matSuffix>home</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Adresse Activité</mat-label>
                        <input matInput type="text" placeholder="Modifier Votre Adresse d'activité" id="adresseactivite" formControlName="adresseactivite">
                        <mat-icon matSuffix>home</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Code Client</mat-label>
                        <input matInput type="text" placeholder="code client" id="clientcode" formControlName="clientcode">
                        <mat-icon matSuffix>home</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Email</mat-label>
                        <input matInput type="email" placeholder="Entrez Votre Email" id="email" formControlName="email" (change)="emailconfirmwithotp()">
                        <mat-icon matSuffix>email</mat-icon>
                      </mat-form-field>
                      <button type="button" (click)="emailconfirmwithotp()"*ngIf="resendemailotp">Renvoyer OTP</button>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer">
                        <mat-label>Numéro Mobile</mat-label>
                        <input matInput type="text" placeholder="Entrez Votre Numéro Mobile" id="mobile" formControlName="mobile" (change)="mobileconfirmwithotp()">
                        <mat-icon matSuffix>phone</mat-icon>
                      </mat-form-field>
                      <button type="button" (click)="mobileconfirmwithotp()"*ngIf="resendmobileotp">Renvoyer OTP</button>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="col-md-4 control-label">Logo</label>
                     

                      <div class="col-md-8 inputGroupContainer" >
                        <div class="invoice-logo">
                          <input type="file" (change)="onImagePick5($event)" accept="application/pdf,image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                          
                          <div *ngIf="pdfSrc">
                            <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" style="display: block; width: 200px; height: auto;"></pdf-viewer>
                          </div>
                          <div *ngIf="imageSrc">
                            <img [src]="imageSrc" alt="Selected Image" style="display: block; width: 200px; height: auto;" />
                          </div>
                          
                          <div *ngIf="!imageSrc && !pdfSrc && !errorMessage">
                            <p>No file selected</p>
                          </div>
                        </div>
                        <b><a [href]="user.ficheUrl5" target="_blank">Aperçu logo</a></b>
                      </div>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer" appearance="outline">
                        <mat-label>Mot De Passe</mat-label>
                        <input matInput [type]="fieldTextType ? 'text' : 'password'" placeholder="Modifier votre Mot de passe" id="password" formControlName="password">
                        <button mat-icon-button matSuffix (click)="toggleFieldTextType()" type="button">
                          <mat-icon>{{ fieldTextType ? 'visibility' : 'visibility_off' }}</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer" appearance="outline">
                        <mat-label>Confirmer Mot De Passe</mat-label>
                        <input matInput [type]="repeatFieldTextType ? 'text' : 'password'" placeholder="Confirmer votre Mot de passe" id="confirmpassword" formControlName="confirmpassword">
                        <button mat-icon-button matSuffix (click)="togglerepeatFieldTextType()" type="button">
                          <mat-icon>{{ repeatFieldTextType ? 'visibility' : 'visibility_off' }}</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
                
          </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Informations spécifiques">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="form-group" *ngIf="natsociete">
                      <label for="choice" class="col-md-8 control-label">Êtes-vous totalement exportateur:</label>
                      <div id="block1">
                        <mat-checkbox id="choice" formControlName="export">Oui</mat-checkbox>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label class="col-md-4 control-label" *ngIf="tot || userForm.controls['export'].value">Déclaration d'investissement</label>
                      <div class="col-md-8 inputGroupContainer" *ngIf="tot || userForm.controls['export'].value">
                        <mat-form-field class="full-width">
                          <mat-label>Déclaration d'investissement</mat-label>
                          <input type="file" matInput #filePicker (change)="onImagePick2($event)" class="form-control" accept="application/pdf" style="border: 1px solid red">
                          <mat-icon matSuffix>attach_file</mat-icon>
                        </mat-form-field>
                        <b><a [href]="user.ficheUrl2" target="_blank">Aperçu DI</a></b>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="natsociete">
                      <label for="choice3" class="col-md-8 control-label">Avez-vous une matricule CNSS:</label>
                      <div id="block3">
                        <mat-checkbox id="choice3" formControlName="salaire">Oui</mat-checkbox>
                      </div>
                    </div>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </mat-tab>
          <mat-tab label="Informations supplémentaires">
            <table>
              <tbody>
                <tr *ngIf="sal || userForm.controls['salaire'].value">
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-9 inputGroupContainer" appearance="outline">
                        <mat-label>Matricule CNSS</mat-label>
                        <input matInput type="text" placeholder="Renseigner la matricule cnss" id="selectfiscalimpot" formControlName="matriculecnss">
                        <mat-icon matSuffix>account_box</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-7 inputGroupContainer" appearance="outline">
                        <mat-label>Taux de la charge patronale</mat-label>
                        <input matInput type="text" placeholder="Renseigner régime fiscal en matière d'impôts directs" id="selectfiscalimpot" formControlName="tauxchargepatronale">
                        <mat-icon matSuffix>attach_money</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer" appearance="outline">
                        <mat-label>Taux de l'accident de travail</mat-label>
                        <input matInput type="text" placeholder="Renseigner régime fiscal en matière d'impôts directs" id="selectfiscalimpot" formControlName="tauxaccidenttravail">
                        <mat-icon matSuffix>warning</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <mat-form-field class="col-md-8 inputGroupContainer" appearance="outline">
                        <mat-label>Régime horaire</mat-label>
                        <input matInput type="text" placeholder="Renseigner régime fiscal en matière d'impôts directs" id="selectfiscalimpot" formControlName="regimehoraire">
                        <mat-icon matSuffix>schedule</mat-icon>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <label class="col-md-4 control-label">Nature D'activité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-select
                            placeholder="Entrer Votre Nature Activité"
                            id="activitynature"
                            (selectionChange)="update0($event)"
                            [(ngModel)]="optionValue"
                            formControlName="activitynature"
                            name="activitynature"
                          >
                            <mat-option *ngIf="currentuser.natureactivite" [value]="currentuser.natureactivite" hidden>{{ currentuser.natureactivite }}</mat-option>
                            <mat-option value=""></mat-option>
                            <mat-option *ngFor="let natactivite of natureactivites" [value]="natactivite">
                              {{ natactivite }}
                            </mat-option>
                            <mat-option value="Autre">Autre</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="optionValue == 'Autre'">
                      <label class="col-md-4 control-label">Nature D'activité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-icon matPrefix>business</mat-icon>
                          <input matInput type="text" placeholder="Renseigner votre nature d'activité" id="selectactivitynature" formControlName="selectactivitynature">
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <label class="col-md-4 control-label">Activité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-select
                            placeholder="Entrer Votre Activité"
                            id="activity"
                            style="width: 150px;"
                            [(ngModel)]="option1Value"
                            (click)="sort()"
                            (selectionChange)="update($event)"
                            formControlName="activity"
                            name="activity"
                          >
                            <mat-option *ngIf="user.activite" [value]="user.activite" hidden>{{ user.activite }}</mat-option>
                            <mat-option value=""></mat-option>
                            <mat-option *ngFor="let activite of activites" [value]="activite">
                              {{ activite }}
                            </mat-option>
                            <mat-option value="Autre" *ngIf="optionValue != 'association et syndic'">Autre</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="option1Value == 'Autre'">
                      <label class="col-md-4 control-label">Activité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-icon matPrefix>business_center</mat-icon>
                          <input matInput type="text" placeholder="Renseigner votre activité" id="selectactivity" formControlName="selectactivity">
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <label class="col-md-4 control-label">Date d'effet</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-icon matPrefix>event</mat-icon>
                          <input matInput type="date" placeholder="Modifier Votre Date d'effet" id="dateeffet" formControlName="dateeffet">
                        </mat-form-field>
                        <p *ngIf="user.dateeffet">{{ user.dateeffet }}</p>
                      </div>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <label class="col-md-4 control-label">Sous-Activité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-select 
                            placeholder="Entrer Votre Sous-Activité" 
                            id="underactivity" 
                            [(ngModel)]="option2Value" 
                            (selectionChange)="update2($event)" 
                            formControlName="underactivity" 
                            name="underactivity">
                            <mat-option *ngIf="user.sousactivite" [value]="user.sousactivite" hidden>{{ user.sousactivite }}</mat-option>
                            <mat-option value=""></mat-option>
                            <mat-option *ngFor="let sousactivite of sousactivites" [value]="sousactivite">
                              {{ sousactivite }}
                            </mat-option>
                            <mat-option value="Autre">Autre</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="option2Value == 'Autre'">
                      <label class="col-md-4 control-label">Sous-Activité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-icon matPrefix>business</mat-icon>
                          <input 
                            matInput 
                            type="text" 
                            placeholder="Renseigner votre Sous-Activité" 
                            id="selectunderactivity" 
                            formControlName="selectunderactivity">
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <label class="col-md-4 control-label">Spécialité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-select 
                            placeholder="Entrer Votre spécialité" 
                            id="specialite" 
                            formControlName="specialite" 
                            (selectionChange)="update3($event)" 
                            name="specialite">
                            <mat-option *ngIf="user.specialite" [value]="user.specialite" hidden>{{ user.specialite }}</mat-option>
                            <mat-option value=""></mat-option>
                            <mat-option *ngFor="let specialite of specialites" [value]="specialite">
                              {{ specialite }}
                            </mat-option>
                            <mat-option value="Autre">Autre</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <label class="col-md-4 control-label">Sous-Spécialité</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-select 
                            placeholder="Entrer Votre Sous-Spécialité" 
                            id="sousspecialite" 
                            formControlName="sousspecialite" 
                            name="sousspecialite">
                            <mat-option *ngIf="user.sousspecialite" [value]="user.sousspecialite" hidden>{{ user.sousspecialite }}</mat-option>
                            <mat-option value=""></mat-option>
                            <mat-option *ngFor="let sousspecialite of sousspecialites" [value]="sousspecialite">
                              {{ sousspecialite }}
                            </mat-option>
                            <mat-option value="Autre">Autre</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td colspan="2">
                    <div class="form-group" *ngIf="user.usertype == 'Client' && userForm.controls['nature'].value=='Personne Physique'">
                      <label class="col-md-4 control-label">Régime Fiscal En Matière D'impôts Directs</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <mat-select
                            placeholder="Entrer Votre régime fiscal en matière d'impôts directs"
                            id="fiscalimpot"
                            [(ngModel)]="option3Value"
                            formControlName="fiscalimpot"
                            name="fiscalimpot">
                            <mat-option *ngIf="currentuser.regimefiscalimpot" [value]="currentuser.regimefiscalimpot" hidden>
                              {{ currentuser.regimefiscalimpot }}
                            </mat-option>
                            <mat-option value="Réel">Réel</mat-option>
                            <mat-option value="Forfait D'assiette">Forfait D'assiette</mat-option>
                            <mat-option *ngIf="userForm.controls['activitynature'].value!='Profession Libérale'" value="Non soumis">
                              Non soumis
                            </mat-option>
                            <mat-option value="Autre">Autre</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    
                    <div class="form-group" *ngIf="option3Value == 'Autre'">
                      <label class="col-md-4 control-label">Régime Fiscal En Matière D'impôts Directs</label>
                      <div class="col-md-8 inputGroupContainer">
                        <mat-form-field class="full-width">
                          <input
                            matInput
                            type="text"
                            placeholder="Renseigner régime fiscal en matière d'impôts directs"
                            id="selectfiscalimpot"
                            formControlName="selectfiscalimpot">
                        </mat-form-field>
                      </div>
                    </div>
                  </td>
                
                  <td>
                    <div class="form-group" *ngIf="user.usertype == 'Client'">
                      <label class="col-md-4 control-label">Régime Fiscal En Matière de TVA</label>
                      <mat-form-field class="full-width">
                        <mat-select
                          placeholder="Entrer Votre régime fiscal en matière de TVA"
                          id="regfiscal"
                          formControlName="fiscaltvaassobli">
                          <mat-option *ngIf="user.regimefiscaltva" [value]="user.regimefiscaltva" hidden>
                            {{ user.regimefiscaltva }}
                          </mat-option>
                          <mat-option value=""></mat-option>
                          <mat-option *ngFor="let fiscaltvaassobli of fiscaltvaassoblis" [value]="fiscaltvaassobli">
                            {{ fiscaltvaassobli }}
                          </mat-option>
                          <mat-option value="Autre">Autre</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
                
                <tr>
                  <td *ngIf="user.activite == 'Syndic des copropriétaires' || userForm.controls['activity'].value=='Syndic des copropriétaires'">
                    <div class="form-group">
                      <label for="choice" class="col-md-8 control-label">Avez vous une matricule fiscale:</label>    
                      <div id="block4">
                        <mat-checkbox class="input-field" id="choice4" formControlName="matfiscale">Oui</mat-checkbox>
                      </div>  
                    </div>
                  </td>
                
                  <td>
                    <div class="form-group">
                      <label class="col-md-4 control-label">Carte d'identification fiscale</label>
                      <div class="col-md-8 inputGroupContainer">
                        <div class="input-group">
                          <span class="input-group-addon" style="max-width: 100%;"><i class="glyphicon glyphicon-asterisk" style="color: red;"></i></span>
                          <input type="file" style="border: 1px solid red" #filePicker (change)="onImagePick($event)" class="form-control" accept="application/pdf">
                          <b><a href="{{user.ficheUrl}}" target="_blank">Aperçu CIF</a></b>
                        </div>
                      </div>
                    </div>
                  </td>
                
                  <td colspan="2" *ngIf="user.activite != 'Syndic des copropriétaires' || userForm.controls['activity'].value!='Syndic des copropriétaires' || mat || userForm.controls['matfiscale'].value==true || !user.activite">
                    <div class="form-group">
                      <label class="col-md-4 control-label">Matricule Fiscale</label>
                      <div class="col-md-8 inputGroupContainer">
                        <div class="input-row">
                          <input class="input-field" style="width: 25%;" type="text" id="fiscalmat" formControlName="fiscalmat" [ngClass]="{ 'is-invalid': f.fiscalmat.errors }">
                          <input class="input-field" style="width: 10%;" type="text" id="fiscalmatletter" formControlName="fiscalmatletter" [ngClass]="{ 'is-invalid': f.fiscalmatletter.errors }">
                          <p>/</p>
                          <input class="input-field" type="text" style="width: 12%;" id="fiscalmatinchanged" formControlName="fiscalmatinchanged" [ngClass]="{ 'is-invalid': f.fiscalmatinchanged.errors }">
                          <p>/</p>
                          <input class="input-field" type="text" style="width: 12%;" id="fiscalmatinchanged2" formControlName="fiscalmatinchanged2" [ngClass]="{ 'is-invalid': f.fiscalmatinchanged2.errors }">
                          <p>/</p>
                          <input class="input-field" type="text" style="width: 10%;" id="fiscalmatnumbers" formControlName="fiscalmatnumbers" [ngClass]="{ 'is-invalid': f.fiscalmatnumbers.errors }" default='000'>
                        </div>
                        <div class="validation-messages">
                          <div *ngIf="f.fiscalmat.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmat.errors.pattern">Veuillez saisir les 7 premiers chiffres</div>
                            <div *ngIf="f.fiscalmat.errors.length">Saisissez uniquement 7 lettres</div>
                            <div *ngIf="f.fiscalmat.errors.required">1ère partie obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatletter.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatletter.errors.pattern">Veuillez saisir la lettre majuscule qui suit les chiffres</div>
                            <div *ngIf="f.fiscalmatletter.errors.length">Saisissez uniquement 1 lettre</div>
                            <div *ngIf="f.fiscalmatletter.errors.required">2ème partie obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatinchanged.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatinchanged.errors.pattern">Veuillez saisir une première lettre majuscule</div>
                            <div *ngIf="f.fiscalmatinchanged.errors.length">Saisissez uniquement 1 lettre</div>
                            <div *ngIf="f.fiscalmatinchanged.errors.required">1ère lettre obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatinchanged2.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatinchanged2.errors.pattern">Veuillez saisir une deuxième lettre majuscule</div>
                            <div *ngIf="f.fiscalmatinchanged2.errors.length">Saisissez uniquement 1 lettre</div>
                            <div *ngIf="f.fiscalmatinchanged2.errors.required">2ème lettre obligatoire</div>
                          </div>
                          <div *ngIf="f.fiscalmatnumbers.errors" class="invalid-feedback" style="color:red;">
                            <div *ngIf="f.fiscalmatnumbers.errors.pattern">Veuillez saisir les 3 derniers chiffres</div>
                            <div *ngIf="f.fiscalmatnumbers.errors.length">Saisissez uniquement 3 chiffres</div>
                            <div *ngIf="f.fiscalmatnumbers.errors.required">3ème partie obligatoire</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                
                <tr *ngIf="user.activite == 'Syndic des copropriétaires' || userForm.controls['activity'].value == 'Syndic des copropriétaires'">
                  <td colspan="4">
                    <fieldset>
                      <legend>Liste des copropriétaires</legend>
                      <p>Veuillez respecter la forme suivante du fichier Excel à rattacher :</p>
                      <p>1ère colonne: numéro &nbsp; 2ème colonne: copropriétaire</p>
                      
                      <div class="form-group row">
                        <label class="col-sm-12 col-form-label">Charger document</label>
                        <div class="col-sm-12">
                          <input type="file" class="form-control inputFile" accept=".xls, .xlsx" (change)="onFileChange($event);">
                        </div>
                      </div>
                      
                      <table class="table table-striped" id="coproprietaire-section" [formGroup]="copform">
                        <thead>
                          <tr> 
                            <th scope="col">Numéro</th>
                            <th scope="col">Copropriétaire</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        
                        <tbody formArrayName="ammounts2">
                          <tr *ngFor="let ammount of ammountControls2; let i = index;" [formGroupName]="i">
                            <td>
                              <div class="col-6">
                                <input type="text" class="form-control" formControlName="numero" placeholder="Numéro" (change)="check(i)" />
                              </div>
                            </td>
                            <td>
                              <div class="col-6">
                                <textarea class="form-control" formControlName="coproprietaire" rows="2" placeholder="Copropriétaire"></textarea>
                              </div>
                            </td>
                            <td>
                              <div>
                                <button type="button" class="btn btn-danger btn-sm" (click)="removeammount2(i)">
                                  <i class="fa fa-minus-circle"></i> Supprimer
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        
                      </table>
                      
                      <div class="col-12 mt-2">
                        <button type="button" class="btn btn-success btn-sm" (click)="addammount2()">
                          <i class="fa fa-plus-circle"></i> Ajouter copropriétaire
                        </button>
                      </div>
                      
                    </fieldset>
                  </td>
                </tr>
                
                </tbody>
            </table>
          </mat-tab>
        </form>
        </mat-tab-group>
        <button class="btn btn-success"[disabled]="userForm.invalid" (click)="onSubmit()" ><mat-icon *ngIf="loading" class="fa-spin">autorenew</mat-icon>Valider</button>
</div>
