import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import { LoginModalService } from '../services/login-modal.service';
import { SignupModalService } from '../services/signup-modal.service';
import { OtherdsServiceUser } from '../services/otherds-user.service';
import { Otherdsmodeluser } from '../models/otherds-user.model';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { Decfiscmens } from '../models/dec-fisc-mens';
import Swal from 'sweetalert2';
import { User } from '../models/user.model';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,OnDestroy {

  loginForm: FormGroup;
  loading = false;
  errorMessage: string;
  isLoggedIn = false;
  isLoginFailed = false;
  role: string;
  email:string;
  firstname:string;
  civilite: string;
  lastname:string;
  fieldTextType: boolean;
  isLoginModalOpen: boolean = false;
  allotherdsSub: any;
  otherdss: Otherdsmodeluser[]=[];
  payedotherdss: Otherdsmodeluser[]=[];
  nonpayedotherdss: Otherdsmodeluser[]=[];
  alldeccomptabSub: any;
  dsdeccomptabilites: Deccomptabilite[]=[];
  payedsdeccomptabilites: Deccomptabilite[]=[];
  nonpayedsdeccomptabilites:Deccomptabilite[]=[];
  alldecfiscmensSub: any;
  dsdecfiscmenss:Decfiscmens[]=[];
  payedsdecfiscmenss: Decfiscmens[];
  nonpayedsdecfiscmenss: Decfiscmens[];
  displayverifsecond='none'
  comptesuser=[]
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private auth: AuthService,
              private userservice: UserService,
              private tokenStorage: TokenStorageService,
              private loginModalService: LoginModalService, private signupModalService : SignupModalService,
              private otheruser:OtherdsServiceUser,private deccompt:DeccomptabiliteService,private dec:DecfiscmensService,
              ) {
                this.loginModalService.modalOpen$.subscribe((isOpen) => {
                  this.isLoginModalOpen = isOpen;
                });
               }
  ngOnDestroy(): void {
    this.allotherdsSub?this.allotherdsSub.unsubscribe():''
    this.alldeccomptabSub?this.alldeccomptabSub.unsubscribe():''
    this.alldecfiscmensSub?this.alldecfiscmensSub.unsubscribe():''
  }

  ngOnInit() {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.role = this.tokenStorage.getUser();
      this.email=this.tokenStorage.getUser();
      this.firstname=this.tokenStorage.getUser().Firstname;
      this.lastname=this.tokenStorage.getUser().Lastname;
      this.civilite=this.tokenStorage.getUser().civilite;
      this.role=this.tokenStorage.getUser().role;
      
    }
   else if(!this.tokenStorage.getToken()&&this.router.url.includes('login')) 
   {
    this.loginModalService.openModal();

   }
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });
  }

  closeLoginModal() {
    this.loginModalService.closeModal();
  }
  openSignupModal() {
    console.log('opensignupModal called'); // Add this log
    this.signupModalService.openModal();
  }
  correctpass()
  {
    this.displayverifsecond='none'

  }
  onverifyduplicate()
  {
    this.userservice.getUserByemail(this.loginForm.get('email').value).then(
      (data:any)=>{
    if(data.length>1)
    {
    this.comptesuser=data
    this.displayverifsecond='block'
    }
    else if(data.length==1)
    {
      this.onLogin(this.loginForm.get('email').value,data[0]._id)
    }
    else 
    {
      Swal.fire('utilisateur introuvable!');
    }
    }
    )
  }
  onLogin(emailform:any,id:any) {
    this.loading = true;
    const email = emailform;
    const password = this.loginForm.get('password').value;
    console.log(id)
    this.auth.login(email, password,id).subscribe({
      next: data => {
        
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.role = this.tokenStorage.getUser().role;
        this.firstname=this.tokenStorage.getUser().Firstname;
        this.lastname=this.tokenStorage.getUser().Lastname;
        this.civilite=this.tokenStorage.getUser().civilite;
        this.userservice.connectUser(this.tokenStorage.getUser().userId,this.tokenStorage.getUser())
     // this.router.navigate(['home'])
    this.closeLoginModal()
    this.verifydsnonpaye()
      },
      error: error => {
        this.loading = false;
        this.isLoginFailed = true;
      }
    });
  }
  verifydsnonpaye()
  {
    this.otheruser.getOtherdsdataByuserwithoutsubscription(this.tokenStorage.getUser().userId).then(
      (otherdss:any)=>{
        this.otherdss = otherdss.filter(e=>new Date(e.created) >=new Date('11.30.2023'));
          this.payedotherdss= this.otherdss.filter(e=>e.paye==true)
          this.nonpayedotherdss= this.otherdss.filter(e=>e.paye==false)
        this.deccompt.getDeccomptabilitereqByfactureuserwithoutsubscription(this.tokenStorage.getUser().userId).then(
          (deccomptabs:any)=>
          {
            this.dsdeccomptabilites = deccomptabs.filter(e=>new Date(e.created)>=new Date('11.30.2023'));
      this.payedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==true)
      this.nonpayedsdeccomptabilites= this.dsdeccomptabilites.filter(e=>e.paye==false)
            this.dec.getdecfiscmensqByfactureuserwithoutsubscription(this.tokenStorage.getUser().userId).then(
              (decfiscss:any)=>
              {
                this.dsdecfiscmenss = decfiscss.filter(e=>new Date(e.created)>=new Date('11.30.2023')&&e.origine!='généré automatiquement');
                this.payedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==true)
                this.nonpayedsdecfiscmenss= this.dsdecfiscmenss.filter(e=>e.paye==false)
                if(this.nonpayedsdecfiscmenss.length>0||this.nonpayedotherdss.length>0||this.nonpayedsdeccomptabilites.length>0){
                  Swal.fire({
                    title: 'Nous avons remarqué que vous avez des notes d\'honoraires qui ne sont pas encore régularisées. Voulez vous procéder au règlement?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Payer maintenant',
                    cancelButtonText: 'Payer ultérieurement',
                  }).then((result) => {
                    if (result.value) {
                      this.router.navigate(['user-board/dsapayer']).then(
                        ()=>{
                       this.reloadPage();
                        }
                      );
                    }
            else{
              this.router.navigate(['home']).then(
                ()=>{
                this.reloadPage();
                }
              );
            }
                  }).catch(() => {
                    Swal.fire('opération non aboutie!');
                  });
                }
                else {
                  console.log('routinghere0')
                  this.router.navigate(['home']).then(
                    ()=>{
                   this.reloadPage();
                    }
                  );
                }
              }
            )

          }
        )

      }
    )
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  reloadPage(): void {
  window.location.reload()
  }
}