<div class="bordered-container" [formGroup]="searchForm">
  <div class="container-fluid" *ngIf="showusersform">
    <div class="searchbar">
      <input class="search_input" type="text" name="" formControlName="email" placeholder="Chercher par Email">
      <div *ngIf="searchForm.get('email').errors && searchForm.get('email').touched" class="error-message">
        <div *ngIf="searchForm.get('email').errors.email">format invalide</div>
      </div>
    </div>
    <div class="searchbar">
      <input class="search_input" type="text" name="" formControlName="mobile" placeholder="Chercher par Mobile">
    </div>
    <div class="searchbar">
      <input class="search_input" type="text" name="" formControlName="nom" placeholder="Chercher par Nom">
    </div>
    <div class="searchbar">
      <input class="search_input" id="firstname" formControlName="firstname" type="text" placeholder="Chercher par Prénom">
    </div>
    <div class="searchbar">
      <input class="search_input" id="raisonsociale" formControlName="raisonsociale" type="text" placeholder="Chercher par raison sociale">
    </div>
    <div class="searchbar">
      <input class="search_input" id="clientcode" formControlName="clientcode" type="text" placeholder="Chercher par code client">
    </div>
    <div class="searchbar">
      <input class="search_input" id="natureactivite" formControlName="natureactivite" type="text" placeholder="Chercher par nature activité">
    </div>
    <div class="searchbar">
      <button type="submit" class="search_button" (click)="onfilter()">
        <i class="fas fa-search"></i> Chercher
      </button>
    </div>
  </div>
  <div class="container-fluid" *ngIf="showalldsform">
    <div class="searchbar">
      <select  class="search_input" id="activity"formControlName="client" name="client">
        <option value=""></option>
        <option *ngFor="let us of globalarrayusers" [value]="us._id">
          {{us.clientcode}} <span *ngIf="us.nature=='Personne Physique'">{{ us.firstname }} &nbsp; {{ us.lastname }}</span> &nbsp; <span *ngIf="us.nature=='Personne Morale'">{{ us.raisonsociale }}</span>
      </option>
      </select>
    </div>
    <div class="searchbar">
      <button type="submit" class="search_button" (click)="onfilter()">
        <i class="fas fa-search"></i> Chercher
      </button>
    </div>
  </div>
</div>

