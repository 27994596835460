import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { devismodel } from '../models/facture.model';
const API_URL_test = 'http://localhost:3002/api/facture/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/facture/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class FactureService {
    
    
    constructor(private http: HttpClient) { }
    public factures: devismodel[] = [];
    public factures$ = new Subject<devismodel[]>();
    getFactures(page: number, size: number, filter: string = '', status: string = ''): devismodel[] {
      let filteredFactures = this.factures;
  
      if (filter) {
        filteredFactures = filteredFactures.filter(invoice => 
          invoice.ref.toLowerCase().includes(filter.toLowerCase()) ||
          invoice.clientdetails.name.toLowerCase().includes(filter.toLowerCase())
        );
      }
  
      if (status) {
        filteredFactures = filteredFactures.filter(invoice => invoice.status === status);
      }
  
      const start = (page - 1) * size;
      return filteredFactures.slice(start, start + size);
    }
  
    getTotalFactures(filter: string = '', status: string = ''): number {
      let filteredFactures = this.factures;
  
      if (filter) {
        filteredFactures = filteredFactures.filter(invoice => 
          invoice.ref.toLowerCase().includes(filter.toLowerCase()) ||
          invoice.clientdetails.name.toLowerCase().includes(filter.toLowerCase())
        );
      }
  
      if (status) {
        filteredFactures = filteredFactures.filter(invoice => invoice.status === status);
      }
  
      return filteredFactures.length;
    }
    getFacturealldata() {
        this.http.get(API_URL_cloud).subscribe(
          (factures: devismodel[]) => {
            if (factures) {
              this.factures = factures;
              this.emitFactures();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitFactures() {
        this.factures$.next(this.factures);
      }
      getfactureuser(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'byuser/'  ,{userId}).subscribe(
            (invo: devismodel[]) => {
              if (invo) {
                this.factures = invo;
                this.emitFactures();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        });
      }
      getfactureuserwithoutsubscription(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'byuser/'  ,{userId}).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getFacturedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(facture: devismodel, image: File[]) {
        return new Promise((resolve, reject) => {
          const factureData = new FormData();
          factureData.append('facture', JSON.stringify(facture));
          for (let i = 0; i < image.length; i++)
          {
            factureData.append('image', image[i],'logofacture'+facture.number+'user'+facture.userId); 
          }
          this.http.post(API_URL_cloud, factureData).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      update(factureId: string, facture: devismodel, image: File[]) {
        return new Promise((resolve, reject) => {
          const factureData = new FormData();
          factureData.append('facture', JSON.stringify(facture));
          for (let i = 0; i < image.length; i++) {
            factureData.append('image', image[i], 'logofacture' + facture.number + 'user' + facture.userId);
          }
          this.http.put(`${API_URL_cloud}/${factureId}`, factureData).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
    /*  modifyfactureById(id: string, facture: devismodel) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, facture).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }*/
      deletefacturedataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
