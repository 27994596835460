import { Component, OnInit } from '@angular/core';
import { CheckboxControlValueAccessor, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { Subscription } from 'rxjs';
import { MustMatch } from '../_helpers/must-match.validator';
import { AlertService } from '../_helpers/alert.service';
import Swal from 'sweetalert2';
import { read, utils } from "xlsx"
import { CommunService } from '../services/commun';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { OTPService } from '../services/otp-table.service';
import { ContactService } from '../services/contact.service';
import { Contact } from '../models/contact.model';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss']
})
export class ModifyUserComponent implements OnInit {
  cifUploaded = false;
  diUploaded = false;
  natsociete=false;
  tot=false
  sal=false
  mat=false
  nontot=false
  public userForm: FormGroup; 
  public isloggedin=false; 
  public currentuser: User;
  public user: User;
  public codeValue: string;
  public secteurValue: string;
  public roleValue: string;
  public imagePreview:string
  private usersSub: Subscription;
  public loading = false;
  public optionValue:any;
  public option1Value:any;
  public option2Value:any;
  public option3Value:any;
  public submitted=false;
  uploadFiles: File[] = [];
  fiscalmatPattern = "^[0-9]{7}$";
  fiscalmatletterPattern="^[A-Z]{1}$";
  fiscalmatnumbersPattern="^[0-9]{3}$";
  fiscalmatinchangedletterPattern = "^[A-Z]{1}$";
  fiscalmatsecondinchangedletterPattern = "^[A-Z]{1}$";

  codepostalPattern: "^[a-zA-Z0-9]+{4}$"

  errormsg:string;
  fiscaltvaassoblis:any[]=["Assujeti obligatoire", "Assujeti sur option", "Assujeti partiel", "Non assujeti", "Assujeti partiel sur option"];
  fiscaltvaassoblisassociation:any[]=["Assujeti obligatoire", "Assujeti sur option", "Assujeti partiel", "Non assujeti", "Assujeti partiel sur option"];
  fiscaltvaassoblisliberale:any[]=["Assujeti obligatoire"];
  natureactivites: any[]=["Profession Libérale","société","association et syndic","Commerçant","Artisan","Salarié ou Pensionnaire"];
  natureactivitesphysique: any[]=["Profession Libérale","Commerçant","Artisan","Salarié ou Pensionnaire"];
  natureactivitesmorale: any[]=["association et syndic","société"];
  activites: any[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
  "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire",/*"Huissiers  (de justice)"*/, "Interprètes",
  "Ingénieurs-conseil","Topographes","Syndic des copropriétaires","Ventes en gros","Ventes en détail","Pharmacie","Consommation sur place","Batiment et travaux annexes","Réparation automobiles","Autre"
  ,"Menuiserie","Commerciale","Industrielle","Service","services liées aux manifestations et cérémonies"];
  sousactivites: any[]=["Avocat","Avocat à la cour d'appel","Avocat à la cour de cassation","Médecin","Médecin spécialiste","Médecin dentiste","Médecin vétérinaire"
  ,"pièces auto","produits dives","étagères métalliques","Fruits secs","Pnematique","Café","Restaurant","Plomberie et climatisation","Electricité Batiment","Maçonnerie","Mécanique Auto","Tolerie"
  ,"Electricité Auto","Menuiserie Bois","Menuiserie Aluminium","Commerce international","Fabrication de produits chimiques","Activités informatiques","Consultant","Agence de voyage",
  "Agence de publicité","Information et promotion médicale et scientifique","Organisation des séminaires","Organisation des évènements","Construction métallique"];
  specialites: any[]=["Chirurgie générale",
  "Chirurgie pédiatrique",
  "Chirurgie carcinologique",
  "Chirurgie cardio-vasculaire",
  "Chirurgie vasculaire périphérique",
  "Chirurgie neurologique",
  "Chirurgie orthopédique et traumatologique",
  "Chirurgie plastique, réparatrice et esthétique",
  "Chirurgie urologique",
  "Gynéco-obstétrique",
  "ORL",
  "Stomatologie et chirurgie maxillo-faciale",
  "Ophtalmologie",
  "Chirurgie thoracique",
  "Anesthésie réanimation",
  "Psychiatrie",
  "Pédo-psychiatrie",
  "Imagerie médicale",
  "Anatomie",
  "Anatomie et cytologie pathologiques",
  "Carcinologie médicale",
  "Cardiologie",
  "Dermatologie",
  "Endocrinologie",
  "Gastro-entérologie",
  "Hématologie clinique",
  "Maladies infectieuses",
  "Médecine d’urgence",
  "Médecine de travail",
  "Médecine interne",
  "Médecine légale",
  "Médecine physique, rééducation et réadaptation fonctionnelle",
  "Médecine préventive et communautaire",
  "Néphrologie",
  "Neurologie",
  "Nutrition et maladies nutritionnelles",
  "Pédiatrie",
  "Pneumologie",
  "Radiothérapie carcinologique",
  "Réanimation médicale",
  "Rhumatologie",
  "Biophysique et médecine nucléaire",
  "Génétique",
  "Biologie médicale option biochimie",
  "Biologie médicale option hématologie",
  "Biologie médicale option parasitologie",
  "Biologie médicale option microbiologie",
  "Biologie médicale option immunologie",
  "Histo-embryologie",
  "Pharmacologie",
  "Physiologie et explorations fonctionnelles"];
  sousspecialites: any[]=[];
  sousactivitesavocat: string[]=["Avocat","Avocat à la cour d'appel","Avocat à la cour de cassation"];
  sousactivitesventeengros: string[]=["pièces auto","produits divers","étagères métalliques","Fruits secs","Pnematique","Produits de quincaillerie"];
  sousactivitesventeendetail: string[]=["pièces auto","produits divers","étagères métalliques","Fruits secs","Pnematique","Produits de quincaillerie"];
  sousactivitesconsommationsurplace: string[]=["Café","Restaurant"];
  sousactivitesbatimentettravauxannexe: string[]=["Plomberie et climatisation","Electricité Batiment","Maçonnerie"];
  sousactivitesreparationautomobile: string[]=["Mécanique Auto","Tolerie","Electricité Auto"];
  sousactivitesmenuiserie: string[]=["Menuiserie Bois","Menuiserie Aluminium"];
  sousactivitescommerciale: string[]=["Commerce international"];
  sousactivitesindustrielle: string[]=["Fabrication de produits chimiques","Construction métallique"];
  sousactivitesservice: string[]=["Consultant","Activités informatiques","Agence de voyage","Agence de publicité","Information et promotion médicale et scientifique","Organisation des séminaires","Organisation des évènements"];
  sousactivitesmedecin: string[]=["Médecin","Médecin spécialiste","Médecin dentiste","Médecin vétérinaire"];
  sousactivitesmanifestation: string[]=["photographe"];
  specialitesmedecinspecialiste: string[]=["Chirurgie générale",
  "Chirurgie pédiatrique",
  "Chirurgie carcinologique",
  "Chirurgie cardio-vasculaire",
  "Chirurgie vasculaire périphérique",
  "Chirurgie neurologique",
  "Chirurgie orthopédique et traumatologique",
  "Chirurgie plastique, réparatrice et esthétique",
  "Chirurgie urologique",
  "Gynéco-obstétrique",
  "ORL",
  "Stomatologie et chirurgie maxillo-faciale",
  "Ophtalmologie",
  "Chirurgie thoracique",
  "Anesthésie réanimation",
  "Psychiatrie",
  "Pédo-psychiatrie",
  "Imagerie médicale",
  "Anatomie",
  "Anatomie et cytologie pathologiques",
  "Carcinologie médicale",
  "Cardiologie",
  "Dermatologie",
  "Endocrinologie",
  "Gastro-entérologie",
  "Hématologie clinique",
  "Maladies infectieuses",
  "Médecine d’urgence",
  "Médecine de travail",
  "Médecine interne",
  "Médecine légale",
  "Médecine physique, rééducation et réadaptation fonctionnelle",
  "Médecine préventive et communautaire",
  "Néphrologie",
  "Neurologie",
  "Nutrition et maladies nutritionnelles",
  "Pédiatrie",
  "Pneumologie",
  "Radiothérapie carcinologique",
  "Réanimation médicale",
  "Rhumatologie",
  "Biophysique et médecine nucléaire",
  "Génétique",
  "Biologie médicale option biochimie",
  "Biologie médicale option hématologie",
  "Biologie médicale option parasitologie",
  "Biologie médicale option microbiologie",
  "Biologie médicale option immunologie",
  "Histo-embryologie",
  "Pharmacologie",
  "Physiologie et explorations fonctionnelles"];
  selected: any;
  activitesassociation: string[];
  activitescommercant: string[]=["Ventes en gros","Ventes en détail","Pharmacie","Consommation sur place"]
  activitesartisan: string[]=["Batiment et travaux annexes","Réparation automobiles","Menuiserie","services liées aux manifestations et cérémonies"]
  activitesassociations: any[]=["Syndic des copropriétaires"/*,"Association"*/];
  activitesliberales: string[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
  "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire",/*"Huissiers  (de justice)"*/, "Interprètes",
  "Ingénieurs-conseil","Topographes","Autre"];
  activitessociete: string[]=["Commerciale","Industrielle","Service"];
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  copform: FormGroup;
  public ammounts2: FormArray;
  file: any;
  uploadEvent: any;
  arrayBuffer: string | ArrayBuffer;
  exceljsondata2: unknown[]=[];
  firsttimer: number;
  emailverified=false
mobileverified=false
  selectedTabIndexsignaletique=0;
  resendemailotp=false
  resendmobileotp=false
  pdfSrc: string | ArrayBuffer | null = null;
  imageSrc: any;
  errorMessage: string;

  constructor(private formBuilder: FormBuilder,
   
    private userservice: UserService,
    private route: ActivatedRoute,private commun: CommunService,private deccompt: DeccomptabiliteService,private DecfiscmensService :DecfiscmensService,

    private router: Router,
    private auth: AuthService,
    private tokenStorage: TokenStorageService,private otp: OTPService,private cont: ContactService,
    private alertService: AlertService) {
      this.copform = this.formBuilder.group({
        ammounts2: this.formBuilder.array([ this.createammount2() ])
     });
    }

  ngOnInit() {
    this.loading = true;


     if (this.tokenStorage.getToken()){
      this.isloggedin=true;
      this.currentuser =this.tokenStorage.getUser()
      this.userservice.getUserById(this.currentuser.userId).then(
        (user: User) => {
          this.loading = false;
          this.user = user;
          this.user.exportateur?this.tot=true:this.tot=false
          this.user.salaire?this.sal=true:this.sal=false
          this.user.matfiscale&&this.user.activite||this.user.activite != 'Syndic des copropriétaires'&&this.user.activite?this.mat=true:this.mat=false
          this.deccompt.getdeccomptabilite(this.user._id)
          this.copform = new FormGroup({
            ammounts2: new FormArray(user.coproprietaires.map(item => 
              {
              const group = this.createammount2();
              //@ts-ignore
              group.patchValue(item);
              return group;
            }))
          });
          if(this.user.nature=="Personne Morale")
          {
            this.natureactivites=this.natureactivitesmorale
            /*this.activites=this.activitessociete
            this.sousactivites=this.sousactivitesservice*/
          }
          else if(this.user.nature=="Personne Physique")
          {
            this.natureactivites=this.natureactivitesphysique
            /*this.activites=this.activitessociete
            this.sousactivites=this.sousactivitesservice*/
          }
          if(this.user.natureactivite=='association et syndic')
          {
      this.activites=this.activitesassociations
      this.fiscaltvaassoblis=this.fiscaltvaassoblisassociation
          }
          else if (this.user.natureactivite=='Profession Libérale')
          {
            this.activites=this.activitesliberales
            this.fiscaltvaassoblis=this.fiscaltvaassoblisliberale
      
          }
          else if (this.user.natureactivite=='société')
          {
            this.activites=this.activitessociete
            this.natsociete=true
          }
          if (this.user.activite == 'Avocat') {
            this.sousactivites = this.sousactivitesavocat;
          } else if (this.user.activite == 'Médecin') {
            this.sousactivites = this.sousactivitesmedecin;
          } else if (this.user.activite == 'Ventes en gros') {
            this.sousactivites = this.sousactivitesventeengros;
          } else if (this.user.activite == 'Ventes en détail') {
            this.sousactivites = this.sousactivitesventeendetail;
          } else if (this.user.activite == 'Consommation sur place') {
            this.sousactivites = this.sousactivitesconsommationsurplace;
          } else if (this.user.activite == 'Batiment et travaux annexes') {
            this.sousactivites = this.sousactivitesbatimentettravauxannexe;
          } else if (this.user.activite == 'Réparation automobiles') {
            this.sousactivites = this.sousactivitesreparationautomobile;
          } else if (this.user.activite == 'Menuiserie') {
            this.sousactivites = this.sousactivitesmenuiserie;
          } else if (this.user.activite == 'Commerciale') {
            this.sousactivites = this.sousactivitescommerciale;
          } else if (this.user.activite == 'Industrielle') {
            this.sousactivites = this.sousactivitesindustrielle;
          } else if (this.user.activite == 'Service') {
            this.sousactivites = this.sousactivitesservice;
          }
          else if (this.user.activite == 'services liées aux manifestations et cérémonies') {
            this.sousactivites = this.sousactivitesmanifestation;
          } 
          else {
            this.sousactivites = [];  // Default to empty if no matching activite found
          }
          if(this.user.sousactivite!="Médecin spécialiste")
          {
            this.specialites=[]
          }
    this.optionValue=this.user.natureactivite;
    this.option1Value=this.user.activite;
    this.option2Value=this.user.sousactivite;
    this.option3Value=this.user.regimefiscalimpot;
    this.loading = false;
    this.userForm = this.formBuilder.group({          
            role: [this.user.role,],
            firstname: [this.user.firstname,],
            lastname: [this.user.lastname,],
            export: [this.user.exportateur,],
            salaire: [this.user.salaire,],
            matfiscale: [this.user.matfiscale,],
            confirmemail: [null],
            mobile: [this.user.mobile,],
            confirmmobile: [null],
            usertype: [this.user.usertype,],
            email: [this.user.email,],
            fonction: [this.user.fonction,],
            password: [null,],
            confirmpassword: [null,],
            secteur: [this.user.secteur,],
            civilite: [this.user.civilite,],
            nature: [this.user.nature,],
            raisonsociale: [this.user.raisonsociale,],
            image: [this.user.ficheUrl||'',],
            image2: [this.user.ficheUrl2||'',],
            image3: [this.user.ficheUrl3||'',],
            image4: [this.user.ficheUrl4||'',],
            image5: [this.user.ficheUrl5||'',],

            activitynature: [this.user.natureactivite],
            selectactivitynature: [null,],
            activity: [this.user.activite,],
            selectactivity:[null,],
            underactivity: [this.user.sousactivite,],
            selectunderactivity:[null,],
            specialite: [this.user.specialite,],
            sousspecialite: [this.user.sousspecialite,],
            fiscalimpot: [this.user.regimefiscalimpot,],
            selectfiscalimpot:[null,],
            fiscaltvaassobli: ["Assujeti Obligatoire"],
            fiscalmat:this.user.matriculefiscale?[this.user.matriculefiscale.split(' ')[0],[Validators.pattern(this.fiscalmatPattern),Validators.maxLength(7),Validators.required]]
            :['',[Validators.pattern(this.fiscalmatPattern),Validators.maxLength(7)]],
            fiscalmatletter:this.user.matriculefiscale? [this.user.matriculefiscale.split(' ')[1].split('/')[0],[Validators.pattern(this.fiscalmatletterPattern),Validators.maxLength(1),Validators.required]]
            :['',[Validators.pattern(this.fiscalmatletterPattern),Validators.maxLength(1)]],
            fiscalmatinchanged:this.user.matriculefiscale?[this.user.matriculefiscale.split(' ')[1].split('/')[1],[Validators.pattern(this.fiscalmatinchangedletterPattern),Validators.maxLength(1),Validators.required]]:['',[Validators.pattern(this.fiscalmatinchangedletterPattern),Validators.maxLength(1)]],
            fiscalmatinchanged2:this.user.matriculefiscale?[this.user.matriculefiscale.split(' ')[1].split('/')[2],[Validators.pattern(this.fiscalmatsecondinchangedletterPattern),Validators.maxLength(1),Validators.required]]:['',[Validators.pattern(this.fiscalmatsecondinchangedletterPattern),Validators.maxLength(1)]],
            fiscalmatnumbers:this.user.matriculefiscale? [this.user.matriculefiscale.split('/')[3],[Validators.pattern(this.fiscalmatnumbersPattern),Validators.maxLength(3),Validators.required]]
            :['',[Validators.pattern(this.fiscalmatnumbersPattern),Validators.maxLength(3)]],
            adresseactivite: [this.user.adresseactivite,],
            dateeffet: [this.user.dateeffet],
            codepostal:[this.user.codepostal,[Validators.maxLength(4)]],
            nomsociete: [this.user.nomsociete,],
            clientcode: [{value:this.user.clientcode,disabled:true}],
            regimehoraire: [this.user.regimehoraire,],
            tauxaccidenttravail: [this.user.tauxaccidenttravail,],
            matriculecnss: [this.user.matriculecnss,],
            tauxchargepatronale: [this.user.tauxchargepatronale,],
          })          
        }
      )}  
            else {this.router.navigate(['login'])};
          }          
          get f() { return this.userForm.controls; } 

          onTabChangesdonnessignaletique(event: any) {
            this.selectedTabIndexsignaletique = event.index;
          }
  onSubmit() {
    this.loading = true;
    this.submitted = true;
    let actifdeccomptable=[]  
  this.deccompt.deccomptabilites?actifdeccomptable=this.deccompt.deccomptabilites.filter(e=>!e.source):actifdeccomptable=[]  
  if (this.userForm.invalid) {
      
     return this.loading = false;
  }
    this.alertService.clear();
    const user = new User();
    user.coproprietaires=[]
    user.userId = this.currentuser.userId;
    user.role = this.userForm.get('role').value;
    user.email = this.userForm.get('email').value;
    user.password =this.userForm.get('password').value;
    user.mobile = this.userForm.get('mobile').value;
    user.usertype =this.userForm.get('usertype').value;
    user.confirmpassword =this.userForm.get('confirmpassword').value;
    user.firstname = this.userForm.get('firstname').value;
    user.lastname = this.userForm.get('lastname').value;
    user.fonction = this.userForm.get('fonction').value;
    user.matriculecnss = this.userForm.get('matriculecnss').value;
    user.tauxchargepatronale = this.userForm.get('tauxchargepatronale').value;
    user.tauxaccidenttravail = this.userForm.get('tauxaccidenttravail').value;
    user.regimehoraire = this.userForm.get('regimehoraire').value;
    user.exportateur = this.userForm.get('export').value;
    user.salaire = this.userForm.get('salaire').value;
    user.matfiscale = this.userForm.get('matfiscale').value;

    user.ficheUrl=this.user.ficheUrl
    user.ficheUrl2=this.user.ficheUrl2
    user.ficheUrl3=this.user.ficheUrl3
    user.ficheUrl4=this.user.ficheUrl4
    user.ficheUrl5=this.user.ficheUrl5

    if (this.userForm.get('activitynature').value=="Autre") { user.natureactivite = this.userForm.get('activitynature').value+'/'+this.userForm.get('selectactivitynature').value}
    else  {user.natureactivite = this.userForm.get('activitynature').value};
    if (this.userForm.get('activity').value=="Autre") {user.activite = this.userForm.get('activity').value+'/'+this.userForm.get('selectactivity').value}
    else {user.activite =this.userForm.get('activity').value};
    if (this.userForm.get('underactivity').value=="Autre") {user.sousactivite = this.userForm.get('underactivity').value+'/'+this.userForm.get('selectunderactivity').value}
    else {user.sousactivite =this.userForm.get('underactivity').value};
    if (this.userForm.get('fiscalimpot').value=="Autre") 
    { user.regimefiscalimpot = this.userForm.get('fiscalimpot').value+'/'+this.userForm.get('selectfiscalimpot').value}
    else {user.regimefiscalimpot = this.userForm.get('fiscalimpot').value};
    user.matriculefiscale = this.mat?this.userForm.get('fiscalmat').value+' '+this.userForm.get('fiscalmatletter').value+'/'+this.userForm.get('fiscalmatinchanged').value+'/'
    +this.userForm.get('fiscalmatinchanged2').value+'/'+this.userForm.get('fiscalmatnumbers').value:'';
    user.regimefiscaltva = this.userForm.get('fiscaltvaassobli').value;
    user.adresseactivite = this.userForm.get('adresseactivite').value;
    user.codepostal = this.userForm.get('codepostal').value;
    user.regimefiscaltva = this.userForm.get('fiscaltvaassobli').value;
    user.specialite = this.userForm.get('specialite').value;
    user.sousspecialite = this.userForm.get('sousspecialite').value;
    user.adresseactivite = this.userForm.get('adresseactivite').value;
    user.dateeffet = this.userForm.get('dateeffet').value;
    user.codepostal = this.userForm.get('codepostal').value;
    user.secteur = this.userForm.get('secteur').value;
    user.civilite = this.userForm.get('civilite').value;
    user.nature = this.userForm.get('nature').value;
    user.raisonsociale = this.userForm.get('raisonsociale').value;
    user.nomsociete = this.userForm.get('nomsociete').value;
    user.clientcode = this.userForm.get('clientcode').value;
    user.coproprietaires=this.copform.get('ammounts2').value
    user.coproprietaires=user.coproprietaires.filter(item => (item.numero!='0'&&item.numero!=''&&item.numero!=null));
    user.emailverified=this.emailverified
    user.mobileverified=this.mobileverified
    if(actifdeccomptable.length==0||this.user.coproprietaires.length==0)
    {
      this.userservice.modifyUserwithimageById(this.user._id, user, this.uploadFiles).then(  
        () => {
         this.userForm.reset();
          this.loading = false;
          
          this.router.navigate(['profil'])
          
          
          
        },
        (error) => {
          this.loading = false
          
        }
      );
    }
    else
    {
   
      let differentcop=[]
      user.coproprietaires.forEach(
        (cop:any)=>
        {
          !this.user.coproprietaires.find(el=>el.numero==cop.numero)?differentcop.push({numero:cop}):''
        }
      )
      console.log(differentcop.length)
    if(differentcop.length>0)
          {
            this.commun.getcurrenttime().then(
              async (time:any) => {
                Swal.fire({
                  title: 'vous êtes sur le point de modifier tous les fichiers comptable de l\'année'+`${new Date().getFullYear()}`,
                  icon: 'info',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#555',
                  confirmButtonText: 'Accepter et continuer',
                  cancelButtonText: 'Annuler',      
                  }).then(async (result) => {
                  if (result.isConfirmed) {
                    this.loading=true
                    for await (const element of actifdeccomptable) {
                      this.DecfiscmensService.geexistenttdecfiscmens(this.user._id,element.annee,element.mois,'').then(
                        (data:Decfiscmens[]) => {
                          console.log(data)
                         if (data.length>0)
                       {
                        this.user.role=='admin'||this.user.role=='supervisor'?data[0].statutcollab.push
                            //@ts-ignore
                            ({
                              statutclient:'déposé pour le compte du client',
                              motifclient:'',
                              datefin:time,
                               duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                            }):
                            data[0].statutcollab.push
                            //@ts-ignore
                            ({
                              statutclient:'déposé par le client',
                              motifclient:'',
                              datefin:time,
                              duree:'',     
                            })
                        data[0].affecte = ''
                        this.DecfiscmensService.completedecfiscmensreqById(data[0]._id,data[0]).then(
                          (data:any) => {
                          this.tokenStorage.saved=true;
                          this.loading = false;
                          this.user.role=='admin'||this.user.role=='supervisor'?element.statutcollab.push
                          //@ts-ignore
                          ({
                            statutclient:'déposé pour le compte du client',
                            motifclient:'',
                            datefin:time,
                             duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                          }):
                          element.statutcollab.push
                          //@ts-ignore
                          ({
                            statutclient:'déposé par le client',
                            motifclient:'',
                            datefin:time,
                            duree:'',     
                          })
                           element.affecte=''
                          this.deccompt.completedeccomptabilitereqById(element._id,element).then(
                            (data:any) => {
                              this.tokenStorage.saved=true;
                              this.loading = false;
                              //this.router.navigate(['user-board']);
                            },
                            (error) => {
                              this.loading = false                      
                            }
                          )
                          //this.router.navigate(['modify-decfiscmens/'+data.data._id])
                          },
                          (error) => {
                          this.loading = false;
                          
                          }
                          )                  
                        }
                          else
                          {
                            this.user.role=='admin'||this.user.role=='supervisor'?element.statutcollab.push
                            //@ts-ignore
                            ({
                              statutclient:'déposé pour le compte du client',
                              motifclient:'',
                              datefin:time,
                               duree:Math.floor((Date.now()-this.firsttimer) / 1000),     
                            }):
                            element.statutcollab.push
                            //@ts-ignore
                            ({
                              statutclient:'déposé par le client',
                              motifclient:'',
                              datefin:time,
                              duree:'',     
                            })
                             element.affecte=''
                            this.deccompt.completedeccomptabilitereqById(element._id,element).then(
                              (data:any) => {
                                this.tokenStorage.saved=true;
                                this.loading = false;
                                //this.router.navigate(['user-board']);
                              },
                              (error) => {
                                this.loading = false                      
                              }
                            )                    
                          }
                            }
                      )  
                              };  
                              this.userservice.modifyUserwithimageById(this.user._id, user, this.uploadFiles).then(  
                                () => {
                                 this.userForm.reset();
                                  this.loading = false;
                                 
                                  this.router.navigate(['profil'])
                                  
                                  
                                  
                                },
                                (error) => {
                                  this.loading = false
                                  
                                }
                              );                   
                  }
                  else
                  {
                    return(
                      user.coproprietaires=[],
this.loading=false        
            )
                    
                   
                  }
            
                  
                  })
              }
            )
        }
        else
        {
          this.userservice.modifyUserwithimageById(this.user._id, user, this.uploadFiles).then(  
            () => {
             this.userForm.reset();
              this.loading = false;
              
              this.router.navigate(['profil'])
              
              
              
            },
            (error) => {
              this.loading = false
              
            }
          );  
        }
    
    }


  }
  async emailconfirmwithotp() {
    try {
      // Récupérer l'email de l'utilisateur depuis le formulaire
      const email = this.userForm.get('email').value;

      // Vérifier si un utilisateur existe avec l'email fourni
      const data: any = await this.userservice.getUserByemail(email);
      console.log(data);

      // Si l'utilisateur existe
      if (data.length > 0) {
        // Demander à l'utilisateur de confirmer l'envoi de l'OTP
        const { value: confirmSendOtp } = await Swal.fire({
          title: 'Utilisateur trouvé',
          text: 'Un utilisateur avec cet email existe déjà. Nous allons vous envoyé un OTP pour confirmer que vous êtes le propriétaire de cette adresse email',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui, envoyer l\'OTP',
          cancelButtonText: 'Non, annuler'
        });

        // Si l'utilisateur confirme l'envoi de l'OTP
        if (confirmSendOtp) {
          // Créer un OTP
          const res: any = await this.otp.createwithoutfile();

          // Envoyer l'OTP à l'admin
          const contactAdmin = new Contact();
          contactAdmin.email = 'macompta@macompta.com.tn';
          contactAdmin.emailenvoyea = 'f.benayed@macompta.com.tn';
          contactAdmin.description = `code de vérification : ${res.data.otp.value}`;
          contactAdmin.typeemail = "OTP";
          await this.cont.comunicatewithuser(contactAdmin);

          // Envoyer l'OTP à l'utilisateur
          const contactUser = new Contact();
          contactUser.email = 'macompta@macompta.com.tn';
          contactUser.emailenvoyea = email;
          contactUser.description = `code de vérification : ${res.data.otp.value}`;
          contactUser.typeemail = "OTP";
          await this.cont.comunicatewithuser(contactUser);

          // Demander à l'utilisateur de saisir l'OTP
          const { value: otpCode } = await Swal.fire({
            title: 'Confirmation d\'identité',
            html: 'Veuillez saisir le code envoyé par email, il va expirer après 1 minute',
            input: 'text',
            inputLabel: 'Code',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Vous devez renseigner une valeur!';
              }
            }
          });

          // Valider l'OTP
          try {
            await this.otp.validateotp(otpCode);
            this.emailverified = true;
            this.resendemailotp=false
            await Swal.fire({
              title: 'OTP valide',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          } catch {
            this.resendemailotp=true
            await Swal.fire({
              title: 'OTP erroné',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
            this.userForm.patchValue({ email: null });
          }
        }
else{
  this.resendemailotp=true
}
      }
    } catch (error) {
      console.error('Erreur lors de la confirmation de l\'email avec OTP:', error);
      this.loading = false;
    }
  }
  async mobileconfirmwithotp() {
    try {
      // Récupérer le mobile de l'utilisateur depuis le formulaire
      const mobile = this.userForm.get('mobile').value;

      // Vérifier si un utilisateur existe avec le mobile fourni
      const datauser: any = await this.userservice.getUserBymobile(mobile);
      console.log(datauser);

      // Si l'utilisateur existe
      if (datauser) {
        // Demander à l'utilisateur de confirmer l'envoi de l'OTP
        const { value: confirmSendOtp } = await Swal.fire({
          title: 'Utilisateur trouvé',
          text: 'Un utilisateur avec ce numéro de mobile existe déjà. Nous allons vous envoyé un OTP pour confirmer que vous êtes le propriétaire de cette adresse email',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui, envoyer l\'OTP',
          cancelButtonText: 'Non, annuler'
        });

        // Si l'utilisateur confirme l'envoi de l'OTP
        if (confirmSendOtp) 
        {
          // Créer un OTP
          const res: any = await this.otp.createwithoutfile();

          // Envoyer l'OTP à l'admin
          const useradmin = {
            _id: '63c6a638b550e8e10edff18c',
            mobile: '21698350575'
          };
          const messageadmin = `code de vérification : ${res.data.otp.value}`;
          await this.userservice.comunicatewithusersms(useradmin, messageadmin);

          // Envoyer l'OTP à l'utilisateur
          const user = datauser;
          const message = `code de vérification : ${res.data.otp.value}`;
          await this.userservice.comunicatewithusersms(user, message);

          // Demander à l'utilisateur de saisir l'OTP
          const { value: numero } = await Swal.fire({
            title: 'Confirmation d\'identité',
            html: 'Veuillez saisir le code envoyé par SMS, il va expirer après 1 minute',
            input: 'text',
            inputLabel: 'Code',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Vous devez renseigner une valeur!';
              }
            }
          });

          // Valider l'OTP
          try {
            await this.otp.validateotp(numero);
            this.mobileverified = true;
            this.resendmobileotp=false
            await Swal.fire({
              title: 'OTP valide',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          } catch {
            await Swal.fire({
              title: 'OTP erroné',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
            this.resendmobileotp=true
            this.userForm.patchValue({ mobile: null });
          }
        }
else
{
  this.resendmobileotp=true
}
      } 
    } catch (error) {
      console.error('Erreur lors de la confirmation du mobile avec OTP:', error);
      this.loading = false;
    }
  }
  update1(e)
  {
    this.selected = e.value
    console.log(this.selected)
    if(this.selected=='Personne Physique')
    {
      this.natureactivites=this.natureactivitesphysique
      this.natsociete=false
      this.userForm.patchValue({
        export: false,
        salaire: false,

      })
      this.tot=false
      this.sal=false
        }
    else  if(this.selected=='Personne Morale')
    {
      this.natureactivites=this.natureactivitesmorale
      this.userForm.get('fiscalmatinchanged').enable()
      this.userForm.get('fiscalmatinchanged2').enable()
    }
    this.userForm.patchValue({
      activitynature: '',
      selectactivitynature:'',
      activity:'',
      selectactivity:'',
      underactivity:'',
      selectunderactivity:'',
      specialite: '',
      sousspecialite: '',
    })
  }
  update0(e)
  {

    this.selected = e.value
/*natureactivites=["Profession Libérale","société","association et syndic","Commerçant","Artisan","Salarié ou Pensionnaire"];*/

    if(this.selected=='association et syndic')
    {
this.natsociete=false
this.activites=this.activitesassociations
this.fiscaltvaassoblis=this.fiscaltvaassoblisassociation
this.userForm.patchValue({
  fiscalmatinchanged:'N',
  fiscalmatinchanged2:'N',
  fiscalmatnumbers:'000',
  export:false,
  salaire:false
})
this.tot=false
this.sal=false
    }
    else if (this.selected=='société'&&this.user.nature=="Personne Morale")
    {
      this.activites=this.activitessociete
      this.natsociete=true
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'M',
        fiscalmatnumbers:'000'
      })
    }
    else if (this.selected=='Profession Libérale')
    {
      this.natsociete=false
      this.activites=this.activitesliberales
      this.fiscaltvaassoblis=this.fiscaltvaassoblisliberale
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'P',
        fiscalmatnumbers:'000'
      })
      this.tot=false
      this.sal=false
    }
    else if (this.selected=='Commerçant')
    {
      this.natsociete=false
      this.activites=this.activitescommercant
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'C',
        fiscalmatnumbers:'000'
      })
    }
    else if (this.selected=='Artisan')
    {
      this.natsociete=false
      this.activites=this.activitesartisan
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'C',
        fiscalmatnumbers:'000'
      })
    }

    else{
      this.activites=[]
      this.userForm.get('fiscalmatinchanged').enable()
      this.userForm.get('fiscalmatinchanged2').enable()
      this.natsociete=false
    }
    this.userForm.patchValue({
      activity:'',
      selectactivity:'',
      underactivity:'',
      selectunderactivity:'',
      specialite: '',
      sousspecialite: '',
    })
  }
  update(e)
  {
    this.selected = e.value
   /* activites: any[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
    "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire","Huissiers notaire (de justice)", "Interprètes",
    "Ingénieurs-conseil","Topographes","Syndic des copropriétaires","Ventes en gros","Ventes en détail","Consommation sur place","Batiment et travaux annexes","Réparation automobiles","Autre"
  ,"Menuiserie","Commerciale","Industrielle","Service"];*/
    if(this.userForm.get('activity').value=='Syndic des copropriétaires')
{
  this.userForm.patchValue({
    fiscalmatinchanged:'N',
    fiscalmatinchanged2:'N',
    fiscalmatnumbers:'000'
  })
}
    if(this.selected=='Avocat')
    {
this.sousactivites=this.sousactivitesavocat
    }
    else if (this.selected=='Médecin')
    {
      this.sousactivites=this.sousactivitesmedecin

    }
    else if (this.selected=='Consommation sur place')
    {
      this.sousactivites=this.sousactivitesconsommationsurplace

    }
    else if (this.selected=='Ventes en détail')
    {
      this.sousactivites=this.sousactivitesventeendetail

    }
    else if (this.selected=='Ventes en gros')
    {
      this.sousactivites=this.sousactivitesventeengros
    }
    else if (this.selected=='Batiment et travaux annexes')
    {
      this.sousactivites=this.sousactivitesbatimentettravauxannexe
    }
    else if (this.selected=='Menuiserie')
    {
      this.sousactivites=this.sousactivitesmenuiserie
    }
    else if (this.selected=='Réparation automobiles')
    {
      this.sousactivites=this.sousactivitesreparationautomobile
    }
    else if (this.selected=='Service')
    {
      this.sousactivites=this.sousactivitesservice

    }
    else if (this.selected=='Commerciale')
    {
      this.sousactivites=this.sousactivitescommerciale

    }
    else if (this.selected=='Industrielle')
    {
      this.sousactivites=this.sousactivitesindustrielle

    }
    else if (this.selected=='services liées aux manifestations et cérémonies')
    {
      this.sousactivites=this.sousactivitesmanifestation

    }
    else{
      this.sousactivites=[]
      this.specialites=[]
      this.sousspecialites=[]
    }
    this.userForm.patchValue({
      underactivity:'',
      selectunderactivity:'',
      specialite: '',
      sousspecialite: '',
    })
  }
  update2(e)
  {
    this.selected = e.value
    
    if(this.selected=='Médecin spécialiste')
    {
this.specialites=this.specialitesmedecinspecialiste
    }
   
    else{
      this.specialites=[]
      this.sousspecialites=[]

    }
    this.userForm.patchValue({
      specialite: '',
      sousspecialite: '',
    })
  }
  update3(e)
  {
  }
  sort()
  {
    this.specialites.sort()
    this.sousactivites.sort()
    this.activites.sort()
  }
  onImagePick(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadFiles=this.uploadFiles.filter(e=>e.name!='cif')
    if (file)
    {
      const myRenamedFile = new File([file],'cif', {
        type: "application/pdf",
      });
      this.userForm.patchValue({ image: myRenamedFile });  
      this.userForm.updateValueAndValidity();         
  this.uploadFiles.push(this.userForm.get('image').value);
    } 
    const reader = new FileReader();
    reader.onload = () => {
      if (this.userForm.valid) {
        this.cifUploaded = true;
      } else {
      }
    };
  }
  onImagePick2(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadFiles=this.uploadFiles.filter(e=>e.name!='di')
    if (file)
    {
      const myRenamedFile = new File([file],'di', {
        type: "application/pdf",
      });
      this.userForm.patchValue({ image2: myRenamedFile });  
      this.userForm.updateValueAndValidity();         
  this.uploadFiles.push(this.userForm.get('image2').value);
    } 
    const reader = new FileReader();
    reader.onload = () => {
      if (this.userForm.valid) {
        this.diUploaded = true;
      } else {
      }
    };
  }
  onImagePick5(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadFiles = this.uploadFiles.filter(e => e.name != 'logo');
    this.errorMessage = null;
  
    if (file) {
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 512) {
        this.errorMessage = 'File size should be less than 512 KB';
        return;
      }
  
      let fileType = file.type;
      let fileExtension = this.commun.MIME_TYPES[fileType];
  
      if (fileType === 'application/octet-stream') {
        const extension = file.name.split('.').pop()?.toLowerCase();
        fileExtension = extension ? this.commun.MIME_TYPES[`image/${extension}`] || extension : 'octet-stream';
        if (fileExtension !== 'octet-stream') {
          fileType = `image/${fileExtension}`;
        }
      }
  
      if (!fileExtension || !['jpg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(fileExtension)) {
        this.errorMessage = 'Invalid file type. Please select a PDF, PNG, JPG, DOC, DOCX, PPT, PPTX, XLS, or XLSX file.';
        return;
      }
  console.log(fileType)
      const myRenamedFile = new File([file], 'logo', { type: fileType });
      this.userForm.patchValue({ image5: myRenamedFile });
      this.userForm.updateValueAndValidity();
      this.uploadFiles.push(this.userForm.get('image5').value);
  
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (fileExtension === 'pdf') {
          this.pdfSrc = e.target.result;
          this.imageSrc = null;
        } else if (['jpg', 'png'].includes(fileExtension)) {
          this.imageSrc = e.target.result;
          this.pdfSrc = null;
        } else {
          this.imageSrc = null;
          this.pdfSrc = null;
        }
      };
      reader.readAsDataURL(file);
    }
  }
  
  
  
  reloadPage (){
    setTimeout(() => window.location.reload(), 3000);
    
    
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  togglerepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  get ammountControls2() {
    return this.copform.get('ammounts2')['controls'];
  }
  createammount2() 
  : FormGroup {
    
    return  this.formBuilder.group({
      numero:'',
      coproprietaire: '',
      donneescomptable: [],
      
    });
  }
  addammount2(i: number): void {
    this.ammounts2 = this.copform.get('ammounts2') as FormArray;
    this.ammounts2.insert(i+1,this.createammount2());
  }
  removeammount2(i: number) {
    this.ammounts2 = this.copform.get('ammounts2') as FormArray;
    this.ammounts2.removeAt(i);
  }
  check(i:number)
  {

  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.uploadEvent = event;
    }
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      //@ts-ignore
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = read(bstr, {
        type: "binary"
      });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.exceljsondata2 = utils.sheet_to_json(worksheet, {
        raw: true,
        defval: "",
      });
      this.copform = new FormGroup({                
        ammounts2: new FormArray(this.exceljsondata2.map(item => {
          const group = this.createammount2();
          //@ts-ignore
          group.patchValue(
            {
                        //@ts-ignore
              numero:item.numero,
                        //@ts-ignore
              coproprietaire:item.coproprietaire
            }); 
            return group;
        }))
      })    
    };
    fileReader.readAsArrayBuffer(this.file);
  }
}
