import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Recouvrementnotif } from '../models/notification-recouvrement.model';
const API_URL_test = 'http://localhost:3003/api/notificationrec/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/notificationrec/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class Notificationrec {  
    constructor(private http: HttpClient) { }
    public notificationrecs: Recouvrementnotif[] = [];
    public notificationrecs$ = new Subject<Recouvrementnotif[]>();
    getallnotificationrec() 
    {
        this.http.get(API_URL_cloud).subscribe(
          (notificationrecs: Recouvrementnotif[]) => {
            if (notificationrecs) {
              this.notificationrecs = notificationrecs;
              this.emitnotificationrecs();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitnotificationrecs() {
        this.notificationrecs$.next(this.notificationrecs);
      }
      getnotificationrecById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getnotificationrecsByuser(client: string) {
        this.http.get(API_URL_cloud+'client/'+client).subscribe(
            (notificationrecs: Recouvrementnotif[]) => {
              if (notificationrecs) {
                this.notificationrecs = notificationrecs;
                this.emitnotificationrecs();
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
      getnotificationrecswithoutsubscription(client: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud+'client/'+client).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(notificationrec: Recouvrementnotif) 
      {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, notificationrec).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      modifynotificationrecById(id: string, notificationrec: Recouvrementnotif) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, notificationrec).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      deleteotherdsdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
