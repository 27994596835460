import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import { AlertService } from '../_helpers/alert.service';
import { User } from '../models/user.model';
import { ExcelService } from '../services/excel.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {
  loading=false;
  user:User;
  civilite:any
  errormsg:string;
  currentUser: any;
  specialite: string;
  constructor(private token: TokenStorageService,private router: Router,private route: ActivatedRoute,private alertService: AlertService,
    private usersservice: UserService,private sanitizer: DomSanitizer,
    private excelService:ExcelService) { }
  ngOnInit(): void {
    this.currentUser = this.token.getUser();
    this.usersservice.getUserById(this.currentUser.userId).then(
      (user: User) => {
        this.loading = false;
        this.user = user;
        this.civilite=this.user.civilite
      });
  }
  get userDetails() {
    return [
      { imgSrc: 'assets/female.png', altText: 'Madame', label: 'Civilité', value: this.user.civilite, isDate: false },
      { imgSrc: 'assets/user-type.png', altText: 'Nature utilisateur', label: 'Nature utilisateur', value: this.user.nature, isDate: false },
      { imgSrc: 'assets/created-at.png', altText: 'Créé le(heure GMT)', label: 'Créé le(heure GMT)', value: this.user.created, isDate: true },
      { imgSrc: 'assets/last-modified.png', altText: 'Dernière modification', label: 'Dernière modification(heure GMT)', value: this.user.updated, isDate: true },
      { imgSrc: 'assets/phone.png', altText: 'Numéro mobile', label: 'Numéro mobile', value: this.user.mobile, isDate: false },
      { imgSrc: 'assets/utilisateur.png', altText: 'Type utilisateur', label: 'Type utilisateur', value: this.user.usertype, isDate: false },
      { imgSrc: 'assets/company.png', altText: 'Raison sociale', label: 'Raison sociale', value: this.user.raisonsociale, isDate: false },
      { imgSrc: 'assets/email.png', altText: 'Email', label: 'Email', value: this.user.email, isDate: false },
      { imgSrc: 'assets/client-code.png', altText: 'Code client', label: 'Code client', value: this.user.clientcode, isDate: false },
      { imgSrc: 'assets/activity.png', altText: 'Nature Activité', label: 'Nature Activité', value: this.user.natureactivite, isDate: false },
      { imgSrc: 'assets/sub-activity.png', altText: 'Sous-Activité', label: 'Sous-Activité', value: this.user.sousactivite, isDate: false },
      { imgSrc: 'assets/activity.png', altText: 'Spécialité', label: 'Spécialité', value: this.user.specialite, isDate: false },
      { imgSrc: 'assets/activity.png', altText: 'Sous-Spécialité', label: 'Sous-Spécialité', value: this.user.sousspecialite, isDate: false },
      { imgSrc: 'assets/exporter.png', altText: 'Totalement exportateur', label: 'Totalement exportateur', value: this.user.exportateur ? 'Oui' : 'Non', isDate: false },
      { imgSrc: 'assets/Activite.png', altText: 'Activité', label: 'Activité', value: this.user.activite, isDate: false },
      { imgSrc: 'assets/fiscal-id.png', altText: 'Carte d\'identification fiscale', label: 'Carte d\'identification fiscale', value: this.user.ficheUrl,title:'Apercu CIF', isDate: false },
      { imgSrc: 'assets/investment.png', altText: 'Déclaration d\'investissement', label: 'Déclaration d\'investissement', value: this.user.ficheUrl2,title:'Apercu DI', isDate: false },
      { imgSrc: 'assets/tax.png', altText: 'Régime fiscal en matières d\'impôts directs', label: 'Régime fiscal en matières d\'impôts directs', value: this.user.regimefiscalimpot, isDate: false },
      { imgSrc: 'assets/adresse.png', altText: 'Adresse D\'activité', label: 'Adresse D\'activité', value: this.user.adresseactivite, isDate: false },
      { imgSrc: 'assets/tax.png', altText: 'Régime fiscal en matières de TVA', label: 'Régime fiscal en matières de TVA', value: this.user.regimefiscaltva, isDate: false },
      { imgSrc: 'assets/date-effet.png', altText: 'Date d\'effet', label: 'Date d\'effet', value: this.user.dateeffet, isDate: true },
      { imgSrc: 'assets/postal-code.png', altText: 'Code Postal', label: 'Code Postal', value: this.user.codepostal, isDate: false },
      { imgSrc: 'assets/utilisateur.png', altText: 'LOGO', label: 'Carte d\'identification fiscale', value: this.user.ficheUrl5,title:'Apercu logo', isDate: false }

    ];
  }
  getSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  get isPersonneMorale() {
    return this.user.nature === 'Personne Morale';
  }
  getNavigation(link, id){
      
    this.router.navigate([link + '/' + id]);
      
  }
  onDelete() {
  this.loading = true;
  
    
      this.usersservice.deleteUserById(this.currentUser.userId).then(
        (data:any) => {
          
          this.loading = false;
          this.alertService.success(data.message+'veuillez se déconnecter pour voir les changements');
          window.scrollTo(0, 0);
        },
        (error) => {
          this.loading = false;
          this.alertService.error(error.error.error);
          window.scrollTo(0, 0);
        }
      )
      

        
    
  
}
exportusersAsXLSX(source:any[],name:string):void {
  console.log(source)
  let exportedsource=[]
  source.forEach(element=>
    {
element.donneescomptable?element.donneescomptable.forEach(e => {
  exportedsource.push(
    {
      numero : element.numero , 
      coproprietaire: element.coproprietaire, 
      annee:e.annee,
      periodicité: e.periodicite,
      montant:e.montant
    }
  )
}):
exportedsource.push(
  {
    numero : element.numero , 
    coproprietaire: element.coproprietaire, 
    annee:'',
    periodicité:'',
    montant:''
  }
)
    }
  )
  this.excelService.exportAsExcel(exportedsource, name);
}
reloadPage(): void {
    
  window.location.reload();
  
}
}
