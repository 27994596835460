

  <body>

    <h2>Informations générales</h2>
    
    <div class="card" *ngIf="user">
      <img src="../../assets/img_avatar.png" *ngIf="user.civilite=='Monsieur'" else alt="Avatar" style="width:10% ;height:10%">
      <img src="../../assets/img_avatar2.png" *ngIf="user.civilite!='Monsieur'" alt="Avatar" style="width:10% ;height:10%">
      <div class="container-fluid">
        <h4 >Utilisateur connecté:</h4><hr>
        <h4>Crée le(heure GMT):<b>  {{ user.created }}</b></h4>
        <h4>Dernière modification  le(heure GMT):<b>  {{ user.updated }}</b></h4>
        <h4>Vérifié le(heure GMT):<b>  {{ user.verified }}</b></h4>
        <h4 *ngIf="user.desactive">Statut du compte:<b *ngIf="user.desactive.statut===false">Compte actif</b><b *ngIf="user.desactive.statut===true">Compte Inactif</b></h4>
        <h4 *ngIf="user.desactive">date du statut(heure GMT):<b>  {{ user.desactive.date }}</b></h4>
        <h4>Mot de passe regénéré le(heure GMT):<b>  {{ user.passwordReset}}</b></h4>
        <h4>Nature utilisateur:<b>  {{ user.nature }}</b></h4>
        <h4>{{ user.civilite }} <b>  {{ user.firstname }} {{ user.lastname }}</b></h4> 
        <h4>Mobile:<b>  {{ user.mobile }}</b></h4>
        <h4>Type utilisateur:<b>  {{ user.usertype }}</b></h4>
        <h4>Fonction:<b>  {{ user.fonction }}</b></h4>
        <h4>Raisonsociale:<b>  {{ user.raisonsociale }}</b></h4>
        <h4>Secteur:<b>  {{ user.secteur }}</b></h4>
        <h4>Nomsociete:<b>  {{ user.nomsociete }}</b></h4>
        <h4>Email:<b>  {{ user.email }}</b></h4>
        <h4>Code client:<b>  {{ user.clientcode }}</b></h4>
        <h4>Nature Activité:<b>  {{ user.natureactivite }}</b></h4>
        <h4>Activité:<b>  {{ user.activite }}</b></h4>
        <h4 *ngIf="user.sousactivite">Sous-Activité:<b>  {{ user.sousactivite }}</b></h4>
        <h4 *ngIf="user.specialite">Spécialité:<b>  {{ user.specialite }}</b></h4>
        <h4 *ngIf="user.sousspecialite">Sous-Spécialité:<b>  {{ user.sousspecialite }}</b></h4>
        <h4>Carte d'identification fiscale: <b><a href="{{user.ficheUrl}}" target="_blank">Aperçu CIF</a></b></h4>
        <h4>Régime fiscal en matières d'impôts directs:<b>  {{ user.regimefiscalimpot }}</b></h4>
        <h4>Régime fiscal en matières de TVA:<b>  {{ user.regimefiscaltva }}</b></h4>
        <h4>Matricule Fiscale:<b>  {{ user.matriculefiscale }}</b></h4>
        <h4>Date d'effet:<b>  {{ user.dateeffet | customDate}}</b></h4>
        <h4>Adresse D'activité:<b>  {{ user.adresseactivite}}</b></h4>
        <h4>Code Postal:<b>  {{ user.codepostal }}</b></h4>
        <h4>
          <fieldset *ngIf="user.coproprietaires.length>0" style="text-align: center;">
            <legend> Etat des copropriétaires</legend>
            <button (click)="exportusersAsXLSX(user.coproprietaires,'Liste des coproprietaires')"><i class="fa fa-file-excel-o" style="color:blue"></i> Exporter vers Excel</button> 
            <table class="table table-striped">
              <thead>
              <tr>
                <th rowspan="2">N° <i class="fa fa-sort" aria-hidden="true" [appSort]="user.coproprietaires" data-order="desc" data-name="numero"  ></i></th>
                <th rowspan="2">copropriétaire<i class="fa fa-sort" aria-hidden="true" [appSort]="user.coproprietaires" data-order="desc" data-name="coproprietaire" ></i></th>
                <th>
                  <span>Annee</span> &nbsp;
                  <span>Périodicité</span> &nbsp;
                  <span>Montant exigible par échéance</span> &nbsp;
            </th>  
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let cop of user.coproprietaires"> 
                <td>{{cop.numero}}</td>
                <td>{{cop.coproprietaire}}</td>
                <td>
                    <tr *ngFor="let dcopm of cop.donneescomptable">
                      <td> {{dcopm.annee}}</td>
                      <td> {{dcopm.periodicite}}</td> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <td> {{dcopm.montant}}</td>
                    </tr>
                </td>
              </tr>
              </tbody>
            </table>
                    </fieldset>
        </h4>
        <h4>
          <fieldset>
            <legend>Liste des choix de la comptabilité</legend>
            <table class="table table-striped"[formGroup]="choixcomptableform">
              <thead>
              <tr>
                <th>Annee</th>
                <th>Déclaration du chiffre d'affaires</th>            
              </tr>
            </thead>       
            <tbody formArrayName="ammounts0">            
              <tr *ngFor="let ammount of ammountControls0; let u = index;"[formGroupName]="u">
                <td>
                  <div >
                       <input type="text"  
                     class="form-control"formControlName="annee"placeholder="Montant"readonly='true'/>
                  </div>
                </td>
                <td>
                  <div >
                       <input type="text"  
                     class="form-control"formControlName="choix"placeholder="Montant"readonly='true'/>
                  </div>
                </td>
                <td>
                  <div>
                  <button type="button" class="btn btn-danger btn-sm"(click)="removeammount0(u)"><span><i class="fa fa-minus-circle"></i></span></button>   
                  </div>
                 </td>       
              </tr>
            </tbody>
          </table>
          </fieldset>
          <fieldset>
            <legend>Liste des numéros  de la comptabilité</legend>
            <table class="table table-striped"[formGroup]="numerocomptableform">
              <thead>
              <tr>
                <th>Annee</th>
                <th>1er numéro</th>            
              </tr>
            </thead>       
            <tbody formArrayName="ammounts1">            
              <tr *ngFor="let ammount of ammountControls1; let i = index;"[formGroupName]="i">
                <td>
                  <div >
                       <input type="text"  
                     class="form-control"formControlName="annee"placeholder="Montant"readonly='true'/>
                  </div>
                </td>
                <td>
                  <div >
                       <input type="text"  
                     class="form-control"formControlName="numero"placeholder="Montant"/>
                  </div>
                </td>
                <td>
                  <div>
                  <button type="button" class="btn btn-danger btn-sm"(click)="removeammount1(i)"><span><i class="fa fa-minus-circle"></i></span></button>   
                  </div>
                 </td>       
              </tr>
            </tbody>
          </table>
          </fieldset>
          <button type="button" class="btn btn-success" (click)="modifierchoixutilisateur()"><i class="fa fa-spinner"*ngIf="validating"></i>Valider</button>
        </h4>
      </div>
      <!-- comunicate with user -->
  <div class="input-container" id="Check2">
    <label for="myCheck1">Afficher le formulaire de communication avec le client par Email</label>
    <input type="checkbox" id="myCheck2" (click)="myFunction2()">
    <label for="myCheck3">Afficher le formulaire de communication avec le client par SMS</label>
    <input type="checkbox" id="myCheck3" (click)="myFunction3()">
  </div>
  <div id="bodycontainer2">
    <div class="container-fluid"> 
      <form [formGroup]="contactform"(ngSubmit)="onSubmit()"style="text-align: center;">
        <div class="form-group">
          <label for="email">Adresse Email de l'expéditeur</label>
          <input type="email" class="form-control" formControlName="email"id="email" placeholder=" Entrez Votre Email">
      </div>
          <div class="form-group">
              <label for="email">Adresse Email de l'utilisateur</label>
              <input type="email" class="form-control" formControlName="emailenvoyea"id="email" placeholder=" Entrez Votre Email">
          </div>
            <div class="form-group">
              <label for ="description"> Description</label>
               <textarea  class="form-control" id="description" formControlName="description"placeholder="Rédiger Votre Message"></textarea>
            </div>
            <button type="submit" class="btn btn-default submit"><i class="fa fa-paper-plane" aria-hidden="true"></i>Envoyer Mail au client</button>
            <button type="reset" class="btn btn-default submit"(click)="onReset()">Réinitialiser</button>   
      </form>
  </div>
  </div>
  <div id="bodycontainer3">
    <div class="container-fluid"> 
      <form [formGroup]="contactsmsform"(ngSubmit)="onSubmitsms()"style="text-align: center;">
            <div class="form-group">
              <label for ="description"> Description</label>
               <textarea  class="form-control" id="description" formControlName="description"placeholder="Rédiger Votre Message"></textarea>
            </div>
            <button type="submit" class="btn btn-default submit"><i class="fa fa-paper-plane" aria-hidden="true"></i>Envoyer SMS au client</button>
            <button type="reset" class="btn btn-default submit"(click)="onReset()">Réinitialiser</button>   
      </form>
  </div>
  </div>
  <div class="container-fluid">
      <button type="button" class="btn btn-success" *ngIf="currentUser.role=='admin'" (click)="getNavigation('modify-user-admin', user._id)">Modifier données utilisateur (Admin)</button>
      <button type="button" class="btn btn-danger"*ngIf="currentUser.role=='admin'"(click)="onDelete()">supprimer utilisateur</button>
      <button type="button" class="btn btn-secondary"*ngIf="currentUser.role=='admin' && !user.desactive"(click)="onDesactivate()">Désactiver utilisateur</button>
      <button type="button" class="btn btn-primary"*ngIf="currentUser.role=='admin'"(click)="ondeletetemporar()">Supprimer Temporairement utilisateur</button>
    </div>
  <div class="container-fluid"*ngIf="user.desactive">
      <button type="button" class="btn btn-primary"*ngIf="currentUser.role=='admin' && user.desactive.statut===false"(click)="onDesactivate()">Désactiver utilisateur</button>
      <button type="button" class="btn btn-primary"*ngIf="currentUser.role=='admin' && !user.standby"(click)="onstandby()">Mettre utilisateur en Standby</button>
      <button type="button" class="btn btn-info"*ngIf="currentUser.role=='admin' && user.desactive.statut===true"(click)="onactivate()">Activer utilisateur</button>
      <button type="button" class="btn btn-info"*ngIf="currentUser.role=='admin' && user.standby"(click)="onliberate()">Libérer utilisateur</button>
      <button type="button" class="btn btn-success"*ngIf="currentUser.role=='admin' && !user.verified"(click)="onverif()">Vérifier manuellement utilisateur</button>

    </div>
    </div>
    
    </body>
