<div class="container">
  <div class="row">
    <div class="col">
      <!-- Form 1: Modification d'actualités -->
      <form [formGroup]="carouselform" *ngIf="!loading && carousel.tarifs.length == 0" class="row g-3 ps-4 pt-5">
        <h1 class="text-center mt-5 text-danger text-decoration-none fs-2">
          Formulaire de modification d'actualités
        </h1>
        
        <div class="col-md-6">
          <div class="input-container" title="indiquer titre actualité">
            <label for="titre" class="form-label"><b>Titre Actualité :</b></label>
            <input type="text" class="form-control" formControlName="titre" id="titre" placeholder="Entrez titre" required />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-container" title="indiquer rang">
            <label for="rang" class="form-label"><b>Rang :</b></label>
            <input type="number" class="form-control" formControlName="rang" id="rang" placeholder="Entrez rang" required />
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="input-container" title="indiquer commentaire">
            <label for="commentaire" class="form-label"><b>Commentaire :</b></label>
            <input type="text" class="form-control" formControlName="commentaire" id="commentaire" placeholder="Entrez commentaire" required />
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="input-container" title="indiquer description">
            <label for="description" class="form-label"><b>Description :</b></label>
            <textarea class="form-control" rows="10" cols="50" id="description" formControlName="description" placeholder="Entrez description" required></textarea>
          </div>
        </div>
        <div class="col-md-8">
          <div class="form-group">
            <label for="inputCV" class="form-label"><b>Attacher votre document joint</b></label>
            <input type="file" #filePicker (change)="onImagePick($event)" class="form-control mx-4" accept="application/pdf" />
            <div class="image-preview" *ngIf="imagePreview">
              <img [src]="imagePreview" alt="" />
            </div>
            <p class="text-success" *ngIf="fileUploaded">Fichier chargé !</p>
          </div>
        </div>
        <div class="col-12">
          <div class="text-end mt-3">
            <button class="btn btn-success me-3" (click)="onSubmit()">
              <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
              Modifier Actualité
            </button>
          </div>
        </div>
      </form>

      <!-- Form 2: Modification de tarifs -->
      <form [formGroup]="carouselform" *ngIf="!loading && carousel.tarifs.length > 0" class="row g-3 ps-4 pt-5">
        <h1 class="text-center mt-5 text-danger text-decoration-none fs-2">
          Formulaire de modification des tarifs
        </h1>
        
        <div class="col-md-6">
          <div class="form-group">
            <label for="type">Type Tarif</label>
            <select class="input-field form-control" placeholder="Entrer type tarif" id="type" formControlName="type" name="type" required>
              <option value=""></option>
              <option *ngFor="let typ of type" [value]="typ">{{typ}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="debut">Date Début</label>
            <input type="date" class="form-control" formControlName="debut" id="debut" placeholder="date début" required />
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="fin">Date Fin</label>
            <input type="date" class="form-control" formControlName="fin" id="fin" placeholder="date fin" required />
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="nature">Nature</label>
            <select class="input-field form-control" placeholder="Entrer nature" id="nature" formControlName="nature" (click)="sort()" name="nature" required>
              <option value=""></option>
              <option *ngFor="let natur of nature" [value]="natur">{{natur}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="natureactivite">Nature Activité</label>
            <select class="input-field form-control" placeholder="Entrer nature activité" id="natureactivite" formControlName="natureactivite" (click)="sort()" name="natureactivite" required>
              <option value=""></option>
              <option *ngFor="let natureact of natureactivite" [value]="natureact">{{natureact}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="activite">Activité</label>
            <select class="input-field form-control" placeholder="Entrer activité" id="activite" formControlName="activite" (click)="sort()" name="activite" required>
              <option value=""></option>
              <option *ngFor="let act of activite" [value]="act">{{act}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="sousactivite">Sous Activité</label>
            <select class="input-field form-control" placeholder="Entrer sous activité" id="sousactivite" formControlName="sousactivite" (click)="sort()" name="sousactivite" required>
              <option value=""></option>
              <option *ngFor="let sousact of sousactivite" [value]="sousact">{{sousact}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="impot">Régime impôt</label>
            <select class="input-field form-control" placeholder="Entrer regime impot" id="impot" formControlName="impot" name="impot" required>
              <option value=""></option>
              <option *ngFor="let regimpot of regimeimpot" [value]="regimpot">{{regimpot}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="prix">Prix</label>
            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'')" class="form-control" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="prix" id="prix" placeholder="prix" required />
          </div>
        </div>

        <div class="col-12 text-end mt-3">
          <button class="btn btn-success me-3" (click)="onSubmit()">
            <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
            Modifier Tarif
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
