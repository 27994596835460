export class invoicemodel {
    totalds: {
        userId:string,

        demandessoumis:[],
demandesnonsoumis:[],
totalht:string,
tva:string,
soustotal1:string,
soustotal2:string,
timbrefiscal:string,
totalgeneral:string
    };
    orderid:string;
    ref:string; 
    prefixe:string; 
userId:string
profid:string
archivated:string
    _id:string; 
    created:Date;
        }