import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalFormat]'
})
export class DecimalFormatDirective {
  @Input() decimalPlaces: number = 3;

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('blur') onBlur() {
    this.formatValue();
  }

  @HostListener('change') onChange() {
    this.formatValue();
  }
  @HostListener('click') onClick() {
    this.formatValue();
  }
  private formatValue() {
    let value: string = this.el.nativeElement.value;
    if (value != null && !isNaN(parseFloat(value))) {
      // Replace comma with point and format to the specified decimal places
      let formattedValue = parseFloat(value.replace(',', '.')).toFixed(this.decimalPlaces);

      // Ensure the formatted value uses a point as the decimal separator
      formattedValue = formattedValue.replace(',', '.');

      this.control.control.setValue(formattedValue, { emitEvent: false });
      this.el.nativeElement.value = formattedValue;
    }
  }
}
