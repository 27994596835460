import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Part2Component } from './part2/part2.component';
import { Part2anx1Component } from './part2anx1/part2anx1.component';
import { Annexe1Component } from './annexe1/annexe1.component';
import { Anx1finComponent } from './anx1fin/anx1fin.component';
import { Anx2p1Component } from './anx2p1/anx2p1.component';
import { Anx2p2Component } from './anx2p2/anx2p2.component';
import { Anx2p3Component } from './anx2p3/anx2p3.component';
import { Anx3p1Component } from './anx3p1/anx3p1.component';
import { Anx3p2Component } from './anx3p2/anx3p2.component';
import { Anx3p3Component } from './anx3p3/anx3p3.component';
import { Anx4p1Component } from './anx4p1/anx4p1.component';
import { Anx4p2Component } from './anx4p2/anx4p2.component';
import { Anx4p3Component } from './anx4p3/anx4p3.component';

import { Anx5p1Component } from './anx5p1/anx5p1.component';
import { Anx5p2Component } from './anx5p2/anx5p2.component';
import { Anx5p3Component } from './anx5p3/anx5p3.component';
import { Anx6Component } from './anx6/anx6.component';
import { Anx6p2Component } from './anx6p2/anx6p2.component';
import { Anx6p3Component } from './anx6p3/anx6p3.component';
import { Anx7p1Component } from './anx7p1/anx7p1.component';

import { Anx7p2Component } from './anx7p2/anx7p2.component';
import { Anx7p3Component } from './anx7p3/anx7p3.component';
import { FichierrecapComponent } from './fichierrecap/fichierrecap.component';
import { DecEmployeurGeneralComponent } from './dec-employeur-general.component';

const routes: Routes = [
  { path: 'part2', component: Part2Component },
  { path: 'part2anx1', component: Part2anx1Component },
  { path: 'annexe1', component: Annexe1Component },
  { path: 'anx1fin', component: Anx1finComponent },
  { path: 'anx2p1', component: Anx2p1Component },
  { path: 'anx2p2', component: Anx2p2Component },
  { path: 'anx2p3', component: Anx2p3Component },
  { path: 'anx3p1', component: Anx3p1Component },
  { path: 'anx3p2', component: Anx3p2Component },
  { path: 'anx3p3', component: Anx3p3Component },
  { path: 'anx4p1', component: Anx4p1Component },
  { path: 'anx4p2', component: Anx4p2Component },
  { path: 'anx4p3', component: Anx4p3Component },
  { path: 'anx5p1', component: Anx5p1Component },
  { path: 'anx5p2', component: Anx5p2Component },
  { path: 'anx5p3', component: Anx5p3Component },
  { path: 'anx6', component: Anx6Component },
  { path: 'anx6p2', component: Anx6p2Component },
  { path: 'anx6p3', component: Anx6p3Component },
  { path: 'anx7p1', component: Anx7p1Component },
  { path: 'anx7p2', component: Anx7p2Component },
  { path: 'anx7p3', component: Anx7p3Component },
  { path: 'fichierrecap', component: FichierrecapComponent },
  { path: '', component: DecEmployeurGeneralComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DecEmployeurRoutingModule { }
