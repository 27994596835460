
  <body>
    <div class="container mt-5">
      <h1 class="text-center mt-5 text-danger fs-2">
        المعلوم على المؤشسات ذات الصبغة الصناعيّة أو التجاريّة أو المهنيّة
      </h1>
      <h1 class="text-center mt-3 text-danger fs-2">T. C. L.</h1>
      <form [formGroup]="tcl3form">
 <table class="table table-bordered mt-5">
  <thead>
    <tr>
      <th scope="col" class="text-center">المعلوم المستوجب</th>
      <th scope="col" class="text-center">النسبة</th>
      <th scope="col" class="text-center">أساس المعلوم</th>
      <th scope="col" colspan="2" class="text-center">المعلوم على المؤسّسات</th>
    </tr>
    <tr>
      <th scope="col" class="text-center">Taxe due</th>
      <th scope="col" class="text-center">Taux</th>
      <th scope="col" class="text-center">Assiette</th>
      <th scope="col" colspan="2" class="text-center">T.C.L.</th>
    </tr>
  </thead>
  <tbody>
    <tr scope="row">
      <td class="text-center">
        <input type="text" formControlName="taxDue_1" />
      </td>
      <td class="text-center">0.2%</td>
      <td class="text-center">
        <input type="text" formControlName="assiette_1" />
      </td>
      <td class="text-right-ar" colspan="2">
        <div class="d-flex justify-content-between align-items-center">
          <span> رقم المعاملات المحلي % الخام المحق بنسبة 0.2</span>
          <span class="text-left-fr">
            C. A. Local Brut realise au taux de 0.2%</span
          >
        </div>
      </td>
    </tr>
    <tr scope="row">
      <td class="text-center">
        <input type="text" formControlName="taxDue_2" />
      </td>
      <td class="text-center">0.1%</td>
      <td class="text-center">
        <input type="text" formControlName="assiette_2" />
      </td>
      <td class="text-right-ar" colspan="2">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            رقم المعاملات - المتأتي من التصدير و من العمليات المنتفعة بنفس
            النظام الجبائي للتصدير
          </span>
          <span class="text-left-fr">
            Chiffres d'affaires provenant de l'exportation et des
            opérations qui bénéficient du même régime
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered mt-5">
  <tbody>
    <tr scope="row">
      <td class="text-center">
        <input type="text" formControlName="taxDue_industrial_commercial_professional" />
      </td>
      <td class="text-right-ar text-primary" colspan="5">
        <div class="d-flex justify-content-between align-items-center">
          <span>المعلوم على المؤسّسات ذات الصبغة الصناعية أو التِجارية أو المهنيّة</span>
          <span class="text-left-fr">T.C.L</span>
        </div>
      </td>
    </tr>
    <tr scope="row">
      <td class="text-center">
        <input type="text" formControlName="taxDue_previous_months" />
      </td>
      <td class="text-right-ar text-primary" colspan="5">
        <div class="d-flex justify-content-between align-items-center">
          <span>المعلوم المستوجب بعنوان الأشهر السابقة</span>
          <span class="text-left-fr">TCL due au titre des mois précédents</span>
        </div>
      </td>
    </tr>
    <tr scope="row">
      <td class="text-center">
        {{total | number:'1.3-3'}}
      </td>
      <td class="text-right-ar text-primary" colspan="5">
        <div class="d-flex justify-content-between align-items-center">
          <span>المجموع</span>
          <span class="text-left-fr">Total</span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered mt-5">
  <tbody>
    <tr scope="row">
      <td class="text-center">
        <input type="text" formControlName="annualTaxDue" />
      </td>
      <td class="text-right-ar" colspan="5">
        <div class="d-flex justify-content-between align-items-center">
          <span>المعلوم المستوجب سنويا </span>
          <span class="text-left-fr">Total annuel dû</span>
        </div>
      </td>
    </tr>
    <tr scope="row">
      <td class="text-center">
        <input type="text" formControlName="minimumTaxDue" />
      </td>
      <td class="text-right-ar" colspan="5">
        <div class="d-flex justify-content-between align-items-center">
          <span> الحدِّ الأدنى للمعلوم على المؤسّسات</span>
          <span class="text-left-fr">Minimum de la TCL</span>
        </div>
      </td>
    </tr>
    <tr scope="row">
      <td class="text-center">
        <input type="text" formControlName="remainingTaxDue" />
      </td>
      <td class="text-right-ar" colspan="5">
        <div class="d-flex justify-content-between align-items-center">
          <span>الباقي المستوجب</span>
          <span class="text-left-fr">Reste dû</span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

      </form>


      <div class="row wizard-buttons">
        <div class="col d-flex justify-content-center align-items-center">
          <button
          (click)="validate()"
          type="button"
          class="btn btn-outline-primary"
        >
        موافق  Valider
        </button>
        <button
        (click)="reset()"
        type="button"
        class="btn btn-outline-primary"
      >
      إلغاء   Annuler
      </button>
      <button
      (click)="goto(5)"
        type="button"
        class="btn btn-outline-primary"
      >
        السابق Precedent
      </button>
        </div>
      </div>
    </div>
  </body>