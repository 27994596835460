import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { devismodel } from '../models/facture.model';
const API_URL_test = 'http://localhost:3002/api/devis/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/devis/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class DevisService {
    
    
    constructor(private http: HttpClient) { }
    public deviss: devismodel[] = [];
    public deviss$ = new Subject<devismodel[]>();
    getDeviss(page: number, size: number, filter: string = '', status: string = ''): devismodel[] {
      let filteredDeviss = this.deviss;
  
      if (filter) {
        filteredDeviss = filteredDeviss.filter(invoice => 
          invoice.ref.toLowerCase().includes(filter.toLowerCase()) ||
          invoice.clientdetails.name.toLowerCase().includes(filter.toLowerCase())
        );
      }
  
      if (status) {
        filteredDeviss = filteredDeviss.filter(invoice => invoice.status === status);
      }
  
      const start = (page - 1) * size;
      return filteredDeviss.slice(start, start + size);
    }
  
    getTotalDeviss(filter: string = '', status: string = ''): number {
      let filteredDeviss = this.deviss;
  
      if (filter) {
        filteredDeviss = filteredDeviss.filter(invoice => 
          invoice.ref.toLowerCase().includes(filter.toLowerCase()) ||
          invoice.clientdetails.name.toLowerCase().includes(filter.toLowerCase())
        );
      }
  
      if (status) {
        filteredDeviss = filteredDeviss.filter(invoice => invoice.status === status);
      }
  
      return filteredDeviss.length;
    }
    getDevisalldata() {
        this.http.get(API_URL_cloud).subscribe(
          (deviss: devismodel[]) => {
            if (deviss) {
              this.deviss = deviss;
              this.emitDeviss();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    
      emitDeviss() {
        this.deviss$.next(this.deviss);
      }
      getdevisuser(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'byuser/'  ,{userId}).subscribe(
            (invo: devismodel[]) => {
              if (invo) {
                this.deviss = invo;
                this.emitDeviss();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        });
      }
      getdevisuserwithoutsubscription(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud+'byuser/'  ,{userId}).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getDevisdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(devis: devismodel, image: File[]) {
        return new Promise((resolve, reject) => {
          const devisData = new FormData();
          devisData.append('devis', JSON.stringify(devis));
          for (let i = 0; i < image.length; i++)
          {
            devisData.append('image', image[i],'logofacture'+devis.number+'user'+devis.userId); 
          }
          this.http.post(API_URL_cloud, devisData).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      update(devisId: string, devis: devismodel, image: File[]) {
        return new Promise((resolve, reject) => {
          const devisData = new FormData();
          devisData.append('devis', JSON.stringify(devis));
          for (let i = 0; i < image.length; i++) {
            devisData.append('image', image[i], 'logofacture' + devis.number + 'user' + devis.userId);
          }
          this.http.put(`${API_URL_cloud}/${devisId}`, devisData).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
    /*  modifydevisById(id: string, devis: devismodel) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, devis).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }*/
      deletedevisdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
