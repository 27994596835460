export class Carouselmodel {
    
titre: string;
commentaire: string;
rang:number
_id:string;
ficheUrl: string;
description:string;  
created:Date;
updated: Date
tarifs:string[]
cours:string[]
  
    }