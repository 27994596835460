import { Component ,Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormStateService } from '../../services/form-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rs',
  templateUrl: './rs.component.html',
  styleUrls: ['./rs.component.scss']
})
export class RSComponent implements OnInit,OnDestroy{
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  rsform: FormGroup;
  sumOfDisabledControls: number = 0;
  formValueChangesSubscription: Subscription;
  constructor(private fb: FormBuilder,private router: Router,private formStateService: FormStateService) {}
  goto(stepno)
  {
  this.moveStep.emit(stepno)
  }
  reset()
  {
    this.rsform.reset();
  }
  ngOnInit(): void {
     // Initialize forms
     const savedFormState = this.formStateService.getrsFormState();
     if (savedFormState) {
       this.rsform = savedFormState;
         // Reset sum
  this.sumOfDisabledControls = 0;

  // Iterate through all controls
  Object.keys(this.rsform.controls).forEach(key => {
    const control = this.rsform.get(key);

    // Check if control is disabled and add its value to sum
    if (control && control.disabled) {
      this.sumOfDisabledControls += control.value;
    }
  });
     }
     else
     {
      this.rsform = this.fb.group({
        salariesPensionsSource: [0, [ Validators.min(0)]],
        salariesPensions: [{value: null, disabled: true}],
        salaries20PercentSource: [0, [ Validators.min(0)]],
        salaries20Percent: [{value: null, disabled: true}],
        socialContribution: [0, [ Validators.min(0)]],
        residentsIndividuals: [0, [ Validators.min(0)]],
        residentsIndividualsAmount: [{value: null, disabled: true}],
        residentsCorporations: [0, [ Validators.min(0)]],
        residentsCorporationsAmount: [{value: null, disabled: true}],
        nonResidentsIndividuals: [0, [ Validators.min(0)]],
        nonResidentsIndividualsAmount: [{value: null, disabled: true}],
        nonResidentsCorporations: [0, [ Validators.min(0)]],
        nonResidentsCorporationsAmount: [{value: null, disabled: true}],
        honorairesNonRegimeReel: [0, [ Validators.min(0)]],
        honorairesNonRegimeReelAmount: [{value: null, disabled: true}],
        honorairesRegimeReel: [0, [ Validators.min(0)]],
        honorairesRegimeReelAmount: [{value: null, disabled: true}],
        remunerationsArtistesCreateurs: [0, [ Validators.min(0)]],
        remunerationsArtistesCreateursAmount: [{value: null, disabled: true}],
        loyersHotelsRegimeReel: [0, [ Validators.min(0)]],
        loyersHotelsRegimeReelAmount: [{value: null, disabled: true}],
        remunerationsPerformanceServices: [0, [ Validators.min(0)]],
        remunerationsPerformanceServicesAmount: [{value: null, disabled: true}],
        interetsComptesEpargne: [0, [ Validators.min(0)]],
        interetsComptesEpargneAmount: [{value: null, disabled: true}],
        revenusResidentsPersonnesPhysiques: [0, [ Validators.min(0)]],
        revenusResidentsPersonnesPhysiquesAmount: [{value: null, disabled: true}],
        personnesMoralesAmount: [0, [ Validators.min(0)]],
        personnesMoralesPercentage: [{value: null, disabled: true}],
        personnesPhysiquesAmount: [0, [ Validators.min(0)]],
        personnesPhysiquesPercentage: [{value: null, disabled: true}],
        personnesMoralesNonResidentesAmount: [0, [ Validators.min(0)]],
        personnesMoralesNonResidentesPercentage: [{value: null, disabled: true}],
        partsActionsResidentesAmount: [0, [ Validators.min(0)]],
        partsActionsResidentesPercentage: [{value: null, disabled: true}],
        partsActionsNonResidentesAmount: [0, [ Validators.min(0)]],
        partsActionsNonResidentesPercentage: [{value: null, disabled: true}],
        remunerationsResidentsPersonnesPhysiquesAmount: [0, [ Validators.min(0)]],
        remunerationsResidentsPersonnesPhysiquesPercentage: [{value: null, disabled: true}],
        remunerationsResidentsPersonnesMoralesAmount: [0, [ Validators.min(0)]],
        remunerationsResidentsPersonnesMoralesPercentage: [{value: null, disabled: true}],
        remunerationsNonResidentsPersonnesPhysiquesAmount: [0, [ Validators.min(0)]],
        remunerationsNonResidentsPersonnesPhysiquesPercentage: [{value: null, disabled: true}],
        remunerationsNonResidentsPersonnesMoralesAmount: [0, [ Validators.min(0)]],
        remunerationsNonResidentsPersonnesMoralesPercentage: [{value: null, disabled: true}],
        remunerations_input1: [0, [ Validators.min(0)]],
        remunerations_input2: [{value: null, disabled: true}],
        interets_input1: [0, [ Validators.min(0)]],
        interets_input2: [{value: null, disabled: true}],
        residents_etablis_input1: [0, [ Validators.min(0)]],
        residents_etablis_input2: [{value: null, disabled: true}],
        non_residents_input1: [0, [ Validators.min(0)]],
        non_residents_input2: [{value: null, disabled: true}],
        personnes_physiques_input1: [0, [ Validators.min(0)]],
        personnes_physiques_input2: [{value: null, disabled: true}],
        personnes_morales_non_etablies_input1: [0, [ Validators.min(0)]],
        personnes_morales_non_etablies_input2: [{value: null, disabled: true}],
        societes_is_15_input1: [0, [ Validators.min(0)]],
        societes_is_15_input2: [{value: null, disabled: true}],
        societes_is_10_input1: [0, [ Validators.min(0)]],
        societes_is_10_input2: [{value: null, disabled: true}],
        pp_deduction_deux_tiers_input1: [0, [ Validators.min(0)]],
        pp_deduction_deux_tiers_input2: [{value: null, disabled: true}],
        autres_entreprises_input1: [0, [ Validators.min(0)]],
        autres_entreprises_input2: [{value: null, disabled: true}],
        tva_retenue_source_input1: [0, [ Validators.min(0)]],
        tva_retenue_source_input2: [{value: null, disabled: true}],
        tva_retenue_source_non_residents_input1: [0, [ Validators.min(0)]],
        tva_retenue_source_non_residents_input2: [{value: null, disabled: true}],
        remunerations_non_residents_input1: [0, [ Validators.min(0)]],
        remunerations_non_residents_input2: [{value: null, disabled: true}],
        personnes_etablies_tunisie_input1: [0, [ Validators.min(0)]],
        personnes_etablies_tunisie_input2: [{value: null, disabled: true}],
        autres_etablissements_stables_input1: [0, [ Validators.min(0)]],
        autres_etablissements_stables_input2: [{value: null, disabled: true}],
        avance_ventes_entreprises_input1: [0, [ Validators.min(0)]],
        avance_ventes_entreprises_input2: [{value: null, disabled: true}],
        avance_ventes_alcoolises_input1: [0, [ Validators.min(0)]],
        avance_ventes_alcoolises_input2: [{value: null, disabled: true}],
        personnes_physiques_input3: [0, [ Validators.min(0)]],
        personnes_physiques_input4: [{value: null, disabled: true}],
        personnes_morales_input1: [0, [ Validators.min(0)]],
        personnes_morales_input2: [{value: null, disabled: true}],
        personnes_physiques_autres_input1: [0, [ Validators.min(0)]],
        personnes_physiques_autres_input2: [{value: null, disabled: true}],
        personnes_morales_autres_input1: [0, [ Validators.min(0)]],
        personnes_morales_autres_input2: [{value: null, disabled: true}]
      });
     }

    this.setupAutoCalculation('residentsIndividuals', 'residentsIndividualsAmount', 0.1);
    this.setupAutoCalculation('salariesPensionsSource', 'salariesPensions', 0.1);
    this.setupAutoCalculation('salaries20PercentSource', 'salaries20Percent', 0.2);
    this.setupAutoCalculation('residentsCorporations', 'residentsCorporationsAmount', 0.1);
    this.setupAutoCalculation('honorairesNonRegimeReel', 'honorairesNonRegimeReelAmount', 0.1);
    this.setupAutoCalculation('honorairesRegimeReel', 'honorairesRegimeReelAmount', 0.03);  // Adjust multiplier as needed
    this.setupAutoCalculation('remunerationsArtistesCreateurs', 'remunerationsArtistesCreateursAmount', 0.05);  // Adjust multiplier as needed
    this.setupAutoCalculation('loyersHotelsRegimeReel', 'loyersHotelsRegimeReelAmount', 0.05);  // Adjust multiplier as needed
    this.setupAutoCalculation('remunerationsResidentsPersonnesPhysiquesAmount', 'remunerationsResidentsPersonnesPhysiquesPercentage', 0.2);  // Adjust multiplier as needed
    this.setupAutoCalculation('remunerationsResidentsPersonnesMoralesAmount', 'remunerationsResidentsPersonnesMoralesPercentage', 0.2);  // Adjust multiplier as needed
    this.setupAutoCalculation('remunerationsNonResidentsPersonnesPhysiquesAmount', 'remunerationsNonResidentsPersonnesPhysiquesPercentage', 0.2);  // Adjust multiplier as needed
    this.setupAutoCalculation('remunerationsNonResidentsPersonnesMoralesAmount', 'remunerationsNonResidentsPersonnesMoralesPercentage', 0.2);  // Adjust multiplier as needed
    this.setupAutoCalculation('remunerations_input1', 'remunerations_input2', 0.15);  // Adjust multiplier as needed
    this.setupAutoCalculation('residents_etablis_input1', 'residents_etablis_input2', 0.025);  // Adjust multiplier as needed
    this.setupAutoCalculation('societes_is_15_input1', 'societes_is_15_input2', 0.01);  // Adjust multiplier as needed
    this.setupAutoCalculation('societes_is_10_input1', 'societes_is_10_input2', 0.05);  // Adjust multiplier as needed
    this.setupAutoCalculation('pp_deduction_deux_tiers_input1', 'pp_deduction_deux_tiers_input2', 0.05);  // Adjust multiplier as needed
    this.setupAutoCalculation('autres_entreprises_input1', 'autres_entreprises_input2', 0.015);  // Adjust multiplier as needed
    this.setupAutoCalculation('tva_retenue_source_input1', 'tva_retenue_source_input2', 0.25);  // Adjust multiplier as needed
    this.setupAutoCalculation('tva_retenue_source_non_residents_input1', 'tva_retenue_source_non_residents_input2', 1);  // Adjust multiplier as needed
    this.setupAutoCalculation('personnes_etablies_tunisie_input1', 'personnes_etablies_tunisie_input2', 0.25);  // Adjust multiplier as needed
    this.setupAutoCalculation('autres_etablissements_stables_input1', 'autres_etablissements_stables_input2', 0.15);  // Adjust multiplier as needed
    this.setupAutoCalculation('avance_ventes_alcoolises_input1', 'avance_ventes_alcoolises_input2', 0.05);  // Adjust multiplier as needed
// Subscribe to value changes of all form controls
this.formValueChangesSubscription = this.rsform.valueChanges.subscribe(() => {
  // Reset sum
  this.sumOfDisabledControls = 0;

  // Iterate through all controls
  Object.keys(this.rsform.controls).forEach(key => {
    const control = this.rsform.get(key);

    // Check if control is disabled and add its value to sum
    if (control && control.disabled) {
      this.sumOfDisabledControls += control.value;
    }
  });
});
  }
  ngOnDestroy(): void {
        // Save the form state when the component is destroyed
        this.formStateService.setrsFormState(this.rsform);
    // Unsubscribe from value changes to prevent memory leaks
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
    this.goto(1)
  }
  setupAutoCalculation(sourceControlName: string, targetControlName: string, multiplier: number): void {
    this.rsform.get(sourceControlName).valueChanges.subscribe(val => {
      const calculatedAmount = Math.floor( (val * multiplier)*1000)/1000;
      this.rsform.get(targetControlName).setValue(calculatedAmount);
    });
  }
  setThreeNumberDecimal($event) {
    $event.target.value = $event.target.value ? $event.target.value : 0;
    $event.target.value = parseFloat($event.target.value).toFixed(3);
  }
  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }

  previous() {
    this.previousStep.emit();
  }

}
