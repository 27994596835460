import { Component, OnInit } from '@angular/core';

import { Contact } from '../models/contact.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '../models/user.model';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2';
import { AlertService } from '../_helpers/alert.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ContactService } from '../services/contact.service';
import { ExcelService } from '../services/excel.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

  public user: User;
  public errormsg:string;
  public loading: boolean;
  choixcomptableform: FormGroup;
  public ammounts0: FormArray;
  numerocomptableform: FormGroup;
  public ammounts1: FormArray;

  currentUser: any;
  contactform: FormGroup;
  contactsmsform: FormGroup;
  validating=false;

  constructor(
    private formBuilder: FormBuilder,
    private cont: ContactService,
              private router: Router,
              private route: ActivatedRoute,
              private usersservice: UserService,
              private token: TokenStorageService,private excelService:ExcelService,
              private alertService: AlertService) {
                this.choixcomptableform = this.formBuilder.group({
                  ammounts0: this.formBuilder.array([ this.createammount0() ])
                });
                this.numerocomptableform = this.formBuilder.group({
                  ammounts1: this.formBuilder.array([ this.createammount1() ])
                });
               }
  ngOnInit() {
    this.loading = true;
    this.currentUser = this.token.getUser();
  
    this.route.params.subscribe(
      (params: Params) => {
        this.usersservice.getUserById(params.id).then(
          (user: User) => {
            this.loading = false;
            this.user = user;
            this.contactform = this.formBuilder.group({
              email: ['macompta@macompta.com.tn'],
              emailenvoyea: [this.user.email,],
              description: ['',],
            }); 
            this.contactsmsform = this.formBuilder.group({
              
              description: ['',],
            }); 
            if(user.choixfacture&&user.activite!='Syndic des copropriétaires') 
            {
              this.choixcomptableform = new FormGroup({                
                ammounts0: new FormArray(user.choixfacture.map(item => {
                  const group = this.createammount0();
                  //@ts-ignore
                  group.patchValue(
                    {
                                        //@ts-ignore

                      annee:item.annee,
                                        //@ts-ignore

                      choix:item.choix
                    }); 
                    return group;
                })
                )
              })
            }
           if(user.choixsyndic&&user.activite=='Syndic des copropriétaires') 
            {
              this.choixcomptableform = new FormGroup({                
                ammounts0: new FormArray(user.choixsyndic.map(item => {
                  const group = this.createammount0();
                  //@ts-ignore
                  group.patchValue(
                    {
                                        //@ts-ignore

                      annee:item.annee,
                                        //@ts-ignore

                      choix:item.choix
                    }); 
                    return group;
                })
                )
              })
            }
          if(user.numeronote)
          {
            this.numerocomptableform = new FormGroup({                
              ammounts1: new FormArray(user.numeronote.map(item => {
                const group = this.createammount1();
                //@ts-ignore
                group.patchValue(
                  {
                                      //@ts-ignore

                    annee:item.annee,
                                      //@ts-ignore

                    numero:item.numero
                  }); 
                  return group;
              }))
            }) 
          }
            
          }
        );
      }
    );
  
  }
  get ammountControls0() {
    return this.choixcomptableform.get('ammounts0')['controls'];
  }
  get ammountControls1() {
    return this.numerocomptableform.get('ammounts1')['controls'];
  }
  createammount0() 
  : FormGroup {
    
      return this.formBuilder.group({
        annee:'',
        choix: '',
      });
    }
    createammount1() 
    : FormGroup {
      
        return this.formBuilder.group({
          annee:'',
          numero: '',
        });
      }
      addammount0(){
        this.ammounts0 = this.choixcomptableform.get('ammounts0') as FormArray;
        this.ammounts0.push(this.createammount0());  
      }
      addammount1(){
        this.ammounts1 = this.numerocomptableform.get('ammounts1') as FormArray;
        this.ammounts1.push(this.createammount1());  
      }
      removeammount0(u: number) {
        this.ammounts0 = this.choixcomptableform.get('ammounts0') as FormArray;
        this.ammounts0.removeAt(u);
        
      }
      removeammount1(i: number) {
        this.ammounts1 = this.numerocomptableform.get('ammounts1') as FormArray;
        this.ammounts1.removeAt(i);      
      }
      modifierchoixutilisateur()
      {
        let ammounts0=this.choixcomptableform.get('ammounts0') as FormArray
        let ammounts1=this.numerocomptableform.get('ammounts1') as FormArray
        const newuser= new User
        newuser.choixfacture=ammounts0.value
        newuser.numeronote=ammounts1.value
        this.validating=true
        this.usersservice.modifyUserById(this.user._id, newuser).then(  
          () => {
            this.validating = false;
            Swal.fire("choix mis à jour avec succès")
            this.reloadPage()
          },
          (error) => {
            this.validating = false           
          }
        );
      }    
  onSubmit() {
    this.loading = true;

    const contact = new Contact();
    contact.email = this.contactform.get('email').value;
    contact.emailenvoyea = this.contactform.get('emailenvoyea').value;
    contact.description = this.contactform.get('description').value;
    this.cont.comunicatewithuser(contact).then(
        (data:any) => {
          this.contactform.reset();
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'message envoyée avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
          this.reloadPage()
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  onSubmitsms() {
    this.loading = true;

    const user = this.user
    const message = this.contactsmsform.get('description').value;
    this.usersservice.comunicatewithusersms(user,message).then(
        (data:any) => {
          this.contactsmsform.reset();
          this.loading = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'sms envoyée avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
          this.reloadPage()
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  myFunction2() {
    var checkbox:any = document.getElementById("myCheck2");
    var text2 = document.getElementById("bodycontainer2");
    if (checkbox.checked == true){
      this.contactform.patchValue({
        emailenvoyea:this.user.email,
        email:'macompta@macompta.com.tn'
      })
      text2.style.display = "block";
    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire de contact avec l\'utilisateur, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          
          this.contactform.reset();
          text2.style.display = "none";
        }
        else{
          checkbox.checked = true
          text2.style.display = "block";
  
        }
      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
    }
  }
  myFunction3() {
    var checkbox:any = document.getElementById("myCheck3");
    var text2 = document.getElementById("bodycontainer3");
    if (checkbox.checked == true){
      text2.style.display = "block";
    } else {
      Swal.fire({
        title: 'Vous êtes sur le point de réinitialiser tous les donnés relatifs au formulaire de contact avec l\'utilisateur, voulez vous continuer?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Réinitialiser',
        cancelButtonText: 'Annuler',
      }).then((result) => {
        if (result.value) {
          
          this.contactsmsform.reset();
          text2.style.display = "none";
        }
        else{
          checkbox.checked = true
          text2.style.display = "block";
  
        }
      }).catch(() => {
        Swal.fire('opération non aboutie!');
      });
    }
  }
  getNavigation(link, id){
      
    this.router.navigate([link + '/' + id]);
      
  }
  onDelete() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        this.usersservice.getUserById(params.id).then(
          (data:any) => {
            this.loading = false;
            Swal.fire({
              title: 'Veuillez confirmer la suppression!',
              
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.usersservice.deleteUserById(params.id);
                this.router.navigate(['admin-board']);
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  onDesactivate() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        
        this.usersservice.getUserById(params.id).then(
          (user: User) => {
            this.loading = false;
            this.user=user;
            Swal.fire({
              title: 'Veuillez confirmer la désactivation!',
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.usersservice.desactivateUser(params.id,user);
                this.router.navigate(['admin-board']);
                this.reloadPage()
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  onstandby() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        
        this.usersservice.getUserById(params.id).then(
          (user: User) => {
            this.loading = false;
            this.user=user;
            Swal.fire({
              title: 'Veuillez confirmer la mise en standby!',
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.usersservice.standbyUser(params.id,user);
                this.router.navigate(['admin-board']);
                this.reloadPage()
              }
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  ondeletetemporar() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        
        this.usersservice.getUserById(params.id).then(
          (user: User) => {
            this.loading = false;
            this.user=user;
            Swal.fire({
              title: 'Veuillez confirmer la suppression temporaire!',
              
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.usersservice.deletetemporarUser(params.id);
                this.router.navigate(['admin-board']);
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  onverif() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        
        this.usersservice.getUserById(params.id).then(
          (user: User) => {
            this.loading = false;
            this.user=user;
            Swal.fire({
              title: 'Veuillez confirmer le forcage de vérification de l\'utilisateur!',
              
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.usersservice.verifyEmail(this.user.accessToken);
                this.router.navigate(['admin-board']);
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  onliberate() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        
        this.usersservice.getUserById(params.id).then(
          (user: User) => {
            this.loading = false;
            this.user=user;
            Swal.fire({
              title: 'Veuillez confirmer la libération de l\'utilisateur!',
              
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.usersservice.liberateUser(params.id,user);
                this.router.navigate(['admin-board']);
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  onactivate() {
    this.loading = true;
    this.route.params.subscribe(
      (params: Params) => {
        
        this.usersservice.getUserById(params.id).then(
          (user: User) => {
            this.loading = false;
            this.user=user;
            Swal.fire({
              title: 'Veuillez confirmer la restauration!',
              
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmer',
              
            }).then((result) => {
              if (result.value) {
                this.usersservice.activateUser(params.id,user);
                this.router.navigate(['admin-board']);
              }
  
            }).catch(() => {
              Swal.fire('opération non aboutie!');
            });
    
        
          }
          
        );
      }
    );
  }
  onReset() {
    this.contactform.reset();
}
exportusersAsXLSX(source:any[],name:string):void {
  console.log(source)
  let exportedsource=[]
  source.forEach(element=>
    {
element.donneescomptable?element.donneescomptable.forEach(e => {
  exportedsource.push(
    {
      numero : element.numero , 
      coproprietaire: element.coproprietaire, 
      annee:e.annee,
      periodicité: e.periodicite,
      montant:e.montant
    }
  )
}):
exportedsource.push(
  {
    numero : element.numero , 
    coproprietaire: element.coproprietaire, 
    annee:'',
    periodicité:'',
    montant:''
  }
)
    }
  )
  this.excelService.exportAsExcel(exportedsource, name);
}
  reloadPage(): void {
    
    setTimeout(() => window.location.reload(), 1000);
    
  }
  }
