import { Component,Output, EventEmitter  } from '@angular/core';
import { FormStateService } from 'src/app/services/form-store.service';

@Component({
  selector: 'app-page-garde2',
  templateUrl: './page-garde2.component.html',
  styleUrls: ['./page-garde2.component.scss']
})
export class PageGarde2Component {
  
  @Output() moveStep = new EventEmitter<void>();
  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  showdctax: boolean;
  showdltax: boolean;
  showdttax: boolean;
  showfoprolostax: boolean;
  showrstax: boolean;
  showtcltax: boolean;
  showtfptax: boolean;
  showthtax: boolean;
  showtvatax: boolean;
  constructor(
    private formStateService: FormStateService
  ) { }
  ngOnInit(): void {
    const savedFormState = this.formStateService.getGardeFormState();
    if (savedFormState) {
      savedFormState.get('DC').value?this.showdctax=true:this.showdctax=false;
      savedFormState.get('DL').value?this.showdltax=true:this.showdltax=false;
      savedFormState.get('DT').value?this.showdttax=true:this.showdttax=false;
      savedFormState.get('FOPROLOS').value?this.showfoprolostax=true:this.showfoprolostax=false;
      savedFormState.get('RS').value?this.showrstax=true:this.showrstax=false;
      savedFormState.get('TCL').value?this.showtcltax=true:this.showtcltax=false;
      savedFormState.get('TFP').value?this.showtfptax=true:this.showtfptax=false;
      savedFormState.get('TH').value?this.showthtax=true:this.showthtax=false;
      savedFormState.get('TVA').value?this.showtvatax=true:this.showtvatax=false;
    }
console.log(savedFormState)
  }
  // Méthodes pour émettre les événements
  next() {
    this.nextStep.emit();
  }
goto(stepno)
{
this.moveStep.emit(stepno)
}
  previous() {
    this.previousStep.emit();
  }
  }




