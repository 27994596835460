<body>
    <div class="container">
    <div class="topleft">

    </div>
    <div class="middle">
      <h1>Bientôt en Marche</h1>
      <hr>
      <p>fonctionnalité En cours de Développement</p>
    </div>
    <div class="bottomleft">
      
    </div>
  </div>
</body>