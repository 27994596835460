<div class="form-container">
  <div class="checkbox-container">
    <mat-checkbox [checked]="true" disabled>
      Déclaration Mensuelle
    </mat-checkbox>
  </div>
  
  <div class="selectors-container">
    <div class="year-selector" id="Year">
      <label for="annee" class="label-year">Sélectionner une année :</label>
      <mat-form-field appearance="fill">
        <mat-select id="year" name="year" (selectionChange)="verifyfutur($event)" [(ngModel)]="option54Value">
          <mat-option></mat-option>
          <mat-option *ngFor="let an of filtredannees" [value]="an">{{ an }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
    <div class="month-selector" *ngIf="option54Value" id="Month">
      <label for="mois" class="label-month">Sélectionner un mois :</label>
      <mat-form-field appearance="fill">
        <mat-select id="month" name="month" #cricket (selectionChange)="verifyfutur($event)" [(ngModel)]="option171Value">
          <mat-option *ngFor="let mo of filtredmois" [value]="mo.value">{{mo.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

 
<!-- modal for adding new cop dc-->
<div class="modal-signup"tabindex="-1"role="dialog"[ngStyle]="{'display':displaycop}">
    <div class="modal-content">
      <div class="right-section-signup">
<div class="container-fluid" style="position: relative; height: 500px;overflow-y: auto;">
  <h4>Formulaire de complétude des données des copropriétaires</h4>
  <div class="form-group">
    <label class="col-md-4 control-label">Périodicité</label>
    <div class="col-md-8 inputGroupContainer">
       <div class="input-group"><span class="input-group-addon"><i class="glyphicon glyphicon-time"></i></span>
        <!-- @ts-ignore -->
        <select class="btn btn-select resizeselect"placeholder="periodicite" id="perio"#someHashName20 [(ngModel)]="option0Value"(click)="sort(settedperiodicites)"(change)="patch()">
          <option hidden [value]=""></option>
          <option value=""></option>
    <option *ngFor="let per of settedperiodicites" [value]="per">
      {{per}}
  </option>
          <option hidden value="Autre">Autre</option>
        </select>
        </div>
    </div>
 </div>
<table class="table table-striped"[formGroup]="ajoutdccopform">
    <thead>
    <tr>
      <th>N°</th>
      <th>copropriétaires</th>
      <th>annee</th>            
      <th>périodicité</th>            
      <th>montant par échéance</th>              
    </tr>
  </thead>

  <tbody formArrayName="ammounts10">
  
    <tr *ngFor="let ammount of ammountControls10; let u = index;"[formGroupName]="u">
      <td>
        <div >
             <input type="text"  
           class="form-control-special"formControlName="numero"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
        </div>
      </td>
      <td>
        <div >
             <input type="text"  
           class="form-control-special"formControlName="coproprietaire"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
        </div>
      </td>
      <td style="width: 100px">
        <div >
             <input type="text"  
           class="form-control-special"formControlName="annee"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
        </div>
      </td>

            <td style="width: 150px;display: block;">
              <div >
                <select class="btn btn-select resizeselect"style="pointer-events: none;"onclick="return false;" onkeydown="return false;"placeholder="periodicite" id="perio"#someHashName20 formControlName="periodicite" name="periodicite" (click)="sort()"readonly='true'>
                  <option hidden [value]=""></option>
                  <option value=""></option>
            <option *ngFor="let per of settedperiodicites" [value]="per">
              {{per}}
          </option>
                  <option hidden value="Autre">Autre</option>
                </select>
                  </div>
                  <div *ngIf="someHashName20?.value == 'Autre'">
                    <input class="form-control-special" type="text"(change)="onChangecop(u)" placeholder="perio" id="period" formControlName="autreperiodicite">
                  </div>
            </td> 
            <td>
              <div >
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                 class="form-control-special"formControlName="montantecheance"(blur)="setThreeNumberDecimal($event)"placeholder="Montant"/>
              </div>
            </td>
       
            
    </tr>
  </tbody>
</table>

<div class="modal-footer">
<button type="button" class="btn btn-danger" (click)="closecopPopup()">Annuler</button>
<button type="button" class="btn btn-success" (click)="ajouterdccop()">Valider</button>

</div>
</div>
      </div>
            <!-- Close Button (Top-left corner) -->
            <div class="close-button" (click)="closecopPopup()" style="background-color: #F8F8F9;">
              <i class="fa fa-times" style="color: red; padding-right: 10px;"></i> <!-- Replace with your icon class or element -->
            </div>
    </div>
</div>
                  <fieldset id="impotlist">
                  <legend>Liste Des Impôts à Déclarer:</legend> 
                  <table>
                    
                    <tbody>
                    <tr>
                      
                     <td style="text-align: center;"> 
                        <div><input type="checkbox" id="myCheck8" (click)="myFunction8()"[(ngModel)]="option48Value" /></div>
                        <div><label for="rb1">Retenue à La Source</label></div>
                     </td>
                     <td style="text-align: center;"*ngIf="activite!='Syndic des copropriétaires'&&exportateur!=true">
                        <div><input type="checkbox" id="myCheck9" (click)="myFunction9()"[(ngModel)]="option49Value" /></div>
                        <div><label for="rb1">TFP</label></div>
                      </td>
                      <td style="text-align: center;"*ngIf="exportateur!=true">
                        <div><input type="checkbox" id="myCheck10"(click)="myFunction10()"[(ngModel)]="option50Value" /></div>
                        <div><label for="rb1">Foprolos</label></div>
                      </td>
                      <td style="text-align: center;"*ngIf="activite!='Syndic des copropriétaires'">
                        <div><input type="checkbox" id="myCheck11" (click)="myFunction11()"[(ngModel)]="option51Value" /></div>
                        <div><label for="rb1">TVA</label></div>
                      </td>
                      <td style="text-align: center;"*ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
            activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
            ||activite==='Sage-femmes'">
                        <div><input type="checkbox" id="myCheck30" (click)="myFunction30()"[(ngModel)]="option172Value" /></div>
                        <div><label for="rb1">Fonds de soutien de la santé publique</label></div>
                      </td>
                      <td style="text-align: center;"*ngIf="activite!='Syndic des copropriétaires'&&exportateur!=true">
                        <div><input type="checkbox" id="myCheck12" (click)="myFunction12()"[(ngModel)]="option52Value" /></div>
                        <div><label for="rb1">Droit de timbre</label></div>
                      </td>
                      <td style="text-align: center;"*ngIf="activite!='Syndic des copropriétaires'">
                        <div><input type="checkbox" id="myCheck13" (click)="myFunction13()"[(ngModel)]="option53Value" /></div>
                        <div><label for="rb1">TCL</label></div>
                      </td>
                     
                  </tr>
                  <tr id="verifrow">
                      
                    <td>
                      
                       <div><input *ngIf="showretenueverif===true" type="checkbox" id="myCheck24" (click)="myFunction24()"[(ngModel)]="option65Value" /></div>
                       <div><label  *ngIf="option65Value===true" style="color: green;">Validé</label></div>
                       <div><label  *ngIf="option65Value===false" style="color: red;">Non Validé</label></div>

                       
                    </td>
                    <td *ngIf="activite!='Syndic des copropriétaires'&&exportateur!=true">
                       <div><input  *ngIf="showtfpverif===true" type="checkbox" id="myCheck25" (click)="myFunction25()"[(ngModel)]="option66Value" /></div>
                       <div><label *ngIf="option66Value===true" style="color: green;">Validé</label></div>
                       <div><label  *ngIf="option66Value===false" style="color: red;">Non Validé</label></div>

                     </td>
                     <td *ngIf="exportateur!=true">
                      <div><input *ngIf="showfoprolosverif===true" type="checkbox" id="myCheck26"(click)="myFunction26()"[(ngModel)]="option67Value" /></div>
                        <div><label *ngIf="option67Value===true" style="color: green;">Validé</label></div>
                        <div><label  *ngIf="option67Value===false" style="color: red;">Non Validé</label></div>

                     </td>
                     <td *ngIf="activite!='Syndic des copropriétaires'">
                      <div><input *ngIf="showtvaverif===true" type="checkbox" id="myCheck27" (click)="myFunction27()"[(ngModel)]="option68Value" /></div>
                        <div><label *ngIf="option68Value===true" style="color: green;">Validé</label></div>
                        <div><label  *ngIf="option68Value===false" style="color: red;">Non Validé</label></div>

                     </td>
                     <td style="text-align: center;"*ngIf="activite==='Médecin'||activite==='Infirmier'||activite==='Masseur'||activite==='Physiothérapeute'||
                     activite==='Ergothérapeute'||activite==='Psychomotricien'||activite==='Diététicien'||activite==='Orthophoniste'||activite==='Orthoptiste'
                     ||activite==='Sage-femmes'">
                      <div><input *ngIf="showfspverif===true" type="checkbox" id="myCheck31" (click)="myFunction31()"[(ngModel)]="option173Value" /></div>
                        <div><label *ngIf="option173Value===true" style="color: green;">Validé</label></div>
                        <div><label  *ngIf="option173Value===false" style="color: red;">Non Validé</label></div>

                     </td>
                     <td *ngIf="activite!='Syndic des copropriétaires'&&exportateur!=true">
                      <div><input *ngIf="showtimbreverif===true" type="checkbox" id="myCheck28" (click)="myFunction28()"[(ngModel)]="option69Value" /></div>
                        <div><label *ngIf="option69Value===true" style="color: green;">Validé</label></div>
                        <div><label  *ngIf="option69Value===false" style="color: red;">Non Validé</label></div>

                     </td>
                     <td *ngIf="activite!='Syndic des copropriétaires'">
                      <div><input *ngIf="showtclverif===true" type="checkbox" id="myCheck29" (click)="myFunction29()"[(ngModel)]="option70Value" /></div>
                        <div><label *ngIf="option70Value===true" style="color: green;">Validé</label></div>
                        <div><label  *ngIf="option70Value===false" style="color: red;">Non Validé</label></div>

                     </td>
                    
                 </tr>
                    </tbody>
                  </table>
                </fieldset> 
                   <hr *ngIf="option55Value==true">
                   <div class="container-fluid"id="tabcontainer"> 
                    <button id="savebutton" class="btn btn-success"*ngIf="showretenuetab||showtfptab||showfoprolostab||showtvatab||showtimbretab||showfsptab||showtcltab" type="button" (click)="onSubmit($event)"><i class="fa fa-refresh fa-spin" *ngIf="loading"></i>Sauvegarder</button>
                    <button id="resetbutton" class="btn btn-secondary" type="button" (click)="restartform()"*ngIf="showretenuetab||showtfptab||showfoprolostab||showtvatab||showtimbretab||showfsptab||showtcltab">Réinitialiser tout</button>
                    <button id="recapbutton" type="button"class="btn btn-primary"(click)="openPopup()"*ngIf="showretenuetab||showtfptab||showfoprolostab||showtvatab||showtimbretab||showfsptab||showtcltab">Récapitulatif</button>
                    <div class="modal-overlay" tabindex="-1" role="dialog" [ngStyle]="{'display': displayStyle}" aria-modal="true">
                      <div class="modal-content-wrapper">
                        <div class="modal-content border border-primary rounded shadow-lg p-3 mb-5 bg-white">
                          <div class="right-section-signup">
                            <div class="container-fluid modal-body">
                              <h4 class="text-center mb-3">Liste Des Impôts Introduits</h4>
                              <div class="d-flex justify-content-between mb-3">
                                <label for="">Mois: <strong>{{ option171Value }}</strong></label>
                                <label for="">Année: <strong>{{ option54Value }}</strong></label>
                              </div>
                              <table class="table table-hover table-bordered">
                                <thead class="thead-dark">
                                  <tr>
                                    <th>Type Impôt</th>
                                    <th>Montant</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngIf="showretenuetab">
                                    <td>Retenue à la source</td>
                                    <td>{{ totalretenueammount | number:'1.3-3' | removeComma }}</td>
                                  </tr>
                                  <tr *ngIf="showtfptab">
                                    <td>TFP</td>
                                    <td *ngIf="tfpareporter > 0">
                                      {{ totaltfpammount | number:'1.3-3' | removeComma }} / ({{ tfpareporter | number:'1.3-3' }}) / <strong>R</strong>
                                    </td>
                                    <td *ngIf="tfpapayer >= 0">
                                      {{ totaltfpammount | number:'1.3-3' | removeComma }} / <strong>P</strong>
                                    </td>
                                  </tr>
                                  <tr *ngIf="showfoprolostab">
                                    <td>Foprolos</td>
                                    <td>{{ totalfoprolosammount | number:'1.3-3' | removeComma }}</td>
                                  </tr>
                                  <tr *ngIf="showtvatab">
                                    <td>TVA</td>
                                    <td *ngIf="totaltvaammount >= 0">
                                      {{ totaltvaammount | number:'1.3-3' | removeComma }} / <strong>P</strong>
                                    </td>
                                    <td *ngIf="totalreporttvaammount > 0 && totaltvaammount == 0">
                                      {{ totaltvaammount | number:'1.3-3' | removeComma }} / ({{ totalreporttvaammount | number:'1.3-3' }}) / <strong>R</strong>
                                    </td>
                                  </tr>
                                  <tr *ngIf="showfsptab">
                                    <td>FSSP</td>
                                    <td>{{ totalfspammount | number:'1.3-3' | removeComma }}</td>
                                  </tr>
                                  <tr *ngIf="showtimbretab">
                                    <td>Droit De Timbre</td>
                                    <td>{{ totaltimbreammount | number:'1.3-3' | removeComma }}</td>
                                  </tr>
                                  <tr *ngIf="showtcltab">
                                    <td>TCL</td>
                                    <td>{{ totaltclammount | number:'1.3-3' | removeComma }}</td>
                                  </tr>
                                  <tr>
                                    <td>Minimum de Perception</td>
                                    <td>{{ minimumperceptionammount | number:'1.3-3' | removeComma }}</td>
                                  </tr>
                                  <tr>
                                    <td>Total à payer</td>
                                    <td>{{ totaldeclaration | number:'1.3-3' | removeComma }}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="modal-footer d-flex justify-content-end">
                                <button type="button" class="btn btn-danger" (click)="closePopup()">Close</button>
                              </div>
                            </div>
                          </div>
                          <!-- Close Button (Top-right corner) -->
                          <div class="close-button" (click)="closePopup()" aria-label="Close">
                            <i class="fa fa-times close-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                    <mat-tab-group (selectedTabChange)="onTabClick($event)">
                    <!-- retenue à la source tab -->                   
                      <mat-tab label="Retenue à La Source" *ngIf="showretenuetab===true"> 
                        <!-- 1ère étape de choice-->
                        <div title="Sélectionner la rubrique convenable"class="input-container"id="retenuelist">
                          <label for="retenuelist">Retenue à La Source sur:</label>
                          <i class="fas fa-wallet icon-special"></i>
                          <select #cricket (change)="update($event)"[(ngModel)]="optionValue">
                          <option value="default"> </option>
                          <option *ngFor="let retenue of retenues" [value]="retenue">
                              {{retenue}}
                          </option>
                      </select>
                      </div>
                      <div class="input-container"id="selectretenue"*ngIf="optionValue == 'Autre'">
                        <label for="retenue"> Total des Retenues à La Source sur:</label>
                        <i class="fas fa-wallet icon-special"></i>
                        <input class="input-field" type="text" placeholder="Renseigner l'intitulé de la rubrique"[(ngModel)]="option2Value" id="selectretenue" >
                      </div>
                      <hr *ngIf="optionValue">
                       <!-- autre form-->
                       <div class="container-fluid"*ngIf="optionValue == 'Autre'">
                         <fieldset >
                          <legend *ngIf="optionValue == 'Autre'"> {{option2Value}}</legend>
                        <form [formGroup]="autreform"(ngSubmit)="logValue()">
                          <h2 *ngIf="optionValue != 'Autre'">{{optionValue}}</h2>
                          <button type="button" class="btn btn-primary mb-2" (click)="addammount()">Ajouter Montant</button>
                      <fieldset formArrayName="ammounts" 
                        *ngFor="let ammount of ammountControls; let i = index;">
                        <div [formGroupName]="i">
                          <div class="row">
                            <div class="col-6">
                                <h4>Rubrique {{i + 1}}</h4>
                                <div class="col-6 text-right">
                                  <button type="button" class="btn btn-danger btn-sm" (click)="removeammount(i)">Supprimer Montant</button>
                              </div>
                            </div>
                            <div class="container-fluid">
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Titre</label>
                                      <input type="text" class="form-control" formControlName="title" placeholder="désignation montant"/>
                                    </div>
                              </div>
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Montant</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                    </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Description</label>
                                    <input type="text" class="form-control" formControlName="description" placeholder="décrivez Ce Montant"/>
                                  </div>
                            </div>
                            </div>
                          </div>
                        </div>
                          </fieldset>
                          <div class="text-right">
                            <input type="submit" class="hidden" value="Submit"/>
                        </div>
                        </form>
                      </fieldset>
                      </div>
                      <!-- traitements et salaires form-->
                      
                      <div class="container-fluid"*ngIf="optionValue == 'traitements et salaires'">
                        <form  [formGroup]="standardtraitementsalaireform">
                          
                          
                          <fieldset>
                            <legend>{{optionValue}}</legend>
                            <div class="row">
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Bruts</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" (blur)="setThreeNumberDecimal($event)"formControlName="brutsalary"placeholder="Montant en DT et à 3 chiffres après la virgule"  />
                                    </div>
                              </div>
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Imposables</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="imposalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                    </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Total des Retenues à La Source</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="retenuesalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Contribution sociale de solidarité</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="solidaritycontribution" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      min="0" oninput="validity.valid||(value='');"/>
                                </div> 
                          </div>
                          
                            </div>
                           </fieldset>
                        </form>
                      </div>
<!-- loyers, commissions, courtages et vacations section-->
                    <div class="container-fluid"*ngIf="optionValue == 'loyers, commissions, courtages et vacations'">
                        <fieldset>
                          <legend>{{optionValue}}</legend>
                        
                      <table class="table table-striped">
                        <thead>
                          <tr>
                      
                            
          <th>Rubrique loyers, commissions, courtages et vacations</th>
          <th>Total des montants bruts</th>
          <th>Taux</th>
          <th>Montant total des retenues à la source</th>
          <th>Total des montants nets</th>
          
                           
                            
                          </tr>
                          </thead>
                          
                        <tbody>
<!-- loyers, commissions, courtages et vacations (résidents et personnes phyisiques)form--> 
                        <tr id="tvaform" [formGroup]="standardlocationresidentesphysiqueform">
                          <td>
                            <p> Servis aux personnes physiques résidentes </p>
                          </td>
                           <td>  <div class="col-6">
                            
                                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              
                        </div></td>
                        <td><div class="col-6">
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                            
                      </div></td>
                      <td><div class="col-6"(click)="setThreeNumberDecimal($event)">
                        
                            <input type="number"  class="form-control"  formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                      
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                        
                  </div></td>
                  
                      </tr>
                      <!-- loyers, commissions, courtages et vacations (résidents et personnes morales)form--> 
                      <tr id="tvaform" [formGroup]="standardlocationresidentesmoraleform">
                        <td>
                          <p> Servis aux personnes morales résidentes </p>
                        </td>
                         <td>  <div class="col-6">
                          
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6">
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                          
                    </div></td>
                    <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                  </div></td>
                  <td><div class="col-6">
                    
                         <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                      
                </div></td>
                
                    </tr>
                     <!-- loyers, commissions, courtages et vacations (non résidents et personnes physiques)form--> 
                     <tr id="tvaform" [formGroup]="standardlocationnonresidentesphysiquesform">
                      <td>
                        <p> Servis aux personnes physiques non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>
                    <!-- loyers, commissions, courtages et vacations (non résidents et personnes morales)form--> 
                    <tr id="tvaform" [formGroup]="standardlocationnonresidentesmoralesform">
                      <td>
                        <p> Servis aux personnes morales non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>  
                      </table>
                    </fieldset>
  
    
       
  
  

                    </div>
<!-- honoraires section-->
<div class="container-fluid"*ngIf="optionValue == 'honoraires'">
<fieldset>
    <legend>{{optionValue}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique honoraires</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- honoraires (servis aux personnes physiques et soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquereelform">
    <td>
      <p> Servis aux personnes physiques soumis au régime réel </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6">
                          
                              <input type="text" class="form-control" value="3%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- honoraires (servis aux personnes physiques et non soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquenonreelform">
  <td>
    <p> Servis aux personnes physiques non soumises au régime réel </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6">
                          
                              <input type="text" class="form-control" value="10%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- honoraires (servis aux sociétés et aux groupements)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairegroupementsform">
  <td>
    <p> Servis aux aux sociétés et aux groupements </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
</table>
</fieldset>

      
        </div>
<!-- mont supérieures à 1000 dt section-->
<div class="container-fluid"*ngIf="optionValue == 'montants supérieurs à 1000 dt'">
<fieldset>
    <legend>{{optionValue}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique montants supérieures à 1000 dt</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 15%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant15form">
    <td>
      <p> établissements soumis à l'i/s au taux de 15% </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 10%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant10form">
  <td>
    <p> établissements soumis à l'i/s au taux de 10% </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements individuels et éligible à la réduction des 2/3 des revenus)form-->                    
<tr id="tvaform" [formGroup]="standardmontantindividuelform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section  (autre établissements)form-->                    
<tr id="tvaform" [formGroup]="standardmontantautreform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>  
</table>
</fieldset>    

</div>
<!-- 1ère étape de décision-->
<hr style="height:2px;border-width:0;color:gray;background-color:gray">
                      <div title="indiquer votre choix"class="col-6"*ngIf="optionValue ">
                        <div class="form-group">
                        <label for="choice"> Avez vous d'autres retenues à la source à déclarer:</label>    
                        <div id="block1"><input type="checkbox"  id="choice"class="input-field"(click)="myFunction14()"  [(ngModel)]="option4Value">Oui</div>  
                        <div id="block2"><input type="checkbox"  id="choice1"class="input-field" [(ngModel)]="option139Value"(click)="myFunction15()">Non</div>
                  </div>
                </div>
<!-- 2ème étape de choice-->

                      <div title="Sélectionner la rubrique convenable"class="input-container"id="retenuelist"*ngIf="option4Value">
                        <label for="retenuelist">Retenue à La Source sur:</label>
                        <i class="fas fa-wallet icon-special"></i>
                        <select #cricket (change)="update($event)"[(ngModel)]="option3Value">
                          <option value="default"> </option>
                          <option *ngFor="let retenue of findfiltredretenue(retenues)" [value]="retenue">
                              {{retenue}}
                          </option>
                      </select>
                    </div>

                    <div class="input-container"id="selectretenue"*ngIf="option3Value == 'Autre'">
                      <label for="retenue"> Total des Retenues à La Source sur:</label>
                      <i class="fas fa-wallet icon-special"></i>
                      <input class="input-field" type="text" placeholder="Renseigner l'intitulé de la rubrique" [(ngModel)]="option11Value"id="selectretenue" >
                    </div>
                    <hr>
                    <h2 *ngIf="option3Value == 'Autre'">{{option11Value}}</h2>
                       <!-- autre form-->
                      
                       <div class="container-fluid"*ngIf="option3Value == 'Autre' &&option4Value">
                         
                        <form [formGroup]="autreform"(ngSubmit)="logValue()">
                          
                          <button type="button" class="btn btn-primary mb-2" (click)="addammount()">Ajouter Montant</button>
                      <fieldset formArrayName="ammounts" 
                        *ngFor="let ammount of ammountControls; let i = index;">
                        <div [formGroupName]="i">
                          <div class="row">
                            <div class="col-6">
                                <h4>Rubrique {{i + 1}}</h4>
                                <div class="col-6 text-right">
                                  <button type="button" class="btn btn-danger btn-sm" (click)="removeammount(i)">Supprimer Montant</button>
                              </div>
                            </div>
                            <div class="container-fluid">
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Titre</label>
                                      <input type="text" class="form-control" formControlName="title" placeholder="désignation montant"/>
                                    </div>
                              </div>
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Montant</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                    </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Description</label>
                                    <input type="text" class="form-control" formControlName="description" placeholder="décrivez Ce Montant"/>
                                  </div>
                            </div>
                            </div>
                          </div>
                        </div>
                          </fieldset>

                          <div class="text-right">
                              <input type="submit" class="hidden" value="Submit"/>
                          </div>
                        </form>
                      </div>
                     <!-- traitements et salaires form-->
                      
                     <div class="container-fluid"*ngIf="option3Value == 'traitements et salaires' &&option4Value">
                      <form  [formGroup]="standardtraitementsalaireform">
                        
                        
                        <fieldset>
                          <legend>{{option3Value}}</legend>
                          <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Total des Salaires Bruts</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutsalary" placeholder="Montant en DT et à 3 chiffres après la virgule"  />
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Total des Salaires Imposables</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="imposalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                    />
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Total des Retenues à La Source</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="retenuesalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                    />
                                </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                                <label>Contribution sociale de solidarité</label>
                                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="solidaritycontribution" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                    min="0" oninput="validity.valid||(value='');"/>
                              </div> 
                        </div>
                        
                          </div>
                         </fieldset>
                      </form>
                    </div>
<!-- loyers, commissions, courtages et vacations section-->
                  <div class="container-fluid"*ngIf="option3Value == 'loyers, commissions, courtages et vacations'&&option4Value">
                      <fieldset>
                        <legend>{{option3Value}}</legend>
                      
                    <table class="table table-striped">
                      <thead>
                        <tr>
                    
                          
        <th>Rubrique loyers, commissions, courtages et vacations</th>
        <th>Total des montants bruts</th>
        <th>Taux</th>
        <th>Montant total des retenues à la source</th>
        <th>Total des montants nets</th>
        
                         
                          
                        </tr>
                        </thead>
                        
                      <tbody>
<!-- loyers, commissions, courtages et vacations (résidents et personnes phyisiques)form--> 
                      <tr id="tvaform" [formGroup]="standardlocationresidentesphysiqueform">
                        <td>
                          <p> Servis aux personnes physiques résidentes </p>
                        </td>
                         <td>  <div class="col-6">
                          
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                          
                    </div></td>
                    <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                  </div></td>
                  <td><div class="col-6">
                    
                         <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                      
                </div></td>
                
                    </tr>
                    <!-- loyers, commissions, courtages et vacations (résidents et personnes morales)form--> 
                    <tr id="tvaform" [formGroup]="standardlocationresidentesmoraleform">
                      <td>
                        <p> Servis aux personnes morales résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>
                   <!-- loyers, commissions, courtages et vacations (non résidents et personnes physiques)form--> 
                   <tr id="tvaform" [formGroup]="standardlocationnonresidentesphysiquesform">
                    <td>
                      <p> Servis aux personnes physiques non résidentes </p>
                    </td>
                     <td>  <div class="col-6">
                      
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                  </div></td>
                  <td><div class="col-6">
                    
                        <input type="text" class="form-control" value="15%" disabled/>
                      
                </div></td>
                <td><div class="col-6">
                  
                      <input type="number"  class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
              </div></td>
              <td><div class="col-6">
                
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                  
                  
            </div></td>
            
                </tr>
                  <!-- loyers, commissions, courtages et vacations (non résidents et personnes morales)form--> 
                  <tr id="tvaform" [formGroup]="standardlocationnonresidentesmoralesform">
                    <td>
                      <p> Servis aux personnes morales non résidentes </p>
                    </td>
                     <td>  <div class="col-6">
                      
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                  </div></td>
                  <td><div class="col-6">
                    
                        <input type="text" class="form-control" value="15%" disabled/>
                      
                </div></td>
                <td><div class="col-6">
                  
                      <input type="number"  class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
              </div></td>
              <td><div class="col-6">
                
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                  
                  
            </div></td>
            
                </tr>  
                    </table>
                  </fieldset>

  
     



                  </div>
<!-- honoraires section-->
<div class="container-fluid"*ngIf="option3Value == 'honoraires'&&option4Value">
<fieldset>
  <legend>{{option3Value}}</legend>

<table class="table table-striped">
<thead>
  <tr>

    
<th>Rubrique honoraires</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

   
    
  </tr>
  </thead>
  
<tbody>
<!-- honoraires (servis aux personnes physiques et soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquereelform">
  <td>
    <p> Servis aux personnes physiques soumis au régime réel </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- honoraires (servis aux personnes physiques et non soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquenonreelform">
<td>
  <p> Servis aux personnes physiques non soumises au régime réel </p>
</td>
 <td>  <div class="col-6">
  
      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

    <input type="text" class="form-control" value="10%" disabled/>
  
</div></td>
<td><div class="col-6">

  <input type="number"  class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>

</div></td>
<td><div class="col-6">

 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- honoraires (servis aux sociétés et aux groupements)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairegroupementsform">
<td>
  <p> Servis aux aux sociétés et aux groupements </p>
</td>
 <td>  <div class="col-6">
  
      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

    <input type="text" class="form-control" value="3%" disabled/>
  
</div></td>
<td><div class="col-6">

  <input type="number"  class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>

</div></td>
<td><div class="col-6">

 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
</table>
</fieldset>

    
      </div>
<!-- mont supérieures à 1000 dt section-->
<div class="container-fluid"*ngIf="option3Value == 'montants supérieurs à 1000 dt'&&option4Value">
<fieldset>
  <legend>{{option3Value}}</legend>

<table class="table table-striped">
<thead>
  <tr>

    
<th>Rubrique montants supérieures à 1000 dt</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

   
    
  </tr>
  </thead>
  
<tbody>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 15%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant15form">
  <td>
    <p> établissements soumis à l'i/s au taux de 15% </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 10%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant10form">
<td>
  <p> établissements soumis à l'i/s au taux de 10% </p>
</td>
 <td>  <div class="col-6">
  
      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

    <input type="text" class="form-control" value="0.5%" disabled/>
  
</div></td>
<td><div class="col-6">

  <input type="number"  class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>

</div></td>
<td><div class="col-6">

 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements individuels et éligible à la réduction des 2/3 des revenus)form-->                    
<tr id="tvaform" [formGroup]="standardmontantindividuelform">
<td>
  <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
</td>
 <td>  <div class="col-6">
  
      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

    <input type="text" class="form-control" value="0.5%" disabled/>
  
</div></td>
<td><div class="col-6">

  <input type="number"  class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>

</div></td>
<td><div class="col-6">

 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section  (autre établissements)form-->                    
<tr id="tvaform" [formGroup]="standardmontantautreform">
<td>
  <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
</td>
 <td>  <div class="col-6">
  
      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

    <input type="text" class="form-control" value="1.5%" disabled/>
  
</div></td>
<td><div class="col-6">

  <input type="number"  class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>

</div></td>
<td><div class="col-6">

 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>  
</table>
</fieldset>    

</div>                  
<!-- 2èmee étape de décision-->
<hr style="height:2px;border-width:0;color:gray;background-color:gray">
                      <div title="indiquer votre choix"class="col-6"*ngIf="option4Value">
                        <div class="form-group">
                        <label for="choice"> Avez vous d'autres retenues à la source à déclarer:</label>    
                        <div id="block3"><input type="checkbox"  class="input-field" id="choice2" (click)="myFunction16()"[(ngModel)]="option18Value" name="choice">Oui</div>  
                        <div id="block4"><input type="checkbox"  class="input-field" id="choice3" (click)="myFunction17()"[(ngModel)]="option60Value" name="choice">Non</div>
                  </div>
                </div>
<!-- 3ème étape de choice-->
                      <div title="Sélectionner la rubrique convenable"class="input-container"id="retenuelist"*ngIf="option18Value">
                        <label for="retenuelist">Retenue à La Source sur:</label>
                        <i class="fas fa-wallet icon-special"></i>
                        <select #cricket (change)="update($event)"[(ngModel)]="option19Value">
                          <option value="default"> </option>
                          <option *ngFor="let retenue of findfiltredretenue2(retenues)" [value]="retenue">
                              {{retenue}}
                          </option>
                      </select>
                    </div>

                    <div class="input-container"id="selectretenue"*ngIf="option19Value == 'Autre'">
                      <label for="retenue"> Total des Retenues à La Source sur:</label>
                      <i class="fas fa-wallet icon-special"></i>
                      <input class="input-field" type="text" placeholder="Renseigner l'intitulé de la rubrique" [(ngModel)]="option20Value"id="selectretenue" >
                    </div>
                    <hr>
                    <h2 *ngIf="option19Value == 'Autre'">{{option20Value}}</h2>
                    <!-- autre form-->
                      
                    <div class="container-fluid"*ngIf="option19Value == 'Autre'&&option18Value">
                         
                      <form [formGroup]="autreform"(ngSubmit)="logValue()">
                        
                        <button type="button" class="btn btn-primary mb-2" (click)="addammount()">Ajouter Montant</button>
                    <fieldset formArrayName="ammounts" 
                      *ngFor="let ammount of ammountControls; let i = index;">
                      <div [formGroupName]="i">
                        <div class="row">
                          <div class="col-6">
                              <h4>Rubrique {{i + 1}}</h4>
                              <div class="col-6 text-right">
                                <button type="button" class="btn btn-danger btn-sm" (click)="removeammount(i)">Supprimer Montant</button>
                            </div>
                          </div>
                          <div class="container-fluid">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Titre</label>
                                    <input type="text" class="form-control" formControlName="title" placeholder="désignation montant"/>
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Montant</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Description</label>
                                  <input type="text" class="form-control" formControlName="description" placeholder="décrivez Ce Montant"/>
                                </div>
                          </div>
                          </div>
                        </div>
                      </div>
                        </fieldset>

                        <div class="text-right">
                            <input type="submit" class="hidden" value="Submit"/>
                        </div>
                      </form>
                    </div>
                    <!-- traitements et salaires form-->                 
                      
                      <div class="container-fluid"*ngIf="option19Value == 'traitements et salaires'&&option18Value">
                        <form  [formGroup]="standardtraitementsalaireform">
                          
                          
                          <fieldset>
                            <legend>{{option19Value}}</legend>
                            <div class="row">
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Bruts</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutsalary" placeholder="Montant en DT et à 3 chiffres après la virgule"  />
                                    </div>
                              </div>
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Imposables</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="imposalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                    </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Total des Retenues à La Source</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="retenuesalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Contribution sociale de solidarité</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="solidaritycontribution" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      min="0" oninput="validity.valid||(value='');"/>
                                </div> 
                          </div>
                          
                            </div>
                           </fieldset>
                        </form>
                      </div>
<!-- loyers, commissions, courtages et vacations section-->
                    <div class="container-fluid"*ngIf="option19Value == 'loyers, commissions, courtages et vacations'&&option18Value">
                        <fieldset>
                          <legend>{{option19Value}}</legend>
                        
                      <table class="table table-striped">
                        <thead>
                          <tr>
                      
                            
          <th>Rubrique loyers, commissions, courtages et vacations</th>
          <th>Total des montants bruts</th>
          <th>Taux</th>
          <th>Montant total des retenues à la source</th>
          <th>Total des montants nets</th>
          
                           
                            
                          </tr>
                          </thead>
                          
                        <tbody>
<!-- loyers, commissions, courtages et vacations (résidents et personnes phyisiques)form--> 
                        <tr id="tvaform" [formGroup]="standardlocationresidentesphysiqueform">
                          <td>
                            <p> Servis aux personnes physiques résidentes </p>
                          </td>
                           <td>  <div class="col-6">
                            
                                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              
                        </div></td>
                        <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                            
                      </div></td>
                      <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                      
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                        
                  </div></td>
                  
                      </tr>
                      <!-- loyers, commissions, courtages et vacations (résidents et personnes morales)form--> 
                      <tr id="tvaform" [formGroup]="standardlocationresidentesmoraleform">
                        <td>
                          <p> Servis aux personnes morales résidentes </p>
                        </td>
                         <td>  <div class="col-6">
                          
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                          
                    </div></td>
                    <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                  </div></td>
                  <td><div class="col-6">
                    
                         <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                      
                </div></td>
                
                    </tr>
                     <!-- loyers, commissions, courtages et vacations (non résidents et personnes physiques)form--> 
                     <tr id="tvaform" [formGroup]="standardlocationnonresidentesphysiquesform">
                      <td>
                        <p> Servis aux personnes physiques non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>
                    <!-- loyers, commissions, courtages et vacations (non résidents et personnes morales)form--> 
                    <tr id="tvaform" [formGroup]="standardlocationnonresidentesmoralesform">
                      <td>
                        <p> Servis aux personnes morales non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>  
                      </table>
                    </fieldset>
  
    
       
  
  

                    </div>
<!-- honoraires section-->
<div class="container-fluid"*ngIf="option19Value == 'honoraires'&&option18Value">
<fieldset>
    <legend>{{option19Value}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique honoraires</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- honoraires (servis aux personnes physiques et soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquereelform">
    <td>
      <p> Servis aux personnes physiques soumis au régime réel </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- honoraires (servis aux personnes physiques et non soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquenonreelform">
  <td>
    <p> Servis aux personnes physiques non soumises au régime réel </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- honoraires (servis aux sociétés et aux groupements)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairegroupementsform">
  <td>
    <p> Servis aux aux sociétés et aux groupements </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
</table>
</fieldset>

      
        </div>
<!-- mont supérieures à 1000 dt section-->
<div class="container-fluid"*ngIf="option19Value == 'montants supérieurs à 1000 dt'&&option18Value">
<fieldset>
    <legend>{{option19Value}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique montants supérieures à 1000 dt</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 15%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant15form">
    <td>
      <p> établissements soumis à l'i/s au taux de 15% </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 10%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant10form">
  <td>
    <p> établissements soumis à l'i/s au taux de 10% </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements individuels et éligible à la réduction des 2/3 des revenus)form-->                    
<tr id="tvaform" [formGroup]="standardmontantindividuelform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section  (autre établissements)form-->                    
<tr id="tvaform" [formGroup]="standardmontantautreform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>  
</table>
</fieldset>    

</div>                   
<!-- 3èmee étape de décision-->
<hr style="height:2px;border-width:0;color:gray;background-color:gray">
                      <div title="indiquer votre choix"class="col-6"*ngIf="option18Value">
                        <div class="form-group">
                        <label for="choice"> Avez vous d'autres retenues à la source à déclarer:</label> 
                        <div id="block5"><input type="checkbox"  class="input-field" id="choice4" (click)="myFunction18()"[(ngModel)]="option27Value" name="choice">Oui</div>  
                          <div id="block6"><input type="checkbox"  class="input-field" id="choice5" (click)="myFunction19()"[(ngModel)]="option61Value" name="choice">Non</div>  
                        
                  </div>
                </div>
<!-- 4ème étape de choice-->
                      <div title="Sélectionner la rubrique convenable"class="input-container"id="retenuelist"*ngIf="option27Value">
                        <label for="retenuelist">Retenue à La Source sur:</label>
                        <i class="fas fa-wallet icon-special"></i>
                        <select #cricket (change)="update($event)"[(ngModel)]="option29Value">
                          <option value="default"> </option>
                          <option *ngFor="let retenue of findfiltredretenue3(retenues)" [value]="retenue">
                              {{retenue}}
                          </option>
                      </select>
                    </div>

                    <div class="input-container"id="selectretenue"*ngIf="option29Value == 'Autre'">
                      <label for="retenue"> Total des Retenues à La Source sur:</label>
                      <i class="fas fa-wallet icon-special"></i>
                      <input class="input-field" type="text" placeholder="Renseigner l'intitulé de la rubrique" [(ngModel)]="option11Value"id="selectretenue" >
                    </div>
                    <hr>
                    <h2 *ngIf="option29Value == 'Autre'">{{option29Value}}</h2>
                    <!-- autre form-->
                      
                    <div class="container-fluid"*ngIf="option29Value == 'Autre'&&option27Value">
                         
                      <form [formGroup]="autreform"(ngSubmit)="logValue()">
                        
                        <button type="button" class="btn btn-primary mb-2" (click)="addammount()">Ajouter Montant</button>
                    <fieldset formArrayName="ammounts" 
                      *ngFor="let ammount of ammountControls; let i = index;">
                      <div [formGroupName]="i">
                        <div class="row">
                          <div class="col-6">
                              <h4>Rubrique {{i + 1}}</h4>
                              <div class="col-6 text-right">
                                <button type="button" class="btn btn-danger btn-sm" (click)="removeammount(i)">Supprimer Montant</button>
                            </div>
                          </div>
                          <div class="container-fluid">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Titre</label>
                                    <input type="text" class="form-control" formControlName="title" placeholder="désignation montant"/>
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Montant</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Description</label>
                                  <input type="text" class="form-control" formControlName="description" placeholder="décrivez Ce Montant"/>
                                </div>
                          </div>
                          </div>
                        </div>
                      </div>
                        </fieldset>

                        <div class="text-right">
                            <input type="submit" class="hidden" value="Submit"/>
                        </div>
                      </form>
                    </div>
                      <!-- traitements et salaires form-->
                      
                      <div class="container-fluid"*ngIf="option29Value == 'traitements et salaires'&&option27Value">
                        <form  [formGroup]="standardtraitementsalaireform">
                          
                          
                          <fieldset>
                            <legend>{{option29Value}}</legend>
                            <div class="row">
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Bruts</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutsalary" placeholder="Montant en DT et à 3 chiffres après la virgule"  />
                                    </div>
                              </div>
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Imposables</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="imposalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                    </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Total des Retenues à La Source</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="retenuesalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Contribution sociale de solidarité</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="solidaritycontribution" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      min="0" oninput="validity.valid||(value='');"/>
                                </div> 
                          </div>
                          
                            </div>
                           </fieldset>
                        </form>
                      </div>
<!-- loyers, commissions, courtages et vacations section-->
                    <div class="container-fluid"*ngIf="option29Value == 'loyers, commissions, courtages et vacations'&&option27Value">
                        <fieldset>
                          <legend>{{option29Value}}</legend>
                        
                      <table class="table table-striped">
                        <thead>
                          <tr>
                      
                            
          <th>Rubrique loyers, commissions, courtages et vacations</th>
          <th>Total des montants bruts</th>
          <th>Taux</th>
          <th>Montant total des retenues à la source</th>
          <th>Total des montants nets</th>
          
                           
                            
                          </tr>
                          </thead>
                          
                        <tbody>
<!-- loyers, commissions, courtages et vacations (résidents et personnes phyisiques)form--> 
                        <tr id="tvaform" [formGroup]="standardlocationresidentesphysiqueform">
                          <td>
                            <p> Servis aux personnes physiques résidentes </p>
                          </td>
                           <td>  <div class="col-6">
                            
                                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              
                        </div></td>
                        <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                            
                      </div></td>
                      <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                      
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                        
                  </div></td>
                  
                      </tr>
                      <!-- loyers, commissions, courtages et vacations (résidents et personnes morales)form--> 
                      <tr id="tvaform" [formGroup]="standardlocationresidentesmoraleform">
                        <td>
                          <p> Servis aux personnes morales résidentes </p>
                        </td>
                         <td>  <div class="col-6">
                          
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                          
                    </div></td>
                    <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                  </div></td>
                  <td><div class="col-6">
                    
                         <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                      
                </div></td>
                
                    </tr>
                     <!-- loyers, commissions, courtages et vacations (non résidents et personnes physiques)form--> 
                     <tr id="tvaform" [formGroup]="standardlocationnonresidentesphysiquesform">
                      <td>
                        <p> Servis aux personnes physiques non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>
                    <!-- loyers, commissions, courtages et vacations (non résidents et personnes morales)form--> 
                    <tr id="tvaform" [formGroup]="standardlocationnonresidentesmoralesform">
                      <td>
                        <p> Servis aux personnes morales non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>  
                      </table>
                    </fieldset>
  
    
       
  
  

                    </div>
<!-- honoraires section-->
<div class="container-fluid"*ngIf="option29Value == 'honoraires'&&option27Value">
<fieldset>
    <legend>{{option29Value}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique honoraires</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- honoraires (servis aux personnes physiques et soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquereelform">
    <td>
      <p> Servis aux personnes physiques soumis au régime réel </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- honoraires (servis aux personnes physiques et non soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquenonreelform">
  <td>
    <p> Servis aux personnes physiques non soumises au régime réel </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- honoraires (servis aux sociétés et aux groupements)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairegroupementsform">
  <td>
    <p> Servis aux aux sociétés et aux groupements </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
</table>
</fieldset>

      
        </div>
<!-- mont supérieures à 1000 dt section-->
<div class="container-fluid"*ngIf="option29Value == 'montants supérieurs à 1000 dt'&&option27Value">
<fieldset>
    <legend>{{option29Value}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique montants supérieures à 1000 dt</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 15%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant15form">
    <td>
      <p> établissements soumis à l'i/s au taux de 15% </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 10%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant10form">
  <td>
    <p> établissements soumis à l'i/s au taux de 10% </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements individuels et éligible à la réduction des 2/3 des revenus)form-->                    
<tr id="tvaform" [formGroup]="standardmontantindividuelform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section  (autre établissements)form-->                    
<tr id="tvaform" [formGroup]="standardmontantautreform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>  
</table>
</fieldset>    

</div>                   
<!-- 4èmee étape de décision-->
<hr style="height:2px;border-width:0;color:gray;background-color:gray">
                      <div title="indiquer votre choix"class="col-6"*ngIf="option27Value">
                        <div class="form-group">
                        <label for="choice"> Avez vous d'autres retenues à la source à déclarer:</label>
                        <div id="block7"><input type="checkbox"  class="input-field" id="choice6" (click)="myFunction20()"[(ngModel)]="option38Value" name="choice">Oui</div>  
                        <div id="block8"><input type="checkbox"  class="input-field" id="choice7" (click)="myFunction21()"[(ngModel)]="option62Value" name="choice">Non</div>     
                        
                  </div>
                </div>
<!-- 5ème étape de choice-->
                      <div title="Sélectionner la rubrique convenable"class="input-container"id="retenuelist"*ngIf="option38Value">
                        <label for="retenuelist">Retenue à La Source sur:</label>
                        <i class="fas fa-wallet icon-special"></i>
                        <select #cricket (change)="update($event)"[(ngModel)]="option40Value">
                          <option value="default"> </option>
                          <option *ngFor="let retenue of findfiltredretenue4(retenues)" [value]="retenue">
                              {{retenue}}
                          </option>
                      </select>
                    </div>
                    <div class="input-container"id="selectretenue"*ngIf="option40Value == 'Autre'">
                      <label for="retenue"> Total des Retenues à La Source sur:</label>
                      <i class="fas fa-wallet icon-special"></i>
                      <input class="input-field" type="text" placeholder="Renseigner l'intitulé de la rubrique" [(ngModel)]="option41Value"id="selectretenue" >
                    </div>
                    <hr>
                    <h2 *ngIf="option40Value == 'Autre'">{{option40Value}}</h2>
                    <!-- autre form-->
                      
                    <div class="container-fluid"*ngIf="option40Value == 'Autre'&&option38Value">
                         
                      <form [formGroup]="autreform"(ngSubmit)="logValue()">
                        
                        <button type="button" class="btn btn-primary mb-2" (click)="addammount()">Ajouter Montant</button>
                    <fieldset formArrayName="ammounts" 
                      *ngFor="let ammount of ammountControls; let i = index;">
                      <div [formGroupName]="i">
                        <div class="row">
                          <div class="col-6">
                              <h4>Rubrique {{i + 1}}</h4>
                              <div class="col-6 text-right">
                                <button type="button" class="btn btn-danger btn-sm" (click)="removeammount(i)">Supprimer Montant</button>
                            </div>
                          </div>
                          <div class="container-fluid">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Titre</label>
                                    <input type="text" class="form-control" formControlName="title" placeholder="désignation montant"/>
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Montant</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Description</label>
                                  <input type="text" class="form-control" formControlName="description" placeholder="décrivez Ce Montant"/>
                                </div>
                          </div>
                          </div>
                        </div>
                      </div>
                        </fieldset>

                        <div class="text-right">
                            <input type="submit" class="hidden" value="Submit"/>
                        </div>
                      </form>
                    </div>
                      <!-- traitements et salaires form-->
                      
                      <div class="container-fluid"*ngIf="option40Value == 'traitements et salaires'&&option38Value">
                        <form  [formGroup]="standardtraitementsalaireform">
                          
                          
                          <fieldset>
                            <legend>{{option40Value}}</legend>
                            <div class="row">
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Bruts</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutsalary" placeholder="Montant en DT et à 3 chiffres après la virgule"  />
                                    </div>
                              </div>
                              <div class="col-6">
                                  <div class="form-group">
                                      <label>Total des Salaires Imposables</label>
                                      <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="imposalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                    </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                    <label>Total des Retenues à La Source</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="retenuesalary" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      />
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>Contribution sociale de solidarité</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                      class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="solidaritycontribution" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                      min="0" oninput="validity.valid||(value='');"/>
                                </div> 
                          </div>
                          
                            </div>
                           </fieldset>
                        </form>
                      </div>
<!-- loyers, commissions, courtages et vacations section-->
                    <div class="container-fluid"*ngIf="option40Value == 'loyers, commissions, courtages et vacations'&&option38Value">
                        <fieldset>
                          <legend>{{option40Value}}</legend>
                        
                      <table class="table table-striped">
                        <thead>
                          <tr>
                      
                            
          <th>Rubrique loyers, commissions, courtages et vacations</th>
          <th>Total des montants bruts</th>
          <th>Taux</th>
          <th>Montant total des retenues à la source</th>
          <th>Total des montants nets</th>
          
                           
                            
                          </tr>
                          </thead>
                          
                        <tbody>
<!-- loyers, commissions, courtages et vacations (résidents et personnes phyisiques)form--> 
                        <tr id="tvaform" [formGroup]="standardlocationresidentesphysiqueform">
                          <td>
                            <p> Servis aux personnes physiques résidentes </p>
                          </td>
                           <td>  <div class="col-6">
                            
                                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              
                        </div></td>
                        <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                            
                      </div></td>
                      <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                      
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                        
                  </div></td>
                  
                      </tr>
                      <!-- loyers, commissions, courtages et vacations (résidents et personnes morales)form--> 
                      <tr id="tvaform" [formGroup]="standardlocationresidentesmoraleform">
                        <td>
                          <p> Servis aux personnes morales résidentes </p>
                        </td>
                         <td>  <div class="col-6">
                          
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
                          
                    </div></td>
                    <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                  </div></td>
                  <td><div class="col-6">
                    
                         <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                      
                </div></td>
                
                    </tr>
                     <!-- loyers, commissions, courtages et vacations (non résidents et personnes physiques)form--> 
                     <tr id="tvaform" [formGroup]="standardlocationnonresidentesphysiquesform">
                      <td>
                        <p> Servis aux personnes physiques non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>
                    <!-- loyers, commissions, courtages et vacations (non résidents et personnes morales)form--> 
                    <tr id="tvaform" [formGroup]="standardlocationnonresidentesmoralesform">
                      <td>
                        <p> Servis aux personnes morales non résidentes </p>
                      </td>
                       <td>  <div class="col-6">
                        
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="15%" disabled/>
                        
                  </div></td>
                  <td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
                    
              </div></td>
              
                  </tr>  
                      </table>
                    </fieldset>
  
    
       
  
  

                    </div>
<!-- honoraires section-->
<div class="container-fluid"*ngIf="option40Value == 'honoraires'&&option38Value">
<fieldset>
    <legend>{{option40Value}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique honoraires</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- honoraires (servis aux personnes physiques et soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquereelform">
    <td>
      <p> Servis aux personnes physiques soumis au régime réel </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- honoraires (servis aux personnes physiques et non soumises au régime réel)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairephysiquenonreelform">
  <td>
    <p> Servis aux personnes physiques non soumises au régime réel </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="10%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- honoraires (servis aux sociétés et aux groupements)form-->                    
<tr id="tvaform" [formGroup]="standardhonorairegroupementsform">
  <td>
    <p> Servis aux aux sociétés et aux groupements </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="3%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
</table>
</fieldset>

      
        </div>
<!-- mont supérieures à 1000 dt section-->
<div class="container-fluid"*ngIf="option40Value == 'montants supérieurs à 1000 dt'&&option38Value">
<fieldset>
    <legend>{{option40Value}}</legend>
  
<table class="table table-striped">
  <thead>
    <tr>

      
<th>Rubrique montants supérieures à 1000 dt</th>
<th>Total des montants bruts</th>
<th>Taux</th>
<th>Montant total des retenues à la source</th>
<th>Total des montants nets</th>

     
      
    </tr>
    </thead>
    
  <tbody>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 15%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant15form">
    <td>
      <p> établissements soumis à l'i/s au taux de 15% </p>
    </td>
     <td>  <div class="col-6">
      
          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
        
  </div></td>
  <td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1%" disabled/>
      
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
    
</div></td>
<td><div class="col-6">

     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
  
</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements soumis à l'i/s au taux de 10%)form-->                    
<tr id="tvaform" [formGroup]="standardmontant10form">
  <td>
    <p> établissements soumis à l'i/s au taux de 10% </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section (établissements individuels et éligible à la réduction des 2/3 des revenus)form-->                    
<tr id="tvaform" [formGroup]="standardmontantindividuelform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="0.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>
<!-- mont supérieures à 1000 dt section  (autre établissements)form-->                    
<tr id="tvaform" [formGroup]="standardmontantautreform">
  <td>
    <p> établissements individuels et éligible à la réduction des 2/3 des revenus </p>
  </td>
   <td>  <div class="col-6">
    
        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="brutammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
      
</div></td>
<td><div class="col-6"  >
                          
                              <input type="text" class="form-control" value="1.5%" disabled/>
    
</div></td>
<td><div class="col-6"  (click)="setThreeNumberDecimal($event)">
                          
                              <input type="text" class="form-control" formControlName="retenueammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
  
</div></td>
<td><div class="col-6">

   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
        class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="netammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>


</div></td>

</tr>  
</table>
</fieldset>    

</div>

                   
                      </mat-tab>                  
                      <!-- TFP tab -->
                      <mat-tab label="TFP" id="TFPtab" *ngIf="showtfptab === true">
                        <h2>TFP</h2>
                        <form [formGroup]="standardtfpform" class="form-grid">
                          <div *ngIf="showtfpsalairebrut === true" class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Total des Salaires Bruts</mat-label>
                              <input matInput type="text" formControlName="tfpsalairebrut" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Salaires Non Soumis TFP</mat-label>
                              <input matInput type="text" formControlName="salairesnonsoumistfp" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Base De La TFP</mat-label>
                              <input matInput type="text" formControlName="basetfp" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Taux</mat-label>
                              <input matInput type="text" value="2%" disabled>
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Montant de la TFP du mois</mat-label>
                              <input matInput type="text" formControlName="tfpammountmoisactuel" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Montant De L'avance</mat-label>
                              <input matInput type="text" formControlName="avanceammount" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Report du TFP du mois précédent</mat-label>
                              <input matInput type="text" formControlName="tfpammountreportmoisprecedent" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>TFP à Payer</mat-label>
                              <input matInput type="text" formControlName="tfpapayer" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                      
                          <div class="form-item">
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>TFP à reporter</mat-label>
                              <input matInput type="text" formControlName="tfpareporter" placeholder="Montant en DT et à 3 chiffres après la virgule"
                                (keyup)="sanitizeInput($event)" appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                            </mat-form-field>
                          </div>
                        </form>
                      </mat-tab>
                      <!-- FOPROLOS tab -->
                      <mat-tab label="FOPROLOS" id="FOPROLOStab"*ngIf="showfoprolostab===true">
                      <!-- FOPROLOSform -->
                      <fieldset>
                        <legend> FOPROLOS</legend>                   
                      <form  [formGroup]="standardfoprolosform">
                        
                      
                          <div class="row">
                            
                            <div class="col-6" *ngIf="showfoprolossalairebrut===true">
                              <div class="form-group">
                                  <label>Total des salaires bruts</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)"  formControlName="foprolossalairebrut" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                                <label>Salaires non soumis au FOPROLOS</label>
                                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="salairesnonsoumisfoprolos" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              </div>
                        </div>
                            <div class="col-6">
                                <div class="form-group"(click)="setThreeNumberDecimal($event)">
                                    <label>Base Du FOPROLOS</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="basefoprolos" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Taux</label>
                                    <input type="text" class="form-control" value="1%" disabled/>
                                  </div>
                            </div>
                            
                          <div class="col-6">
                            <div class="form-group">
                                <label>Montant du FOPROLOS</label>
                                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="foprolosammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              </div>
                          </div> 
                          </div>
                        </form>
                          </fieldset>
                          
                      </mat-tab>                     
                     <!-- TVA tab -->
                     <mat-tab label="TVA" id="TVAtab"*ngIf="showtvatab===true">
                        <!-- 1ère étape de choice-->
                        
                        <div class="input-container">
                          <div class="form-group"> 
                          <label for="retenuelist">Renseigner votre report de TVA du mois précédant :</label>     
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                     class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" [(ngModel)]="option64Value" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                                    </div> 
                 </div>
                 
                        
                    
                      <!-- tva collecté form-->
                      <div class="container-fluid">
                        <!-- TVA Collectée Section -->
                        <fieldset *ngIf="tva7">
                          <legend>
                            TVA Collectée
                            <span > C.A</span>
                            <span *ngIf="isMedicalProfessional(activite)">
                              (Actes de soin)
                            </span>
                          </legend>
                          <form [formGroup]="standardtvacollecteform">
                            <div class="form-row">
                              <div class="form-group">
                                <label>Chiffre d'affaires HT</label>
                                <input type="text" class="form-control" formControlName="chiffreaffaireht"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                      
                              <div class="form-group">
                                <label>Taux</label>
                                <input type="text" class="form-control" value="7%" disabled>
                              </div>
                      
                              <div class="form-group">
                                <label>Montant TVA</label>
                                <input type="text" class="form-control" formControlName="tvaammount"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                      
                              <div class="form-group">
                                <label>Chiffre d'affaires TTC</label>
                                <input type="text" class="form-control" formControlName="ammountttc"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                            </div>
                          </form>
                        </fieldset>
                      
                        <!-- TVA Collectée 13% Section -->
                        <fieldset *ngIf="tva13">
                          <legend>TVA Collectée <span>C.A à 13%</span></legend>
                          <form [formGroup]="standardtvacollecte13form">
                            <div class="form-row">
                              <div class="form-group">
                                <label>Chiffre d'affaires HT</label>
                                <input type="text" class="form-control" formControlName="chiffreaffaireht"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                      
                              <div class="form-group">
                                <label>Taux</label>
                                <input type="text" class="form-control" value="13%" disabled>
                              </div>
                      
                              <div class="form-group">
                                <label>Montant TVA</label>
                                <input type="text" class="form-control" formControlName="tvaammount"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                      
                              <div class="form-group">
                                <label>Chiffre d'affaires TTC</label>
                                <input type="text" class="form-control" formControlName="ammountttc"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                            </div>
                          </form>
                        </fieldset>
                      
                        <!-- TVA Collectée 19% Section -->
                        <fieldset *ngIf="tva19">
                          <legend>
                            TVA Collectée C.A à 19%
                            <span *ngIf="isMedicalActivity(option54Value, sousactivite)">
                              (Actes de soin)
                            </span>
                            <span *ngIf="isVeterinaryActivity(option54Value, sousactivite)">
                              (Etudes)
                            </span>
                          </legend>
                          <form [formGroup]="standardtvacollecte19form">
                            <div class="form-row">
                              <div class="form-group">
                                <label>Chiffre d'affaires HT</label>
                                <input type="text" class="form-control" formControlName="chiffreaffaireht"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                      
                              <div class="form-group">
                                <label>Taux</label>
                                <input type="text" class="form-control" value="19%" disabled>
                              </div>
                      
                              <div class="form-group">
                                <label>Montant TVA</label>
                                <input type="text" class="form-control" formControlName="tvaammount"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                      
                              <div class="form-group">
                                <label>Chiffre d'affaires TTC</label>
                                <input type="text" class="form-control" formControlName="ammountttc"
                                  placeholder="Montant en DT et à 3 chiffres après la virgule"
                                  (keyup)="sanitizeInput($event)"
                                  appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)">
                              </div>
                            </div>
                          </form>
                        </fieldset>
                      </div>
                      
    <!-- 1ère étape de décision-->
    <div title="indiquer votre choix"class="col-6">
      <div class="form-group">
      <label for="choice" style="color: red;"> Avez vous d'autres revenus accessoires soumis à la TVA:</label>
      <div id="block9"><input type="checkbox"  class="input-field" id="choice8" (click)="myFunction22()"[(ngModel)]="option170Value" name="choice">Oui</div>  
      <div id="block10"><input type="checkbox"  class="input-field" id="choice9" (click)="myFunction23()"[(ngModel)]="option63Value" name="choice">Non</div>     
      
      </div>
      </div>
       
                      <div class="users-grid" style="overflow-x:hidden;overflow-y: auto;height:auto;"*ngIf="option170Value">
                        <!-- location à usage d'habitation meublé form-->
                        
                        <fieldset>
                          <legend>Autres revenus accessoires soumis à la TVA</legend>
                        <table class="table table-striped" *ngIf="option170Value">
                          <thead>
                            <tr>
                        
                              
            <th>Type TVA</th>
            <th>Montant Hors Taxes</th>
            <th>Taux</th>
            <th>Montant TVA</th>
            <th>Montant TTC</th>
            
                             
                              
                            </tr>
                            </thead>
                            
                          <tbody>
                          <tr id="tvaform" [formGroup]="standardlocationusagehabitationmeubleform">
                            <td>
                              <p> TVA sur location à usage d'habitation meublé</p>
                            </td>
                             <td>  <div class="col-6">
                              
                                  
                                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                
                          </div></td>
                          <td><div class="col-6">
                            
                                
                                <input type="text" class="form-control" value="19%" disabled/>
                              
                        </div></td>
                        <td><div class="col-6"(click)="setThreeNumberDecimal($event)">
                          
                              
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="tvaammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6">
                        
                            
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountttc" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                      </div></td>
                    
                        </tr>
                        <!-- location à usage commercial form-->
                        <tr id="tvaform" [formGroup]="standardlocationusagecommercialform">
                          <td>
                            <p>TVA sur location à usage commercial, industriel, professionnel et artisanal</p>
                          </td>
                           <td>  <div class="col-6">
                            
                                
                                 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              
                        </div></td>
                        <td><div class="col-6">
                          
                              
                              <input type="text" class="form-control" value="19%" disabled/>
                            
                      </div></td>
                      <td><div class="col-6"(click)="setThreeNumberDecimal($event)">
                        
                            
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="tvaammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                      
                          
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountttc" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                    </div></td>
                  
                      </tr>
                      
                <!-- opérations de lotissement   -->
                <tr id="tvaform" [formGroup]="standardoperationlotissementform">
                  <td>
                    <p>TVA sur opérations de lotissement</p>
                  </td>
                   <td>  <div class="col-6">
                    
                        
                         <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                      
                </div></td>
                <td><div class="col-6">
                  
                      
                      <input type="text" class="form-control" value="19%" disabled/>
                    
              </div></td>
              <td><div class="col-6"(click)="setThreeNumberDecimal($event)">
                
                    
                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="tvaammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                  
            </div></td>
            <td><div class="col-6">
              
                  
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountttc" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                
            </div></td>
          
              </tr>
              <!-- intérêts perçus    -->
              <tr id="tvaform" [formGroup]="standardinteretpercueform">
                <td>
                  <p>TVA sur intérêts perçus</p>
                </td>
                 <td>  <div class="col-6">
                  
                      
                       <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                    
              </div></td>
              <td><div class="col-6">
                
                    
                    <input type="text" class="form-control" value="19%" disabled/>
                  
            </div></td>
            <td><div class="col-6"(click)="setThreeNumberDecimal($event)">
              
                  
                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="tvaammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                
          </div></td>
          <td><div class="col-6">
            
                
                 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountttc" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
              
          </div></td>
        
            </tr>
             <!-- autre tva special    -->
             <tr id="tvaform" [formGroup]="standardautretvaspecialform">
              <td>
                <p>Autre TVA</p>
              </td>
               <td>  <div class="col-6">
                
                    
                     <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                  
            </div></td>
            <td><div class="col-6">
              
                  <div class="form-group">
                  <input type="text" (keypress)="keyPressNumbers($event)" class="form-control" formControlName="tauxpercent"/>
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
          </div></td>
          <td class="hidden"><div class="col-6">
              
                  
            <input type="text" class="form-control" formControlName="taux"/>
          
    </div></td>
          <td><div class="col-6"(click)="setThreeNumberDecimal($event)">
            
                
                <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="tvaammount" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
              
        </div></td>
        <td><div class="col-6">
          
              
               <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="ammountttc" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
            
        </div></td>
      
          </tr>
          <!-- Somme Autre revenus soumis à la TVA    -->
          <tr id="tvaform">
            <td>
              <p>Total des autres revenus soumis à la TVA</p>
            </td>
            
             <td>  <div class="col-6">
              
                  
                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                         disabled     class="form-control"appTwoDigitDecimaNumber (change)="setThreeNumberDecimal($event)" [(ngModel)]="option71Value" placeholder="Total Montant HT"/>
                
          </div></td>
          
        <td><div class="col-6">
            
                
          <input type="text" class="form-control"  value="19%" disabled/>
        
  </div></td>
        <td><div class="col-6"(click)="setThreeNumberDecimal($event)">
          
              
              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
              disabled     class="form-control"appTwoDigitDecimaNumber (change)="setThreeNumberDecimal($event)" [(ngModel)]="option72Value" placeholder="Total TVA"/>
            
      </div></td>
      
    
        </tr>
                          </tbody>
                        
                        </table>
                      </fieldset>

                      
                      </div>
                      <fieldset>  
                        <legend>TVA récupérable</legend>
                        <table class="table table-striped" >
                          
                          <col>
                          <colgroup span="2"></colgroup>
                          <colgroup span="2"></colgroup>
                          <thead>
                          <tr>
                            <td rowspan="2"></td>
                            <th colspan="2" scope="colgroup"style="text-align: center;">Achats locaux</th>
                            <th colspan="2" scope="colgroup"style="text-align: center;">Achats importés</th>
                          </tr>
                          <tr>
                            <th scope="col"style="text-align: center;">Hors Taxes</th>
                            <th scope="col"style="text-align: center;">TVA</th>
                            <th scope="col"style="text-align: center;">Hors Taxes</th>
                            <th scope="col"style="text-align: center;">TVA</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="tvaform" [formGroup]="standardtvarecuperableequipementform">
                            <th scope="row">Achat Equipements</th>
                             <td>  <div class="col-6">
                              
                                  
                                   <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                              class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatlocauxht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                
                          </div></td>
                          <td><div class="col-6">
                              
                                  
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                       class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatlocauxtva" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                         
                    </div></td>
                        <td><div class="col-6">
                          
                              
                              <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatimporteht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6">
                        
                            
                             <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatimportetva" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                      </div></td>
                    
                        </tr>
                         
                        <tr id="tvaform" [formGroup]="standardtvarecuperableautreachatform">
                          <th scope="row">Autre Achats</th>
                           <td>  <div class="col-6">
                            
                                
                                 <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                            class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatlocauxht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                              
                        </div></td>
                        <td><div class="col-6">
                            
                                
                          <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                     class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatlocauxtva" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                       
                    </div></td>
                      <td><div class="col-6">
                        
                            
                            <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatimporteht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                          
                    </div></td>
                    <td><div class="col-6">
                      
                          
                           <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatimportetva" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                        
                    </div></td>
                    
                      </tr>
                      <tr id="tvaform" [formGroup]="standardtvarecuperableimmobilierform">
                        <th scope="row">Achats Immobiliers</th>
                         <td>  <div class="col-6">
                          
                              
                               <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                          class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatlocauxht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                            
                      </div></td>
                      <td><div class="col-6">
                          
                              
                        <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                                   class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="achatlocauxtva" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                     
                    </div></td>
                    
                    
                    </tr>
                          </tbody>
                        
                        </table>
                    </fieldset>
                     </mat-tab>
                     <mat-tab label="FSSP" id="FSSPtab"*ngIf="showfsptab===true">
                      <fieldset>
                        <legend>FSSP</legend>                   
                      <form  [formGroup]="standardfspform">
                        
                      
                          <div class="row">
                            <div class="col-6">
                                <div class="form-group" (click)="setThreeNumberDecimal($event)">
                                    <label>Chiffre d'affaire HT</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="chiffreaffaireht" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>taux</label>
                                    <input type="text" class="form-control" value="1%" disabled/>
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group"(click)="setThreeNumberDecimal($event)">
                                  <label>Montant de la Contribution</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="montantcontribution" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                </div>
                          </div>
                         
                          </div>
                        </form>
                          </fieldset>
                    </mat-tab>
                     <mat-tab label="Droit De Timbre" id="Timbretab"*ngIf="showtimbretab===true">
                      <fieldset>
                        <legend> Droit de timbre</legend>                   
                      <form  [formGroup]="standarddroittimbreform">
                        
                      
                          <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>nombre de notes d'honoraires</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber  formControlName="nombrenotehonoraire" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                  </div>
                            </div>
                            <div class="col-6"(click)="setThreeNumberDecimal($event)">
                                <div class="form-group">
                                    <label>Droit de timbre unitaire</label>
                                    <input type="text" class="form-control" formControlName="taux"/>
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group">
                                  <label>total du droit de timbre</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="totaldroittimbre" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                </div>
                          </div>
                         
                          </div>
                        </form>
                          </fieldset>
                     </mat-tab>
                     <mat-tab label="TCL" id="TCLtab"*ngIf="showtcltab===true">
                      <fieldset>
                        <legend>TCL</legend>                   
                      <form  [formGroup]="standardtclform">
                        
                      
                          <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Chiffre d'affaire TTC</label>
                                    <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                    class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="chiffreaffairettc" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>taux</label>
                                    <input type="text" class="form-control" value="0.2%" disabled/>
                                  </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group"(click)="setThreeNumberDecimal($event)">
                                  <label>TCL à payer</label>
                                  <input type="text" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" 
                                  class="form-control"appTwoDigitDecimaNumber (blur)="setThreeNumberDecimal($event)" formControlName="tclapayer" placeholder="Montant en DT et à 3 chiffres après la virgule"/>
                                </div>
                          </div>
                         
                          </div>
                        </form>
                          </fieldset>
                    </mat-tab>
                    </mat-tab-group>
                   </div>
