<form (ngSubmit)="onSubmit()" class="filter-form">
    <div class="form-group">
      <label for="user">User ID</label>
      <select #cricket [(ngModel)]="filters.user" (click)="getactifusers()">
        <option value="">Select a user</option> <!-- Default option -->
        <ng-container *ngIf="users && users.length > 0">
          <option *ngFor="let us of users" [value]="us._id">
            <span appUserDisplay="{{us._id}}" [displayProperties]="['firstname', 'raisonsociale']"></span>
          </option>
        </ng-container>
      </select>           
    </div>
  
    <div class="form-group">
      <label for="year">Year</label>
      <input type="text" [(ngModel)]="filters.year" name="year" id="year" placeholder="Enter Year" />
    </div>
  
    <div class="form-group">
      <label for="ref">Reference Number</label>
      <input type="text" [(ngModel)]="filters.ref" name="ref" id="ref" placeholder="Enter Reference Number" />
    </div>
  
    <div class="form-group">
      <label for="dateinf">Start Date</label>
      <input type="date" [(ngModel)]="filters.dateinf" name="dateinf" id="dateinf" placeholder="Select Start Date" />
    </div>
  
    <div class="form-group">
      <label for="datesup">End Date</label>
      <input type="date" [(ngModel)]="filters.datesup" name="datesup" id="datesup" placeholder="Select End Date" />
    </div>
  
    <button type="submit" class="submit-btn">Search</button>
  </form>
  