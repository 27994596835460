<button id="savebutton" class="btn btn-success"type="button" (click)="onSubmit($event)"><i class="fa fa-refresh fa-spin" *ngIf="loading"></i>Sauvegarder</button>
<div *ngIf="currentStep === 0">
  <app-page-garde
    (nextStep)="next()"
    (previousStep)="previous()"
  ></app-page-garde>
</div>
<div *ngIf="currentStep === 1">
  <app-page-garde2
    (nextStep)="next()"
    (previousStep)="previous()"
    (moveStep)="handleStepChanged($event)"
  ></app-page-garde2>
</div>
<div *ngIf="currentStep === 2">
  <app-rs (nextStep)="next()" (previousStep)="previous()" (moveStep)="handleStepChanged($event)"
  ></app-rs>
</div>
<div *ngIf="currentStep === 3">
  <app-tfp-comp (nextStep)="next()" (previousStep)="previous()"(moveStep)="handleStepChanged($event)"></app-tfp-comp>
</div>
<div *ngIf="currentStep === 4">
  <app-foprolos-compo
    (nextStep)="next()"
    (previousStep)="previous()"
    (moveStep)="handleStepChanged($event)"
  ></app-foprolos-compo>
</div>
<div *ngIf="currentStep === 5">
  <app-tva1 (nextStep)="next()" (previousStep)="previous()"(moveStep)="handleStepChanged($event)"></app-tva1>
</div>
<div *ngIf="currentStep === 6">
  <app-tva2 (nextStep)="next()" (previousStep)="previous()"(moveStep)="handleStepChanged($event)"></app-tva2>
</div>
<div *ngIf="currentStep === 7">
  <app-dt-comp (nextStep)="next()" (previousStep)="previous()"(moveStep)="handleStepChanged($event)"></app-dt-comp>
</div>
<div *ngIf="currentStep === 8">
  <app-tcl1 (nextStep)="next()" (previousStep)="previous()"(moveStep)="handleStepChanged($event)"></app-tcl1>
</div>
<div *ngIf="currentStep === 9">
  <app-tcl2 (nextStep)="next()" (previousStep)="previous()"(moveStep)="handleStepChanged($event)"></app-tcl2>
</div>
<div *ngIf="currentStep === 10">
  <app-tcl3 (nextStep)="next()" (previousStep)="previous()"(moveStep)="handleStepChanged($event)"></app-tcl3>
</div>
