<div class="container">
  <div class="row">
    <div class="col-12 mb-3">
      <div class="title2 mt-3">
        <span
          style="font-size: 28px; color: rgba(255, 0, 0, 1)"
          class="mx-3"
          >Données Réclamation :</span
        >
      </div>
    </div>
  </div>

  <div class="row mt-4 info-container mb-3">
    <div class="col-md-8">
      <div class="section1 fs-4 px-3">
        <p>
          <span class="info-label">Crée le (heure GMT) :</span>
          <span class="info-data">{{ contact.created }}</span>
        </p>
        <p>
          <span class="info-label"
            >Dernière modification le (heure GMT) :</span
          >
          <span class="info-data">{{ contact.updated }}</span>
        </p>
        <p>
          <span class="info-label">Nom et Prénom :</span>
          <span class="info-data"> {{ contact.firstname }} {{ contact.lastname }}</span>
        </p>
        <p>
          <span class="info-label">Mobile :</span>
          <span class="info-data">{{ contact.mobile }}</span>
        </p>
        <p>
          <span class="info-label">Description :</span>
          <span class="info-data">{{ contact.description }}</span>
        </p>
        <p>
          <span class="info-label">Fichier joint</span>
          <a href="{{contact.ficheUrl}}" class="info-data"  style="text-decoration: underline" target="_blank">Aperçu Fiche</a>
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <img
        src="../../assets/ebp-image-blog-taches-comptables-a-dematerialiser-d-urgence-bd-489887943.png"
        alt="Image"
        style="max-width: 100%; height: 300px"
        class="img-fluid"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-md-11">
      <table class="table table-bordered mt-4">
        <thead>
      <tr *ngIf="contact.dateouverturedossier&&currentUser.role=='admin'||contact.dateouverturedossier&&currentUser.role=='supervisor'"><th>date ouverture dossier:</th><td>{{contact.dateouverturedossier|customDate}}</td></tr> 
      <tr *ngIf="contact.affecte&&currentUser.role=='admin'||contact.affecte&&currentUser.role=='supervisor'"><th>Collaborateur affecté:</th><td>{{filterusers(contact.affecte)}}{{prenomcollab}}&nbsp;{{nomcollab}}</td></tr> 
      <tr *ngIf="currentUser.role=='admin'||currentUser.role=='supervisor'"><th>durée de traitement:</th><td>{{countdown}}</td></tr> 
      <tr>
        <th  style="text-align: center;">Statuts:</th>
        <th  style="text-align: center;">Motifs:</th> 
        <th  style="text-align: center;">date traitement:</th>
        <th  style="text-align: center;">durée cumulée de traitement:</th>
        <th  style="text-align: center;">traité par:</th>
  
      </tr>  
      </thead>
        <tbody>
          <tr *ngFor="let statut of sortedallstatuts">
            <td  style="text-align: center;"> <span *ngIf="statut.statut">{{statut.statut}}</span><span *ngIf="statut.statutcoll">{{statut.statutcoll}}</span> </td>
            <td  style="text-align: center;"> <span *ngIf="statut.statut">{{statut.motif}}</span><span *ngIf="statut.statutcoll">{{statut.motifcoll}}</span></td>
            <td  style="text-align: center;"> {{statut.datefin| customDate}}</td>
            <td  style="text-align: center;"> {{statut.duree}}</td>
            <td  style="text-align: center;"><span *ngIf="statut.statut">Admin</span><span *ngIf="statut.statutcoll">Collaborateur</span></td>
          </tr>
        </tbody> 
</table>
    </div>
  </div>
  <div class="row">
    <!-- Boutons d'action -->
    <div
      class="col-md-6 d-flex flex-column flex-md-row align-items-center mt-3"
    >
      <button
      *ngIf="currentUser.role=='admin'" (click)="decideadmin()"
        type="button"
        style="font-family: Monaco; padding: 10px 20px"
        class="btn btn-success btn-lg rounded-pill mb-3 mb-md-0 me-md-1 text-nowrap"
      >
        Modifier statut Réclamation (Admin)
      </button>
      <button
      *ngIf="currentUser.role=='supervisor'||currentUser.rolesuperviseur=='Autorisé'" (click)="traite()"
        type="button"
        style="font-family: Monaco; padding: 10px 20px"
        class="btn btn-danger btn-lg rounded-pill mb-3 mb-md-0 me-md-1 text-nowrap"
      >
        Marquer comme traité (collab)
      </button>
    </div>

    <!-- Affectation de Collaborateur -->
    <div class="col-md-3 d-flex flex-column align-items-start mb-4"*ngIf="currentUser.role=='admin'">
      <label for="validationDefaultActivity2" class="form-label fs-5"
      >Choix Collaborateur</label
    >   
      <select class="form-select" id="validationDefaultActivity2"#cricket [(ngModel)]="optionValue"*ngIf="currentUser.role=='admin'" required >
        <option value="default"> </option>
        <option *ngFor="let col of collab" [value]="col._id">
            {{col.firstname}} {{col.lastname}}
        </option>
      </select>
    </div>

    <!-- Gestion des Statuts -->
    <div class="col-md-3 d-flex flex-column align-items-start"*ngIf="currentUser.role=='admin'">
      <label for="validationDefaultActivity1" class="form-label fs-5"
        >Gérer les Statuts</label
      >
      <select class="form-select"#cricket (change)="update($event)" id="validationDefaultActivity1" required>
        <option value="default"> </option>
          <option (click)="deletestatutcollab()">supprimer le dernier statut collaborateur</option>
          <option (click)="deletestatutadmin()">supprimer le dernier statut admin</option>
      </select>
    </div>

    <!-- Bouton d'Affectation -->
    <div class="col-12 d-flex justify-content-end mt-2">

      <button
        type="button"
        style="font-family: Monaco; width: 150px"
        class="btn btn-warning fs-5 mt-3 text-dark rounded-pill"
        *ngIf="currentUser.role=='admin'" (click)="affect()"
      >
      <span *ngIf="!contact.affecte">Affecter collaborateur</span> <span *ngIf="contact.affecte">Réaffecter collaborateur</span>
      </button>
    </div>
  </div>
</div>