import { Component, EventEmitter, Output,OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-invoice',
  templateUrl: './filter-invoice.component.html',
  styleUrls: ['./filter-invoice.component.scss']
})
export class FilterInvoiceComponent implements OnInit {
  filters = {
    user: '',
    year: '',
    ref: '',
    dateinf: '',
    datesup: ''
  };

  @Output() filtersSubmitted: EventEmitter<any> = new EventEmitter<any>();
  users=[];
  usersSub: Subscription;
  constructor(private userservice: UserService
    ){}
    ngOnInit(): void {
      this.usersSub = this.userservice.clientactifusers$.subscribe(
        (users) => {
          this.users = users;                                              
        },
        (error) => {
        }
      );
    }
  onSubmit() {
    // Construct the filter array
    const filterArray = [];

    if (this.filters.user) {
      filterArray.push({ key: 'user', value: this.filters.user });
    }
    if (this.filters.year) {
      filterArray.push({ key: 'year', value: this.filters.year });
    }
    if (this.filters.ref) {
      filterArray.push({ key: 'ref', value: this.filters.ref });
    }
    if (this.filters.dateinf) {
      filterArray.push({ key: 'dateinf', value: this.filters.dateinf });
    }
    if (this.filters.datesup) {
      filterArray.push({ key: 'datesup', value: this.filters.datesup });
    }

    // Emit the filter array to the parent component
    this.filtersSubmitted.emit(filterArray);
  }
  getactifusers()
  {
    this.userservice.getUseractif()
  }
}
