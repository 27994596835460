
  <body>
    <div class="container mt-5">
      <h1 class="text-center mt-5 text-danger fs-2">
        المساهمة في صندوق النسوض بالمسكن لفائدة الأجراء
      </h1>
      <h1 class="text-center mt-3 text-danger fs-2">
        CONTRIBUTION AU FOPROLOS
      </h1>

      <table class="table table-bordered mt-5">
        <thead>
          <tr>
            <th scope="col" class="text-center">المساهمة المستوجبة</th>
            <th scope="col" class="text-center">النسبة</th>
            <th scope="col" class="text-center">أساس المساهمة</th>
          </tr>
          <tr>
            <th scope="col" class="text-center">Contribution due</th>
            <th scope="col" class="text-center">Taux</th>
            <th scope="col" class="text-center">Assiette de la Contribution</th>
          </tr>
        </thead>
        <tbody [formGroup]="foprolosform">
          <tr>
            <td class="text-center">
              <input type="text" appDecimalFormat  formControlName="v1" class="form-control" />
            </td>
            <td class="text-center">1%</td>
            <td class="text-center">
              <input type="text" appDecimalFormat  formControlName="v2" class="form-control" />
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-bordered mt-5">
        <thead>
          <tr>
            <th class="text-center">Non لا</th>
            <th class="text-center">Oui نعم</th>
            <th class="text-center">معفى</th>
            <th class="text-center" colspan="2" rowspan="2">
              <input type="text" class="form-control" />
            </th>
            <th class="text-center" colspan="2">عدد المنتفعين</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <input type="radio" name="approval" value="non" />
            </td>
            <td class="text-center">
              <input type="radio" name="approval" value="oui" />
            </td>
            <td class="text-center">Exonéré</td>
            <td colspan="2" class="text-center"></td>
            <td class="text-center">Nb bénéficiaires</td>
          </tr>
        </tbody>
      </table>

      <div class="row wizard-buttons">
        <div class="col d-flex justify-content-center align-items-center">
          <button
          (click)="ngOnDestroy()"
          type="button"
          class="btn btn-outline-primary"
        >
        موافق  Valider
        </button>
        <button
        (click)="reset()"
        type="button"
        class="btn btn-outline-primary"
      >
      إلغاء   Annuler
      </button>
      <button
      (click)="goto(1)"
        type="button"
        class="btn btn-outline-primary"
      >
        السابق Precedent
      </button>
        </div>
      </div>
    </div>
  </body>
